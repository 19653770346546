import { db, firebase } from "@/firebase/config.js";
import moment from 'moment';

export async function FBactualizaApoyo(data, etapaS, idRegistroS, todosLosDatos, totalApoyos) {
    try {

        //console.log(data, etapaS, idRegistroS, todosLosDatos, totalApoyos);

        const datosGenerales = todosLosDatos;
        //SE GUARDA O AGREGA EN LA ESTA CORRESPONDIENTE -----------------------------#1
        const collectionPathCreaProspecto = `cf-ventas/etapas/${etapaS}`;

        const querySnapshot = await db
            .collection(collectionPathCreaProspecto)
            .where('seguimientoId', '==', idRegistroS) // Filtrar por el valor de idRegistroS
            .get();

        if (querySnapshot.empty) {
            //console.error('No se encontraron documentos que coincidan con el filtro.');
            return [false, "No existe Documento"];
        }

        const docRef = querySnapshot.docs[0].ref; // Obtener la referencia del primer documento que cumple con el filtro

        // Luego, puedes actualizar el documento usando el método `update`:
        await docRef.update({
            apoyo: firebase.firestore.FieldValue.arrayUnion(data)
        });

        //SE GUARDA EN EL AREA QUE SE ESTÁ PIDIENDO APOYO -----------------------------#2
        //Si la fecha llega en formato 'YYYY-MM-DD' la convierte de lo contrario se queda como viene.
        //const proxContac = await convertirFechaATimestamp();
        //console.log(proxContac);

        const estado = "pendiente"; //false; Hay t4 estados pendiente, terminado,trabajando, cancelado
        const posicion = totalApoyos; // Es la posición para luego hacer actulizaciones en la ficha de cliente.
        // /cf-juridico/tareasExternas/listado
        const jsonNuevo = {
            cliente: datosGenerales.cliente,
            title: data.title,
            observacion: data.observacion,
            departamento: data.departamento,
            colaborador: "N/A",
            seguimientoId: idRegistroS,
            proxContacto: datosGenerales.proxContacto,
            etapa: datosGenerales.etapa,
            prioridad: datosGenerales.prioridad,
            estado: estado,
            timeR: firebase.firestore.FieldValue.serverTimestamp(),
            posicion: posicion
        };

        //console.log(jsonNuevo);

        // Verificar si existe la ruta collectionPathApoyo
        const collectionPathApoyo = `${data.departamento}/tareasExternas/listado`;

        // Resto del código para guardar el JSON en la ruta especificada y obtener la referencia al documento agregado
        const docApoyoRef = await db.collection(collectionPathApoyo).add(jsonNuevo);

        if (docApoyoRef && docApoyoRef.id) {
            console.log('JSON guardado exitosamente en ' + data.departamento, jsonNuevo);
            // return true;
        } else {
            console.error('Error al guardar el JSON cf-ventas');
            // return false;
        }



        return [true, "Se ha actualizado con exito"];
    } catch (error) {
        console.error('Error al actualizar el documento:', error);
        return [false, "Error.."];
    }
}


async function convertirFechaATimestamp(fecha) {
    if (moment.isDate(fecha)) {
      // Si la fecha es de tipo Date, asumimos que ya es un timestamp
      return fecha;
    }
  
    const fechaObjetivo = moment(fecha, 'YYYY-MM-DD').toDate();
    const timestamp = firebase.firestore.Timestamp.fromDate(fechaObjetivo);
    return timestamp;
  }
  