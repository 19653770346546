import { db } from "@/firebase/config";

// Trae los Clientes
export async function FBtraeClientes() {
  try {

    const rolesRef = db.collection("cf-cartera")
      .doc("Clientes")
      .collection("Listado")
      .where("data.estado", "==", true);

    const querySnapshot = await rolesRef.get();

    if (!querySnapshot.empty) {
      const clientesData = [];
      querySnapshot.forEach((doc) => {
        clientesData.push(doc.data());
      });
      return clientesData;
    } else {
      return [];
    }






  } catch (error) {
    console.error('Error:', error);
    return {};
  }
}

