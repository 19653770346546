import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,//el usuario no existe
    cambiosPanelesDCM: false,
  },
  mutations: {
    //se usa para modificar el state
    setUser(state, playload) {
      state.user = playload;
    },
    setCambiosPanelDCM(state, playload) {
      console.log('Desde Set');
      state.cambiosPanelesDCM = playload;
    }
  },
  actions: {
    cerrarSesion({ commit }) {
      commit('setUser', null)
      localStorage.removeItem('usuario');//Elimina usuario de local Storage
      router.push('/');
    },
    async ingresoUsuario({ commit }, usuario) {
      /*console.log('store - actions');
      console.log(usuario);
      console.log(usuario.accessToken);
      console.log(usuario.email);
      console.log(usuario.uid);*/
      commit('setUser', usuario);
      localStorage.setItem('usuario', JSON.stringify(usuario));
    },
    detectaCambiosDCMFGestion({ commit }, dato) {
      console.log('dato', dato.estado);//true or false
      commit('setCambiosPanelDCM', dato.estado);
    }
  },
  getters: {
    usuarioAutenticado(state) {
      return !!state.user
    }
  },
  modules: {
  }
})
