<template>
  <div v-show="rolUsuario == 'acm' || rolUsuario == 'g'">
    <v-toolbar dark dense>
      <v-toolbar-title>Gráfico Titulares </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>

      <v-spacer></v-spacer>
      <v-btn icon @click="getAsignaciones()">
        <v-icon>mdi-update</v-icon>
      </v-btn>
    </v-toolbar>
    <div>
      <Radar
        :chartData="data"
        :chartOptions="options"
        :width="500"
        :height="500"
      />
    </div>
  </div>
</template>

<script>
import { firebase, db, storage } from "../../../../firebase/config";
import { Radar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  RadialLinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  RadialLinearScale,
  LineElement
);

export default {
  name: "gestionAnalisisBolsaACM",
  components: {
    Radar,
  },
  props: ["rolUsuario", "idUsuario"],
  data() {
    const max = 100; // Valor por defecto
    return {
      data: {
        labels: [],
        datasets: [
          {
            label: "Porcentaje",
            data: [],
            backgroundColor: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          r: {
            min: 0,
            max: max, //100
            ticks: {
              stepSize: 20,
            },
          },
        },
      },
      asignaciones:[],
      informacionRecopilada: [],
    };
  },
  mounted() {
    this.getAsignaciones();
  },
  methods: {
    async getAsignaciones() {
      const asignacionesRef = db
        .collection("cf-cartera")
        .doc("Asignaciones")
        .collection(this.idUsuario);
      const querySnapshot = await asignacionesRef.get();
      this.asignaciones = [];
      querySnapshot.forEach((doc) => {
        this.asignaciones.push(doc.data().tipiUlti);
        this.informacionRecopilada.push(doc.data());
      });
      const counts = {};
      for (const tipiUlti of this.asignaciones) {
        counts[tipiUlti] = counts[tipiUlti] ? counts[tipiUlti] + 1 : 1;
      }
      const total = this.asignaciones.length;
      const porcentajes = Object.values(counts).map((count) => {
        return (count / total) * 100;
      });
      this.data.labels = Object.keys(counts);
      this.data.datasets[0].data = porcentajes;
      this.data.datasets[0].backgroundColor = [
        "rgba(179,181,198,0.2)",
        "rgba(255,99,132,0.2)",
        "rgba(255,205,86,0.2)",
        "rgba(75,192,192,0.2)",
        "rgba(54,162,235,0.2)",
      ];

      const nuevoMax = Math.max(...this.data.datasets[0].data);
      this.options.scales.r.max = nuevoMax;
      this.enviarDatosPadre();
    },
    actualizarDatos() {
      this.getAsignaciones();
    },
    enviarDatosPadre(){
      //console.log("desde analisisBolsa.vue");
      this.$emit('aigrafica', this.asignaciones); // emitimos el evento personalizado con un mensaje

      
    }
  },
};
</script>
