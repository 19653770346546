import { firebase, db, storage } from "../../../firebase/config"

const ref = storage.ref();

//Listado de nits
export async function FBverClientesNit() {
    let data = [];
    try {
        const clientesRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const snapshot = await clientesRef.where('data.estado', '==', true).get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            //False no existe
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            data.push(doc.data().data.nit);
        });

        //True es existe
        return data;

    } catch (error) {
        console.log(error);
    }

}

//Encontrar valor Array 01 Para organizaTitularActual
async function localizaTitularInterno(bolsa, localizar) {
    //console.log(bolsa);
    //console.log(localizar);
    let index = bolsa.findIndex((el) => {
        return el.idRegistro == localizar.idRegistro; // or el.nombre=='T NORTE';
    });
    //console.log(index);
    //console.warn(localizar);
    return [index, localizar];
}

//Analizamos los titulares asignados.
export async function organizaTitularActual(bolsa) {
    //------------Se toma 1 titular al azar
    //console.log('Total en bolsa: ', bolsa.length);//Traer toda la consulta
    let numeroMaximo = 20; //Tramo por tocar
    let arrayFinal = [];
    let sinTocar = _.remove(bolsa, (n) => {
        //return n % 2 == 0;
        //console.log(n.toques);
        return n.toques == 0
    });
    //console.log(sinTocar);
    //console.log('sinTocar: ', sinTocar.length);

    let conFecha = _.remove(bolsa, (n) => {
        //return n % 2 == 0;
        //console.log(n.proxToque);
        return n.proxToque != null;
    });
    //console.log(conFecha);
    //console.log('conFecha: ', conFecha.length);

    var conFechaOrderBy = _.orderBy(conFecha, 'proxToque', 'desc');
    var llamarOtroDia = [];

    //console.log(conFechaOrderBy);
    //console.log('-------------------------');
    //primera version 70/30 ahora es 50/50
    //Entre más alto el numero multiplicador más para llamar hoy va a traer
    let llamarHoy = (numeroMaximo * 15) / 100;//16 en caso que sea 20 

    //15 es el equivalente de 3 titulares con respecto a 20 por gestionar



    //console.log('Llamar para hoy: ', llamarHoy);
    //0. Los URGENTE por llamar HOY, y los agrega a la bolsa.
    for (const datos of conFechaOrderBy) {
        //timeTodate
        //console.log('|----------INICIO---------|');
        var fechaRegistro = await timeTodate(datos.proxToque);

        var d = new Date(fechaRegistro);
        //console.log(d);
        var fechaTitularContacto = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
        var hoy = new Date();
        var anoHoy = hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate()

        //console.log(fechaTitularContacto);
        //console.log(anoHoy);
        //console.log('Cuadrando fechas....');
        //console.log('Cupo para llamar hoy:', arrayFinal.length);

        if (anoHoy == fechaTitularContacto) {
            //Pero no todo va hacer para hoy
            //Llega a 10 máximo o al % configurado
            // MODIFICACIÓN SE HACE EL 14 DE SEP 2022 PARA NO LIMITAR LOS QUE TIENEN LA FECHA DE HOY.
            //if (arrayFinal.length < llamarHoy) {
            //console.log('Llamar para hoy');
            arrayFinal.push(datos);
            //console.log(datos);
            //} else {
            //Ya tiene más del 50% en la bolsa
            //Toca surtidito...!
            //console.log('va a salir... superó el 50% de capacidad de la bolsa');
            //}
        } else {
            //console.log('Para llamar otro día-------------------------------');
            //if (llamarOtroDia.length > 20) {
            // MODIFICACIÓN SE HACE EL 14 DE SEP 2022 PARA NO LIMITAR LOS QUE TIENEN LA FECHA DE HOY.
            //-------------------------------////////////////////////-----------------------------------------------------------------------2022 SEP
            if (llamarOtroDia.length > 2) {
                //console.log('LLevamos más de 20 / ', llamarOtroDia.length);
                //return true;//Se suspende 25 de Febrero 
            } else {
                //console.log('Llevamos: ', llamarOtroDia.length);
                //console.log('Llama otro día');
                //console.log(datos);
                llamarOtroDia.push(datos); //Lo modifqué porque no estaba funcionando.

            }
        }

        //console.log('Análisis terminado por fecha.');

    };//termina 

    //console.log('Total para llamar hoy: ', arrayFinal.length);
    //console.log(arrayFinal.length);
    /*console.warn('SinTocar y Tocados vs Llamar hoy');
    console.log(arrayFinal.length);
    console.log(arrayFinal);
    console.log('-----**-----');*/
    //Lo Organicé de manera desc porque le da prioridad a los mas tocados.
    /*for (let index = 0; index < llamarOtroDia.length; index++) {
        const element = llamarOtroDia[index];
        console.log(element);
    }*/

    let llamarOtroDiaOrderBy = _.orderBy(llamarOtroDia, 'proxToque.seconds', 'asc');//desc

    let titularesPendientes = numeroMaximo - arrayFinal.length;
    let totalLlamarhoy = arrayFinal.length; //Los agregados hasta aquí
    /*console.log('Para llamar hoy: ', arrayFinal.length);
    console.log('Titulares sinTocar: ', sinTocar.length);
    console.log('Para llamar otro día desc: ', llamarOtroDiaOrderBy.length);
    console.log(llamarOtroDiaOrderBy);
    console.log('Posiciones por llenar: ', titularesPendientes);
    console.log('//--------NUEVOS HOY----------//');*/

    //console.log('Para llamar otro día: ', llamarOtroDiaOrderBy);

    //Si no se llenó el cupo de para llamar hoy vamos a llenar los faltantes con la bolsa de llamarOtroDiaOrdenBy
    if (totalLlamarhoy < llamarHoy) {
        //Cuanto falta?
        let faltante = llamarHoy - totalLlamarhoy;

        //console.log('Faltan: ', faltante);
        //Debemos llenar las posiciones
        for (let index = 0; index < faltante; index++) {
            const element = llamarOtroDiaOrderBy[index];
            if (element != undefined) {
                arrayFinal.push(element);
            }
        }
    }

    //console.log('Total para llamar, entre hoy y otras fechas:' + arrayFinal.length);
    //console.log('Posiciones por llenar: ', titularesPendientes);

    //console.log(arrayFinal);
    //0. Vamos a llenar el 20% que hace falta 
    //1. SinTocar
    //console.log("Sin tocar");
    //console.log(sinTocar.length);
    if (sinTocar.length > 0) {
        //console.log('sinTocar > 0 *************************');
        //Si sinTocar es mayor a 0 entre
        for (let index = 0; index < titularesPendientes;) {
            //1. Se necesita recorrer de manera aleatoria 
            const noRepetido = await localizaTitularInterno(arrayFinal, _.sample(sinTocar))
            //console.log('Index: ', index);
            //console.log('Consultado: ', noRepetido);
            //-1 no existe, es nuevo
            if (noRepetido[0] == -1) {
                //console.log('Nuevo');
                if (arrayFinal.length < titularesPendientes) {
                    arrayFinal.push(noRepetido[1]);
                    index++
                    noRepetido;
                } else {
                    index = titularesPendientes;
                }
            } else {
                //console.log('Ya existe, tomar otro. ');
                //noRepetido;
                if (sinTocar.length <= index) {
                    //console.log('Se acabaron las posiciones. ');
                    index = titularesPendientes;
                } else {
                    //console.log('Todavia hay posiciones.');
                    noRepetido;
                }
            }
        }
    } else {
        //console.log('Se acabaron los sinTocar*************************');
        //No existen Titulares sinTocar
        // No tenemos ninguno en 0, llenar con los de llamar otro dia.
        for (let index = 0; index < titularesPendientes;  index++) {
            
            //llamarOtroDiaOrderBy.length
            //1. Se necesita recorrer de manera aleatoria 
            const noRepetido = await localizaTitularInterno(arrayFinal, _.sample(llamarOtroDiaOrderBy))

            //-1 no existe, es nuevo
            if (noRepetido[0] == -1) {
                //console.log('Nuevo');
                arrayFinal.push(noRepetido[1]);
                //index++
                noRepetido;
            } else {
                //console.log('Ya existe, tomar otro. ');
                //noRepetido;
                if (llamarOtroDiaOrderBy.length <= index) {
                    //console.log('Se acabaron las posiciones. ');
                    index = titularesPendientes;
                } else {
                    //console.log('Todavia hay posiciones.');
                    noRepetido;
                }
            }
            //arrayFinal.push(llamarOtroDiaOrderBy[index]);
        }

    }

    //Organiza lo armado por orden de mayor toque a menor.
    //No sirve nada.
    //console.log('Llegando al final.');
    //console.log(arrayFinal);
    let arrayFinalOrderBy = _.orderBy(arrayFinal, 'toques', 'desc');
    // console.log(arrayFinalOrderBy);
    //console.warn('Termina proceso de Arreglo');
    return [arrayFinalOrderBy, totalLlamarhoy, sinTocar.length];
}

async function timeTodate(a) {
    //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.
    /*console.log('Entra a leer fecha');
    console.log(a);
    console.log('**********************************');
    console.log(new Date(a.seconds * 1000));*/

    if (typeof a == "object" && a != null) {
        //console.log(a);
        let time = {
            seconds: a.seconds,
            nanoseconds: a.nanoseconds,
        };

        const fireBaseTime = new Date(
            time.seconds * 1000
        );

        //const date = fireBaseTime.toDateString();
        //const atTime = fireBaseTime.toLocaleTimeString();
        //console.log("Es un Timespam");
        //console.log(date, atTime);
        //const resultado = date + " / " + atTime;
        //console.log(resultado);
        return new Date(fireBaseTime);
    } /**/
}