<template>
  <v-card class="mx-auto" max-width="344">
    <v-img
      src="https://img.freepik.com/vector-gratis/ilustracion-concepto-resumen-propiedades_114360-1500.jpg?w=1480&t=st=1692024595~exp=1692025195~hmac=f1dc2cdcc958f65d6fe547ac24275e6586ea75b580f60314542d42c68b69c216"
      height="200px"
    ></v-img>

    <v-card-title>Editar Producto </v-card-title>

    <v-card-subtitle>Podrás editar el producto que desees para tu catálogo, no olvides detener el SKU. </v-card-subtitle>

    <v-card-actions>
      <edita />

      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          Los prouductos aquí creados aparecerán en el catálogo de la empresa en tiempo real.
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Edita from "./Editar.vue";
export default {
  data: () => ({
    show: false,
  }),
  components: {
    Edita,
  },
};
</script>