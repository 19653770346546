<template>
  <div class="text-center" v-show="showPanelEditButton">
    <v-dialog
      transition="dialog"
      max-width="60%"
      v-model="dialogPanel"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          fab
          x-small
          color="grey lighten-1"
          v-bind="attrs"
          v-on="on"
          v-show="isRoleG"
        >
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <v-card v-if="loadModulo" color="black" dark>
        <v-card-text>
          Procesando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>

      <v-card shaped outlined hover v-else-if="!loadModulo">
        <!-- rol {{ rolPrueba }} -->
        <v-toolbar color="black"  dark>
          Administrar Módulo {{ datos.title }} 
        </v-toolbar>

        <v-container class="grey lighten-5">
          <v-row>
            <v-col cols="12" sm="6">
              <v-switch
                v-show="showSwitch('swAlert')"
                v-model="datos.alert"
                :label="switchLabel('s1', datos.alert)"
                @change="actualizaModulo()"
                inset
                color="amber"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-show="showSwitch('swClick')"
                v-model="datos.click"
                :label="switchLabel('s2', datos.click)"
                @change="actualizaModulo()"
                inset
                color="amber"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-show="showSwitch('swEstado')"
                v-model="datos.estado"
                :label="switchLabel('s3', datos.estado)"
                @change="actualizaModulo()"
                inset
                color="amber"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-show="showSwitch('swModalCard')"
                v-model="datos.modalCard"
                :label="switchLabel('s4', datos.modalCard)"
                @change="actualizaModulo()"
                inset
                color="amber"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-show="showSwitch('swMensaje', datos.title)"
                v-model="datos.verMensaje"
                :label="switchLabel('s5', datos.verMensaje)"
                @change="actualizaModulo()"
                inset
                color="amber"
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <v-slider
                v-model="datos.flex"
                :label="ex3.label"
                :thumb-color="ex3.color"
                thumb-label="always"
                max="12"
                min="1"
                @change="actualizaModulo()"
                inset
                color="amber"
              ></v-slider>
            </v-col>
            <v-col cols="12">
              <!-- acceso -->
              <v-select
                v-show="showSelect('acceso')"
                v-model="datos.roles"
                :items="items"
                item-text="clave"
                item-value="value"
                label="Acceso"
                attach
                chips
                multiple
                @change="actualizaModulo()"
                color="amber"
              ></v-select>
            </v-col>
            <v-col cols="6" v-show="showSwitch('swMensaje', datos.title)">
              <v-text-field
                label="Mensaje"
                v-model="datos.mensaje"
                @change="actualizaModulo()"

              ></v-text-field>
            </v-col>
            <v-col cols="6" v-show="showSwitch('imagen', datos.title)">
              <v-text-field
                label="Imágen"
                v-model="datos.src"
                @change="actualizaModulo()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-show="showSwitch('color', datos.title)">
              <p>Color panel</p>
              <v-text-field
                v-model="datos.color"
                v-mask="mask"
                hide-details
                class="ma-0 pa-0"
                solo
                @change="actualizaModulo()"
              >
                <template v-slot:append>
                  <v-menu
                    v-if="datos && datos.color"
                    v-model="menu"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="datos.color" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions class="justify-end">
          <v-btn text @click="dialogPanel = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../../../firebase/config";
import { mask } from "vue-the-mask";
import { FBtraeRoles } from "../../../components/Roles";

export default {
  name: "editarModulos",
  props: ["datos", "modulos", "rolPrueba"],
  directives: { mask },
  data: () => ({
    loadModulo: false,
    dialogPanel: false,
    ex3: { label: "Diseño (Tamaño panel)", color: "red" },
    color: "#1976D2FF",
    mask: "!#XXXXXXXX",
    menu: false,
    items: [],
  }),
  async mounted() {
    const rolesData = await FBtraeRoles();
    this.items = Object.entries(rolesData.datos).map(([clave, value]) => ({
      clave,
      value,
    }));
  },
  methods: {
    async actualizaModulo() {
      try {
        this.loadModulo = true;
        const moduloRef = db.collection("recursos").doc("modulos");
        const res = await moduloRef.update(this.modulos);

        if (!res) {
          this.loadModulo = false;
        }

        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    switchLabel(a, b) {
      switch (a) {
        case "s1":
          return b ? "Alerta activa" : "Alerta desactivada";
        case "s2":
          return b ? "Click activo" : "Click desactivado";
        case "s3":
          return b ? "Estado activo" : "Estado desactivado";
        case "s4":
          return b ? "ModalCard activo" : "ModalCard desactivado";
        case "s5":
          return b ? "Mensaje activo" : "Mensaje desactivado";
        default:
          break;
      }
    },
    showSwitch(a) {
      if (this.rolPrueba === "g") {
        return true;
      }
      return false;
    },
    showSelect(a) {
      if (this.rolPrueba === "g") {
        return true;
      }
      return false;
    },
    showPanelEditButton() {
      return this.isRoleG;
    },
    showSwitch(a, b) {
      if (this.rolPrueba === "g") {
        return true;
      } else if (
        (this.rolPrueba === "dcm" && b === "Cartera") ||
        (this.rolPrueba === "s" && b === "Secretaria") ||
        (this.rolPrueba === "t" && b === "Localizador")
      ) {
        return true;
      }
      return false;
    },
    showField(a, b) {
      if (
        (this.rolPrueba === "dcm" && b === "Cartera") ||
        (this.rolPrueba === "s" && b === "Secretaria") ||
        (this.rolPrueba === "t" && b === "Localizador") ||
        this.rolPrueba === "g"
      ) {
        return true;
      }
      return false;
    },
  },
  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    isRoleG() {
      return this.rolPrueba === "g";
    },
  },
};
</script>
