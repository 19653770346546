<template>
  <div class="text-center">
    <v-progress-circular
      v-if="datosCliente == null"
      indeterminate
      color="blue-grey darken-1"
    ></v-progress-circular>

    <v-expansion-panels v-else>
      <v-expansion-panel class="blue-grey darken-3">
        <v-expansion-panel-header @click="LlamaDatosCliente">
          PDF Acuerdo de Pago
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <!-- 
            Por analizar 
            @progress="onProgress($event)"
            -->
          <vue-html2pdf
            :show-layout="true"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="
              titularActual.data.Nombre + '-' + titularActual.data.Cedula
            "
            :pdf-quality="1"
            :manual-pagination="false"
            pdf-format="letter"
            pdf-orientation="portrait"
            pdf-content-width="auto"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <div style="margin-top: 35px; font-size: 16"></div>
              <div class="pdfAP" style="margin-left: 30px">
                <div style="text-align: center; margin-top: -30px">
                  <img
                    :draggable="false"
                    src="../../../../assets/Logo-1-cabecera-acuerdodepago-612_82.jpg"
                  />
                </div>
                <div style="text-align: center; margin-top: 5px; padding: 1em">
                  <h3>Acuerdo de Pago</h3>
                </div>
                <div
                  style="text-align: justify; margin-right: 1em; margin-top: 5px padding: 1em"
                >
                  <p>
                    Entre {{ titularActual.data.Nombre }} con documento de
                    identificación N° {{ titularActual.data.Cedula }} y BYB
                    Asesores Jurídicos S.A.S con Nit 901.554.354-8, Se ha
                    llegado al siguiente acuerdo con el propósito de saldar la
                    obligación con {{ this.datosCliente.cliente }}.
                    <br />
                    <br />
                    <span> PRIMERA:</span>
                    Se etableció acuerdo de pago total por valor de
                    {{ decimales(Math.floor(interesesDescuento + monto)) }} para
                    quedar a PAZ Y SALVO en
                    {{ textoNumero(acuerdos.length) }} ({{ acuerdos.length }})
                    cuota (s) (Si, y sólo si se cancelan en la fecha estipulada)
                    y se cancelará como se describe a continuación para
                    suspender trámite ejecutivo y de embargo.
                  </p>
                </div>

                <div
                  style="
                    text-align: center;
                    padding: 0 1rem;
                    justify-content: center;
                    margin-top: 10px;
                  "
                >
                  <div style="width: 100%">
                    <table
                      style="
                        border-collapse: collapse;
                        border-radius: 1em;
                        overflow: hidden;
                        width: 90%;
                        padding: 1em;
                        font-size: 12px;
                      "
                    >
                      <tr>
                        <th style="background-color: #244492; color: #ffffff">
                          Nº Cuota
                        </th>
                        <th style="background-color: #244492; color: #ffffff">
                          Fecha de Pago
                        </th>
                        <th style="background-color: #244492; color: #ffffff">
                          Valor a Consignar
                        </th>
                      </tr>
                      <tr v-for="(item, index, key) in acuerdos" :key="key">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item }}</td>
                        <td style="text-align: center">
                          {{ decimales(totalCuota) }}
                        </td>
                      </tr>
                    </table>

                    <div style="padding: 0em"></div>

                    <table
                      style="
                        border-collapse: collapse;
                        border-radius: 1em;
                        overflow: hidden;
                        width: 90%;
                        padding: 1em;
                        font-size: 12px;
                      "
                    >
                      <tr>
                        <td style="background-color: transparent"></td>
                        <td
                          style="
                            background-color: #244492;
                            color: #ffffff;
                            text-align: center;
                            border-bottom-left-radius: 1em;
                          "
                        >
                          Total
                        </td>
                        <td
                          style="
                            text-align: center;
                            background-color: #244492;
                            color: #ffffff;
                            border-top-right-radius: none;
                          "
                        >
                          {{
                            decimales(Math.floor(interesesDescuento + monto))
                          }}
                        </td>
                      </tr>

                      <tr></tr>
                    </table>
                  </div>
                </div>
                <div
                  style="text-align: justify; margin-right: 1em; margin-top: 5px padding: 1em"
                >
                  <p>
                    <br />
                    <span class="font-weight-black"> SEGUNDA:</span>
                    En caso de cumplir con el acuerdo con las condiciones
                    especificadas anteriormente,
                    {{ this.datosCliente.cliente }} se compromete que una vez
                    reflejado el pago en las cuentas de la entidad enviará
                    actualización a centrales de información Data crédito
                    manifestando PAZ Y SALVO de la obligación.
                    <br />
                    <br />
                    <span class="font-weight-black"> TERCERO:</span>
                    Este acuerdo no constituye novación, extinción, sustitución
                    ni renuncia de la obligación y en eventual caso de no llegar
                    a cumplir el compromiso en las condiciones o términos
                    señalados arriba, este acuerdo quedará sin efecto, perderá
                    los beneficios y se reversan los descuentos ortorgados por
                    la entidad y prestará mérito ejecutivo.

                    <br />
                    <br />
                    <span class="font-weight-black"> CUARTO:</span>
                    En caso de incumplimiento, los pagos realizados se tomarán
                    como pago al acuerdo inicial cancelando primero intereses y
                    luego capital.
                    <br />
                    <br />
                    <span class="font-weight-black"> QUINTO:</span>
                    BYB Asesores Jurídicos S.A.S con Nit 901.554.354-8, se
                    compromete a llevar el acuerdo realizado al juzgado donde se
                    encuentra radicado, en caso que esta obligación sea parte de
                    un proceso, como a su vez acompañará un memorial donde
                    solicite mantener el proceso en esta instancia.
                    <br />
                    <br />
                    <span class="font-weight-black"> SEXTO:</span>
                    Los pagos se realizarán a través de las cuentas adjuntas a
                    este documento.
                    <br />
                    <br />
                    Su PAZ Y SALVO se genera a los 5 días hábiles posterior al
                    pago TOTAL de la obligación.
                    <br />
                    <br />

                    Se firma el presente documento por quienes en ella
                    participan, a los {{ hoyFecha }} en la ciudad de Bogotá.
                  </p>
                </div>

                <br />
                <br />

                <img :draggable="false" src="../../../../assets/firma-2.jpg" />
                <br />

                <footer>
                  <img
                    :draggable="false"
                    src="../../../../assets/Logo-1-footer-612_82.jpg"
                  />
                </footer>
              </div>
            </section>
          </vue-html2pdf>

          <v-btn @click="generateReport">Generar PDF</v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
    


<script>
import { JSdecimales } from "../../../../components/Cartera/validacionesGestionC";
import { FBTodosLosDatosCliente } from "../../../../components/Cartera/gestionaCartera";

import VueHtml2pdf from "vue-html2pdf";

import { jsPDF } from "jspdf";

const doc = new jsPDF({
  orientation: "p",
  unit: "px",
  format: "legal",
  /*putOnlyUsedFonts: true,
  compress: true,*/
  hotfixes: ["px_scaling"],
  precision: 5,
});

export default {
  name: "pdfAcuerdoPagovDos",
  props: ["acuerdos", "interesesDescuento", "monto", "titularActual"],
  data: () => ({
    base: true,
    logoA: require("../../../../assets/Logo-1-cabecera-acuerdodepago-612_82.jpg"),
    totalCuota: 0,
    hoyFecha: null,
    meses: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    datosCliente: null,
  }),
  components: {
    VueHtml2pdf,
  },
  mounted() {
    this.fechaHoy();
    this.LlamaDatosCliente();
    //console.log(this.titularActual);
  },
  updated() {
    //this.LlamaDatosCliente();
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async LlamaDatosCliente() {
      //console.log("Actualiza datos clientes");
      const clienteLlega = await FBTodosLosDatosCliente(
        this.titularActual.nitCliente
      );
      //console.log(clienteLlega);
      this.datosCliente = clienteLlega[0].data.data;
      //console.log(this.datosCliente);
    },
    GeneraPDF() {
      const logo = require("../../../../assets/Logo-1-footer-612_82.jpg");
      const firmas = require("../../../../assets/firma-2.jpg");
      var source = this.$refs.testHtml;
      var cabecera_01 = new Image();
      var firmas_02 = new Image();
      firmas_02.src = firmas;
      cabecera_01.src = logo; //"../../../assets/Logo-1-cabecera-acuerdodepago.jpg";
      doc.html(source, {
        callback: (pdf) => {
          //pdf.addImage(cabecera_01, "JPEG", 20, 20);

          var hoy = new Date();
          var transcurso = hoy.getTime();
          //console.log(transcurso);
          //var convertir = new Date(transcurso);
          //console.log(convertir);

          /*pdf.text(
            pdf.internal.pageSize.getWidth() - 250,
            pdf.internal.pageSize.getHeight() - 120,
            "AP-" +
              this.titularActual.idRegistro.slice(3, -2) +
              "/" +
              transcurso
          );*/

          var aA = pdf.internal.pageSize.getWidth() - 760; //horizaontal
          var bB = pdf.internal.pageSize.getHeight() - 300; //Vertical

          pdf.addImage(firmas_02, "JPEG", aA, bB);

          var a = pdf.internal.pageSize.getWidth() - 700;
          var b = pdf.internal.pageSize.getHeight() - 100;

          pdf.addImage(cabecera_01, "JPEG", a, b);

          //pdf.output("dataurlnewwindow"); //Abre de una el navegador con el pdf
          pdf.save("output.pdf"); //Pide permiso para guardar el pdf para verlo.
        },
      });
    },
    fechaHoy() {
      var text = "27 días del mes de ENERO del año 2022";
      var fechaHoy = new Date();

      var textoFinalFecha =
        fechaHoy.getDate() +
        " días del mes de " +
        this.meses[fechaHoy.getMonth()] +
        " del año " +
        fechaHoy.getFullYear();

      //console.log(textoFinalFecha);
      this.hoyFecha = textoFinalFecha;
      return textoFinalFecha;
    },
    decimales(a) {
      //console.log(a);
      const decimalesLlega = JSdecimales(a);

      return decimalesLlega;
    },
    textoNumero(a) {
      var texto = [
        "cero",
        "una",
        "dos",
        "tres",
        "cuatro",
        "cinco",
        "seis",
        "siete",
        "ocho",
        "nueve",
        "diez",
        "once",
        "doce",
        "trece",
        "catorce",
        "quince",
        "dieciséis",
        "diecisiete",
        "dieciocho",
        "diecinueve",
        "veinte",
        "veintiuno",
        "veintidós",
        "veintitrés",
        "veinticuatro",
      ];
      return texto[a];
    },
  },
  watch: {
    acuerdos() {
      //console.log("Cambio de valor");
      //console.log(this.acuerdos.length);
      this.totalCuota = Math.floor(
        (this.interesesDescuento + this.monto) / this.acuerdos.length
      );
    },
  },
};
</script>

<style scoped>
.pdfAP {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 9;
  /*
  margin-top: 1px;
  margin-left: 1px;
  
  padding: 1em;*/
  width: 90%;
  text-align: center;
}
p {
  font-size: 10px;
}

/*
table {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  width: 90%;
  padding: 1em;
}

*/

td,
th {
  border-radius: 1em;
  background-color: #ffffff;

  color: rgb(0, 0, 0);
  padding: 0em;
  /*background: #ddd;
  border-bottom: 0px solid rgb(185, 185, 185); */
  width: 33%;
  text-align: center;
  border-bottom: 1px solid rgb(185, 185, 185);
}

footer {
  /* footer sample 
  height: 150px;*/
  /* background-color: #223;*/
  left: 0;
  bottom: 0;
  width: 100%;
  /*background-color: red;*/
  color: white;
  text-align: center;
  margin-top: -15px;
}
</style>
