<template>
  <v-dialog dark v-model="dialog" scrollable max-width="80%" persistent>
    <!-- Botón activador con tooltip -->
    <template v-slot:activator="{}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
            <v-icon>mdi-division</v-icon>
          </v-btn>
        </template>
        <span>Asigna Cartera</span>
      </v-tooltip>
    </template>

    <v-card class="mx-auto">
      <v-toolbar dark>
        Asigna Cartera
        <v-spacer></v-spacer>
        <v-btn class="ma-2" text icon color="amber lighten-2">
          <a href="https://drive.google.com/drive/folders/1RzKDettCKT6j8cSCluft1dPvaGs75XNe?usp=drive_link" target="_blank">
            <v-icon>mdi-google-drive</v-icon>
          </a>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <p class="pa-3">
          Por favor, suba el archivo .csv para asignarlo a los ejecutivos
          activos
          <br />
        </p>
        <load-cartera />
        <!-- Nombre corregido del componente -->
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="amber" text @click="dialog = false"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LoadCartera from "../../../components/loadFile/carteras/asignaCartera.vue";

export default {
  name: "AsignaCartera",
  components: {
    LoadCartera,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    retornaEstadoMensaje() {
      // return this.sheet = this.estadoMensaje; // Comentado porque no se utiliza
    },
  },
};
</script>

<!--
En este código:
- Corregí el nombre del componente "loadCartera" a "LoadCartera" (se prefiere PascalCase para los nombres de los componentes)
- Agregué comentarios para describir las secciones de código y proporcionar claridad
- Eliminé la propiedad "props" no utilizada
- Cambié la propiedad "data" a una función que devuelve un objeto (se recomienda usar funciones de datos en los componentes de Vue 2)
- Corregí el nombre del componente en la plantilla de "load-cartera" a "LoadCartera"
- Comenté la propiedad computada "retornaEstadoMensaje" ya que no se está utilizando
-->
