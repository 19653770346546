import { db } from "../../firebase/config";

export async function FBbuscadorClienteNityRazonSocial(datoAbuscar) {
  try {
    if (datoAbuscar === null) {
      // Si datoAbuscar es null, retornar un array vacío
      return [];
    }
    // Obtener una referencia a la colección
    const collectionRef = db.collection('cf-cartera/Clientes/Listado');

    // Convertir el dato de búsqueda a minúsculas o mayúsculas
    const datoAbuscarLowerCase = datoAbuscar.toLowerCase();

    // Realizar la consulta
    const querySnapshot = await collectionRef
      //.where('data.nit', '==', datoAbuscarLowerCase)
      .get();

    // Array para almacenar los documentos encontrados
    const documentosEncontrados = [];

    // Recorrer los resultados de la consulta
    querySnapshot.forEach((doc) => {
      // Obtener los datos del documento y agregarlo al array
      const documento = doc.data();
      const idDocumento = doc.id;
      documento.id = idDocumento; // Agregar el ID del documento al objeto `documento`
      documentosEncontrados.push(documento);
    });

    // Si no se encontraron resultados y datoAbuscar es un string,
    // realizar una segunda consulta utilizando data.cliente
    if (documentosEncontrados.length === 0 && typeof datoAbuscar === 'string') {
      const secondQuerySnapshot = await collectionRef.get();

      secondQuerySnapshot.forEach((doc) => {
        const documento = doc.data();
        const idDocumento = doc.id;
        documento.id = idDocumento; // Agregar el ID del documento al objeto `documento`
        const clienteLowerCase = documento.data.cliente.toLowerCase();

        // Verificar si el nombre del cliente contiene el dato buscado
        if (clienteLowerCase.includes(datoAbuscarLowerCase)) {
          documentosEncontrados.push(documento);
        }
      });
    }

    // Retornar los documentos encontrados
    //console.log(documentosEncontrados);
    return documentosEncontrados;
  } catch (error) {
    console.error('Error al buscar documentos:', error);
    throw error;
  }
}

