import { db } from "../../firebase/config";

// Trae los módulos

export async function FBtraeModulos() {
  try {
    const modulosRef = db.collection('recursos').doc('modulos');
    let doc = await modulosRef.get();

    if (!doc.exists) {
      await modulosRef.set({ cards: modulosData });
      doc = await modulosRef.get(); // Obtener el documento nuevamente después de crearlo.
    }

    return doc.data();

  } catch (error) {
    console.error('Error al obtener o crear los módulos:', error);
    return false;
  }
}

/*export async function FBtraeModulos() {
  try {
    const modulosRef = db.collection('recursos').doc('modulos');
    const doc = await modulosRef.get();

    if (doc.exists) {
      return doc.data();
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}*/

const modulosData = [
  {
    badge: 2,
    click: false,
    coleccion: "cf-localizadores",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd'],
    src: "https://selectra.mx/sites/selectra.mx/files/styles/article_hero/public/images/localizar-telefono-825x293.png?itok=n4n3OHV9",
    title: "Localizadores",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-contabilidad",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd', 'dcm', 'acm', 'acd'],
    src: "",
    title: "Contabilidad",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-juridico",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd', 'j'],
    src: "",
    title: "Jurídico",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-secretaria",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd', 's'],
    src: "https://cdn.pixabay.com/photo/2021/09/10/18/39/secretary-6613923_960_720.png",
    title: "Secretaria",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-recursoHumano",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd', 'acm', 'rh', 'mk'],
    src: "https://cdn.pixabay.com/photo/2017/08/02/00/49/people-2569234_960_720.jpg",
    title: "Recurso Humano",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-administrativo",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd', 'adm'],
    src: "https://img.freepik.com/vector-gratis/ilustracion-concepto-online_114360-1009.jpg?w=740",
    title: "Administrativo",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-mercadeo",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd', 'mk'],
    src: "https://img.freepik.com/vector-gratis/diseno-fondo-negocios_1300-345.jpg?w=1380&t=st=1686704621~exp=1686705221~hmac=ceb85b60902732967f8d23ae40e12e9ffb11e9ef2fd2cdc665e7c17de1b77f3c",
    title: "Mercadeo y Publicidad",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-pesv",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd', 'pesv'],
    src: "https://img.freepik.com/vector-gratis/ilustracion-concepto-policia-transito_114360-12602.jpg?w=1380&t=st=1686704677~exp=1686705277~hmac=553ed030f53f0a6a84ec8f6e77964fdcdb365edac25489941b8a3f05a9613235",
    title: "PESV",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-ventas",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd', 'ven'],
    src: "https://img.freepik.com/vector-gratis/ilustracion-concepto-asociacion_114360-5954.jpg?w=1800&t=st=1686705311~exp=1686705911~hmac=d12a296c8256f14e023e6169a48f44bb07bbf83f741af4177c1a9656fdf6e3fd",
    title: "Negocios",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-planeacion",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd'],
    src: "https://img.freepik.com/vector-gratis/concepto-estrategia-empresarial-dibujada-mano_23-2149158212.jpg?w=1800&t=st=1686949268~exp=1686949868~hmac=53add38de404832f645a7b406c3f55b9adae0f57d125183443bae4ab2806e6bc",
    title: "Planificacion y Control",
    verMensaje: false,
    visible: true
  },
  {
    badge: 2,
    click: false,
    coleccion: "cf-produccion",
    color: "#E2E2E2FF",
    estado: true,
    flex: 4,
    mensaje: "",
    modalCard: false,
    roles: ['g', 'd'],
    src: "https://img.freepik.com/free-vector/processing-concept-illustration_114360-410.jpg?w=2000&t=st=1690985858~exp=1690986458~hmac=3b5b0501f5e788df03ee8b618e90de4112cdde6282c95b7108933e749c6d1d48",
    title: "Producción",
    verMensaje: false,
    visible: true
  }
];



