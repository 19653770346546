<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <!-- v-show="!hidden" -->
          <v-btn
            color="warning"
            v-bind="attrs"
            v-on="on"
            fab
            dark
            small
            absolute
            bottom
            left
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Requerimiento Especial
        </v-card-title>

        <v-card-text>
          Titular: {{ observacionesModal.data.Nombre }}
          <br />
          En construcción.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "agregaobservaciones",
  props: ["observacionesModal"],
  data: () => ({
    dialog: false,
  }),
};
</script>
