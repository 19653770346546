<template>
  <v-container>
    <v-row align="center" justify="center" no-gutters>
      <v-col
        class="pa-3"
        cols="12"
        sm="8"
        md="6"
        lg="4"
        order="1"
        order-lg-first
      >
        <!-- -->
        <div class="account-wall pa-3">
          <h1 class="text-center login-title">
            Sistema de Gestión Beraca Grupo Empresarial<br />
            AI 1.1
          </h1>

          <img
            class="profile-img"
            src="https://firebasestorage.googleapis.com/v0/b/bybabogados-138f2.appspot.com/o/Imagenes%2Flogo%2Flogo-beraca_negro.png?alt=media&token=5926506b-2184-4929-9049-0fca44670339"
            alt="Beraca Grupo Empresarial Sistema de Gestion"
          />

          <div class="form-signin text-center">
            <template>
              <v-progress-circular
                :size="50"
                color="red"
                indeterminate
                v-if="estado"
              ></v-progress-circular>

              <div v-if="!estado">
                <v-text-field
                  type="text"
                  label="Correo"
                  outlined
                  filled
                  v-model="correo"
                  block
                  color="teal darken-4"
                ></v-text-field>

                <v-text-field
                  label="Contraseña"
                  outlined
                  filled
                  block
                  v-model="contra"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  color="teal darken-4"
                ></v-text-field>

                <v-btn
                  block
                  v-on:click="validarFormulario()"
                  :disabled="estado"
                  class="mt-3"
                  color="black"
                  dark
                >
                  Ingresar
                </v-btn>
              </div>
              <div class="p-3 login-title">
                {{ respuesta }}
              </div>
            </template>
          </div>
          <span class="text-center login-title mt-n5">versión 1.2 - W 1.1 </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { FBvalidarUsuario } from "../../components/Auth/index";

export default {
  name: "ingreso",
  data() {
    return {
      correo: "",
      contra: "",
      respuesta: "", //respuesta del servidor
      conteo: 3000, //tiempo que dura en volver a disponer los campos y botones
      estado: false, //mostrar o ocultar el btn
      show1: false,
    };
  },
  mounted() {},
  props: {
    //msg: String,
  },
  components: {},
  computed: {},
  methods: {
    ...mapActions(["ingresoUsuario"]),
    async validarFormulario() {
      this.conteo = Math.floor(Math.random() * (6000 - 3000 + 1)) + 3000; //Tiempo aleatorio
      this.estado = true;
      const result = await FBvalidarUsuario(this.correo, this.contra); //Va a Firebase para validar.

      if (result[0] && result[2] != undefined) {
        this.respuesta = "Estamos cargando tu contenido";
        this.contra = ""; //Vaciar la contraseña
        this.correo = ""; //vaciar el usuario

        this.ingresoUsuario(result); //Envia los datos a la tienda store

        setTimeout(
          () => (this.$router.push("/i"), (this.estado = true)),
          this.conteo
        );
      } else {
        this.estado = true;
        this.respuesta = "Usuario con datos errados.";
        this.contra = ""; //Vaciar la contraseña
        this.correo = ""; //vaciar el usuario
        setTimeout(
          () => ((this.respuesta = ""), (this.estado = false)),
          this.conteo
        );
      }
    },
  },
};
</script>

<style scoped>
.account-wall {
  max-width: 400px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 20px;
}

.profile-img {
  width: 100px;
  height: 100px;
  margin: 0 auto 10px;
  display: block;
  border-radius: 50%;
}

.login-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.form-signin {
  max-width: 300px;
  margin: 0 auto;
}

.mt-3 {
  margin-top: 1rem;
}

/*Diseño login google */
.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.account-wall {
  margin-top: 20px;
  padding: 40px 0px 20px 0px;
  background-color: #f0f0f0;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  height: "100%";
}
.login-title {
  color: #424242;
  font-size: 12px;
  font-weight: 400;
  display: block;
}
.profile-img {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.need-help {
  margin-top: 10px;
}
.new-account {
  display: block;
  margin-top: 10px;
}
/*END Diseño login google */
</style>