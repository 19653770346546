<template>
  <div v-show="rolUsuario == 'acm' || rolUsuario == 'g'">
    <v-toolbar dark dense>
      <v-toolbar-title>Llamar Hoy</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>

      <v-spacer></v-spacer>
      <v-btn @click="consultaBase()" icon>
        <v-icon>mdi-update</v-icon>
      </v-btn>
    </v-toolbar>
    <v-simple-table height="100%">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Cédula</th>
            <th class="text-left">Tipificación</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in desserts" :key="item.name">
            <td>{{ item.Cedula }}</td>
            <td>{{ item.tipiUlti }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
        
<script>
import { FBLlamarHoy } from "../../../../components/Cartera/panelesACM/consultasBasicas";
export default {
  name: "gestionHoyACM",
  components: {},
  props: ["rolUsuario", "idUsuario"],
  data: () => ({
    desserts: [
      {
        name: "Cargando...",
        calories: 0,
      },
    ],
  }),
  mounted() {
    this.consultaBase();
    //console.log(this.idUsuario);
  },
  methods: {
    async consultaBase() {
      this.desserts = [];
      const result = await FBLlamarHoy(this.idUsuario);
      //console.log(result);
      this.desserts = result;
    },
  },
};
</script>
