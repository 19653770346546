import { db, firebase, functions } from "@/firebase/config.js";
import { sendMensajeGeneral } from "@/Util/twilio/";
import moment from 'moment';
import axios from 'axios';
import { FBconsultaToken } from "./whatsapp/tokens";


function separarTextoCliente(texto) {
    const etiquetaCliente = "<cliente>";
    const indiceEtiqueta = texto.indexOf(etiquetaCliente);

    if (indiceEtiqueta !== -1) {
        const observacion = texto.substring(0, indiceEtiqueta).trim();
        const mensajeCliente = texto.substring(indiceEtiqueta + etiquetaCliente.length).trim();

        return { observacion, mensajeCliente };
    }

    // Si no se encuentra la etiqueta, se asigna todo el texto a "observacion"
    return { observacion: texto.trim(), mensajeCliente: "" };
}

function convertirFechaATimestamp(fecha) {
    const fechaObjetivo = moment(fecha, 'YYYY-MM-DD').toDate();
    const timestamp = firebase.firestore.Timestamp.fromDate(fechaObjetivo);
    return timestamp;
}


// Guardar en base de datos
async function saveMessageToDatabase(senderPhoneNumber, messaging, timestamp, contacts, doliente) {
    try {
        console.log(senderPhoneNumber, messaging, timestamp, contacts, doliente);
        const userRef = db.collection("cf-whatsapp").doc(senderPhoneNumber);

        // Obtener el documento existente o crear uno nuevo si no existe
        const doc = await userRef.get();

        if (doc.exists) {
            //Segundo mensaje en adelante
            const existingMessages = doc.data().messages;
            existingMessages.push({ body: messaging, timestamp: timestamp, usuario: doliente });
            const updatedMessages = existingMessages.sort((a, b) => a.timestamp - b.timestamp);
            await userRef.update({ messages: updatedMessages });
        } else {
            //Primera vez 
            await userRef.set({ messages: [{ body: messaging, timestamp: timestamp, usuario: doliente }], usuario: contacts });
        }
    } catch (error) {
        console.log('Error al guardar el mensaje en la base de datos:', error);
    }
}

async function sendMessage(telefonoSelect, contacto, doliente, correoSelect) {
    try {
        const phoneModificado = "57" + telefonoSelect

        // Numero TEST https://graph.facebook.com/v17.0/102503192897456/messages 
        const apiUrl =
            "https://graph.facebook.com/v17.0/103122939510904/messages";

        const TOKEN = await FBconsultaToken();

        const accessToken = TOKEN;

        //Plantilla
        /*const requestData = {
          messaging_product: "whatsapp",
          to: "573006386111",
          type: "template",
          template: {
            name: "hello_world",
            language: { code: "en_US" },
          },
        };*/

        const requestData = {
            messaging_product: "whatsapp",
            to: phoneModificado,
            type: "text",
            text: {
                //body: "Hola..."
                body: "Sr(a)." + contacto + ", su Ejecutiv@: " + doliente + " ha generado una nueva gestión y ha sido notificado a su correo: " + correoSelect + " Saludos!. "
            }
        };

        const response = await axios.post(apiUrl, requestData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });


        console.log("Mensaje enviado:", response.data);

        // Si se envia el mensaje que se agregue a la cola de mensajes. 
        const timestamp = new Date();


        saveMessageToDatabase(phoneModificado, requestData.text.body, timestamp, contacto, doliente);

    } catch (error) {
        console.error("Error al enviar el mensaje:", error.response.data);
    }
}

export async function FBAgregaObservacion(objeto, todosLosDatos) {
    try {
        console.log(objeto);
        console.log(todosLosDatos);

        const fechaEmail = objeto.proxContacto;
        objeto.fechaRegistro = firebase.firestore.Timestamp.now();
        objeto.proxContacto = convertirFechaATimestamp(objeto.proxContacto);

        const texto = objeto.observacion;
        const { observacion, mensajeCliente } = separarTextoCliente(texto);

        const idRegistroS = todosLosDatos.seguimientoId;
        const collectionPathCreaProspecto = `cf-ventas/etapas/${todosLosDatos.etapa}`;

        const querySnapshot = await db
            .collection(collectionPathCreaProspecto)
            .where('seguimientoId', '==', idRegistroS)
            .get();

        if (querySnapshot.empty) {
            return [false, "No existe Documento"];
        }

        const docRef = db.collection(collectionPathCreaProspecto).doc(querySnapshot.docs[0].id);

        const gestionArray = querySnapshot.docs[0].data().gestion || []; // Obtener el array existente de 'gestion' o un array vacío si no existe

        await docRef.update({
            gestion: [...gestionArray, { ...objeto, observacion, mensajeCliente }],
            proxContacto: objeto.proxContacto,
        });


        //NOTIFICAR EMAIL LA GESTIÓN AL CONTACTO   ------------- Email ------------- SMS -------------
        if (objeto.envioMail) {
            const seguimientoContacto = functions.httpsCallable("seguimientoProspecto");
            const datosFormularioEmail = {
                correoContacto: objeto.correoSelect,
                contacto: todosLosDatos.contacto,
                doliente: todosLosDatos.doliente,
                dateProximoContacto: fechaEmail,
                etapa: todosLosDatos.etapa,
                gestion: mensajeCliente,
                cliente: todosLosDatos.cliente,
            };

            console.log(datosFormularioEmail);
            // Llama a la función de Firebase Cloud Functions para enviar el correo electrónico
            await seguimientoContacto(datosFormularioEmail);
        }

        if (objeto.envioSMS) {
            //Si se desea enviar un SMS de Bienvenida al usuario  ########### ----------  PASO # 5
            const indicativo = "+57" // Se debe traer de manera dinámica segun el pais que se tomó
            const mensaje = "Sr(a)." + todosLosDatos.contacto + ", Sistema  BeracaBG.com notifica nueva gestión registrada por Ejecutiv@: " + todosLosDatos.doliente + " Saludos!"
            const telefono = objeto.telefonoSelect;//Toma el primer número

            // Llama a la función de Firebase Cloud Functions para enviar el correo electrónico
            await sendMensajeGeneral(telefono, mensaje, indicativo)

        }

        if (objeto.envioWhatsApp) {

            sendMessage(objeto.telefonoSelect, todosLosDatos.contacto, todosLosDatos.doliente, objeto.correoSelect);
        }



        //NOTIFICAR VIA SMS

        return [true, "Se ha actualizado con éxito"];
    } catch (error) {
        console.error('Error al actualizar el documento:', error);
        return [false, "Error.."];
    }
}








