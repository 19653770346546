<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <Cartera />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Cartera from "../components/loadFile/carteras/cartera.vue";

export default {
  name: "HelloWorld",
  components: {
    Cartera,
  },
  data: () => ({
    //
  }),
};
</script>
