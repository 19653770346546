var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-file-input',{attrs:{"type":"file","accept":".csv","label":"Cargar Cartera .CSV"},on:{"change":_vm.onLoadFile}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"pa-2 orange"},[_vm._v(" Monto Cartera "),_c('p',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.totalMonto))])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 orange",attrs:{"headers":_vm.headers,"items":_vm.carteraLoad,"items-per-page":5,"item-class":_vm.row_classes,"loading":_vm.loadinTable,"loading-text":"Cargando... Espere por favor.","hide-default-footer":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoad.length > 1
            ? 'pa-1 grey lighten-2'
            : 'pa-1 red lighten-4'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Total Titulares Finalizados "),_c('p',{staticClass:"pa-3 font-weight-black"},[_vm._v(_vm._s(_vm.carteraLoad.length))])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoad.length > 0)?_c('v-btn',{attrs:{"text":"","color":"gray"},on:{"click":() => _vm.verPanel('Finalizados')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoadRep01.length > 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 grey lighten-2'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Repetidos procesados por cédula "),_c('p',{staticClass:"pa-3 font-weight-black text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.carteraLoadRep01.length)+" ")])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoadRep01.length > 0)?_c('v-btn',{attrs:{"text":"","color":"blue-grey darken-1"},on:{"click":() => _vm.verPanel('reCedula')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoadRep02.length >= 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 grey lighten-2'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Repetidos procesados por monto "),_c('p',{staticClass:"pa-3 font-weight-black text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.carteraLoadRep02.length)+" ")])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoadRep02.length > 0)?_c('v-btn',{attrs:{"text":"","color":"grey lighten-2"},on:{"click":() => _vm.verPanel('reMonto')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoadSinMonto.length > 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 grey lighten-2'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Total Titulares sin monto "),_c('p',{staticClass:"pa-3 font-weight-black"},[_vm._v(" "+_vm._s(_vm.carteraLoadSinMonto.length)+" ")])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoadSinMonto.length > 0)?_c('v-btn',{attrs:{"text":"","color":"grey lighten-2"},on:{"click":() => _vm.verPanel('reSinMonto')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoadSinCedula.length > 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 grey lighten-2'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Total Titulares sin cédula "),_c('p',{staticClass:"pa-3 font-weight-black text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.carteraLoadSinCedula.length)+" ")])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoadSinCedula.length > 0)?_c('v-btn',{attrs:{"text":"","color":"grey lighten-2"},on:{"click":() => _vm.verPanel('reSinCedula')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoad.length > 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 grey lighten-2'},[_vm._v(" --- "),_c('p',{staticClass:"pa-3 font-weight-black text-decoration-line-through"})])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"hint":`Cliente seleccionado: ${_vm.selectCliente.nombre}, Nit.:${_vm.selectCliente.nit}`,"items":_vm.items,"item-text":"nombre","item-value":"nit","label":"Despliegue...","persistent-hint":"","return-object":"","single-line":""},on:{"change":function($event){return _vm.cargaCarterasDisponibles(_vm.selectCliente.nit)}},model:{value:(_vm.selectCliente),callback:function ($$v) {_vm.selectCliente=$$v},expression:"selectCliente"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"hint":`Cliente seleccionado: ${
          _vm.selectCarteras.nombre
        }, Código: ${_vm.recorteTexto(_vm.selectCarteras.id)}`,"items":_vm.itemsCarteras,"item-text":"nombre","item-value":"id","label":"Despliegue...","persistent-hint":"","return-object":"","single-line":""},model:{value:(_vm.selectCarteras),callback:function ($$v) {_vm.selectCarteras=$$v},expression:"selectCarteras"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('h5',[_vm._v(" "+_vm._s("Respueta Proceso:" + _vm.textoSubidaCSV)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading,"color":"orange","fab":"","disabled":!_vm.hasData
                ? true
                : false || _vm.carteraLoad.length > 1
                ? false
                : true || _vm.loading},on:{"click":function($event){return _vm.cargarCartera()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cloud-upload ")])],1)]}}])},[_c('span',[_vm._v("Cargar Base a la Cartera Seleccionada")])])],1)],1),(_vm.verTablaExportar)?_c('datatable',{attrs:{"title":'Panel: ' + _vm.nombrePanel,"columns":_vm.tableColumns1,"rows":_vm.comodinDatosTabla,"perPage":[5, 10, 20, 30, 50],"locale":"es"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }