<template>
  <v-card class="mx-auto" max-width="344">
    <v-img
      src="https://img.freepik.com/vector-gratis/concepto-marca-ilustracion-pagina-aterrizaje_52683-18232.jpg?w=2000&t=st=1691963735~exp=1691964335~hmac=cc34436c91c12716bebd8775ce3c2f17bdc4578e06203e3400cd5f33082380af"
      height="200px"
    ></v-img>

    <v-card-title>Crea Productos </v-card-title>

    <v-card-subtitle>Podrás crear los productos que desees para tu catálogo </v-card-subtitle>

    <v-card-actions>
      <crear />

      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          Los prouductos aquí creados aparecerán en el catálogo de la empresa en tiempo real.
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Crear from "./Crear.vue";
export default {
  data: () => ({
    show: false,
  }),
  components: {
    Crear,
  },
};
</script>