<template>
  <div class="text-center">
    <v-dialog v-model="dialog" scrollable max-width="900px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on" color="green">
          mdi-eye-outline
        </v-icon>
      </template>

      <v-card v-if="!isUpdating" dark>
        <v-card-title class="text-h5 amber">
          <v-tooltip color="amber" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                @click="copiarTexto(todosLosDatos.contacto)"
                color="black"
                label
                outlined
                v-bind="attrs"
                v-on="on"
              >
                {{ todosLosDatos.contacto }}
              </v-chip>
            </template>
            <span style="color: black; align-content: center"
              >Cliente<br />
              Clic para copiar
            </span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <div>
            <Notas :datos="todosLosDatos" />
          </div>

          <div>
            <WhatsappPrivado
              :correo="correoSeleccionadoContacto"
              :celular="celularSeleccionadoContacto"
              :datos="todosLosDatos"
              :indica="indicativoSelect"
            />
          </div>

          <v-tooltip color="amber" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                @click="copiarTexto(todosLosDatos.nit)"
                color="black"
                label
                outlined
                v-bind="attrs"
                v-on="on"
              >
                {{ todosLosDatos.nit ? todosLosDatos.nit : "No disponible" }}
              </v-chip>
            </template>
            <span style="color: black; align-content: center"
              >NIT | ID <br />
              Clic para copiar
            </span>
          </v-tooltip>
        </v-card-title>

        <v-card-text>
          <!-- PRIMERA LINEA DE DATOS NIT , RESPONSABLE, ETC-->
          <v-row align="center" class="mt-5 text-center">
            <v-col cols="4">
              <v-tooltip color="orange" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    color="orange"
                    label
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ todosLosDatos.proyecto }}
                  </v-chip>
                </template>
                <span>Proyecto</span>
              </v-tooltip>
            </v-col>

            <v-col cols="4">
              <v-tooltip color="orange" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    color="orange"
                    label
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ todosLosDatos.doliente }}
                  </v-chip>
                </template>
                <span>Responsable</span>
              </v-tooltip>
            </v-col>
            <v-col cols="4">
              <v-chip color="orange" label outlined>
                {{ todosLosDatos.estado ? "Activo" : "Pausado" }}
              </v-chip>
            </v-col>
          </v-row>

          <v-row align="center" class="mt-5 text-center">
            <v-col cols="12">
              <v-tooltip color="orange" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    color="orange"
                    label
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    Fecha Próximo Contacto:
                    {{ fechaConvertirFirebase(todosLosDatos.proxContacto) }}
                  </v-chip>
                </template>
                <span>Fecha Próximo Contacto</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row align="center" class="mt-n5 text-center">
            <v-col cols="4"> </v-col>
            <v-col cols="4"> </v-col>
            <v-col cols="4"> </v-col>
          </v-row>

          <v-row class="mt-5">
            <v-divider></v-divider>
          </v-row>

          <!-- CAMPOS DE CONTACTO PARA EDITAR -->
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="todosLosDatos.contacto"
                label="Contacto | Cliente"
                placeholder="Contacto | Cliente"
                outlined
                color="orange"
                @change="actulizarCamposCliente('contacto')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="todosLosDatos.depContacto"
                :items="departamentos"
                label="Dep. | Contacto"
                outlined
                color="orange"
                @change="actulizarCamposCliente('depContacto')"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="todosLosDatos.prioridad"
                :items="prioridadSelect"
                label="Etapa"
                outlined
                color="orange"
                @change="actulizarCamposCliente('prioridad')"
              ></v-select>
            </v-col>
          </v-row>

          <!-- SEGUNDA SECCIÓN-->
          <v-row class="mt-n10">
            <v-col cols="4">
              <v-select
                v-model="todosLosDatos.tipificacionGlobal"
                :items="tipificacion"
                label="Tipificación Global"
                outlined
                color="orange"
                @change="actulizarCamposCliente('tipificacionGlobal')"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="todosLosDatos.estado"
                :items="estadoCliente"
                item-text="value"
                item-value="item"
                label="Estado"
                outlined
                color="orange"
                @change="actulizarCamposCliente('estado')"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Prox. Contacto"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="orange"
                  v-model="date"
                  no-title
                  scrollable
                  dark
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="orange" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn outlined color="orange" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <!-- TERCERA SECCIÓN-->
          <v-row class="mt-n10">
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="todosLosDatos.dirEntrega"
                label="Dirección Entrega"
                placeholder="Dir. Entrega"
                outlined
                color="orange"
                @change="actulizarCamposCliente('dirEntrega')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="todosLosDatos.nit"
                label="Nit / CC / DNI"
                placeholder="Documento"
                outlined
                color="orange"
                @change="actulizarCamposCliente('nit')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="todosLosDatos.codPostal"
                label="COD. Postal"
                placeholder="..."
                outlined
                color="orange"
                @change="actulizarCamposCliente('codPostal')"
              ></v-text-field>
            </v-col>
          </v-row>


           <!-- CUARTA SECCIÓN-->
           <v-row class="mt-n10">
            <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="todosLosDatos.pagoCliente"
                :items="pagoClienteSelect"
                label="Etapa pago"
                outlined
                color="orange"
                @change="actulizarCamposCliente('pagoCliente')"
              ></v-select>
            </v-col>
            </v-row>

          <!-- DATOS DEL CONTACTO -->
          <v-row class="mt-n10">
            <v-col cols="6">
              <v-row>
                <v-col cols="10">
                  <v-select
                    v-model="correoSeleccionadoContacto"
                    :items="todosLosDatos.correoContacto"
                    label="Correos"
                    outlined
                    color="orange"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <AgregaDatos
                    title="Agrega Correo"
                    :identificador="[
                      {
                        codigoSeguimiento: this.todosLosDatos.codigoSeguimiento,
                        seguimientoId: todosLosDatos.seguimientoId,
                        etapa: todosLosDatos.etapa,
                        datos: todosLosDatos.correoContacto,
                        claveCambio: 'correoContacto',
                      },
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="10">
                  <v-row>
                    <v-col cols="5">
                      <v-select
                        v-model="indicativoSelect"
                        :items="indicativos"
                        label="Indicativo"
                        outlined
                        color="orange"
                      ></v-select>
                    </v-col>
                    <v-col cols="7">
                      <v-select
                        v-model="celularSeleccionadoContacto"
                        :items="todosLosDatos.whatsappContacto"
                        label="Telefono | Movil"
                        outlined
                        color="orange"
                        max-width="100%"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2">
                  <AgregaDatos
                    title="Agrega Celular"
                    :identificador="[
                      {
                        codigoSeguimiento: todosLosDatos.codigoSeguimiento,
                        seguimientoId: todosLosDatos.idConsulta,
                        etapa: todosLosDatos.etapa,
                        datos: todosLosDatos.whatsappContacto,
                        claveCambio: 'whatsappContacto',
                      },
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- TIMELINE -->
          <v-row class="mt-n10 ml-n10">
            <v-col>
              <TimeLine
                :todosLosDatos="todosLosDatos"
                :correoSelect="correoSeleccionadoContacto"
                :telefonoSelect="celularSeleccionadoContacto"
                :date="date"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-divider></v-divider>
          </v-row>
        </v-card-text>

        <v-divider color="orange"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="orange" @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- CARGADOR DE CAMBIOS -->
      <v-card dark v-else>
        <v-card-title class="text-h5 black">
          {{ textoRespuesta }}
        </v-card-title>

        <v-progress-linear indeterminate color="amber"></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TimeLine from "./timeLineB2C.vue";
import AgregaDatos from "./mailYcelAddB2C.vue";
import Notas from "../Tableros/notasFichaCliente.vue";
import WhatsappPrivado from "./whatsapp/wappPrivado.vue";

import { FBeditarFichaClienteCampo } from "@/components/Ventas/Tableros/editarCamposClienteB2C.js";
import {
  FBconsultarDepartamentos,
  FBconsultarTipificaciones,
  FBconsultarEstados,
} from "@/components/Ventas/consultas.js";

export default {
  props: ["todosLosDatos"],
  components: {
    TimeLine,
    AgregaDatos,
    Notas,
    WhatsappPrivado,
  },
  data() {
    return {
      dialog: false,
      estadoCliente: [], 
      prioridadSelect: ["Alto", "Medio", "Bajo"],
      pagoClienteSelect: ["Pendiente", "Recibido", "Devolución"],
      correoSeleccionadoContacto: "",
      celularSeleccionadoContacto: "",
      isUpdating: false,
      departamentos: "",
      tipificacion: ["Cargando..."],
      textoRespuesta: "Procesando...",
      //time
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      indicativoSelect: "57",
      indicativos: [
        "1", // Estados Unidos
        "7", // Rusia
        "33", // Francia
        "34", // España
        "39", // Italia
        "44", // Reino Unido
        "49", // Alemania
        "52", // México
        "54", // Argentina
        "55", // Brasil
        "56", // Chile
        "57", // Colombia
        "61", // Australia
        "82", // Corea del Sur
        "86", // China
        "81", // Japón
        "91", // India
      ],
    };
  },
  created() {
    this.inicializar();
  },

  updated() {},

  methods: {
    async inicializar() {
      //Cómo la primera vez no se conoce la tipificación global entonces toca agregarla
      if (!this.todosLosDatos.hasOwnProperty("tipificacionGlobal")) {
        this.$set(this.todosLosDatos, "tipificacionGlobal", null);
      }

      this.departamentos = await FBconsultarDepartamentos();
      this.tipificacion = await FBconsultarTipificaciones();
      this.estadoCliente = await FBconsultarEstados();

      //console.log(this.todosLosDatos);
    },

    async actulizarCamposCliente(clave) {
      if (!clave) {
        return; // Si no se proporciona una clave, no se ejecuta nada
      }

      const {
        depContacto,
        contacto,
        prioridad,
        tipificacionGlobal,
        estado,
        dirEntrega,
        nit,
        codPostal,
        pagoCliente,
      } = this.todosLosDatos;
      let valorClave = "";

      if (clave === "depContacto") {
        valorClave = depContacto;
      } else if (clave === "contacto") {
        valorClave = contacto;
      } else if (clave === "prioridad") {
        valorClave = prioridad;
      } else if (clave === "tipificacionGlobal") {
        valorClave = tipificacionGlobal;
        //valorClave = tipificacionGlobal?.propiedadExistente;
      } else if (clave === "estado") {
        valorClave = estado;
        //valorClave = tipificacionGlobal?.propiedadExistente;
      } else if (clave === "dirEntrega") {
        valorClave = dirEntrega;
      } else if (clave === "nit") {
        valorClave = nit;
      } else if (clave === "codPostal") {
        valorClave = codPostal;
      } else if (clave === "pagoCliente") {
        valorClave = pagoCliente;
      }

      this.isUpdating = true;

      const json = {
        etapa: this.todosLosDatos.etapa,
        //seguimientoId: this.todosLosDatos.seguimientoId,
        codigoSeguimiento: this.todosLosDatos.codigoSeguimiento,
        nuevoDato: valorClave,
        clave: clave,
      };

      //console.log(json);

      const result = await FBeditarFichaClienteCampo(json);

      if (result[0]) {
        console.log("Se ha actualizado con éxito");
      } else {
        console.log("No se ha podido actualizar");
      }

      this.isUpdating = false;
    },

    async copiarTexto(textoLlega) {
      if (!navigator.clipboard) {
        console.error(
          "La API navigator.clipboard no es compatible en este navegador."
        );
        return;
      }

      let textoCopiado = textoLlega;

      try {
        await navigator.clipboard.writeText(textoCopiado);
        //console.log("Texto copiado al portapapeles: ", textoCopiado);
      } catch (error) {
        //console.error("Error al copiar el texto al portapapeles:", error);
      }
    },

    fechaConvertirFirebase(timestamp) {
      if (!timestamp || !timestamp.seconds) {
        return "Fecha inválida";
      }

      const fecha = new Date(timestamp.seconds * 1000);
      const fechaHoraLegible = fecha.toLocaleString();

      return fechaHoraLegible;
    },

    capturarEnlaces() {
      const expresionRegular = /(https?:\/\/\S+)/g;
      const matches = this.observacion.match(expresionRegular);

      if (matches) {
        this.enlaces = matches;
      } else {
        this.enlaces = [];
      }
    },

    abrirEnlace(enlace) {
      window.open(enlace, "_blank");
    },
  },

  watch: {},
};
</script>