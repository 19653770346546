import { db } from "@/firebase/config.js";

export async function FBconsultaToken() {
  try {
    //  /recursos/whatsapp
    const docRef = db.collection("recursos").doc("whatsapp");
    const docSnapshot = await docRef.get();

    if (docSnapshot.exists) {
      const data = docSnapshot.data();
      const codigo = data.token;
      const IdentificadorPhone = data.IdentificadorPhone;
      const IdentificadorWhatsappBusiness = data.IdentificadorWhatsappBusiness;
      //console.log("Valor de 'codigo':", codigo);

      const TOKEN = codigo; // Asigna tu token de forma segura
      const IDP = IdentificadorPhone; // Asigna tu IdentificadorPhone de forma segura
      const IWB = IdentificadorWhatsappBusiness; // Asigna tu IdentificadorWhatsappBusiness de forma segura

      return [TOKEN, IDP, IWB];
    } else {
      //console.log("El documento '/recursos/w' no existe.");
      return null;
    }

  } catch (error) {
    //console.log(error);
  }
}

// Variable para almacenar la token de manera segura
//const TOKEN = 'EAASfe3vP0N8BAFFenIF0NGYhwra4KgbmZCZC8fEHzKKefcmipvldqF5tNXwHp8zF4fWOif7aRhULPvGjTN5tjnSTkTaGpSkkaqnEalJnaFPf8Vz3O1JJ9E0uMSCtNZASWdtSjgZAfy8ImSjN4soYqrXwtxLzmCUR73XBjIHgL4SOR36odvLOOk52fE9ghaZAqdvrCipzbugZDZD'; // Asigna tu token de forma segura

// Exporta la variable TOKEN
//export default TOKEN;