<template>
  <v-container>
    <v-row class="pa-2" dense>
      <v-col v-for="card in filteredCards" :key="card.title" :md="card.flex">
        <v-card :color="card.color" shaped outlined hover class="mt-0">
          <v-img
            :src="card.src"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
            <v-card-title class="titulos">{{ card.title }}</v-card-title>
          </v-img>

          <v-card-actions>
            <v-spacer>
              <div v-if="card.verMensaje">
                <marquee>{{ card.mensaje }}</marquee>
              </div>
            </v-spacer>
            <v-btn v-if="card.alert" disabled depressed icon>
              <v-badge dot overlap bordered color="green" :content="card.badge">
                <v-icon>mdi-alarm-light-outline</v-icon>
              </v-badge>
            </v-btn>
            <div v-else></div>

            
            <component
              v-for="(component, index) in card.components"
              :key="index"
              :is="component.name"
              v-bind="component.props"
              v-show="roles(component.roles)"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FBtraeModulos } from "../../components/Modulos/index.js";
import creaEmpleado from "./Empleados/creaEmpleado.vue";
import Empleados from "./Empleados/Empleados.vue";
import Solicitudes from "./Solicitudes/Solicitudes.vue";
import AgregaSolicitudes from "./Solicitudes/AgregarSolicitud.vue";
import Indicadores from "./Indicadores/Indicadores.vue";
import store from "../../store";

export default {
  name: "RHumanos",
  data() {
    return {
      modulo: "Recurso Humano",
      subModulo: ["Solicitudes"],
      rolPrueba: store.state.user[2][0].data.cargo,
      cards: [
        {
          title: "Recurso Humano",
          src: "xx",
          flex: 4,
          mensaje: "",
          verMensaje: true,
          alert: false,
          badge: 1,
          modalCard: true,
          color: "#E4E4E4FF",
          roles: ["g", "rh", "acm", "j"],
          components: [
            { name: "creaEmpleado", props: {}, roles: ["g", "rh"] },
            { name: "Empleados", props: { titulo: "Listado de trabajadores" }, roles: ["g", "rh", "acm"] },
          ],
        },
        {
          title: "Solicitudes",
          src: "xx",
          flex: 4,
          mensaje: "",
          alert: false,
          modalCard: true,
          color: "#E4E4E4FF",
          roles: ["g", "rh", "acm", "j"],
          components: [
            { name: "AgregaSolicitudes", props: { modulo: "Recursos Humanos" }, roles: ["g", "rh", "acm", "j"] },
            { name: "Solicitudes", props: {}, roles: ["g", "rh", "acm", "j"] },
          ],
        },
        {
          title: "Indicadores",
          src: "xx",
          flex: 4,
          mensaje: "",
          alert: false,
          modalCard: true,
          color: "#E4E4E4FF",
          roles: ["g", "rh"],
          components: [
            { name: "Indicadores", props: {}, roles: ["g", "rh"] },
          ],
        },
      ],
    };
  },
  components: {
    creaEmpleado,
    Empleados,
    Solicitudes,
    AgregaSolicitudes,
    Indicadores,
  },
  async mounted() {
    const rolesData = await FBtraeModulos();

    const matchingRoleData = rolesData.cards.find((card) => card.title === this.modulo);

    if (matchingRoleData) {
      const cardsToUpdate = this.cards.filter((card) => this.subModulo.includes(card.title));

      cardsToUpdate.forEach((card) => {
        card.roles = matchingRoleData.roles;
      });
    }
  },
  computed: {
    filteredCards() {
      return this.cards.filter((card) => card.roles.includes(this.rolPrueba));
    },
  },
  methods: {
    roles(roles) {
      return roles.includes(this.rolPrueba);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Sarpanch&display=swap");
@import url("https://fonts.googleapis.com/css2?family=B612+Mono&display=swap");

.titulos {
  font-family: "Sarpanch", sans-serif;
  color: white;
  font-weight: 700;
}

marquee {
  font-family: "B612 Mono", monospace;
  color: rgb(122, 122, 122);
  font-weight: 200;
}
</style>
