import { firebase, db, storage } from "../../firebase/config"
const ref = storage.ref();

export async function FBcedulaCambio(datos, nuevoDato, usuarioNombre) {
    //console.log(datos, nuevoDato);
    //console.log(datos);
    try {
        // 1. Modificar la cédula en la base de datos original
        const clientesRef = db.collection('cf-cartera').doc('Carteras').collection(datos.nitCliente).doc(datos.idCartera).collection('registros').doc(datos.idRegistro);
        await clientesRef.update({ 'data.Cedula': Number(nuevoDato) });

        // Obtener la fecha actual
        const fechaActual = firebase.firestore.Timestamp.now();;

        // Crear el objeto 'timeRegistroModificado'
        const timeRegistroModificado = {
            fecha: fechaActual,
            tipoCambio: 'Actualiza Cédula',
            usuario: usuarioNombre
        };

        // Agregar 'timeRegistroModificado' al array 'registrosModificados'
        await clientesRef.update({
            timeRegistroModificado: firebase.firestore.FieldValue.arrayUnion(timeRegistroModificado)
        });

        // 2. Modificar la cédula en la bolsa del ejecutivo
        const bolsaEjecutivoRef = db.collection('cf-cartera').doc('Asignaciones').collection(datos.asignadoActual[0]).where('Cedula', '==', datos.data.Cedula);
        const snapshot = await bolsaEjecutivoRef.get();

        if (snapshot.empty) {
            //return false; // No hay documentos coincidentes No se activa porque se para el proceso
        }

        const updatePromises = [];

        snapshot.forEach(doc => {
            const itemEnLaBolsa = db.collection('cf-cartera').doc('Asignaciones').collection(datos.asignadoActual[0]).doc(doc.id);
            updatePromises.push(itemEnLaBolsa.update({ 'Cedula': Number(nuevoDato) }));
        });

        await Promise.all(updatePromises);


        // 3. Actualizar el nombre de la carpeta en el storage
        // no se puede probar el cambio por temas de permisos en el storage
        const storagePath = `st-cartera/Clientes/${datos.nitCliente}/Titulares/${datos.data.Cedula}`;
        const folderRef = storage.ref().child(storagePath);
        await folderRef.updateMetadata({ customMetadata: { nombre: nuevoDato } });


        return true; // Se realizaron las actualizaciones correctamente
    } catch (error) {
        console.log(error);
        return false; // Ocurrió un error durante el proceso de actualización
    }
}
