<template>
  <v-container style="max-width: 850px">
    <v-timeline v-if="!isUpdating" dense clipped>
      <v-timeline-item fill-dot class="white--text mb-12" color="orange" large>
        <template v-slot:icon>
          <span>HOY</span>
        </template>

        <v-row>
          <v-col cols="12" class="relative-position">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="orange"
                  @click="agregaEtiquetaCliente()"
                  fab
                  x-small
                  dark
                  class="button-overlay"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-comment-account-outline</v-icon>
                </v-btn>
              </template>
              <span
                >Luego de la etiqueta {{ "&lt;cliente&gt;" }} Lo que escribas
                será enviado al Contacto en el correo electrónico seleccionado.
              </span>
            </v-tooltip>
          </v-col>
          <v-col class="mt-n12" cols="12">
            <v-textarea
              v-model="input"
              hide-details
              flat
              label="Escribe... Si colocas **texto** se verá en negrita al guardar... 😎"
              color="orange"
              rows="8"
              @input="capturarEnlaces"
              outlined
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row class="mt-n1">
          <v-col cols="12">
            Tus links: {{ enlaces.length }}
            <!-- Mostrar los enlaces capturados -->
            <ul class="pa-2" v-if="enlaces.length > 0">
              <li
                class="custom-div pa-1"
                v-for="(enlace, index) in enlaces"
                :key="index"
                style="margin-bottom: 10px"
              >
                <v-row>
                  <v-col class="ancho-link" cols="10">
                    {{ enlace }}
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip color="orange" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="orange"
                          v-bind="attrs"
                          v-on="on"
                          @click="abrirEnlace(enlace)"
                        >
                          <v-icon>mdi-link</v-icon>
                        </v-btn>
                      </template>
                      <span>Prueba el link de tu contenido</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </li>
            </ul>
          </v-col>
        </v-row>
        <!-- TIPIFICACIÓN -->
        <v-row class="mt-n5">
          <v-col>
            <v-select
              v-model="tipificacionSale"
              :items="tipificacion"
              label="Tipificación Gestión"
              outlined
              color="orange"
            ></v-select>
          </v-col>
        </v-row>
        <!-- GUARDAR, SMS Y CORREO -->
        <v-row class="mt-n10">
          <v-col cols="4">
            <v-switch
              class="mx-0 mt-n1"
              depressed
              v-model="switchWhatsApp"
              :label="`Enviar WhatsApp`"
              color="green"
            ></v-switch>
          </v-col>
          <v-col cols="4">
            <v-switch
              class="mx-0 mt-n1"
              depressed
              v-model="switchCorreo"
              :label="`Enviar correo`"
              color="purple"
            ></v-switch>
          </v-col>
          <v-col cols="4">
            <v-switch
              class="mx-0 mt-n1"
              depressed
              v-model="switchSMS"
              :label="`Enviar SMS`"
              color="deep-orange darken-3"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row class="mt-n5">
          <v-col cols="12">
            <v-btn
              class="mx-0"
              outlined
              depressed
              @click="agregaObservacion()"
              color="orange"
              block
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
        <!-- 
        <v-row class="mt-n5">
          <v-col cols="12">
            <v-chip color="orange" label outlined class="w-100">
              {{ respuesta }}
            </v-chip>
          </v-col>
        </v-row>
         -->
        <v-row class="mt-n2">
          <v-col cols="4">
            <Cotizaciones
              :datosGenerales="todosLosDatos"
              :dateCalendario="date"
              :correoSelec="correoSelect"
            />
          </v-col>
          <v-col class="4">
            <v-btn small rounded color="blue darken-3" dark>
              Orden de Compra
            </v-btn>
          </v-col>
          <v-col class="4">
            <v-btn small rounded color="lime darken-3" dark> Factura </v-btn>
          </v-col>
        </v-row>
      </v-timeline-item>

      <!-- INICIA LA LINEA DE TIEMPO -->
      <v-slide-x-transition group>
        <v-timeline-item
          v-for="event in timeline"
          :key="event.id"
          class="mb-4"
          small
        >
          <v-row justify="space-between">
            <v-col cols="7">
              <pre>{{ event.text }} </pre>
              --
            </v-col>
            <v-col class="text-right" cols="5"> {{ event.time }}</v-col>
            <v-btn icon color="pink">
              <v-icon>mdi-email-arrow-right-outline</v-icon>
            </v-btn>
          </v-row>
        </v-timeline-item>
      </v-slide-x-transition>

      <!--- #################################### -- HISTORIAL -- ###########################################-->
      <!-- HISTORIAL LINEA DE TIEMPO -->

      <v-timeline-item class="mb-3" hide-dot>
        <span>Historial - Total Gestiones {{ observaciones.length }}</span>
      </v-timeline-item>

      <template v-slot:icon>
        <v-icon color="red">mdi-account-network</v-icon>
      </template>

      <v-timeline-item
        v-for="(item, index) in observaciones"
        :key="index"
        class="mb-4"
        small
      >
        <v-row justify="space-between">
          <v-col cols="8">
            <div class="mensajeGestion mt-5">
              <div v-html="textoFormateadoFuncion(item.observacion)"></div>
            </div>
            <v-divider color="orange" class="mt-3"></v-divider>
            <!-- VER MAS TEXTO -->
            <v-tooltip color="#9afffd" bottom dark>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <TextoFaltante :textoFaltante="item" />
                </div>
              </template>
              <span style="color: #474747"> Da clic para ver más texto...</span>
            </v-tooltip>
          </v-col>
          <v-col class="text-right" cols="4">
            <v-tooltip color="orange" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="orange" v-bind="attrs" v-on="on" label outlined>
                  {{ fechaConvertirFirebase(item.fechaRegistro) }}
                </v-chip>
              </template>
              <span>Fecha Registro</span>
            </v-tooltip>
            <br />
            <v-tooltip color="orange" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="orange" v-bind="attrs" v-on="on" label outlined>
                  {{ item.tipificacion ? item.tipificacion : "N/A" }}
                </v-chip>
              </template>
              <span>Gestión Tipificación</span>
            </v-tooltip>
            <br />

            <v-tooltip
              v-if="item && item.enlaces && item.enlaces.length > 0"
              color="orange"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="orange" v-bind="attrs" v-on="on" label outlined>
                  <v-btn
                    v-for="(enlace, index) in item.enlaces"
                    :key="index"
                    icon
                    outlined
                    x-small
                    color="orange"
                    @click="abrirEnlace(enlace)"
                  >
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </v-chip>
              </template>
              <span>Accede a los link que contiene el mensaje.</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- BOTONERA EMAIL - SMS - WHATSAPP-->
        <v-row>
          <v-col cols="3">
            <v-tooltip bottom color="purple">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  class="white--text ml-0"
                  color="purple"
                  v-bind="attrs"
                  v-on="on"
                  @click="enviarCorreo(item)"
                >
                  <v-icon>mdi-email-arrow-right-outline</v-icon>
                </v-btn>
              </template>
              <span
                >{{ getSwitch(item.envioMail) }} se ha enviado eMail / Si da
                clic y selecciona un correo se Re Envia el comunicado de esta
                gestión.</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  class="white--text ml-0"
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  @click="enviarSMS"
                >
                  <v-icon>mdi-message-processing</v-icon>
                </v-btn>
              </template>
              <span>{{ getSwitch(item.envioSMS) }} se ha enviado SMS</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3">
            <v-tooltip bottom color="green">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  class="white--text ml-0"
                  color="green"
                  v-bind="attrs"
                  v-on="on"
                  @click="enviarWhatsApp"
                >
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
              </template>
              <span
                >{{ getSwitch(item.envioWhatsApp) }} se ha enviado
                WhatsApp</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="3"> </v-col>
        </v-row>
        <!-- END BOTONERA EMAIL... ETC-->
      </v-timeline-item>
    </v-timeline>

    <!-- CARGADOR DE CAMBIOS -->
    <v-card v-else>
      <v-card-title class="text-h5" color="transparent">
        {{ respuesta }}
      </v-card-title>

      <v-progress-linear indeterminate color="amber"></v-progress-linear>
    </v-card>
  </v-container>
</template>

<script>
import { FBconsultarTipificaciones } from "@/components/Ventas/consultas.js";
import { FBAgregaObservacion } from "@/components/Ventas/Tableros/agregaObservacion.js";
import TextoFaltante from "./textoFaltante.vue";
import Cotizaciones from "../Tableros/cotizaciones.vue";

export default {
  props: ["todosLosDatos", "correoSelect", "telefonoSelect", "date"],
  data: () => ({
    isUpdating: false,
    events: [],
    input: "",
    nonce: 0,
    switchCorreo: false,
    switchSMS: false,
    switchWhatsApp: false,
    observaciones: [],
    tipificacion: ["Cargando..."],
    tipificacionSale: "",
    respuesta: "...",
    mensajeSeleccionado: null,
    enlaces: [],
  }),

  components: {
    TextoFaltante,
    Cotizaciones,
  },

  created() {
    this.inicializar();
  },

  computed: {
    timeline() {
      return this.events.slice().reverse();
    },
  },

  methods: {
    capturarEnlaces() {
      const expresionRegular = /(https?:\/\/\S+)/g;
      const matches = this.input.match(expresionRegular);

      if (matches) {
        this.enlaces = matches;
      } else {
        this.enlaces = [];
      }
    },

    abrirEnlace(enlace) {
      window.open(enlace, "_blank");
    },

    async inicializar() {
      this.tipificacion = await FBconsultarTipificaciones();

      //console.log(this.todosLosDatos);
      //console.log(this.date);
      const objeto = {
        observacion: this.todosLosDatos.observaciones,
        fechaRegistro: this.todosLosDatos.timeR,
        envioMail: false,
        envioSMS: false,
        envioWhatsApp: false,
        prioridad: this.todosLosDatos.prioridad,
        contador: 0,
      };

      //Sino llega observaciones es porque es la primera vez
      //Por consiguiente se hace push para agregar la primera observacion
      if ("gestion" in this.todosLosDatos) {
        //organiza por fecha.
        this.todosLosDatos.gestion.sort((a, b) => {
          const fechaA = new Date(a.fechaRegistro);
          const fechaB = new Date(b.fechaRegistro);

          return fechaB - fechaA; // Orden descendente (de la más reciente a la más antigua)
        });

        // La propiedad existe en el objeto
        this.observaciones = this.todosLosDatos.gestion;
        //Agregar la observación inicial
        this.observaciones.push(objeto);

        //Organiza x fecha
        this.observaciones.sort((a, b) => {
          const fechaA = a.fechaRegistro.toDate();
          const fechaB = b.fechaRegistro.toDate();

          if (fechaA < fechaB) {
            return 1; // Cambiado a 1 para ordenar de manera descendente
          } else if (fechaA > fechaB) {
            return -1; // Cambiado a -1 para ordenar de manera descendente
          } else {
            return 0;
          }
        });

        //this.observaciones.reverse();

        //console.log(this.observaciones);
      } else {
        // La propiedad no existe en el objeto
        this.observaciones.push(objeto);
      }
    },

    truncarTexto(texto) {
      if (texto.length > 150) {
        return texto.slice(0, 150) + "...";
      } else {
        return texto;
      }
    },

    mostrarTextoOculto(item) {
      alert(item.mensajeCliente); // Puedes utilizar otro método para mostrar el texto oculto, como un cuadro de diálogo o una ventana emergente.
    },

    textoFormateadoFuncion(llegaTexto) {
      let textoConFormato = llegaTexto;

      // Reemplazar los saltos de línea con etiquetas <br>
      textoConFormato = textoConFormato.replace(/\n/g, "<br>");

      // Agregar negrita a ciertas partes del texto
      textoConFormato = textoConFormato.replace(
        /(\*\*|__)(.*?)\1/g,
        "<strong>$2</strong>"
      );

      // Devolver el texto formateado
      return textoConFormato;
    },

    textoFormateado() {
      let textoConFormato = this.input;

      // Reemplazar los saltos de línea con etiquetas <br>
      textoConFormato = textoConFormato.replace(/\n/g, "<br>");

      // Agregar negrita a ciertas partes del texto
      textoConFormato = textoConFormato.replace(
        /(\*\*|__)(.*?)\1/g,
        "<strong>$2</strong>"
      );

      // Devolver el texto formateado
      return textoConFormato;
    },

    async agregaObservacion() {
      this.respuesta = "Procesando...";
      this.isUpdating = true; //muestra proceos

      const textoEstilos = this.textoFormateado();

      const objetoActual = {
        correoSelect: this.correoSelect,
        telefonoSelect: this.telefonoSelect,
        observacion: textoEstilos, //this.input,
        fechaRegistro: null,
        envioMail: this.switchCorreo,
        envioSMS: this.switchSMS,
        envioWhatsApp: this.switchWhatsApp,
        prioridad: this.todosLosDatos.prioridad,
        toques: 1,
        tipificacion: this.tipificacionSale,
        proxContacto: this.date,
        enlaces: this.enlaces,
      };

      const result = await FBAgregaObservacion(
        objetoActual,
        this.todosLosDatos
      );

      const { observacion, mensajeCliente } = this.separarTextoCliente(
        this.input
      );
      // Actualizar el contenido de la clave "clave1"
      objetoActual.observacion = observacion;
      objetoActual.mensajeCliente = mensajeCliente;

      if (result[0]) {
        //console.log("Se agregó");
        // Se agrega al array de las gestiones.
        this.observaciones.unshift(objetoActual);
        this.respuesta = "Se ha agregado con éxito!";

        //Organiza x fecha
        this.observaciones.sort((a, b) => {
          const fechaA = a.fechaRegistro.toDate();
          const fechaB = b.fechaRegistro.toDate();

          if (fechaA < fechaB) {
            return 1; // Cambiado a 1 para ordenar de manera descendente
          } else if (fechaA > fechaB) {
            return -1; // Cambiado a -1 para ordenar de manera descendente
          } else {
            return 0;
          }
        });

        //this.observaciones.reverse();

        //console.log(this.observaciones);

        setTimeout(() => {
          this.respuesta = "...";
          this.isUpdating = false; //muestra proceos
        }, 3000);
      } else {
        console.log("Falló");
        this.respuesta = "Falló el proceso.";
        setTimeout(() => {
          this.respuesta = "...";
          this.isUpdating = false; //muestra proceos
        }, 3000);
      }

      // Set Variables
      this.input = "";
      this.switchCorreo = false;
      this.switchSMS = false;
      this.switchWhatsApp = false;
      this.tipificacionSale = "";
      this.enlaces = [];
    },

    enviarWhatsApp() {
      //console.log("Enviar Whatsapp: " + this.telefonoSelect);
    },

    enviarSMS() {
      //console.log("Enviar SMS: " + this.telefonoSelect);
    },

    enviarCorreo(mensaje) {
      if (this.correoSelect === "") {
        //console.log("No ha seleccionado Correo");
        return;
      }

      this.switchCorreo = true;
      //console.log("Enviar Correo: " + this.correoSelect);
      //console.log(mensaje.mensajeCliente);
      const mensajeConEtiqueta =
        "Re envio de correo <cliente>" + mensaje.mensajeCliente;

      this.input = mensajeConEtiqueta;
      this.agregaObservacion();
    },

    getSwitch(valor) {
      if (valor) {
        return "Si";
      } else {
        return "No";
      }
    },

    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(
          /:\d{2}\sGMT-\d{4}\s\((.*)\)/,
          (match, contents, offset) => {
            return ` ${contents
              .split(" ")
              .map((v) => v.charAt(0))
              .join("")}`;
          }
        ),
      });

      this.input = null;
    },

    fechaConvertirFirebase(timestamp) {
      // Verifica si timestamp es undefined o nulo
      if (!timestamp || !timestamp.seconds) {
        return "Fecha no disponible"; // o cualquier otro valor por defecto
      }

      // Convierte el objeto Timestamp a una fecha JavaScript
      const fecha = new Date(timestamp.seconds * 1000);

      // Obtén la fecha y hora en un formato legible
      const fechaHoraLegible = fecha.toLocaleString();

      return fechaHoraLegible;
    },

   

    agregaEtiquetaCliente() {
      if (this.input.endsWith(" <cliente>")) {
        this.input = this.input.slice(0, -10);
      } else {
        this.input += " <cliente>";
      }
    },

    separarTextoCliente(texto) {
      const etiquetaCliente = "<cliente>";
      const indiceEtiqueta = texto.indexOf(etiquetaCliente);

      if (indiceEtiqueta !== -1) {
        const observacion = texto.substring(0, indiceEtiqueta).trim();
        const mensajeCliente = texto
          .substring(indiceEtiqueta + etiquetaCliente.length)
          .trim();

        return { observacion, mensajeCliente };
      }

      // Si no se encuentra la etiqueta, se asigna todo el texto a "observacion"
      return { observacion: texto.trim(), mensajeCliente: "" };
    },
  },
};
</script>

<style>
div pre {
  white-space: pre-line;
}
.relative-position {
  position: relative;
}

.button-overlay {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 15px;
  transform: translate(50%, -50%);
  z-index: 1;
}

.mensajeCliente {
  border-bottom: 1px solid #00fefa;
  padding: 10px;
  font-size: 14px;
  color: #474747;
  margin-top: 0;
  padding: 10px;
  border-radius: 15px;
  background-color: #9afffd;
}

.mensajeGestion {
  border-bottom: 1px solid orange;
  padding: 10px;
  font-size: 14px;
  color: #474747;
  margin-top: 0;
  padding: 10px;
  border-radius: 15px;
  background-color: rgb(255, 220, 155);
}

.mensajeClienteB {
  max-width: 300px; /* Ajusta el ancho máximo según tus preferencias */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.custom-div {
  border-radius: 10px;
  background-color: #424242;
  /* max-width: 90%;  Ajusta el valor según el ancho deseado */
}

.ancho-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
</style>