<template>
  <div>
    <gestionJuridico />
  </div>
</template>

<script>
import gestionJuridico from "./GestionJuridico/gestionJuridico.vue";

export default {
  name: "contenedorJuridico",
  data: () => ({}),
  props: {},
  components: {
    gestionJuridico,
  },
};
</script>