<template>
  <v-card class="mx-auto" max-width="344">
    <v-img
      src="https://img.freepik.com/vector-gratis/ilustracion-concepto-tablero-scrum_114360-1570.jpg?w=2000&t=st=1692024655~exp=1692025255~hmac=0f870c9ace88404d62c78c8dc7463907830003d286b4367f0fd110ad4e4de2cc"
      height="200px"
    ></v-img>

    <v-card-title>Crea Categorias</v-card-title>

    <v-card-subtitle>Podrás administrar las categorias para tu catálogo onLine </v-card-subtitle>

    <v-card-actions>
      <categorias />

      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
         Administra el nombre de la tienda y las Categorias que comprenden tus productos
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Categorias from "./Categorias.vue";
export default {
  data: () => ({
    show: false,
  }),
  components: {
    Categorias,
  },
};
</script>