<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      hide-no-data
      hide-selected
      item-text="label"
      item-value="label"
      label="Nombre empresa..."
      placeholder="Escribe aquí..."
      prepend-icon="mdi-database-search"
      return-object
      color="amber"
    ></v-autocomplete>

    <v-expansion-panels class="mt-2" elevation="0">
      <v-expansion-panel
        v-for="(item, i) in 1"
        :key="i"
        style="background: #fff1c2; color: #414141"
        
      >
        <v-expansion-panel-header> Ficha Cliente </v-expansion-panel-header>
        <v-expansion-panel-content >
          <v-expand-transition >
            <v-list v-if="model" style="background: #fff1c2; color: #414141">
              <v-list-item v-for="(field, i) in fields" :key="i" style="background: #fff1c2; color: #414141">
                <v-list-item-content class="mt-n6">
                  <v-list-item-title>
                    {{
                      field.label === "TimeR"
                        ? fechaConvertirFirebase(field.value)
                        : field.value
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      field.label === "TimeR" ? "Fecha Registro" : field.label

                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expand-transition>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="pa-2">
      <v-btn :disabled="!model" color="amber" @click="clearModel">
        Comenzar de nuevo
        <v-icon right>mdi-close-circle</v-icon>
      </v-btn>
    </div>

    <br />
  </div>
</template>
  
  <script>
import { FBbuscadorClienteNityRazonSocial } from "@/components/Ventas/buscadorCorporativo.js";

export default {
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    juegoDeDatosClienteBuscar: null,
  }),

  computed: {
    fields() {
      if (!this.model) return [];

      const entry = this.model.data;
      //console.log(this.model);

      //juegoDeDatosClienteBuscar

      this.$emit("my-event", entry);

      return [
        {
          label: "País",
          value: entry.pais || "n/a",
        },
        {
          label: "Ciudad",
          value: entry.ciudad || "n/a",
        },
        {
          label: "Sitio Web",
          value: entry.sitioWeb || "n/a",
        },
        {
          label: "Correos",
          value: entry.correos?.join(", ") || "n/a",
        },
        {
          label: "Teléfonos",
          value: entry.telefonos?.join(", ") || "n/a",
        },
        {
          label: "Dirección",
          value: entry.dir || "n/a",
        },
        {
          label: "Investigar",
          value: entry.investigar || "n/a",
        },
        {
          label: "TimeR",
          value: entry.timeR || "n/a",
        },
      ];
    },
    items() {
      return this.entries.map((entry) => {
        return {
          label: entry.data?.cliente || "n/a",
          id: entry.id,
          data: entry, // Agregamos el objeto completo como propiedad 'data'
        };
      });
    },
  },

  watch: {
    async search(val) {
      // Los elementos ya se han cargado.
      if (this.items.length > 0) return;

      // Los artículos ya han sido solicitados.
      if (this.isLoading) return;

      this.isLoading = true;

      try {
        // Realizar la consulta a la base de datos
        const documentosEncontrados = await FBbuscadorClienteNityRazonSocial(
          val
        );

        // Asignar los resultados de la consulta a "entries"
        this.entries = documentosEncontrados;

        //console.log(this.entries);

        // Establecer isLoading en falso después de cargar los datos
        this.isLoading = false;
      } catch (error) {
        console.error("Error al buscar documentos:", error);
        // Manejar el error de alguna manera apropiada para tu caso
      }
    },
  },

  methods: {
    clearModel() {
      this.model = null;
      this.entries = [];
    },
    fechaConvertirFirebase(timestamp) {
      // Convierte el objeto Timestamp a una fecha JavaScript
      const fecha = new Date(timestamp.seconds * 1000); // Multiplica por 1000 para convertir segundos a milisegundos

      // Obtén la fecha y hora en un formato legible
      const fechaHoraLegible = fecha.toLocaleString(); // Utiliza el método toLocaleString() para obtener la fecha y hora en el formato local del usuario

      //console.log(fechaHoraLegible);
      return fechaHoraLegible;
    },
  },
};
</script>
  