import firebase from 'firebase/compat/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import "firebase/compat/database";
import  'firebase/compat/functions';

import { config } from './datos/config.js';

const firebaseNode = firebase.initializeApp(config);
const db = firebase.firestore();
const dbReal = firebase.database()
const storage = firebase.storage();
const auth = getAuth();
const functions = firebase.functions();


export { firebase, firebaseNode, db, dbReal, storage, signInWithEmailAndPassword, createUserWithEmailAndPassword, auth, functions };