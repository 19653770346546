<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-for="button in buttons"
          :key="button.icon"
          fab
          dark
          small
          color="black"
          @click="openModal(button.icon)"
          v-bind="attrs"
          v-on="on"
          class="pa-2"
          
        >
          <v-icon dark>{{ button.icon }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 amber">
          Guarda la URL de {{ selectedButtonName }} <br />
          <v-text-field
            v-model="url"
            label="Pega la URL"
            prepend-icon="mdi-link-variant"
            color="black"
          ></v-text-field>
        </v-card-title>

        <v-card-text> </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="black" text @click="saveData">Guardar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      buttons: [
        { icon: "mdi-google-drive", name: "Google Drive" },
        { icon: "mdi-youtube", name: "YouTube" },
        { icon: "mdi-microsoft", name: "Microsoft" },
        { icon: "mdi-link", name: "Link" },
        { icon: "mdi-linkedin", name: "LinkedIn" },
        { icon: "mdi-facebook", name: "Facebook" },
        { icon: "mdi-instagram", name: "Instagram" },
      ],
      selectedButton: null,
      url: "",
    };
  },
  computed: {
    activatorButtonLabel() {
      return this.selectedButton ? this.selectedButton.name : "Click Me";
    },
    selectedButtonName() {
      return this.selectedButton ? this.selectedButton.name : "Privacy Policy";
    },
  },
  methods: {
    openModal(icon) {
      this.selectedButton = this.buttons.find((button) => button.icon === icon);
      this.dialog = true;
      console.log("Abrir modal con el icono:", icon);
    },
    saveData() {
      if (this.url) {
        const data = {
          button: this.selectedButton,
          url: this.url,
        };
        console.log(data);
        this.$emit("save-data", data);
      }
      this.dialog = false;
    },
  },
};
</script>


  
  