<template>
  <v-carousel height="250">
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      transition="fade-transition"
      :progress="true"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "slider01",
  data: () => ({
    model: 0,
    items: [
      {
        src: "https://firebasestorage.googleapis.com/v0/b/bybabogados-138f2.appspot.com/o/Imagenes%2Fbanner%2Fbase-2.png?alt=media&token=23ffdf52-e66b-419f-9fac-0fa901b08ced",
      }
    ],
  }),
};
</script>