<template>
  <div class="text-center">
    <v-dialog v-model="dialog" transition="dialog-top-transition" scrollable>
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
          </template>
          <span>Listado Clientes</span>
        </v-tooltip>
      </template>

      <v-card dark>
        <v-card-title>Listado Clientes</v-card-title>

        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-list lines="one">
            <v-list-item v-for="item in items2" :key="item.title">
              <!-- 
                <v-list-item-icon>
                  <v-icon v-if="item.icon" color="pink"> mdi-star </v-icon>
                </v-list-item-icon>
              -->

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.info.data.cliente
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    >Nit.: {{ item.info.data.nit }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <!-- v-model="item.data.estado" :disabled="rolPrueba != 'g'" :label="`Usuario: ${textoSw(item.data.estado)}`"
               @change="actualizaCampo('estado', item.id, item.data.estado)"-->

              <v-divider vertical class="pa-3" inset></v-divider>
              <span class="pa-3">{{ textoEstado(item.info.data.estado) }}</span>
              <v-switch
                v-model="item.info.data.estado"
                @change="actualiza('estado', item.id, item.info.data.estado)"
                color="amber"
              ></v-switch>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon v-bind="attrs" v-on="on">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      icon
                      color="deep-orange"
                      @click="pdfLink(item.id, item.info.data.nit, 'cuentas')"
                    >
                      <v-icon dark> mdi-file-pdf-box </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </template>
                <span>PDF Certificados Cuentas</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon v-bind="attrs" v-on="on">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      icon
                      color="primary"
                      @click="pdfLink(item.id, item.info.data.nit, 'endoso')"
                    >
                      <v-icon dark> mdi-file-pdf-box </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </template>
                <span>PDF Carta de Endoso</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon v-bind="attrs" v-on="on">
                    <detalleCliente
                      v-bind:titulo="
                        item.info.data.cliente +
                        ' Dep.' +
                        item.info.data.cargoCP
                      "
                      v-bind:contactos="item.info.data.contactoP"
                      v-bind:cel="item.info.data.celCP"
                      v-bind:mail="item.info.data.mailCP"
                    />
                  </v-list-item-icon>
                </template>
                <span>Dep. {{ item.info.data.cargoCP }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-icon v-bind="attrs" v-on="on">
                    <detalleCliente
                      v-bind:titulo="
                        item.info.data.cliente +
                        ' Dep.' +
                        item.info.data.cargoCS
                      "
                      v-bind:contactos="item.info.data.contactoS"
                      v-bind:cel="item.info.data.celCS"
                      v-bind:mail="item.info.data.mailCS"
                    />
                  </v-list-item-icon>
                </template>
                <span>Dep. {{ item.info.data.cargoCS }}</span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          {{ resultadoPDF }}
          <v-spacer></v-spacer>

          <v-btn color="amber" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  FBverClientes,
  FBverPDFClientes,
  FBactualizaCliente,
} from "../../../components/Cartera/verClientes.js";
import detalleCliente from "./detalleCliente.vue";

export default {
  name: "verClientes",
  props: [],
  components: { detalleCliente },
  data: () => ({
    dialog: false,
    items: [
      {
        icon: true,
        title: "Ident Corp",
        avatar:
          "https://cdn.icon-icons.com/icons2/2483/PNG/512/customer_icon_149954.png",
      },
    ],
    items2: [],
    cards: [
      {
        title: "EPS",
        src: "https://cdn.pixabay.com/photo/2018/01/31/05/43/web-3120321_960_720.png",
        flex: 4,
        click: true, //General
        url: "https://google.com",
        datos: "usuario: A contraseña: B",
      },
    ],
    tipoServicio: [],
    resultadoPDF: "...",
  }),
  mounted() {},
  beforeMount() {
    const registros = async () => {
      //console.log("Entra a buscar");
      const result = await FBverClientes();
      //console.log(result);
      this.items2 = result;
    };

    registros();
  },
  methods: {
    async pdfLink(id, nit, tipo) {
      //console.log(id, nit, tipo);

      const rest = await FBverPDFClientes(id, nit, tipo);
      //console.log(rest);

      if (rest) {
        window.open(rest, "_blank");
        this.resultadoPDF = "Abriendo archivo.";
      } else {
        //console.log("No existe el archivo");
        this.resultadoPDF = "No existe el archivo.";
      }
    },
    async actualiza(campo, id, valor) {
      const result = await FBactualizaCliente(campo, id, valor);

      console.log(result);
    },
    textoEstado(a) {
      switch (a) {
        case true:
          return "Servicio Activo";
          break;
        case false:
          return "Servicio Pausa";
          break;
        default:
          break;
      }
    },
  },
  computed: {
    retornaEstadoMensaje() {
      //return (this.sheet = this.estadoMensaje);
    },
  },
  watch: {
    resultadoPDF: {},
  },
};
</script>

<style scoped>
/*Reversed input variant */
</style>