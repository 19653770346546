import { firebase, db, storage } from "../../../firebase/config"

const ref = storage.ref();

//nit, idC, idR
export async function FBagregaDatosDA(key, value, nit, idC, idR, ivDA) {
    try {
        let valueUpdate = {};

        if (key !== 'puntaje') {
            valueUpdate[`${ivDA}.${key}`] = value;
        }

        const titularRef = db.collection('cf-cartera').doc('Carteras').collection(nit).doc(idC).collection('registros').doc(idR);

        await titularRef.update(valueUpdate);

        return true;
    } catch (error) {
        console.log('Error:', error);
        return false;
    }
}


