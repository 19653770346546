var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"600px","persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-2",attrs:{"outlined":"","small":"","icon":"","color":"green darken-4"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"scrollable":"","max-width":"600px","dark":""}},[_c('v-card-title',{staticClass:"green"},[_vm._v(" WhatsApp "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('div',{staticClass:"caption"},[_vm._v("Nuevo Contacto "+_vm._s(_vm.contacto))]),_c('v-spacer'),_c('div',{staticClass:"body-2 mr-5"},[_vm._v(_vm._s(_vm.indicativoSelect)+_vm._s(_vm.celular))]),_c('v-btn',{attrs:{"x-small":"","icon":"","color":"green","disabled":""}},[_c('v-icon',[_vm._v("mdi-hand-wave-outline")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-card',{attrs:{"scrollable":"","max-width":"500px","elevation":"0"}},[_c('div',{staticClass:"message-scroll"},[(_vm.switchTiempoReal)?_c('v-list',[_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"orange","size":"24"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(index))])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.usuario !== undefined && item.usuario !== null ? item.usuario : _vm.usuarioW))]),(item.body.type === 'button')?_c('v-list-item-title',{class:[
                          _vm.colorChat(item.type),
                          'text-left',
                          'text-wrap',
                        ]},[_c('div',{staticClass:"secondary text-no-wrap rounded-pill d-inline pa-1 deep-purple accent-4 white--text"},[_vm._v(" "+_vm._s(item.body.button.text)+" ")])]):(item.body.type === 'sticker')?_c('v-list-item-title',{class:[
                          _vm.colorChat(item.type),
                          'text-left',
                          'text-wrap',
                        ]},[_c('img',{staticClass:"rounded-xxl",attrs:{"src":item.urlFinal,"contain":"","lazy-src":"https://picsum.photos/id/11/10/6","aspect-ratio":16 / 9,"height":"150"}})]):(item.body.type === 'image')?_c('v-list-item-title',{class:[
                          _vm.colorChat(item.type),
                          'text-left',
                          'text-wrap',
                        ]},[_c('button',[_c('img',{staticClass:"rounded-xxl elevation-5 row-pointer",attrs:{"src":item.urlFinal,"contain":"","lazy-src":"https://picsum.photos/id/11/10/6","aspect-ratio":16 / 9,"height":"150"},on:{"click":function($event){return _vm.abrirEnlace(item.urlFinal)}}})]),(item.body.image?.caption)?_c('div',[_c('br'),_c('p',[_vm._v(_vm._s(item.body.image?.caption))])]):_vm._e()]):(item.body.type === 'reaction')?_c('v-list-item-title',{class:[
                          _vm.colorChat(item.type),
                          'text-left',
                          'text-wrap',
                        ]},[_vm._v(" "+_vm._s(item.body.reaction.emoji)+" ")]):(item.body.type === 'video')?_c('v-list-item-title',{class:[
                          _vm.colorChat(item.type),
                          'text-left',
                          'text-wrap',
                        ]},[_c('button',{on:{"click":function($event){return _vm.abrirEnlace(item.urlFinal)}}},[_c('div',{staticClass:"secondary rounded-pill d-inline pa-1 mt-3s deep-purple accent-4 white--text"},[_c('v-icon',[_vm._v("mdi-movie")]),_vm._v(" Video - Multimedia ")],1)]),(item.body.video?.caption)?_c('div',[_c('br'),_c('p',[_vm._v(_vm._s(item.body.video?.caption))])]):_vm._e()]):(item.body.type === 'audio')?_c('v-list-item-title',{class:[
                          _vm.colorChat(item.type),
                          'text-left',
                          'text-wrap',
                        ]},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.playSound(item.urlFinal)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-play ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.stopSound()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-stop ")])],1)],1):_c('v-list-item-title',{class:[
                          _vm.colorChat(item.type),
                          'text-left',
                          'text-wrap',
                        ]},[_vm._v(" "+_vm._s(item.body.text?.body || item.body)+" ")]),_c('v-list-item-title',{staticClass:"text-right caption mt-2"},[_c('v-btn',{staticClass:"mr-3",attrs:{"disabled":item.body.type === 'audio' ||
                            item.body.type === 'sticker' ||
                            item.body.type === 'button',"icon":"","color":"amber","x-small":""},on:{"click":function($event){return _vm.copiarTexto(
                              item.body.text?.body ||
                                item.body.image?.caption ||
                                item.body.video?.caption
                            )}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),(item.type !== undefined)?_c('v-chip',{staticClass:"mr-3",attrs:{"x-small":"","color":"orange","outlined":""}},[_vm._v(" "+_vm._s(item.type)+" ")]):_vm._e(),_c('v-chip',{attrs:{"small":"","color":"green","outlined":""}},[_vm._v(" "+_vm._s(item.usuario !== undefined && item.usuario !== null ? item.usuario : _vm.usuarioW)+" - "+_vm._s(_vm.fechaConvertirFirebase(item.timestamp))+" ")])],1),_c('v-divider',{staticClass:"mt-2",attrs:{"color":"green"}})],1)],1)})],2):_vm._e()],1)]),(_vm.items.length <= 0 ? true : false)?_c('p',[_vm._v(" En caso que el usuario no tenga mensajes se debe enviar primero la plantilla de Autorización y esperar a que responda. ")]):_vm._e(),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-textarea',{attrs:{"label":_vm.items.length <= 0
                    ? 'Debe enviarse la plantilla Autorización...'
                    : 'Escribe...',"auto-grow":"","outlined":"","rows":"2","row-height":"25","shaped":"","color":"green","disabled":_vm.items.length <= 0},model:{value:(_vm.mensaje),callback:function ($$v) {_vm.mensaje=$$v},expression:"mensaje"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":_vm.conversar}},[_c('v-icon',[_vm._v("mdi-send")])],1)],1)],1),_c('v-row',{staticClass:"mt-n10"},[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.indicativos,"label":"Indicativo","outlined":"","color":"orange"},model:{value:(_vm.indicativoSelect),callback:function ($$v) {_vm.indicativoSelect=$$v},expression:"indicativoSelect"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"color":"green","label":"Número de contacto","placeholder":"Indicativo Número de contacto","outlined":""},model:{value:(_vm.celular),callback:function ($$v) {_vm.celular=$$v},expression:"celular"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-n10"},[_c('v-col',[_c('v-text-field',{attrs:{"color":"green","label":"Nombre Contacto","placeholder":"Nombre Usuario","outlined":""},model:{value:(_vm.contacto),callback:function ($$v) {_vm.contacto=$$v},expression:"contacto"}})],1),_c('v-col',[_c('v-select',{attrs:{"color":"green","items":_vm.plantillas,"label":"Plantilla","outlined":""},model:{value:(_vm.plantilla),callback:function ($$v) {_vm.plantilla=$$v},expression:"plantilla"}})],1)],1)],1),_c('v-divider',{staticClass:"mt-n8"}),_c('v-card-actions',{staticClass:"mt-n3"},[_c('v-switch',{attrs:{"label":`Consultar e Iniciar`,"color":"green"},on:{"change":_vm.listenToMessages},model:{value:(_vm.switchTiempoReal),callback:function ($$v) {_vm.switchTiempoReal=$$v},expression:"switchTiempoReal"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":_vm.reiniciar}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }