import { db } from "../../firebase/config";

// Trae los modulos con su configuración
export async function FBtraeModulos() {
  try {
    const docRef = db.collection("recursos").doc("modulos");
    const doc = await docRef.get();

    if (doc.exists) {
      return doc.data();
    } else {
      return {};
    }
  } catch (error) {
    console.error('Error:', error);
    return {};
  }
}

