import { auth, createUserWithEmailAndPassword, db, firebase, storage, functions } from '../../firebase/config';

const ref = storage.ref();

async function generateRandomPassword(length = 8) {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let password = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }
  
    // Verificar si la contraseña cumple con los requisitos mínimos de Firebase
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    if (!password.match(passwordRegex)) {
      // Si no cumple, generar una nueva contraseña recursivamente
      return generateRandomPassword(length);
    }
  
    return password;
  }
  

export async function FBprobar() {
    console.log("Ingresa a Probar");
   // const result = await generateRandomPassword();
   // console.log(result);
}


export async function FBcreaEmpleado(datosFormulario, hojaDeVida) {
    try {
        // Crear el usuario en la base de datos
        const empleadoRef = db
            .collection('cf-recursoHumano')
            .doc('RecursoHumano')
            .collection('Empleados');

        const res = await empleadoRef.add({
            'data': datosFormulario,
            'timeR': [firebase.firestore.Timestamp.now()], //fecha registro
            'hdvPDF': '',
            'puntaje': 0,
        });

        // Generar una contraseña aleatoria
        const password = await generateRandomPassword();

        //console.log(password);

        // Crear el usuario en la autenticación de Firebase
        const authResult = await createUserWithEmailAndPassword(auth, datosFormulario.correo, password);
        // Resto del código para el caso de éxito

        //Enviar un correo al usuario con la contraseña 
        const enviarCorreo = functions.httpsCallable("creaEmpleado");

        const datosFormularioConPassword = {
            ...datosFormulario,
            'password': password
          };

        // Llama a la función de Firebase Cloud Functions para enviar el correo electrónico
        await enviarCorreo(datosFormularioConPassword);

        //console.log(user);

        // Si se creó exitosamente
        if (authResult) {
            // Crea referencia para agregar el tema de login
            const empleadoLogin = await db
                .collection('cf-recursoHumano')
                .doc('RecursoHumano')
                .collection('Login')
                .doc(datosFormulario.cedula)
                .set({ marca: [] });

            return [true, res.id, password];
        }

        return false;
    } catch (error) {
        console.error('error: ' + error);
        return false;
    }
}



//Actualiza el campo de hoja de vida
async function subeInfo(idUsuario, urlHdv) {

    //Esta Parte la creé para guardar la url del archivo de la hoja de vida, sin embargo,
    //se necesita el token para poder ingresar al archivo, solo la dirección no es suficiente. 
    //console.log(urlHdv);
    //console.log('Actualizando url subeInfo');

    const urlPDF = urlHdv.ref.bucket + '/' + urlHdv.ref.fullPath;

    const upDateEmpleadoRef = db.collection('cf-recursoHumano').doc('RecursoHumano').collection('Empleados').doc(idUsuario);
    // Set the 'capital' field of the city
    const res = await upDateEmpleadoRef.update({ hdvPDF: urlPDF });

    return true;

}


//Carga PDF hoja de vida
export async function FBhdvEmpleado(idDb, cedula, hojaDeVida) {
    try {
        //Crear el usuario en la base de datos

        //console.log('Ingresando a guardar el documento');
        //Se declara la ruta
        const refImag = ref.child("st-recursoHumano/Empleado/" + idDb + "/" + "hdv" + "/" + "hdv-" + cedula + ".pdf");
        const respuesta = await refImag.put(hojaDeVida);

        console.log('-----------');

        //respuesta.ref 
        //{bucket, fullPath, name, }
        //console.log(respuesta.ref);//Trae toda la info de la subida
        const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

        //console.log(respuesta);
        //console.log(urlPDF);

        const respuesta2 = await subeInfo(idDb, respuesta);

        //Sale de actualizar la url de 

        return ([true, urlPDF])

    } catch (error) {
        console.error('error: ' + error);
        return false;
    }
}

//Validar si existe registro
export async function FBvalidaUsuario(datosFormulario) {
    try {
        //const cityRef = db.collection('cities').doc('SF');
        const empleadoRef = db.collection('cf-recursoHumano').doc('RecursoHumano').collection('Empleados');
        const snapshot = await empleadoRef.where('data.' + 'cedula', '==', datosFormulario.cedula).get();
        let infoResult = [];
        if (snapshot.empty) {
            //console.log('No matching documents.');
            //No existe usuario
            infoResult.push(true, '0')
            return infoResult;
        }
        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            //console.log(doc.data().data.cedula);
            infoResult.push(false, doc.data().data.cedula);
        });

        return infoResult;
    } catch (error) {
        return error
    }

}




/*const o = firebase.firestore.Timestamp
const t = firebase.firestore.Timestamp.fromDate(new Date());
const ft = new Date(t.seconds * 1000 + t.nanoseconds / 1000000,);
const d = t.toDate();
const m = t.toMillis();
const r = t.valueOf();
const n = t.toDate().toDateString();
const z = new Date(o.now().seconds*1000).toLocaleDateString();
const timeNow = ft.toLocaleTimeString();

console.log('o: ', o);
console.log('t: ', t);
console.log('d: ', d);
console.log('m: ', m);
console.log('r: ', r);
console.log('n: ', n);
console.log('z: ', z);
console.log('atTime: ', timeNow);
console.log(z +' '+ timeNow);*/

