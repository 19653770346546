import { firebase, db, storage } from "../../firebase/config"

const ref = storage.ref();

//Crea al empleados
export async function FBcreaCliente(data, certificados, endoso) {

    // /cf-cartera/config/Clientes/PoOTzJ4OAxKpBbvqPA01

    try {
        //Crear el usuario en la base de datos
        const empresaRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const res = await empresaRef.add({
            'data': data,
            'timeR': [firebase.firestore.Timestamp.now()], //fecha registro
            'contrato': '',
        });
        //console.log(res); /**/
        //Si se creó exitosamente

        //guardar medios de pago
        const respuestaSubeMediosDePago = FBmediosPagoPDF(res.id, data.nit, certificados, endoso)

        return ([respuestaSubeMediosDePago, res.id])

    } catch (error) {
        //console.error('error: ' + error);
        return false;
    }
}

//Carga PDF hoja de vida
async function FBmediosPagoPDF(idDb, nit, certificados, endoso) {
    //console.log('FBmediosPago');
    //console.log(certificados);
    //console.log(endoso);
    try {
        //Crear el usuario en la base de datos

        ///st-cartera/Clientes/DocumentosCreacion
        //Se declara la ruta
        const refCertificados = ref.child("st-cartera/Clientes/" + nit + "/DocumentosCreacion/" + idDb + "/entidades-de-pago.pdf");
        const respuestaA = await refCertificados.put(certificados);

        const refCartaEndoso = ref.child("st-cartera/Clientes/" + nit + "/DocumentosCreacion/" + idDb + "/carta-de-endoso.pdf");
        const respuestaB = await refCartaEndoso.put(endoso);

        //console.log('-----Entidades de Pago------');

        //respuesta.ref 
        //{bucket, fullPath, name, }
        //console.log(respuesta.ref);//Trae toda la info de la subida
        //const urlPDF = respuesta.ref.bucket +'/'+respuesta.ref.fullPath;

        //return ([true, urlPDF])
        return true;

    } catch (error) {
        console.error('error: ' + error);
        return false;
    }
}

//Busca Clientes
export async function FBbuscaCliente(nit) {


    try {
        const nitNewRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const snapshot = await nitNewRef.where('data.nit', '==', nit).get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            //False no existe
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
        });

        //True es existe
        return true;

    } catch (error) {
        console.log(error);
    }

}