<template>
  <div v-show="rolUsuario == 'acm' || rolUsuario == 'g'">
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar  dark dense>
            <v-toolbar-title>50 Titulares con mayor dias</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-spacer></v-spacer>
            <v-btn @click="consultaBase()" icon>
              <v-icon>mdi-update</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :items-per-page="5"
            :headers="headers"
            :items="desserts"
            :search="search"
          >
            <template v-slot:item.diasVencidos="{ item }">
              <v-chip :color="getColor(item.diasVencidos)" dark>
                {{ item.diasVencidos }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
        
<script>
import { FBLlamadasViejitas } from "../../../../components/Cartera/panelesACM/consultasBasicaViejitas";
export default {
  name: "gestionHoyACM",
  components: {},
  props: ["rolUsuario", "idUsuario"],
  data: () => ({
    search: "",
    calories: "",
    desserts2: [
      {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: 1,
      },
    ],
    desserts: [
      {
        name: "Cargando...",
        calories: 0,
      },
    ],
  }),
  mounted() {
    this.consultaBase();
  },
  methods: {
    async consultaBase() {
      this.desserts = [];
      const result = await FBLlamadasViejitas(this.idUsuario);
      //console.log(result);
      this.desserts = result;
    },
    getColor(calories) {
      if (calories > 120) return "red";
      else if (calories > 90) return "orange";
      else if (calories > 30) return "amber";
      else return "blue";
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Cédula",
          align: "start",
          sortable: false,
          value: "Cedula",
        },
        {
          text: "Tipificación",
          value: "tipiUlti",
          filter: (value) => {
            if (!this.calories) return true;

            return value < parseInt(this.calories);
          },
        },
        { text: "Dias Vencidos", value: "diasVencidos" },
      ];
    },
  },
};
</script>
