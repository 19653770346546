import { firebase, db, storage } from "../../firebase/config"

const ref = storage.ref();

//Carga registros a la cartera seleccionada
export async function FBtitularesCarteraSeleccionada(selectCliente, selectCarteras) {
    //console.log('Titulares Cartera Seleccionada');

    //console.log(selectCliente, selectCarteras);
    //console.log(selectCliente, selectCarteras, carteraLoad);
    try {
        //const rutaRegistrosCartera = db.collection('cf-cartera').doc('Carteras').collection(selectCliente.nit).doc(selectCarteras.id).collection('registros');
        const rutaRegistrosCartera = db.collection('cf-cartera').doc('Carteras').collection(selectCliente.nit).doc(selectCarteras.id).collection('registros').where("asignadoActual", "array-contains", 0);
        const capitalNotFalseRes = await rutaRegistrosCartera.get();

        let arraySale = [];
        if (capitalNotFalseRes.empty) {
            //console.log('No matching documents.');
            return;
        }

        capitalNotFalseRes.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            let fusion = doc.data();
            fusion.id = doc.id
            arraySale.push(fusion);
        });



        return [true, arraySale];
    } catch (error) {
        console.log(error);
        return [false];
    }
}

//Carga la asignación realizada por el gerente
//this.selectCliente,this.selectCarteras,this.carteraLoad,this.arrayFinalAsesores
export async function FBasignarCartera(selecCliente, selectCarteras, carteraLoad, arrayFinalAsesores) {
    //console.log(selecCliente, selectCarteras, carteraLoad, arrayFinalAsesores);
    let nombreCliente = selecCliente.nombre;//Sophia
    let nitCliente = selecCliente.nit;//902

    let idCartera = selectCarteras.id;
    let nombreCartera = selectCarteras.nombre;

    //carteraLoad Es la cartera total sin asignacion
    //La cartera asignada arrayFinalAsesores (Asesor, Cartera, Total, totalAsignados)

    try {
        //------------------------NUEVA SECCION 
        //Guardar la configuración de la cartera.total asesores / estado / nombre cartera / modificaciones.
        //Barrio guarda si el Asesor ya carga esos titulares a su bolsa
        //console.log('***************************************************************');
        let totalAsesoresdeCartera = [];
        arrayFinalAsesores.forEach(async element => {
            let idAsesor = element.Asesor.id;
            //console.log(element);

            //console.log('asesor:', idAsesor);
            //console.log('Total:', element.Cartera.TOTAL);
            //console.log('Asignados:', element.Cartera.totalAsignados);

            let AsesoresLimpios = { idAsesor: idAsesor, puntajeCartera: 0, monto: element.TOTAL, asignados: element.totalAsignados, barrio: false };
            totalAsesoresdeCartera.push(AsesoresLimpios)
            //const resultCarteraAsesores = await guardaAsesoresEnCartera.update({ asesores: arrayUnionB(AsesoresLimpios) });

        });

        //const arrayUnionB = firebase.firestore.FieldValue.arrayUnion;
        //BORRA la asignación acual Asesores
        const guardaAsesoresEnCartera = db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(idCartera);
        const resultCarteraAsesores = await guardaAsesoresEnCartera.update({ asesores: totalAsesoresdeCartera });

        //console.log('***************************************************************');
        /***  FIN NUEVA SECCION */

        //Recorremos el array que llega del asesor
        arrayFinalAsesores.forEach(element => {
            /*console.log('Asesor');
            console.log(element.Asesor.id);
            console.log(element.Cartera);*/

            let idAsesor = element.Asesor.id;

            let carteraAsesor = element.Cartera;
            //Recorremos el array de los titualres asignados
            carteraAsesor.forEach(async elementCartera => {
                //console.log(elementCartera.id);
                //console.log(elementCartera.asignado);

                //1. Agregar al array la asignación. Se trae el array que existe y se agrega el nuevo.
                // 'asignado': [{ usuario: 0, estado: false, fecha: firebase.firestore.Timestamp.now(), }],
                let nueveoelementCartera = { usuario: idAsesor, estado: true, fecha: firebase.firestore.Timestamp.now() }
                const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

                //2. 

                ///cf-cartera/Carteras/902/4njopDRN1C50cWOEbVf5/registros/0DGytvQ0z75yunjNfkvZ
                const titularRef = db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(idCartera).collection('registros').doc(elementCartera.id);
                const result = await titularRef.update({
                    asignado: arrayUnion(nueveoelementCartera),
                    estado: true,
                    asignadoActual: [idAsesor],
                    respetableActual: [],//
                    docPedido: [false],//true o false para saber si se tiene el pedido o hay que solicitarlo
                })
            });

        });
        return true
    } catch (error) {
        console.log(error);
        return false
    }
}