import { firebase, db, storage } from "../../../firebase/config"
import store from "../../../store";

const ref = storage.ref();

//
export async function FBCSV1(datos) {
    //console.log(datos);
    let dataLlega = datos;
    let cliente = dataLlega.Cliente;
    let cartera = dataLlega.Cartera;
    let registro = dataLlega.Registro;
    let cedula = Number(dataLlega.cedula);


    const fecha = new Date();

    try {
        ////cf-cartera/Carteras/1017131215/UZXsgZJYfXXPR95cUWHC/registros/078uFz1gwPXeh5fnw2YA
        const clientesRef = db.collection('cf-cartera')
            .doc('Carteras')
            .collection(cliente)
            .doc(cartera)
            .collection('registros')
            .doc(registro)
        //.where('data.Cedula', '==', cedula);
        const doc = await clientesRef.get()//.where('data.estado', '==', true).get();   .limit(10)
        //.orderBy('fechaRegistro', 'desc')

        if (!doc.exists) {
            //console.log('No matching documents.');
            //False no existe
            //return false;
        } else {
            //console.log(doc.id, '=>', doc.data());
            var datosNuevos = doc.data().data;
            var Observaciones = doc.data().observaciones;
            //console.log(Observaciones);
            //data.push(doc.data());

            var Telefono = datosNuevos['Telefono 1'];
            var obsevaciones = [];
            //Miremos que tipificaciones ha tenido el titular.
            for (let index = 0; index < Observaciones.length; index++) {
                const efecto = Observaciones[index].efecto;
                //console.log(efecto);
                if (efecto != '') {

                    obsevaciones.push(efecto);
                }
            }

            var usuario = {
                Nombre: datosNuevos.Nombre,
                Cuenta: datosNuevos.Cuenta,
                Monto: datosNuevos.Monto,
                Email: datosNuevos.email,
                Telefonos: Telefono,
                Observaciones: obsevaciones,
            };
            return usuario;
        };


        return true;

    } catch (error) {
        console.log(error);
        return false;
    }

}
