<template>
  <div justify="center">
    <v-dialog v-model="dialog" scrollable max-width="80%" persistent>
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Generar Solicitud</span>
        </v-tooltip>
      </template>

      <v-card class="mx-auto">
        <v-toolbar color="black" dark>
          Generar Solicitud
          <v-divider></v-divider>
          {{ modulo }}
        </v-toolbar>

        <v-card-text>
          <v-container>
            <span class="grey--text text--lighten-1">Datos de Iniciales</span>
            <v-divider></v-divider>
            <!-- Sección 1 -->
            <v-row class="mt-n1">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.solicitante"
                  filled
                  color="blue-grey lighten-2"
                  label="Solicitante"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="11">
                    <v-select
                      v-model.trim="datosFormulario.importancia"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Nivel de prioridad"
                      :items="items"
                    ></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="pa-1 mt-4 ml-n5"
                      outlined
                      fab
                      x-small
                      color="black"
                    >
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="11">
                    <v-select
                      :items="objetivos"
                      v-model.trim="datosFormulario.objetivos"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Objetivo General"
                    ></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="pa-1 mt-4 ml-n5"
                      outlined
                      fab
                      x-small
                      color="black"
                    >
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- Sección 2 -->
            <v-row class="mt-n10">
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="11">
                    <v-select
                      v-model.trim="datosFormulario.proyecto"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Seleccione Proyecto"
                      :items="proyectos"
                    ></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="pa-1 mt-4 ml-n5"
                      outlined
                      fab
                      x-small
                      color="black"
                    >
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="11">
                    <v-select
                      v-model.trim="datosFormulario.doliente"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="¿A quién va la solicitud?"
                      :items="personalRol"
                    ></v-select>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      class="pa-1 mt-4 ml-n5"
                      outlined
                      fab
                      x-small
                      color="black"
                    >
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Inicio y Fin de la Solicitud"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      color="black"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" color="black" range scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="black" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="black" @click="$refs.dialog.save(dates)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <!-- Observaciones -->
            <v-row class="mt-n2">
              <v-col cols="12">
                <v-textarea
                  v-model.trim="datosFormulario.observaciones"
                  :disabled="isUpdating"
                  filled
                  color="black"
                  label="Redacta la solicitud o tarea..."
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Documentos -->
            <span class="grey--text text--lighten-1">Documentos y Referencias</span>
            <v-divider></v-divider>

            <v-row>
              <v-col class="mt-5" cols="12">
                <RepositoriosLogos />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loadCartera from "../../../components/loadFile/carteras/cartera.vue";
import RepositoriosLogos from "./DocumentosReferencias.vue";

export default {
  name: "agregaSolicitudes",
  props: ["modulo"],
  components: {
    loadCartera,
    RepositoriosLogos
  },
  data() {
    return {
      modal: false,
      dialog: false,
      isUpdating: false,
      datosFormulario: {
        solicitante: "",
        importancia: "",
        objetivos: "",
        proyecto: "",
        doliente: "",
        observaciones: "",
        estado: true,
      },
      items: ["Bajo", "Medio", "Alto"],
      objetivos: ["Objetivo 1", "Objetivo 2", "Objetivo 3", "N/A"],
      proyectos: ["Proyecto 1", "Proyecto 2", "Proyecto 3"],
      personalRol: ["Javier", "Mauricio", "Andrés"],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: ["2019-09-10", "2019-09-20"],
    };
  },
  methods: {
    /*

    Bajo:

      Plazo mínimo: 3 días hábiles
      Plazo máximo: 1 semana (5 días hábiles)

    Medio:

      Plazo mínimo: 2 días hábiles
      Plazo máximo: 10 días hábiles

    Alto:

      Plazo mínimo: 1 día hábil
      Plazo máximo: 3 días hábiles
    */
  },
  computed: {
    retornaEstadoMensaje() {
      //return (this.sheet = this.estadoMensaje);
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
};
</script>
