<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent dark>
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on" color="orange">
          mdi-swap-horizontal
        </v-icon>
      </template>

      <v-card v-if="!isUpdating">
        <v-card-title class="text-h5 amber"> Cambio de Estado </v-card-title>

        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              El siguiente proceso es para cambiar el estado del apoyo.
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="d-flex" cols="12">
              <v-select
                v-model="selectedItem"
                :items="items"
                item-text="title"
                item-value="panel"
                label="Cambiar a..."
              ></v-select>
            </v-col>
            <v-col>
              <v-card elevation="2">
                {{ selectedItemDescription }}
              </v-card>
            </v-col>
          </v-row>
        
          <v-row align="center">
            <v-col class="d-flex" cols="12">
              <v-btn block elevation="2" text color="orange" outlined  @click="cambiarProceso"
                >Cambiar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" text outlined @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>

      <!-- CARGADOR DE CAMBIOS -->
      <v-card  v-else>
        <v-card-title class="text-h5" color="transparent">
          {{ respuesta }}
        </v-card-title>

        <v-progress-linear indeterminate color="amber"></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
//import { FBconsultarEtapasProspectos } from "@/components/Ventas/consultas.js";
import { FBcambioProcesoProspecto } from "@/components/Ventas/cambioCategoriaApoyo.js";

export default {
  props: ["idRegistro", "etapa", "departamento", "etapaEspecifica", "posicion"],
  data() {
    return {
      isUpdating: false,
      respuesta: "...",
      dialog: false,
      items: [],
      selectedItem: null,
    };
  },

  computed: {
    selectedItemDescription() {
      if (this.selectedItem) {
        const selectedItem = this.items.find(
          (item) => item.panel === this.selectedItem
        );
        return selectedItem ? selectedItem.descripcion : "";
      }
      return "";
    },
  },

  created() {
    this.inicializar();
  },

  methods: {
    async inicializar() {
      //const resultado = await FBconsultarEtapasProspectos();
      this.items = this.etapa;
    },
    async cambiarProceso() {
      if (this.selectedItem) {
        this.respuesta = "Procesando...";
        this.isUpdating = true;
        //console.log("Cambiando a:", this.selectedItem);

        // Realiza las acciones necesarias para cambiar el proceso
        //  {{ idRegistro }} - {{ etapa }} - {{ departamento }} - {{ etapaEspecifica }}
        const json = {
          //etapa: this.etapa,//Actual
          idRegistro: this.idRegistro, //IdSeguimiento
          nuevoProceso: this.selectedItem, //La nueva etapa
          departamento: this.departamento,
          posicion: this.posicion,
        };

        //console.log(json);

        const result = await FBcambioProcesoProspecto(json);
        //console.log(result);
        if (result) {
          this.respuesta = "Se ha cambiado de categoria.";
          this.selectedItem = null;
          this.dialog = true;

          // Retrasar la desactivación de la bandera y la eliminación de la respuesta
          setTimeout(() => {
            this.isUpdating = false;
            this.respuesta = "";
            this.observacion = "";
            this.$emit("my-event");
          }, 2000); // Retraso de 2 segundos (ajusta el valor según tus necesidades)
        } else {
          this.respuesta = "Error, no se ha actualizado la categoria.";
          this.selectedItem = null;
          this.dialog = true;

          // Retrasar la desactivación de la bandera y la eliminación de la respuesta
          setTimeout(() => {
            this.isUpdating = false;
            this.respuesta = "";
            this.observacion = "";
            //this.$emit("my-event");
          }, 2000); // Retraso de 2 segundos (ajusta el valor según tus necesidades)
          console.log("No se actualizó el apoyo");
        }
      }
    },
  },
};
</script>
  