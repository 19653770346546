import { firebase, db, storage } from "../../../firebase/config"

const ref = storage.ref();

export async function FBHuella(titularActual, campo) {

    try {
        //console.log('Entra a huella');
        let idResponsable = titularActual.asignadoActual[0]
        let idCartera = titularActual.idCartera;
        let idTitular = titularActual.idRegistro;
        let cliente = titularActual.nitCliente;
        let nodo = campo;
        /*let value = valor;
        let valueUpdate = {};
        if (nodo != 'puntaje') {
            valueUpdate[`data.${nodo}`] = value;
        } else if (nodo == 'puntaje') {
            valueUpdate = { 'puntaje': Number(valor) }
        }*/
        let huella = { fecha: firebase.firestore.Timestamp.now(), tipoCambio: nodo, usuario: idResponsable, }

        const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
        const empleadoRef = db.collection('cf-cartera').doc('Carteras').collection(cliente).doc(idCartera).collection('registros').doc(idTitular);

        //Registra huella
        const result = await empleadoRef.update({
            timeRegistroModificado: arrayUnion(huella),
        })

        //console.log(result);
        //console.log('Sale de FBHuella ');

        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}