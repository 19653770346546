import { db, firebase, functions } from "../../firebase/config";
import { sendMensajeGeneral } from "@/Util/twilio/";
import moment from 'moment';

// Función para crear un prospecto en Firebase
export async function FBcreaProspecto(datos) {
  //console.log(datos);
  try {
    let investigarProspecto = true;

    if (datos.etapa != null || datos.etapa != '') {
      investigarProspecto = false;
    }

    // Define el JSON que deseas guardar Prospecto en Clientes ########### ---------- PASO # 1
    const jsonProspecto = {
      //...datos,
      data: {
        cargoCP: "",
        cargoCS: "",
        cargoCS: [],
        celCS: [],
        cliente: datos.cliente,
        contactoP: [],
        contactoS: [],
        estado: true,
        mailCP: [],
        mailCS: [],
        nit: datos.nit,
        servicio: [],//Muestra los servicios que el cliente ha adquirido
      },
      timeR: firebase.firestore.FieldValue.serverTimestamp(),
      contrato: "",
      pais: datos.pais,
      ciudad: datos.ciudad,
      sitioWeb: datos.sitio_web,
      telefonos: datos.telefono,
      dir: datos.direccion,
      correos: datos.correos,
      investigar: investigarProspecto
    };

    // Define la ruta donde deseas guardar el prospecto
    const collectionPathCreaProspecto = 'cf-cartera/Clientes/Listado';

    // Guarda el JSON en la ruta especificada y obtén la referencia al documento agregado
    const docClienteRef = await db.collection(collectionPathCreaProspecto).add(jsonProspecto);

    if (docClienteRef && docClienteRef.id) {
      //console.log('JSON guardado exitosamente Prospecto');
      //return true;
    } else {
      console.error('Error al guardar el JSON Prospecto');
      //return false;
    }

    const jsonProspeccion = {
      //...datos,
      //datos cliente para conectar con Clientes 
      cliente: datos.cliente,
      estado: true,
      nit: datos.nit,
      servicio: [],//Muestra los servicios que el cliente ha adquirido
      timeR: firebase.firestore.FieldValue.serverTimestamp(),
      //Cliente o Prospecto
      proyecto: datos.proyecto,
      objetivos: datos.objetivos,
      proxContacto: datos.date,
      llamar: datos.llamar,
      doliente: datos.doliente,
      contacto: datos.contacto,
      depContacto: datos.depContacto,
      cargoContacto: datos.cargoContacto,
      correoContacto: datos.correoContacto,
      whatsappContacto: datos.whatsappContacto,
      observaciones: datos.observaciones,
      //proceos
      etapa: datos.etapa,
      prioridad: datos.prioridad,
      //apoyo
      apoyo: datos.apoyo,
      //Notificaciones al Prospecto
      switchEnviarBienvenida: datos.switchEnviarBienvenida,
      switchEnviarBienvenidaSMS: datos.switchEnviarBienvenidaSMS,
    };
    //Convertir fecha a formato firebase
    jsonProspeccion.proxContacto = convertirFechaATimestamp(jsonProspeccion.proxContacto);

    //Ahora bien si datos.etapa es null es porque solo se está creando el Prospecto para luego hacer una etapa de prospección

    if (datos.etapa === null || datos.etapa === '') {
      // La variable datos.etapa es null o un string vacío
      // Realiza alguna acción aquí
    } else {
      // La variable datos.etapa tiene un valor distinto de null y no es un string vacío
      // Realiza otra acción aquí

      //Definir para que seccion va de Prospeccion  ########### ---------- PASO # 2
      // Define la ruta donde deseas guardar la tarea de prospectos
      const collectionPathTareaProspecto = `cf-ventas/etapas/${datos.etapa}`;

      // Guardar el JSON en la ruta especificada y obtener la referencia al documento agregado
      const docTareaRef = await db.collection(collectionPathTareaProspecto).add(jsonProspeccion);

      if (docTareaRef && docTareaRef.id) {
        const seguimientoId = docTareaRef.id + "-" + datos.nit; // Se guarda para próximos registros
        jsonProspeccion.seguimientoId = seguimientoId; // Agregar la propiedad seguimientoId con el valor docTareaRef.id

        // Actualizar el documento en la base de datos con la propiedad seguimientoId
        await docTareaRef.update({ seguimientoId });

        //console.log('JSON guardado exitosamente en cf-ventas', datos.etapa);
        //return true;
      } else {
        console.error('Error al guardar el JSON cf-ventas');
        //return false;
      }

      //Si apoyo es mayor a 0 entonces se debe disparar a cada departamento.  ########### ----------  PASO # 3
      if (Array.isArray(datos.apoyo) && datos.apoyo.length > 0) {
        const idSeguimiento = jsonProspeccion.seguimientoId;
        const proxContacto = jsonProspeccion.proxContacto;
        const etapa = jsonProspeccion.etapa;
        const prioridad = jsonProspeccion.prioridad;
        const estado = "pendiente"; //false; Hay t4 estados pendiente, terminado,trabajando, cancelado

        for (const posicion in datos.apoyo) {
          const apoyo = datos.apoyo[posicion];
          const departamento = apoyo.departamento;
          Object.assign(apoyo, {
            cliente: datos.cliente,
            colaborador: "N/A",
            seguimientoId: idSeguimiento,
            proxContacto,
            etapa,
            prioridad,
            estado,
            timeR: firebase.firestore.FieldValue.serverTimestamp(),
            posicion: posicion
          });

          // Verificar si existe la ruta collectionPathApoyo
          const collectionPathApoyo = `${departamento}/tareasExternas/listado`;

          // Resto del código para el caso en que el array existe y tiene elementos
          // Utiliza la variable collectionPathApoyo para guardar en la ubicación correspondiente

          // Guardar el JSON en la ruta especificada y obtener la referencia al documento agregado
          const docApoyoRef = await db.collection(collectionPathApoyo).add(apoyo);

          if (docApoyoRef && docApoyoRef.id) {
            apoyo.seguimientoId = idSeguimiento; // Se agrega código de seguimiento
            //console.log('JSON guardado exitosamente en ' + departamento, apoyo);
            //return true;
          } else {
            console.error('Error al guardar el JSON cf-ventas');
            //return false;
          }
        }
      } else {
        // El array no existe o no tiene elementos
        // Realiza alguna acción alternativa o muestra un mensaje de error si es necesario
        console.log("No requiere apoyo");
      }

      //Si se desea enviar un eMail de Bienvenida al usuario  ########### ----------  PASO # 4
      //Enviar un correo al usuario con la contraseña 
      const bienvenidaProspecto = functions.httpsCallable("bienvenidaProspecto");

      const datosFormularioEmail = {
        correoContacto: datos.correoContacto[0],
        contacto: datos.contacto,
        doliente: datos.doliente,
        llamar: datos.llamar,
        dateProximoContacto: datos.date,
        etapa: datos.etapa,
      };
      if (datos.switchEnviarBienvenida) {
        // Llama a la función de Firebase Cloud Functions para enviar el correo electrónico
        await bienvenidaProspecto(datosFormularioEmail);
      }

      //Si se desea enviar un SMS de Bienvenida al usuario  ########### ----------  PASO # 5
      const indicativo = "+57" // Se debe traer de manera dinámica segun el pais que se tomó
      const mensaje = "Bienvenido Sr(a)." + datos.contacto + ", Su proceso ha sido registrado en BeracaBG.com. Ejecutiv@: " + datos.doliente + " Saludos!"
      const telefono = datos.whatsappContacto[0];//Toma el primer número

      if (datos.switchEnviarBienvenidaSMS) {
        // Llama a la función de Firebase Cloud Functions para enviar el correo electrónico
        await sendMensajeGeneral(telefono, mensaje, indicativo)
      }
    }



    return true

  } catch (error) {
    console.error('Error al guardar el JSON:', error);
  }
}

//etapa cierre - contactoI - investigacion - negociacion - propuestaypresentacion -  prospeccion -  seguimiento


function convertirFechaATimestamp(fecha) {
  const fechaObjetivo = moment(fecha, 'YYYY-MM-DD').toDate();
  const timestamp = firebase.firestore.Timestamp.fromDate(fechaObjetivo);
  return timestamp;
}
