import { db } from "@/firebase/config.js";

//Actualiza el cliente pero en Listdo no en procesos
export async function consultaCatalogo() {
    const collectionPath = 'cf-catalogo';
  
    try {
      const querySnapshot = await db.collection(collectionPath).get();
  
      if (!querySnapshot.empty) {
        const resultados = [];
  
        querySnapshot.forEach((doc) => {
          resultados.push({
            id: doc.id,
            data: doc.data(),
          });
        });
  
        //console.log('Resultados de la consulta:', resultados);
        return resultados;
      } else {
        //console.log('La colección está vacía.');
        return false;
      }
    } catch (error) {
      console.error('Error al realizar la consulta:', error);
    }
  }
