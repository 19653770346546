<template>
  <div class="text-center">
    <v-progress-circular
      v-if="this.titularActual.data == undefined"
      color="blue-grey darken-4"
      indeterminate
      >🏢</v-progress-circular
    >
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="font-weight-bold"
          color="blue-grey darken-4"
        >
          ADRES
          <v-divider> </v-divider>
          {{ selectTipoAfiliado }}
          <template v-slot:actions>
            <v-icon color="blue"> 🏢 </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey darken-4">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="selectEstado"
                :items="estado"
                label="Seleccione"
                data-vv-name="select"
                required
                v-show="!loadData"
                @change="guardaDatos('selectEstado', selectEstado)"
              ></v-select>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >😛
              </v-progress-circular>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="selectRegimen"
                :items="regimen"
                label="Seleccione"
                data-vv-name="select"
                required
                v-show="!loadData"
                @change="guardaDatos('selectRegimen', selectRegimen)"
                disabled
              ></v-select>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >😛
              </v-progress-circular>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="selectTipoAfiliado"
                :items="tipoAfiliado"
                label="Seleccione"
                data-vv-name="select"
                required
                v-show="!loadData"
                @change="guardaDatos('selectTipoAfiliado', selectTipoAfiliado)"
              ></v-select>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >😛
              </v-progress-circular>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Dato 1 - EPS"
                placeholder="..."
                filled
                rounded
                dense
                v-show="!loadData"
                v-model="dato1"
                @change="guardaDatos('dato1', dato1)"
              ></v-text-field>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >😛
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { FBagregaDatosDA } from "../../../../components/Cartera/investigacion/gestionCarteraInvestigacion";
import { FBHuella } from "../../../../components/Cartera/huella/huella";

export default {
  name: "adres",
  props: ["titularActual"],
  data: () => ({
    selectEstado: "",
    selectRegimen: "",
    selectTipoAfiliado: "",
    estado: [
      "Activo",
      "Inactivo",
      "Retirado",
      "Activo por Emergencia",
      "Protección Laboral",
    ],
    regimen: ["Contributivo", "Subsidiado", "Reg. Especial"],
    tipoAfiliado: [
      "Cotizante",
      "Beneficiario",
      "Cabeza de Familia",
      "Pensionado",
    ],
    loadData: false,
    dato1: "",
    cargaDatos: null,
  }),
  mounted() {
    //console.log("Monta Datos Adicionales...");
    //console.log(this.titularActual);

    if (this.titularActual.ivADRES != undefined) {
      this.cargaDatos = this.titularActual.ivADRES;
      this.updateDatos();
    }
  },
  methods: {
    async guardaDatos(key, value) {
      this.loadData = true;
      //console.log("Guarda datos...");
      //console.log(key, value);

      var nit = this.titularActual.nitCliente;
      var idC = this.titularActual.idCartera;
      var idR = this.titularActual.idRegistro;

      const result = await FBagregaDatosDA(
        key,
        value,
        nit,
        idC,
        idR,
        "ivADRES"
      );

      //Huella
      //console.log("Se va a huella");
      const resultHuella = await FBHuella(this.titularActual, "ADRES");

      //console.log(result);
      this.loadData = false;
    },
    updateDatos() {
      if (this.cargaDatos != undefined || this.cargaDatos != null) {
        //console.log(this.cargaDatos);
        //Recorrer JSON
        for (var x in this.cargaDatos) {
          //console.log(x);
          switch (x) {
            case "dato1":
              this.dato1 = this.cargaDatos[x];
              break;
            case "selectEstado":
              this.selectEstado = this.cargaDatos[x];
              break;
            case "selectRegimen":
              this.selectRegimen = this.cargaDatos[x];
              break;
            case "selectTipoAfiliado":
              this.selectTipoAfiliado = this.cargaDatos[x];
              break;
            default:
              break;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
