import { firebase, db, storage } from "../../firebase/config"

const ref = storage.ref();

//Carga registros a la cartera seleccionada
export async function FBcargaTitulares(selectCliente, selectCarteras, carteraLoad) {

    //console.log(selectCliente, selectCarteras, carteraLoad)
    try {
        ///cf-cartera/Carteras/902/7LQUTBPqVjzdRzEG3LsT/registros/qpXBSbzEaXlM8ATn2ghW
        const rutaRegistrosCartera = db.collection('cf-cartera').doc('Carteras').collection(selectCliente.nit).doc(selectCarteras.id).collection('registros');

        
        //Sube cada registro
        carteraLoad.forEach(async (element) => {

            //let modificionData = element.cedula
            ///!!!!! FALTA URGENTE 
            //Cargar los datos adicionales del titular en la variable carteraLoad
            let arrayOfTelefonos = [];
            let arrayOfEmail = [];

            if (element.whatsapp && element.whatsapp !== '0' && element.whatsapp !== 'o') {
                arrayOfTelefonos = element.whatsapp.split(',');
            }

            if (element.email && element.email !== '0' && element.email !== 'o') {
                arrayOfEmail = element.email.split(',');
            }

            //Eliminamos las propiedades para que no se guarden dentro del grupo, sino
            //Que la única información sea la externa
            delete element.whatsapp;
            delete element.email;

            let dataFinal = {
                data: element,//datos iniciales suministrados por el cliente
                whatsappContacto: arrayOfTelefonos,
                correoContacto: arrayOfEmail,
                estado: false,//estado general del titular
                asignado: [{ usuario: element.Asignado, estado: false, fecha: firebase.firestore.Timestamp.now(), }],//se encuentran todos los registros de asignados
                timeRegistroModificado: [{
                    fecha: firebase.firestore.Timestamp.now(),
                    usuario: 'Sistema BBG',
                    tipoCambio: 'creacion'
                }],//desde creación hasta cualquier update o modificación del titular será grabado
                respetable: false,//historial de respetable
                asignadoActual: [element.Asignado],
                observaciones: [{ observacion: element.Observaciones, contacto: '', efecto: '', fecha: firebase.firestore.Timestamp.now(), }],//observaciones que ha tenido el titular
            }
            
            await rutaRegistrosCartera.add(dataFinal);
            //console.log(result);
        });

        return true;
    } catch (error) {
        //console.log(error);
        return false;
    }
}