<template>
  <div class="text-center">
    <v-dialog v-model="dialog" scrollable max-width="700px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" fab x-small dark v-bind="attrs" v-on="on">
          <v-chip dark>
            <v-icon dark>mdi-account-switch</v-icon>
            {{ datosApoyo.length }}
          </v-chip>
        </v-btn>
      </template>

      <!-- SOLICITUDES -->
      <v-card v-if="datosApoyo.length > 0" dark>
        <v-card-title class="text-h5 amber">
          Solicitudes de Apoyo |
          <span class="caption"
            >Total solicitudes: {{ datosApoyo.length }}
          </span>

          <v-spacer></v-spacer>
          <AgregarApoyo
            v-if="resultDepart != null"
            :modulos="resultDepart"
            @agregarApoyo="agregaApoyo"
            :idRegistroS="idRegistro"
            :etapaS="etapa"
            :todosLosDatos="todosLosDatos"
            :totalApoyos="datosApoyo.length"
          />
        </v-card-title>

        <v-card-text v-if="!procesando">
          <v-divider></v-divider>

          <v-expansion-panels class="mt-5">
            <v-expansion-panel v-for="(item, i) in datosApoyo" :key="i">
              <v-badge
                class="ml-n1 mt-n5"
                :color="getProcesoColor(item.estado)"
                :icon="getProcesoIcon(item.estado)"
              ></v-badge>

              <v-expansion-panel-header class="pa-2">
                <v-row align="center" justify="end">
                  <v-col cols="6">
                    {{ item.title }}
                  </v-col>
                  <v-col cols="1">
                    <v-divider color="orange" vertical></v-divider>
                  </v-col>
                  <v-col cols="5">
                    <span>{{ getProcesoTexto(item.estado) }}</span>
                    <v-divider color="orange"></v-divider>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-divider color="grey darken-1"></v-divider>

              <v-expansion-panel-content class="pa-2">
                <div
                  class="custom-div pa-3"
                  v-html="formatText(item.observacion)"
                ></div>

                <v-row class="mt-5 justify-center">
                  <v-col cols="4" class="justify-center text-center">
                    <v-tooltip color="red" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          outlined
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="item.estado === 'cancelado'"
                        >
                          <v-icon>mdi-account-alert</v-icon>
                        </v-btn>
                      </template>
                      <span>Enviar Alerta Recordatorio</span>
                    </v-tooltip>
                  </v-col>
                  <!--- EDITA REQUERIMIENTO O APOYO -->
                  <v-col cols="4" class="justify-center text-center">
                    <EditarApoyoContenido
                      v-if="item.estado !== 'cancelado'"
                      :todosLosDatos="todosLosDatos"
                      :totalApoyos="i"
                      :observacion="item.observacion"
                      :estado="item.estado"
                      @DatoModificado="modificaTextoSolicitud"
                    />
                  </v-col>

                  <!--- CANCELA O PUEDE VER EL REQUERIMIENTO -->

                  <!-- || item.estado === 'pendiente' -->
                  <v-col cols="4" class="justify-center text-center">
                    <div
                      v-if="
                        item.estado === undefined || item.estado === 'cancelado'
                      "
                    >
                      <v-tooltip color="indigo" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="cancelar(i)"
                            color="indigo"
                            v-bind="attrs"
                            v-on="on"
                            icon
                            outlined
                            :disabled="item.estado === 'cancelado'"
                          >
                            <v-icon>mdi-cancel</v-icon>
                          </v-btn>
                        </template>
                        <span>Cancelar Requerimiento</span>
                      </v-tooltip>
                    </div>

                    <div v-else>
                      <FichaApoyo
                        :todosLosDatosReales="todosLosDatos"
                        :todosLosDatos="item"
                        :seguimientoId="todosLosDatos.seguimientoId"
                        :posicion="i"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-divider color="grey" class="mt-2"></v-divider>

                <v-row class="mt-2">
                  <v-col cols="12">
                    Última actualización :
                    {{
                      item.fechaActualizacion
                        ? fechaConvertirFirebase(item.fechaActualizacion)
                        : "No ha tenido su primer cambio o actualización"
                    }}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" fab x-small>
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Puede variar sino ha actualizado el listado</span>
                    </v-tooltip>
                  </v-col>
                  <v-col> </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <!-- CARGADOR DE PROCESO -->
        <v-card v-else class="text-center mt-5" dark>
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
          <br />
          <span>{{ proceso }}</span>
        </v-card>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>

      <!-- NO HAY SOLICITUDES -->
      <v-card v-else dark>
        <v-card-title class="text-h5 amber">
          Solicitudes de Apoyo |
          <span class="caption"
            >Total solicitudes: {{ datosApoyo.length }}
          </span>

          <v-spacer></v-spacer>
          <AgregarApoyo
            v-if="resultDepart != null"
            :modulos="resultDepart"
            @agregarApoyo="agregaApoyo"
            :idRegistroS="idRegistro"
            :etapaS="etapa"
            :todosLosDatos="todosLosDatos"
            :totalApoyos="datosApoyo.length"
          />
        </v-card-title>
        <v-card-text> No hay solicitudes.. </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="orange" outlined @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AgregarApoyo from "./agregaApoyo.vue";
import FichaApoyo from "../Clientes/fichaApoyoCliente.vue";
import { FBconsultarModulos } from "@/components/Ventas/consultas.js";
import {
  FBactualizaApoyo,
  FBactualizaApoyoObservacion,
} from "@/components/Ventas/Tableros/editaApoyo.js";
import EditarApoyoContenido from "./editarApoyoSolicitante.vue";

export default {
  props: ["datosApoyo", "idRegistro", "etapa", "todosLosDatos"],

  data() {
    return {
      dialog: false,
      resultDepart: null,
      proceso: "Procesando...",
      procesando: false,
    };
  },

  components: {
    AgregarApoyo,
    EditarApoyoContenido,
    FichaApoyo,
  },

  created() {
    this.consultaDepartamentos();
  },
  
  methods: {
    async consultaDepartamentos() {
      const resultDepartment = await FBconsultarModulos();
      this.resultDepart = resultDepartment;
    },

    agregaApoyo(data) {
      // Aquí puedes realizar las acciones necesarias con los datos guardados
      //console.log(data.departamento);
      //console.log(data.colaborador);
      //console.log(data);

      this.datosApoyo.push(data);
      //No se actualiza el número de padre porque ya viene con el total de array.
    },

    async cancelar(posicion) {
      this.proceso = "Procesando...";
      this.procesando = true;

      //console.log(posicion);
      const estado = "cancelado";
      const departamento = this.todosLosDatos.apoyo[posicion].departamento;
      const idSeguimiento = this.todosLosDatos.seguimientoId;

      const json = {
        etapa: this.etapa,
        idRegistro: this.idRegistro,
        posicion: posicion,
        estado: estado,
        departamento: departamento,
        seguimientoId: idSeguimiento,
      };

      const respuesta = await FBactualizaApoyo(json);

      //console.log(respuesta);

      if (respuesta[0]) {
        if (this.datosApoyo[posicion].hasOwnProperty("estado")) {
          // La clave existe en la posición x del array
          this.datosApoyo[posicion].estado = estado;

          this.proceso = "Se actualizó correctamente";

          setTimeout(() => {
            this.procesando = false;
          }, 2000);
        } else {
          // La clave no existe en la posición x del array, se agrega
          this.datosApoyo[posicion].estado = estado;

          this.proceso = "Se actualizó apoyo";
          setTimeout(() => {
            this.procesando = false;
          }, 2000);
        }
      } else {
        this.proceso = "Error al actualizar el Apoyo";
        setTimeout(() => {
          this.procesando = false;
        }, 2000);
        //console.log("No se actualizó.");
      }

      //console.log(this.datosApoyo);
    },

    formatText(text) {
      // Buscar el patrón "número." y aplicar estilos
      const formattedText = text.replace(
        /(\d+)\./g,
        "<br><strong>$1.</strong>"
      );
      return formattedText;
    },

    getProcesoColor(proceso) {
      if (proceso === "pendiente") {
        return "primary";
      } else if (proceso === "terminado") {
        return "green";
      } else if (proceso === "trabajando") {
        return "orange";
      } else if (proceso === "cancelado") {
        return "red";
      } else {
        return "purple"; // Si el estado no está definido, mostrará el color "error"
      }
    },

    getProcesoIcon(proceso) {
      if (proceso === "pendiente") {
        return "mdi-account-clock";
      } else if (proceso === "terminado") {
        return "mdi-check";
      } else if (proceso === "trabajando") {
        return "mdi-account-hard-hat";
      } else if (proceso === "cancelado") {
        return "mdi-cancel";
      } else {
        return "mdi-timer-outline"; // Si el estado no está definido, mostrará el icono "mdi-alpha-x"
      }
    },

    getProcesoTexto(proceso) {
      if (proceso === "pendiente") {
        return "Pendiente...";
      } else if (proceso === "terminado") {
        return "Terminado...";
      } else if (proceso === "trabajando") {
        return "Trabajando...";
      } else if (proceso === "cancelado") {
        return "Cancelado...";
      } else {
        return "Por asignar..."; // Si el estado no está definido, mostrará el icono "mdi-alpha-x"
      }
    },

    fechaConvertirFirebase(timestamp) {
      // Convierte el objeto Timestamp a una fecha JavaScript
      const fecha = new Date(timestamp.seconds * 1000); // Multiplica por 1000 para convertir segundos a milisegundos

      // Obtén la fecha y hora en un formato legible
      const fechaHoraLegible = fecha.toLocaleString(); // Utiliza el método toLocaleString() para obtener la fecha y hora en el formato local del usuario

      //console.log(fechaHoraLegible);
      return fechaHoraLegible;
    },

    async modificaTextoSolicitud(contenido, posicion) {
      const departamento = this.todosLosDatos.apoyo[posicion].departamento;
      const idSeguimiento = this.todosLosDatos.seguimientoId;

      const json = {
        etapa: this.etapa,
        idRegistro: this.idRegistro,
        posicion: posicion,
        observacion: contenido,
        departamento: departamento,
        seguimientoId: idSeguimiento,
      };

      const respuesta = await FBactualizaApoyoObservacion(json);

      if (respuesta[0]) {
        //console.log(this.todosLosDatos.apoyo[posicion].observacion = contenido);
        if (
          Array.isArray(this.todosLosDatos.apoyo) &&
          posicion >= 0 &&
          posicion < this.todosLosDatos.apoyo.length
        ) {
          // Actualiza la clave 'observacion' en la posición específica
          this.todosLosDatos.apoyo[posicion].observacion = contenido;
        }
      } else {
        console.log("No se actualizó.");
      }
    },
  },
};
</script>

<style scoped>
.custom-div {
  background-color: #2e2e2e; /* Cambia el color de fondo según tus preferencias */
  border-radius: 10px; /* Ajusta el valor según el redondeo deseado */
}
</style>





