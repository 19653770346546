import { db } from "@/firebase/config.js";

//Actualiza el cliente pero en Listdo no en procesos
export async function FBeditarCampoCliente(jsonLlega) {
    try {
        console.log(jsonLlega);
        const json = {
            clave: jsonLlega.clave,
            idDoc: jsonLlega.idDoc,
            valor: jsonLlega.valor,
        }

        const collectionPathCreaProspecto = `cf-pedidos/${jsonLlega.idDoc}`;
        const docRef = db.doc(collectionPathCreaProspecto);

        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
            await docRef.update({
                [json.clave]: json.valor,
            });
        } else {
            await docRef.set({
                [json.clave]: json.valor,
            });
        }

        return [true, "Se ha actualizado con éxito"];
    } catch (error) {
        console.error("Error al actualizar el documento:", error);
        return [false, "Error..."];
    }
}



//Actualiza ficha de cliente en procesos
export async function FBeditarFichaClienteCampo(jsonLlega) {
    try {
        console.log(jsonLlega);
    
        const idSeguimiento = jsonLlega.codigoSeguimiento;

        const json = {
            clave: jsonLlega.clave,
            valor: jsonLlega.nuevoDato,
        };

        const collectionPathCreaProspecto = `cf-pedidos`;
        const collectionRef = db.collection(collectionPathCreaProspecto);
        const queryRef = collectionRef.where("codigoSeguimiento", "==", idSeguimiento);
        
        const querySnapshot = await queryRef.get();
        
        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref;
        
          await docRef.update({
            [json.clave]: json.valor,
          });
        
          return [true, "Se ha actualizado con éxito"];
        } else {
          const newDocRef = collectionRef.doc();
        
          await newDocRef.set({
            seguimientoId: idSeguimiento,
            [json.clave]: json.valor,
          });
        
          return [true, "Se ha creado con éxito"];
        }

    } catch (error) {
        console.error("Error al actualizar el documento:", error);
        return [false, "Error..."];
    }
}
