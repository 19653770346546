<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500px" persistent dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mt-3"
          color="orange"
          fab
          x-small
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-tooltip color="orange" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-book-account</v-icon>
            </template>
            <span>Administrar Datos</span>
          </v-tooltip>
        </v-btn>
      </template>

      <v-card v-if="!isUpdating">
        <v-card-title class="text-h5 amber">
          {{ title }}
        </v-card-title>

        <v-card-text class="mt-5">
          Se agrega un dato al Contacto. Se almacenará al final de listado.
          <v-row>
            <v-col cols="10" class="mt-5">
              <v-text-field
                v-model="selectedItem"
                label="Nuevo dato"
                placeholder="Escribe aquí..."
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="mt-8">
              <v-tooltip color="amber" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="amber"
                    @click="agregar"
                    fab
                    x-small
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
                <span>Enviar</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="mt-n15">
            <v-col>
              <v-list dense>
                <v-subheader>Listado </v-subheader>
                <v-list-item-group color="orange">
                  <v-list-item v-for="(item, i) in listadoDatos" :key="i">
                    <v-tooltip color="orange" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content
                          @click="copiarTexto(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-title>{{ item }}</v-list-item-title>

                          <v-divider></v-divider>
                        </v-list-item-content>
                      </template>
                      <span>Da clic para copiar</span>
                    </v-tooltip>

                    <v-list-item-icon>
                      <div class="mt-n1">
                        <v-tooltip color="error" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="error"
                              @click="eliminar(i)"
                              fab
                              x-small
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-delete-empty</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>
                      </div>
                      <div class="mt-n1">
                        <v-btn
                          color="grey darken-3"
                          @click="mover(i)"
                          fab
                          x-small
                          dark
                          disabled
                        >
                          <v-icon>mdi-cursor-move</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="orange" @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-progress-linear v-else indeterminate color="amber"></v-progress-linear>
    </v-dialog>
  </div>
</template>

<script>
import {
  FBagregaDatoContacto,
  FBeliminaDatoContacto,
} from "@/components/Ventas/Tableros/agregaEmailyTelefonoB2C.js";

export default {
  props: ["title", "identificador"],
  data() {
    return {
      dialog: false,
      selectedItem: "",
      listadoDatos: [],
      isUpdating: false,
    };
  },
  created() {
    this.inicializar();
  },

  methods: {
    inicializar() {
      this.listadoDatos = this.identificador[0].datos;
    },

    async eliminar(posicion) {
      //console.log("Eliminar" + posicion);

      this.isUpdating = true;

      const json = {
        //etapa: this.identificador[0].etapa,
        seguimientoId: this.identificador[0].seguimientoId,
        codigoSeguimiento: this.identificador[0].codigoSeguimiento,
        datoAEliminar: posicion,
        calve: this.identificador[0].claveCambio,
      };
      //console.log(json);

      const respuesta = await FBeliminaDatoContacto(json);
      if (respuesta[0]) {
        this.selectedItem = "";
        this.isUpdating = false;
        // Eliminar el elemento del array en la posición indicada
        if (posicion >= 0 && posicion < this.listadoDatos.length) {
          this.listadoDatos.splice(posicion, 1);
        }
      } else {
        console.log("Erro: " + respuesta[1]);
        this.isUpdating = false;
      }
    },

    async agregar() {
      this.isUpdating = true;
      //console.log("agregar:" + this.selectedItem);

      this.listadoDatos.push(this.selectedItem);
      const json = {
        etapa: this.identificador[0].etapa,
        seguimientoId: this.identificador[0].seguimientoId,
        nuevoDato: this.selectedItem,
        calve: this.identificador[0].claveCambio,
        codigoSeguimiento: this.identificador[0].codigoSeguimiento,
      };

      const respuesta = await FBagregaDatoContacto(json);
      if (respuesta[0]) {
        this.selectedItem = "";
        this.isUpdating = false;
      } else {
        console.log("Erro" + respuesta[1]);
        this.isUpdating = false;
      }
    },

    mover(posicion) {
      console.log("mover" + posicion);
    },

    async copiarTexto(textoLlega) {
      if (!navigator.clipboard) {
        console.error(
          "La API navigator.clipboard no es compatible en este navegador."
        );
        return;
      }

      let textoCopiado = textoLlega;

      try {
        await navigator.clipboard.writeText(textoCopiado);
        //console.log("Texto copiado al portapapeles: ", textoCopiado);
      } catch (error) {
        //console.error("Error al copiar el texto al portapapeles:", error);
      }
    },
  },
};
</script>