<template>
  <div>
    <!-- Footer -->
    <v-footer class="mt-10" color="black" fixed dark padless>
      <v-card class="flex" flat tile>
        <v-card-title class="grey darken-3">
          <div class="d-flex align-center justify-space-between">
            <strong class="subheading" v-if="$vuetify.breakpoint.smAndDown">
              {{ truncateText("Bienvenido", 5) }}

              <strong v-if="usuario != null">
                
                | {{ truncateText(usuario[2][0].data.empleado, 5) }}
              </strong>
            </strong>

            <strong class="subheading" v-else>
              Bienvenido

              <strong v-if="usuario != null">
                | {{ usuario[2][0].data.empleado }}
              </strong>
            </strong>

            <!-- OTROS BOTONES DE NAVEGACIÓN LOGIN -->
            <div v-if="usuario != null" class="d-flex text-right ml-4">
              <v-btn
                class="mx-2"
                color="secondary"
                v-for="link in links"
                :key="link.icon"
                @click="linkFooter(link.page)"
                fab
                x-small
                dark
              >
                <v-icon>{{ link.icon }}</v-icon>
              </v-btn>

              <modalDirectorio class="mx-2" />
            </div>
            <!-- END OTROS BOTONES DE NAVEGACIÓN LOGIN -->
          </div>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} —
          <strong>Sistema de Gestión Beraca BG </strong> |

          <!-- Enlace para abrir el panel deslizante -->
          <span v-if="usuario == null" @click="dialog = true"
            >Políticas de Privacidad</span
          >
        </v-card-text>
      </v-card>
    </v-footer>

    <!-- Dialogo con la política de privacidad -->
    <v-dialog v-model="dialog" max-width="600px" dark>
      <v-card>
        <v-card-title class="headline">Política de Privacidad</v-card-title>
        <v-card-text>
          <div>
            <h2>Política de Privacidad</h2>
            <p>Última actualización: {{ new Date().getFullYear() }}</p>

            <p>
              Bienvenido a Beraca Grupo Empresarial S.A.S . Apreciamos tu
              confianza en nuestros servicios. Esta Política de Privacidad
              explica cómo recopilamos, utilizamos, protegemos y compartimos tu
              información personal.

              <br />
              <br />

              1. **Información Recopilada**: Recopilamos datos que nos
              proporcionas directamente, como tu nombre, dirección de correo
              electrónico, número de teléfono y otros datos relevantes para
              nuestros servicios. También podemos recopilar información
              automáticamente cuando visitas nuestra tienda virtual o catálogo
              Beraca https://tiendaberaca.com o interactúas con nuestras
              comunicaciones de marketing.

              <br /><br />

              2. **Uso de la Información**: Utilizamos la información que
              recopilamos para: a. Personalizar y mejorar tu experiencia en
              nuestros servicios. b. Enviar publicidad y promociones
              relacionadas con nuestros productos y servicios. c. Procesar
              transacciones y consultas en nuestra tienda virtual o catálogo
              Beraca https://tiendaberaca.com/. d. Responder a tus solicitudes,
              preguntas o comentarios.

              <br /><br />

              3. **Publicidad por WhatsApp**: Utilizamos WhatsApp como una de
              nuestras plataformas para enviar publicidad y promociones. Al
              proporcionarnos tu número de teléfono y consentir recibir
              comunicaciones por este medio, aceptas que te contactemos a través
              de WhatsApp.

              <br /><br />

              4. **Cookies y Otros Rastreadores**: Utilizamos cookies y otros
              rastreadores en nuestra tienda virtual o catálogo Beraca
              https://tiendaberaca.com/ para mejorar tu experiencia de usuario y
              para comprender mejor cómo interactúan nuestros clientes con
              nuestro sitio.

              <br /><br />

              5.**Protección de Datos**: No vendemos tus datos a terceros.
              Tomamos medidas de seguridad adecuadas para proteger la
              información que recopilamos y almacenamos.

              <br /><br />

              6. **Retirar el Consentimiento**: Puedes optar por no recibir
              comunicaciones publicitarias en cualquier momento siguiendo las
              instrucciones de "cancelar suscripción" en nuestras comunicaciones
              o contactándonos directamente al correo
              notificaciones@beracabg.com

              <br /><br />

              7. **Cambios a esta Política**: Podemos actualizar esta Política
              de Privacidad ocasionalmente. Te notificaremos cualquier cambio
              importante. Gracias por confiar en Beraca Grupo Empresarial S.A.S.
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="amber" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import modalDirectorio from "../Directorio/modalDirectorio";

export default {
  name: "Footer",
  components: { modalDirectorio },
  data: () => ({
    links: [
      { page: "Inicio", icon: "mdi-home" },
      { page: "Ventas", icon: "mdi-briefcase-account" },
    ],
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    dialog: false, // Controla la visibilidad del diálogo
  }),
  props: ["usuario"],
  methods: {
    linkFooter(llega) {
      // Tu lógica para manejar los links aquí, por ejemplo:
      console.log(llega);
      this.$router.push({ name: llega });
    },

    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.slice(0, maxLength) + "…";
      }
    },
  },
};
</script>

<style scoped>
/* Tu CSS aquí, si es necesario */
</style>




