<template>
  <div class="pa-3 text-center">
    <v-dialog v-model="dialog" width="500" persistent dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-2"
          dark
          small
          color="red"
          v-bind="attrs"
          v-on="on"
          rounded
        >
          <v-icon dark> mdi-plus </v-icon>
          Agregar Abono
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 teal darken-4">
          Agregar Abono
        </v-card-title>

        <v-card-text class="teal lighten-1">
          <v-row class="pa-3">
            <v-col cols="12" sm="12" md="12">
              Atener en cuenta:
              <br />
              No olvide luego de agregar el abono, subir el soporte PDF e ir a
              observación y confirmar pago, Gracias.
            </v-col>
          </v-row>

          <v-row class="pa-1 mt-n5">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                single-line
                type="number"
                :disabled="enviando"
                v-model="valorAbono"
                label="Digite el valor sin decimales..."
                placeholder="$..."
                filled
                rounded
                dense
              ></v-text-field>
              {{ respuesta }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="teal darken-4">
          <v-btn :disabled="enviando" plain @click="enviarAbono" color="white">
            💵 - Agregar Abono
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="enviando"
            class="ma-1"
            color="teal lighten-3"
            text
            @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "abono",
  props: ["respuestaServerAbono"],
  data: () => ({
    dialog: false,
    valorAbono: null,
    respuesta: "",
    enviando: false,
  }),
  methods: {
    enviarAbono() {
      this.enviando = true;
      this.respuesta = "Enviando Abono...";

      if (this.valorAbono != null || this.valorAbono != undefined) {
        this.$emit("callAbono", this.valorAbono);

        //this.enviando = false;
        this.valorAbono = null;
        setTimeout(() => {
          this.enviando = false;
          this.respuesta = this.respuestaServerAbono;
          //this.dialog = false;
        }, 3000);
      } else {
        this.respuesta = "Ingrese un valor";
        setTimeout(() => (this.enviando = false), 3000);
      }
    },
  },
  watch: {
    respuesta(val) {
      if (!val) return;
      setTimeout(() => (this.respuesta = "..."), 6000);
    },
  },
};
</script>