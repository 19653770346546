import { storage, db, firebase } from "@/firebase/config.js";
import { toSlug } from  "@/Util/slug.js";

export async function subirImageFB(json) {
    return new Promise(async (resolve, reject) => {
        try {
            const slug = toSlug(json.nombre);
            const sku = json.sku;
            const nombreProducto = slug;
            let rutas = [];

            for (let index = 0; index < json.imageFile.length; index++) {
                const file = json.imageFile[index];
                const fileRef = storage.ref(`st-catalogo/productos/${sku}/imagen${index}.jpg`);
                await fileRef.put(file);
                const url = await fileRef.getDownloadURL();
                rutas.push({ imageAlt: nombreProducto + '-' + index, url: url, size: "500x500" });
            }

            resolve([true, rutas]);  // Si todo va bien, resuelve la promesa con el array [true, rutas].

        } catch (error) {
            reject([false, error]);  // En caso de un error, rechaza la promesa con el array [false, error].
        }
    });
}


export async function subirRegistroFB(json) {
    try {
        //console.log(json);

        const slug = toSlug(json.nombre);

        //Eliminamos el archivo
        delete json.imageFile;
        json.toSlug = slug; // para el sistema de rutas
        json.dateR = firebase.firestore.FieldValue.serverTimestamp();

        const docRef = await db.collection("cf-catalogo").add(json);;
        //console.log("Document written with ID: ", docRef.id);


        return [true, docRef.id];
    } catch (error) {
        //console.log(error);
        return [false, error];
    }
}

export async function consultaProductoFB(producto){
    try {

        console.log(producto);

        let items = null;
        
        // Referencia a tu colección en Firestore
        const collectionRef = db.collection("cf-catalogo").where("sku", "==", producto);

        // Hacer la consulta
        const snapshot = await collectionRef.get();

        // Convertir los documentos de Firestore a un array de objetos
        items = snapshot.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data()
          };
        });

        console.log(items);


        return [true, items];
    } catch (error) {
        console.log(error);
        return [false, error];
    }
}



/* GUARDAR EN WEBP PARA MAYOR RENDIEMIENTO

npm install image-conversion


import imageConversion from 'image-conversion';

export async function subirImageFB(json) {
    try {
        const sku = json.sku;
        const nombreProducto = json.nombre;
        let rutas = [];

        const uploadPromises = json.imageFile.map(async (file, index) => {
            // Convierte la imagen a webp
            const webpFile = await imageConversion.convertFileToWebp(file);

            const fileRef = storage.ref(`st-catalogo/productos/${sku}/imagen${index}.webp`); // Nota que ahora la extensión es .webp
            await fileRef.put(webpFile);
            const url = await fileRef.getDownloadURL();
            rutas.push({ imageAlt: nombreProducto+'-'+ index, url: url, size: "1200x1200" });
        });

        await Promise.all(uploadPromises);

        return [true, rutas];
    } catch (error) {
        return [false, error];
    }
}

*/