<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600px" persistent scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="pa-2"
          outlined
          small
          icon
          color="green darken-4"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
      </template>

      <v-card scrollable max-width="600px" dark>
        <v-card-title class="green">
          WhatsApp

          <v-divider class="mx-4" inset vertical></v-divider>
          <div class="caption">Nuevo Contacto {{ contacto }}</div>

          <v-spacer></v-spacer>
          <div class="body-2 mr-5">{{ indicativoSelect }}{{ celular }}</div>
          <v-btn x-small icon color="green" disabled>
            <v-icon>mdi-hand-wave-outline</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <!-- v-if="celular !== null && celular !== ''" -->
          <v-card scrollable max-width="500px" elevation="0">
            <div class="message-scroll">
              <v-list v-if="switchTiempoReal">
                <template>
                  <v-list-item v-for="(item, index) in items" :key="index">
                    <v-list-item-avatar>
                      <v-avatar color="orange" size="24">
                        <span class="white--text">{{ index }}</span>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.usuario !== undefined && item.usuario !== null
                          ? item.usuario
                          : usuarioW
                      }}</v-list-item-title>
                      
                      

                      <!-- Para cuando es un BUTTON -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-if="item.body.type === 'button'"
                    >
                      <div
                        class="secondary text-no-wrap rounded-pill d-inline pa-1 deep-purple accent-4 white--text"
                      >
                        {{ item.body.button.text }}
                      </div>
                    </v-list-item-title>

                    <!-- Para cuando es un STICKER -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'sticker'"
                    >
                      <img
                        class="rounded-xxl"
                        :src="item.urlFinal"
                        contain
                        lazy-src="https://picsum.photos/id/11/10/6"
                        :aspect-ratio="16 / 9"
                        height="150"
                      />
                    </v-list-item-title>

                    <!-- Para cuando es un IMAGEN -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'image'"
                    >
                      <button>
                        <img
                          class="rounded-xxl elevation-5 row-pointer"
                          :src="item.urlFinal"
                          contain
                          lazy-src="https://picsum.photos/id/11/10/6"
                          :aspect-ratio="16 / 9"
                          height="150"
                          @click="abrirEnlace(item.urlFinal)"
                        />
                      </button>

                      <div v-if="item.body.image?.caption">
                        <br />
                        <p>{{ item.body.image?.caption }}</p>
                      </div>
                    </v-list-item-title>

                    <!-- Para cuando es un REACTION -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'reaction'"
                    >
                      {{ item.body.reaction.emoji }}
                    </v-list-item-title>

                    <!-- Para cuando es un VIDEO -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'video'"
                    >
                      <button @click="abrirEnlace(item.urlFinal)">
                        <div
                          class="secondary rounded-pill d-inline pa-1 mt-3s deep-purple accent-4 white--text"
                        >
                          <v-icon>mdi-movie</v-icon>
                          Video - Multimedia
                        </div>
                      </button>

                      <div v-if="item.body.video?.caption">
                        <br />
                        <p>{{ item.body.video?.caption }}</p>
                      </div>
                    </v-list-item-title>

                    <!-- Para cuando es un AUDIO -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'audio'"
                    >
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        @click="playSound(item.urlFinal)"
                      >
                        <v-icon dark> mdi-play </v-icon>
                      </v-btn>

                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        @click="stopSound()"
                      >
                        <v-icon dark> mdi-stop </v-icon>
                      </v-btn>
                    </v-list-item-title>

                    <!-- CUando no se identifica el INdefinido o Text -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else
                    >
                      {{ item.body.text?.body || item.body }}
                    </v-list-item-title>
                    
                    <!-- Copia texto de cualquier mensaje que contenga -->
                    <v-list-item-title class="text-right caption mt-2">
                      <v-btn
                        :disabled="
                          item.body.type === 'audio' ||
                          item.body.type === 'sticker' ||
                          item.body.type === 'button'
                        "
                        icon
                        color="amber"
                        @click="
                          copiarTexto(
                            item.body.text?.body ||
                              item.body.image?.caption ||
                              item.body.video?.caption
                          )
                        "
                        x-small
                        class="mr-3"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>

                      <v-chip
                        v-if="item.type !== undefined"
                        x-small
                        color="orange"
                        class="mr-3"
                        outlined
                      >
                        {{ item.type }}
                      </v-chip>

                      <v-chip small color="green" outlined>
                        {{
                          item.usuario !== undefined && item.usuario !== null
                            ? item.usuario
                            : usuarioW
                        }}
                        -
                        {{ fechaConvertirFirebase(item.timestamp) }}
                      </v-chip>
                    </v-list-item-title>

                    <v-divider color="green" class="mt-2"></v-divider>

                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </div>
          </v-card>

          <!--
<v-card elevation="0" class="mt-5" v-else>
            Por favor seleccionar un número del contacto para poder consultar
            los mensajes.
          </v-card>
          -->

          <!-- SECCIÓN DE CHAT-->
          <!-- v-if="celular !== null && celular !== ''" -->
          <p v-if="items.length <= 0 ? true : false">
            En caso que el usuario no tenga mensajes se debe enviar primero la
            plantilla de Autorización y esperar a que responda.
          </p>
          <v-row class="mt-3">
            <v-col cols="10">
              <v-textarea
                v-model="mensaje"
                :label="
                  items.length <= 0
                    ? 'Debe enviarse la plantilla Autorización...'
                    : 'Escribe...'
                "
                auto-grow
                outlined
                rows="2"
                row-height="25"
                shaped
                color="green"
                :disabled="items.length <= 0"
              ></v-textarea>
            </v-col>
            <v-col cols="2">
              <v-btn @click="conversar" icon color="green">
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-n10">
            <v-col>
              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="indicativoSelect"
                    :items="indicativos"
                    label="Indicativo"
                    outlined
                    color="orange"
                  ></v-select>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="celular"
                    color="green"
                    label="Número de contacto"
                    placeholder="Indicativo Número de contacto"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-n10">
            <v-col>
              <v-text-field
                v-model="contacto"
                color="green"
                label="Nombre Contacto"
                placeholder="Nombre Usuario"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="plantilla"
                color="green"
                :items="plantillas"
                label="Plantilla"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mt-n8"></v-divider>

        <v-card-actions class="mt-n3">
          <!--  v-if="celular !== null && celular !== ''" -->
          <v-switch
            v-model="switchTiempoReal"
            :label="`Consultar e Iniciar`"
            color="green"
            @change="listenToMessages"
          ></v-switch>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="reiniciar"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase/config.js";

import { FBtemplateWhatsapp } from "@/components/Ventas/Tableros/whatsapp/configuracion.js";

import {
  FBenviarMensajeWhatsapp,
  FBBienvenidaMensajeWhatsapp,
} from "@/components/Ventas/Tableros/whatsapp/conversar.js";

export default {
  props: ["NombreEmpleado"],
  data() {
    return {
      dialog: false,
      switchTiempoReal: false,
      items: [],
      mensaje: "",
      unsubscribe: null, // Variable reactiva para almacenar la función de desactivación
      usuarioW: null,
      celular: "",
      contacto: "Desconocido",
      plantillas: ["No disponible"],
      plantilla: null,
      //indicativo: "57",
      indicativoSelect: "57",
      indicativos: [
        "1", // Estados Unidos
        "7", // Rusia
        "33", // Francia
        "34", // España
        "39", // Italia
        "44", // Reino Unido
        "49", // Alemania
        "52", // México
        "54", // Argentina
        "55", // Brasil
        "56", // Chile
        "57", // Colombia
        "61", // Australia
        "82", // Corea del Sur
        "86", // China
        "81", // Japón
        "91", // India
      ],
    };
  },

  created() {
    this.inicializar();
  },

  methods: {
    async inicializar() {
      const plantillas = await FBtemplateWhatsapp();
      //console.log(plantillas.plantillas);
      this.plantillas = plantillas.plantillas;
    },

    abrirEnlace(enlace) {
      window.open(enlace, "_blank");
    },

    //Whatsapp
    playSound(sound) {
      if (sound) {
        this.audio = new Audio(sound);

        this.audio.addEventListener("loadedmetadata", () => {
          const duration = this.audio.duration; // Duración en segundos
          //console.log(`Duración del audio: ${duration} segundos`);
          const minutes = Math.floor(duration / 60);
          this.audio.play();
          //console.log(minutes);
        });

        this.audio.addEventListener("ended", () => {
          // El audio ha terminado de reproducirse
        });
      }
    },

    //Whatsapp
    stopSound() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    },

    colorMensaje(estado) {
      if (estado) {
        return "red";
      } else {
        return "green";
      }
    },

    async copiarTexto(textoLlega) {
      if (!navigator.clipboard) {
        console.error(
          "La API navigator.clipboard no es compatible en este navegador."
        );
        return;
      }

      let textoCopiado = textoLlega;

      try {
        await navigator.clipboard.writeText(textoCopiado);
        //console.log("Texto copiado al portapapeles: ", textoCopiado);
      } catch (error) {
        //console.error("Error al copiar el texto al portapapeles:", error);
      }
    },

    reproducirAudio() {
      audio.play();
    },

    colorChat(llega) {
      if (llega === undefined) {
        return "custom-backgroundInterno";
      } else {
        return "custom-background";
      }
    },

    fechaConvertirFirebase(timestamp) {
      if (!timestamp || !timestamp.seconds) {
        return "Fecha inválida";
      }

      const fecha = new Date(timestamp.seconds * 1000);
      const fechaHoraLegible = fecha.toLocaleString();

      return fechaHoraLegible;
    },

    async conversar() {
      //console.log("conversar");
      //console.log(this.plantilla);
      if (this.plantilla === null) {
        return;
      }

      // telefonoSelect, contacto, doliente, correoSelect
      const jsonSale = {
        telefonoSelect: this.celular,
        contacto: this.contacto, //this.datos.contacto,
        doliente: this.NombreEmpleado, //this.datos.doliente,
        correoSelect: "", //this.correo,
        mensaje: this.mensaje, //this.mensaje
        fuente: "nuevoUsuario",
        template: this.plantilla,
        indicativo: this.indicativoSelect,
      };

      if (this.plantilla === "personalizado") {
        //console.log("Personalizado, ya existe conversación.");
        const resultPersonalizado = await FBenviarMensajeWhatsapp(jsonSale);
        if (resultPersonalizado) {
          this.mensaje = "";
        } else {
          console.log("falló en mensaje Whatsapp");
        }
      } else {
        //console.log("Plantilla template..");
        const result = await FBBienvenidaMensajeWhatsapp(jsonSale);
        if (result) {
          this.mensaje = "";
        } else {
          console.log("falló en mensaje Whatsapp");
        }
      }
    },

    listenToMessages() {
      //console.log(this.switchTiempoReal);

      //!this.celular ||
      if (this.celular.trim().length < 10) {
        this.switchTiempoReal = false;
        //console.log("entra a switch");
        return;
      }

      //console.log("Pasa");

      this.$emit("cerrarTienda");

      if (this.switchTiempoReal) {
        //const phoneNumber = "57" + this.celular; // Número de teléfono a consultar
        const phoneNumber = `${String(this.indicativoSelect)}${String(
          this.celular
        )}`;

        //console.log(phoneNumber);
        const docRef = db.collection("cf-whatsapp").doc(phoneNumber);

        this.unsubscribe = docRef.onSnapshot((snapshot) => {
          if (snapshot.exists) {
            const data = snapshot.data();
            if (data && data.messages) {
              this.items = data.messages;
              this.usuarioW = data.usuario;
            } else {
              this.items = [];
            }
          } else {
            this.items = [];
          }
        });
      } else {
        this.stopListening();
      }
    },

    stopListening() {
      if (this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    },

    reiniciar() {
      this.dialog = false;
      this.switchTiempoReal = false;
      this.stopListening();
      this.items = [];
      this.mensaje = "";
      this.celular = "";
      this.contacto = "Desconocido";
    },
  },

  beforeDestroy() {
    this.stopListening();
  },
};
</script>

<style scoped>
.message-scroll {
  max-height: 300px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto;
}

.custom-background {
  background-color: #fff7a3; /* Reemplaza con el color de fondo deseado */
  border-radius: 10px; /* Ajusta el valor para redondear las puntas */
  padding: 10px; /* Ajusta el valor del relleno según tus necesidades */
  color: rgb(58, 58, 58);
}

.custom-backgroundInterno {
  background-color: #a1ffa5; /* Reemplaza con el color de fondo deseado */
  border-radius: 10px; /* Ajusta el valor para redondear las puntas */
  padding: 10px; /* Ajusta el valor del relleno según tus necesidades */
  color: rgb(0, 58, 0);
}
</style>