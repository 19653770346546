import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Login from '../views/Login/Ingreso.vue'
import Home from '../views/Home.vue'
import Inicio from '../views/Inicio.vue'
import Cartera from '../views/Cartera/Paneles.vue'
import RHumanos from '../views/RecursosHumanos/Paneles.vue'
import Secretaria from '../views/Secretaria/Paneles.vue'
import Juridico from '../views/Juridico/contenedor.vue'
import Administrativo from '../views/Administrativo/contenedor.vue'

import Mercadeo from '../views/Mercadeo/Paneles.vue'
import PESV from '../views/PESV/Paneles.vue'
import Ventas from '../views/Ventas/Paneles.vue'

import Produccion from '../views/Produccion/Paneles.vue'

import PedidoTienda from '../views/PedidosTienda/Index.vue';

Vue.use(VueRouter)

const routes = [
  {// Página Pedidos Tienda En Linea
    path: '/Pedidos',
    name: 'PedidosTienda',
    component: PedidoTienda,
    props: true
  },
  {// Página Login
    path: '/',
    name: 'Login',
    component: Login
  },
  {// Página Principal
    path: '/i',
    name: 'Inicio',
    component: Inicio,
    meta: { requiresAuth: true },
  },
  {// Página Cartera
    path: '/Cartera',
    name: 'Cartera',
    component: Cartera,
    meta: { requiresAuth: true },
  },
  {// Página R Humano
    path: '/rh',
    name: 'RHumanos',
    component: RHumanos,
    meta: { requiresAuth: true },
  },
  {// Página Secretaria
    path: '/sc',
    name: 'Secretaria',
    component: Secretaria,
    meta: { requiresAuth: true },
  },
  {// Página Juridico
    path: '/jd',
    name: 'Juridico',
    component: Juridico,
    meta: { requiresAuth: true },
  },
  {// Página Administrativo
    path: '/adm',
    name: 'Administrativo',
    component: Administrativo,
    meta: { requiresAuth: true },
  },
  {// Página Home
    path: '/h',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {// Página Mercadeo
    path: '/Mercadeo',
    name: 'Mercadeo',
    component: Mercadeo,
    meta: { requiresAuth: true },
  },
  {// Página Mercadeo
    path: '/PESV',
    name: 'PESV',
    component: PESV,
    meta: { requiresAuth: true },
  },
  {// Página Mercadeo
    path: '/Ventas',
    name: 'Ventas',
    component: Ventas,
    meta: { requiresAuth: true },
  },
  {// Página Mercadeo
    path: '/Produccion',
    name: 'produccion',
    component: Produccion,
    meta: { requiresAuth: true },
  },
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth) {
    if (store.getters.usuarioAutenticado) {
      next();
    } else {
      next({ name: 'Login' });
    }
  } else {
    // No necesita privilegios
    if (localStorage.usuario) {
      localStorage.removeItem('usuario');
      next();
    } else {
      next();
    }
  }
})

export default router
