<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on" outlined>
          <v-icon>mdi-television</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Detalle </v-card-title>

        <v-card-text class="mt-5">
          <div v-if="modulo == 'titulares'">
            <v-card
              v-for="item in datos"
              :key="item.Cedula"
              class="mx-auto"
              tile
              :outlined="false"
            >
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    Cédula:
                    {{ item.datosTitular.data.Cedula }}</v-list-item-title
                  >

                  <v-list-item-subtitle>
                    Nombre:
                    {{ item.datosTitular.data.Nombre }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>

          <div v-if="modulo == 'asesores'">
            <v-card
              v-for="item in datos"
              :key="item.idAsesor"
              class="mx-auto"
              tile
              :outlined="false"
            >
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    Asesor:
                    {{ item.idAsesor}}</v-list-item-title
                  >

                  <v-list-item-subtitle>
                    Asignados:
                    {{ item.asignados }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>

          <div v-if="modulo == 'gestionados'">
            <v-card
              v-for="item in datos"
              :key="item.data.Cedula"
              class="mx-auto"
              tile
              :outlined="false"
            >
            
            <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    Cédula:
                    {{ item.data.Cedula }}</v-list-item-title
                  >

                  <v-list-item-subtitle>
                    Nombre:
                    {{ item.data.Nombre }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>

          <div v-if="modulo == 'acuerdoDP'">
            <v-card
              v-for="item in datos"
              :key="item.fecha"
              class="mx-auto"
              tile
              :outlined="false"
            >
            {{ item }}
            <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    Cédula:
                    {{ item }}</v-list-item-title
                  >

                  <v-list-item-subtitle>
                    Nombre:
                    {{ item }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "detalleItemInforme",
  props: ["datos", "modulo"],
  data: () => ({
    dialog: false,
  }),
};
</script>