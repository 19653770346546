import { firebase, db, storage } from "../../firebase/config"
import { FBverClientesNit, organizaTitularActual } from "./gestionCartera/consultasBasicas"
import { disparoDepartamento } from "./tipificaciones/asignaciones"
import { FBEstrategiaPorItem } from "./estrategiaCartera";

const ref = storage.ref();

async function FBFiltrosEstrategicos(listadoNits, bolsaSinFiltro, asesor) {
    //console.log("Entra a Filtros Estrategicos");
    //console.log(listadoNits);

    //console.log("------------");
    //console.log(bolsaSinFiltro);

    //console.log("------------");
    //console.log(asesor);
    //Deberia entrar de una a 
    const resultFiltroA = await FBEstrategiaPorItem(listadoNits, bolsaSinFiltro, 'montos_altos', asesor);

    //console.log(resultFiltroA);

    return resultFiltroA;
}

//********* PRIMERO 1º */
//Function Principal para cargar los titulares asignados
//Carga registros a la cartera seleccionada
export async function FBcargaTitulares(titularesPorDia, usuario) {

    //console.log(titularesPorDia, usuario);
    let idAsesor = usuario;
    let maxTitulares = titularesPorDia;

    try {
        //0. Comprobar si existe la base
        const existeBolsaAgente = await FBverificaBolsaAsesor(idAsesor);

        //console.log(existeBolsaAgente);//trae toda la bolsa del Ejecutivo
        //console.log(existeBolsaAgente.length);//Total elementos de la bolsa

        //YA SE HA CARGADO LA BOLSA...
        if (existeBolsaAgente != undefined && existeBolsaAgente.length > 0) {
            //console.log('Titulares asignados.', existeBolsaAgente.length);
            //Existen titulares cargados en la bolsa, pero no sabemos si tenemos carteras pendientes...

            //0. Traer todos los numero de nit
            const resultClientes = await FBverClientesNit();
            //console.log(resultClientes);//Solo los nit con estado true

            /****************** Localizar si Existe una cartera nueva por agregar a la bolsa */

            //const localizarNuevosTitulares = await nuevosTitulares(resultClientes, idAsesor);

            //console.log(existeBolsaAgente);
            //console.log(idAsesor);
            //console.log(resultClientes);

            //1. Vamos a traer el primer titular para trabajar
            const resultTitular = await FBramdomTitular(existeBolsaAgente, idAsesor, resultClientes);
            //trae resultTitular[0] = bolsa de titulares,  resultTitular[1] totalLlamarhoy , resultTitular[2] = sinTocar
            //console.log('resultTitular');
            //console.log(resultTitular);

            return [true, 'titularLlega', resultTitular[0], resultTitular[1], resultTitular[2]];

        } else {
            //console.log('Primera vez, Ejecutivo sin Titulares');
            //1. Traer todos los numero de nit
            const resultClientes = await FBverClientesNit();
            //console.log(resultClientes);//Trae todos los clientes con estado ACTIVO
            //console.log('Resultado Clientes Activos');
            //console.log(resultClientes);// Es un array con los Nits ['900', '901']

            //2. Localiza  Buscar en las carteras por el nit, para traer todas las carteras en estado true
            //OJO SOLO SE PUEDE HACER UNA VEZ / sino DUPLICA TODOS los REGISTROS

            const resultCarteras = await FBListadoCarteraClientesActivos(resultClientes, idAsesor, maxTitulares);
            //cliente: element, idCartera: doc.id,
            // Retorna todos los titulares asignados a ese Ejecutivo o Asesor.
            //console.log(resultCarteras);

            //console.log('Resultado MEGABOLSAS COMPILADAS');
            //console.log(resultCarteras.length);

            //3. Guardar todos los asignados por id +  
            const resultLlenarBolsaTitular = await FBllenarAsignaciones(resultCarteras);

            //Llega un Array con todos los Titulares asignados para iniciar a trabajar. 
            //resultLlenarBolsaTitular
            //console.log('Resultado... .js');
            //console.log(resultLlenarBolsaTitular);
            //console.log(resultLlenarBolsaTitular.length);
            

            if (resultLlenarBolsaTitular.length > 0) {
                /// El Agente Tiene titulares asignados
                return [true, 'recargar'];
            } else {
                /// El Agente No tiene titulares asignados 
                return [false, 'noAsignados'];
            }
        }

    } catch (error) {
        console.log(error);
        return false;
    }
}

//Buscar carteras donde el Asesor esté pendiente por cargar titulares
// Puesto que ya tiene una bolsa incial.
async function listadoCarterasPorGestionar() {
    //
}

//1. Algoritmo para sacar de manera inteligente el primer Titular.
export async function FBramdomTitular(bolsaGeneral, idAsesor, nits) {
    //console.log('FBramdomTitular');
    //console.log(bolsaGeneral.length);
    //console.log(bolsaGeneral[0]);
    //console.log(idAsesor, nits);

    let listadoNits = nits; //Los nits en estado activo
    let asesor = idAsesor;
    let arayTitularCarteras = [];
    let arrayCarterasActivas = [];
    //let nistCarteras = [];


    try {
        //console.log("V 2.0");
        //V 2.0
        //Tengo que pasarla por un filtro estratégico
        const bolsaEstrategica = await FBFiltrosEstrategicos(listadoNits, bolsaGeneral, asesor);
        //console.log('paso A');

        //console.log(bolsaEstrategica);
        //Se activará en caso que no esté configurado con una estrategia
        //const titularAlAzar = await organizaTitularActual(bolsaGeneral);//V1

        const titularAlAzar = await organizaTitularActual(bolsaEstrategica);//V2
        //console.log('paso B');
        //console.log(titularAlAzar);
        //console.log('TOTAL análisis: ', titularAlAzar.length);
        //console.log(titularAlAzar[0]);

        //Retorna ordenado por toques de mayor a menor.
        // arrayFinalOrderBy, totalLlamarhoy, sinTocar
        // [Array(20), 0, 143]

        //console.log('* Proceso Final de Análisis de Titulares *');
        //console.log(titularAlAzar.length);//Llegan los titulares no mayor a 20 unidades listos para trabajar.
        //console.log(titularAlAzar[0]);
        //console.log(titularAlAzar);
        //console.log('Total Cliente: ', listadoNits);
        //V2
        //Ya NO SE REQUIERE porque se hace dentro de la consulta de recorrerCarterasActivas
        /*for (let index = 0; index < listadoNits.length; index++) {
            const element = listadoNits[index];
            //1. Primera consulta para definir que carteras están en estado true
            const empresaRef = db.collection('cf-cartera').doc('Carteras').collection(element);
            const snapshot = await empresaRef.where('estado', '==', true).get();

            if (snapshot.empty) {
                //console.log('No matching documents.');
                //return false;
            }
            snapshot.forEach(async doc => {
                //console.log(doc.id, '=>', doc.data());
                //cliente = element,
                let idCartera = doc.id;
                //nistCarteras.push(element);
                arrayCarterasActivas.push({ 'nit': element, 'idCartera': idCartera });
            });
        }*/

        //console.log('LLegan carteras activas');
        //console.log(arrayCarterasActivas.length);
        //Entra a consultar 1 x 1 para llegar el array
        //const resultadoA = await recorrerCarterasActivas(arrayCarterasActivas, titularAlAzar);
        const resultadoA = await recorrerCarterasActivas(titularAlAzar);
        //console.log('ResultadoA');
        //console.log(resultadoA);

        arayTitularCarteras.push(resultadoA)
        //// SALE DEL FOR PARA CONTINUAR CON LA CONSULTA DE TITULARES ASIGNADOS

        //console.log('Llega el Resultado Final RESULTADOA');
        //console.log(arayTitularCarteras);
        //let arrayFinalOrderBy = _.orderBy(arrayFinal, 'toques', 'desc');arayTitularCarteras
        let arrayFinalOrderBy = _.orderBy(arayTitularCarteras[0], 'orden1', 'desc');
        //console.log('Organizar descending');
        //console.log(arrayFinalOrderBy);

        //titularAlAzar[1] = totalLlamarhoy , titularAlAzar[2] = sinTocar
        //console.log('Organiza Titular...');
        //console.log(titularAlAzar);
        return [arrayFinalOrderBy, titularAlAzar[1], titularAlAzar[2]];
        //return false;

        // Retorna todos los titulares asignados a ese Ejecutivo o Asesor.

        //return arayTitularCarteras;
        //return arayCarteras;

    } catch (error) {

    }
}

//idCarterasActivas
async function recorrerCarterasActivas(titularAlAzar) {
    //Entra SOLO los 20 Registros ya filtrados...
    //console.log('Entra a Recorrer Carteras Activas');
    //console.log(titularAlAzar);
    //console.log(idCarterasActivas); llegaba como parámertro pero se quita
    let arayTitularCarteras = [];
    //console.log('Entra a recorrer activas');
    let totalTitulares = titularAlAzar[0];
    try {

        //*******TOCA METER UN FOR - para recorrer el idCarterasActivas */
        //2. Ahora vamos a recorrer todo y localizaremos al asesor actual al que le ha sido asignado el titular...
        // TRAE TODOS LOS TITULARES ASIGNADOS DE TODAS LAS CARTERAS 
        ///cf-cartera/Carteras/903/ccnfYfqWeacasTFcZONp/registros
        //console.log(totalTitulares.length);
        //V2 8 de mayo del 2022
        //Este cambió llevó a recorrer las carteras activas, y no tiene mucho sentido porque
        // ya llegan los 20 registros, debe de ser antes de llegar aquí.
        for (let index = 0; index < totalTitulares.length; index++) {
            //console.log('Entra a el for de titularAlAzar');
            const elementIdCartera = totalTitulares[index];
            //console.log(elementIdCartera);

            const totalRegistrosCarteraRef = db.collection('cf-cartera')
                .doc('Carteras')
                .collection(elementIdCartera.nitCliente)
                .doc(elementIdCartera.idCartera)
                .collection('registros')
                .doc(elementIdCartera.idRegistro);

            const doc = await totalRegistrosCarteraRef.get();

            if (!doc.exists) {
                //console.log('No such document!');
            } else {
                //console.log('Document data:', doc.data());

                let registro = doc.data();
                registro.idCartera = elementIdCartera.idCartera;
                registro.idRegistro = doc.id;
                registro.nitCliente = elementIdCartera.nitCliente;
                registro.orden1 = index
                arayTitularCarteras.push(registro);
            }

        }

        //V1
        /*for (let index = 0; index < idCarterasActivas.length; index++) {
            const elementIdCartera = idCarterasActivas[index];
            //console.log(elementIdCartera);

            const totalRegistrosCarteraRef = db.collection('cf-cartera').doc('Carteras').collection(elementIdCartera.nit).doc(elementIdCartera.idCartera).collection('registros');

            const consulta = await totalRegistrosCarteraRef.get();

            if (consulta.empty) {
                //console.log('No matching documents.');
                return;
            }
            consulta.forEach(doc => {
                //console.log('Cliente:', element);
                //console.log(doc.id, '=>', doc.data());
                //doc.data() Trae todos los datos que contiene el titular 
                //Version 2
                for (let index = 0; index < titularAlAzar[0].length; index++) {
                    const element = titularAlAzar[0];
                    //console.log(element);
                    if (doc.id == element[index].idRegistro) {

                        let registro = doc.data();
                        registro.idCartera = elementIdCartera.idCartera;
                        registro.idRegistro = doc.id;
                        registro.nitCliente = elementIdCartera.nit;
                        registro.orden1 = index
                        arayTitularCarteras.push(registro);

                    } else {
                        //No pertenece a esta cartera
                        //console.log('No existe en cartera');
                    }
                }


            });
            //console.log('Sale de recorrer');
            //console.log('Retorno recorrerCarterasActivas');

        }*/

        //Concatenar... los arrays
        return arayTitularCarteras;


    } catch (error) {
        //console.log('Error::', error);
    }
}

//Consultar si la bolsa existe del Asesor de lo contrario pase a crear la BOLSA
// ***********************************PILAS*******************************************
// ***********************************DELICADO****************************************
export async function FBverificaBolsaAsesor(idAsesor) {


    // ***********************************PILAS*******************************************
    // ***********************************DELICADO****************************************
    //DONDE ESTE CAMPO SE A VACIO EN UN TRUE VA A CARGAR TODOS LOS TITULARES DE NUEVO!!!!!

    let bolsaCortaAsesor = [];//Devuleve todos los titulares que se encuentran en la GRAN BOLSA
    try {
        //1. El primer codigo es del Asesor, el segundo es del es la llave add
        ///cf-cartera/Asignaciones/JkjkRimf3fFN4MTeQj41/1BKRhJVhb71BKkpdPmIi
        //const citiesRef = db.collection('cf-cartera').doc('Asignaciones').collection(idAsesor)
        // PILAS!!!!!!!!!!!!!!
        const citiesRef = db.collection('cf-cartera')
            .doc('Asignaciones')
            .collection(idAsesor)
        //.where('estado', '!=', false)//.where('tipiUlti', '==', 'Fallecido');
        const snapshot = await citiesRef.get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            return;
        }

        //Se entiende que filtra la bolsa que tiene el EJECUTIVO PARA QUE LUEGO haga el filtro respectivo, sacando todo lo que no debe salir. 
        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            let datos = doc.data();
            //1. Agregué esta parte para que no vuelva a mostrar en el análisis los fallecidos e incobrables
            //console.log(datos.tipiUlti);

            let tipificaciones = ['Demandado - Notificado Oficio', 'Demandado - Notificado Auto', 'Demandado - Notificado Acta Reparto', 'Solicitud Titulo Valor', 'Demanda Radicada', 'Iniciar Demanda', 'Posible Demanda', 'Sin Titulo Valor', 'Novedad ADM', 'Confirmar Pago', 'Verificar Pago', 'Posible Fallecido', 'Posible Incobrable', 'Posible Ilocalizado', 'Fallecido', 'Ilocalizado', 'Incobrable', 'Paz y Salvo - Notificado'];
            // Se quita , 'Paz y Salvo v2', 'Paz y Salvo', 'Notificar Paz y Salvo' porque si deben aparecer en la gestión diaria.

            //Localizamos si la tipificación está almenos 1 vez en el proceso.
            let aA = _.find(tipificaciones, (tipi) => {
                //console.log('-------', datos.Cedula);
                //console.log('-------', datos.tipiUlti);
                //console.log('+++++', tipi);
                //console.log('-------');
                return tipi == datos.tipiUlti;
            });

            //console.warn(aA);
            if (aA == undefined) {
                if (datos.a == undefined) {
                    //Es apto para agregar a la bolsa
                    bolsaCortaAsesor.push(doc.data());
                    //console.log('Apto: ', aA);
                }
            } else {
                //No es apto porque se encontró
                //console.log('Se encontró. ', aA);
            }

            //Se agrega el día 18 pero se elimina el día 17 de Octubre 2022
            /*
            if (datos.a == undefined) {
                if (datos.tipiUlti != 'Fallecido') {
                    if (datos.tipiUlti != 'Ilocalizado') {
                        if (datos.tipiUlti != 'Posible Ilocalizado') {
                            if (datos.tipiUlti != 'Incobrable') {
                                if (datos.tipiUlti != 'Paz y Salvo - Notificado') {
                                    if (datos.tipiUlti != 'Posible Incobrable') {
                                        if (datos.tipiUlti != 'Posible Fallecido') {
                                            if (datos.tipiUlti != 'Verificar Pago') {
                                                if (datos.tipiUlti != 'Confirmar Pago') {
                                                    if (datos.tipiUlti != 'Novedad ADM') {
                                                        if (datos.tipiUlti != 'Sin Titulo Valor') {
                                                            if (datos.tipiUlti != 'Posible Demanda') {
                                                                if (datos.tipiUlti != 'Iniciar Demanda') {
                                                                    if (datos.tipiUlti != 'Demanda Radicada') {
                                                                        bolsaCortaAsesor.push(doc.data());
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            */
        });

        return bolsaCortaAsesor; //V1

        //console.log(bolsaCortaAsesor.length);
        //1. Filtrar cual de esos titulares las carteras están 
        /*for  (let datos of bolsaCortaAsesor) {
            console.log(datos);
            
            const totalRegistrosCarteraRef = db.collection('cf-cartera')
                .doc('Carteras')
                .collection(datos.nitCliente)
                .doc(datos.idCartera)
                .collection('registros')
                .doc(datos.idRegistro);

            const doc = await totalRegistrosCarteraRef.get();

            if (!doc.exists) {
                console.log('No such document!');
            } else {
                //console.log('Document data:', doc.data());
                //console.log( doc.data().observaciones);
                var arrayObservaciones = doc.data().observaciones

                for  (let index of arrayObservaciones) {
                    const element = arrayObservaciones[index].efecto;
                    
                    console.log(element);
                
                }
            }

             
        }*/

        /// CAMBIO REALIZADO EL 28 DE NOV 2022
        // LO HAGO PORUQ EYA NO SE NECESITA LIMPIAR LA BOLSA PORQUE PREVI SE LIMPIA en MODIFICACIONES MASIVAS
        //const resultadoB = await limpiarBolsaPorObservacion(bolsaCortaAsesor);

        //console.log('Saliendo...');
        //console.log(resultadoB);
        //Coloco esta opción por si las moscas... por si filtra todos los resultados que tendrá que mostrar otros....
        /*if (resultadoB.length <= 0) {
            //console.log(bolsaCortaAsesor);
            return bolsaCortaAsesor; //V1
        } else {
            //console.log(resultadoB);
            return resultadoB //V2
        }
        //return true*/

    } catch (error) {
        //console.log('Error:', error);
    }
}

//Debido a la re-asignación en la cual no se excluyeron las tipificaciones finales, esto generó errores y re-procesos, por eso se hace este filtro.
async function limpiarBolsaPorObservacion(bolsaLlega) {

    //console.log(bolsaLlega);
    let bolsaCortaAsesorB = [];//Devuleve todos los titulares que se encuentran en la GRAN BOLSA
    let esValido = true;

    try {
        for (const element of bolsaLlega) {
            //console.log(element);

            const totalRegistrosCarteraRef = db.collection('cf-cartera')
                .doc('Carteras')
                .collection(element.nitCliente)
                .doc(element.idCartera)
                .collection('registros')
                .doc(element.idRegistro)
            //.limit(100);// Se limita para acortar el tiempo de carga... y tambien está acorde al anaálisis de la bolsa que es 100

            const doc = await totalRegistrosCarteraRef.get();

            if (!doc.exists) {
                //console.log('No such document!');
            } else {
                //console.log('Document data:', doc.data());
                //console.log(doc.data().observaciones);
                let arrayObservaciones = doc.data().observaciones;
                for (let index of arrayObservaciones) {
                    let tipificaciones = ['Fallecido', 'Ilocalizado', 'Incobrable', 'Paz y Salvo - Notificado'];
                    // 'Notificar Paz y Salvo', 'Posible Fallecido', 'Posible Incobrable', 'Posible Ilocalizado', 'Solicitud Titulo Valor','Demanda Radicada', 'Iniciar Demanda', 'Posible Demanda','Sin Titulo Valor', 'Novedad ADM', 'Confirmar Pago', 'Verificar Pago', NO aplica porque no es una tifipicación final
                    //se quitan porque sí deben aparecer. no se deben excluir. 

                    //Localizamos si la tipificación está almenos 1 vez en el proceso.
                    let aA = _.find(tipificaciones, (tipi) => {
                        //console.log('-------', datos.Cedula);
                        //console.log('-------', datos.tipiUlti);
                        //console.log('+++++', tipi);
                        //console.log('-------');
                        return tipi == index.efecto;
                    });

                    //console.log(aA);

                    if (aA == undefined) {
                        //Es apto para agregar a la bolsa
                        //bolsaCortaAsesor.push(doc.data());
                        //console.log('Si es Apto: ', aA);
                        //bolsaCortaAsesorB.push(element);

                    } else {
                        //No es apto porque se encontró
                        //console.log('No es Apto. ', aA);
                        esValido = false;
                        break;
                    }
                }//cierra el for de las observaciones

                //console.log('SALE FOR OBSERVACIONES...');
                //console.warn(element);
                //Cuando salga del for nos diga si ese juego de array está limpio y se puede agregar el objeto
                if (esValido) {
                    bolsaCortaAsesorB.push(element);
                }
                esValido = true;
            }

        };
        //console.log('Termina FOR ');
        //console.log(bolsaCortaAsesorB.length);
        return bolsaCortaAsesorB;
    } catch (error) {
        console.log(error);
    }
}

//Guarda todos los asginados del titular por primera vez
export async function FBllenarAsignaciones(bolsaAgente) {
    let totalAsignados = bolsaAgente;
    //console.log(totalAsignados);
    //console.log('FBllenarAsignaciones');
    //console.log(totalAsignados.length);
    //let gfg = _.orderBy(totalAsignados, ['Cedula'], ['desc']);
    //console.log(gfg);

    var limpiandoTA = totalAsignados.filter((dato) => {
        return dato !== undefined;
    });

    //console.log(cars);

    try {
        let bolsaCortaAsesor = [];
        limpiandoTA.forEach(async element => {
            //console.log('***************************************************************');
            //console.log(element.asignadoActual);
            //console.log("Titular: ", element.idRegistro);
            //console.log("Agente: ", element.asignadoActual[0]);


            //Se agregó la cedula para el buscador. 
            let idAsesor = element.asignadoActual[0];
            let fechasLlega = 0;
            //V 3.0 Para agregar detalles a la bolsa del Ejecutivo
            var losToquesNuevos = element.toques - 1;
            let datosPrincipales = ({ 'idRegistro': element.idRegistro, 'toques': losToquesNuevos, 'Cedula': element.Cedula, 'idCartera': element.idCartera, 'idRegistro': element.idRegistro, 'nitCliente': element.nitCliente });

            if (element.proxToque != undefined) {
                datosPrincipales.proxToque = element.proxToque;

            }

            if (element.tipiUlti != undefined || element.tipiUlti != "") {
                datosPrincipales.tipiUlti = element.tipiUlti;
            }

            ///cf-cartera/Asignaciones/80852032/PU9p9iyOQ4z5MZ3TQMQB
            const resulBolsa = await db.collection('cf-cartera').doc('Asignaciones').collection(idAsesor).add(datosPrincipales);
            bolsaCortaAsesor.push(element.idRegistro);

        });
        //Devuelve solo los id para iniciar a trabajar
        //console.log(bolsaCortaAsesor);
        //return true;
        return limpiandoTA;
    } catch (error) {
        //console.log(error);
    }
}


//Listado carteras por cliente 
//Hace la consulta de los titulares asignados
export async function FBListadoCarteraClientesActivos(nits, asesor, maxTitulares) {
    //nits es un array  ['908', '903', '902', '907', '900', '905', '904']
    //console.log(nits, asesor, maxTitulares);
    let listadoNits = nits;
    let totalMegaBolsa = [];
    try {
        let idCarterasActivas = []; //Guarda id CARTERA
        //1. Primera consulta para definir que CARTERAS están en estado TREU
        for (let index = 0; index < listadoNits.length; index++) {
            const nitActual = listadoNits[index];


            const empresaRef = db.collection('cf-cartera').doc('Carteras').collection(nitActual);
            const snapshot = await empresaRef.where('estado', '==', true).get();

            if (snapshot.empty) {
                //console.log('No matching documents.');
                //return false;
            }
            //Traemos todas las carteras. que estan estado activo.
            snapshot.forEach(async doc => {
                let idCartera = doc.id;
                idCarterasActivas.push([{ nit: nitActual, cartera: idCartera }]);
            });
        }
        //console.log('idCarterasActivas:', idCarterasActivas.length);
        //console.log(idCarterasActivas);
        //2. Ahora vamos a recorrer todo y localizaremos al asesor actual al que le ha sido asignado el titular...
        for (let index = 0; index < idCarterasActivas.length; index++) {
            const nitActual = idCarterasActivas[index][0].nit;
            const idCartera = idCarterasActivas[index][0].cartera;
            //console.log(nitActual, idCartera, asesor);

            // TRAE TODOS LOS TITULARES ASIGNADOS DE TODAS LAS CARTERAS
            const MegaBolsaTitularesAsignados = await MegaBolsa(nitActual, idCartera, asesor);
            //console.log('Bolsa:', nitActual);
            // Retorna todos los titulares asignados a ese Ejecutivo o Asesor.
            //console.log('Retornó MEGA-bolsa');
            //console.log(MegaBolsaTitularesAsignados);
            totalMegaBolsa = _.concat(totalMegaBolsa, MegaBolsaTitularesAsignados);
        }

        //console.log('Terminamos de Cargar todas las Mega-Bolsas');
        //console.log(totalMegaBolsa.length);
        return totalMegaBolsa

    } catch (error) {
        //console.log('Error: ', error);
    }

}

//1. Rretorna Array de titulares Asginados por cartera. 
async function MegaBolsa(clienteNit, idCartera, asesor) {

    //console.log('Entra a MEGABOLSA');
    try {
        let arayTitularCarteras = [];
        //console.log('Bienvenido Mega Bolsa');
        /*console.log('id:', idCartera);
        console.log('Nit: ', clienteNit);
        console.log('Asesor:', asesor);
        console.log('Datos para megaB');*/

        const totalRegistrosCarteraRef = db.collection('cf-cartera').doc('Carteras').collection(clienteNit).doc(idCartera).collection('registros').where('asignadoActual', 'array-contains-any', [asesor]);

        const consulta = await totalRegistrosCarteraRef.get();//dividr por el total de carteras.
        //console.log('Sale de consulta -------', consulta.size);

        if (consulta.empty) {
            //console.log('No matching documents.');
            return;
        }
        consulta.forEach(async doc => {
            //console.log(doc.id, '=>', doc.data());
            //doc.data() Trae todos los datos que contiene el titular 
            let registro = doc.data();
            registro.idCartera = idCartera;
            registro.idRegistro = doc.id;
            registro.Cedula = doc.data().data.Cedula;//Nuevo para que trabaje el buscador por cedula
            registro.nitCliente = clienteNit;
            //Nueva parte V 3.0 para identificar cual fué la ultima fecha de próximo contacto
            if (doc.data().proximoContactoCalendario != undefined) {
                registro.proxToque = registro.proximoContactoCalendario;
            }
            registro.tipiUlti = registro.observaciones[registro.observaciones.length - 1].efecto;

            registro.toques = registro.observaciones.length;

            //Segundo Filtro de tipificaciones
            switch (registro.tipiUlti) {
                case 'Incobrable':
                    //console.log('Rechazado');
                    break;
                case 'Ilocalizado':
                    //console.log('Rechazado');
                    break;
                case 'Fallecido':
                    //console.log('Rechazado');
                    break;
                case 'Paz y Salvo - Notificado':
                    //console.log('Rechazado');
                    break;
                default:
                    arayTitularCarteras.push(registro);
                    break;
            }


        });

        //console.log('Sale:' + arayTitularCarteras.length);
        //Actualiza el barrido del asesor para que no vuelva a entrar, encaso que sea false deberá volver a entrar.
        const resultCambioBarrido = await actualizaBarridoAgente(clienteNit, idCartera, asesor);

        //Retorna MegaBolsa por Cartera.
        return arayTitularCarteras;

    } catch (error) {
        //console.log('Error::', error);
    }
}


async function actualizaBarridoAgente(element, idCartera, asesor) {
    try {
        //Entrar a actualizar el asesor en la cartera para informar que esos titulares ya han sido cargados. y el barrido ha sido exitoso
        //let AsesoresLimpios = { idAsesor: idAsesor, puntajeCartera: 0, monto: element.TOTAL, asignados: element.totalAsignados, barrio: false };
        //console.log('Nit: ', element);
        //console.log('idCartera: ', idCartera);
        //console.log('asesor: ', asesor);
        let nitCliente = element;
        let AsesorLogueado = asesor;
        let AsesoresLimpios = { barrio: true };
        const arrayUnionB = firebase.firestore.FieldValue.arrayUnion;
        //.doc(idCartera)
        const guardaAsesoresEnCartera = db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(idCartera);

        const resultCarteraAsesores = await guardaAsesoresEnCartera.get();
        //console.log('Resultado *******************************');
        if (!resultCarteraAsesores.exists) {
            //console.log('No such document!');
            return;
        } else {
            //console.log('Document data:', resultCarteraAsesores.data());

            if (resultCarteraAsesores.data().asesores != undefined) {
                let juegoAsesores = resultCarteraAsesores.data().asesores;

                juegoAsesores.forEach(async element => {
                    //console.log('element: ', element.idAsesor);
                    //console.log('Asesor Actual:', AsesorLogueado);
                    if (element != undefined) {
                        if (element.idAsesor == AsesorLogueado && element.idAsesor != undefined) {
                            //console.log('EXISTE ASESOR');
                            //console.log(element);

                            let modificacion = element;
                            modificacion.barrio = true;

                            let posicion = _.findIndex(juegoAsesores, ['idAsesor', element.idAsesor]);

                            juegoAsesores.splice(posicion, 1, modificacion);


                            //console.log('Modifica...');
                            //console.log(juegoAsesores);


                            //encontrarElemento Posición del elemento que se encontró

                            // Replace item at index using native splice
                            //arr.splice(index, 1, {id: 100, name: 'New object.'});

                            //console.log(posicion);

                            //Eso marca que ese Asesor ya pasó por aquí y no tiene porque volver a pasar a no ser que sea false, (Se agrega titulares ejemplo)
                            const guardaAsesoresEnCartera = await db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(idCartera).update({ asesores: juegoAsesores });

                            //console.log(guardaAsesoresEnCartera);



                            return;
                        } else {
                            //console.log('No Existe');
                            return;
                        }
                    } else {
                        return;
                    }
                });
            } else {
                //console.log('No existen datos...');

            }


            return;
        }



        //return true;
    } catch (error) {
        console.log('Error Barrido: ', error);
    }

}

//Agregar una observación al titular + Huella
// El ultimo campo que recibe resultadoFechas es solo para acuerdos de pago. 
// y newTotalAjustado que es el total acordado en el acuerdo de pago.
//HUELLA MODIFICADA
export async function FBagregaObservacion(jsonDatos) {

    //titularActual, selectContacto, selectEfecto, date, textTareaBoservaciones, resultadoFechas, newTotalAjustado, usuario

    /*console.log(titularActual);
    console.log(titularActual.idRegistro);
    console.log(selectContacto);
    console.log(selectEfecto);
    console.log(date);
    console.log(textTareaBoservaciones);
    console.log(resultadoFechas);
    console.log(usuario);*/

    //console.log(jsonDatos);
    //console.log(jsonDatos.titularActual.asignadoActual[0]);
    try {
        let idResponsable = jsonDatos.titularActual.asignadoActual[0];//Era antes... un ERROR...
        let idLogueado = jsonDatos.usuario
        let idCartera = jsonDatos.titularActual.idCartera;
        let idTitular = jsonDatos.titularActual.idRegistro;
        let cliente = jsonDatos.titularActual.nitCliente;
        let contacto = jsonDatos.selectContacto;
        let efecto = jsonDatos.selectEfecto;
        let observaciones = jsonDatos.textTareaBoservaciones;

        //console.log('----', idLogueado);
        //console.log(idResponsable, idCartera, idTitular, cliente, contacto, efecto, observaciones);

        let nuevaObservacionCartera = { contacto: contacto, efecto: efecto, fecha: jsonDatos.date, observacion: observaciones, fechaR: firebase.firestore.Timestamp.now(), }
        let huella = { fecha: firebase.firestore.Timestamp.now(), tipoCambio: efecto, usuario: idLogueado, }
        let acuerdoPago = [];

        let valorCuota = 0;
        //console.log(resultadoFechas);
        if (jsonDatos.resultadoFechas != undefined) {
            valorCuota = Math.floor((jsonDatos.newTotalAjustado / jsonDatos.resultadoFechas.length));

            if (jsonDatos.resultadoFechas.length > 0 && jsonDatos.selectEfecto == "Acuerdo de pago") {
                jsonDatos.resultadoFechas.forEach(element => {
                    acuerdoPago.push({ fecha: element, estado: false, monto: valorCuota })
                });
            }
        }

        //console.log(acuerdoPago);


        //1. Almacena Array em OBSERVACIONES o ACUERDO DE PAGO
        const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
        ///cf-cartera/Carteras/903/ccnfYfqWeacasTFcZONp/registros/0o8mEQs5NMf4QUMIbHUW
        ///cf-cartera/Carteras/55178395/MkbMgl9hFezaDts9xaJn/registros/PdWn7otq70790BMtzZnq
        const titularRef = db.collection('cf-cartera').doc('Carteras').collection(cliente).doc(idCartera).collection('registros').doc(idTitular);
        if (jsonDatos.resultadoFechas != undefined) {

            if (jsonDatos.resultadoFechas.length > 0 && jsonDatos.selectEfecto == "Acuerdo de pago") {

                const result = await titularRef.update({
                    observaciones: arrayUnion(nuevaObservacionCartera),
                    proximoContactoCalendario: jsonDatos.date,//Nos ayudará a luego filtrar por esa fecha para priorizar
                    timeRegistroModificado: arrayUnion(huella),
                    acuerdoPago: acuerdoPago,
                })
            }
        } else {

            const result = await titularRef.update({
                observaciones: arrayUnion(nuevaObservacionCartera),
                proximoContactoCalendario: jsonDatos.date,//Nos ayudará a luego filtrar por esa fecha para priorizar
                timeRegistroModificado: arrayUnion(huella),
            })
        }


        //2. Segundo registro en la Bolsa
        //A futuro se filtra las tipificaciones para que cuando sea una tipificacion de eliminar se valla a false.
        const increment = firebase.firestore.FieldValue.increment(1);
        //proxToque: date,
        //Se agrega fechaResgistro para poder sacar el análisis de lo que hizo en el día. 
        //let updateBolsa = { toques: increment, proxToque: jsonDatos.date, tipiUlti: jsonDatos.selectEfecto } v.2
        let updateBolsa = { toques: increment, proxToque: jsonDatos.date, tipiUlti: jsonDatos.selectEfecto, fechaRegistro: firebase.firestore.Timestamp.now() };

        ///cf-cartera/Asignaciones/idAsesor/PU9p9iyOQ4z5MZ3TQMQB
        //console.log(idResponsable, ' - ', idTitular);
        let titularEnProceso1 = [];
        const resulBolsa = await db.collection('cf-cartera').doc('Asignaciones').collection(idResponsable).where('idRegistro', '==', idTitular).get();
        //console.log(resulBolsa);

        if (resulBolsa.empty) {
            //console.log('No matching documents. update Bolsa');
            //return false;// Antes salia error....
        }

        resulBolsa.forEach(async doc => {
            //console.log(doc.id, '=>', doc.data());
            //console.log(doc.id);
            //titularEnProceso.push(doc.id);
            titularEnProceso1.push(doc.data());
            const updateRegistroBolsa = db.collection('cf-cartera').doc('Asignaciones').collection(idResponsable).doc(doc.id)
            const result1 = await updateRegistroBolsa.update(updateBolsa);

            //titularEnProceso.push(doc.id);
            //console.log(result1);
        });


        //3. DISPARO A DEPARTAMENTO
        //const resulBolsa = await db.collection('cf-cartera').doc('Asignaciones').collection(idResponsable).where('idRegistro', '==', idTitular).get();
        const departametosAsignados = await disparoDepartamento(jsonDatos.selectEfecto);

        let data = {};
        for (var x of titularEnProceso1) {
            //console.log(x.Cedula);
            data.Cedula = x.Cedula;
            data.proxToque = firebase.firestore.Timestamp.now()//Mejor Fecha registro.//jsonDatos.date//x.proxToque
            data.tipiUlti = efecto//x.tipiUlti
            data.idCartera = x.idCartera
            data.idRegistro = x.idRegistro
            data.nitCliente = x.nitCliente
            data.toques = increment//x.toques
        }

        departametosAsignados.forEach(async departamento => {
            try {
                if (departamento != 'n/a') {

                    const resulBolsa = db.collection('cf-cartera').doc('Asignaciones').collection(departamento);
                    const inyectar = await resulBolsa.add(data);
                    //console.log(inyectar);
                }

            } catch (error) {
                console.log(error);
            }
        });




        return true;
    } catch (error) {
        console.log(error);
    }
}

export async function FBupdateTitular(titularActual, campo, valor, usuario) {
    try {
        const idLogueado = usuario;
        const idResponsable = titularActual.asignadoActual[0];
        const idCartera = titularActual.idCartera;
        const idTitular = titularActual.idRegistro;
        const cliente = titularActual.nitCliente;
        const nodo = campo;
        const value = valor;
        let valueUpdate = {};

        if (nodo !== 'puntaje') {
            valueUpdate[`data.${nodo}`] = value;
        } else {
            valueUpdate = { 'puntaje': Number(valor) };
        }

        const huella = { fecha: firebase.firestore.Timestamp.now(), tipoCambio: nodo, usuario: idLogueado };

        const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
        const empleadoRef = db.collection('cf-cartera').doc('Carteras').collection(cliente).doc(idCartera).collection('registros').doc(idTitular);

        await empleadoRef.update(valueUpdate);
        await empleadoRef.update({ timeRegistroModificado: arrayUnion(huella) });

        return true;
    } catch (error) {
        console.log('Error: ', error);
        return false;
    }
}


//Actualiza Acuerdo de pago + huella
//this.titularActual, campo, valor, index
export async function FBupdateTitularAcuerdoPago(titularActual, campo, valor, array, usuario) {

    let idResponsable = titularActual.asignadoActual[0];
    let idLogueado = usuario
    let idCartera = titularActual.idCartera;
    let idTitular = titularActual.idRegistro;
    let cliente = titularActual.nitCliente;
    let nodo = campo;
    let value = valor;

    //console.log(idResponsable, nodo, valor);

    let huella = { fecha: firebase.firestore.Timestamp.now(), tipoCambio: nodo, usuario: idLogueado, }
    let pago = array;

    //console.log('Recorrer Array de pagos'); Para luego mostrar el historial de pago. 
    var alertaTrue = false;
    var posicion = null;

    array.forEach((element, index) => {
        //console.log(index);
        //console.log(element.estado);
        if (element.estado == false && alertaTrue == false) {
            //console.log('Acuerdo numero ', (index - 1), ' realizado ');
            //console.log('true ', index);
            alertaTrue = true;
            if (index != 0) {
                posicion = index - 1;
            } else {
                posicion = index
            }
            //console.log(posicion);
        } else {
            //console.log('Paso...');
        }
    });

    //console.log(posicion);
    //Es porque es la ultima posición, el ultimo acuerdo de pago. 
    if (posicion == null) {
        posicion = (array.length - 1);
    }
    //console.log(posicion);

    let historialPagosArray = { fecha: firebase.firestore.Timestamp.now(), pago: array[posicion], usuario: idLogueado, }

    //console.log(valueUpdate);
    //console.log(huella);
    //console.log(pago);
    try {
        const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
        ///cf-cartera/Carteras/903/ccnfYfqWeacasTFcZONp/registros/0o8mEQs5NMf4QUMIbHUW
        const empleadoRef = db.collection('cf-cartera').doc('Carteras').collection(cliente).doc(idCartera).collection('registros').doc(idTitular);
        await empleadoRef.update({
            acuerdoPago: pago,
            timeRegistroModificado: arrayUnion(huella),
            historialPagos: arrayUnion(historialPagosArray)
        })

        //2. Se debe agregar el pago en el historial donde nunca se borrará.
        return true;
    } catch (error) {
        //console.log('Error: ', error);
        return false;
    }


}


//Busca PDF --id, nit, tipo
export async function FBverPDFClientes(nit, tipo) {

    //Traemos id del cliente
    const idCliente = await FBbuscarDatosCliente(nit)

    //console.log('entra a encontrar la ruta');
    let tipoDoc = "";
    switch (tipo) {
        case 'endoso':
            tipoDoc = "carta-de-endoso";
            break;
        case 'cuentas':
            tipoDoc = "entidades-de-pago";
            break;

        default:
            break;
    }

    try {

        ///st-cartera/Clientes/906/DocumentosCreacion/I5lv5RXy08gEPIf8olzf
        const storageRef = firebase.storage().ref("st-cartera/Clientes/" + nit + "/DocumentosCreacion/" + idCliente + "/" + tipoDoc + ".pdf");
        const result = await storageRef.getDownloadURL();
        //console.log(result);
        return result
    } catch (error) {
        console.log(error);
        return false;
    }

}

//Buscar datos del cliente
export async function FBbuscarDatosCliente(nit) {
    let data = [];
    try {
        const clientesRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const snapshot = await clientesRef.where('data.nit', '==', nit).get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            //False no existe
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            data.push(doc.id);
        });

        //True es existe
        return data;

    } catch (error) {
        console.log(error);
    }

}

//Carga PDF Titulo Valor del Titular
export async function FB_PDF_TituloValor_Titular(titularActual, archive, tipo, usuario) {

    //console.log(titularActual, archive, tipo, usuario);

    let idLogueado = usuario
    let cedulaTitular = titularActual.data.Cedula;
    let cliente = titularActual.nitCliente;
    let documentoPDF = archive;
    let tipoDocumento = tipo;
    let huellaTipoCambio = ''; //Para luego modificar en la huella
    let fechaActual = firebase.firestore.Timestamp.now();


    try {
        //1. Se sube el archivo
        //* 903 es el nit del cliente
        //* Factura puede ser tambien: pedido o pagaré
        ///st-cartera/Clientes/903/Titulares/CedulaTitular/TituloValor/titulo-valor.pdf
        //pazysalvoV2
        if (tipoDocumento == 'tituloValor') {
            huellaTipoCambio = 'docPedido'
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/" + tipoDocumento + "-" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            //console.log(respuesta.ref);//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            var URLFile = await respuesta.ref.getDownloadURL();

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'pagos') {
            huellaTipoCambio = 'Registro Pago';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Pagos/" + fechaActual.seconds + "-" + fechaActual.nanoseconds + "-.pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            //console.log(respuesta.ref);//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            var URLFile = await respuesta.ref.getDownloadURL();

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'acuerdoPago') {
            huellaTipoCambio = 'Acuerdo Pago';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/AcuerdoPago/" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            var URLFile = await respuesta.ref.getDownloadURL();
            //console.log(respuesta.ref.getDownloadURL());//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'pazysalvoOld') {
            huellaTipoCambio = 'Paz y Salvo v1';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/PazySalvov1/" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            var URLFile = await respuesta.ref.getDownloadURL();
            //console.log(respuesta.ref.getDownloadURL());//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            //Marcamos el titular con pazYsalvo v.1 como ok
            //titularActual, campo, valor
            //const updateTitular = await FBupdateTitular(titularActual, 'pazYsalvo', 1);

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'pazysalvoV2') {
            huellaTipoCambio = 'Paz y Salvo v2';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/PazySalvov2/" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            var URLFile = await respuesta.ref.getDownloadURL();
            //console.log(respuesta.ref.getDownloadURL());//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            //Marcamos el titular con pazYsalvo v.1 como ok
            //titularActual, campo, valor
            //const updateTitular = await FBupdateTitular(titularActual, 'pazYsalvo', 1);

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'PDF-Demanda') {
            huellaTipoCambio = 'PDF Demanda';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/demanda-" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            var URLFile = await respuesta.ref.getDownloadURL();
            //console.log(respuesta.ref.getDownloadURL());//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            //Marcamos el titular con pazYsalvo v.1 como ok
            //titularActual, campo, valor
            //const updateTitular = await FBupdateTitular(titularActual, 'pazYsalvo', 1);

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'ActaReparto') {
            huellaTipoCambio = 'PDF Acta Reparto';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/actareparto-" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            var URLFile = await respuesta.ref.getDownloadURL();
            //console.log(respuesta.ref.getDownloadURL());//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            //Marcamos el titular con pazYsalvo v.1 como ok
            //titularActual, campo, valor
            //const updateTitular = await FBupdateTitular(titularActual, 'pazYsalvo', 1);

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'Auto') {
            huellaTipoCambio = 'PDF Auto';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/auto-" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            var URLFile = await respuesta.ref.getDownloadURL();
            //console.log(respuesta.ref.getDownloadURL());//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            //Marcamos el titular con pazYsalvo v.1 como ok
            //titularActual, campo, valor
            //const updateTitular = await FBupdateTitular(titularActual, 'pazYsalvo', 1);

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'Oficio') {
            huellaTipoCambio = 'PDF Oficio';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/oficio-" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            var URLFile = await respuesta.ref.getDownloadURL();
            //console.log(respuesta.ref.getDownloadURL());//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            //Marcamos el titular con pazYsalvo v.1 como ok
            //titularActual, campo, valor
            //const updateTitular = await FBupdateTitular(titularActual, 'pazYsalvo', 1);

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'TerminacionProceso') {
            huellaTipoCambio = 'PDF Terminación de Proceso';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/tp-" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            var URLFile = await respuesta.ref.getDownloadURL();
            //console.log(respuesta.ref.getDownloadURL());//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            //Marcamos el titular con pazYsalvo v.1 como ok
            //titularActual, campo, valor
            //const updateTitular = await FBupdateTitular(titularActual, 'pazYsalvo', 1);

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        } else if (tipoDocumento == 'LiquidacionTitulos') {
            huellaTipoCambio = 'PDF Liquidación de Titulos';
            const refImag = ref.child("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/lt-" + cedulaTitular + ".pdf");
            const respuesta = await refImag.put(documentoPDF);
            //respuesta.ref 
            //{bucket, fullPath, name, }
            var URLFile = await respuesta.ref.getDownloadURL();
            //console.log(respuesta.ref.getDownloadURL());//Trae toda la info de la subida
            //const urlPDF = respuesta.ref.bucket + '/' + respuesta.ref.fullPath;

            //2. Envia true al registro del titular para saber que ya está disponible.
            //const respuesta2 = await subeInfo(idDb, respuesta); 

            const huella = await FBupdateTituloValorHuellaTitular(titularActual, huellaTipoCambio, true, idLogueado)
            // huella retorna true

            //Marcamos el titular con pazYsalvo v.1 como ok
            //titularActual, campo, valor
            //const updateTitular = await FBupdateTitular(titularActual, 'pazYsalvo', 1);

            // return ([huella, urlPDF])
            return ([huella, URLFile])
        }
        // @change="validaSubidaPDFS(file4, 'pazysalvoOld')"



    } catch (error) {
        //console.error('error: ' + error);
        return false;
    }
}

//Actualiza campos 'docPedido' titulo valor del titular + huella
export async function FBupdateTituloValorHuellaTitular(titularActual, campo, valor, idLogueado) {

    //console.log(campo, valor);
    let idLogueadoLlega = idLogueado;
    let idResponsable = titularActual.asignadoActual[0]
    let idCartera = titularActual.idCartera;
    let idTitular = titularActual.idRegistro;
    let cliente = titularActual.nitCliente;
    let nodo = campo;
    let value = valor;
    let tipoCambio = '';
    let valueUpdate = {};
    if (nodo == 'docPedido') {
        valueUpdate = { 'docPedido': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'PDF Titulo Valor'
    } else if (nodo == 'Registro Pago') {
        tipoCambio = 'PDF Pago'
    } else if (nodo == 'Acuerdo Pago') {
        valueUpdate = { 'acuerdoPagoDoc': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'PDF Acuerdo Pago'
    } else if (nodo == 'Paz y Salvo v1') {//nodo = Paz y Salvo v1
        valueUpdate = { 'PazySalvov1': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'Paz y Salvo v.1'
    } else if (nodo == 'Paz y Salvo v2') {//nodo = Paz y Salvo v1
        valueUpdate = { 'PazySalvov2': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'Paz y Salvo v.2'
    } else if (nodo == 'PDF Demanda') {//nodo =
        valueUpdate = { 'pdfDemanda': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'PDF Demanda'
    } else if (nodo == 'PDF Acta Reparto') {//nodo = 
        valueUpdate = { 'pdfActaReparto': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'PDF Acta Reparto'
    } else if (nodo == 'PDF Auto') {//nodo = 
        valueUpdate = { 'pdfAuto': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'PDF Auto'
    } else if (nodo == 'PDF Oficio') {//nodo = 
        valueUpdate = { 'pdfOficio': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'PDF Oficio'
    } else if (nodo == 'PDF Terminación de Proceso') {//nodo = 
        valueUpdate = { 'pdfTp': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'PDF Terminación de Proceso'
    } else if (nodo == 'PDF Liquidación de Titulos') {//nodo = 
        valueUpdate = { 'pdfLt': value }//Lo tengo para poner ese estado en primer nivel es importante!
        tipoCambio = 'PDF Liquidación de Titulos'
    }

    let huella = { fecha: firebase.firestore.Timestamp.now(), 'tipoCambio': tipoCambio, usuario: idLogueadoLlega, }

    //console.log(valueUpdate);
    //console.log(huella);

    try {
        const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
        ///cf-cartera/Carteras/903/ccnfYfqWeacasTFcZONp/registros/0o8mEQs5NMf4QUMIbHUW
        const empleadoRef = db.collection('cf-cartera').doc('Carteras').collection(cliente).doc(idCartera).collection('registros').doc(idTitular);
        if (nodo == 'docPedido' || nodo == 'Acuerdo Pago' || nodo == 'Paz y Salvo v1'
            || nodo == 'Paz y Salvo v2' || nodo == 'PDF Demanda' || nodo == 'PDF Acta Reparto'
            || nodo == 'PDF Auto' || nodo == 'PDF Oficio' || nodo == 'PDF Terminación de Proceso'
            || nodo == 'PDF Liquidación de Titulos') {
            await empleadoRef.update(valueUpdate);
        }
        //Registra huella
        await empleadoRef.update({
            timeRegistroModificado: arrayUnion(huella),
        })

        return true;
    } catch (error) {
        //console.log('Error: ', error);
        return false;
    }


}

//Busca PDF Titulo Valor Tiular
export async function FBverPDFTituloValorTitular(titularActual, tipo) {
    let cliente = titularActual.nitCliente;
    let cedulaTitular = titularActual.data.Cedula;

    try {

        if (tipo == 'acuerdo-de-pago') {
            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/AcuerdoPago/" + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else if (tipo == 'tituloValor') {

            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/" + tipo + '-' + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else if (tipo == 'paz-salvo-v1') {

            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/PazySalvov1/" + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else if (tipo == 'paz-salvo-v2') {

            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/PazySalvov2/" + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else if (tipo == 'demanda') {

            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/" + "demanda" + "-" + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else if (tipo == 'reparto') {

            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/" + "actareparto" + "-" + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else if (tipo == 'auto') {

            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/" + "auto" + "-" + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else if (tipo == 'oficio') {

            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/" + "oficio" + "-" + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else if (tipo == 'tp') {

            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/" + "tp" + "-" + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else if (tipo == 'lt') {

            ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
            const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Juridico/" + "lt" + "-" + cedulaTitular + ".pdf");
            const result = await storageRef.getDownloadURL();
            //console.log(result);
            return result
        } else {
            return false
        }

    } catch (error) {
        console.log(error);
    }

}


//Busca PDF Recibos de Pago Titular
export async function FBverPDFpagosTitular(titularActual) {
    let cliente = titularActual.nitCliente;
    let cedulaTitular = titularActual.data.Cedula;


    try {
        let listadoUrlArchivos = [];
        //("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Pagos/" + tipoDocumento + "-" + cedulaTitular + "-" + fechaActual + ".pdf");
        ///st-cartera/Clientes/903/Titulares/431800506/tituloValor-431800506.pdf
        const storageRef = firebase.storage().ref("st-cartera/Clientes/" + cliente + "/Titulares/" + cedulaTitular + "/Pagos/");
        //const result = await storageRef.getDownloadURL();

        //1.Trae todos los documentos encontrados dentro de la carpeta Pagos del titular
        const result = await storageRef.listAll();

        //2. Recorrer el listado que llega
        result.items.forEach(async (imageRef) => {

            //fechaActual.seconds + "-" + fechaActual.nanoseconds + ".pdf"
            //console.log(imageRef);
            //console.log(imageRef.bucket);
            //console.log(imageRef.name);
            const URLArchivo = await imageRef.getDownloadURL();

            //console.log(URLArchivo);
            listadoUrlArchivos.push({ url: URLArchivo, time: imageRef.name });
        });



        return [true, listadoUrlArchivos]
    } catch (error) {
        //console.log(error);
    }

}

//AGREGAR un ABONO
export async function FBAddAbono(titularActual, campo, valor, usuario) {
    try {
        //console.log(titularActual, campo, valor, usuario);

        let idResponsable = titularActual.asignadoActual[0];
        let idLogueado = usuario
        let idCartera = titularActual.idCartera;
        let idTitular = titularActual.idRegistro;
        let cliente = titularActual.nitCliente;
        let nodo = campo;
        let value = valor;
        let fechaR = firebase.firestore.Timestamp.now();

        let huella = { fecha: fechaR, tipoCambio: 'Abono', usuario: idLogueado, }
        let historialPagosArray = { fecha: fechaR, pago: { estado: true, monto: value, fecha: fechaR, tipo: 'Abono' }, usuario: idLogueado, }

        const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

        //1. Agregar el Abono
        const empleadoRef = db.collection('cf-cartera').doc('Carteras').collection(cliente).doc(idCartera).collection('registros').doc(idTitular);
        await empleadoRef.update({
            //abonos: pago,
            timeRegistroModificado: arrayUnion(huella),
            historialPagos: arrayUnion(historialPagosArray)
        })

        //2. Agregar Historial de pagos

        //3. Huella

        return true
    } catch (error) {
        console.log(error);
        return false
    }
}


//**********************************************BUSCADOR 01 POR ELIMINAR************************************************/

//BUSCADOR 01
//Consultar si la bolsa existe del Asesor de lo contrario pase a crear la BOLSA
export async function FBconsultaTitularPorCedula(idAsesor, titular) {
    //console.log('FB', idAsesor, titular);

    let datosTitular = [];
    try {
        //1. El primer codigo es del Asesor, el segundo es del es la llave add
        ///cf-cartera/Asignaciones/JkjkRimf3fFN4MTeQj41/1BKRhJVhb71BKkpdPmIi

        const citiesRef = db.collection('cf-cartera').doc('Asignaciones').collection(idAsesor).where('Cedula', '==', Number(titular));
        //.collection("Z9yqrRvsymlViYTMxoMy").where("Cedula", "==", 1037545432)
        const snapshot = await citiesRef.get();
        if (snapshot.empty) {
            console.log('No matching documents.');
            return [false, 'No se encuentró registro.'];
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            datosTitular.push(doc.data());
        });

        //return bolsaCortaAsesor;
        return [true, 'Cargando datos titular.', datosTitular]

    } catch (error) {
        console.log(error);
    }
}

//BUSCADOR 01
export async function FBlocalizarTitularPorCedula(a, b) {

    let idAsesor = a;
    let arrayTitularBasico = b;
    let cedulaTitular = arrayTitularBasico[0].Cedula

    //console.log(arrayTitularBasico[0].Cedula);

    try {
        //0. Traer todos los numero de nit
        const resultClientes = await FBverClientesNit();
        //console.log(resultClientes);
        //1. Vamos a traer el primer titular para trabajar
        let arayTitularCarteras = [];

        for (let index = 0; index < resultClientes.length; index++) {
            const element = resultClientes[index];

            //1. Primera consulta para definir que carteras están en estado true
            const empresaRef = db.collection('cf-cartera').doc('Carteras').collection(element);
            const snapshot = await empresaRef.where('estado', '==', true).get();

            if (snapshot.empty) {
                //console.log('No matching documents.');
                //return false;
            }

            snapshot.forEach(async doc => {
                //console.log('Entra a Buscar en cada cartera');
                //cliente = element,
                //console.log(doc.id, '=>', doc.data());
                let idCartera = doc.id;
                //console.log(element);
                //console.log(idCartera);
                //console.log('titular a buscar:', titularAlAzar.idRegistro);
                //2. Ahora vamos a recorrer todo y localizaremos al asesor actual al que le ha sido asignado el titular...
                // TRAE TODOS LOS TITULARES ASIGNADOS DE TODAS LAS CARTERAS 
                ///cf-cartera/Carteras/903/ccnfYfqWeacasTFcZONp/registros
                //Versión 1.2
                //const totalRegistrosCarteraRef = db.collection('cf-cartera').doc('Carteras').collection(element).doc(idCartera).collection('registros').where("data.Cedula", "==", cedulaTitular).where("data.Telefono1", "==", cedulaTitular);

                const totalRegistrosCarteraRef = db.collection('cf-cartera').doc('Carteras').collection(element).doc(idCartera).collection('registros').where(
                    db.or(
                        db.where("data.Cedula", "==", cedulaTitular),
                        db.where("data.Telefono1", "array-contains", cedulaTitular)
                    )
                );

                const consulta = await totalRegistrosCarteraRef.get();

                consulta.forEach(doc => {
                    //console.log(doc.id, '=>', doc.data());
                    let registro = doc.data();
                    registro.idCartera = idCartera;
                    registro.idRegistro = doc.id;
                    registro.nitCliente = element;
                    registro.orden1 = index
                    arayTitularCarteras.push(registro);

                });



            });



        }

        return arayTitularCarteras;


    } catch (error) {
        console.log(error);
    }

}

//Localizar carteras que no se 
export async function nuevosTitulares() {
    try {
        console.log('Encontar nuevos Titulares');
        return true;
    } catch (error) {

    }
}

//Trae todos los datos del cliente.
export async function FBTodosLosDatosCliente(nit) {
    let data = [];
    try {
        const clientesRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const snapshot = await clientesRef.where('data.nit', '==', nit).get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            //False no existe
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            data.push({ id: doc.id, data: doc.data() });
        });

        //True es existe
        return data;

    } catch (error) {
        console.log(error);
    }

}

//**********************************************BUSCADOR DCM listado tabla************************************************/
//BUSCADOR 02
//Consultar si la bolsa existe del Asesor de lo contrario pase a crear la BOLSA
export async function FBconsultaTitularPorCedulaDCM(datosTitular) {
    //console.log('FB', datosTitular);

    let jsonTitular = datosTitular;

    let titular = [];
    try {

        //1. Buscar en la cartera. 
        // /cf-cartera/Carteras/805028053/A3YvLMeLBBDbQYQAF7qE/registros
        const buscadorRef = db.collection('cf-cartera').doc('Carteras')
            .collection(jsonTitular.nitCliente).doc(jsonTitular.idCartera)
            .collection('registros').where('data.Cedula', '==', Number(jsonTitular.Cedula));
        //.collection("Z9yqrRvsymlViYTMxoMy").where("Cedula", "==", 1037545432)
        const snapshot = await buscadorRef.get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            return [false, 'No se encuentró registro.'];
        }

        snapshot.forEach(doc => {
            //console.log('Existe Documento');
            //console.log(doc.id, '=>', doc.data());
            titular.push(doc.data());
            titular[0].idRegistro = doc.id;
            titular[0].idCartera = jsonTitular.idCartera;
            titular[0].nitCliente = jsonTitular.nitCliente;
        });

        //return bolsaCortaAsesor;
        return [true, titular]

    } catch (error) {
        console.log(error);
    }
    // return true;
}

//**********************************************BUSCADOR GENERAL lupita************************************************/
//BUSCADOR 03
//Consultar si la bolsa existe del Asesor de lo contrario pase a crear la BOLSA
export async function FBconsultaTitularPorCedulaSinDatos(titularCedula) {
    //console.log('FBSinDatos', titularCedula);
    var cedulaLlega = Number(titularCedula);
    let titularesSalen = [];//Puede ser que la cedula esté en mas de una cartera

    try {

        //0. Listado Clientes
        const resultClientes = await FBverClientesNit();

        //console.log(resultClientes);
        let listCarteras = [];

        for (var nit of resultClientes) {
            //console.log(nit);
            //1. Cargar carteras
            // /cf-cartera/Carteras
            const snapshot = await db.collection('cf-cartera').doc('Carteras').collection(nit).get();;

            snapshot.forEach(doc => {
                //console.log(doc.id, '=>', doc.data());
                listCarteras.push({ cliente: nit, cartera: doc.id })
            });
        }

        //console.log('Termina listado documentos...');
        //console.log(listCarteras); // cliente: "805028053" cartera: "A3YvLMeLBBDbQYQAF7qE" 

        //2. Buscar titular en cada cartera. 
        for (var [index, posicion] of listCarteras.entries()) {
            //console.log(index);
            //console.log(posicion);

            //1. Cargar carteras
            // /cf-cartera/Carteras/805028053/A3YvLMeLBBDbQYQAF7qE/registros

            const snapshot = await db.collection('cf-cartera').doc('Carteras').collection(posicion.cliente).doc(posicion.cartera).collection('registros').where('data.Cedula', '==', cedulaLlega).get();

            if (snapshot.empty) {
                //console.log('No matching documents.');
                //return;
            }

            snapshot.forEach(doc => {
                //console.log(index, posicion);
                //console.log(doc.id, '=>', doc.data());
                titularesSalen.push(doc.data());
                //console.log(titularesSalen.length);

                titularesSalen[titularesSalen.length - 1].idCartera = posicion.cartera;
                titularesSalen[titularesSalen.length - 1].idRegistro = doc.id;
                titularesSalen[titularesSalen.length - 1].nitCliente = posicion.cliente;


                //console.log('********************************************');
                //console.log(titularesSalen);
            });
        }
        //console.log('Saliendo del FOR...');
        //console.log(titularesSalen);

        //Validar que llega
        if (titularesSalen.length == 0) {
            return [false, 'No se encontró registro.'];
        } else {
            return [true, titularesSalen];
        }

    } catch (error) {
        //console.log(error);
        return [false, error];
    }
    // return true;
}