import { firebase, db, storage } from "../../firebase/config"

const ref = storage.ref();

//trae todos los empleados
export async function FBlistadoEmpleado() {
  try {
    const empleadosRef = db.collection('cf-recursoHumano').doc('RecursoHumano').collection('Empleados');
    //const snapshot = await citiesRef.where('capital', '==', true).get();
    const snapshot = await empleadosRef.get();
    if (snapshot.empty) {
      //console.log('No matching documents.');
      return;
    }
    let datos = [];

    snapshot.forEach(doc => {
      //console.log(doc.id, '=>', doc.data());
      let jsonData = doc.data();
      jsonData.id = doc.id;
      datos.push(jsonData)
    });

    return [true, datos];
    //return ([true, res.id])

  } catch (error) {
    console.error('error: ' + error);
    return [false];
  }
}

//trae todos los empleados que están activos y por cargo.
export async function FBlistadoEmpleadoFiltroCargo(cargo) {
  try {
    const empleadosRef = db.collection('cf-recursoHumano').doc('RecursoHumano').collection('Empleados');

    const queryEstado = empleadosRef.where('data.estado', '==', true);

    const queryCargo = queryEstado.where('data.cargo', '==', 'acm');


    const snapshot = await queryCargo.get();

    if (snapshot.empty) {
      //console.log('No matching documents.');
      return;
    }
    let datos = [];

    snapshot.forEach(doc => {
      //console.log(doc.id, '=>', doc.data());
      let jsonData = doc.data();
      jsonData.id = doc.id;
      datos.push(jsonData)
    });

    return [true, datos];
    //return ([true, res.id])

  } catch (error) {
    console.error('error: ' + error);
    return [false];
  }
}

export async function FBpdfHdv(id, cedula) {
  //console.log('entra a encontrar la ruta');
  const storageRef = firebase.storage().ref("st-recursoHumano/Empleado/" + id + "/hdv/hdv-" + cedula + ".pdf");
  const result = await storageRef.getDownloadURL();
  //console.log(result);
  return result

}

export async function FBactualizaEmpleado(campo, id, valor) {

  //console.log('Entramos a actualizar campo');

  let nodo = campo;
  let value = valor;
  let valueUpdate = {};
  if (nodo != 'puntaje') {
    valueUpdate[`data.${nodo}`] = value;
  } else if (nodo == 'puntaje') {
    valueUpdate = { 'puntaje': Number(valor) }
  }

  try {
    ///cf-recursoHumano/RecursoHumano/Empleados/NSKQ1b3nKbAE2vyzRqMA
    const empleadoRef = db.collection('cf-recursoHumano').doc('RecursoHumano').collection('Empleados').doc(id);
    await empleadoRef.update(valueUpdate);

    return true;
  } catch (error) {
    console.log('Error: ', error);
  }

}

//Para Historial de pagos
export async function FBLocalizarEjecutivo() {
  try {

    const empleadosRef = db.collection('cf-recursoHumano').doc('RecursoHumano').collection('Empleados');
    const snapshot = await empleadosRef.get();
    if (snapshot.empty) {
      //console.log('No matching documents.');
      return;
    }
    let datos = [];

    //console.log(snapshot.data());

    snapshot.forEach(doc => {
      //console.log(doc.id, '=>', doc.data());
      //let jsonData = doc.data();
      //jsonData.id = doc.id;
      datos.push({ id: doc.id, info: doc.data() })
    });

    return datos;

  } catch (error) {
    console.log(error);
  }
}