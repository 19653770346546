import { db, firebase } from "@/firebase/config.js";


export async function FBactualizaApoyo(json) {
  try {
    const { etapa, seguimientoId, nota } = json;

    const collectionPathCreaProspecto = `cf-ventas/etapas/${etapa}`;
    const querySnapshot = await db
      .collection(collectionPathCreaProspecto)
      .where("seguimientoId", "==", seguimientoId)
      .get();

    if (!querySnapshot.empty) {
      // Actualizar la nota existente
      const docRef = querySnapshot.docs[0].ref;
      await docRef.update({
        nota: json.nota, // Reemplazar por el valor deseado para actualizar la nota
        fechaUpdate: firebase.firestore.Timestamp.now(),
      });
    } else {
      // Crear una nueva nota dentro del documento
      const docRef = db.collection(collectionPathCreaProspecto).doc();
      await docRef.set({
        seguimientoId,
        nota: json.nota, // Reemplazar por el valor deseado para la nueva nota
        fechaUpdate: firebase.firestore.Timestamp.now(),
      });
    }

    return true; // Retorna true si el proceso es exitoso
  } catch (error) {
    console.error("Error al actualizar o crear la nota:", error);
    return false; // Retorna false si ocurre un error
  }
}

