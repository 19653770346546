import { db, firebase } from "../../firebase/config";

export async function FBcambioProcesoProspecto(json) {
    try {
        //console.log(json);

        const jsonLlega = {
            //etapaAnterior: json.etapa,
            idRegistro: json.idRegistro,
            nuevaEtapa: json.nuevoProceso,
            departamento: json.departamento,
            posicion: json.posicion,
        };

        //console.log(jsonLlega);

        // Construye la ruta de la colección
        const collectionPathCreaProspecto = `${jsonLlega.departamento}/tareasExternas/listado`;

        try {
            // Realiza la consulta utilizando el filtro 'where'
            const querySnapshot = await db
                .collection(collectionPathCreaProspecto)
                .where("seguimientoId", "==", jsonLlega.idRegistro)
                .get();

            // Verifica si se encontró un documento que cumpla con la condición
            if (!querySnapshot.empty) {
                // Obtén la referencia al primer documento encontrado
                const docRef = querySnapshot.docs[0].ref;

                // Actualiza el campo 'estado'
                await docRef.update({
                    estado: jsonLlega.nuevaEtapa, // Reemplaza 'nuevaEtapa' por el valor que desees asignar al campo 'estado'
                });

                const resultadoCategorias = await ActulizaProceo(jsonLlega.idRegistro, jsonLlega.posicion, jsonLlega.nuevaEtapa);

                //console.log(resultadoCategorias);

                //console.log("Campo 'estado' actualizado con éxito");
                return true;
            } else {
                //console.log("No se encontró un documento con seguimientoId igual a jsonLlega.idRegistro");
                return false;
            }
        } catch (error) {
            //console.log("Error al realizar la consulta o actualizar el campo 'estado'", error);
            return false;
        }

    } catch (error) {
        console.error("Error al cambiar el proceso el Apoyo:", error);
        return false;
    }
}


async function ActulizaProceo(idRegistro, posicion, etapa) {
    //console.log(idRegistro, posicion, etapa);

    // Obtén la referencia a la colección "/recursos/etapasProspectos"
    const collectionRef = db.collection("recursos").doc("etapasProspectos");

    try {
        // Obtén todos los campos del documento "/recursos/etapasProspectos"
        const docSnapshot = await collectionRef.get();
        const data = docSnapshot.data();

        //console.log(data);

        // Recorre cada campo en el documento
        for (const campo in data) {

            //console.log(campo);
            // Verifica si el campo existe en "/cf-ventas/etapas"
            if (data.hasOwnProperty(campo)) {
                // Construye la ruta de la subcolección correspondiente en "/cf-ventas/etapas"
                const subCollectionPath = `/cf-ventas/etapas/${campo}`;

                // Realiza la consulta utilizando el filtro 'where'
                const querySnapshot = await db
                    .collection(subCollectionPath)
                    .where("seguimientoId", "==", idRegistro)
                    .get();

                // Verifica si se encontró un documento que cumpla con la condición
                if (!querySnapshot.empty) {
                    // Obtén la referencia al primer documento encontrado
                    const docRef = querySnapshot.docs[0].ref;
                    //console.log("Entra a actualizar el doc especifico");

                    // Obtén los datos actuales en la posición específica
                    const docData = querySnapshot.docs[0].data();
                    const apoyo = docData.apoyo || [];

                    // Realiza los cambios necesarios en los datos de esa posición
                    apoyo[posicion] = { ...apoyo[posicion], 'estado': etapa };

                    // Actualiza todo el array en esa posición
                    await docRef.update({
                        apoyo,
                    });

                    //console.log(`Campo '${campo}' actualizado con éxito`);
                    return true;
                }
            }
        }

        return true;
    } catch (error) {
        console.log("Error al realizar el proceso del apoyo", error);
        return false;
    }
}

