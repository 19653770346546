<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="800px"   persistent scrollable dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small rounded color="purple" dark v-bind="attrs" v-on="on">
          Cotizaciones
        </v-btn>
      </template>

      <v-card class="max-height-80">
        <v-card-title class="text-h5 amber">
          Cotizaciones
          <v-spacer></v-spacer>
          <v-btn color="secondary" fab x-small dark @click="simuladorHtml">
            <v-icon>mdi-television</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-5">
            <!-- FECHAS Y CORREO-->
            <v-col cols="6">
              Por favor tener en cuenta que debe tener mínimo seleccionado un
              correo, el cual será el destinatario. Este proceso sólo notifica
              al email más no al whatsapp ni mensaje de texto del contacto.
              <br />
              Para hacer un texto en negrita solo debes hacerlo así **texto**
            </v-col>
            <v-col cols="6">
              Correo donde llegará la cotización:
              <v-chip color="orange">{{ correoSelec }}</v-chip> <br /><br />
              Fecha próximo contacto:
              <v-chip color="orange">{{ dateCalendario }}</v-chip>
            </v-col>
          </v-row>
          <v-row class="mt-n8">
            <v-col cols="12">
              <!-- 
              <v-toolbar>
                <v-overflow-btn
                  :items="dropdown_font"
                  label="Select font"
                  hide-details
                  class="pa-0"
                ></v-overflow-btn>

                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <v-divider vertical></v-divider>

                  <v-overflow-btn
                    :items="dropdown_edit"
                    editable
                    label="Select size"
                    hide-details
                    class="pa-0"
                    overflow
                  ></v-overflow-btn>

                  <v-divider vertical></v-divider>

                  <v-spacer></v-spacer>

                  <v-btn-toggle
                    v-model="toggle_multiple"
                    color="amber"
                    dense
                    group
                    multiple
                  >
                    <v-btn :value="1" text>
                      <v-icon>mdi-format-bold</v-icon>
                    </v-btn>

                    <v-btn :value="2" text>
                      <v-icon>mdi-format-italic</v-icon>
                    </v-btn>

                    <v-btn :value="3" text>
                      <v-icon>mdi-format-underline</v-icon>
                    </v-btn>

                    <v-btn :value="4" text>
                      <v-icon>mdi-format-color-fill</v-icon>
                    </v-btn>
                  </v-btn-toggle>

                  <div class="mx-4"></div>

                  <v-btn-toggle
                    v-model="toggle_exclusive"
                    color="primary"
                    dense
                    group
                  >
                    <v-btn :value="1" text>
                      <v-icon>mdi-format-align-left</v-icon>
                    </v-btn>

                    <v-btn :value="2" text>
                      <v-icon>mdi-format-align-center</v-icon>
                    </v-btn>

                    <v-btn :value="3" text>
                      <v-icon>mdi-format-align-right</v-icon>
                    </v-btn>

                    <v-btn :value="4" text>
                      <v-icon>mdi-format-align-justify</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </v-toolbar>
              -->
            </v-col>

            <!-- CONTENIDO -->
            <v-col v-if="!procesando" cols="12">
              <v-textarea
                outlined
                v-model="textCotizacion"
                color="orange"
                label="Contenido"
                :disabled="correoSelec === ''"
              ></v-textarea>
            </v-col>
            <v-col v-else cols="12">
              <div class="text-center">
                <v-progress-circular
                  :size="50"
                  color="amber"
                  indeterminate
                ></v-progress-circular>
                {{ respuestaGuardar }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-n10">
            <v-col cols="12">
              <v-btn
                outlined
                color="orange"
                @click="nuevaCotizacion"
                :disabled="procesando || correoSelec === ''"
                block
              >
                Guardar y Enviar al Cliente
              </v-btn>
            </v-col>
          </v-row>
          <!-- LISTADO DE COTIZACIONES REALIZADAS HISTORIAL-->
          <v-row>
            <v-col class="subtitle-2" cols="12"> Historial </v-col>
          </v-row>
          <v-divider color="orange"></v-divider>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, i) in cotizaciones" :key="i">
                  <v-expansion-panel-header>
                    Cotización {{ i + 1 }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-html="item"></div>
                    <v-spacer></v-spacer>
                    <v-tooltip color="green" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          class="white--text ml-0"
                          color="green"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="correoSelec === ''"
                        >
                          <v-icon>mdi-email-arrow-right-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Re-Enviar Cotización</span>
                    </v-tooltip>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="orange" @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { FBcreaProspecto } from "@/components/Ventas/Tableros/agregaCotizacionBtB.js";

export default {
  props: ["datosGenerales", "dateCalendario", "correoSelec"],
  data() {
    return {
      dialog: false,
      dropdown_font: [
        { text: "Arial" },
        { text: "Calibri" },
        { text: "Courier" },
        { text: "Verdana" },
      ],
      dropdown_edit: [
        { text: "100%" },
        { text: "75%" },
        { text: "50%" },
        { text: "25%" },
        { text: "0%" },
      ],
      toggle_exclusive: 2,
      toggle_multiple: [1, 2, 3],
      datosClientes: null,
      cotizaciones: null,
      textCotizacion: "",
      respuestaGuardar: "...",
      procesando: false,
    };
  },
  created() {
    this.inicializar();
  },

  methods: {
    inicializar() {
      this.datosClientes = this.datosGenerales;

      if ("cotizaciones" in this.datosClientes) {
        //console.log("La clave 'cotizaciones' existe en el objeto JSON.");
        this.cotizaciones = this.datosClientes.cotizaciones;
      } else {
        //console.log("La clave 'cotizaciones' no existe en el objeto JSON.");
        this.cotizaciones = [];
      }
      //console.log(this.datosClientes);
    },

    async nuevaCotizacion() {
      if (!this.textCotizacion || this.textCotizacion.trim().length < 100) {
        return;
      }
      this.respuestaGuardar = "Enviando Cotización...";
      this.procesando = true;

      //console.log(this.datosClientes);
      //console.log(this.textCotizacion);

      //etapa, seguimientoId,
      //contacto, correoContacto[0],

      const fechaFirebase = this.fechaConvertirFirebase(
        this.datosClientes.proxContacto
      );

      const resultadoTexto = this.textoFormateado();

      const jsonSale = {
        etapa: this.datosClientes.etapa,
        seguimientoId: this.datosClientes.seguimientoId,
        contacto: this.datosClientes.contacto,
        correoContacto: this.correoSelec, //this.datosClientes.correoContacto,
        cotizacion: resultadoTexto, //this.textCotizacion
        cliente: this.datosClientes.cliente,
        proxContacto: this.dateCalendario, //fechaFirebase,
        doliente: this.datosClientes.doliente,
      };

      const respuesta = await FBcreaProspecto(jsonSale);

      //console.log(respuesta);
      if (respuesta[0]) {
        this.respuestaGuardar = "Se ha enviado con éxito";
        setTimeout(() => {
          this.procesando = false;
        }, 2000);
        this.textCotizacion = "";
      } else {
        this.respuestaGuardar =
          "Error al enviar, asegurate de haber seleccionado email del contacto...";
        setTimeout(() => {
          this.procesando = false;
        }, 3000);
      }
    },

    fechaConvertirFirebase(timestamp) {
      // Convierte el objeto Timestamp a una fecha JavaScript
      const fecha = new Date(timestamp.seconds * 1000); // Multiplica por 1000 para convertir segundos a milisegundos

      // Obtén la fecha y hora en un formato legible
      const fechaHoraLegible = fecha.toLocaleString(); // Utiliza el método toLocaleString() para obtener la fecha y hora en el formato local del usuario

      //console.log(fechaHoraLegible);
      return fechaHoraLegible;
    },

    textoFormateado() {
      let textoConFormato = this.textCotizacion;

      // Reemplazar los saltos de línea con etiquetas <br>
      textoConFormato = textoConFormato.replace(/\n/g, "<br>");

      // Agregar negrita a ciertas partes del texto
      textoConFormato = textoConFormato.replace(
        /(\*\*|__)(.*?)\1/g,
        "<strong>$2</strong>"
      );

      // Devolver el texto formateado
      return textoConFormato;
    },

    simuladorHtml() {
      window.open(
        "https://www.w3schools.com/html/tryit.asp?filename=tryhtml_basic",
        "_blank"
      );
    },
  },
};
</script>
  