<template>
  <div class="text-center">
    <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-2"
          icon
          color="black"
          v-bind="attrs"
          v-on="on"
          outlined
        >
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
      </template>

      <v-card scrollable max-width="500px" dark>
        <v-card-title class="green">
          WhatsApp
          <v-spacer></v-spacer>
          <div class="body-2 mr-5">{{ indica }} {{ celular }}</div>
          <v-btn x-small icon color="green" disabled>
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-card
            v-if="celular !== null && celular !== ''"
            max-width="600px"
            elevation="0"
          >
            <v-list  outlined>
              <div class="message-scroll" ref="scrollContainer">
                <v-list-item v-for="(item, index) in items" :key="index">
                  <v-list-item-avatar>
                    <v-avatar color="orange" size="24">
                      <span class="white--text">{{ index + 1 }}</span>
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      item.usuario !== undefined && item.usuario !== null
                        ? item.usuario
                        : usuarioW
                    }}</v-list-item-title>

                   <!-- Para cuando es un BUTTON -->
                   <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-if="item.body.type === 'button'"
                    >
                      <div
                        class="secondary text-no-wrap rounded-pill d-inline pa-1 deep-purple accent-4 white--text"
                      >
                        {{ item.body.button.text }}
                      </div>
                    </v-list-item-title>

                    <!-- Para cuando es un STICKER -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'sticker'"
                    >
                      <img
                        class="rounded-xxl"
                        :src="item.urlFinal"
                        contain
                        lazy-src="https://picsum.photos/id/11/10/6"
                        :aspect-ratio="16 / 9"
                        height="150"
                      />
                    </v-list-item-title>

                    <!-- Para cuando es un IMAGEN -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'image'"
                    >
                      <button>
                        <img
                          class="rounded-xxl elevation-5 row-pointer"
                          :src="item.urlFinal"
                          contain
                          lazy-src="https://picsum.photos/id/11/10/6"
                          :aspect-ratio="16 / 9"
                          height="150"
                          @click="abrirEnlace(item.urlFinal)"
                        />
                      </button>

                      <div v-if="item.body.image?.caption">
                        <br />
                        <p>{{ item.body.image?.caption }}</p>
                      </div>
                    </v-list-item-title>

                    <!-- Para cuando es un REACTION -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'reaction'"
                    >
                      {{ item.body.reaction.emoji }}
                    </v-list-item-title>

                    <!-- Para cuando es un VIDEO -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'video'"
                    >
                      <button @click="abrirEnlace(item.urlFinal)">
                        <div
                          class="secondary rounded-pill d-inline pa-1 mt-3s deep-purple accent-4 white--text"
                        >
                          <v-icon>mdi-movie</v-icon>
                          Video - Multimedia
                        </div>
                      </button>

                      <div v-if="item.body.video?.caption">
                        <br />
                        <p>{{ item.body.video?.caption }}</p>
                      </div>
                    </v-list-item-title>

                    <!-- Para cuando es un AUDIO -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'audio'"
                    >
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        @click="playSound(item.urlFinal)"
                      >
                        <v-icon dark> mdi-play </v-icon>
                      </v-btn>

                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        @click="stopSound()"
                      >
                        <v-icon dark> mdi-stop </v-icon>
                      </v-btn>
                    </v-list-item-title>

                    <!-- CUando no se identifica el INdefinido o Text -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else
                    >
                      {{ item.body.text?.body || item.body }}
                    </v-list-item-title>

                    <!-- Copia texto de cualquier mensaje que contenga -->
                    <v-list-item-title class="text-right caption mt-2">
                      <v-btn
                        :disabled="
                          item.body.type === 'audio' ||
                          item.body.type === 'sticker' ||
                          item.body.type === 'button'
                        "
                        icon
                        color="amber"
                        @click="
                          copiarTexto(
                            item.body.text?.body ||
                              item.body.image?.caption ||
                              item.body.video?.caption
                          )
                        "
                        x-small
                        class="mr-3"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>

                      <v-chip
                        v-if="item.type !== undefined"
                        x-small
                        color="orange"
                        class="mr-3"
                        outlined
                      >
                        {{ item.type }}
                      </v-chip>

                    </v-list-item-title>

                    <v-chip small color="green" outlined>
                        {{
                          item.usuario !== undefined && item.usuario !== null
                            ? item.usuario
                            : usuarioW
                        }}
                        -
                        {{ fechaConvertirFirebase(item.timestamp) }}
                      </v-chip>
                    <v-divider color="green" class="mt-2"></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-card>

          <v-card elevation="0" class="mt-5" v-else>
            Por favor seleccionar un número del contacto para poder consultar
            los mensajes.
          </v-card>

          <p v-if="items.length <= 0 ? true : false">
            En caso que el usuario no tenga mensajes se debe enviar primero la
            plantilla de Autorización y esperar a que responda.
          </p>

          <!-- SECCIÓN DE CHAT-->
          <v-row class="mt-3" v-if="celular !== null && celular !== ''">
            <v-col cols="10">
              <!--  :disabled=" items.length <= 0 || plantilla === null || plantilla !== 'personalizado' " -->
              <v-textarea
                v-model="mensaje"
                :label="
                  items.length <= 0
                    ? 'Debe enviarse la plantilla Autorización...'
                    : 'Escribe...'
                "
                auto-grow
                outlined
                rows="2"
                row-height="25"
                shaped
                color="green"
                
              ></v-textarea>
            </v-col>
            <v-col cols="2">
              <v-btn
               @click="conversar" 
               icon color="green"
               :disabled="plantilla === null"
               >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-n8" v-if="celular !== null && celular !== ''">
            <v-col>
              <v-select
                v-model="plantilla"
                color="green"
                :items="plantillas"
                label="Plantilla"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-switch
            v-if="celular !== null && celular !== ''"
            v-model="switchTiempoReal"
            :label="`Consultar e Iniciar Conversación`"
            color="green"
            @change=" listenToMessages"
          ></v-switch>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="reiniciar"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase/config.js";

import { FBtemplateWhatsapp } from "@/components/Ventas/Tableros/whatsapp/configuracion.js";

//import { FBconsultaChat } from "@/components/Ventas/Tableros/whatsapp/chatPersonalizado.js";
import {
  FBenviarMensajeWhatsapp,
  FBBienvenidaMensajeWhatsapp,
} from "@/components/Ventas/Tableros/whatsapp/conversar.js";

export default {
  props: ["datos", "celular", "correo", "indica"],
  data() {
    return {
      dialog: false,
      switchTiempoReal: false,
      items: [],
      mensaje: "",
      unsubscribe: null, // Variable reactiva para almacenar la función de desactivación
      usuarioW: null,
      plantillas: ["No disponible"],
      plantilla: null,
      audio: null,
    };
  },

  created() {
    this.inicializar();
  },

  methods: {
    abrirEnlace(enlace) {
      window.open(enlace, "_blank");
    },

    //Whatsapp
    playSound(sound) {
      if (sound) {
        this.audio = new Audio(sound);

        this.audio.addEventListener("loadedmetadata", () => {
          const duration = this.audio.duration; // Duración en segundos
          //console.log(`Duración del audio: ${duration} segundos`);
          const minutes = Math.floor(duration / 60);
          this.audio.play();
          //console.log(minutes);
        });

        this.audio.addEventListener("ended", () => {
          // El audio ha terminado de reproducirse
        });
      }
    },

    //Whatsapp
    stopSound() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    },

    colorChat(llega) {
      if (llega === undefined) {
        return "custom-backgroundInterno";
      } else {
        return "custom-background";
      }
    },

    async inicializar() {
      const plantillas = await FBtemplateWhatsapp();
      //console.log(plantillas.plantillas);
      this.plantillas = plantillas.plantillas;
    },

    fechaConvertirFirebase(timestamp) {
      if (!timestamp || !timestamp.seconds) {
        return "Fecha inválida";
      }

      const fecha = new Date(timestamp.seconds * 1000);
      const fechaHoraLegible = fecha.toLocaleString();

      return fechaHoraLegible;
    },

    async conversar() {
      // telefonoSelect, contacto, doliente, correoSelect
      const jsonSale = {
        telefonoSelect: this.celular,
        contacto: this.datos.contacto,
        doliente: this.datos.doliente,
        correoSelect: this.correo,
        mensaje: this.mensaje,
        template: this.plantilla,
        indicativo: this.indica,
      };

      if (this.plantilla === "personalizado") {
        const result = await FBenviarMensajeWhatsapp(jsonSale);
        if (result) {
          this.mensaje = "";
        } else {
          console.log("falló en mensaje Whatsapp");
        }
      } else {
        //console.log("Plantilla template..");
        const result = await FBBienvenidaMensajeWhatsapp(jsonSale);
        if (result) {
          this.mensaje = "";
        } else {
          console.log("falló en mensaje Whatsapp");
        }
      }
    },

    listenToMessages() {
      if (this.celular == null || this.celular == null || this.celular == "") {
        return;
      }

      if (this.switchTiempoReal) {
        //const phoneNumber = "57" + this.celular; // Número de teléfono a consultar
        const phoneNumber = this.indica + this.celular; // Número de teléfono a consultar
        //console.log(phoneNumber);
        const docRef = db.collection("cf-whatsapp").doc(phoneNumber);

        this.unsubscribe = docRef.onSnapshot((snapshot) => {
          if (snapshot.exists) {
            const data = snapshot.data();
            if (data && data.messages) {
              this.items = data.messages;
              this.usuarioW = data.usuario;
            } else {
              this.items = [];
            }
          } else {
            this.items = [];
          }
        });
      } else {
        this.stopListening();
      }

      // Después de agregar nuevo contenido al div de desplazamiento
      this.scrollToBottom();
    },

    stopListening() {
      if (this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    },

    reiniciar() {
      this.dialog = false;
      this.stopListening();
      this.switchTiempoReal = false;
      this.items = [];
      this.mensaje = "";
    },

    //Va al final de DIV de chat
    scrollToBottom() {
      const scrollContainer = this.$refs.scrollContainer;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    },

    async copiarTexto(textoLlega) {
      if (!navigator.clipboard) {
        console.error(
          "La API navigator.clipboard no es compatible en este navegador."
        );
        return;
      }

      let textoCopiado = textoLlega;

      try {
        await navigator.clipboard.writeText(textoCopiado);
        //console.log("Texto copiado al portapapeles: ", textoCopiado);
      } catch (error) {
        //console.error("Error al copiar el texto al portapapeles:", error);
      }
    },
  },

  beforeDestroy() {
    this.stopListening();
  },
};
</script>

<style scoped>
.message-scroll {
  max-height: 300px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto;
}
.custom-background {
  background-color: #fff7a3; /* Reemplaza con el color de fondo deseado */
  border-radius: 10px; /* Ajusta el valor para redondear las puntas */
  padding: 10px; /* Ajusta el valor del relleno según tus necesidades */
  color: rgb(58, 58, 58);
}

.custom-backgroundInterno {
  background-color: #a1ffa5; /* Reemplaza con el color de fondo deseado */
  border-radius: 10px; /* Ajusta el valor para redondear las puntas */
  padding: 10px; /* Ajusta el valor del relleno según tus necesidades */
  color: rgb(0, 58, 0);
}
</style>