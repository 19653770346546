<template>
  <div justify="center">
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="60%"
      scrollable
    >
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Crear Cartera</span>
        </v-tooltip>
      </template>

      <v-card dark>
        <v-card-title> Crear Cartera </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="valueCliente"
                    :items="items"
                    item-text="nombre"
                    item-value="nit"
                    dense
                    filled
                    label="Selecciones Cliente"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="carteraNombre"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="Nombre Cartera"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" md="6">
                  <v-switch
                    v-model="switch1"
                    :label="`Estado: ${mustraTexto(switch1)}`"
                    @change="mustraTexto(switch1)"
                  ></v-switch>
                  <!--
                  <p>{{ tipoServicio }}</p>-->
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <span>Resultado: {{ resultado }}</span>
          <v-spacer></v-spacer>
          <v-btn color="amber" text @click="dialog = false"> Cerrar </v-btn>

          <v-btn
            :disabled="autoUpdate"
            :loading="isUpdating"
            color="amber"
            depressed
            @click="fCreaCartera()"
          >
            Crear
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  FBListadoClientes,
  FBcreaCartera,
} from "../../../components/Cartera/verClientes";

export default {
  name: "creaCartera",
  props: [],
  components: {},
  data: () => ({
    dialog: false,
    autoUpdate: false,
    isUpdating: false,
    items: [{ nombre: "Esperando datos...", nit: 0 }],
    valueCliente: null,
    carteraNombre: "",
    switch1: true,
    resultado: "...",
  }),
  mounted() {
    const registros = async () => {
      //console.log("Entra a buscar");
      const result = await FBListadoClientes();
      //.log(result);
      this.items = result;
    };
    registros();
  },
  methods: {
    async fCreaCartera() {
      this.isUpdating = true; //

      const result = await FBcreaCartera(
        this.valueCliente,
        this.carteraNombre,
        this.switch1
      );

      if (result) {
        this.isUpdating = false;
        this.resultado = "Se ha creado con éxito.";
      } else {
        this.isUpdating = false;
        this.resultado = "No se ha creado, intente de nuevo.";
      }
    },
    mustraTexto(switch1) {
      if (switch1) {
        //this.estadoSw = "Activo";
        return "Activo";
      } else {
        //this.estadoSw = "Inactivo";
        return "Inactivo";
      }
    },
  },
  computed: {
    retornaEstadoMensaje() {
      //return (this.sheet = this.estadoMensaje);
    },
  },
};
</script>