<template>
  <div justify="center">
    <v-dialog v-model="dialog" scrollable max-width="80%" persistent>
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
              <v-icon>mdi-database</v-icon>
            </v-btn>
          </template>
          <span>Masivos</span>
        </v-tooltip>
      </template>

      <v-card class="mx-auto">
        <v-toolbar color="primary" dark>Modificaciones Masivas</v-toolbar>

        <v-card-text class="mt-5">
          <p>
            El objetivo es modificar las bolsas de los ejectuvos de manera
            masiva con respecto a las carteras activas.
          </p>

          <v-container fluid>
            <v-row align="center">
              <v-col cols="12" sm="6">
                <v-subheader v-text="'Cartera(s) a analizar...'"></v-subheader>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="e6"
                  :items="itemsCliente"
                  item-text="razonSocial"
                  item-value="nit"
                  attach
                  chips
                  label="Seleccione"
                  multiple
                  :menu-props="{ maxHeight: '400' }"
                  hint="Carteras disponibles"
                  persistent-hint
                >
                  <template #selection="{ item }">
                    <v-chip color="pink lighten-3">{{
                      item.razonSocial
                    }}</v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-subheader
                  v-text="'Seleccionar las tipificaciones'"
                ></v-subheader>
              </v-col>

              <v-col cols="12" sm="6">
                <v-select
                  v-model="e7"
                  :items="itemsTipi"
                  label="Selección multiple"
                  multiple
                  hint="Tipificaciones disponibles"
                  persistent-hint
                >
                  <template #selection="{ item }">
                    <v-chip color="lime lighten-2">{{ item }}</v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <!-- Boton de análisis-->
            <v-row class="pa-3">
              <v-col>
                <v-btn block elevation="2" @click="prevAnalisis">
                  Iniciar Pre Análisis
                </v-btn>
              </v-col>
            </v-row>
            <!-- Paneles de resultados -->
            <v-row>
              <!-- Panel Azul-->
              <v-col cols="6" sm="6">
                <v-card
                  elevation="2"
                  width="100%"
                  height="150"
                  class="overflow-auto pa-3"
                  color="blue lighten-1"
                >
                  <v-card-text>
                    <div>
                      1. Paso (Analizar) {{ procesoAnalizar }}
                      <v-btn @click="limpiar" fab dark small>
                        <v-icon dark> mdi-update </v-icon>
                      </v-btn>
                    </div>

                    <div class="text--primary mt-5">
                      Cartera procesada(s): {{ carteraProcesada }} <br />
                      Total registros encontrados:
                      {{ totalRegistroEncontrados }} <br />
                      Ejecutivos afectados: n/a <br />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Paneles Naranja -->
              <v-col cols="6" sm="6">
                <v-card
                  elevation="2"
                  width="100%"
                  height="150"
                  class="overflow-auto pa-3"
                  color="amber"
                >
                  <v-card-text>
                    <div>2. Paso (Procesar)</div>
                    <div class="text--primary mt-5">
                      Estado del proceso: {{ mensajeEliminar }} <br />
                      <br />

                      <v-btn
                        color="amber darken-4"
                        @click="eliminarTitulares"
                        dark
                      >
                        Eliminar Titulares de las bolsas...
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
          <!--
          <v-btn color="blue darken-1" text @click="dialog = false">
            Save
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FBverClientes } from "../../../components/Cartera/verClientes";
import {
  FBtotalClientes,
  FBeliminarRegistrosBolsaEjecutivo,
} from "../../../components/Cartera/procesosMasivos";
import loadCartera from "../../../components/loadFile/carteras/cartera.vue";

export default {
  name: "masivosa",
  props: [],
  components: {
    loadCartera,
  },
  data: () => ({
    dialog: false,
    e6: [], //Nits Seleccionados
    e7: [], //Tipificaciones seleccionadas
    itemsCliente: [],
    valueCliente: [],
    itemsTipi: [
      "Ilocalizado",
      "Fallecido",
      "Incobrable",
      "Paz y Salvo - Notificado",
      "Paz y Salvo",
    ],
    valueTipi: [],
    carteraProcesada: 0,
    totalRegistroEncontrados: 0,
    ejecutivos: 0,
    procesoAnalizar: "Iniciar",
    resultadoPreanalisis: [],
    mensajeEliminar: "Iniciar",
  }),
  mounted() {
    this.cargaDatos();
  },
  methods: {
    async cargaDatos() {
      //console.log("Si buenas.");
      const result = await FBverClientes();
      //console.log(result);
      if (result.length > 0) {
        result.forEach((element) => {
          this.itemsCliente.push({
            razonSocial: element.info.data.cliente,
            nit: element.info.data.nit,
          });
          //this.valueCliente.push(element.info.data.nit);
        });
      } else {
      }
    },
    async prevAnalisis() {
      //console.log("Consultando Clientes...");

      this.procesoAnalizar = "Iniciando análisis, espere por favor.";

      const consultClientes = await FBtotalClientes(this.e6, this.e7);
      //console.log(consultClientes);

      if (consultClientes.length > 0) {
        this.carteraProcesada = this.e6.length;
        this.totalRegistroEncontrados = consultClientes.length;
        this.ejecutivos = 0;
        this.resultadoPreanalisis = consultClientes;
      } else {
      }

      this.procesoAnalizar = "Ha terminado el proceso.";

      /*console.log("Consultando carteras...");
      const consultCarteras = await totalClientes(resultPreconsulta);*/
    },
    async eliminarTitulares() {
      this.mensajeEliminar =
        "Eliminando los titulares de las bolsas de los ejecutivos.";

      const resultEliminar = await FBeliminarRegistrosBolsaEjecutivo(
        this.resultadoPreanalisis
      );

      //console.log(resultEliminar);
      if (resultEliminar) {
        this.mensajeEliminar = "Se han eliminado con éxito.";

        //Reiniciar variables
        this.carteraProcesada = 0;
        this.totalRegistroEncontrados = 0;
        this.ejecutivos = 0;
        this.resultadoPreanalisis = "Iniciar";
      } else {
        this.mensajeEliminar = "Error al eliminar.";
      }
    },
    limpiar() {

      this.e6 = [];
      this.e7 = [];
      this.carteraProcesada = 0;
      this.totalRegistroEncontrados = 0;
      this.ejecutivos = 0;
      this.resultadoPreanalisis = "Iniciar";
      this.procesoAnalizar = "Iniciar";
    },
  },
  computed: {},
  watch: {},
};
</script>