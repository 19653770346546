import { db } from "@/firebase/config.js";

export async function FBconsultarEtapasProspectos() {
  try {
    const collectionPath = "recursos";
    const documentId = "etapasProspectos";

    const docRef = db.collection(collectionPath).doc(documentId);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data();
      const etapas = Object.values(data).sort((a, b) => a.orden - b.orden);

      //console.log(etapas);
      return etapas;
    } else {
      console.log("El documento no existe");
    }
  } catch (error) {
    console.error("Error al consultar las etapas de prospectos:", error);
  }
}

export async function FBconsultarTiempos() {
  try {
    const collectionPath = "recursos";
    const documentId = "prioridad";

    const docRef = db.collection(collectionPath).doc(documentId);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data();
      const etapas = Object.values(data).sort((a, b) => a.orden - b.orden);

      //console.log(etapas);
      return etapas;
    } else {
      console.log("El documento no existe");
    }
  } catch (error) {
    console.error("Error al consultar los tiempos de prospectos:", error);
  }
}

export async function FBconsultarProyectos() {
  try {
    const collectionPath = "cf-planeacion";
    const documentId = "proyectos";
    const subCollectionPath = "listado";

    const docRef = db.collection(collectionPath).doc(documentId).collection(subCollectionPath);
    const snapshot = await docRef.where("estado", "==", true).get();

    const proyectos = [];

    snapshot.forEach((doc) => {
      proyectos.push(doc.data());
    });

    //console.log(proyectos);
    return proyectos;
  } catch (error) {
    console.error("Error al consultar los proyectos:", error);
  }
}

export async function FBconsultaObjetivos() {
  try {
    const collectionPath = "cf-planeacion";
    const documentId = "objetivos";
    const subCollectionPath = "listado";

    const docRef = db.collection(collectionPath).doc(documentId).collection(subCollectionPath);
    const snapshot = await docRef.where("estado", "==", true).get();

    const objetivos = [];

    snapshot.forEach((doc) => {
      objetivos.push(doc.data());
    });

    //console.log(objetivos);
    return objetivos;
  } catch (error) {
    console.error("Error al consultar los objetivos:", error);
  }
}

export async function FBconsultaPaises() {
  try {
    const collectionPath = "recursos";
    const documentId = "paises";

    const docRef = db.collection(collectionPath).doc(documentId);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data();

      //console.log(data);
      return data;
    } else {
      console.log("El documento no existe");
    }
  } catch (error) {
    console.error("Error al consultar los Paises de prospectos:", error);
  }
}

export async function FBconsultaEmpleados() {
  try {
    const collectionPath = "cf-recursoHumano";
    const documentId = "RecursoHumano";
    const subCollectionPath = "Empleados";

    const querySnapshot = await db
      .collection(collectionPath)
      .doc(documentId)
      .collection(subCollectionPath)
      .where("data.estado", "==", true)
      .get();

    const empleados = [];

    const cargosColaboradores = await FBconsultaCargosColaboradores(); // Obtener los cargos y colaboradores

    querySnapshot.forEach((doc) => {
      const empleadoData = doc.data().data;
      const cargoAbreviatura = empleadoData.cargo;

      // Buscar la abreviatura del cargo en el objeto cargosColaboradores
      const cargoLegible = Object.entries(cargosColaboradores).find(([cargo, abreviatura]) => abreviatura === cargoAbreviatura)?.[0] || empleadoData.cargo;

      const empleado = {
        nombre: empleadoData.empleado,
        nombreCargo: `${empleadoData.empleado} - ${cargoLegible}`,
      };

      empleados.push(empleado);
    });

    return empleados;
  } catch (error) {
    console.error("Error al consultar los empleados:", error);
  }
}

export async function FBconsultaCargosColaboradores() {
  try {
    const collectionPath = "recursos";
    const documentId = "cargos";

    const docRef = db.collection(collectionPath).doc(documentId);
    const doc = await docRef.get();

    if (doc.exists) {
      const datos = doc.data().datos;

      // Convertir el objeto de datos a un objeto de mapeo de abreviaturas a cargos legibles
      const cargosColaboradores = Object.entries(datos).reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

      return cargosColaboradores;
    } else {
      console.log("El documento no existe");
    }
  } catch (error) {
    console.error("Error al consultar los cargos y colaboradores:", error);
  }
}

export async function FBconsultarDepartamentos() {
  try {
    const collectionPath = "recursos";
    const documentId = "departamentos";

    const docRef = db.collection(collectionPath).doc(documentId);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data().datos;
      

      //console.log(data);
      return data;
    } else {
      console.log("El documento no existe");
    }
  } catch (error) {
    console.error("Error al consultar las etapas de prospectos:", error);
  }
}

export async function FBconsultarModulos() {
  try {
    const collectionPath = "recursos";
    const documentId = "modulos";

    const docRef = db.collection(collectionPath).doc(documentId);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data().cards;
      
      //console.log(data);
      return data;
    } else {
      console.log("El documento no existe");
    }
  } catch (error) {
    console.error("Error al consultar las etapas de prospectos:", error);
  }
}

export async function FBconsultarTipificaciones() {
  try {
    const collectionPath = "recursos";
    const documentId = "tipificacionesVenta";

    const docRef = db.collection(collectionPath).doc(documentId);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data().tipificaciones;
      

      //console.log(data);
      return data;
    } else {
      console.log("El documento no existe");
    }
  } catch (error) {
    console.error("Error al consultar las tipificaciones:", error);
  }
}

export async function FBconsultarEstados() {
  try {
    const collectionPath = "recursos";
    const documentId = "estados";

    const docRef = db.collection(collectionPath).doc(documentId);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data().estado;
      

      //console.log(data);
      return data;
    } else {
      console.log("El documento no existe");
    }
  } catch (error) {
    console.error("Error al consultar las tipificaciones:", error);
  }
}

export async function FBconsultaEstadosTareas() {
  try {
  
    const collectionPath = "recursos";
    const documentId = "estadosTareas";

    const docRef = db.collection(collectionPath).doc(documentId);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data().estados;
      
      //console.log(data);
      return data;
    } else {
      console.log("El documento no existe");
    }
  } catch (error) {
    console.error("Error al consultar las tipificaciones:", error);
  }
}