<template>
  <v-container class="grey lighten-5">
    <v-card class="mx-auto my-12" max-width="100%">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-img
        max-height="100"
        contain
        class="white"
        src="https://firebasestorage.googleapis.com/v0/b/bybabogados-138f2.appspot.com/o/st-catalogo%2Flogo-tienda-beraca-bbg.jpg?alt=media&token=c1c5a9cb-e59b-478f-99dd-e59dbab07ebb"
      ></v-img>

      <v-card-title>Seguimiento del Pedido</v-card-title>
      <!-- CONTENIDO -->
      <v-card-text v-if="pedidos.length > 0">
        <v-row class="p-3">
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <span class="font-weight-black">Nombre: </span>
            {{ pedidos[0].contacto }}
          </v-col>
          <!--
            <v-col cols="12" lg="6" md="6" sm="12" xs="12"
              ><span class="font-weight-black">Movil: </span>
              {{ pedidos[0].whatsappContacto[0]  }}
            </v-col>
          -->
          <v-col cols="12" lg="6" md="6" sm="12" xs="12"
            ><span class="font-weight-black">Estado del Pedido: </span>
            {{ pedidos[0].estado }}
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12"
            ><span class="font-weight-black">Fecha Registro: </span>
            {{ convertirTimestampAFecha(pedidos[0].fechaRegistro) }}
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12"
            ><span class="font-weight-black">Proyecto: </span>
            {{ pedidos[0].proyecto }}
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12">
            <span class="font-weight-black">Tipificación: </span>
            {{ pedidos[0].tipificacionGlobal }}
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12"
            ><span class="font-weight-black">Pago del Pedido: </span>
            {{ pedidos[0].pagoCliente }}
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12"
            ><span class="font-weight-black">Dirección de entrega: </span>
            {{ pedidos[0].dirEntrega }}
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12"
            ><span class="font-weight-black">Observaciones: </span>
            {{ pedidos[0].observaciones }}
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12" xs="12"
            ><span class="font-weight-black">Total: </span>
            {{ convertirANumeroDeMoneda(pedidos[0].total) }}
          </v-col>

          <v-col cols="12">
            <v-list three-line>
              <template v-for="(item, index) in pedidos[0].pedido">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item v-else :key="item.title">
                  <v-avatar tile>
                    <v-img :src="item.url"></v-img>
                  </v-avatar>

                  <v-list-item-content class="ml-3">
                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                    <v-list-item-subtitle
                      >SKU: {{ item.sku }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Cantidad: {{ item.cantidad }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >Variaciones: {{ formattedVariations(item.variaciones) }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else>
        Por favor, pongase en contacto al Whatsapp para verificar su código de
        pedido, gracias.
      </v-card-text>
    </v-card>

    <!-- TERMINOS Y CONDICIONES POR DESARROLLAR -->
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="pa-2 text-justify" outlined tile>
          <p>
            Estimado cliente, Si deseas consultar el estado de tu pedido o
            tienes alguna pregunta o solicitud relacionada con él, puedes
            hacerlo aquí. Estamos aquí para ayudarte en todo momento. Si tienes
            alguna inquietud, necesitas realizar algún cambio o simplemente
            deseas hablar con nosotros, no dudes en contactarnos a través de
            WhatsApp al número +57 315 450 50 01 o envíanos un correo
            electrónico a notificaciones@beracabg.com. Estamos comprometidos en
            brindarte la mejor experiencia posible.
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "@/firebase/config";

export default {
  props: {
    codigoSeguimiento: {
      type: String,
      required: true, // Hace que el prop sea obligatorio
    },
  },
  data: () => ({
    items: [
      { header: "Today" },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Brunch this weekend?",
        subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Oui oui",
        subtitle:
          '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Birthday gift",
        subtitle:
          '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Recipe to try",
        subtitle:
          '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
    ],
    pedidos: [],
    unsubscribe: null,
  }),

  created() {
    // Verificar si el código de seguimiento coincide antes de iniciar la escucha
    if (this.codigoSeguimiento !== null) {
      this.iniciarEscuchaPedidos();
    } else {
      // Manejar el caso en el que el código no coincide
      //console.error("Código de seguimiento incorrecto");
      // Puedes redirigir al usuario o mostrar un mensaje de error, según tu necesidad
    }
  },

  methods: {
    iniciarEscuchaPedidos() {
      //console.log(this.codigoSeguimiento);
      this.unsubscribe = db
        .collection("cf-pedidos")
        .where("codigoSeguimiento", "==", this.codigoSeguimiento)
        .where("estado", "==", true)
        .onSnapshot((snapshot) => {
          this.pedidos = [];
          snapshot.forEach((doc) => {
            this.pedidos.push({ id: doc.id, ...doc.data() });
          });
        });
    },

    convertirTimestampAFecha(timestamp) {
      const milisegundos =
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
      const fecha = new Date(milisegundos);

      // Obtiene las partes de la fecha (día, mes y año)
      const dia = fecha.getDate();
      const mes = fecha.getMonth() + 1; // Los meses se indexan desde 0, así que sumamos 1
      const año = fecha.getFullYear();

      // Formatea la fecha en el formato deseado (DD/MM/YYYY)
      const fechaFormateada = `${dia}/${mes}/${año}`;

      return fechaFormateada;
    },

    convertirANumeroDeMoneda(numero) {
      return numero.toLocaleString("es-CO", {
        style: "currency",
        currency: "COP",
      });
    },

    formattedVariations(variations) {
      // Verifica si es un objeto y si tiene al menos una propiedad
      if (variations && typeof variations === 'object' && Object.keys(variations).length > 0) {
        // Convierte el objeto a una cadena de texto formateada
        return Object.entries(variations)
          .map(([key, value]) => `${key}: ${value}`)
          .join(', ');
      } else {
        return 'Sin variaciones';
      }
    },

  },

  beforeDestroy() {
    // Detener la escucha cuando el componente se destruye para evitar fugas de memoria
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>