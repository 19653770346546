<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="orange lighten-2" text v-bind="attrs" v-on="on">
          Editar
        </v-btn>
      </template>

      <!-- INICIA TARJETA -->
      <v-card>
        <v-card-title class="text-h5 amber bg-black">
          Edita un Producto
        </v-card-title>

        <v-card-text v-if="!estadoProceso">
          <v-row class="mb-6 mt-3">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-n10">
              <!-- v-model="product.nombre" -->
              <v-autocomplete
                v-model="values"
                :items="items"
                outlined
                dense
                chips
                small-chips
                label="SKU Producto"
                multiple
                color="black"
                @input="consultar"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <!-- FORMULARIO AGREGA PRODUCTO -->
              <v-form ref="form" v-model="valid" @submit.prevent="submit">
                <v-row class="mb-6 mt-3">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                    <!-- Nombre Producto -->
                    <v-text-field
                      color="black"
                      v-model="product.nombre"
                      label="Nombre Producto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                    <!-- Precio Base -->
                    <v-text-field
                      color="black"
                      v-model="product.base_price"
                      label="Precio Base"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    class="mb-n10"
                  >
                    <!-- Descripción -->
                    <v-text-field
                      color="black"
                      v-model="product.descripcion"
                      label="Descripción Producto"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                    <!-- Categoria Principal -->
                    <v-select
                      color="black"
                      :items="categorias"
                      v-model="product.categoria"
                      label="Categoría"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                    <!-- Sub Categoria -->
                    <v-select
                      color="black"
                      :items="subCategorias"
                      v-model="product.subCategoria"
                      label="Sub Categoría"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                    <!-- Fabricante -->
                    <v-select
                      color="black"
                      :items="fabricantes"
                      v-model="product.fabricante"
                      label="Fabricante"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                    <!-- Estado Producto  -->
                    <v-select
                      color="black"
                      :items="estado"
                      v-model="product.estado"
                      item-text="etiqueta"
                      item-value="valor"
                      label="Estado"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                    <!-- Alt Imagen -->
                    <v-text-field
                      color="black"
                      v-model="product.imageAlt"
                      label="Alt Imagen"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                    <!-- SKU Producto -->
                    <v-text-field
                      color="black"
                      v-model="product.sku"
                      label="SKU"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                    <!-- stock Producto -->
                    <v-text-field
                      color="black"
                      v-model="product.stock"
                      label="Stock"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- ... añade los otros campos de texto aquí ... -->

                <!-- Puedes usar v-file-input para subir imágenes si es necesario -->
                <v-file-input
                  multiple
                  color="black"
                  v-model="product.imageFile"
                  label="Imagen"
                  placeholder="Sube las imágenes.."
                  prepend-icon="mdi-paperclip"
                  @change="previewFiles"
                  counter
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="amber">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>

                <!-- Aquí mostramos las imágenes en miniatura -->
                <div
                  v-for="(url, index) in imagePreviews"
                  :key="url.img"
                  class="d-flex align-center"
                >
                  <v-badge :content="index + 1" color="black" overlap>
                  </v-badge>
                  <v-img
                    :src="url.img"
                    max-height="120"
                    max-width="120"
                    class="rounded-xl ml-3"
                  ></v-img>

                  <span class="ml-2">{{ url.name }}</span>
                  <!-- añadido un margen a la izquierda para dar espacio entre la imagen y el texto -->
                </div>

                <v-divider class="mt-3"></v-divider>

                <div
                  class="d-flex justify-center align-center"
                  style="height: 5vh"
                >
                  <v-btn
                    color="black"
                    block
                    text
                    outlined
                    @click="submit"
                    class="mt-5"
                    >Añadir Producto</v-btn
                  >
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-else>
          {{ proceso }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions style="background-color: black">
          <v-spacer></v-spacer>
          <v-btn color="amber" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
      
   
  
<script>
import {
  subirImageFB,
  subirRegistroFB,
  consultaProductoFB,
} from "@/components/Mercadeo/Catalogo/crear.js";

export default {
  data() {
    return {
      items: ["foo", "bar", "fizz", "buzz"],//Autocomple
      values: "",//Autocomple
      value: null,//Autocomple
      dialog: false,
      valid: true,
      product: {
        nombre: "",
        descripcion: "",
        base_price: null,
        categoria: "",
        fabricante: "",
        imageFile: null,
      },
      imagePreviews: [],
      categorias: ["Editorial Rehobot" /*...otros valores...*/],
      fabricantes: ["Rehobot" /*...otros valores...*/],
      subCategorias: ["Educación" /*...otros valores...*/],
      estado: [
        { etiqueta: "Activo", valor: true },
        { etiqueta: "No Disponible", valor: false },
      ],
      proceso: "",
      estadoProceso: false,
    };
  },
  methods: {

    async consultar(){
      console.log(this.values);
      const resultConsulta = await consultaProductoFB(this.values);
      console.log(resultConsulta);
    },

    previewFiles() {
      this.imagePreviews = [];
      for (let file of this.product.imageFile) {
        //console.log(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.imagePreviews.push({ img: e.target.result, name: file.name });
        };
      }
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.estadoProceso = true;

      this.proceso = "Procesando Solicitud";

      const resultImg = await subirImageFB(this.product);

      if (resultImg[0]) {
        this.proceso =
          "Se han guardado las imágene son éxito. Procesando Producto...";
        this.product.imagen = resultImg[1];

        setTimeout(async () => {
          //Ahora subir datos
          const resultDatos = await subirRegistroFB(this.product);

          if (resultDatos[0]) {
            //console.log(resultDatos[1]);
            this.proceso = "Se el Producto, de manera exitosa.";

            setTimeout(() => {
              this.proceso = "";
              this.estadoProceso = false;
              this.reiniciarFormulario();
            }, 3000);
          } else {
            //console.log(resultDatos[1]);
            this.proceso = "Error al crear el producto.";

            setTimeout(() => {
              this.proceso = "";
              this.estadoProceso = false;
              this.reiniciarFormulario();
            }, 3000);
          }
        }, 3000); // 3000 milisegundos equivalen a 3 segundos
      } else {
        this.proceso = "Error guardar las imágenes.";

        setTimeout(() => {
          this.proceso = "";
          this.estadoProceso = false;
          this.reiniciarFormulario();
        }, 3000);
      }
    },

    reiniciarFormulario() {
      (this.product = {
        nombre: "",
        descripcion: "",
        base_price: null,
        categoria: "",
        fabricante: "",
        imageFile: null,
      }),
        (this.imagePreviews = []);
    },
  },
};
</script>
  