<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent max-width="950" dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="green darken-4"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 amber">
          Oportunidad |
          <div class="overline">Persona Natural</div>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2">
            <v-col>
              En caso de ser una persona natural, puedes crear una oportunidad
              para hacer seguimiento.
            </v-col>
          </v-row>
          <v-container>
            <v-row class="mb-6 mt-3">
              <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="mb-n10">
                <v-text-field
                  label="Cliente"
                  placeholder="Escribe aquí..."
                  outlined
                  color="orange"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="mb-n10">
                <v-text-field
                  label="Correo Electrónico"
                  placeholder="Escribe aquí..."
                  outlined
                  color="orange"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="mb-n10">
                <v-text-field
                  v-model="telegram"
                  label="Telegram"
                  placeholder="Escribe aquí... @Usuario"
                  outlined
                  color="blue"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <v-autocomplete
                  v-model="productosSeleccionados"
                  :items="productos"
                  :item-text="
                    (item) =>
                      `${item.producto} - ${item.inventario} en inventario`
                  "
                  outlined
                  chips
                  small-chips
                  label="Producto(s)"
                  multiple
                  color="orange"
                  :menu-props="{ class: 'custom-menu-color' }"
                >
                  <!--
<template v-slot:item="{ item }">
                    {{ item.producto }}
                  </template>
                -->
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <v-select
                  :items="tipificacionesVenta"
                  label="Tipificación"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="mt-n8">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- FECHA PROXIMO CONTACTO -->
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      color="orange"
                      v-model="date"
                      label="Fecha Proximo Contacto"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="orange" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      outlined
                      color="orange"
                      @click="$refs.menu.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="mb-n10 custom-select-container"
              >
                <v-btn
                  class="custom-button mt-8"
                  color="orange"
                  fab
                  x-small
                  dark
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <!-- TELEFONOS CONTACTO -->
                <v-select
                  :items="telefonos"
                  label="Telefonos Contacto"
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>

            <v-divider class="mt-8" color="orange"></v-divider>
            <!-- TIPO DE VENTA -->
            <v-row class="mt-2">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Tipo de Venta
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="mt-2">
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n10"
                      >
                        <v-select
                          :items="tipoVenta"
                          label="Tipo de Venta"
                          outlined
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n10"
                      >
                        <v-select
                          :items="totalCutoas"
                          label="Total Cuotas"
                          outlined
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n10"
                      >
                        <v-menu
                          ref="menuPP"
                          v-model="menuPP"
                          :close-on-content-click="false"
                          :return-value.sync="datePrimerPago"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              color="orange"
                              v-model="datePrimerPago"
                              label="Fecha Pago"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="datePrimerPago"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              outlined
                              color="orange"
                              @click="menuPP = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              outlined
                              color="orange"
                              @click="$refs.menuPP.save(datePrimerPago)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n8"
                      >
                        <v-text-field
                          v-model="precioVenta"
                          color="orange"
                          label="Precio Venta"
                          placeholder="Sin decimales"
                          outlined
                          disabled
                          filled
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n8"
                      >
                        <v-text-field
                          v-model="direccionEntrega"
                          color="orange"
                          label="Dirección Entrega"
                          placeholder="..."
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n8"
                      >
                        <v-text-field
                          v-model="gps"
                          color="orange"
                          label="Ubicación GPS"
                          placeholder="..."
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n8"
                      >
                        <v-text-field
                          v-model="descuento"
                          color="orange"
                          label="Descuento en %"
                          placeholder="..."
                          outlined
                          filled
                          :disabled="precioVenta === null"
                          @change="calculaDescuento"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              
            </v-row>

           
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" outlined @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  
  data() {
    return {
      dialog: false,
      telegram: "@",
      productos: [
        { producto: "Lectura Rápida", inventario: 100, precio: 550000 },
        { producto: "Daira English", inventario: 100, precio: 45000 },
        { producto: "Kit Bebé", inventario: 100, precio: 45000 },
      ],
      productosSeleccionados: [],
      tipificacionesVenta: [],
      telefonos: [],
      tipoVenta: ["Crédito", "Contado"],
      totalCutoas: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
      precioVenta: 0,
      cantidad: 0,
      direccionEntrega: "",
      gps: null,
      precioConDescuento: 0,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      descuento: 0,

      datePrimerPago: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menuPP: false,
      //modalPP: false,
      //menu2: false,

     
    };
  },

  methods: {
    calculaDescuento() {
      const precioNumerico = Number(this.precioVenta.replace(/[^0-9.-]+/g, ""));
      const precioVentaNumerico = parseFloat(precioNumerico);
      const descuentoNumerico = parseFloat(this.descuento) / 100; // Convertir el porcentaje a decimal

      if (!isNaN(precioVentaNumerico) && !isNaN(descuentoNumerico)) {
        // Realiza el cálculo aquí con precioVentaNumerico y descuentoNumerico
        const descuentoCalculado = precioVentaNumerico * descuentoNumerico;
        this.precioConDescuento = precioVentaNumerico - descuentoCalculado;
        console.log("Precio con descuento:", this.precioConDescuento);
        // Formateamos el precio con descuento en formato de precio con decimales
        this.formatoPrecio();
      } else {
        console.log("Los valores no son numéricos o no son válidos.");
      }
    },
    formatoPrecio() {
      // Formateamos el precio con descuento en formato de precio con decimales y separadores de miles
      this.precioVenta = this.precioConDescuento.toLocaleString("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    },
   
  },

  watch: {
    productosSeleccionados(newValue) {
      let totalPrecioVenta = 0;

      // Recorremos todas las posiciones de nombreProducto
      newValue.forEach((nombreProductoSeleccionado) => {
        // Utiliza el método split() para separar el string en un array por el carácter "-"
        const parts = nombreProductoSeleccionado.split("-");
        // Obtén la primera parte del array resultante, que corresponde al nombre del producto
        const nombreProducto = parts[0].trim();

        // Buscamos el objeto completo del producto en el array 'productos' por el nombre del producto
        const productoSeleccionado = this.productos.find(
          (producto) => producto.producto === nombreProducto
        );

        // Verificamos si se encontró el producto y sumamos su precio al total
        if (productoSeleccionado) {
          // Convertir el precio a número antes de sumarlo
          totalPrecioVenta += parseFloat(productoSeleccionado.precio);
        }
      });

      // Asignamos el total al campo precioVenta
      this.precioVenta = totalPrecioVenta.toString();
      this.calculaDescuento(); // Volvemos a calcular el descuento para actualizar el campo precioVenta con el descuento aplicado
    },
  },
};
</script>

<style scoped>
.custom-menu-color {
  background-color: orange;
  color: white; /* Puedes cambiar el color del texto si lo deseas */
}

/* Agrega estilos de posición */
.custom-select-container {
  position: relative;
}

.custom-button {
  position: absolute;
  top: -10px; /* Ajusta este valor para cambiar la distancia desde arriba */
  left: -10px; /* Ajusta este valor para cambiar la distancia desde la izquierda */
}
</style>