<template>
  <div justify="center">
    <v-dialog v-model="dialog" scrollable max-width="50%" persistent>
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="recargaComponente">
              <v-icon>mdi-account-hard-hat</v-icon>
            </v-btn>
          </template>
          <span>Ver Empleado</span>
        </v-tooltip>
      </template>

      <v-card  class="mx-auto">
        <v-toolbar class="black" dark>
          {{ titulo }}
          <v-row>
            <v-col class="text-right" cols="12">
              <v-menu bottom left transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="cargaEmpleadosA">
                    <v-list-item-action>
                      <v-icon>mdi-update</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Actualizar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text class="mt-5">
          <LoadCartera ref="listadoEmpleados" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="black" text @click="cerrarComponente">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoadCartera from "./listadoEmpleados.vue";

export default {
  name: "empleados",
  props: ["titulo"],
  components: {
    LoadCartera,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    cerrarComponente() {
      this.dialog = false;
    },
    recargaComponente() {
      this.dialog = !this.dialog;
      this.cargaEmpleadosA();
    },
    cargaEmpleadosA() {
      if (this.$refs.listadoEmpleados) {
        this.$refs.listadoEmpleados.cargaEmpleados();
      }
    },
  },
};
</script>
