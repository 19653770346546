<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="50%" scrollable>
      <template v-slot:activator="{ on, attrs }">

        <v-btn color="balck"  v-bind="attrs" v-on="on" @click="openDialog"  fab x-small dark>
            <v-icon>mdi-account-group</v-icon>
          </v-btn>
          
      
      </template>

      <v-card>
        <v-toolbar color="black" dark>
          Directorio

          <!-- Boton de Actualizacion -->
          <v-row>
            <v-col class="text-right" cols="12">
              <v-menu bottom left transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="cargaEmpleados">
                    <v-list-item-action>
                      <v-icon>mdi-cog</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Actualizar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          
          <listadoEmpleados ref="listadoEmpleados" />

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="cerrarComponente">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import listadoEmpleados from "../RecursosHumanos/Empleados/listadoEmpleados.vue";

export default {
  name: "modalDirectorio",
  components: {
    listadoEmpleados,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    cerrarComponente() {
      this.dialog = false;
    },
    cargaEmpleados() {
      this.$refs.listadoEmpleados.cargaEmpleados();
    },
  },
};
</script>
