var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-file-input',{attrs:{"type":"file","accept":".csv","label":"Cargar Cartera .CSV"},on:{"change":_vm.onLoadFile}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"pa-2 orange lighten-2"},[_vm._v(" Monto Cartera "),_c('p',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.totalMonto))])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 blue lighten-5",staticStyle:{"color":"#004b85"},attrs:{"headers":_vm.headers,"items":_vm.carteraLoad,"items-per-page":20,"item-class":_vm.row_classes,"loading":_vm.loadinTable,"loading-text":"Cargando... Espere por favor.","hide-default-footer":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoad.length > 1
            ? 'pa-1 blue lighten-4'
            : 'pa-1 red lighten-4'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Total Titulares Finalizados "),_c('p',{staticClass:"pa-3 font-weight-black"},[_vm._v(_vm._s(_vm.carteraLoad.length))])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoad.length > 0)?_c('v-btn',{attrs:{"text":"","color":"blue-grey darken-1"},on:{"click":() => _vm.verPanel('Finalizados')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoadRep01.length > 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 blue lighten-5'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Repetidos procesados por cédula "),_c('p',{staticClass:"pa-3 font-weight-black text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.carteraLoadRep01.length)+" ")])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoadRep01.length > 0)?_c('v-btn',{attrs:{"text":"","color":"blue-grey darken-1"},on:{"click":() => _vm.verPanel('reCedula')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoadRep02.length >= 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 blue lighten-5'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Repetidos procesados por monto "),_c('p',{staticClass:"pa-3 font-weight-black text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.carteraLoadRep02.length)+" ")])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoadRep02.length > 0)?_c('v-btn',{attrs:{"text":"","color":"blue-grey darken-1"},on:{"click":() => _vm.verPanel('reMonto')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoadSinMonto.length > 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 blue lighten-5'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Total Titulares sin monto "),_c('p',{staticClass:"pa-3 font-weight-black"},[_vm._v(" "+_vm._s(_vm.carteraLoadSinMonto.length)+" ")])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoadSinMonto.length > 0)?_c('v-btn',{attrs:{"text":"","color":"blue-grey darken-1"},on:{"click":() => _vm.verPanel('reSinMonto')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoadSinCedula.length > 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 blue lighten-5'},[_c('v-card-text',{staticClass:"pb-0"},[_vm._v(" Total Titulares sin cédula "),_c('p',{staticClass:"pa-3 font-weight-black text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.carteraLoadSinCedula.length)+" ")])]),_c('v-card-actions',{staticClass:"pt-0"},[(_vm.carteraLoadSinCedula.length > 0)?_c('v-btn',{attrs:{"text":"","color":"blue-grey darken-1"},on:{"click":() => _vm.verPanel('reSinCedula')}},[_vm._v(" Detalles ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-card',{class:_vm.carteraLoad.length > 1
            ? 'pa-1 red lighten-4'
            : 'pa-1 blue lighten-5'},[_vm._v(" ------ "),_c('p',{staticClass:"pa-3 font-weight-black text-decoration-line-through"})])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-subheader',[_vm._v(" Seleccione el cliente al que desea cargar la cartera ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"hint":`${_vm.select.state}, ${_vm.select.abbr}`,"items":_vm.items,"item-text":"state","item-value":"abbr","label":"Despliegue...","persistent-hint":"","return-object":"","single-line":""},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1),_c('v-row',[_c('v-col',[(_vm.subirOk)?_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading,"color":"blue-grey","fab":""},on:{"click":function($event){return _vm.procesarBase()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cloud-upload ")])],1):_vm._e(),_c('v-overlay',{attrs:{"absolute":true,"value":_vm.avancesLoadCartera}}),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.avancesLoadCartera),expression:"avancesLoadCartera"}],attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1),(_vm.verTablaExportar)?_c('datatable',{attrs:{"title":'Panel: ' + _vm.nombrePanel,"columns":_vm.tableColumns1,"rows":_vm.comodinDatosTabla,"perPage":[5, 10, 20, 30, 50],"locale":"es"}}):_vm._e(),_c('p',[_vm._v("Trabajando con "+_vm._s(_vm.totalAsesores)+" Asesores")]),_c('p',[_vm._v("Solo se necesitan "+_vm._s(_vm.arrayFinalAsesores.length)+" Asesores")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersCarteras,"items":_vm.arrayFinalAsesores,"items-per-page":5},scopedSlots:_vm._u([{key:"items",fn:function(){return [_vm._v(" hola "+_vm._s(_vm.items.Cartera)+" ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }