<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" scrollable dark>
      <template v-slot:activator="{ on }">
        <v-tooltip color="green" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              outlined
              color="green"
              v-on="on"
              v-bind="attrs"
              @click="dialog = true"
              :disabled="estadoVerifica !== 'pendiente'"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title class="text-h5 amber"> Editar </v-card-title>

        <v-card-text class="mt-3">
          Edita tu Apoyo...
          <v-row class="mt-3">
            <v-col>
              <v-textarea
                color="orange"
                v-model="input"
                outlined
                name="input-7-4"
                label="Actualiza el requerimiento"
                :value="input"
                @change="actualizaDato"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" outlined text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["todosLosDatos", "totalApoyos", "observacion", "estado"],
  data() {
    return {
      dialog: false,
      input: "",
      estadoVerifica: null,
    };
  },

  created() {
    this.inicializar();
  },

  methods: {
    inicializar() {
      if (this.estado === undefined) {
        this.estadoVerifica = "pendiente";
      } else {
        this.estadoVerifica = this.estado;
      }

      this.input = this.observacion;
    },

    async actualizaDato() {
      console.log(this.input);

      this.$emit("DatoModificado", this.input, this.totalApoyos);
    },
  },
};
</script>