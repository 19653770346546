import { db, firebase, functions } from "@/firebase/config.js";
import { sendMensajeGeneral } from "@/Util/twilio/";
import moment from 'moment';

export async function FBcreaProspecto(jsonLlega) {
    try {
        //Convertir la fecha a formato firebase
        const dateString = jsonLlega.proxContacto;//"2024-02-21"
        const date = new Date(dateString + "T00:00:00");
        const timestamp = await convertirFechaATimestamp(date);

        const cliente = jsonLlega.cliente;
        const seguimientoId = jsonLlega.seguimientoId;
        const etapa = jsonLlega.etapa;
        const contacto = jsonLlega.contacto;
        const emailCliente = jsonLlega.correoContacto;
        const cotizacion = jsonLlega.cotizacion;
        const proxContacto = timestamp;
        const doliente = jsonLlega.doliente;

        const jsonDB = {
            cliente: cliente,
            contacto: contacto,
            correoContacto: emailCliente,
            dateProximoContacto: proxContacto,
            cotizacion: cotizacion,
            doliente: doliente,
            etapa: etapa,
        }

        const jsonMail = {
            cliente: cliente,
            contacto: contacto,
            correoContacto: emailCliente,
            dateProximoContacto: jsonLlega.proxContacto,
            cotizacion: cotizacion,
            doliente: doliente,
            etapa: etapa,
        }

        // Define la ruta donde deseas guardar el prospecto
        const collectionPathCreaProspecto = `cf-ventas/etapas/${etapa}`;

        // 1. Localizar el documento con seguimientoId igual a seguimientoId
        const prospectoQuerySnapshot = await db
            .collection(collectionPathCreaProspecto)
            .where('seguimientoId', '==', seguimientoId)
            .get();

        if (!prospectoQuerySnapshot.empty) {
            const prospectoDocRef = prospectoQuerySnapshot.docs[0].ref;
            const prospectoData = prospectoQuerySnapshot.docs[0].data();

            // 2. Verificar si la clave "cotizaciones" existe en el documento
            if (prospectoData.hasOwnProperty('cotizaciones')) {
                // La clave "cotizaciones" existe, agregar la cotización al final del array
                await prospectoDocRef.update({
                    cotizaciones: firebase.firestore.FieldValue.arrayUnion(cotizacion),
                    proxContacto: proxContacto,
                });
            } else {
                // La clave "cotizaciones" no existe, crearla con un nuevo array que contiene la cotización
                await prospectoDocRef.update({
                    cotizaciones: [cotizacion],
                    proxContacto: proxContacto,
                });
            }

            // Enviar un correo al usuario con la cotización
            const enviaEmailCotizacion = functions.httpsCallable("cotizacionBtoB");

            await enviaEmailCotizacion(jsonMail);

            return [true, "Se ha guardado con éxito"];
        }

        console.log(prospectoQuerySnapshot.docs[0].data());
    } catch (error) {
        console.log(error);
        return [false, "No se realizó el proceso."];
    }
}


async function convertirFechaATimestamp(fecha) {
    if (moment.isDate(fecha)) {
        // Si la fecha es de tipo Date, asumimos que ya es un timestamp
        return fecha;
    }

    const fechaObjetivo = moment(fecha, 'YYYY-MM-DD').toDate();
    const timestamp = firebase.firestore.Timestamp.fromDate(fechaObjetivo);
    return timestamp;
}