import { db } from "../../firebase/config";

export async function FBconsultarProcesos(etapa = "prospeccion") {
  try {
    const array = [];
    const collectionPath = "cf-ventas";
    const etapasDocument = "etapas";

    const docRef = db.collection(collectionPath).doc(etapasDocument).collection(etapa);

    const querySnapshot = await docRef.get();

    querySnapshot.forEach(docSnapshot => {
     
      if (!docSnapshot.exists || (docSnapshot.exists && docSnapshot.data().a !== "no borrar")) {
        //array.push({ idConsulta: docSnapshot.id, datos: docSnapshot.data() });
        array.push(Object.assign({ idConsulta: docSnapshot.id }, docSnapshot.data()));
      }
    });

    //console.log(array);
    return [true, array, null];

  } catch (error) {
    console.error("Error al consultar las etapas de prospectos:", error);
    return [false, [], null];
  }
}



/*export async function FBconsultarProcesos() {
  try {
    const array = [];
    const collectionPath = "cf-ventas";
    const etapasDocument = "etapas";
    const etapaCollections = ["negociacion", "prospeccion"];

    const docRef = db.collection(collectionPath).doc(etapasDocument);

    const docSnapshot = await docRef.get();

    if (docSnapshot.exists) {
      const data = docSnapshot.data();
      etapaCollections.forEach(async etapa => {
        if (data && data.hasOwnProperty(etapa) && Array.isArray(data[etapa])) {
          for (const docRef of data[etapa]) {
            const docSnapshot = await docRef.get();
            if (!docSnapshot.exists || (docSnapshot.exists && docSnapshot.data().a !== "no borrar")) {
              array.push({ idConsulta: docSnapshot.id, datos: docSnapshot.data() });
            }
          }
        }
      });
    }

    return [true, array, null];

  } catch (error) {
    console.error("Error al consultar las etapas de prospectos:", error);
    return [false, [], null];
  }
}*/












