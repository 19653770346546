<template>
  <div>
    <!-- BUSCADOR...  -->
    <v-row>
      <v-col cols="12">
        <Titular v-show="titular" :datosTitular="datosTitularBusqueda" />
      </v-col>
    </v-row>

    <!-- TABLA DE TRABAJO LISTADO DE TITULARES...  -->
    <v-row>
      <v-col cols="12">
        <v-card color="blue darken-4 rounded-xl">
          <v-card-title>
            <v-text-field
              class="blue darken-4"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              filled
              rounded
              dense
              dark
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.tipiUlti`]="{ item }">
              <v-chip :color="getColortipiUlti(item.tipiUlti)" dark>
                {{ item.tipiUlti }}
              </v-chip>
            </template>

            <template v-slot:[`item.toques`]="{ item }">
              <v-chip :color="getColor(item.toques)" dark>
                {{ item.toques }}
              </v-chip>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <!--  -->
                <v-toolbar-title>Panel de Trabajo Jurídico</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >¿Está seguro de eliminar el registro?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn color="red" text @click="deleteItemConfirm()"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
              <v-icon small class="mr-2" @click="detect(item, 'Editar')">
                mdi-pencil
              </v-icon>
              <v-icon small @click="detect(item, 'Eliminar')">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="py-3 rounded-pill blue lighten-3 mt-5 text-center">
      <buscador />
    </div>

    <!-- Alerta de Cambios...  -->
    <v-snackbar v-model="snackbar" :timeout="timeout" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { firebase, db, storage } from "../../../../firebase/config";
import {
  FBverClientesNit,
  FBverCarteras,
} from "../../../../components/Cartera/gestionDCM/busquedas";

import Titular from "../panelTitular.vue";
import buscador from "../buscadorTitular.vue";

const audio = new Audio(
  require("../../../../assets/notificaciones/Vermillion.mp3")
);

export default {
  name: "gestionBasicaJ",
  components: {
    Titular,
    buscador,
  },
  data: () => ({
    search: "",
    headers: [
      { text: "Tipificación", value: "tipiUlti" },
      { text: "Cédula", value: "Cedula" },
      { text: "Toques", value: "toques" },
      { text: "Fecha solicitud", value: "proxToque" }, //Antes era proxToque, pero ahora es solo fecha regisotro
      { text: "Prioridad", value: "iron" },
      //{ text: "Cartera", value: "idCartera" },
      { text: "Acciones", value: "acciones", sortable: false },
    ],
    desserts: [
      /*{
        Cedula: 80,
        idRegistro: 159,
        proxToque: 6.0,
        tipiUlti: 24,
        toques: 4.0,
      },*/
    ],
    nits: null,
    carteras: null,
    estadoBotonCargaCartera: false,
    estadoCarterasText: "",
    snackbar: false,
    text: "",
    timeout: 3000,
    titular: false,
    datosTitularBusqueda: null,
    dialogDelete: false,
    titularAccion: null,
  }),
  mounted() {
    //console.log("Montado Gestion DCM");
    setTimeout(() => {
      //this.consultasCarterasTotalesBasico();
      this.consultaTareasDCM();
    }, 1000);
  },
  methods: {
    async deleteItemConfirm() {
      //console.log("Eliminar del todo...");
      //console.log(this.titularAccion);
      this.dialogDelete = false;

      /*db.collection('cf-cartera').doc('Carteras')
            .collection(jsonTitular.nitCliente).doc(jsonTitular.idCartera)
            .collection('registros').where('data.Cedula', '==', Number(jsonTitular.Cedula));*/
      //.collection("Z9yqrRvsymlViYTMxoMy").where("Cedula", "==", 1037545432)

      const rutaTitular = await db
        .collection("cf-cartera")
        .doc("Asignaciones")
        .collection("juridico")
        .doc(this.titularAccion.codigoB)
        .delete();

      //console.log(rutaTitular);
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    detect(event, accion) {
      //console.log(event);
      //console.log(event.Cedula);
      switch (accion) {
        case "Eliminar":
          //this.snackbar = true;
          //this.text = "Usuario:" + event.Cedula;

          //this.titular = true;
          //this.datosTitularBusqueda = event;
          //console.log("Eliminar:", event);
          this.titularAccion = event;
          this.dialogDelete = true;
          //const res = await db.collection('cities').doc('DC').delete();index.js

          break;
        case "Editar":
          //this.snackbar = true;
          this.text = "Usuario:" + event.Cedula;

          this.titular = true;
          this.datosTitularBusqueda = event;
          break;
        default:
          break;
      }
    },
    async consultaTareasDCM() {
      this.FBgestionDCM();
    },
    //TRAE LOS DATOS DEL PANEL CARTERAS
    async consultasCarterasTotalesBasico() {
      this.estadoCarterasText = "Cargando datos...";
      this.carteras = null;
      this.estadoBotonCargaCartera = true;

      const result = await FBverClientesNit();
      //console.log(result);
      this.nits = result;
      const resultCarteras = await FBverCarteras(result);
      //console.log(resultCarteras);
      this.carteras = resultCarteras;
      this.estadoBotonCargaCartera = false;
    },
    sumaHistorial(a) {
      var sumar = 0;
      for (var element of a) {
        sumar = sumar + Number(element);
      }

      var pesosCOP = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      }).format(sumar);

      return pesosCOP;
    },
    pesosCOP(a) {
      var pesosCOP = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      }).format(a);

      return pesosCOP;
    },
    titulares() {
      //console.log("Titulares");
      this.snackbar = true;
    },
    boleanTexto(a) {
      switch (a) {
        case true:
          return "Activa";
          break;
        case false:
          return "InActiva";
          break;
        default:
          break;
      }
    },
    timeTodate(a) {
      //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.
      //console.log('Entra a leer fecha');
      //console.log(typeof a);
      //console.log(a);

      if (typeof a == "object" && a != null) {
        let time = {
          seconds: a[0].seconds,
          nanoseconds: a[0].nanoseconds,
        };

        const fireBaseTime = new Date(time.seconds * 1000);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        //var convertida = fireBaseTime.toDateString();

        return fireBaseTime.toLocaleDateString("es-ES", options);
      }
    },
    timeTodateDCM(a) {
      //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.
      //console.log('Entra a leer fecha');
      //console.log(typeof a);
      //console.log(a);

      if (typeof a == "object" && a != null && a != undefined) {
        let time = {
          seconds: a.seconds,
          nanoseconds: a.nanoseconds,
        };

        const fireBaseTime = new Date(time.seconds * 1000);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        //var convertida = fireBaseTime.toDateString();

        return fireBaseTime.toLocaleDateString("es-ES", options);
      }
    },
    FBgestionDCM() {
      try {
        //console.log("Gestion DCM");

        // /cf-cartera/Asignaciones/dcm
        const observer = db
          .collection("cf-cartera")
          .doc("Asignaciones")
          .collection("juridico"); //.limit(1); //.where('state', '==', 'CA')

        observer.onSnapshot(
          (docSnapshot) => {
            //console.log(docSnapshot.size);
            this.desserts = []; //Vaciar
            audio.play();

            docSnapshot.forEach((doc) => {
              //console.log(doc.data());
              if (doc.data().a == "no borrar") {
              } else {
                var fechaCambio = this.timeTodateDCM(doc.data().proxToque);
                this.desserts.push({
                  Cedula: doc.data().Cedula,
                  idRegistro: doc.data().idRegistro,
                  proxToque: fechaCambio,
                  tipiUlti: doc.data().tipiUlti,
                  toques: doc.data().toques,
                  idCartera: doc.data().idCartera,
                  nitCliente: doc.data().nitCliente,
                  codigoB: doc.id,
                });
              }
            });
          },
          (error) => {
            //console.log(error);
            //return false;
          }
        );
      } catch (error) {
        //console.log(error);
      }
    },
    //Color Toques
    getColor(toques) {
      if (toques > 10) return "red";
      else if (toques > 20) return "orange";
      else return "blue lighten-4";
    },
    //Titificación
    getColortipiUlti(a) {
      switch (a) {
        case "Iniciar Demanda":
          return "red darken-1";
          break;
        case "Volver a Demandar":
          return "red darken-4";
          break;
        case "Demanda Radicada":
          return "deep-purple darken-1";
          break;
        case "Demanda - Subsanada":
          return "blue darken-3";
          break;
        case "Demandado - Notificado Acta Reparto":
          return "green darken-4";
          break;
        case "Demandado - Notificado Auto":
          return "orange darken-2";
          break;
        case "Demandado - Notificado Oficio":
          return "orange darken-3";
          break;
        case "Demandado - Terminación Proceso":
          return "blue-grey darken-3";
          break;
        case "Demandado - Liquidación de Titulos":
          return "purple darken-4";
          break;
        /*case "Demandado - Liquidación de Titulos":
          return "orange";
          break;*/
        default:
          return "grey darken-1";
          break;
      }
    },
  },
  watch: {
    desserts() {
      //console.log("Llega trabajo!");
      this.snackbar = true;
      this.text = "¡Llega Titular!";
    },
  },
};
</script>