<template>
  <v-container >
    <!-- PANELES DE CONTROL -->
    <v-row lass="pa-2" dense>
      <v-col cols="12" >
        <h1 class="text-center">Paneles de Gestión</h1>
        <v-divider class="mx-4" inset></v-divider>
        <Contador
          :tiempoLimite="10"
          @tiempo-alcanzado="onTiempoAlcanzado"
          texto="Proxima gestión:"
        ></Contador>
      </v-col>
      <v-col cols="12">
        <tableroTareasPropias />
      </v-col>
      <v-col cols="12">
        <tableroTareasExternas />
      </v-col>
      <v-col cols="12">
        <tableroPedidosTienda />
      </v-col>

      
      <v-col cols="12">
        <Whatsapp  />
      </v-col>
      <!-- 
        <v-col cols="12">
          <tableroClientes />
        </v-col>
      -->
    </v-row>

    <!-- PANELES DE PROCESOS -->
    <!-- Prospectos, Indicadores, etc-->
    <v-row class="pa-2" dense>
      <v-col
        v-for="card in filteredCards"
        :key="card.title"
        :md="card.flex"
        cols="12"
      >
        <v-card
          @click="abrirPanel(card.title)"
          :color="card.color"
          shaped
          outlined
          hover
        >
          <v-img
            :src="card.src"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
            <v-card-title class="titulos">{{ card.title }}</v-card-title>
          </v-img>

          <v-card-actions>
            <v-spacer>
              <div v-if="card.verMensaje">
                <marquee>{{ card.mensaje }}</marquee>
              </div>
            </v-spacer>
            <v-btn v-if="card.alert" disabled depressed icon>
              <v-badge dot overlap bordered color="green" :content="card.badge">
                <v-icon>mdi-alarm-light-outline</v-icon>
              </v-badge>
            </v-btn>
            <div v-else></div>

            <component
              v-for="(component, index) in card.components"
              :key="index"
              :is="component.name"
              v-bind="component.props"
              v-show="roles(component.roles)"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import indicadoresSc from "./Indicadores/Indicadores.vue";
import solicitudesSc from "./Solicitudes/Solicitudes.vue";
import verSolicitudSc from "./Solicitudes/verSolicitudes.vue";
import agregaProspectoSc from "./Prospectos/agregarProspecto.vue"; //Trae el listado de Prospectos
import agregaOportunidadSc from "./Prospectos/agregarOportunidad.vue";
import tableroTareasExternas from "./Tableros/tareasExternas.vue";
import tableroPedidosTienda from "./Tableros/pedidosTienda.vue";
import tableroTareasPropias from "./Tableros/tareasPersonales.vue";
import tableroClientes from "./Tableros/listadoClientesProspectos.vue";
import Contador from "./Tableros/temporizador.vue";
import Whatsapp from "./Clientes/whatsapp/wappGeneral.vue"

export default {
  name: "Secretaria",
  data() {
    return {
      rolPrueba: "g",
      cards: [
        {
          title: "Prospectos",
          src: "xx",
          flex: 4,
          mensaje: "Mensaje para secretaria...",
          verMensaje: false,
          alert: false,
          badge: 1,
          modalCard: true,
          color: "#E4E4E4FF",
          roles: ["g", "ven"],
          components: [
            {
              name: "agregaProspectoSc",
              props: {},
              roles: ["g", "ven"],
              modalCard: true,
            },
            {
              name: "agregaOportunidadSc",
              props: {},
              roles: ["g", "ven"],
              modalCard: true,
            },
          ],
        },
        /*{
          title: "Clientes",
          src: "xx",
          flex: 4,
          alert: false,
          modalCard: true,
          color: "#E4E4E4FF",
          roles: ["dcm", "acm", "g", "s"],
          components: [
            {
              name: "verSolicitudSc",
              props: {},
              roles: ["dcm", "g", "s"],
              modalCard: true,
            },
          ],
        },
        {
          title: "Solicitudes",
          src: "xx",
          flex: 4,
          alert: false,
          modalCard: true,
          color: "#E4E4E4FF",
          roles: ["dcm", "g", "s"],
          components: [
            {
              name: "solicitudesSc",
              props: {},
              roles: ["dcm", "g", "s"],
              modalCard: true,
            },
          ],
        },*/
        {
          title: "Indicadores",
          src: "xx",
          flex: 4,
          alert: false,
          modalCard: true,
          color: "#E4E4E4FF",
          roles: ["ven", "g"],
          components: [
            {
              name: "indicadoresSc",
              props: {},
              roles: ["dcm", "g", "s"],
              modalCard: true,
            },
          ],
        },
      ],
    };
  },
  components: {
    indicadoresSc,
    solicitudesSc,
    verSolicitudSc,
    agregaProspectoSc,
    agregaOportunidadSc,
    tableroTareasExternas,
    tableroTareasPropias,
    tableroPedidosTienda,
    tableroClientes,
    Contador,
    Whatsapp,
  },
  computed: {
    filteredCards() {
      return this.cards.filter((card) => this.roles(card.roles));
    },
  },
  methods: {
    roles(roles) {
      return roles.includes(this.rolPrueba);
    },
    abrirPanel(panel) {
      switch (panel) {
        case "Carteras":
          // Genera error porque ya se encuentra en la ruta
          // this.$router.push("/Cartera");
          break;
        default:
          break;
      }
    },
    onTiempoAlcanzado() {
      // Aquí puedes agregar la lógica que deseas ejecutar cuando se alcanza el tiempo límite
      console.log("¡Tiempo alcanzado!");
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Sarpanch&display=swap");
@import url("https://fonts.googleapis.com/css2?family=B612+Mono&display=swap");

.titulos {
  font-family: "Sarpanch", sans-serif;
  color: white;
  font-weight: 700;
}
marquee {
  font-family: "B612 Mono", monospace;
  color: rgb(122, 122, 122);
  font-weight: 200;
}
</style>
