<template>
    <div class="text-center">
      <v-dialog v-model="dialog" width="650px" persistent scrollable dark>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-2"
            icon
            color="black"
            v-bind="attrs"
            v-on="on"
            @click="openDialog"
            outlined
          >
            <v-icon>mdi-note-edit-outline</v-icon>
          </v-btn>
        </template>
  
        <v-card>
          <v-card-title class="text-h5 amber">Notas Rápidas</v-card-title>
  
          <v-card-text class="mt-5">
            <p>
              Escribe todo lo que requieras para tener en cuenta con el cliente.
            </p>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-textarea
                  v-model="campo"
                  name="input-7-1"
                  label="Escribe o actualiza tus notas de cliente..."
                  hint="Notas rápidas"
                  color="amber"
                  @change="actualizarNota"
                  :value="campo"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
        
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange" outlined text @click="dialog = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import { FBactualizaApoyo } from "@/components/Ventas/Tableros/actualizaNotasRapidas.js";
  
  export default {
    props: ["datos"],
    data() {
      return {
        dialog: false,
        campo: this.datos.nota || "",
      };
    },
    methods: {
      async openDialog() {
        this.dialog = true;
      },
      async actualizarNota() {
        const json = {
          etapa: this.datos.etapa,
          seguimientoId: this.datos.seguimientoId,
          nota: this.campo
        };
  
        const respuesta = await FBactualizaApoyo(json);
        if (respuesta) {
          this.dialog = false;
        }
      },
    },
  };
  </script>
  