import { db, firebase } from "@/firebase/config.js";

export async function FBagregaDatoContacto(jsonLlega) {
    try {
        const json = {
            codigoSeguimiento: jsonLlega.codigoSeguimiento,
            seguimientoId: jsonLlega.seguimientoId,
            nuevoDato: jsonLlega.nuevoDato,
            clave: jsonLlega.calve
        }
        //console.log(json);
        //SE GUARDA O AGREGA EN LA ESTA CORRESPONDIENTE -----------------------------#1
        const collectionPathCreaProspecto = `cf-pedidos`;

        const querySnapshot = await db
            .collection(collectionPathCreaProspecto)
            .where('codigoSeguimiento', '==', json.codigoSeguimiento) // Filtrar por el valor de idRegistroS
            .get();

        if (querySnapshot.empty) {
            //console.error('No se encontraron documentos que coincidan con el filtro.');
            return [false, "No existe Documento"];
        }

        const docRef = querySnapshot.docs[0].ref; // Obtener la referencia del primer documento que cumple con el filtro

        //console.log(json.clave);
        // Luego, puedes actualizar el documento usando el método `update`:
        await docRef.update({
            [json.clave]: firebase.firestore.FieldValue.arrayUnion(json.nuevoDato)
        });


        return [true, "Se ha actualizado con exito"];
    } catch (error) {
        console.error('Error al actualizar el documento:', error);
        return [false, "Error.."];
    }
}

export async function FBeliminaDatoContacto(jsonLlega) {
    try {
        const json = {
            codigoSeguimiento: jsonLlega.codigoSeguimiento,
            seguimientoId: jsonLlega.seguimientoId,
            datoAEliminar: jsonLlega.datoAEliminar,
            clave: jsonLlega.calve
        }

        //console.log(json);

        //SE BUSCA EL DOCUMENTO QUE CONTIENE EL ARRAY ---------------------------------#1
        const collectionPathCreaProspecto = `cf-pedidos`;

        const querySnapshot = await db
            .collection(collectionPathCreaProspecto)
            .where('codigoSeguimiento', '==', json.codigoSeguimiento) // Filtrar por el valor de idRegistroS
            .get();


        if (querySnapshot.empty) {
            return [false, "No existe el documento"];
        }

        const docRef = querySnapshot.docs[0].ref; // Obtener la referencia del primer documento que cumple con el filtro

        //console.log(json.clave);

        // Obtener el array "whatsappContacto" actual
        const docData = await docRef.get();
        const consultaArray = docData.get(json.clave) || []; // Si el array no existe, se inicializa como un array vacío

        // Eliminar el elemento del array en la posición indicada
        if (json.datoAEliminar >= 0 && json.datoAEliminar < consultaArray.length) {
            consultaArray.splice(json.datoAEliminar, 1);
        }

        // Actualizar el documento con el array modificado
        await docRef.update({
            [json.clave]: consultaArray
        });

        return [true, "Se ha eliminado el dato exitosamente"];
    } catch (error) {
        console.error('Error al actualizar el documento:', error);
        return [false, "Error al eliminar el dato"];
    }
}

