
export async function disparoDepartamento(tipi) {

    switch (tipi) {
        case "Mensaje Empresa":
            return ['n/a'];
            break;
        case "Mensaje con Tercero":
            return ['n/a'];
            break;
        case "Tercero no Recibe Mensaje":
            return ['n/a'];
            break;
        case "Acuerdo de pago":
            return ['n/a'];
            break;
        case "Cliente se acerca a oficina":
            return ['n/a'];
            break;
        case "Posible Incobrable":
            return ['dcm'];
            break;
        case "Posible Fallecido":
            return ['dcm'];
            break;
        case "Posible Ilocalizado":
            return ['dcm'];
            break;
        case "Modificar Datos":
            return ['dcm'];
            break;
        case "Novedad":
            return ['dcm'];
            break;
        case "Posible Negociación":
            return ['n/a'];
            break;
        case "Reclamación":
            return ['n/a'];
            break;
        case "Recordatorio de Pago":
            return ['n/a'];
            break;
        case "Renuente":
            return ['n/a'];
            break;
        case "Solicitud Titulo Valor":
            return ['dcm'];
            break;
        case "Solicitud Descuento":
            return ['dcm'];
            break;
        case "Iniciar Demanda":
            return ['juridico'];
            break;
        case "Verificar Pago":
            return ['dcm'];
            break;
        case "Confirmar Pago":
            return ['dcm'];
            break;
        case "Pago Realizado":
            return ['administrativo'];
            break;
        case "Pago Pendiente":
            return ['administrativo'];
            break;
        case "Pago Rechazado DCM"://pilas a agregar..
            return ['n/a'];
            break;
        case "Pago Rechazado ADM":
            return ['dcm'];
            break;
        case "Novedad ADM"://Nueva 
            return ['dcm'];
            break;
        case "Novedad Gerencia"://Nueva 
            return ['dcm'];
            break;
        case "Incobrable":
            return ['n/a'];
            break;
        case "Sin Titulo Valor":
            return ['n/a'];
            break;
        case "Titulo Valor Cargado":
            return ['n/a'];
            break;
        case "Solicitud Descuento Gerencia":
            return ['gerencia'];
            break;
        case "Descuento Autorizado":
            return ['n/a'];
            break;
        case "Descuento Rechazado":
            return ['n/a'];
            break;
        case "Modifica Datos Rechazado":
            return ['n/a'];
            break;
        case "Posible Demanda":
            return ['dcm'];
            break;
        case "Rechazo Posible Demanda":
            return ['n/a'];
            break;
        case "Iniciar Demanda":
            return ['juridico'];
            break;
        case "Demanda Radicada":
            return ['juridico'];
            break;
        case "Volver a Demandar":
            return ['juridico'];
            break;
        case "Demanda - Subsanar":
            return ['n/a'];
            break;
        case "Demanda - Subsanada":
            return ['juridico'];
            break;
        case "Demandado - Notificado Acta Reparto":
            return ['juridico'];
            break;
        case "Demandado - Notificado Auto":
            return ['juridico'];
            break;
        case "Demandado - Notificado Oficio":
            return ['juridico'];
            break;
        case "Demandado - Novedad Dep. Juridico":
            return ['juridico'];//Esa novedad es de otro departamento a Juridico.
            break;
        case "Demandado - Notificar Acta Reparto":
            return ['n/a'];
            break;
        case "Demandado - Notificar Auto":
            return ['n/a'];
            break;
        case "Demandado - Notificar Oficio":
            return ['n/a'];
            break;
        case "Demandado - Terminación Proceso":
            return ['juridico'];
            break;
        case "Demandado - Liquidación de Titulos":
            return ['juridico'];
            break;
        case "Correo":
            return ['n/a'];
            break;
        case "Ilocalizado":
            return ['n/a'];
            break;
        case "No contesta / Ocupado":
            return ['n/a'];
            break;
        case "Dañado, Fuera de servicio":
            return ['n/a'];
            break;
        case "Buzón":
            return ['n/a'];
            break;
        case "Fallecido":
            return ['n/a'];
            break;
        case "Paz y Salvo v2":
            return ['n/a'];
            break;
        case "Paz y Salvo":
            return ['n/a'];
            break;
        case "Notificar Paz y Salvo":
            return ['n/a'];
            break;
        case "Paz y Salvo - Notificado":
            return ['n/a'];
            break;
        default:
            break;
    }



}

