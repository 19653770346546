<template>
  <v-container>
    <v-expansion-panels multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Informes | {{ respuestaSave }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6" sm="6">
              <v-select
                v-model="ejecutivo"
                :items="listadoEjecutivos"
                item-text="datos.data.empleado"
                item-value="id"
                label="Ejecutivos"
                dense
                solo
              ></v-select>
              <v-row class="pa-4">
                Fecha de la consulta para reporte diario:
                {{ picker }}
              </v-row>
              <v-row class="pa-3">
                <v-col cols="6" sm="6">
                  <v-btn class="pa-2" @click="consulta">Consulta</v-btn>
                </v-col>
                <v-col cols="6" sm="6">
                  <!--
                  <v-btn class="pa-2" @click="guardaHistorial">Guardar</v-btn>
                -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="6">
              <v-row justify="center">
                <v-date-picker v-model="picker" />
              </v-row>
            </v-col>
          </v-row>

          <v-row> </v-row>

          <v-container class="grey lighten-5" v-if="tieneAnalisis">
            <!--Paneles de INDICADORES TOTALES -->
            <v-row>
              <v-col cols="12" sm="4">
                <v-card class="pa-2 text-center rounded-xl">
                  <div class="font-weight-black">Total Asignados</div>
                  <v-divider></v-divider>
                  <div class="pa-2">{{ totalAsignados }}</div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-card class="pa-2 text-center rounded-xl">
                  <div class="font-weight-black">Total Sin Tocar</div>
                  <v-divider></v-divider>
                  <div class="pa-2">{{ totalSinTocar }}</div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-card class="pa-2 text-center rounded-xl">
                  <div class="font-weight-black">Total Gestinados</div>
                  <v-divider></v-divider>
                  <div class="pa-2">{{ totalGestionados }}</div>
                </v-card>
              </v-col>
            </v-row>

            <!--Paneles GRÁFICOS Datos Generales-->
            <v-row class="pa-3">
              <v-row>
                <v-col md="12" sm="12" xs="12">
                  <Ghoy
                    :datos="totalClasificados"
                    v-if="!totalClasificadosRecarga"
                  />
                </v-col>
              </v-row>
            </v-row>

            <!--Panel ESTADO DE LA CARTERA -->
            <v-row>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Estado de la Cartera | Totales - Generales
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">Tipificación</th>
                                    <th class="text-left" width="70%">Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item, key) in totalClasificados"
                                    :key="key"
                                  >
                                    <td>{{ key }}</td>
                                    <td>
                                      <v-row>
                                        <v-col>
                                          <v-expansion-panels>
                                            <v-expansion-panel>
                                              <v-expansion-panel-header
                                                color="green accent-1"
                                              >
                                                {{ item.detalle.length }}
                                                Registros
                                              </v-expansion-panel-header>
                                              <v-expansion-panel-content>
                                                <v-card
                                                  v-for="(
                                                    item2, key2
                                                  ) in item.detalle"
                                                  :key="key2"
                                                  class="mx-auto"
                                                  tile
                                                >
                                                  <v-list rounded>
                                                    <v-subheader>{{
                                                      key
                                                    }}</v-subheader>
                                                    <v-list-item-group
                                                      color="primary"
                                                    >
                                                      <v-list-item>
                                                        <v-list-item-icon>
                                                          <v-icon
                                                            v-text="item2.icon"
                                                          ></v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content
                                                          @click="
                                                            consultaDetalleTitular(
                                                              item2
                                                            ),
                                                              copiar(
                                                                item2.cedula
                                                              )
                                                          "
                                                        >
                                                          <v-list-item-title
                                                            v-text="
                                                              'Cédula: ' +
                                                              item2.cedula +
                                                              ' ||   F.R: ' +
                                                              item2.fechaRegistro
                                                            "
                                                          ></v-list-item-title>
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                    </v-list-item-group>
                                                  </v-list>
                                                </v-card>
                                              </v-expansion-panel-content>
                                            </v-expansion-panel>
                                          </v-expansion-panels>
                                        </v-col>
                                        <v-col>
                                          <exportCSV
                                            :datos="item"
                                            :tipificacion="key"
                                          />
                                        </v-col>
                                      </v-row>

                                      <v-divider inset></v-divider>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>

            <v-divider class="pa-5"></v-divider>

            <!--Panel RESUMEN DEL DIA -->

            <!--Paneles GRÁFICOS -->
            <v-row class="pa-3">
              <v-row>
                <v-col md="12" sm="12" xs="12">
                  <Ghoy2
                    :datos="totalClasificadosHoy"
                    v-if="!totalClasificadosRecarga"
                  />
                </v-col>
              </v-row>
            </v-row>

            <v-row>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Resumen del día | Clasificado por tipificación
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="4">
                        <v-card
                          :color="getColor(totalGestionadosHoy)"
                          class="pa-2 text-center rounded-xl"
                        >
                          <div class="font-weight-black">
                            Total Gestionados Hoy
                          </div>
                          <v-divider></v-divider>
                          <div class="pa-2">{{ totalGestionadosHoy }}</div>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Tipificación</th>
                                <th class="text-left" width="70%">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, key) in totalClasificadosHoy"
                                :key="key"
                              >
                                <td>{{ key }}</td>
                                <td>
                                  <v-expansion-panels>
                                    <v-expansion-panel>
                                      <v-expansion-panel-header
                                        color="light-blue accent-1"
                                      >
                                        {{ item.detalle.length }}
                                        Registros
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-card
                                          v-for="(item2, key2) in item.detalle"
                                          :key="key2"
                                          class="mx-auto"
                                          tile
                                        >
                                          <v-list rounded>
                                            <v-subheader>{{ key }}</v-subheader>
                                            <v-list-item-group color="primary">
                                              <v-list-item>
                                                <v-list-item-icon>
                                                  <v-icon
                                                    v-text="item2.icon"
                                                  ></v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content
                                                  @click="
                                                    consultaDetalleTitular(
                                                      item2
                                                    ),
                                                      copiar(item2.cedula)
                                                  "
                                                >
                                                  <v-list-item-title
                                                    v-text="
                                                      'Cédula: ' +
                                                      item2.cedula +
                                                      ' -   H.R: ' +
                                                      item2.horasRegistro
                                                    "
                                                  >
                                                  </v-list-item-title>
                                                </v-list-item-content>
                                              </v-list-item>
                                            </v-list-item-group>
                                          </v-list>
                                        </v-card>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>

                                  <v-divider inset></v-divider>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>

            <v-divider class="pa-5"></v-divider>

            <!--Panel RESUMEN DEL DÍA ORDENADO POR FECHA -->
            <v-row>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Resumen del día | Ordenado por fecha
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="4">
                        <v-card
                          :color="getColor(totalGestionadosHoy)"
                          class="pa-2 text-center rounded-xl"
                        >
                          <div class="font-weight-black">
                            Total Gestionados Hoy
                          </div>
                          <v-divider></v-divider>
                          <div class="pa-2">
                            Total por Tipificación {{ totalGestionadosHoy }}
                          </div>
                          <div class="pa-2">
                            Total por Hora {{ TotalesConFechaOrdenado.length }}
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-row>
                          <v-col>
                            No cuenta las tipificaciones: "Titulo Valor
                            Cargado", "Paz y Salvo", "Demandado - Novedad Dep.
                            Juridico"
                            <br />
                            Cuenta el último estado de la tipificación del
                            titular.
                            <br />
                            <br />
                          </v-col>
                        </v-row>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">Marca</th>
                                <th class="text-left" width="30%">
                                  Tipificacion
                                </th>
                                <th class="text-left" width="30%">Titular</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, key) in TotalesConFechaOrdenado"
                                :key="key"
                              >
                                <td>{{ item.fecha.toLocaleString() }}</td>

                                <td>
                                  {{ item.tipificacion }}
                                </td>
                                <td>
                                  <div>
                                    {{ item.cedula }}
                                    <v-icon
                                      color="green darken-2"
                                      @click="
                                        consultaDetalleTitular(item),
                                          copiar(item.cedula)
                                      "
                                    >
                                      mdi-account-circle
                                    </v-icon>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col> Pendiente por seleccionar Ejecutivo. </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import {
  FBverRegistros,
  FBsaveHistorial,
  FBconsultaListadoEjecutivos,
} from "../../../../../components/Cartera/gestionDCM/consultaACM.js";

//convertir a CSV
import exportCSV from "@/views/Recursos/exportar.vue";

//Graficos
//C:\Users\User\Firebase\byb-1\src\views\Cartera\Graficas\BarChartHoy.vue
import Ghoy from "@/views/Cartera/Graficas/BarChartHoy.vue";
import Ghoy2 from "@/views/Cartera/Graficas/BarChart2.vue";

export default {
  name: "gestionBasicaDCMIndicadoresACM",
  components: {
    exportCSV,
    Ghoy,
    Ghoy2,
  },
  data: () => ({
    ejecutivo: "",
    items: [
      { texto: "Juliana Marcela Rivera", value: "0N27BVAKtfhnaOsB8pv2" },
    ],
    tieneAnalisis: true,
    totalAsignados: 0,
    totalSinTocar: 0,
    totalGestionados: 0,
    totalClasificados: {},
    totalClasificadosRecarga: true,
    totalGestionadosHoy: 0,
    totalClasificadosHoy: {},
    respuestaSave: "",
    selectedItem: 1,
    TotalesConFechaOrdenado: {},
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    listadoEjecutivos: [],
    actualizarTabla: false,
  }),
  async beforeMount() {},
  async mounted() {
    const resultEjecutivos = await FBconsultaListadoEjecutivos();
    //console.log(resultEjecutivos);
    this.listadoEjecutivos = resultEjecutivos;
  },
  methods: {
    consultaFecha(a) {
      //console.log("Pols");
      //console.log(a);
    },
    consultaDetalleTitular(e) {
      //console.log(e);
    },
    copiar(elemento) {
      //console.log(elemento);
      let dato = elemento;

      let textoCopiado = dato;

      //Copia el texto en el portapapeles
      const a = navigator.clipboard.writeText(textoCopiado);
    },
    async guardaHistorial() {
      const jsonHistorial = {};

      jsonHistorial.totalAsignados = this.totalAsignados;
      jsonHistorial.totalSinTocar = this.totalSinTocar;
      jsonHistorial.totalGestionados = this.totalGestionados;
      jsonHistorial.totalClasificados = this.totalClasificados;
      jsonHistorial.totalGestionadosHoy = this.totalGestionadosHoy;
      jsonHistorial.totalClasificadosHoy = this.totalClasificadosHoy;
      jsonHistorial.ejecutivo = this.ejecutivo;

      //console.log(jsonHistorial);

      const resultSave = await FBsaveHistorial(jsonHistorial);

      //console.log(resultSave);
      if (resultSave) {
        this.respuestaSave = "Se ha guardado con éxito.";
      } else {
        this.respuestaSave = "No se guardo, contacte a Soporte urg! ";
      }
    },
    async consulta() {
      this.totalClasificadosRecarga = true; //Oculta gráfica
      //console.log(this.ejecutivo);
      if (
        this.ejecutivo != "" &&
        this.ejecutivo != undefined &&
        this.ejecutivo != null
      ) {
        const resultAsesor = await FBverRegistros(this.ejecutivo);

        if (resultAsesor.length > 0) {
          //console.log(resultAsesor);
          //console.log("Tiene más de 1 registro");
          this.analisisInicial(resultAsesor);
        } else {
          //console.log("No tiene registros...");
        }
      }
    },
    async analisisInicial(bolsa) {
      //console.log(bolsa);
      this.totalAsignados = bolsa.length;
      const fechaHoy = new Date();
      //console.log(fechaHoy);

      //Cedula, fechaRegistro, idCartera, idRegistro, nitCliente, proxToque, tipiUlti, toques
      //var totalAsginados = bolsa.length;
      //console.log(totalAsginados);

      let sinTocar = _.remove(bolsa, (n) => {
        //return n % 2 == 0;
        //console.log(n.toques);
        return n.toques == 0;
      });
      //console.log(sinTocar);
      //console.log("sinTocar: ", sinTocar.length);
      this.totalSinTocar = sinTocar.length;

      let conFecha = _.remove(bolsa, (n) => {
        //return n % 2 == 0;
        //console.log(n.proxToque);
        return n.proxToque != null;
      });
      //console.log(conFecha);
      //console.log("conFecha: ", conFecha.length);
      this.totalGestionados = conFecha.length;

      var conFechaOrderBy = _.orderBy(conFecha, "tipiUlti", "desc");
      //console.log(conFechaOrderBy);

      var repetidosPorCategoria = {};

      var repetidosPorCategoriaExtenso = {};
      var arrayCategoriaExtenso = [];
      //arrayCategoriaExtenso.push(element.Cedula);

      conFechaOrderBy.forEach((element) => {
        //console.log(element);
        //console.log(element.tipiUlti);
        repetidosPorCategoria[element.tipiUlti] =
          (repetidosPorCategoria[element.tipiUlti] || 0) + 1;

        arrayCategoriaExtenso.push({
          grupo: element.tipiUlti,
          cedula: element.Cedula,
          Registro: element.idRegistro,
          Cartera: element.idCartera,
          Cliente: element.nitCliente,
          fechaRegistro: element.fechaRegistro,
        });
      });

      //Agrupar los elementos del array
      arrayCategoriaExtenso.forEach(async (element) => {
        //console.log(element);
        //Si la ciudad no existe en nuevoObjeto entonces
        //la creamos e inicializamos el arreglo de Tipificaciones
        if (!repetidosPorCategoriaExtenso.hasOwnProperty(element.grupo)) {
          repetidosPorCategoriaExtenso[element.grupo] = {
            detalle: [],
          };
        }

        //Agregamos los datos de las Tipifiaciones
        var fechaRegistroF = await this.timeTodate(element.fechaRegistro);

        //var fechaRegistroF = new Date(element.fechaRegistro.seconds * 1000).toUTCString()

        repetidosPorCategoriaExtenso[element.grupo].detalle.push({
          cedula: element.cedula,
          Registro: element.Registro,
          Cartera: element.Cartera,
          Cliente: element.Cliente,
          fechaRegistro: fechaRegistroF,
          icon: "mdi-account",
        });
      });

      //console.log(repetidosPorCategoriaExtenso);
      //console.log("Total Clasificados:");
      //console.log(repetidosPorCategoria);
      ///Version 1 - Solo traia a los
      //this.totalClasificados = repetidosPorCategoria;
      //ORDENAR POR FECHA

      this.totalClasificados = repetidosPorCategoriaExtenso;
      this.desserts = repetidosPorCategoriaExtenso;

      //console.log(this.totalClasificados);
      //console.log(this.desserts);

      /////////////////////////// TRABAJADOS HOY //////////////////////////
      //Los gestionados Hoy
      var totalTrabajadosHoy = [];
      var arrayCategoriaExtensoTrabajadoHoy = [];
      //const conFechaHoy = await this.totalGestionHoy(conFecha, fechaHoy);
      //Llena el array trabajados hoy
      for (const datos of conFechaOrderBy) {
        if (datos.fechaRegistro != undefined) {
          //console.log(datos.fechaRegistro);
          //var fechaRegistro = await this.timeTodate(datos.fechaRegistro);

          var fechaRegistro = new Date(
            datos.fechaRegistro.seconds * 1000
          ).toUTCString();
          //console.log(fechaRegistro);

          var d = new Date(fechaRegistro);
          //console.log(d);
          var fechaTitularContacto =
            d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
          var hoy = new Date();

          //Validemos si es la fecha de hoy o la del calendario
          //V2 OJO hay que tener en cuenta que la bolsa de titulares se està modificando.
          var anoHoy =
            hoy.getFullYear() +
            "-" +
            (hoy.getMonth() + 1) +
            "-" +
            hoy.getDate();
          //Traducir el picker
          //console.log(this.picker);
          var datePicker = new Date(this.picker);
          var picker01 =
            datePicker.getFullYear() +
            "-" +
            (datePicker.getMonth() + 1) +
            "-" +
            (datePicker.getDate() + 1);

          //console.log(datePicker);
          //console.log(picker01);

          if (picker01 != anoHoy) {
            //console.log("Diferente");
            //console.log(anoHoy, picker01, this.picker, fechaTitularContacto);
            anoHoy = picker01;
          } else {
            //console.log("Es igual");
            //console.log(anoHoy, picker01, fechaTitularContacto);
          }

          if (anoHoy == fechaTitularContacto) {
            //console.log(datos);
            totalTrabajadosHoy.push(datos);
          }
        } //Solo si fechaRegistro EXISTE
      }
      //console.log("Trabajados hoy: " + totalTrabajadosHoy.length);
      //this.totalGestionadosHoy = totalTrabajadosHoy.length;//V1 se cambia porque trae todos y solo se quiere los que trabajó
      //console.log(totalTrabajadosHoy);

      var clasificadosHoy = {}; //var repetidosPorCategoriaHoy = {};

      var repetidosPorCategoriaExtensoHoy = {};

      totalTrabajadosHoy.forEach((element) => {
        //console.log(element);
        //console.log(element.tipiUlti);
        clasificadosHoy[element.tipiUlti] =
          (clasificadosHoy[element.tipiUlti] || 0) + 1;

        //Filtramos las tipificaciones que no aplican para el reporte diario
        if (element.tipiUlti != "Titulo Valor Cargado") {
          if (element.tipiUlti != "Paz y Salvo") {
            if (element.tipiUlti != "Incobrable") {
              if (element.tipiUlti != "Descuento Autorizado") {
                if (element.tipiUlti != "Pago Realizado") {
                  if (element.tipiUlti != "Demandado - Novedad Dep. Juridico") {
                    arrayCategoriaExtensoTrabajadoHoy.push({
                      grupo: element.tipiUlti,
                      cedula: element.Cedula,
                      Registro: element.idRegistro,
                      Cartera: element.idCartera,
                      Cliente: element.nitCliente,
                      fechaRegistro: element.fechaRegistro,
                    });
                  }
                }
              }
            }
          }
        }
      });
      //El total de tipificaciones
      this.totalGestionadosHoy = arrayCategoriaExtensoTrabajadoHoy.length; //V2 linea 456 cambio

      //Agrupar los elementos del array
      arrayCategoriaExtensoTrabajadoHoy.forEach(async (element) => {
        //console.log(element);
        //console.log(element.tipiUlti);
        //Si la ciudad no existe en nuevoObjeto entonces
        //la creamos e inicializamos el arreglo de Tipificaciones
        if (!repetidosPorCategoriaExtensoHoy.hasOwnProperty(element.grupo)) {
          repetidosPorCategoriaExtensoHoy[element.grupo] = {
            detalle: [],
          };
        }

        //Agregamos los datos de las Tipifiaciones
        var fechaRegistroF = await this.timeTodate(element.fechaRegistro);

        var fechaRegistroHoras = new Date(
          element.fechaRegistro.seconds * 1000
        ).toLocaleTimeString("en-US");
        //console.log(fechaRegistroHoras.toLocaleTimeString('en-US'));
        //var fechaRegistroF = new Date(element.fechaRegistro.seconds * 1000).toUTCString();
        //var fechaRegistroF = new Date(element.fechaRegistro.seconds * 1000).toUTCString()

        repetidosPorCategoriaExtensoHoy[element.grupo].detalle.push({
          cedula: element.cedula,
          Registro: element.Registro,
          Cartera: element.Cartera,
          Cliente: element.Cliente,
          fechaRegistro: fechaRegistroF,
          horasRegistro: fechaRegistroHoras,
          icon: "mdi-account",
        });
      });

      //console.log(arrayCategoriaExtensoTrabajadoHoy);
      //Nuevo versión 2, se agrupan las categorias de tipificación
      //console.log(repetidosPorCategoriaExtensoHoy);
      ////////////////////////////////////////////////////////////////////////////////////////////////////////////

      //console.log("Total clasificados hoy:");
      //console.log(clasificadosHoy);

      //this.totalClasificados = repetidosPorCategoriaExtenso;
      //this.desserts = repetidosPorCategoriaExtenso;

      //this.totalClasificadosHoy = clasificadosHoy;

      this.totalClasificadosHoy = repetidosPorCategoriaExtensoHoy;

      //Analizar los que tienen fecha de hoy y ordenarlos
      let ordenarFechaGestionadosHoy = _.orderBy(
        totalTrabajadosHoy,
        "fechaRegistro",
        "asc"
      );

      let informePorHoras = [];
      //Convertir la fecha
      ordenarFechaGestionadosHoy.forEach((element) => {
        //console.log(element);
        //Tue, 10 May 2022 12:09:22 GMT
        //console.log(element.fechaRegistro);
        var fechaRegistro = new Date(
          element.fechaRegistro.seconds * 1000 +
            element.fechaRegistro.nanoseconds / 1000000
        ).toUTCString();
        //console.log(fechaRegistro);

        var d = new Date(fechaRegistro);
        //console.log(d);
        if (element.tipiUlti != "Titulo Valor Cargado") {
          if (element.tipiUlti != "Paz y Salvo") {
            //if (element.tipiUlti != "Incobrable") {
            //if (element.tipiUlti != "Descuento Autorizado") {
            //if (element.tipiUlti != "Pago Realizado") {
            if (element.tipiUlti != "Demandado - Novedad Dep. Juridico") {
              informePorHoras.push({
                fecha: d,
                tipificacion: element.tipiUlti,
                cedula: element.Cedula,
                nitCliente: element.nitCliente,
                idCartera: element.idCartera,
                idRegistro: element.idRegistro,
              });
            }
            //}
            //}
          }
          //}
        }
      });

      this.TotalesConFechaOrdenado = informePorHoras;

      this.totalClasificadosRecarga = false; //Activar gráfica

      //console.log(this.TotalesConFechaOrdenado);
    },
    async totalGestionHoy(conFecha, fechaHoy) {
      const fecha = new Date(fechaHoy);
      const fechaTraducia =
        fecha.getDate() +
        "-" +
        (fecha.getMonth() + 1) +
        "-" +
        fecha.getFullYear();

      let totalTrabajadosHoy = await Promise.all(
        _.find(conFecha, async (n) => {
          //return n % 2 == 0;
          //console.log(n.proxToque);
          console.log(n.fechaRegistro);

          //var fechaTraducidaFirebase = await this.timeTodate(n.fechaRegistro);

          var fechaTraducidaFirebase = new Date(
            n.fechaRegistro.seconds * 1000
          ).toUTCString();

          var fechaLigera = fechaTraducidaFirebase; //new Date(fechaTraducida);
          if (fechaLigera != undefined) {
            var fechaAgrupada =
              fechaLigera.getDate() +
              "-" +
              (fechaLigera.getMonth() + 1) +
              "-" +
              fechaLigera.getFullYear();
            /*console.log(fechaLigera.getDay());
            console.log(fechaLigera.getMonth() + 1);
            console.log(fechaLigera.getFullYear());*/
            //console.log(fechaAgrupada);
            //console.log(fechaTraducia);
            return fechaAgrupada != fechaTraducia;
          }
        })
      );
      //console.log(totalTrabajadosHoy);
      return totalTrabajadosHoy;
    },
    async timeTodate(a) {
      //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.
      /*console.log('Entra a leer fecha');
    console.log(a);
    console.log('**********************************');
    console.log(new Date(a.seconds * 1000));*/

      //console.log(a);

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      if (typeof a == "object" && a != null) {
        //console.log(a);
        let time = {
          seconds: a.seconds,
          nanoseconds: a.nanoseconds,
        };

        //const fireBaseTime = new Date(element.fechaRegistro.seconds * 1000).toUTCString()

        const fireBaseTime = new Date(time.seconds * 1000);

        return new Date(fireBaseTime).toLocaleDateString(undefined, options);
      } /**/
    },
    async fechas() {
      const fecha = new Date();
      //console.log(fecha);
      return fecha;
    },
    getColor(gestion) {
      //console.log(gestion);
      if (gestion > 60) return "green";
      else if (gestion <= 59 && gestion >= 21) return "orange";
      else if (gestion <= 20 && gestion >= 1) return "red";
      else if (gestion == 0) return "";
      //else return "red";
    },
  },
  watch: {
    respuestaSave(val) {
      val &&
        setTimeout(() => {
          this.respuestaSave = "";
        }, 2000);
    },
  },
};
</script>
