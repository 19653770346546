<template>
  <div class="text-center">
    <v-progress-circular
      color="blue-grey darken-2"
      indeterminate
      v-if="this.titularActual.data == undefined"
    >
      📌
    </v-progress-circular>

    <v-expansion-panels v-else>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="font-weight-bold"
          color="blue-grey darken-2"
        >
          Datos Adicionales
          <v-divider> </v-divider>
          <template v-slot:actions>
            <v-icon color="blue"> 📌</v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content color="blue-grey darken-2">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-show="!loadData"
                v-model="dato1"
                label="Dato 1"
                placeholder="Dense & Rounded"
                filled
                rounded
                dense
                @change="guardaDatos('dato1', dato1)"
              ></v-text-field>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >😁
              </v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-list dense>
              <v-subheader>REPORTS</v-subheader>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item v-for="(item, i) in cargaDatos" :key="i">
                  <v-list-item-content>
                    <v-list-item-title> {{ item }}- {{ i }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { FBagregaDatosDA } from "../../../../components/Cartera/investigacion/gestionCarteraInvestigacion";

export default {
  name: "gestionDatosAdicionales",
  props: ["titularActual"],
  data: () => ({
    selectedItem: 1,
    loadData: false,
    select: "",
    //items: ["Empleado", "Independiente", "Desempleado"],
    dato1: "",
    dato2: "",
    dato3: "",
    dato4: "",
    dato5: "",
    cargaDatos: null,
  }),
  mounted() {
    //console.log("Monta Datos Adicionales...");
    //console.log(this.titularActual);

    if (this.titularActual.ivDA != undefined) {
      this.cargaDatos = this.titularActual.data;
      this.updateDatos();
    }
  },
  methods: {
    async guardaDatos(key, value) {
      this.loadData = true;
      //console.log("Guarda datos...");
      //console.log(key, value);

      var nit = this.titularActual.nitCliente;
      var idC = this.titularActual.idCartera;
      var idR = this.titularActual.idRegistro;

      //const result = await FBagregaDatosDA(key, value, nit, idC, idR, "ivDA");

      //console.log(result);
      this.loadData = false;
    },
    updateDatos() {
      if (this.cargaDatos != undefined || this.cargaDatos != null) {
        //console.log(this.cargaDatos);
        //Recorrer JSON
        /*for (var x in this.cargaDatos) {
          console.log(x);

          switch (x) {
            case "1 Referencia":
              this.dato1 = this.cargaDatos[x];
              break;
            case "dato2":
              this.dato2 = this.cargaDatos[x];
              break;
            case "dato3":
              this.dato3 = this.cargaDatos[x];
              break;
            case "dato4":
              this.dato4 = this.cargaDatos[x];
              break;
            case "dato5":
              this.dato5 = this.cargaDatos[x];
              break;
            case "select":
              this.select = this.cargaDatos[x];
              break;
            default:
              break;
          }
        }*/
      }
    },
  },
};
</script>

<style scoped>
</style>
