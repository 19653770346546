import { firebase, db, storage } from "../../../firebase/config"
import moment from 'moment';

const ref = storage.ref();


export async function FBLlamadasViejitas(idUsuario) {
    try {
        const asignacionesRef = db.collection("cf-cartera").doc("Asignaciones").collection(idUsuario);
        const today = moment().startOf('day');
        const tomorrow = moment(today).add(1, 'days');
        const fiveDaysFromTomorrow = moment(tomorrow).add(5, 'days');

        const querySnapshot = await asignacionesRef.get();
        const asignaciones = [];

        querySnapshot.forEach((doc) => {
            const asignacion = doc.data();
            if (asignacion.proxToque) {
                const proxToqueDate = moment(asignacion.proxToque.toDate());
                const diasVencidos = today.diff(proxToqueDate, 'days');
                asignacion.diasVencidos = diasVencidos;

                if (diasVencidos >= 1) {
                    asignaciones.push(asignacion);
                }
            }
        });

        const asignacionesOrdenadas = asignaciones.sort((a, b) => b.diasVencidos - a.diasVencidos);
        const primeros50Registros = asignacionesOrdenadas.slice(0, 50);

        return primeros50Registros;
    } catch (error) {
        console.log(error);
    }
}



