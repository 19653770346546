import { db } from "@/firebase/config.js";
import axios from "axios";
import { FBconsultaToken } from "./tokens";


// Guardar en base de datos
async function saveMessageToDatabase(senderPhoneNumber, messaging, timestamp, contacts, doliente) {
    try {
        //console.log(senderPhoneNumber, messaging, timestamp, contacts, doliente);
        const userRef = db.collection("cf-whatsapp").doc(senderPhoneNumber);

        // Obtener el documento existente o crear uno nuevo si no existe
        const doc = await userRef.get();

        if (doc.exists) {
            //Segundo mensaje en adelante
            const existingMessages = doc.data().messages;
            existingMessages.push({ body: messaging, timestamp: timestamp, usuario: doliente });
            const updatedMessages = existingMessages.sort((a, b) => a.timestamp - b.timestamp);
            await userRef.update({ messages: updatedMessages, mensajePendiente: false });
        } else {
            //Primera vez 
            await userRef.set({ messages: [{ body: messaging, timestamp: timestamp, usuario: doliente }], usuario: contacts, mensajePendiente: false, });
        }
    } catch (error) {
        console.log('Error al guardar el mensaje en la base de datos:', error);
    }
}

export async function FBenviarMensajeWhatsapp(jsonLlega) {
    try {
        const phoneModificado = jsonLlega.telefonoSelect;

        const TOKEN = await FBconsultaToken();

        const accessToken = TOKEN[0];
        const IDENTIFICADORPHONE = TOKEN[1];

        const apiUrl = `https://graph.facebook.com/v17.0/${IDENTIFICADORPHONE}/messages`;

        let requestData;
        let mensaje;

        if (jsonLlega.plantilla) {
            // Si plantilla es una plantilla aprobada por WhatsApp
            const plantillaName = jsonLlega.plantilla;
            requestData = {
                messaging_product: "whatsapp",
                to: phoneModificado,
                type: "template",
                template: {
                    name: plantillaName,
                    language: {
                        code: "es"
                    }
                }
            };
            mensaje = plantillaName; // Usamos el nombre de la plantilla como mensaje
        } else {
            // Si no es una plantilla, enviamos un mensaje de texto normal
            requestData = {
                messaging_product: "whatsapp",
                to: phoneModificado,
                type: "text",
                text: {
                    body: jsonLlega.mensaje
                }
            };
            mensaje = jsonLlega.mensaje; // Usamos el cuerpo del mensaje como mensaje

        }

        const response = await axios.post(apiUrl, requestData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });

        const timestamp = new Date();

        //saveMessageToDatabase(phoneModificado, requestData.text ? requestData.text.body : jsonLlega.mensaje, timestamp, jsonLlega.contacto, jsonLlega.doliente);
        saveMessageToDatabase(phoneModificado, mensaje, timestamp, jsonLlega.contacto, jsonLlega.doliente);


        return true;

    } catch (error) {
        console.error("Error al enviar el mensaje:", error.response.data);
    }
}


