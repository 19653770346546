import { db, } from "@/firebase/config.js";

export async function FBconsultaEmpleados() {
    try {
        const collectionPath = "/cf-recursoHumano/RecursoHumano/Empleados/";
        const cargo = "ven"; // Cargo deseado para filtrar los empleados
        const estado = true; // Valor del estado deseado para filtrar los empleados

        // Realiza la consulta utilizando los filtros 'where'
        const querySnapshot = await db
            .collection(collectionPath)
            .where("data.cargo", "==", cargo)
            .where("data.estado", "==", estado)
            .get();

        // Obtiene los documentos que cumplen con las condiciones
        const empleados = querySnapshot.docs.map((doc) => doc.data().data);
        
        // Devuelve los empleados encontrados
        return empleados;

    } catch (error) {
        console.log(error);
    }
}