<template>
  <v-app>
    <!-- Cabecera grey lighten-4 -->
    <v-card
      v-if="usuario == null"
      color="grey lighten-4"
      flat
      height="100%"
      tile
      dense
    >
      <v-card-text>
        <!-- Contenido -->
        <v-container>
          <!-- Introducción  mb-6 -->
          <v-row class="mb-4" align="center" justify="center">
            <v-col class="text-center" cols="12" md="8">
              <h2 style="line-height: 1">
                Unifica la gestión de tus clientes y empresa en una plataforma
              </h2>
              <p>Conexión omnicanal para una comunicación fluida</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-main class="mt-n15">
                <v-overlay :value="print" :z-index="zIndex"></v-overlay>
                <router-view />
              </v-main>
            </v-col>
          </v-row>

          <!-- MÓDULOS TIENDABERACA.COM -->
          <v-row v-if="!ocultarPanel" dense>
            <v-col cols="12">
              <v-card color="grey darken-4  rounded-xl" dark>
                <div
                  class="border border-red d-flex flex-column flex-md-row justify-space-between align-center"
                >
                  <div class="flex-grow-1">
                    <v-card-title class="text-h5">
                      Clientes TiendaBeraca.com
                    </v-card-title>

                    <v-card-subtitle>
                      Si has realizado una compra, por favor pega el código de
                      pedido. Si no lo recuerdas, lo puedes encontrar en tu
                      correo o solicitarlo en nuestra línea de WhatsApp.
                    </v-card-subtitle>

                    <v-card-text class="mb-n8">
                      <v-form @submit.prevent="submitForm">
                        <v-text-field
                          v-model="codigoPedido"
                          label="Digita el código del pedido"
                          outlined
                          color="white"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn @click="consultarPedido" dark block color="red">Consultar</v-btn>
                    </v-card-actions>
                  </div>

                  <div class="ma-3" @click="abrirTiendaBeraca()">
                    <v-avatar size="125" tile>
                      <v-img
                        class="rounded-xl border border-red"
                        src="https://firebasestorage.googleapis.com/v0/b/bybabogados-138f2.appspot.com/o/st-catalogo%2Ftienda-beraca.jpeg?alt=media&token=43a75470-082b-4346-8374-b15e7ef9405a"
                      ></v-img>
                    </v-avatar>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <!-- MÓDULOS DEL SISTEMA -->
          <v-row v-if="true">
            <v-col
              cols="12"
              md="4"
              v-for="(modulo, index) in modulos"
              :key="index"
            >
              <v-card color="grey lighten-3">
                <!-- Aquí puedes agregar un icono usando v-icon antes del título -->
                <v-card-title>
                  <v-icon left>{{ modulo.icon }}</v-icon>
                  {{ modulo.titulo }}
                </v-card-title>
                <v-card-subtitle>{{ modulo.Subititulo }}</v-card-subtitle>
                <v-card-text>
                  {{ modulo.descripcion }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-app-bar v-if="usuario != null" color="grey darken-4" dark absolute>
      <div class="d-flex align-center mt-5">
        <v-img
          alt="Beraca Grupo Empresarial"
          class="shrink mr-2 mt-5"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/bybabogados-138f2.appspot.com/o/Imagenes%2Flogo%2Flogo-beraca_negro.png?alt=media&token=5926506b-2184-4929-9049-0fca44670339"
          transition="scale-transition"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <Letreto :rol="usuario[2][0].data.cargo" />

      <v-spacer></v-spacer>

      <v-btn @click="marcaSalida(), cerrarSesion()" target="_blank" text>
        <span class="mr-2">Salir</span>
        <v-icon>mdi-exit-run</v-icon>
      </v-btn>
    </v-app-bar>

    <v-row v-if="usuario != null" class="mt-15">
      <v-col>
        <v-main class="mt-n15">
          <v-overlay :value="print" :z-index="zIndex"></v-overlay>
          <router-view />
        </v-main>
      </v-col>
    </v-row>

    <br /><br /><br /><br />

    <Footer :usuario="usuario"> </Footer>

    <div id="whatsapp">
      <a href="https://wa.me/573154505001" target="_blank">
        <img
          :src="require('@/assets/logo-wasap.png')"
          width="30%"
          height="30%"
          alt="Logo de WhatsApp"
        />
      </a>
    </div>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import store from "./store";
import Letreto from "./views/Comunicacion/Letrero.vue";
import Footer from "./views/Footer/Footer.vue";
import { FBmarcaLogin } from "./components/Auth/";

export default {
  name: "App",
  components: { Letreto, Footer },
  data: () => ({
    print: false,
    zIndex: 1000,
    usuario: null,
    codigoPedido: "", // Variable para almacenar el código de pedido
    modulos: [
      {
        titulo: "Potencia tus Ventas",
        Subititulo: "B2B y B2C",
        icon: "mdi-handshake",
        descripcion:
          "Optimiza tus oportunidades de negocio con un embudo eficiente y aumenta tus ventas de manera sencilla.",
      },
      {
        titulo: "Crea tu Catálogo en Línea",
        Subititulo: "B2C",
        icon: "mdi-shopping-outline",
        descripcion:
          "Establece tu tienda virtual y gestiona tus productos o servicios en línea de forma conveniente.",
      },
      {
        titulo: "Controla tus Finanzas",
        Subititulo: "Recaudo",
        icon: "mdi-cash",
        descripcion:
          "Vende a crédito y administra tu cartera de forma eficiente.",
      },
      {
        titulo: "Impulsa tu Marketing",
        Subititulo: "Sistema Omnicanal",
        icon: "mdi-desk-lamp-on",
        descripcion:
          "Administra tu tienda virtual o catálogo en línea con nuestra solución omnicanal.",
      },
      {
        titulo: "Gestiona tu Talento",
        Subititulo: "Sistema Omnicanal",
        icon: "mdi-nature-people-outline",
        descripcion:
          "Simplifica la administración de personal, permisos y creación de nuevos colaboradores.",
      },
      {
        titulo: "Control de Flota y Conductores",
        Subititulo: "Versión 1.0 PESV",
        icon: "mdi-card",
        descripcion:
          "Supervisa tus vehículos y conductores con la versión 1.0 de nuestro módulo PESV.",
      },
      {
        titulo: "Optimiza la Producción",
        Subititulo: "Sistema Omnicanal",
        icon: "mdi-robot-industrial",
        descripcion:
          "Gestiona la producción de acuerdo a los pedidos generados en el módulo de ventas.",
      },
    ],
    ocultarPanel: false,
  }),
  created() {
    this.$nextTick(() => {
      document.title = "Sistema de Gestión Empresarial | Beraca BBG  ";
    });
  },
  methods: {
    ...mapActions(["cerrarSesion"]),
    async marcaSalida() {
      await FBmarcaLogin(store.state.user[2][0].data.cedula, 0);
    },

    validaUsuario() {
      this.usuario = store.state.user;
    },

    submitForm() {
      // Lógica para manejar el envío del formulario
      //console.log("Código de Pedido:", this.codigoPedido);
     
    },

    consultarPedido() {
      //console.log(this.codigoPedido);
      this.$router.push({
        name: "PedidosTienda",
        params: { codigoSeguimiento: this.codigoPedido },
      });

      this.ocultarPanel = true;
    },

    abrirTiendaBeraca() {
      // Abrir Tienda Beraca en una nueva pestaña o ventana
      window.open("https://tiendaberaca.com", "_blank");
    },
  },
  updated() {
    this.validaUsuario();
  },
  mounted() {
    window.onbeforeunload = () => {
      return "¿Realmente quieres realizar la acción?";
    };
  },
  watch: {
    usuario() {},
  },
};
</script>

<style scoped>
#whatsapp {
  z-index: 10000;
  position: fixed;
  bottom: 40px;
  right: -100px;
}
.border-red {
  border: 2px solid red; /* Puedes ajustar el grosor del borde aquí */
}
</style>

