<template>
  <div class="text-center">
    <v-progress-circular
      color="blue-grey darken-4"
      v-if="this.titularActual.data == undefined"
      indeterminate
      >🏰</v-progress-circular
    >
    <v-expansion-panels v-else>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="font-weight-bold"
          color="blue-grey darken-4"
        >
          Bienes e Inmuebles ( Finca Raiz )
          <v-divider> </v-divider>
          <template v-slot:actions>
            <v-icon color="blue"> 🏰 </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="blue-grey darken-4">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="select"
                :items="items"
                label="Seleccione"
                data-vv-name="select"
                required
                v-show="!loadData"
                @change="guardaDatos('select', select)"
              ></v-select>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >🤔
              </v-progress-circular>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Dato 1"
                placeholder="..."
                filled
                rounded
                dense
                v-show="!loadData"
                v-model="dato1"
                @change="guardaDatos('dato1', dato1)"
              ></v-text-field>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >🤔
              </v-progress-circular>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Dato 2"
                placeholder="..."
                filled
                rounded
                dense
                v-show="!loadData"
                v-model="dato2"
                @change="guardaDatos('dato2', dato2)"
              ></v-text-field>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >🤔
              </v-progress-circular>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Dato 3"
                placeholder="..."
                filled
                rounded
                dense
                v-show="!loadData"
                v-model="dato3"
                @change="guardaDatos('dato3', dato3)"
              ></v-text-field>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >🤔
              </v-progress-circular>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Dato 4"
                placeholder="..."
                filled
                rounded
                dense
                v-show="!loadData"
                v-model="dato4"
                @change="guardaDatos('dato4', dato4)"
              ></v-text-field>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >🤔
              </v-progress-circular>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Dato 5"
                placeholder="..."
                filled
                rounded
                dense
                v-show="!loadData"
                v-model="dato5"
                @change="guardaDatos('dato5', dato5)"
              ></v-text-field>
              <v-progress-circular
                v-show="loadData"
                color="blue-grey lighten-4"
                indeterminate
                >🤔
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { FBagregaDatosDA } from "../../../../components/Cartera/investigacion/gestionCarteraInvestigacion";
import { FBHuella } from "../../../../components/Cartera/huella/huella";

export default {
  name: "gestionDatosInmueblesFincaRaiz",
  props: ["titularActual"],
  data: () => ({
    select: "",
    items: ["Casa", "Apto", "Lote", "Comercial"],
    loadData: false,
    dato1: "",
    dato2: "",
    dato3: "",
    dato4: "",
    dato5: "",
    cargaDatos: null,
  }),
  mounted() {
    //console.log("Monta Datos Adicionales...");
    //console.log(this.titularActual);

    if (this.titularActual.ivBeIF != undefined) {
      this.cargaDatos = this.titularActual.ivBeIF;
      this.updateDatos();
    }
  },
  methods: {
    async guardaDatos(key, value) {
      this.loadData = true;
      //console.log("Guarda datos...");
      //console.log(key, value);

      var nit = this.titularActual.nitCliente;
      var idC = this.titularActual.idCartera;
      var idR = this.titularActual.idRegistro;

      const result = await FBagregaDatosDA(key, value, nit, idC, idR, "ivBeIF");

      //Huella
      //console.log("Se va a huella");
      const resultHuella = await FBHuella(
        this.titularActual,
        "Bienes e Inmuebles (Finca Raíz)"
      );

      //console.log(result);
      this.loadData = false;
    },
    updateDatos() {
      if (this.cargaDatos != undefined || this.cargaDatos != null) {
        //console.log(this.cargaDatos);
        //Recorrer JSON
        for (var x in this.cargaDatos) {
          //console.log(x);
          switch (x) {
            case "dato1":
              this.dato1 = this.cargaDatos[x];
              break;
            case "dato2":
              this.dato2 = this.cargaDatos[x];
              break;
            case "dato3":
              this.dato3 = this.cargaDatos[x];
              break;
            case "dato4":
              this.dato4 = this.cargaDatos[x];
              break;
            case "dato5":
              this.dato5 = this.cargaDatos[x];
              break;
            case "select":
              this.select = this.cargaDatos[x];
              break;
            default:
              break;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
