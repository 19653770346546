<template>
  <v-card class="mx-auto" max-width="344">
    <v-img
      src="https://img.freepik.com/vector-gratis/ilustracion-concepto-imagenes_114360-4968.jpg?w=1480&t=st=1692281579~exp=1692282179~hmac=b59b982db5881484072ed336ce90fb47de5b8080c829f4bc7399687c34636c9e"
      height="200px"
    ></v-img>

    <v-card-title>Galeria</v-card-title>

    <v-card-subtitle>Repositorio de imágenes</v-card-subtitle>

    <v-card-actions>

      <galeria />

      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          Ingresa todas las imágenes necesarias para la diferentes acciones de mercadeo.
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Galeria from "./Galeria.vue";
export default {
  data: () => ({
    show: false,
  }),
  components: {
    Galeria,
  },
};
</script>