import { db } from "@/firebase/config.js";

export async function consultaCategoriasFB() {
    try {
        let datos;
        const docRef = db.collection("recursos").doc("catalogo");

        const doc = await docRef.get();

        if (doc.exists) {
            //console.log("Document data:", doc.data());
            datos = doc.data();
        } else {
            //console.log("No such document!");
            datos = null;
        }

        return [true, datos];
    } catch (error) {
        //console.log(error);
        return [false, error];
    }
}


