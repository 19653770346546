<template>
  <v-container>
    <!-- INICIO SECCIÓN EJECUTIVOS -->

    <v-row>
      <v-col class="d-flex" cols="12" sm="6">
        <v-select
          v-model="ejecutivoSeleccionado"
          :items="items01"
          label="Ejecutivos Activos"
        ></v-select>
      </v-col>
      <v-col>
        <v-btn
          elevation="2"
          class="pa-3"
          :disabled="progresoCalculoRespetables"
          @click="calculaRespetable"
        >
          Calcular Respetables
          <v-progress-circular
            v-if="progresoCalculoRespetables"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          elevation="2"
          class="pa-3"
          :disabled="true"
          @click="accionmasiva"
        >
          Acción Masiva
          <v-progress-circular
            v-if="progresoCalculoRespetables"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>

    <!-- INICIO PANELES -->

    <v-row>
      <v-col cols="4" sm="4">
        <v-card
          :class="
            carteraLoadSinMonto.length > 1
              ? 'pa-1 red lighten-4'
              : 'pa-1 blue lighten-5'
          "
        >
          <v-card-text class="pb-0">
            Total Posible Respetables
            <p class="pa-3 font-weight-black">
              {{ totalRespetablesA }}
            </p>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn
              v-if="totalRespetablesA > 0"
              text
              color="blue-grey darken-1"
              @click="() => verPanel('reSinMonto')"
              :disabled="true"
            >
              Detalles
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4" sm="4">
        <v-card
          :class="
            carteraLoadSinCedula.length > 1
              ? 'pa-1 red lighten-4'
              : 'pa-1 blue lighten-5'
          "
        >
          <v-card-text class="pb-0">
            Total Respetables Finales
            <p class="pa-3 font-weight-black">
              {{ posiblesRespetables.length }} -
              {{ posiblesRespetablesModificar.length }}
            </p>
          </v-card-text>
          <v-card-actions class="pt-0">
            <!-- @click="() => verPanel('reSinCedula')" -->
            <v-btn
              v-if="posiblesRespetables.length > 0"
              text
              color="blue-grey darken-1"
              @click="ejecutaRespetables"
            >
              Modificar Base
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="4" sm="4">
        <v-card
          :class="
            carteraLoad.length > 1
              ? 'pa-1 red lighten-4'
              : 'pa-1 blue lighten-5'
          "
        >
          <v-card-text class="pb-0">
            Total Registros Disponibles 0 Cartera Seleccionada
            <p class="pa-3 font-weight-black text-decoration-line-through">
              {{ carteraLoad.length }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="pa-3 mt-5"></v-divider>
    <!-- INICIO RESETEO DE TITULARES-->
    <p>
      Proceso Re-asignación de Cartera. Se debe resetear todos los demás
      titulares para que se les quite el ejecutivo anteriormente asignado y
      permita reasignarlo.
    </p>

    <v-row>
      <v-col>
        <v-btn @click="reseteoTitulares" block>Resetear Titulares</v-btn>
      </v-col>
    </v-row>

    <!-- INICIO RESETEO -->
    <v-divider class="pa-3 mt-5"></v-divider>
    <p>
      Proceso Re-asignación de Cartera. Por favor consultar la documentación
      para conocer los filtros. Hacer mejoras para la 2º versión.
    </p>

    <!-- INICIO DESPLEGABLES CLIENTE AND CARTERA -->

    <v-row align="center" class="text-center">
      <v-col cols="4">
        <v-select
          v-model="selectCliente"
          :hint="`Cliente seleccionado: ${selectCliente.nombre}, Nit.:${selectCliente.nit}`"
          :items="items"
          item-text="nombre"
          item-value="nit"
          label="Despliegue..."
          persistent-hint
          return-object
          single-line
          @change="cargaCarterasDisponibles(selectCliente.nit)"
        ></v-select>
      </v-col>

      <v-col cols="4">
        <v-select
          v-model="selectCarteras"
          :hint="`Cliente seleccionado: ${
            selectCarteras.nombre
          }, Código: ${recorteTexto(selectCarteras.id)}`"
          :items="itemsCarteras"
          item-text="nombre"
          item-value="id"
          label="Despliegue..."
          persistent-hint
          return-object
          single-line
          @change="traerTitulares()"
        ></v-select>
      </v-col>
      <v-col cols="4">
        Calcular asignación...
        <!--v-if="subirOk" -->

        <v-btn
          :loading="loading"
          color="blue-grey"
          class="ma-2 white--text"
          fab
          @click="procesarBase()"
        >
          <v-icon dark> mdi-database-search-outline </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- INICIO DOS PANELES TOTAL TITULARES Y TOTAL EJECUTIVOS -->
    <v-row>
      <v-col>
        <v-card
          color="cyan lighten-1"
          class="mx-auto"
          align="center"
          justify="end"
        >
          <v-card-text>
            <div>Información Básica Cartera Seleccionada</div>
            <p class="text-h4 text--primary">
              Cliente {{ selectCliente.nombre }}
            </p>
            <p>Cartera seleccionada {{ selectCarteras.nombre }}</p>
            <div class="text--primary">
              TOTAL TITULARES <br />
              <h2>{{ totalTitularesCarteraSeleccionada }}</h2>
              <h4>{{ totalMonto }}</h4>
            </div>
          </v-card-text>
          <v-card-actions>
            <!--
        <v-btn text color="deep-purple accent-4"> Learn More </v-btn>
        -->
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          color="light-blue lighten-1"
          class="mx-auto"
          align="center"
          justify="end"
        >
          <v-card-text>
            <div>Información Básica Equipo de trabajo</div>

            <div class="text--primary pa-3">
              <p class="text-h4 text--primary">
                Ejecutivos Cartera Morosa Activos {{ listadoEmpleados.length }}
              </p>
              <p>Ejecutivos Procesados {{ arrayFinalAsesores.length }}.</p>
              <p>Total titualres asignados: {{ this.totalesAsignadosSumatoria }}</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <!--
        <v-btn text color="deep-purple accent-4"> Learn More </v-btn>
        -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-spacer class="mt-5"></v-spacer>

    <v-divider></v-divider>

    <!-- INICIO ASIGNACION DISPONIBLE -->

    <v-spacer class="mt-5"></v-spacer>
    <!-- tabla que muestra los resultados de la depuración -->
    <datatable
      v-if="verTablaExportar"
      :title="'Panel: ' + nombrePanel"
      :columns="tableColumns1"
      :rows="comodinDatosTabla"
      :perPage="[5, 10, 20, 30, 50]"
      locale="es"
    />

    <!-- Botón para asignar a asesores -->
    <!--
    <v-row>
      <v-col>
        < ! - - v-if="subirOk" - - >
        <v-btn
          :loading="loading"
          color="blue-grey"
          class="ma-2 white--text"
          fab
          @click="procesarBase()"
        >
          <v-icon dark> mdi-cloud-upload </v-icon>
        </v-btn>

        <v-overlay :absolute="true" :value="avancesLoadCartera"> </v-overlay>

        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          v-show="avancesLoadCartera"
        ></v-progress-circular>
      </v-col>
    </v-row>
    -->

    <v-data-table
      :headers="headersCarteras"
      :items="arrayFinalAsesores"
      :items-per-page="5"
      class="elevation-1"
    >

      <template v-slot:items>
        {{ items.Cartera }}
      </template>
    </v-data-table>

    <!-- Boton Subir base al servidor -->

    <v-row class="text-center">
      <v-col cols="12">
        <v-btn
          :loading="loading"
          color="blue-grey"
          class="ma-2 white--text"
          @click="asignarCarteraServidor()"
        >
          <v-icon class="pa-2" dark> mdi-cloud-upload </v-icon>
          Asignar Cartera - {{ this.respuestaAsignacion }}
        </v-btn>

        <v-overlay :absolute="true" :value="avancesLoadCartera"> </v-overlay>

        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          v-show="avancesLoadCartera"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-overlay :absolute="true" :value="avancesLoadCartera"> </v-overlay>

    <v-divider class="pa-3 mt-5"></v-divider>
    <p>
      Proceso Validación asignación de ejecutivo x por titular activos. Este
      proceso se realiza en caso que existan titulares sin ejecutivos activos,
      se reasignan.
    </p>

    <v-row align="center" class="text-center">
      <v-col cols="4">
        <v-select
          v-model="selectCliente"
          :hint="`Cliente seleccionado: ${selectCliente.nombre}, Nit.:${selectCliente.nit}`"
          :items="items"
          item-text="nombre"
          item-value="nit"
          label="Despliegue..."
          persistent-hint
          return-object
          single-line
          @change="cargaCarterasDisponibles(selectCliente.nit)"
        ></v-select>
      </v-col>

      <v-col cols="4">
        <v-select
          v-model="selectCarteras"
          :hint="`Cliente seleccionado: ${
            selectCarteras.nombre
          }, Código: ${recorteTexto(selectCarteras.id)}`"
          :items="itemsCarteras"
          item-text="nombre"
          item-value="id"
          label="Despliegue..."
          persistent-hint
          return-object
          single-line
          @change="traerTitularesSinEjecutivos()"
        ></v-select>
      </v-col>
      <v-col cols="4">
        Otros procesos
        <!--v-if="subirOk" -->

        <v-btn
          :loading="loading"
          color="blue-grey"
          class="ma-2 white--text"
          fab
          @click="procesarBase()"
          disabled
        >
          <v-icon dark> mdi-database-search-outline </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from "vue-materialize-datatable";
import {
  FBListadoClientesActivos,
  FBListadoCarteraClientesActivos,
} from "../../Cartera/verClientes";
import {
  FBtitularesCarteraSeleccionada,
  FBasignarCartera,
  FBcontadorProximosRespetables,
  FBcalculoRespetables,
  FBactualizarRespetables,
  FBaccionesMasivasModificarDatos,
  FBtitularesPendientes,
  FBresetTitualres,
} from "../../Cartera/reAsignarCartera";
import { FBlistadoEmpleadoFiltroCargo } from "../../RecursoHumano/listadoEmpleados";

export default {
  name: "reasignacarterafirebase",
  data: () => ({
    respuestaAsignacion: "",
    totalCarteraParaAsesores: 0,
    totalTitularesCarteraSeleccionada: 0,
    nuevoTitularesCarteraSeleccionada: [],
    nombrePanel: "",
    verTablaExportar: false,
    tableColumns1: [
      {
        label: "Titular",
        field: "Nombre",
        numeric: false,
        html: false,
      },
      {
        label: "Cédula",
        field: "Cedula",
        numeric: false,
        html: false,
      },
      {
        label: "Deuda",
        field: "Monto",
        numeric: false,
        html: false,
      },
      {
        label: "Cuenta",
        field: "Cuenta",
        numeric: false,
        html: false,
      },
    ],
    loadinTable: false,
    headersCarteras: [
      {
        text: "Asesor",
        value: "Asesor.data.empleado",
      },
      { text: "Asignados", value: "totalAsignados" },
      { text: "Total", value: "TOTAL" },
      //{ text: "Titulares", value: "Cartera" },
    ],
    headers: [
      /*{
        text: "Dessert (100g serving)",
        align: "start",
        sortable: false,
        value: "name",
      },*/
      { text: "Titular", value: "Nombre" },
      { text: "Cédula", value: "Cedula" },
      { text: "Cuenta", value: "Cuenta" },
      { text: "Dirección", value: "Direccion" },
      { text: "1 Telefono", value: "Telefono 1" },
      { text: "2 Telefono", value: "Telefono 2" },
      { text: "Correo", value: "Correo" },
      { text: "Deuda", value: "Monto" },
      { text: "# Cuotas", value: "Numero de cuotas" },
      { text: "Valor Cuota", value: "Valor de cuotas" },
      { text: "Último Pago", value: "Fecha ultimo pago" },
      { text: "1 Referencia", value: "1 Referencia" },
      { text: "1R Parentesco", value: "1R Parentesco" },
      { text: "1R Telefono", value: "1R Telefono" },
      { text: "2 Referencia", value: "2 Referencia" },
      { text: "2R Parentesco", value: "2R Parentesco" },
      { text: "2R Telefono", value: "2R Telefono" },
      { text: "3 Referencia", value: "3 Referencia" },
      { text: "3R Parentesco", value: "3R Parentesco" },
      { text: "3R Telefono", value: "3R Telefono" },
      { text: "Producto", value: "Producto" },
      { text: "Asesor", value: "Asesor" },
    ],
    carteraLoad: [], //Procesado no Repetidos
    carteraLoadRep01: [], //Procesado 01 Repetidos por cedula
    carteraLoadRep02: [], //Procesado 02 Repetidos por monto
    carteraLoadSinMonto: [], //Procesado 01 sin monto
    carteraLoadSinCedula: [], //Procesado 01 sin cedula
    comodinDatosTabla: [],
    totalRegistros: 0,
    totalMonto: 0,
    totalMontoTrabajo: 0,
    subirOk: false,
    loading: false,
    selectCliente: { nombre: "-", nit: "-" },
    selectCarteras: { nombre: "-", nit: "-" },
    items: [{ nombre: "", nit: "" }],
    items01: [], //Guardar Solo correos
    items02: [], //Guardar todos los datos
    ejecutivoSeleccionado: [],
    itemsCarteras: [{ nombre: "", nit: "" }],
    arrayFinalAsesores: [],
    totalesAsignadosSumatoria: 0,
    avancesLoadCartera: false,
    totalAsesores: 0,
    resultadoCarteras: "",
    listadoEmpleados: [],
    posiblesRespetables: [],
    posiblesRespetablesModificar: [],
    totalRespetablesA: 0,
    progresoCalculoRespetables: false,
  }),
  mounted() {
    const registros = async () => {
      //console.log("Entra a buscar");
      const result = await FBListadoClientesActivos();
      //console.log(result.length);
      if (result.length != 0) {
        this.items = result;
        //Cargar Empleados
        const empleadosResult = await FBlistadoEmpleadoFiltroCargo();
        //console.log(empleadosResult[1]);

        //V3. Se crea para guardar los Ejecutivos Activos
        empleadosResult[1].forEach((element) => {
          this.items01.push(element.data.correo);
          this.items02.push({ correo: element.data.correo, id: element.id });
        });

        /*console.log(this.items01);
         console.log(empleadosResult[1].length);*/
        //Organizar los asesores de mayor puntaje a menor
        let gfg = _.orderBy(empleadosResult[1], ["puntaje"], ["desc", "asc"]);
        //console.log(gfg);
        //this.listadoEmpleados = empleadosResult[1];
        this.listadoEmpleados = gfg;
      } else {
        this.items = [];
      }
    };
    registros();
  },
  components: {
    datatable: DataTable, //tabla para exportar a excel y pdf
  },
  methods: {
    async reseteoTitulares() {
      const respuestaMasivaReseteo = await FBresetTitualres();

      console.log(respuestaMasivaReseteo);
    },
    async accionmasiva() {
      const result = await FBaccionesMasivasModificarDatos();
      console.log(result);
    },
    //Primer proceso para CONSULTAR CARTERAS SELECCIONADAS...
    async traerTitulares() {
      console.log("Inicia proceso de carga disponible");
      this.respuestaAsignacion = ""; //Limpia la respuesta anterior del servidor.
      //console.log("Consulta titulares");
      //selectCliente El Cliente
      //selectCarteras La Cartera
      const result = await FBtitularesCarteraSeleccionada(
        this.selectCliente,
        this.selectCarteras
      );

      console.log("Total a disponbiles: " + result[0].length);

      //Devulve el array con todos los TT encontrados y válidos para asignar
      if (result[0]) {
        //es true
        this.totalTitularesCarteraSeleccionada = result[1].length;
        this.nuevoTitularesCarteraSeleccionada = result[1];
        this.comodinDatosTabla = result[1];
        this.carteraLoad = result[1];

        //Vamos a recorrer el nuevo array, porque llega un array diferente
        const resultArrayNuevo = await this.reAsignaArrayServidos();
        //console.log("Llega renovado el resultado:" + this.carteraLoad);
        //console.log("Vamos a conseguir el total de la cartera que llega");
        //vaciar el total de la anterior cartera this.totalMonto
        this.totalMonto = 0;
        this.fTotalMonto();

        //console.log("Titulares");
        //console.log(result[1]);
      } else {
        this.avancesLoadCartera = false;
        this.totalTitularesCarteraSeleccionada = 0;
        this.nuevoTitularesCarteraSeleccionada = [];
      }
    },
    //Consulta 2
    async traerTitularesSinEjecutivos() {
      console.log("Validar titulares huerfanos");

      const resultTitularesPendientes = await FBtitularesPendientes(
        this.selectCliente,
        this.selectCarteras,
        this.listadoEmpleados
      );

      //console.log(result);
      if (resultTitularesPendientes == true) {
        console.log("Se ha logrado el proceso");
      } else {
        console.log("Existe algún error. ");
      }
    },
    async calculaRespetable() {
      console.log("Calcula Respetables");
      // items = a total de clientes activos
      //console.log(this.items);

      this.progresoCalculoRespetables = true;

      let ejecutivoSeleccionado = _.find(this.items02, (persona) => {
        return persona.correo == this.ejecutivoSeleccionado;
      });

      //Enviar el ejecutivo a consultar
      const respuestaCalculo = await FBcontadorProximosRespetables(
        ejecutivoSeleccionado,
        this.items //carteras
      );

      console.log(respuestaCalculo);

      if (respuestaCalculo[0] != undefined) {
        //console.log("Hay Registros");
        //console.log(respuestaCalculo);
        //console.log("Total Respetables: ", respuestaCalculo.length);

        this.totalRespetablesA = respuestaCalculo[0].length;
        //console.log("Total Respetables: ", this.totalRespetablesA);
        //console.log("///********////");

        this.registrosfaltantesRespetable(
          respuestaCalculo[0],
          ejecutivoSeleccionado
        );

        console.log("Finaliza");
        this.progresoCalculoRespetables = false;
      } else {
        console.log("No hay registros");
        this.progresoCalculoRespetables = false;
      }
    },
    async ejecutaRespetables() {
      console.log("---GRABAR RESPETABLE---");
      this.progresoCalculoRespetables = true;
      //Bolsa de Titulares Respetables...
      //console.log(this.posiblesRespetables);

      let ejecutivoSeleccionado = _.find(this.items02, (persona) => {
        return persona.correo == this.ejecutivoSeleccionado;
      });

      const result = await FBactualizarRespetables(
        this.posiblesRespetables,
        ejecutivoSeleccionado,
        this.posiblesRespetablesModificar
      );

      this.progresoCalculoRespetables = false;
      console.log(result);
    },
    async registrosfaltantesRespetable(totalA, asesor) {
      //console.log(asesor);

      //Calcula los respetables finales...
      const calcularRespetables = await FBcalculoRespetables(totalA, asesor);

      console.log("Resultado Calculo Respetables");
      console.log(calcularRespetables);
      this.posiblesRespetables = calcularRespetables[0];
      this.posiblesRespetablesModificar = calcularRespetables[1];
    },
    recorteTexto(texto) {
      var cadena1 = String(texto);
      var cadena2 = cadena1.slice(-10);
      //console.log(cadena2);
      return cadena2;
    },
    async cargaCarterasDisponibles(nit) {
      //console.log("Carga nuevas carteras");
      this.respuestaAsignacion = ""; //Limpia la respuesta anterior del servidor.
      //console.log(nit);
      const rest = await FBListadoCarteraClientesActivos(nit);

      if (rest) {
        //console.log(rest);
        this.itemsCarteras = rest;
      } else {
        this.itemsCarteras = [{ nombre: "", nit: "" }];
        this.selectCarteras = {};
        //console.log("No existen registros...");
      }
    },
    onLoadFile(e) {
      //console.log(e);
      //Procesa el CSV

      if (e !== null) {
        this.$papa.parse(e, {
          header: true,
          download: true,
          dynamicTyping: true,
          complete: this.onComplete,
        });
      } else {
        //Limpiamos porque se va a cargar otro archivo
        this.carteraLoad = []; //Procesado no Repetidos
        this.carteraLoadRep01 = []; //Procesado 01 Repetidos por cedula
        this.carteraLoadRep02 = []; //Procesado 02 Repetidos por monto
        this.carteraLoadSinMonto = []; //Procesado 01 sin monto
        this.carteraLoadSinCedula = []; //Procesado 01 sin cedula
        this.comodinDatosTabla = []; //Comidin datos tabla dinámica
        this.verTablaExportar = false; //Ver tabla de datos dinámicos para exportar
        this.totalRegistros = 0;
        this.totalMonto = 0;
        this.arrayFinalAsesores = []; //Muestra la cartera asignada por asesores.
        this.totalesAsignadosSumatoria = 0;
      }
    },
    async onComplete(results) {
      //Competa la operación de carga del archivo con sus registros.
      //console.log("onComplete");
      //console.log(results);//viene con todos los campos del .CSV
      const datosIniciales = results.data;
      //console.log(cases);
      //console.log(cases.length);

      //this.carteraLoad = datosIniciales;
      //this.totalRegistros = datosIniciales.length;

      //const duplicateElements = this.procesamientoData(datosIniciales);
      //console.log(duplicateElements);
      this.loadinTable = true; //Activa la barra de cargar de la tabla
      this.subirOk = true; //Muestra el botón
      this.loading = true; //Botñon de carga
      //console.log("Procesando...");
      //console.log("Depurando CSV...");
      const resultadoDataInicial = await this.procesamientoData(datosIniciales);
      //console.log("Proceso terminado ProcesamientoData");
      //console.log(resultado);
      if (resultadoDataInicial) {
        //console.log("Inicia proceso de depurar Repetidos");

        const resultadoRepetidos = await this.procesandoRepetidos();

        if (resultadoRepetidos) {
          //console.log("Inicia proceso de total de la cartera");

          const resultadoMonto = await this.fTotalMonto();

          if (resultadoMonto) {
            //console.log("Proceso terminado");
            //console.log(this.carteraLoad);
            this.loadinTable = false; //Desactiva la barra de carga
            this.subirOk = true; //Activa el botón de cargar base
            this.loading = false; //Botñon de carga
          }
        }
      }
    },
    async procesamientoData(array) {
      //console.log("Entrando a ProcesamientoData");
      //console.log(array); //LLegan todos los campos del .CSV
      //Necesitamos validar cuantos hay repetidos...
      //Pilas, debe ser por cedula, pèro teniendo en cuento que si es un monto diferente puede ser que tenga varios productos.

      var hash = Object.create(null);

      array.some((a) => {
        //console.log(a);
        //console.log(a.Cedula && (hash[a.Cedula] || !(hash[a.Cedula] = true)));
        if (a.Cedula && (hash[a.Cedula] || !(hash[a.Cedula] = true))) {
          //console.log("Repetido");
          //console.log(hash);
          //console.log(a.Cedula, a.Monto);

          this.carteraLoadRep01.push(a); //Agrega elemento repetido pero fata procesarlo por monto
        } else {
          //console.log("No Repetido");
          //console.log(hash); //{80852033: true, 80852034: true}
          //console.log(a); //El objeto actual

          if (a.Nombre !== null) {
            // Si la casilla de nombre es diferente a null entra
            if (
              a.Cedula === 0 ||
              a.Cedula === undefined ||
              a.Cedula === "" ||
              a.Cedula === null
            ) {
              this.carteraLoadSinCedula.push(a);
            } else {
              if (
                a.Monto === 0 ||
                a.Monto === undefined ||
                a.Monto === "0" ||
                a.Monto === "" ||
                a.Monto === null
              ) {
                this.carteraLoadSinMonto.push(a); //Identifica al que está sin monto
              } else {
                this.carteraLoad.push(a); //Agrega elemento no repetido y con Monto y con Cedula
              }
            }
          }
        }
      });

      return true;
    },
    async procesandoRepetidos() {
      /* console.log("Entra a validar los repetidos");
      console.log(this.carteraLoadRep01);
      console.log(this.carteraLoad.length);
      console.log(this.carteraLoadRep01.length);
      console.log(this.carteraLoad);*/
      let nuevoObjetoRepetidos = [];
      let nuevoObjetoLimpio = [];
      let totalRegistroRecibidos =
        this.carteraLoad.length + this.carteraLoadRep01.length;

      //-------------ELIMINA Duplicados del JSON de Duplicados----------------------------------//

      const resultDuplicadosIniciales = _.uniqBy(this.carteraLoadRep01, (e) => {
        return e.Cedula;
      });

      //console.log("Duplicados Iniciales Limpios");
      //console.log(totalRegistroRecibidos);
      //console.log(resultDuplicadosIniciales);
      //console.log(resultDuplicadosIniciales.length);

      var uniqs = _.filter(this.carteraLoad, (a) => {
        //console.log("a");
        //console.log(a.Cedula);

        //Recorrer los repetidos y los agrega al array de repetidos para limpiar.
        for (let index = 0; index < this.carteraLoadRep01.length; index++) {
          if (a.Cedula === this.carteraLoadRep01[index].Cedula) {
            this.carteraLoadRep01.push(a); //Almacenaria todos los repetidos con los iniciales.
            return a.Cedula, a.Nombre;
          }
        }
      });
      //console.log("Uniqs");
      //console.log(uniqs); // [ 1, 2, 5, 6 ]

      //-----------------------------2--------------------------------------------//
      let contenoRecorrido = 0;
      let cajonA = [];
      let resultado = [];

      var uniqs2 = _.find(this.carteraLoadRep01, (a) => {
        for (let index = 0; index < this.carteraLoadRep01.length; index++) {
          //const element = array[index];
          if (contenoRecorrido < this.carteraLoadRep01.length) {
            if (a.Cedula === this.carteraLoadRep01[index].Cedula) {
              cajonA.push(a.Cedula);
              contenoRecorrido++;
            }
          } else {
            //console.log("Terminó");
            index = this.carteraLoadRep01.length;
            return cajonA;
          }
          //console.log(index, contenoRecorrido);
          //return a.Cedula === this.carteraLoadRep01[index].Cedula;
        }

        //console.log("Termina ciclo");
        //console.log(cajonA); // Resultado de los repetidos por grupos
        resultado.push(cajonA);

        //-----Continua para seguir trabajando el array provisional
        //limpiar repetidos por grupo
        const resultadoFinal = _.uniqBy(resultado, (e) => {
          return e.Cedula;
        });
        //console.log("Resultado Final");
        //console.log(resultadoFinal);
        resultado = []; //Vaciar el array
      });
      //Finaliza uniqs2

      //console.log("Finaliza uniqs2");
      //console.log(uniqs2);

      //Eliminar los que ya se filtraron
      const limpiezaGeneral = _.uniqBy(this.carteraLoadRep01, (e) => {
        return e.Monto;
      });

      this.carteraLoadRep01 = limpiezaGeneral;

      let nuevoTotalTitulares = this.carteraLoad.length;
      let finalRepetidos = [];

      //Necesito recorrer los titulares para saber si en los repetidos hay duplicados por monto.
      _.find(this.carteraLoadRep01, (a) => {
        for (let index = 0; index < nuevoTotalTitulares; index++) {
          if (a.Cedula === this.carteraLoad[index].Cedula) {
            //console.log("Existe: " + a.Cedula);
            if (a.Monto === this.carteraLoad[index].Monto) {
              //console.log("Mismo monto: " + a.Monto);
            } else {
              //console.log("Monto diferente: " + a.Monto);
              finalRepetidos.push(a);
            }
          } else {
            //console.log("No existe" + a.Cedula);
          }
        }
        //console.log(a.Cedula);
      });

      //console.log(finalRepetidos);
      //console.log(finalRepetidos.length);

      this.carteraLoadRep02 = finalRepetidos;
      //Se unen los dos arreglos, el original sin repetidos y el depurado de repetidos.
      this.carteraLoad = this.carteraLoad.concat(finalRepetidos);
      //console.log(this.carteraLoad);
      //////////////////------FINALIZA-----////////////////////
      return true;
    },
    async fTotalMonto() {
      //carteraLoad;

      for (var attributename in this.carteraLoad) {
        /*console.log(
          attributename + ": " + this.carteraLoad[attributename].Monto
        );*/

        if (this.carteraLoad[attributename].Monto !== undefined) {
          this.totalMonto =
            this.totalMonto + this.carteraLoad[attributename].Monto;
        }
      }

      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      this.totalMontoTrabajo = this.totalMonto;
      const montoFinal = formatter.format(this.totalMonto);
      this.totalMonto = montoFinal;
      //console.log(this.totalMonto);

      return true;
    },
    row_classes(item) {
      //Para mirar el tema de colores en rangos por campos
      //console.log(item);
      if (item.Monto <= 500000) {
        return "orange lighten-5"; //can also return multiple classes e.g ["orange","disabled"]
      } else if (item.Monto <= 1000000) {
        return "orange lighten-4"; //can also return multiple classes e.g ["orange","disabled"]
      } else if (item.Monto <= 1500000) {
        return "orange lighten-3"; //can also return multiple classes e.g ["orange","disabled"]
      } else if (item.Monto <= 2000000) {
        return "orange lighten-2"; //can also return multiple classes e.g ["orange","disabled"]
      } else if (item.Monto <= 2500000) {
        return "orange lighten-1"; //can also return multiple classes e.g ["orange","disabled"]
      } else if (item.Monto <= 3000000) {
        return "orange darken-1"; //can also return multiple classes e.g ["orange","disabled"]
      } else if (item.Monto <= 3500000) {
        return "orange darken-2"; //can also return multiple classes e.g ["orange","disabled"]
      } else if (item.Monto > 3500000) {
        return "orange darken-3"; //can also return multiple classes e.g ["orange","disabled"]
      }

      //orange accent-3
      //console.log(item);
    },
    verPanel(panel) {
      //comodinDatosTabla
      switch (panel) {
        case "Finalizados":
          this.verTablaExportar = true;
          this.nombrePanel = "Finalizados";
          this.comodinDatosTabla = this.carteraLoad;
          break;
        case "reCedula":
          this.verTablaExportar = true;
          this.nombrePanel = "repetidos procesados por Cédula";
          this.comodinDatosTabla = this.carteraLoadRep01;
          break;
        case "reMonto":
          this.verTablaExportar = true;
          this.nombrePanel = "repetidos procesados por Monto";
          this.comodinDatosTabla = this.carteraLoadRep02;
          break;
        case "reSinCedula":
          this.verTablaExportar = true;
          this.nombrePanel = "procesados sin Cédula";
          this.comodinDatosTabla = this.carteraLoadSinCedula;
          break;
        case "reSinMonto":
          this.verTablaExportar = true;
          this.nombrePanel = "procesados sin Monto";
          this.comodinDatosTabla = this.carteraLoadSinMonto;
          break;

        default:
          this.verTablaExportar = true;
          this.comodinDatosTabla = [];
          break;
      }
    },
    //Dispara para controlar los procesos
    async procesarBase() {
      //console.log("Procesar Base");

      this.avancesLoadCartera = true;

      setTimeout(async () => {
        const subirBase = await this.subirBase();
        //this.avancesLoadCartera = false;

        if (subirBase) {
          //console.log("Ha terminado de procesar la base");

          this.avancesLoadCartera = false;
        } else {
          //console.log("Problemas para procesar la base");
          //this.avancesLoadCartera = false;
        }
      }, 1000);
    },
    async reAsignaArrayServidos() {
      let nuevoArrayModificado = [];
      //.log(this.carteraLoad);
      for (var i = 0; i < this.carteraLoad.length; i++) {
        //console.log("Entra a nuevo Array Modificado");
        var value = this.carteraLoad[i].data;
        value.id = this.carteraLoad[i].id;
        value.asignado = this.carteraLoad[i].asignado;
        value.timeRegistroModificado =
          this.carteraLoad[i].timeRegistroModificado;
        nuevoArrayModificado.push(value);
        //console.log(value);
      }
      this.carteraLoad = nuevoArrayModificado;
      return true;
    },
    //Hace por ahora todo el proceso, toma la cartera y la asigna a los asesores
    async subirBase() {
      //console.log("Cargar Base de datos al Servidor");
      //console.log(this.carteraLoad);

      //var monto = "$ 178.000";
      //var montoFormat = monto.replace(/[$.]/g,'');

      /*console.log(
        "Asignación de base*************************************************"
      );
      //this.totalAsesores = 10;
      console.log("Monto a trabajar:" + this.totalMontoTrabajo);*/
      //console.log("Empleados a trabajar: " + this.listadoEmpleados.length);
      //this.totalAsesores = 5;
      this.totalAsesores = this.listadoEmpleados.length + 1;
      let sumaAsesores = 1;
      let totalRegistros = this.carteraLoad.length;
      let totalMontoCartera = this.totalMontoTrabajo;
      let totalMontoCD2 = totalMontoCartera / (this.totalAsesores - 1); //(this.totalAsesores - 1)
      let promedioBase = totalMontoCartera / totalRegistros;

      //Valores variables por Asesores
      let sumaBase = 0;
      let arrayValores = []; //cartera del asesor
      let arrayComodin = []; //Para que cuando cree los próximos, se entienda cual ya está asignado
      //let arrayFinalAsesores = []; // Para guardar todos los juegos de datos por asesor
      this.arrayFinalAsesores = []; // Para guardar todos los juegos de datos por asesor
      this.totalesAsignadosSumatoria= 0;
      let totalPorAsesor = Math.round(totalMontoCD2); //Total de la cartera dividida por el total de asesores da el valor por ;

      /*console.log("totalMontoCartera: " + totalMontoCartera);
      console.log("total Asesores: " + this.totalAsesores);
      console.log("totalMontoCarteraPorAsesor: " + totalPorAsesor);
      console.log("Promedio Base: " + Math.trunc(promedioBase));*/

      //Operaciones
      // Retorna un número aleatorio entre min (incluido) y max (excluido)
      const resultadoRam = () => {
        //Nos da un valor aleatorio de toda la cartera
        return _.sample(this.carteraLoad);
      };

      while (sumaAsesores <= this.totalAsesores) {
        //Ciclo para llegar al total por Asesor

        //Hay que mirar la forma de detener el loop si el total
        //de la base disponible que queda puede llegar a ser >= a el totalPorAsesor
        //1. Recorrer el array
        //
        let sumaDiferencia = 0;
        let difBase = _.difference(this.carteraLoad, arrayComodin);
        //console.log(dif);

        for (let index = 0; index < difBase.length; index++) {
          //const element = array[index];
          sumaDiferencia = sumaDiferencia + difBase[index].Monto;
        }
        //Validamos si el total de titulares que quedan dan para continuar con el loop
        if (sumaDiferencia >= totalPorAsesor) {
          //Puede entrar a seguir llenando la proxima cartera
          /*console.error(
            "sumaDiferencia: " + sumaDiferencia,
            "totalPorAsesor: " + totalPorAsesor
          );*/
          //Iniciamos el ciclo porque los valores dan para eso.
          while (sumaBase <= totalPorAsesor) {
            let objetoRamdom = resultadoRam();

            //console.warn(objetoRamdom);
            //console.log("Ramdom: " + objetoRamdom.Cedula);

            //Array Comodin donde se guarda todos los titulares asignados
            const result = _.find(arrayComodin, (n) => {
              if (n.Cedula === objetoRamdom.Cedula) {
                //Existe

                //console.log("Array Comodin: " + arrayValores.length);
                //console.log(arrayValores);
                return objetoRamdom;
              }
            });

            //console.log("Resultado result / Comodin");
            //console.log(result);
            //console.log(result.Cedula, result.Nombre);
            //if (result.Cedula !== null || result.Cedula !== undefined)
            if (result === null || result === undefined) {
              //console.log("No existe en el Comodin");

              //Se debe entrar a mirar si existe en el arrayComid
              ///Buscar en el array de la cartera que se está creando
              const result2 = _.find(arrayValores, (n) => {
                if (n.Cedula === objetoRamdom.Cedula) {
                  //Existe
                  //console.log("Array Valores Cartera: " + arrayValores.length);
                  //console.log(arrayValores);
                  return objetoRamdom;
                }
              });
              //console.log("Resultado result2 / CarteraAsesor" + sumaAsesores);
              // console.log(result2);

              if (result2 === null || result2 === undefined) {
                if (objetoRamdom.Monto != undefined) {
                  //console.log("No existe en la cartera Asesor");
                  //No existe en el comodin y se puede agregar a la cartera del usuario
                  sumaBase = sumaBase + objetoRamdom.Monto;
                  arrayValores.push(objetoRamdom); //Array que guarda los valores por asesor
                  arrayComodin.push(objetoRamdom); //Array que guarda todos los ya asignados
                  //console.log(objetoRamdom);
                  //console.warn("No Existe: " + objetoRamdom.Cedula);
                }
              } else {
                //console.error("Existe Cartera: " + result2.Cedula);
              }
            } else {
              //console.error("Existe Comodin: " + result.Cedula);

              //Validemos si es válido seguir buscando
              if (arrayComodin.length + 1 >= this.carteraLoad.length) {
                /*console.error(
                  "Tiene la misma cantidad que el array Original.. no deberia seguir buscando"
                );*/
                return true;
                break;
              } else {
                /*console.log(
                  "Comodin es mejor a el carteraLoad. " + arrayComodin.length
                );*/
              }
            }
            //}

            //Buscar si ya existe el registro en el objeto asignado

            //console.log(this.busquedaCarteraAsesor(objetoRamdom));
          } //While 1 por monto
        } else {
          //debe para porque el loop no se detendrá
          //console.warn("SE DETIENE PORQUE NO DA LA BASE");
          sumaAsesores = this.totalAsesores;
          return true;
          break;
        }

        /*console.log(
          "/////////////////////////////////////////////////////////////"
        );
        console.log("SALE DE WHILE MONTO");

        console.log("Asesor: " + sumaAsesores);
        console.log("Total Asesor:" + sumaBase);
        console.log(arrayValores.length, " / ", arrayValores);*/

        let tituloA = "Asesor-" + sumaAsesores;
        //console.log(tituloA);
        tituloA = this.listadoEmpleados[sumaAsesores - 1];

        this.arrayFinalAsesores.push({
          Asesor: tituloA,
          Cartera: arrayValores,
          totalAsignados: arrayValores.length,
          TOTAL: sumaBase,
        });
        this.totalesAsignadosSumatoria = this.totalesAsignadosSumatoria + arrayValores.length;
        sumaAsesores++; //Suma para saber que ya se llenó la posición del asesor
        //console.log(this.arrayFinalAsesores);
        //console.log("@@@@@@@@@@@@@ Limpiando");
        //Limpiar para la siguiente cartera
        arrayValores = [];
        //sumaAsesores <= totalAsesores
        //0 <= 4
        if (sumaAsesores < this.totalAsesores - 1) {
          //Vuelve a 0 para iniciar la siguiente cartera.
          sumaBase = 0;
          //console.log(sumaAsesores, this.totalAsesores);
          //console.log("*******************RE CALCULA********************");
        } else if (sumaAsesores == this.totalAsesores - 1) {
          sumaBase = 0;
          /* console.log("Llegamos a la ultima posición");
          console.warn("Comodin: " + arrayComodin.length);
          console.warn("Cartera Total: " + this.carteraLoad.length);*/

          //Asignamos la diferencia la cual si se puede a correr
          //seria infinita, porque la sumaBase no llegaria para terminar el loop
          let dif = _.difference(this.carteraLoad, arrayComodin);
          //console.log(dif);

          for (let index = 0; index < dif.length; index++) {
            //const element = array[index];
            sumaBase = sumaBase + dif[index].Monto;
          }
          let tituloB = "Asesor-" + sumaAsesores;
          //console.log(tituloB);
          tituloB = this.listadoEmpleados[sumaAsesores - 1];

          //console.log(tituloB);

          this.arrayFinalAsesores.push({
            Asesor: tituloB,
            Cartera: dif,
            totalAsignados: dif.length,
            TOTAL: sumaBase,
          });
          this.totalesAsignadosSumatoria = this.totalesAsignadosSumatoria + dif.length;
          //console.log("FIN");
          return true;
          break;
        } else {
          //sumaBase = 0;
          //.log(arrayComodin.length);
          arrayValores = []; //cartera del asesor

          //console.log(sumaAsesores, this.totalAsesores);
          //console.log("SE HA LLENADO TODAS LAS POSICIONES DE ASESORES");
          return true;
          break;
        }
      } //While 2 por Asesor
    },
    //nuevo
    ramdomEmpleado() {},
    aproximacion(e) {
      //console.log("Llega: " + e);
      return "Pepito";
    },
    //sube al servidor
    async asignarCarteraServidor() {
      //console.log("Final");
      //Recogemos todos los datos para almacenarlos.
      //console.log(this.carteraLoad);
      //console.log(this.selectCliente, this.selectCarteras);
      this.avancesLoadCartera = true;

      const rest = await FBasignarCartera(
        this.selectCliente,
        this.selectCarteras,
        this.carteraLoad,
        this.arrayFinalAsesores
      );

      //console.log(rest);

      if (rest) {
        this.avancesLoadCartera = false; //oculta el cargador....
        this.respuestaAsignacion = "Se ha asignado con éxito";
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
</style>