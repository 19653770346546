import { db, } from "@/firebase/config.js";

export async function FBconsultaProceso(json) {
    try {
        // Suponiendo que tienes una referencia a la colección en Firestore
        const collectionRef = db.collection(`${json.departamento}/tareasExternas/listado/`);

        // Obtener los valores de seguimientoId y posicion
        const seguimientoId = json.seguimientoId;
        const posicion = json.posicion;

        // Realizar la consulta
        const querySnapshot = await collectionRef
            .where("seguimientoId", "==", seguimientoId)
            .where("posicion", "==", posicion)
            .get();

        // Verificar si se encontraron documentos que cumplen las condiciones
        if (!querySnapshot.empty) {
            // Recorrer el resultado con un bucle for...of para obtener el documento que coincidió
            for (const doc of querySnapshot.docs) {
                const documento = {documento: doc.data(), idDocumento: doc.id};
                //console.log("Documento encontrado:", documento);
                return [true, documento];
            }
        } else {
            //console.log("No se encontró ningún documento que cumpla con las condiciones.");
            return [false];
        }

    } catch (error) {
        console.log(error);
        return [false];
    }
}