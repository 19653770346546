import { db, firebase, functions } from "@/firebase/config";
import { sendMensajeGeneral } from "@/Util/twilio/";
import moment from 'moment';

// Función para crear un prospecto en Firebase
export async function FBagregaNotificacionEmail(datos) {
    try {
        console.log(datos);
        //tipoDeCobro:["Juridico", "Pre Juridico"],

        //INICIO DE PROCESO PARA GUARDAR EN BASE DE DATOS
        ///cf-cartera/Carteras/900251971/7wtlRrfZrtTPAiEpZGqB/registros/UpQApqpfinHxZqp17saN
        const collectionPathCreaProspecto = `cf-cartera/Carteras/${datos.nitCliente}/${datos.idCartera}/registros/${datos.idRegistro}`;

        // Obtener la referencia al documento
        const documentRef = db.doc(collectionPathCreaProspecto);

        // Actualizar el documento para agregar el array "notificaciones" si no existe
        await documentRef.set(
            {
                notificaciones: firebase.firestore.FieldValue.arrayUnion({
                    fechaRegistro: new Date(),
                    mensaje: datos.mensaje,
                })
            },
            { merge: true }
        );

        //Si se desea enviar un eMail de Bienvenida al usuario  ########### ----------  PASO # 2
        //Enviar un correo al usuario con la contraseña 
        const cobranzaProspecto = functions.httpsCallable("cobranzaPrejuridicoUno");

        const datosFormularioEmail = {
            correoContacto: datos.selectCorreo,
            contacto: datos.deudor,
            doliente: 'Javier Vanegas',//datos.doliente
            llamar: datos.llamar,
            dateProximoContacto: datos.date,
            etapa: datos.tipoCobro,
            gestion: datos.mensaje
        };

        //if (datos.switchEnviarBienvenida) {
        // Llama a la función de Firebase Cloud Functions para enviar el correo electrónico
        await cobranzaProspecto(datosFormularioEmail);
        //}

        //SE INACTIVA PUESTO QUE LAS PRUEBAS NO FUNCIONAN. 
        // Si se desea enviar un SMS de Bienvenida al usuario  ########### ----------  PASO # 3
        const indicativo = "+57"; // Se debe traer de manera dinámica según el país que se tomó
        const mensaje = `Etapa: ${datos.tipoCobro}. Sr(a). ${datos.deudor}. Su proceso ha iniciado. Bufete de Abogados Beraca Business Group | BeracaBG.com Saludos! hemos notificado a su correo corporativo.`;
        const telefono = datos.selectTelefono; // Toma el primer número

        // if (datos.switchEnviarBienvenidaSMS) {
        // Llama a la función de Firebase Cloud Functions para enviar el mensaje de texto
        //await sendMensajeGeneral(telefono, mensaje, indicativo);
        //}

        return true;
    } catch (error) {
        console.log(error);
    }
}