<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="red"
          fab
          small
          outlined
          class="pa-2"
        >
          <v-icon>mdi-cached</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Cambio de Cédula Titular
        </v-card-title>

        <v-card-text>
          <p style="margin-top: 10px">
            ¿Cómo funciona? El sistema ubica la cédula actual y la modifica por
            la nueva digitada en el campo presente, para ver el cambio se debe
            solicitar al sistema volver a cargar la información, sea en el
            buscador o en la próxima vez de la gestión.
          </p>

          <v-row>
            <v-text-field
              label="Nueva cédula"
              placeholder="..."
              filled
              rounded
              dense
              v-model="nuevoDato"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-btn color="error" @click="cambiarCedula()" depressed>
              {{ estadoP }}
            </v-btn>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            :disabled="btnCerrar"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FBcedulaCambio } from "../../../../components/Cartera/subProcesosTT";
import store from "../../../../store";

export default {
  name: "cambioCedula",
  props: ["datos"],
  data: () => ({
    dialog: false,
    estadoP: "Cambiar cédula",
    btnCerrar: false,
    nuevoDato: null,
    nombreUsuario:""
  }),
  mounted() {
    //console.log(store.state.user[2][0].data.empleado);
    this.nombreUsuario=store.state.user[2][0].data.empleado;
  },
  methods: {
    async cambiarCedula() {
      this.btnCerrar = true;
      this.estadoP = "Espere por favor...";

      if (this.nuevoDato != null || this.nuevoDato != "") {
        const resultado = await FBcedulaCambio(this.datos, this.nuevoDato, this.nombreUsuario);
        /*console.log("inico de cambio");
        console.log(this.datos.nitCliente);
        console.log(this.datos.idCartera);
        console.log(this.datos.idRegistro);
        console.log(this.datos.data.Cedula);
        console.log(this.datos.asignadoActual[0]);*/

        console.log(resultado);

        this.estadoP = "Se ha modificado con éxito";
        this.btnCerrar = false;
        this.nuevoDato = null;
      } else {
        this.estadoP = "El campo está vacio";
      }
    },
  },
};
</script>