import { db } from "../../firebase/config";

// Trae los roles
export async function FBtraeRoles() {
  try {
    const rolesRef = db.collection("recursos").doc("cargos");
    const doc = await rolesRef.get();

    if (doc.exists) {
      return doc.data();
    } else {
      return {};
    }
  } catch (error) {
    console.error('Error:', error);
    return {};
  }
}

export async function FBcargo(a) {
  const rolesConsulta = this.roles;

  for (const clave in rolesConsulta) {
    let valor = rolesConsulta[clave];

    if (valor === a) {
      return clave;
    }
  }

  return "Cargo desconocido";
}

