<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="850" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange lighten-2"
          text
          v-bind="attrs"
          v-on="on"
          @click="categoriasLoad()"
        >
          Crear
        </v-btn>
      </template>

      <!-- INICIA TARJETA -->
      <v-card>
        <v-card-title class="text-h5 amber bg-black">
          Agregar un Producto
        </v-card-title>

        <v-card-text v-if="!estadoProceso">
          <!-- FORMULARIO AGREGA PRODUCTO -->

          <v-form ref="form" v-model="valid" @submit.prevent="submit">
            <!-- PRODUCTO -->
            <v-row
              class="mt-5 text-center yellow lighten-5 text-no-wrap rounded-xl elevation-1"
            >
              <v-col cols="12" class="text-left"><h3>Inicio</h3></v-col>
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <!-- Nombre Producto -->
                <v-text-field
                  color="black"
                  v-model="product.nombre"
                  label="Nombre Producto"
                  filled
                  rounded
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="mt-n10 mb-n5"
              >
                <!-- Descripción -->
                <v-textarea
                  color="black"
                  v-model="product.descripcion"
                  label="Descripción Producto"
                  filled
                  rounded
                  dense
                  rows="15"
                  display="none"
                ></v-textarea>

                <v-btn @click="boldText">Bold</v-btn>
                <v-btn @click="insertEmoji">Insert Emoji</v-btn>
              </v-col>
            </v-row>
            <!-- END PRODUCTO -->

            <!-- PRECIO -->
            <v-row
              class="mt-8 text-center yellow lighten-5 text-no-wrap rounded-xl elevation-1"
            >
              <v-col cols="12" class="text-left"><h3>Precios</h3></v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Precio Base -->
                <v-text-field
                  placeholder="..."
                  filled
                  rounded
                  dense
                  color="black"
                  v-model="product.base_price"
                  label="Precio Base"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Precio Tachado -->
                <v-text-field
                  placeholder="..."
                  filled
                  rounded
                  dense
                  color="black"
                  v-model="product.base_price_tachado"
                  label="Precio Tachado"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Precio Tachado -->
                <v-text-field
                  placeholder="..."
                  filled
                  rounded
                  dense
                  color="black"
                  v-model="product.costo"
                  label="Costo Producto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n5">
                <!-- Precio Tachado -->
                <v-text-field
                  disabled
                  placeholder="..."
                  filled
                  rounded
                  dense
                  color="black"
                  v-model="product.costo"
                  label="Ganancia - Margen"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- END PRECIO -->

            <!-- Organización de Producto -->
            <v-row
              class="mt-8 text-center yellow lighten-5 text-no-wrap rounded-xl elevation-1"
            >
              <v-col cols="12" class="text-left"
                ><h3>Organización de Producto</h3></v-col
              >

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Categoria Principal -->
                <v-select
                  filled
                  rounded
                  dense
                  color="black"
                  :items="categorias"
                  item-text="nombre"
                  v-model="product.categoria"
                  label="Categoría"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Sub Categoria -->
                <v-select
                  filled
                  rounded
                  dense
                  color="black"
                  :items="filteredSubCategorias"
                  v-model="product.subCategoria"
                  label="Sub Categoría"
                  item-text="nombre"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Fabricante -->
                <v-select
                  filled
                  rounded
                  dense
                  color="black"
                  :items="fabricantes"
                  v-model="product.fabricante"
                  label="Fabricante"
                  item-text="nombre"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Tipo de Producto -->
                <v-select
                  filled
                  rounded
                  dense
                  color="black"
                  :items="tipoProducto"
                  v-model="product.tipoProducto"
                  label="Tipo"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Disponible en.. -->
                <v-select
                  filled
                  rounded
                  dense
                  color="black"
                  :items="productoDisponible"
                  v-model="product.productoDisponible"
                  label="Disponible en"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Estado Producto  -->
                <v-select
                  filled
                  rounded
                  dense
                  color="black"
                  :items="estado"
                  v-model="product.estado"
                  item-text="etiqueta"
                  item-value="valor"
                  label="Estado"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n5">
                <!-- Estado Producto  -->
                <v-select
                  filled
                  rounded
                  dense
                  color="black"
                  :items="garantias"
                  v-model="product.garantia"
                  item-text="etiqueta"
                  item-value="valor"
                  label="Garantia"
                ></v-select>
              </v-col>
            </v-row>
            <!-- END Organización de Producto -->

            <!-- Inventario -->
            <v-row
              class="mt-8 text-center yellow lighten-5 text-no-wrap rounded-xl elevation-1"
            >
              <v-col cols="12" class="text-left"><h3>Inventario</h3></v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- stock Producto -->
                <v-text-field
                  filled
                  rounded
                  dense
                  color="black"
                  v-model="product.stock"
                  label="Stock"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- SKU Producto -->
                <v-text-field
                  filled
                  rounded
                  dense
                  color="black"
                  v-model="product.sku"
                  label="SKU"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- SKU Producto -->
                <v-switch
                  class="ml-3"
                  v-model="product.seguir_vendiendo_agotado"
                  inset
                  color="amber"
                  :label="`Vender cuando esté agotado`"
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n5">
                <!-- Codigo de barras -->
                <v-text-field
                  filled
                  rounded
                  dense
                  color="black"
                  v-model="product.codigo_barras"
                  label="Código de Barras"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- END Inventario -->

            <!-- EN PROCESO 
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
              <! -- Alt Imagen - ->
              <v-text-field
                disabled
                filled
                rounded
                dense
                color="black"
                v-model="product.imageAlt"
                label="Alt Imagen"
                :value="product.nombre"
              ></v-text-field>
            </v-col>
          -->

            <!-- Variaciones -->
            <v-row
              class="mt-8 text-center yellow lighten-5 text-no-wrap rounded-xl elevation-1"
            >
              <v-col
                cols="12"
                class="d-flex justify-space-between align-items-start"
              >
                <h3>
                  Variaciones
                  <v-icon color="green darken-2" v-if="showIcon" dense>
                    mdi-check-bold
                  </v-icon>
                </h3>
                <v-btn
                  @click="addVariationGroup"
                  outlined
                  x-small
                  color="grey"
                  class="ma-2 white--text"
                >
                  Agregar Variación
                  <v-icon right dark>mdi-plus</v-icon>
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="mb-n3"
                v-for="(variationGroup, groupIndex) in product.variations"
                :key="groupIndex"
              >
                <!-- Nombre de la opción -->
                <v-col :cols="12">
                  <v-combobox
                    :items="optionNames"
                    v-model="variationGroup.name"
                    label="Nombre de la opción"
                    :menu-props="{ 'close-on-content-click': false }"
                    item-value="text"
                    item-text="text"
                    @change="updateOptionValues(groupIndex)"
                    chips
                    clearable
                    color="balck"
                    deletable-chips
                    append-icon=""
                    filled
                    rounded
                    dense
                  ></v-combobox>
                </v-col>

                <!-- Encabezados de la tabla -->
                <v-row>
                  <v-col
                    v-for="header in tableHeaders"
                    :key="header"
                    :cols="
                      header === 'Valores de la opción'
                        ? 3
                        : header === 'Precio'
                        ? 3
                        : 2
                    "
                    class="text-center bg-yellow"
                  >
                    {{ header }}
                  </v-col>
                </v-row>

                <!-- Contenido de la tabla -->
                <v-row
                  v-for="(
                    variation, variationIndex
                  ) in variationGroup.variations"
                  :key="variationIndex"
                  class="mt-n5"
                >
                  <v-col cols="3">
                    <v-combobox
                      :items="optionValues[variationGroup.name]"
                      v-model="variation.selectedValue"
                      :menu-props="{ 'close-on-content-click': false }"
                      item-value="text"
                      item-text="text"
                      chips
                      clearable
                      filled
                      rounded
                      dense
                      deletable-chips
                      append-icon=""
                      class="dynamic-width-combobox"
                    ></v-combobox>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      v-model="variation.price"
                      label="Precio"
                      prefix="$"
                      filled
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-text-field
                      v-model="variation.stock"
                      label="Stock"
                      filled
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-text-field
                      v-model="variation.barcode"
                      label="Código de barras"
                      filled
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-btn
                      @click="removeVariation(groupIndex, variationIndex)"
                      icon
                      small
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-btn
                  @click="addVariationToGroup(groupIndex)"
                  outlined
                  x-small
                  color="red"
                  class="ma-2 white--text"
                  >Añadir fila</v-btn
                >
              </v-col>

              <v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-n3">
                  <v-btn
                    v-if="
                      product.variations.length > 0 && productHasValidName()
                    "
                    @click="finishConfiguration"
                    color="yellow lighten-2"
                    small
                    block
                    >{{ textoVariacion }}</v-btn
                  >
                </v-col>
              </v-col>
            </v-row>

            <!-- END Variaciones -->

            <!-- CONTENIDO MULTIMEDIA -->
            <v-row
              class="mt-8 text-center yellow lighten-5 text-no-wrap rounded-xl elevation-1"
            >
              <v-col cols="12" class="text-left"
                ><h3>Contenido Multimedia</h3></v-col
              >
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-n3">
                <!-- Contenido Multimedia  counter -->
                <v-file-input
                  accept="image/png, image/jpeg"
                  multiple
                  color="black"
                  v-model="selectedImages"
                  label="Imagen"
                  placeholder="Sube las imágenes.."
                  prepend-icon="mdi-paperclip"
                  @change="handleImagesSelected"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="amber">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>

                <div
                  v-for="(preview, index) in imagePreviews"
                  :key="index"
                  class="d-flex align-center"
                >
                  <v-badge :content="index + 1" color="black" overlap></v-badge>
                  <v-img
                    :src="preview.img"
                    max-height="120"
                    max-width="120"
                    class="rounded-xl ml-3"
                  ></v-img>
                  <span class="ml-2">{{ preview.name }}</span>
                  <v-btn @click="removeImage(index)" small icon color="red">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <!-- END Contenido Multimedia -->
              </v-col>
            </v-row>
            <!-- END CONTENIDO MULTIMEDIA -->
            <v-divider class="mt-3"></v-divider>

            <div class="d-flex justify-center align-center" style="height: 5vh">
              <v-btn color="amber" block @click="submit" class="mt-5"
                >Añadir Producto</v-btn
              >
            </div>
          </v-form>
        </v-card-text>

        <v-card-text v-else>
          {{ proceso }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions style="background-color: black">
          <v-spacer></v-spacer>
          <v-btn color="amber" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
      
   
  
<script>
import {
  subirImageFB,
  subirRegistroFB,
} from "@/components/Mercadeo/Catalogo/crear.js";
import { consultaCategoriasFB } from "@/components/Mercadeo/Catalogo/categorias.js";

export default {
  data() {
    return {
      dialog: false,
      valid: true,
      product: {
        nombre: "",
        descripcion: "",
        base_price: null,
        categoria: "",
        tipoProducto: "Producto",
        productoDisponible: "Ambas",
        seguir_vendiendo_agotado: true,
        estado: "A",
        fabricante: "",
        imageFile: [],
        variations: [],
      },
      imagePreviews: [],
      categorias: ["Editorial Rehobot" /*...otros valores...*/],
      fabricantes: ["Rehobot" /*...otros valores...*/],
      subCategorias: ["Educación" /*...otros valores...*/],
      estado: [
        { etiqueta: "Activo", valor: "A" },
        { etiqueta: "Borrador", valor: "B" },
        { etiqueta: "No Disponible", valor: "ND" },
      ],
      garantias: [
        { etiqueta: "No aplica", valor: "No aplica" },
        { etiqueta: "1 mes", valor: "1 mes" },
        { etiqueta: "2 mes", valor: "2 mes" },
        { etiqueta: "3 mes", valor: "3 mes" },
        { etiqueta: "4 mes", valor: "4 mes" },
        { etiqueta: "5 mes", valor: "5 mes" },
        { etiqueta: "6 mes", valor: "6 mes" },
        { etiqueta: "1 año", valor: "1 año" },
      ],
      proceso: "",
      estadoProceso: false,
      tipoProducto: ["Producto", "Servicio"],
      productoDisponible: ["Tienda", "Online", "Ambas"],
      sva: true,
      //Variaciones

      optionNames: ["Tamaño", "Color", "Material", "Talla"],
      optionValues: {
        Tamaño: [
          "5 cm Diámetro",
          "7 cm Diámetro",
          "10 cm Diámetro",
          "Pequeño",
          "Mediano",
          "Grande",
          "60 x 40 cm",
          "80 x 50 cm",
          "90 x 60 cm",
          "100 x 65 cm",
          "120 x 75 cm",
          "150 x 80 cm",
          "180 x 90 cm",
          "200 x 100 cm",
        ],
        Color: [
          "Rojo",
          "Verde",
          "Azul",
          "Amarillo",
          "Negro",
          "Blanco",
          "Naranja",
          "Rosa",
          "Morado",
          "Gris",
        ],
        Material: [
          "Algodón",
          "Poliéster",
          "Lino",
          "Cobre",
          "Plástico ABS",
          "Cuero",
          "Metal",
          "Madera",
          "Vidrio",
          "Cristal",
        ],
        Talla: [
          "Pequeña",
          "Mediana",
          "Grande",
          "XL",
          "XXL",
          "Talla única",
          "S",
          "M",
          "L",
          "XS",
        ],
      },
      tableHeaders: [
        "Valores de la opción",
        "Precio",
        "Stock",
        "Código de barras",
        "Eliminar",
      ],
      //END Variaciones
      showIcon: false,
      textoVariacion: "Guardar Variaciones",
      selectedImages: [], // Esta será una lista de objetos { image: imagenSeleccionada, timestamp: Date.now() }
    };
  },

  computed: {
    filteredSubCategorias() {
      if (!this.product.categoria) return [];
      return this.subCategorias.filter(
        (subCat) => subCat.categoria === this.product.categoria
      );
    },
  },

  methods: {
    async categoriasLoad() {
      const resultCate = await consultaCategoriasFB();
      //console.log(resultCate);
      this.categorias = resultCate[1].categorias;
      this.subCategorias = resultCate[1].subcategorias;
      this.fabricantes = resultCate[1].fabricantes;
    },

    //VARIACIONES
    addVariationGroup() {
      this.product.variations.push({
        name: "",
        variations: [
          {
            selectedValue: "",
            price: "",
            stock: "",
            barcode: "",
          },
        ],
      });
    },

    addVariationToGroup(groupIndex) {
      this.product.variations[groupIndex].variations.push({
        selectedValue: "",
        price: "",
        stock: "",
        barcode: "",
      });
    },

    updateOptionValues(groupIndex) {
      this.product.variations[groupIndex].variations.forEach((variation) => {
        variation.selectedValue = ""; // reset the selected value when changing the option name
      });
    },

    removeVariation(groupIndex, variationIndex) {
      this.product.variations[groupIndex].variations.splice(variationIndex, 1);
      if (this.product.variations[groupIndex].variations.length === 0) {
        this.product.variations.splice(groupIndex, 1);
      }
    },

    finishConfiguration() {
      const finalVariations = this.product.variations.filter(
        (variationGroup) => variationGroup.name.trim() !== ""
      );

      //console.log("Configuración finalizada", finalVariations);

      this.showIcon = true;
      this.textoVariacion = "Se han guardado...";

      setTimeout(() => {
        this.showIcon = false;
        this.textoVariacion = "Guardar Variaciones";
      }, 20000); // 60000 milisegundos = 1 minuto
    },

    productHasValidName() {
      return this.product.variations.every(
        (variation) => variation.name && variation.name.trim() !== ""
      );
    },

    //END VARIACIONES

    previewFiles() {
      this.imagePreviews = [];
      for (let file of this.product.imageFile) {
        //console.log(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.imagePreviews.push({ img: e.target.result, name: file.name });
        };
      }
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.estadoProceso = true;

      this.proceso = "Procesando Solicitud";

      const resultImg = await subirImageFB(this.product);

      if (resultImg[0]) {
        this.proceso =
          "Se han guardado las imágene son éxito. Procesando Producto...";
        this.product.imagen = resultImg[1];

        setTimeout(async () => {
          //Ahora subir datos
          const resultDatos = await subirRegistroFB(this.product);

          if (resultDatos[0]) {
            //console.log(resultDatos[1]);
            this.proceso = "Se el Producto, de manera exitosa.";

            setTimeout(() => {
              this.proceso = "";
              this.estadoProceso = false;
              this.reiniciarFormulario();
            }, 3000);
          } else {
            //console.log(resultDatos[1]);
            this.proceso = "Error al crear el producto.";

            setTimeout(() => {
              this.proceso = "";
              this.estadoProceso = false;
              this.reiniciarFormulario();
            }, 3000);
          }
        }, 3000); // 3000 milisegundos equivalen a 3 segundos
      } else {
        this.proceso = "Error guardar las imágenes.";

        setTimeout(() => {
          this.proceso = "";
          this.estadoProceso = false;
          this.reiniciarFormulario();
        }, 3000);
      }
    },

    reiniciarFormulario() {
      this.product = {
        nombre: "",
        descripcion: "",
        base_price: null,
        categoria: "",
        fabricante: "",
        imageFile: null,
        variations: [],
      };
      this.imagePreviews = [];
    },

    handleImagesSelected() {
      const newPreviews = this.imagePreviews.slice(); // Clona el arreglo existente para mantener el orden.

      // Crear vistas previas para las nuevas imágenes
      for (let image of this.selectedImages) {
        if (newPreviews.findIndex((p) => p.name === image.name) === -1) {
          const reader = new FileReader();

          reader.onload = (event) => {
            newPreviews.push({
              img: event.target.result,
              name: image.name,
            });
          };

          reader.readAsDataURL(image);
        }
      }

      // Actualizar el modelo de datos del producto con las imágenes seleccionadas
      this.product.imageFile = this.selectedImages;

      this.imagePreviews = newPreviews;
    },
    removeImage(index) {
      const imageName = this.imagePreviews[index].name;
      this.imagePreviews.splice(index, 1);
      const fileIndex = this.selectedImages.findIndex(
        (img) => img.name === imageName
      );
      if (fileIndex !== -1) {
        this.selectedImages.splice(fileIndex, 1);
      }
    },
    updateImagePreviews() {
      this.imagePreviews = this.selectedImages.map((image) => {
        const reader = new FileReader();
        let imageData;

        reader.onload = (event) => {
          imageData = event.target.result;
        };

        reader.readAsDataURL(image);

        return {
          img: imageData,
          name: image.name,
        };
      });
    },
    boldText() {
      // Inserta texto en negrita en el v-textarea
      const selectedText = window.getSelection().toString();
      if (selectedText) {
        const newText = `<strong>${selectedText}</strong>`;
        this.product.descripcion =
          this.product.descripcion.replace(selectedText, newText);
      }
    },
    insertEmoji() {
      // Inserta un emoji en el v-textarea
      const emoji = "😊"; // Cambia esto al emoji que desees
      this.product.descripcion += emoji;
    },
  },
  watch: {
    "product.variations": {
      handler(newVal) {
        let lastVariation = newVal[newVal.length - 1];
        if (
          lastVariation &&
          lastVariation.name &&
          lastVariation.selectedValue
        ) {
          this.addVariation();
        }
      },
      deep: true,
    },
    selectedImages(newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        // verifica si se eliminó alguna imagen
        this.updateImagePreviews();
      }
    },
    "product.categoria"() {
      this.product.subCategoria = null;
    },
  },
};
</script>

<style>
.dynamic-width-combobox {
  width: fit-content !important;
}
</style>
  