<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent max-width="70%" scrollable dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="green darken-4"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 amber">
          Oportunidad |
          <div class="overline ml-3">Persona Natural</div>

          <v-spacer></v-spacer>

          <v-tooltip color="amber" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                @click="copiarTexto(codigoSeguimiento)"
                color="black"
                label
                outlined
                v-bind="attrs"
                v-on="on"
              >
                {{ codigoSeguimiento }}
              </v-chip>
            </template>
            <span style="color: black; align-content: center"
              >Código Seguimiento<br />
              Clic para copiar
            </span>
          </v-tooltip>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2">
            <v-col>
              Ayuda al cliente a finalizar su orden de compra acorde a las
              existencias de los aliados.
            </v-col>
          </v-row>
          <v-container>
            <v-row class="mb-6 mt-3">
              <!-- NOMBRE DEL USUARIO -->
              <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="mb-n10">
                <v-text-field
                  label="Cliente"
                  placeholder="Escribe aquí..."
                  outlined
                  color="orange"
                  v-model="todosLosDatos.contacto"
                  @change="editaCampo('contacto', todosLosDatos.contacto)"
                ></v-text-field>
              </v-col>

              <!-- LISTDO CORREOS USUARIO -->
              <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="mb-n10">
                <v-select
                  label="Correo Electrónico"
                  placeholder="Escribe aquí..."
                  outlined
                  color="orange"
                  :items="todosLosDatos.correoContacto"
                ></v-select>
              </v-col>

              <!-- CELULAR -->
              <v-col
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                class="mb-n10 custom-select-container"
              >
                <!-- TELEFONOS CONTACTO -->
                <v-select
                  :items="todosLosDatos.whatsappContacto"
                  label="Telefonos Contacto"
                  outlined
                >
                </v-select>
              </v-col>

              <!-- TELEGRAM -->
              <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="mb-n10">
                <v-text-field
                  v-model="todosLosDatos.telegram"
                  label="Telegram"
                  placeholder="Escribe aquí... @Usuario"
                  outlined
                  color="blue"
                  @change="editaCampo('telegram', todosLosDatos.telegram)"
                ></v-text-field>
              </v-col>

              <!-- CATALOGO -->
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-n10">
                <v-autocomplete
                  background-color="gray"
                  item-color="orange"
                  v-model="productosSeleccionados"
                  :items="productos"
                  :item-text="
                    (item) =>
                      `${item.data.nombre} - ${item.data.sku} - ${item.data.stock} en inventario`
                  "
                  outlined
                  chips
                  small-chips
                  label="Producto(s)"
                  multiple
                  color="orange"
                  :menu-props="{ class: 'custom-menu-color' }"
                  deletable-chips
                >
                  <template v-slot:item="{ item }">
                    <div class="d-flex align-center">
                      <div class="pa-3">
                        <!-- 
                          <v-avatar left> </v-avatar>
                        -->
                        <v-img
                          :src="item.data.imagen[0].url"
                          max-width="150px"
                        ></v-img>
                      </div>
                      <div class="flex-grow-1">
                        <div class="subtitle-1">{{ item.data.nombre }}</div>
                        <div class="caption">{{ item.data.sku }}</div>
                        <div class="caption">
                          Stock: {{ item.data.stock }} en inventario
                        </div>
                      </div>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <!-- PEDIDO INICIAL -->
            <v-row class="mt-2">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-divider color="orange"></v-divider>
                  <v-expansion-panel-header>
                    Pedido

                    <v-spacer></v-spacer>
                    Total del Pedido:
                    {{ formatPrecio(totalPedido) }}</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="mb-n10"
                      >
                        <v-data-table
                          :headers="headers"
                          :items="todosLosDatos.pedido"
                          class="elevation-1"
                          style="overflow-x: auto"
                        >
                          <template v-slot:item.url="{ item }">
                            <v-img
                              :src="item.url"
                              max-width="80"
                              max-height="80"
                            ></v-img>
                          </template>

                          <template v-slot:item.precioFinalC="{ item }">
                            {{ formatPrecio(item.precioFinalC) }}
                          </template>

                          <template v-slot:item.cantidad="{ item }">
                            <div>
                              <v-btn icon @click="decrementarCantidad(item)">
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                              {{ item.cantidad }}
                              <v-btn icon @click="incrementarCantidad(item)">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </div>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>

            <!-- TIPO DE VENTA -->
            <v-row class="mt-4">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-divider color="orange"></v-divider>
                  <v-expansion-panel-header>
                    Tipo de Venta
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="mt-2">
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n10"
                      >
                        <v-select
                          :items="tipoVenta"
                          label="Tipo de Venta"
                          outlined
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n10"
                      >
                        <v-select
                          :items="totalCutoas"
                          label="Total Cuotas"
                          outlined
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n10"
                      >
                        <v-menu
                          ref="menuPP"
                          v-model="menuPP"
                          :close-on-content-click="false"
                          :return-value.sync="datePrimerPago"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              color="orange"
                              v-model="datePrimerPago"
                              label="Fecha Pago"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="datePrimerPago"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              outlined
                              color="orange"
                              @click="menuPP = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              outlined
                              color="orange"
                              @click="$refs.menuPP.save(datePrimerPago)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n8"
                      >
                        <!-- v-model="precioVenta" -->
                        <v-text-field
                          color="orange"
                          label="Precio Venta"
                          placeholder="Sin decimales"
                          outlined
                          disabled
                          filled
                          :value="formatPrecio(totalPedido)"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n8"
                      >
                        <v-text-field
                          v-model="direccionEntrega"
                          color="orange"
                          label="Dirección Entrega"
                          placeholder="..."
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n8"
                      >
                        <v-text-field
                          v-model="gps"
                          color="orange"
                          label="Ubicación GPS"
                          placeholder="..."
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="4"
                        xl="4"
                        class="mb-n8"
                      >
                        <v-text-field
                          v-model="descuento"
                          color="orange"
                          label="Descuento en %"
                          placeholder="..."
                          outlined
                          filled
                          :disabled="precioVenta === null"
                          @change="calculaDescuento"
                        ></v-text-field>

                        <v-text-field
                          v-model="porcentajeDescuento"
                          type="text"
                          placeholder="Porcentaje de descuento"
                        />
                        <v-btn @click="aplicarDescuento">
                          Aplicar Descuento
                        </v-btn>
                        <v-btn @click="quitarDescuento">
                          Quitar Descuento
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" outlined @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FBeditarCampoCliente } from "@/components/Ventas/Tableros/editarCamposClienteB2C.js";
import { consultaCatalogo } from "@/components/Ventas/Tableros/consultaCatalogoB2C.js";
export default {
  props: ["todosLosDatos"],
  data() {
    return {
      dialog: false,
      telegram: "@",
      productos: [
        {
          data: {
            nombre: "Nombre del Producto 2",
            sku: "SKU-002",
            stock: 5,
          },
        },
      ],
      productosSeleccionados: [],
      tipificacionesVenta: [],
      telefonos: [],
      tipoVenta: ["Crédito", "Contado"],
      totalCutoas: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ],
      precioVenta: 0,
      cantidad: 0,
      direccionEntrega: "",
      gps: null,
      precioConDescuento: 0,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      descuento: 0,

      datePrimerPago: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menuPP: false,
      //modalPP: false,
      //menu2: false,

      headers: [
        { text: "Imagen", value: "url" },
        { text: "Fabricante", value: "fabricante" },
        { text: "Precio", value: "precioFinalC" },
        { text: "SKU", value: "sku" },
        { text: "Nombre", value: "nombre" },
        { text: "Cantidad", value: "cantidad", sortable: false },
        { text: "Acciones", value: "action", sortable: false },
      ],
      codigoSeguimiento: "",
      porcentajeDescuento: "", // Este campo almacena el porcentaje de descuento ingresado por el usuario.
      valoresBase: {}, // Objeto para almacenar los valores base de cada elemento de la lista.
    };
  },

  mounted() {
    this.inicilizar();
  },

  methods: {
    async inicilizar() {
      this.precioVenta = this.todosLosDatos.total;

      //Codigo seguimiento
      let firstFive = this.todosLosDatos.idConsulta.slice(0, 5); // toma desde el inicio hasta el índice 5 (sin incluirlo)
      let lastFive = this.todosLosDatos.idConsulta.slice(-5); // toma los últimos 5 caracteres

      this.codigoSeguimiento = firstFive + lastFive;

      this.productos = await consultaCatalogo();
      //console.log(this.productos);
    },

    async editaCampo(clave, valor) {
      let json = {
        clave: clave,
        valor: valor,
        id: this.todosLosDatos.idConsulta,
      };
      console.log(json);
      //const resultEdicion = await FBeditarCampoCliente(json);
      //console.log(resultEdicion);
    },

    calculaDescuento() {
      const precioNumerico = Number(this.precioVenta.replace(/[^0-9.-]+/g, ""));
      const precioVentaNumerico = parseFloat(precioNumerico);
      const descuentoNumerico = parseFloat(this.descuento) / 100; // Convertir el porcentaje a decimal

      if (!isNaN(precioVentaNumerico) && !isNaN(descuentoNumerico)) {
        // Realiza el cálculo aquí con precioVentaNumerico y descuentoNumerico
        const descuentoCalculado = precioVentaNumerico * descuentoNumerico;
        this.precioConDescuento = precioVentaNumerico - descuentoCalculado;
        console.log("Precio con descuento:", this.precioConDescuento);
        // Formateamos el precio con descuento en formato de precio con decimales
        //this.formatoPrecio();
      } else {
        console.log("Los valores no son numéricos o no son válidos.");
      }
    },

    formatPrecio(precio) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(parseFloat(precio));
    },

    eliminarProducto(producto) {
      const index = this.productos.indexOf(producto);
      if (index !== -1) {
        this.productos.splice(index, 1);
      }
    },

    async copiarTexto(textoLlega) {
      if (!navigator.clipboard) {
        console.error(
          "La API navigator.clipboard no es compatible en este navegador."
        );
        return;
      }

      let textoCopiado = textoLlega;

      try {
        await navigator.clipboard.writeText(textoCopiado);
        //console.log("Texto copiado al portapapeles: ", textoCopiado);
      } catch (error) {
        //console.error("Error al copiar el texto al portapapeles:", error);
      }
    },

    decrementarCantidad(item) {
      if (item.cantidad > 1) {
        item.cantidad--;
      }
    },

    incrementarCantidad(item) {
      item.cantidad++;
    },

    aplicarDescuento() {
      const descuento = parseFloat(this.porcentajeDescuento);
      if (!isNaN(descuento)) {
        // Calcular el descuento y actualizar los precios en los datos
        const factorDescuento = 1 - descuento / 100;
        this.todosLosDatos.pedido.forEach((item) => {
          const precioUnitario = parseFloat(item.precioFinalC);
          if (!isNaN(precioUnitario)) {
            if (!this.valoresBase[item.id]) {
              // Si aún no hemos guardado el valor base para este elemento, guárdalo ahora.
              this.valoresBase[item.id] = precioUnitario;
            }
            item.precioFinalC = (precioUnitario * factorDescuento).toFixed(2);
          }
        });
      }
    },

    quitarDescuento() {
      // Restaurar los valores base para cada elemento de la lista
      this.todosLosDatos.pedido.forEach((item) => {
        const valorBase = this.valoresBase[item.id];
        if (!isNaN(valorBase)) {
          item.precioFinalC = valorBase.toFixed(2);
        }
      });

      // Limpiar el objeto de valores base
      this.valoresBase = {};
    },
  },

  computed: {
    formattedPrecioVenta() {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(this.precioVenta);
    },

    totalPedido() {
      let total = 0;
      this.todosLosDatos.pedido.forEach((item) => {
        const cantidad = parseFloat(item.cantidad);
        const precioUnitario = parseFloat(item.precioFinalC);
        if (!isNaN(cantidad) && !isNaN(precioUnitario)) {
          total += cantidad * precioUnitario;
        }
      });
      return total;
    },
  },

  watch: {
    productosSeleccionados(newValue) {
      let totalPrecioVenta = 0;

      // Recorremos todas las posiciones de nombreProducto
      newValue.forEach((nombreProductoSeleccionado) => {
        // Utiliza el método split() para separar el string en un array por el carácter "-"
        const parts = nombreProductoSeleccionado.split("-");
        // Obtén la primera parte del array resultante, que corresponde al nombre del producto
        const nombreProducto = parts[0].trim();

        // Buscamos el objeto completo del producto en el array 'productos' por el nombre del producto
        const productoSeleccionado = this.productos.find(
          (producto) => producto.producto === nombreProducto
        );

        // Verificamos si se encontró el producto y sumamos su precio al total
        if (productoSeleccionado) {
          // Convertir el precio a número antes de sumarlo
          totalPrecioVenta += parseFloat(productoSeleccionado.precio);
        }
      });

      // Asignamos el total al campo precioVenta
      this.precioVenta = totalPrecioVenta.toString();
      this.calculaDescuento(); // Volvemos a calcular el descuento para actualizar el campo precioVenta con el descuento aplicado
    },
  },
};
</script>

<style scoped>
.custom-menu-color {
  background-color: orange;
  color: white; /* Puedes cambiar el color del texto si lo deseas */
}

/* Agrega estilos de posición */
.custom-select-container {
  position: relative;
}

.custom-button {
  position: absolute;
  top: -10px; /* Ajusta este valor para cambiar la distancia desde arriba */
  left: -10px; /* Ajusta este valor para cambiar la distancia desde la izquierda */
}
</style>