import { firebase, db, storage } from "../../firebase/config"

const ref = storage.ref();



//POR AHORA
//SE DEBE ELIMINAR MANUAL MENTE LAS BOLSAS DE LOS EJECUTIVOS Y EN LAS RESPECTIVAS CARTERAS DETECTAR EN ASESORES LOS EJECUTIVOS PARA 
//AGREGAR MANUALMENTE LA CARGA DE TITULARES A LA BOLSA....


//Carga registros a la cartera seleccionada PARA REASIGNACIÓN 
//Teniendo en cuenta los respetables.
export async function FBtitularesCarteraSeleccionada(selectCliente, selectCarteras) {
    //console.log('Titulares Cartera Seleccionada');

    //console.log(selectCliente, selectCarteras);
    //console.log(selectCliente, selectCarteras, carteraLoad);

    //Version 1 2021
    //let tipificaciones = ['Paz y Salvo v2', 'Paz y Salvo', 'Fallecido', 'Ilocalizado', 'Incobrable', 'Notificar Paz y Salvo', 'Paz y Salvo - Notificado'];

    //Version 2 2022
    let tipificaciones = ['Paz y Salvo v2', 'Paz y Salvo', 'Fallecido', 'Ilocalizado', 'Incobrable', 'Paz y Salvo - Notificado'];


    try {
        //const rutaRegistrosCartera = db.collection('cf-cartera').doc('Carteras').collection(selectCliente.nit).doc(selectCarteras.id).collection('registros');
        //Ejemplo de asignación asignarCartera.js
        //const rutaRegistrosCartera = db.collection('cf-cartera').doc('Carteras').collection(selectCliente.nit).doc(selectCarteras.id).collection('registros').where("asignadoActual", "array-contains", 0);
        //Ruta de Prueba:
        ///cf-cartera/Carteras/900/obVcuaRgT3xdtTRvThjN/registros/3QM5zgulPo9MOVgVvY4Z
        //[{ estado: false }]   -- firestore.collection('col').where('categories', 'array-contains', {id: 'abc', allNames: ['name1']})
        //const rutaRegistrosCartera = db.collection('cf-cartera').doc('Carteras').collection('900').doc('obVcuaRgT3xdtTRvThjN').collection('registros').where("respetable", "array-contains", { estado: false });
        // Los false son los ejecutivos que tienen esos registros como respetables y no harán parte de la reasignación
        //ME LO TIRÉ

        //Versión 1.4 se modifican todos los registros //Antes "respetable", "array-contains", { contador: 0, estado: false, usuario: 0 } // ahora  respetable= false
        const rutaRegistrosCartera = db.collection('cf-cartera').doc('Carteras').collection(selectCliente.nit).doc(selectCarteras.id).collection('registros').where("respetable", "!=", true);
        //const rutaRegistrosCartera = db.collection('cf-cartera').doc('Carteras').collection(selectCliente.nit).doc(selectCarteras.id).collection('registros').where("respetableActual", "array-contains",);

        const capitalNotFalseRes = await rutaRegistrosCartera.get();

        let arraySale = [];
        if (capitalNotFalseRes.empty) {
            console.log('No matching documents. 44');
            //return;
        }

        capitalNotFalseRes.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            let fusion = doc.data();
            fusion.id = doc.id;

            //console.log(fusion);

            let pazysalvo2 = doc.data().PazySalvov2;
            let observaciones = doc.data().observaciones;
            let observacionesTotal = observaciones.length;

            //console.log('Total Elementos: ', observacionesTotal);

            for (let index2 = 0; index2 < observacionesTotal; index2++) {
                //console.log('Entra: ', index2);
                const elementJuego = observaciones[index2];

                //console.log(elementJuego.efecto);
                //Localizamos si la tipificación está almenos 1 vez en el proceso.
                let aA = _.find(tipificaciones, (tipi) => {
                    //console.log('-------', elementJuego.efecto);
                    //console.log('-------');
                    return tipi == elementJuego.efecto;
                });

                //console.log(aA);

                //Filtramos cuando hay tipificación que aplica para respetable 
                //y si el respetable tiene paz y salvo no aplica para respetable.
                //&& pazysalvo2 === undefined
                if (aA != undefined) {
                    //console.warn('No aplica: ', aA);
                    //console.warn(doc.id);
                    //if (aA !== undefined) {
                    //console.log('aA: ', aA);
                    //console.log(pazysalvo2);
                    index2 = observacionesTotal + 1;

                    //respetable.push({ nit: datosCartera.nit, cartera: datosCartera.cartera, idUsuario: doc.id, usurio: doc.data(), });
                    //console.log('PARAR!!!!!!');
                } else {
                    if (index2 == (observacionesTotal - 1)) {
                        //console.log('ULTIMO registro');
                        //console.log(index2, observacionesTotal);
                        //console.error('APLICA: ', aA);
                        //console.error(doc.id);
                        //console.log(fusion);
                        arraySale.push(fusion);
                    }
                }

            }//cierre for


        });

        console.log('Termina RE Asignación');

        //console.log(arraySale);
        return [true, arraySale];
    } catch (error) {
        console.log(error);
        return [false];
    }
}

//Carga la asignación realizada por el gerente
//this.selectCliente,this.selectCarteras,this.carteraLoad,this.arrayFinalAsesores
export async function FBasignarCartera(selecCliente, selectCarteras, carteraLoad, arrayFinalAsesores) {
    //console.log(selecCliente, selectCarteras, carteraLoad, arrayFinalAsesores);
    let nombreCliente = selecCliente.nombre;//Sophia
    let nitCliente = selecCliente.nit;//902

    let idCartera = selectCarteras.id;
    let nombreCartera = selectCarteras.nombre;

    //carteraLoad Es la cartera total sin asignacion
    //La cartera asignada arrayFinalAsesores (Asesor, Cartera, Total, totalAsignados)

    try {
        //------------------------NUEVA SECCION 
        //Guardar la configuración de la cartera.total asesores / estado / nombre cartera / modificaciones.
        //Barrio guarda si el Asesor ya carga esos titulares a su bolsa
        //console.log('***************************************************************');
        let totalAsesoresdeCartera = [];
        arrayFinalAsesores.forEach(async element => {
            let idAsesor = element.Asesor.id;
            //console.log(element);

            //console.log('asesor:', idAsesor);
            //console.log('Total:', element.Cartera.TOTAL);
            //console.log('Asignados:', element.Cartera.totalAsignados);

            let AsesoresLimpios = { idAsesor: idAsesor, puntajeCartera: 0, monto: element.TOTAL, asignados: element.totalAsignados, barrio: false };
            totalAsesoresdeCartera.push(AsesoresLimpios)
            //const resultCarteraAsesores = await guardaAsesoresEnCartera.update({ asesores: arrayUnionB(AsesoresLimpios) });

        });

        //const arrayUnionB = firebase.firestore.FieldValue.arrayUnion;
        //BORRA la asignación acual Asesores
        const guardaAsesoresEnCartera = db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(idCartera);
        const resultCarteraAsesores = await guardaAsesoresEnCartera.update({ asesores: totalAsesoresdeCartera });

        //console.log('***************************************************************');
        /***  FIN NUEVA SECCION */

        //Recorremos el array que llega del asesor
        arrayFinalAsesores.forEach(element => {
            /*console.log('Asesor');
            console.log(element.Asesor.id);
            console.log(element.Cartera);*/

            let idAsesor = element.Asesor.id;

            let carteraAsesor = element.Cartera;
            //Recorremos el array de los titualres asignados
            carteraAsesor.forEach(async elementCartera => {
                //console.log(elementCartera.id);
                //console.log(elementCartera.asignado);

                //1. Agregar al array la asignación. Se trae el array que existe y se agrega el nuevo.
                // 'asignado': [{ usuario: 0, estado: false, fecha: firebase.firestore.Timestamp.now(), }],
                let nueveoelementCartera = { usuario: idAsesor, estado: true, fecha: firebase.firestore.Timestamp.now() }
                const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
                
                //Versión 2
                //var arrayNuevoLimpiar = [{ contador: 0, estado: false, usuario: 0 }];

                //Version 3
                var arrayNuevoLimpiar = false; //Pues ya no se usa el array de la versión 2

                //2. 
                ///cf-cartera/Carteras/902/4njopDRN1C50cWOEbVf5/registros/0DGytvQ0z75yunjNfkvZ
                const titularRef = db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(idCartera).collection('registros').doc(elementCartera.id);
                const result = await titularRef.update({
                    asignado: arrayUnion(nueveoelementCartera),
                    //estado: true,
                    asignadoActual: [idAsesor],
                    respetable: arrayNuevoLimpiar,
                    respetableActual: [],//
                    //docPedido: [false],//true o false para saber si se tiene el pedido o hay que solicitarlo, se desactivo por que lo hace masivo y borra los titulares que ya tienen documento. 

                })
            });

        });
        return true
    } catch (error) {
        console.log(error);
        return false
    }
}

//Consultar Cuantos registros tiene el ejecutivo seleccionado y generar separar solo 
//100 para marcarlos como respetables.
export async function FBcontadorProximosRespetables(ejecutivo, clientes,) {
    //console.log(ejecutivo.id);
    //console.log(clientes);
    console.log('Proximos Respetables');

    //function para los nit con su respectiva cartera
    const carterasActivas = await FBcarterasActivas(clientes);

    //console.log(carterasActivas.length);

    let numero = 0;
    //Version 1
    //let tipificaciones = ['Acuerdo de pago', 'Recordatorio de Pago', 'Posible Demanda', 'Iniciar Demanda', 'Demanda Radicada', 'Volver a Demandar', 'Demanda - Subsanar', 'Demanda - Subsanada', 'Demandado - Notificado Acta Reparto', 'Demandado - Notificado Auto', 'Demandado - Notificado Oficio', 'Demandado - Novedad Dep. Juridico', 'Demandado - Notificar Acta Reparto', 'Demandado - Notificar Auto', 'Demandado - Notificar Oficio', 'Demandado - Terminación Proceso', 'Demandado - Liquidación de Titulos'];

    //Version 2 mentiras me equivoqué
    //let tipificaciones = ['Paz y Paz y Salvo - Notificado', 'Paz y Salvo', 'Fallecido', 'Ilocalizado', 'Incobrable'];

    let tipificaciones = ['Acuerdo de pago', 'Recordatorio de Pago', 'Iniciar Demanda', 'Demanda Radicada', 'Volver a Demandar', 'Demanda - Subsanar', 'Demanda - Subsanar', 'Demanda - Subsanada', 'Demandado - Notificado Acta Reparto', 'Demandado - Notificado Auto', 'Demandado - Notificado Oficio', 'Demandado - Novedad Dep. Juridico', 'Demandado - Notificar Acta Reparto', 'Demandado - Notificar Auto', 'Demandado - Notificar Oficio', 'Demandado - Terminación Proceso', 'Demandado - Liquidación de Titulos', 'Posible Negociación'];

    let respetable = [];
    let noRespetable = [];//los que tienen que quedar disponibles para otra asignación


    try {
        for (let index = 0; index < carterasActivas.length; index++) {
            const datosCartera = carterasActivas[index];
            //console.log('-----------------------------------');
            //console.log(datosCartera.cartera);
            //console.log(datosCartera.nit);

            const titularesRef = db.collection('cf-cartera').doc('Carteras').collection(datosCartera.nit).doc(datosCartera.cartera).collection('registros');
            const filtroEjecutivo = titularesRef.where('asignadoActual', 'array-contains', ejecutivo.id);//.where('PazySalvov2', '!=', true);
            //const listTitulares = filtroEjecutivo

            const result1 = await filtroEjecutivo.get();

            if (result1.empty) {
                //console.log('No matching documents.');
                //return;
            }



            result1.forEach(doc => {
                //console.log('Entra');
                //console.log(doc.id);
                //console.log(doc.id, '=>', doc.data());
                //console.log(doc.data().observaciones);
                let pazysalvo2 = doc.data().PazySalvov2;
                let observaciones = doc.data().observaciones;
                let observacionesTotal = observaciones.length;


                noRespetable.push({ nit: datosCartera.nit, cartera: datosCartera.cartera, idUsuario: doc.id });

                //console.log('Total elementos: ', observacionesTotal);

                for (let index2 = 0; index2 < observacionesTotal; index2++) {
                    //console.log('Entra: ', index2);
                    const elementJuego = observaciones[index2];
                    //Localizamos si la tipificación está almenos 1 vez en el proceso.
                    let aA = _.find(tipificaciones, (tipi) => {
                        //console.log('-------', observaciones.efecto);
                        //console.log('-------');
                        //Todos los que son iguales
                        return tipi == elementJuego.efecto;
                        //Todos los que son diferentes
                        //return tipi != elementJuego.efecto;

                    });

                    //Filtramos cuando hay tipificación que aplica para respetable 
                    //y si el respetable tiene paz y salvo no aplica para respetable.
                    if (aA !== undefined && pazysalvo2 === undefined) {
                        //if (aA !== undefined) {
                        //console.log('aA: ', aA);
                        //console.log(pazysalvo2);
                        index2 = observacionesTotal + 1;
                        respetable.push({ nit: datosCartera.nit, cartera: datosCartera.cartera, idUsuario: doc.id, usurio: doc.data(), });
                        //console.log('PARAR!!!!!!');
                    }

                }//cierre for


                //Era para veirifacar cuantos registros tenia el ejecutivo

                //console.log(numero++);
            });
            //console.log('INDEX', index);
        }

        //console.log('SALE FOR ');
        //console.log(respetable.length);
        //console.log(noRespetable);

        var result = noRespetable.filter(o1 => respetable.filter(o2 => o2.idUsuario === o1.idUsuario).length === 0);

        console.log(result);

        return [respetable, result];
        //return [respetable, noRespetable];

    } catch (error) {
        console.log(error);
    }

}

export async function FBcalculoRespetables(respuestaCalculo, asesor) {
    let bolsaTitulares = respuestaCalculo;
    let topeRespetables = 250;

    //console.log(bolsaTitulares);
    //console.log('Total Posibles Respetables: ', bolsaTitulares.length);
    if (asesor.id == "1sl11rzdWQRdD9M4ZokJ" || asesor.id == "VIsP5q4ys2K8BFMBkHaO" || asesor.id == "Ybxkk8dRjmkKXrXbjUA8" || asesor.id == "o2QXKwDV5CNxjnObvYNl") {
        //console.log('Yobanni');
        //topeRespetables = 250;
    }

    try {
        //const x = [20, 30, 40, 50, 60, 70, 30, 40, 50, 60, 70, 30, 40, 50, 60, 70, 30, 40, 50, 60, 70, 30, 40, 50, 60, 70, 30, 40, 50, 60, 70, 30, 40, 50, 60, 70, 30, 40, 50, 60, 70, 30, 40, 50, 60, 70, 30, 40, 50, 60, 70,];
        const cantidadTotal = bolsaTitulares.length;

        //console.log(bolsaTitulares);
        //Desarodenar el array
        const desorden = await desardenarArray(bolsaTitulares);

        //bolsaTitulares = bolsaTitulares.sort((a, b) => { return (Math.random() - 0.5) });
        //console.log(desorden);
        //Elimina el rango de la posición 2 al 3 quiere decir [ 20, 30, 70 ]
        // v.1 bolsaTitulares.splice(topeRespetables, cantidadTotal);//Deja solo 100

        if (desorden.length > topeRespetables) {
            console.log('Es mayor que el topeResponsables');
            desorden.splice(topeRespetables, cantidadTotal);//Deja solo topeRespetables equivale a un numero
        }

        //console.log(bolsaTitulares);
        //console.log('Total Respetables: ', bolsaTitulares.length);

        //Retornamos los 100 seleccionados
        //return bolsaTitulares;

        //Ahora vamos a dejar el array que debemos quitar 
        var diferencia = bolsaTitulares.filter(x => desorden.indexOf(x) === -1);
        //console.log(diferencia);



        return [desorden, diferencia];

    } catch (error) {
        console.log(error);
    }

}

async function desardenarArray(array) {

    const n = array.length;
    const arr = new Array(n);
    const arrayFinal = [];

    for (let i = 0; i < n; i++) {
        arr[i] = i + 1;
    }

    arr.sort(() => Math.random() > 0.5 ? 1 : -1);

    for (let index = 0; index < array.length; index++) {
        //const element = array[index];
        var numero = arr[index];
        //console.log(array[numero]);
        if (array[numero] != undefined) {
            arrayFinal.push(array[numero])
        }
    }

    //console.log(arr);

    //console.log(arrayFinal);

    return arrayFinal;

}

//Arma array de nit + cartera
async function FBcarterasActivas(clientes) {
    let listadoNits = [];

    clientes.forEach(element => {
        listadoNits.push(element.nit);
    });

    try {
        let idCarterasActivas = []; //Guarda id CARTERA
        //1. Primera consulta para definir que CARTERAS están en estado TREU
        for (let index = 0; index < listadoNits.length; index++) {
            const nitActual = listadoNits[index];


            const empresaRef = db.collection('cf-cartera').doc('Carteras').collection(nitActual);
            const snapshot = await empresaRef.where('estado', '==', true).get();

            if (snapshot.empty) {
                //console.log('No matching documents.');
                //return false;
            }
            //Traemos todas las carteras. que estan estado activo.
            snapshot.forEach(async doc => {
                let idCartera = doc.id;
                idCarterasActivas.push({ nit: nitActual, cartera: idCartera });
            });
        }
        //console.log('Devuleve todos los nit con su respectivas carteras');
        return idCarterasActivas;
    } catch (error) {
        console.log(error);
    }
}


// PILAS!!!! REASIGNAR!!! --------- Actualiza la base de datos para marcarlos como respetables.
export async function FBactualizarRespetables(bolsa, ejecutivoSeleccionado, bolsaaLimpiar) {

    let respetables = bolsa;
    let respetablesALimpiar = bolsaaLimpiar;
    let ejecutivo = ejecutivoSeleccionado;
    let idEjecutivo = ejecutivo.id;


    //console.log(respetables);
    //console.log(respetablesALimpiar);
    //console.log(ejecutivo);

    //1 Problema, no es posible actualizar el array de asesores de la cartera para que cuando ingrese
    //se logre cargar, toca por ahora hacer el registro a mano. 

    var arrayAsignado = [idEjecutivo];
    var arrayNuevo = [{ contador: 0, estado: true, usuario: 0 }];
    var arrayAsignadoLimpiar = [];
    var arrayNuevoLimpiar = [{ contador: 0, estado: false, usuario: 0 }];

    try {
        //Marca como respetable
        for (let index = 0; index < respetables.length; index++) {
            if (respetables[index] != undefined) {

                //const element = respetables[index];
                let nit = respetables[index].nit;
                let cartera = respetables[index].cartera;
                let id = respetables[index].idUsuario;

                console.log(nit, cartera, id);
                ///cf-cartera/Carteras/900/obVcuaRgT3xdtTRvThjN/registros/vp4eX0WlAvn0HPkKnvHF
                const res = db.collection('cf-cartera').doc('Carteras').collection(nit).doc(cartera).collection('registros').doc(id)
                await res.update({
                    //estado: true
                    asignadoActual: arrayAsignado,
                    respetable: arrayNuevo,
                });
            }
        }

        //Liberar el titular como respetable
        for (let index1 = 0; index1 < respetablesALimpiar.length; index1++) {
            console.log(respetablesALimpiar[index1]);
            if (respetablesALimpiar[index1] != undefined) {

                //const element = respetables[index];
                let nit = respetablesALimpiar[index1].nit;
                let cartera = respetablesALimpiar[index1].cartera;
                let id = respetablesALimpiar[index1].idUsuario;

                //console.log(nit, cartera, id);
                ///cf-cartera/Carteras/900/obVcuaRgT3xdtTRvThjN/registros/vp4eX0WlAvn0HPkKnvHF
                const res2 = db.collection('cf-cartera').doc('Carteras').collection(nit).doc(cartera).collection('registros').doc(id)
                await res2.update({
                    //estado: true
                    asignadoActual: arrayAsignadoLimpiar,
                    respetable: arrayNuevoLimpiar,
                });
            }
        }


        return true;
    } catch (error) {
        console.log(error);
    }
}

//PROCESO PARA MODIFICAR DE MANERA MASIVA LOS DATOS DE LAS CARTERAS DE MANERA MANUAL
//------------OJO DELICADO -------------------- LEER BIEN
export async function FBaccionesMasivasModificarDatos() {
    console.log('Datos a modificar');

    //1. Configurar aquí los datos manualmente
    let nit = '55178395';
    let idCartera = 'MkbMgl9hFezaDts9xaJn';

    //2. Por favor llenar el array que son las cedulas a modificar. 
    const datos = await datosManuales();

    let modificados = [];
    let noModificados = [];

    try {
        //Acciones masivas manuales
        //  ---ACTUALIZAR---
        /*for (let index = 0; index < datos.length; index++) {
            const cedulaTitular = datos[index];
            //console.log(cedulaTitular);

            //1. Consultar el id de esa cédula
            const idCedula = await idRegistros(nit, idCartera, cedulaTitular);
            //console.log(idCedula);

            if (idCedula != false) {

                //console.log('Es diferente a false');

                modificados.push(cedulaTitular);

                //2. Ruta prueba
                ///cf-cartera/Carteras/902/4njopDRN1C50cWOEbVf5/registros/0DGytvQ0z75yunjNfkvZ
                ////-------------PILAS!!!!!! ACCIÖN MASIVA
                const titularRef = db.collection('cf-cartera')
                    .doc('Carteras')
                    .collection(nit)
                    .doc(idCartera)
                    .collection('registros')
                    .doc(idCedula)
                const result = await titularRef.update({
                    docPedido: true,// true es porque tiene titulo valor cargado

                })
                //console.log(result);
                //console.log(index);
            } else {
                noModificados.push(cedulaTitular);

                //console.log('No se pudo actualizar la cedula: ', cedulaTitular);
            }
        }*/

        // --- ELIMINAR UN REGISTROS---
        /*
        for (let index = 0; index < datos.length; index++) {
            const cedulaTitular = datos[index];


            //1. Consultar el id de esa cédula
            const datosTitular = await idRegistros(nit, idCartera, cedulaTitular);

            console.log(datosTitular);

            if (datosTitular != false) {
                console.log('Se encuentra datos para eliminar');
                //2. Solo NO SIEMPRE ES necesario. Puede traer cualquier dato, en este caso el id del ejecutivo
                let idCedula = datosTitular[0].idTitular;
                let asesorAsginado = datosTitular[0].asesorAsignado;

                //console.log('Es diferente a false');
                //console.log('Id a Eliminar: ', idCedula);
                //console.log('Id Asesor Asignado: ', asesorAsginado);

                //2. Ruta prueba
                ///cf-cartera/Carteras/902/4njopDRN1C50cWOEbVf5/registros/0DGytvQ0z75yunjNfkvZ
                ////-------------PILAS!!!!!! ACCIÖN MASIVA
                //Eliminar registro de la cartera
                const titularRef = db.collection('cf-cartera')
                    .doc('Carteras')
                    .collection(nit)
                    .doc(idCartera)
                    .collection('registros')
                    .doc(idCedula)
                const result = await titularRef.delete();

                //Eliminar registro de la bolsa Ejecutivo
                //1. Primero se obtiene el documento a eliminar, de la bolsa del ejecutivo
                //console.log(cedulaTitular);
                let numeroA = Number(cedulaTitular);

                const idRegistroBolsaEje = db.collection('cf-cartera').doc('Asignaciones').collection(asesorAsginado);
                const doc = await idRegistroBolsaEje.where('Cedula', '==', numeroA).get();//

                //console.log(doc);

                if (doc.empty) {
                    console.log('No se encuentra documento:', numeroA);
                    //return;
                }

                doc.forEach(async doc2 => {
                    console.log('Entra a eliminar Registro en Bolsa Ejecutivo');
                    //console.log(doc.id, '=>', doc.data());
                    //Para eliminar el registro de la bolsa del ejecutivo
                    const idRegistroBolsaEje2 = db.collection('cf-cartera')
                        .doc('Asignaciones')
                        .collection(asesorAsginado)
                        .doc(doc2.id);
                    //const result2 = await idRegistroBolsaEje2.get();

                    const resultBolsa = await idRegistroBolsaEje2.delete();

                    //console.log(resultBolsa);

                    //console.log(result);
                    //console.log(index);
                });

                //Fin de eliminar carteras 

                //1. Agrega cédula
                modificados.push(cedulaTitular);

            } else {
                noModificados.push(cedulaTitular);

                //console.log('No se pudo actualizar la cedula: ', cedulaTitular);
            }
        }
        */

        console.log('---NO PROCESADAS---');
        console.log(noModificados);

        console.log('---PROCESADAS---');
        console.log(modificados);

        return 'ok';

    } catch (error) {
        console.log(error);
    }
}

async function datosManuales() {

    //const datos = ['10004', '10002'];

    const datos = [
        // Arreglo de datos a inyectar 
        //En este caso son las cédulas de la cartare a modificar.....
        "1645955",
        "1122128820",


    ];


    return datos;
}

async function idRegistros(nit, idCartera, dato) {
    //Solo nos devuelve el id de la cedula que se va a procesar
    //console.log('Datos a conocer id: ', dato);
    let datosALocarizar = Number(dato);
    //console.log(datosALocarizar);

    //let idTitular = '';
    let idTitular = [];

    try {
        ///cf-cartera/Carteras/900/obVcuaRgT3xdtTRvThjN/registros/3QM5zgulPo9MOVgVvY4Z
        const empresaRef = db.collection('cf-cartera').doc('Carteras').collection(nit).doc(idCartera).collection('registros');
        const snapshot = await empresaRef.where('data.Cedula', '==', datosALocarizar).get();

        if (snapshot.empty) {
            //console.log('No matching documents.', dato);
            return false;
        }
        //Traemos todas las carteras. que estan estado activo.
        snapshot.forEach(async doc => {
            //idTitular = doc.id;
            //console.log(doc.data().asignadoActual[0]);

            idTitular.push({ idTitular: doc.id, asesorAsignado: doc.data().asignadoActual[0] })
            //idCarterasActivas.push({ nit: nitActual, cartera: idCartera });
        });
    } catch (error) {
        console.log(error);
    }
    return idTitular;
    //console.log('Finaliza proceso ID');
}

///-----FINALIZA PROCESO DE ACTUALIZACIÓN DE REGISTROS MASIVOS


///---- ENTRAR A VALIDAR CUANTOS TITULARES ESTÁN PENDIENTES POR ASIGNAR ------///

export async function FBtitularesPendientes(selectCliente, selectCarteras, ejecutivosActivos) {
    console.log('Titulares Huerfanos');

    //console.log(selectCliente, selectCarteras);
    console.log('nit:', selectCliente.nit, 'cartera:', selectCarteras.id, 'total eje disponibles:', ejecutivosActivos.length);

    let cliente = selectCliente.nit;//selectCliente.nit;//'900';
    let cartera = selectCarteras.id;//selectCarteras.id;//'obVcuaRgT3xdtTRvThjN';
    let ejecutivosDisponibles = []; //Array
    let mitad = [];
    let tipificaciones = ['Paz y Salvo v2', 'Paz y Salvo', 'Fallecido', 'Ilocalizado', 'Incobrable', 'Paz y Salvo - Notificado'];
    let numero = 0;
    let losHuerfanos = [];

    //1. Armar el array de Ejecutivos Disponibles
    for (let index = 0; index < ejecutivosActivos.length; index++) {
        //const element = array[index];
        ejecutivosDisponibles.push(ejecutivosActivos[index].id)
    }

    try {
        //1. Consultar
        const rutaRegistrosCartera = db.collection('cf-cartera')
            .doc('Carteras')
            .collection(cliente)
            .doc(cartera)
            .collection('registros')
            .where("asignadoActual", "not-in", ejecutivosDisponibles);

        const totalRegistrosCartera = await rutaRegistrosCartera.get();

        //console.log(capitalNotFalseRes);
        if (totalRegistrosCartera.empty) {
            console.log('No matching documents.');
            return;
        }

        const resultHuerfanos = await prepararArrayHuerfanos(totalRegistrosCartera, losHuerfanos, tipificaciones, ejecutivosDisponibles);

        console.log(resultHuerfanos);
        //const resultAgregaDatos = await guardarDatos(resultHuerfanos, cliente, cartera, ejecutivosDisponibles);

       // console.log(resultAgregaDatos);

        return resultAgregaDatos;

        //2. Validar si no está asignado según la asignación actula

        //3. En caso que si pasar, en caso que no, modificar.

        //4. Seguir con el siguiente titular.

    } catch (error) {
        console.log(error);
    }

}

async function prepararArrayHuerfanos(totalRegistrosCartera, losHuerfanos, tipificaciones, ejecutivosDisponibles) {

    try {

        for (let titulares of totalRegistrosCartera.docs) {
            //console.log(titulares);
            //console.log(titulares.id);
            //console.log(titulares.data());
            //console.log(titulares.data());

            const aplica = await aplicaParaReasignar(titulares.id, titulares.data(), tipificaciones, ejecutivosDisponibles);

            if (aplica == 1) {
                //1 = a actualizar asignado y agregarlo a la bolsa
                //console.log(doc.id, '=>', doc.data());
                //console.log(doc.data().data.Cedula, ' -/- ', doc.data().asignadoActual); 
                losHuerfanos.push({ idT: titulares.id, dataT: titulares.data() })
                //console.log(numero++);
                console.log('Se agrega');

            } else if (aplica == 0) {
                //0 = a no hacer nada...
                console.log('No aplica para reasignar corrección.');
            } else if (aplica == 2) {
                console.warn('Pilas');

            }/**/
        }

        console.log('Sale');

        return losHuerfanos;

    } catch (error) {
        console.log(error);
    }

}

async function aplicaParaReasignar(idTitular, dataTitular, tipificaciones, ejecutivosDisponibles) {
    //console.log(idTitular, dataTitular, tipificaciones, ejecutivosDisponibles);

    try {
        let observaciones = dataTitular.observaciones;
        let ejecutivoActual = dataTitular.asignadoActual;
        //console.log(observaciones);

        //1. recorrer si Existe la tipificación
        const tipi = await validaTipificaciones(idTitular, observaciones, tipificaciones, ejecutivoActual, ejecutivosDisponibles);
        //console.log(tipi);

        if (tipi == true) {
            //console.info('Listo para agregar');
            //console.log(idTitular);
            return 1;
        } else if (tipi == false) {
            //console.error('No agregar');
            return 0;
        } else {
            //console.log('No es false ni true');
            return 2;
        }

    } catch (error) {
        console.log(error);
    }
    //return 0;
}

async function validaTipificaciones(idTitular, elemento, tipificaciones, ejecutivoActual, ejecutivosDisponibles) {

    //console.log(idTitular);
    //console.error('--------------------------');
    let tipificacionesDelElmento = [];//Todas las que vamos a comprar del TTular
    let ejecutivo = ejecutivoActual;//El ejecutivo Actual del TTular

    //1. Primero el ejecutivo

    var filtro = _.filter(ejecutivosDisponibles, function (persona) {
        //console.log(persona);
        //console.log(ejecutivo[0]);
        return persona === ejecutivo[0];

    });

    //console.log(filtro);

    if (filtro.length == 0) {
        //Es porque no lo encontró.
        //console.log('Se puede asignar, está libre.');
        //console.warn('Validar si aplica tipificación.');
        //2. Luego las tipificaciones
        for (let index = 0; index < elemento.length; index++) {
            const element = elemento[index];
            //console.log(element.efecto);
            tipificacionesDelElmento.push(element.efecto);

        }
        //console.log(tipificacionesDelElmento);
        const resultante = _.difference(tipificaciones, tipificacionesDelElmento);

        //3. Se va en true o false
        if (tipificaciones.length === resultante.length) {
            //No se encontraron tipificaciones.
            //console.log('Listo para agregar');
            //console.log(tipificacionesDelElmento);
            //console.log(resultante);
            return true
        } else {
            //console.log('No agregar, tipificación que no aplica para volver a JUGAR');
            //console.log(tipificacionesDelElmento);
            //console.log(resultante);
            return false
        }
        //console.log(resultante);
    } else {
        //if (filtro.length > 1)
        //console.log('Ups! no se puede trabajar, ya está asignado a un ejecutivo con estado ACTIVO');
        return false;
    }




}

async function guardarDatos(huerfanos, cliente, cartera, ejecutivosDisponibles) {
    console.log('-------Guardar Datos--------');

    //console.log(cliente);
    //console.log(cartera);
    console.log(huerfanos.length);

    try {
        if (huerfanos.length > 0) {

            for (const huerfano of huerfanos) {
                //0. Tomo un ejecutivo al azar
                const idEjecutivoAzar = await ejecutivoAzar1(ejecutivosDisponibles);

                //console.log(idEjecutivoAzar);
                let asesor = idEjecutivoAzar;//'g5XK9n5Fdche1oHnYM6x'
                let cedula = Number(huerfano.dataT.data.Cedula);
                let idTitular = huerfano.idT;

                //console.log('*asesor:', asesor, ' *cedula:', cedula, ' *idTitular:', idTitular);

                //1. Actualizo el titular con el nuevo ejecutivo asignado
                //RUTA: /cf-cartera/Carteras/900/obVcuaRgT3xdtTRvThjN/registros/LJ5FUZbJkiLOfUMWvjoo
                const titularRef = db.collection('cf-cartera')
                    .doc('Carteras')
                    .collection(cliente)
                    .doc(cartera)
                    .collection('registros')
                    .doc(idTitular)

                // Actualiza el ejecutivo asignado
                //OJO USAR ESTA!!!!!
                const actualizaTitular = await titularRef.update({ asignadoActual: [asesor] });



                //------PROCESO......

                //2. Agrego el titular a la bolsa del ejecutivo al azar para iniciar a jugar
                //RUTA: /cf-cartera/Asignaciones/g5XK9n5Fdche1oHnYM6x/yFcQR0sxbyKUd2WgRRQE
                //console.log(huerfano);

                const data = {
                    Cedula: cedula,
                    idCartera: cartera,
                    idRegistro: idTitular,
                    nitCliente: cliente,
                    toques: 0
                };

                // Agrega el documento al Ejecutivo para entrar a JUGAR
                //OJO USAR ESTA!!!!!
                const res = await db.collection('cf-cartera')
                    .doc('Asignaciones')
                    .collection(asesor)
                    .add(data);/**/

            }
        } else {
            console.log('NO HAY HUERFANOS');
        }

        console.log('Finaliza el ACTUALIZADOR de EJECUTIVO');
        return true;
    } catch (error) {
        console.log(error);
    }
}

async function ejecutivoAzar1(ejecutivosDisponibles) {

    const nombres = ejecutivosDisponibles;
    const aleatorio = nombres[Math.floor(Math.random() * nombres.length)];
    //console.log("El arreglo es: ");
    //console.log(nombres);
    //console.log("Y un aleatorio es: ");
    //console.log(aleatorio);
    //console.log('Defina Ejecutivo aleatorio');

    return aleatorio;
}

///---- ENTRAR A VALIDAR CUANTOS TITULARES ESTÁN PENDIENTES POR ASIGNAR ------///

///----- RESETEO DE TITULARES PARA LUEGO ASIGNAR ----////

export async function FBresetTitualres() {

    try {
        console.log('Hola...');
        let arrayIDCambio = [];
        let nit = '805028053';//98698923
        let cartera = '2kYNwesqTXnrdHJ6olYy';//r365ZX2Eu0X26CycRmCe

        //{ contador: 0, estado: true, usuario: 0 } Es un respetable
        //{ contador: 0, estado: false, usuario: 0 } No es un responsable

        //Como se encuentra actulmente y lo vamos a remplazar por true solamente los respetables.
        //{ contador: 0, estado: true, usuario: 0 }

        //PASO 1 // Problemas con la palabra contador y contado, esto es para convertir los array en solo una variable true
        var arrayNuevoLimpiar = { contador: 0, estado: true, usuario: 0 };
        ///cf-cartera/Carteras/902/4njopDRN1C50cWOEbVf5/registros/0DGytvQ0z75yunjNfkvZ
        //Para traer todos los que son True 
        //const titularRef = db.collection('cf-cartera').doc('Carteras').collection(nit).doc(cartera).collection('registros').where("respetable", "array-contains", arrayNuevoLimpiar);

        //PASO 2
        //Para identificar los que son diferentes a true y se debe borrar el asignadoActual y respetable false
        const titularRef = db.collection('cf-cartera').doc('Carteras').collection(nit).doc(cartera).collection('registros').where("respetable", "==", false);
        //const capitalNotFalseRes = await titularRef.where('respetable', '!=', false).get();
        //const snapshot = await titularRef.limit(100).get();
        const snapshot = await titularRef.get();

        

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            arrayIDCambio.push(doc.id);
        });

        console.log('Identificar Titulares ya respetados');

        console.log(arrayIDCambio.length);
         var procesando = 0;
        // TOCARIA.... Solo por primera vez.... se debe eliminar.
        // Recorrer todos los respetables por primera vez 
        arrayIDCambio.forEach(async element => {
            procesando++;
            //console.log(element);
            ////RUTA
            const titularUpdate = db.collection('cf-cartera')
                .doc('Carteras')
                .collection(nit)
                .doc(cartera)
                .collection('registros')
                .doc(element);

            //PASO 1
            //Solo para colocar en true cuando son respetables.
            /*await titularUpdate.update({
                respetable: true,
            });/*  */

            //PASO 2
            //Solo para marcar los no respetables y borrar su asignadoActual
           /* await titularUpdate.update({
                asignadoActual: [],
                respetable: false,
            })/*  */

            //PASO 3 
            // Resetear asignado actual
            await titularUpdate.update({
                asignadoActual: [],
                //respetable: false,
            })/*  */

        });
        console.log(procesando);
        console.log('Update 1');

        return ['kiko', false]
    } catch (error) {
        console.log(error);
    }
}