

export async function copiarTexto(dato){

   
        //this.$emit();
        let textoCopiado = dato
  
        //Copia el texto en el portapapeles
        const a = await navigator.clipboard.writeText(textoCopiado);
       // console.log(a);

        return a
      

}