<template>
  <div justify="center">
    <v-dialog v-model="dialog" scrollable max-width="80%" persistent>
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
              <v-icon>mdi-recycle-variant</v-icon>
            </v-btn>
          </template>
          <span>Re-Asgina Cartera</span>
        </v-tooltip>
      </template>

      <v-card class="mx-auto">
        <v-toolbar color="primary" dark>Re-Asignar Cartera</v-toolbar>

        <v-card-text>
          <div class="mt-3">
            <p>
              Para la reasignación de titulares se debe seguir los siguientes
              pasos
              <br />
              1. Se debe calcular cada ejecutivo por separado y depurar los
              datos en el primer select, esta operación se debe hacer 1 a 1
              <br />
              2. Se ejecuta los respetables asigandos para marcarlos para
              respetables...
            </p>
          </div>

          <loadCartera />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
          <!--
          <v-btn color="blue darken-1" text @click="dialog = false">
            Save
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loadCartera from "../../../components/loadFile/carteras/reAsignaCartera.vue";

export default {
  name: "reAsignaCartera",
  props: [],
  components: {
    loadCartera,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {},
  computed: {
    retornaEstadoMensaje() {
      //return (this.sheet = this.estadoMensaje);
    },
  },
};
</script>