<template>
  <div justify="center">
    <v-dialog v-model="dialog" max-width="80%" scrollable persistent>
      <template v-slot:activator="{}">
        <!-- Boton de activación-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
              <v-icon>mdi-account-multiple-plus</v-icon>
            </v-btn>
          </template>
          <span>Crea Usuario</span>
        </v-tooltip>
      </template>

      <v-card :loading="isUpdating" v-if="!isUpdating" dark>
        <template slot="progress">
          <v-progress-linear
            color="yellow darken-2"
            indeterminate
          ></v-progress-linear>
        </template>
        
        <v-card-title>
          Crear Colaborador
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <span class="grey--text text--lighten-1">Datos de Iniciales</span>
            <v-divider></v-divider>
            <v-row class="mt-n1">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="datosFormulario.empleado"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Nombres y Apellidos"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="datosFormulario.cedula"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Cédula (sin decimales)"
                  :rules="cedulaRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n9">
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="datosFormulario.cargo"
                  :disabled="isUpdating"
                  :items="cargos"
                  :filter="customFilter"
                  item-value="abbr"
                  color="black"
                  item-text="name"
                  label="Cargo"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="datosFormulario.cliente"
                  :disabled="isUpdating"
                  :items="clientes"
                  :filter="customFilter"
                  item-value="abbr"
                  color="black"
                  item-text="name"
                  label="Cliente"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="datosFormulario.modulos"
                  :items="modulos"
                  attach
                  chips
                  label="Módulos"
                  multiple
                  class="mt-n2"
                  item-text="name"
                  color="black"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n9">
              <v-col cols="12" md="5">
                <v-text-field
                  v-model.trim="datosFormulario.telefonoCorp"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Whatsapp Corporativo / Contacto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model.trim="datosFormulario.extencion"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Extención"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model.trim="datosFormulario.correo"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Correo Corporativo de ingreso a plataforma"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <span class="grey--text text--lighten-1">Datos de Personales</span>
            <v-divider></v-divider>
            <v-row class="mt-n1">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.dirHogar"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Dirección Hogar"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.telefonoHogar"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Teléfono Hogar"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.emailPersonal"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Correo Personal"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- REFERENCIA 1 -->
            <v-row class="mt-n9">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.nameRef1"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Nombre Referencia 1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.parentescoRef1"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Parentesco"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.teleRef1"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Teléfono Referencia 1"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- REFERENCIA 2 -->
            <v-row class="mt-n9">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.nameRef2"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Nombre Referencia 2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.parentescoRef2"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Parentesco"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.teleRef2"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Teléfono Referencia 2"
                ></v-text-field>
              </v-col>
            </v-row>

            <span class="grey--text text--lighten-1">Otros</span>
            <v-divider></v-divider>
            <!-- Otros -->
            <v-row class="mt-n1">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.idiomas"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Idiomas"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.conocimientos"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Conocimientos y Habilidades"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="datosFormulario.gustos"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Gustos o Pregerencias"
                ></v-text-field>
              </v-col>
            </v-row>

            <span class="grey--text text--lighten-1">Documentos</span>
            <v-divider></v-divider>
            <!-- Documentos  -->
            <v-row class="mt-n1">
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model.trim="datosFormulario.docHV"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Hoja de Vida"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      class="pa-1 mt-4 ml-n5"
                      outlined
                      fab
                      x-small
                      color="black"
                    >
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model.trim="datosFormulario.docCyE"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Certificaciones y Estudios"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      class="pa-1 mt-4 ml-n5"
                      outlined
                      fab
                      x-small
                      color="black"
                    >
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model.trim="datosFormulario.docODoc"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Otros Documentos"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      class="pa-1 mt-4 ml-n5"
                      outlined
                      fab
                      x-small
                      color="black"
                    >
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <span class="grey--text text--lighten-1"
              >Observaciones Generales</span
            >
            <v-divider></v-divider>
            <!-- Observaciones -->
            <v-row class="mt-n2">
              <v-col cols="12">
                <v-textarea
                  v-model.trim="datosFormulario.observaciones"
                  :disabled="isUpdating"
                  filled
                  color="blue-grey lighten-2"
                  label="Observaciones..."
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn :disabled="isUpdating" color="amber" text @click="guardar"
            >Guardar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn :disabled="isUpdating" color="amber" text @click="cancelar"
            >Cancelar</v-btn
          >
          
          <!-- 
          <v-btn :disabled="isUpdating" color="primary" text @click="probar"
            >Probar</v-btn
          >
          -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  FBcreaEmpleado,
  FBprobar,
} from "@/components/RecursoHumano/creaEmpleado.js";
import { FBtraeRoles } from "@/components/Roles/";
import { FBtraeClientes } from "@/components/Clientes/";
import { FBtraeModulos } from "@/components/Modulos/";
export default {
  data() {
    return {
      dialog: false,
      isUpdating: false,
      datosFormulario: {
        empleado: "", //ok
        cedula: "", //ok
        cargo: "", //ok
        cliente: "", //ok
        modulos: "", //ok
        telefonoCorp: "", //ok
        extencion: "", //ok
        correo: "", //ok
        dirHogar: "", //ok
        telefonoHogar: "", //ok
        emailPersonal: "", //ok
        nameRef1: "", //ok
        parentescoRef1: "", //ok
        teleRef1: "", //ok
        nameRef2: "", //ok
        parentescoRef2: "", //ok
        teleRef2: "", //ok
        idiomas: "", //ok
        conocimientos: "", //ok
        gustos: "", //ok
        docHV: "", //ok
        docCyE: "", //ok
        docODoc: "", //ok
        observaciones: "", //ok
        estado: true, //acceso al sistema
      },
      nameRules: [
        (value) =>
          (value && value.length <= 100) ||
          "El nombre y apellido debe tener máximo 100 caracteres",
      ],
      cedulaRules: [
        (value) => /^\d+$/.test(value) || "La cédula debe ser un número entero",
      ],
      emailRules: [
        (value) => /.+@.+\..+/.test(value) || "El correo debe ser válido",
      ],
      cargos: [],
      clientes: [],
      roles: [],
      modulos: [],
    };
  },
  async created() {
    const rolesData = await FBtraeRoles();
    //console.log(rolesData);
    //Actualiza listado Cargos de DB
    if (rolesData) {
      this.cargos = Object.entries(rolesData.datos).map(([name, abbr]) => ({
        name,
        abbr,
      }));
    } else {
      this.cargos = [{ abbr: "loading", name: "Cargando..." }];
    }

    //console.log(this.cargos);

    const clientesData = await FBtraeClientes();
    //console.log(clientesData);
    //Actualiza listado clientes de DB validando si hay clientes
    if (clientesData && clientesData.length > 0) {
      this.clientes = clientesData.map((cliente) => ({
        name: cliente.data.cliente,
        abbr: cliente.data.cliente,
      }));
    } else {
      this.clientes = [{ abbr: "loading", name: "Cargando..." }];
    }

    //console.log(this.clientes);

    const modulosData = await FBtraeModulos();
    //console.log(modulosData.cards.length);
    //Actualiza listado clientes de DB validando si hay clientes
    if (modulosData && modulosData.cards.length > 0) {
      this.modulos = modulosData.cards.map((cliente) => ({
        name: cliente.title,
        abbr: cliente.title,
      }));
    } else {
      this.modulos = [{ abbr: "loading", name: "Cargando..." }];
    }

    //console.log(this.modulos);
  },
  methods: {
    async probar() {
      const result = await FBprobar();
    },
    customFilter(item, queryText, itemText) {
      const hasValue = (val) => (val != null ? val : "");
      const text = hasValue(itemText).toString().toLowerCase();
      const query = hasValue(queryText).toString().toLowerCase();
      return text.includes(query);
    },
    cancelar() {
      this.dialog = false;
      // Reiniciar formulario
      this.datosFormulario = {
        empleado: "", //ok
        cedula: "", //ok
        cargo: "", //ok
        cliente: "", //ok
        modulos: "", //ok
        telefonoCorp: "", //ok
        extencion: "", //ok
        correo: "", //ok
        dirHogar: "", //ok
        telefonoHogar: "", //ok
        emailPersonal: "", //ok
        nameRef1: "", //ok
        parentescoRef1: "", //ok
        teleRef1: "", //ok
        nameRef2: "", //ok
        parentescoRef2: "", //ok
        teleRef2: "", //ok
        idiomas: "", //ok
        conocimientos: "", //ok
        gustos: "", //ok
        docHV: "", //ok
        docCyE: "", //ok
        docODoc: "", //ok
        observaciones: "", //ok
        estado: true, //acceso al sistema
      };
    },
    guardar() {
      // Lógica para guardar los datos del formulario

      const result = FBcreaEmpleado(this.datosFormulario);

      this.dialog = false;

      //Faltaria mostrar el proceso de guardado y un cargador
      //console.log(result);

      // Reiniciar formulario
      this.datosFormulario = {
        empleado: "", //ok
        cedula: "", //ok
        cargo: "", //ok
        cliente: "", //ok
        modulos: "", //ok
        telefonoCorp: "", //ok
        extencion: "", //ok
        correo: "", //ok
        dirHogar: "", //ok
        telefonoHogar: "", //ok
        emailPersonal: "", //ok
        nameRef1: "", //ok
        parentescoRef1: "", //ok
        teleRef1: "", //ok
        nameRef2: "", //ok
        parentescoRef2: "", //ok
        teleRef2: "", //ok
        idiomas: "", //ok
        conocimientos: "", //ok
        gustos: "", //ok
        docHV: "", //ok
        docCyE: "", //ok
        docODoc: "", //ok
        observaciones: "", //ok
        estado: true, //acceso al sistema
      };
    },
  },
};
</script>

<style scoped>
.v-dialog--width-500 {
  max-width: 500px;
}
</style>