<template>
  <!-- fill-height fluid class="grey lighten-5" -->
  <Paneles />
</template>

<script>
import Paneles from "../views/Home/Paneles.vue";

export default {
  name: "Inicio",
  data: () => ({}),
  components: {
    Paneles,
  },
  mounted() {},
};
</script>