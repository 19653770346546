import { render, staticRenderFns } from "./fichaApoyo.vue?vue&type=template&id=e505cfc4&scoped=true&"
import script from "./fichaApoyo.vue?vue&type=script&lang=js&"
export * from "./fichaApoyo.vue?vue&type=script&lang=js&"
import style0 from "./fichaApoyo.vue?vue&type=style&index=0&id=e505cfc4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e505cfc4",
  null
  
)

export default component.exports