import { firebase, db, storage } from "../../firebase/config"

const ref = storage.ref();

//Busca Clientes
export async function FBverClientes() {
    let data = [];
    try {
        const clientesRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const snapshot = await clientesRef.get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            //False no existe
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            data.push({ id: doc.id, info: doc.data() });
        });

        //True es existe
        return data;

    } catch (error) {
        console.log(error);
    }

}

//Busca PDF --id, nit, tipo
export async function FBverPDFClientes(id, nit, tipo) {
    //console.log('entra a encontrar la ruta');
    let tipoDoc = "";
    switch (tipo) {
        case 'endoso':
            tipoDoc = "carta-de-endoso";
            break;
        case 'cuentas':
            tipoDoc = "entidades-de-pago";
            break;

        default:
            break;
    }

    try {

        ///st-cartera/Clientes/906/DocumentosCreacion/I5lv5RXy08gEPIf8olzf
        const storageRef = firebase.storage().ref("st-cartera/Clientes/" + nit + "/DocumentosCreacion/" + id + "/" + tipoDoc + ".pdf");
        const result = await storageRef.getDownloadURL();
        //console.log(result);
        return result
    } catch (error) {
        console.log(error);
    }

}


export async function FBactualizaCliente(campo, id, valor) {

    //console.log('Entramos a actualizar campo');

    let nodo = campo;
    let value = valor;
    let valueUpdate = {};
    valueUpdate[`data.${nodo}`] = value;

    try {
        ////cf-cartera/Clientes/Listado/3l1fkVMLIBWQcV7GkdV4
        const empresaRef = db.collection('cf-cartera').doc('Clientes').collection('Listado').doc(id);
        await empresaRef.update(valueUpdate);

        return true;
    } catch (error) {
        console.log('Error: ', error);
    }

}

//Listado de clientes
export async function FBListadoClientes() {

    let result = [];

    try {
        ////cf-cartera/Clientes/Listado/3l1fkVMLIBWQcV7GkdV4
        const empresaRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const snapshot = await empresaRef.get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            result.push({ nombre: doc.data().data.cliente, nit: doc.data().data.nit })
        });


        return result;
    } catch (error) {
        console.log('Error: ', error);
    }

}

//Crea Cartera
export async function FBcreaCartera(valueCliente, carteraNombre, switch1) {
    console.log(valueCliente, carteraNombre, switch1);

    let dataCartera = {
        nombreCartera: carteraNombre, estado: switch1, timeR: [firebase.firestore.Timestamp.now()],
    }
    try {
        ///cf-cartera/Carteras/901
        const rutaCreCartera = db.collection('cf-cartera').doc('Carteras').collection(valueCliente);
        const result = await rutaCreCartera.add(dataCartera);

        //console.log(result);
        //Hay un registro de iniciación en false para no mostrar
        //Se deshabilita porque quedaba ese registro dando problemas al calcular la asignación
        //const rutaRegistrosCartera = db.collection('cf-cartera').doc('Carteras').collection(valueCliente).doc(result.id).collection('registros');
        //const result2 = await rutaRegistrosCartera.add({ estado: false, titular: 'prueba' });

        //console.log(result2);

        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

//Listado de clientes Activos
export async function FBListadoClientesActivos() {
    try {
        let result = [];
        const empresaRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const snapshot = await empresaRef.where('data.estado', '==', true).get();

        if (snapshot.empty) {
            return false;
        }

        snapshot.forEach(doc => {
            result.push({ nombre: doc.data().data.cliente, nit: doc.data().data.nit })
        });


        return result;
    } catch (error) {
        console.log('Error: ', error);
    }

}

//Listado carteras por cliente
export async function FBListadoCarteraClientesActivos(nit) {
    try {
        let result = [];
        const empresaRef = db.collection('cf-cartera').doc('Carteras').collection(nit);
        const snapshot = await empresaRef.where('estado', '==', true).get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            result.push({ nombre: doc.data().nombreCartera, id: doc.id })
        });


        return result;
    } catch (error) {
        console.log('Error: ', error);
    }

}

