<template>
  <v-container fluid>
    <v-row>
      <Banner v-if="modulos !== null" />
    </v-row>

    <Modulos :modulos="modulos" v-if="!isLoading" />

    <v-row v-else>
      <!-- Indicador de carga -->
      <v-progress-circular indeterminate color="black" dark>
        Modulos
      </v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
//Consulta de módulo en la base de datos
import { FBtraeModulos } from "../../components/Home/recursos";
import Letrero from "../Comunicacion/Letrero.vue";
import Modulos from "./Cards/card01.vue";
import Banner from "./Banners/carousel01.vue";

export default {
  name: "Paneles",
  data: () => ({
    modulos: null,
    isLoading: false,
  }),
  components: {
    Letrero,
    Modulos,
    Banner,
  },
  methods: {
    async cargaModulos() {
      this.isLoading = true;

      try {
        const result = await FBtraeModulos().catch((error) => {
          console.error("error: " + error);
        });

        if (result) {
          this.modulos = result;
        }
      } catch (error) {
        console.error("error: " + error);
      }

      this.isLoading = false;
    },
  },
  mounted() {
    this.cargaModulos();
  },
};
</script>

<style src="./css/Paneles.vue.css" scoped></style>
