<template>
  <div class="mt-10 text-center">
    <v-card max-width="100%">
      <h2>Estado Bolsa (generales)</h2>
      <br />
      <v-card-text>
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
  
<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    datos: null,
  },
  data() {
    return {
      chartData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      datosLlegan: null,
    };
  },
  mounted() {
    //console.log(this.datos);
    //this.datos = this.datosLlegan;
    this.datosLlegan = this.datos;

    //console.log(this.datosLlegan);
    this.ActualizaDatos();
  },
  methods: {
    async ActualizaDatos() {
      //console.log(this.datos);
      var base = this.datos;
      var tipificaciones = [];
      var cantidadxTipi = [];
      var otros = [];

      //console.log(base);

      // Obteniendo todas las claves del JSON
      for (var clave in base) {
        // Controlando que json realmente tenga esa propiedad
        tipificaciones.push(clave);
        /*if (base.hasOwnProperty(clave)) {
          // Mostrando en pantalla la clave junto a su valor
          //console.log("La clave es " + clave + " y el valor es " + base[clave]);
          console.log(base[clave].detalle);
          console.log(base[clave].detalle.length);
          cantidadxTipi.push(base[clave].detalle.length);
        }*/
      }

      //console.log(this.datos["Correo"].detalle.length);

      //console.log("Mostrando resultado final:");
      //console.log(tipificaciones);
      this.chartData.labels = tipificaciones;

      const resultCantidades = await this.contadorA();

      let comodin = [];

      resultCantidades.forEach((element) => {
        comodin.push(element.length);
        //console.error(element.length);
      });

      cantidadxTipi = comodin;

      //console.log("Cantidades");
      //console.warn(cantidadxTipi);

      this.chartData.datasets = [{ data: cantidadxTipi }];

      /*if (base.hasOwnProperty(clave)) {
        // Mostrando en pantalla la clave junto a su valor
        //console.log("La clave es " + clave + " y el valor es " + base[clave]);
        console.log(base[clave].detalle);
        console.log(base[clave].detalle.length);
        cantidadxTipi.push(base[clave].detalle.length);
      }*/

      /* base.forEach((dato) => {
        console.log(dato);
        //console.log(dato.datos.P9[0]);
        //Saca los item que se encuentran en ciudad, sin repetir, no cuenta
        if (ciudades.indexOf(dato) == -1) {
          ciudades.push(dato);
        }
      });
      console.log(ciudades);
      console.log(ciudades.length);
      this.chartData.labels = ciudades;*/

      /*for (let index = 0; index < this.datos.length; index++) {
        const element = this.datos[index].datos;
        console.log(element.P9[0].ciudad);
      }*/

      /*for (let index = 0; index < ciudades.length; index++) {
        const ciudad = ciudades[index];

        var filtro = _.filter(base, (persona) => {
          //console.log(persona.datos.P9[0].ciudad);
          return persona.datos.P9[0].ciudad === ciudad;
          //return persona.edad === 50;
        });
        cantidadxCiudades.push(filtro.length);
      }

      this.chartData.datasets = [{ data: cantidadxCiudades }];*/

      //console.log(this.chartData);
    },
    async contadorA() {
      //console.log(this.datosLlegan);

      let contador = [];

      for (const property in this.datosLlegan) {
        /*console.log(
          //`${property}: ${this.datosLlegan[property].detalle.length}`
          //this.datosLlegan[property].detalle.length
        );*/
        contador.push(this.datosLlegan[property].detalle);
      }

      //console.log("Termina el FOR");

      return contador;
    },
  },
};
</script>
  