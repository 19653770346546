const config = {
  apiKey: "AIzaSyBS64HHPYvy2UrWGjuDnHX8IMGT33Jjfyc",
  authDomain: "bybabogados-138f2.firebaseapp.com",
  databaseURL: "https://bybabogados-138f2-default-rtdb.firebaseio.com",
  projectId: "bybabogados-138f2",
  storageBucket: "bybabogados-138f2.appspot.com",
  messagingSenderId: "253043047621",
  appId: "1:253043047621:web:4724d377f8e9df31ef04ac",
  measurementId: "G-YG65454QHK"
};

export { config };