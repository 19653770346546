<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon dark v-bind="attrs" v-on="on" color="green">
          <v-icon>mdi-swap-horizontal</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Cambio de Ejecutivo
        </v-card-title>

        <v-card-text>
          <p style="margin-top: 10px">
            <span class="font-weight-black"> ¿Cómo funciona?</span> Debes
            seleccionar un ejecutivo del listado, solo se muestra los activos.
            Luego de seleccionar el ejecutivo al que se va a asignar, dar clic
            en el botón "Cambiar Ejecutivo". <br /><br />
            El cambio se ve reflejado cuando se consulte de nuevo el Titular.
            <br /><br />
            Ejecutivo actual:
            <span class="font-weight-black">{{ ejecutivoActual }}</span>
          </p>

          <v-row>
            <v-col class="d-flex" cols="12" sm="12">
              <v-select
                :items="ejecutivos[1]"
                filled
                label="Asignar a..."
                v-model="nuevoEjecutivo"
                :disabled="botonAsignar"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              color="error"
              @click="cambiarEjecutivo()"
              :disabled="botonAsignar"
            >
              {{ estadoP }}
            </v-btn>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
            :disabled="btnCerrar"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  FBcambiarEjecutivo,
  FBListadoEjecutivos,
} from "../../../../components/Cartera/subProcesosCambioEjecutivo";

export default {
  name: "cambioEjecutivo",
  props: ["datos"],
  data: () => ({
    dialog: false,
    estadoP: "Cambiar ejecutivo..",
    btnCerrar: false,
    ejecutivos: ["Cargando..."],
    ejecutivoActual: "Cargando...",
    nuevoEjecutivo: "",
    botonAsignar: false,
  }),
  async mounted() {
    //console.log("Montado...");
    const listadoEjecutivos = await FBListadoEjecutivos();
    //console.log(listadoEjecutivos);
    this.ejecutivos = listadoEjecutivos;
    //console.log(this.ejecutivos);
    this.ejecutivoInicial();
  },
  methods: {
    ejecutivoInicial() {
      //console.log(this.datos);
      var filtro = _.filter(this.ejecutivos[0], (persona) => {
        //console.log(persona.idEjecutivo);
        return persona.idEjecutivo == this.datos.asignadoActual;
      });

      //console.log(filtro);
      //console.log(filtro.length);
      if (filtro.length == 1) {
        this.ejecutivoActual = filtro[0].nombre;
        this.botonAsignar = false;
      } else {
        this.ejecutivoActual = "No está asignado a un ejecutivo activo.";
        this.botonAsignar = false;
      }
    },
    async conocerEjecutivo(a) {
      var filtro = _.filter(this.ejecutivos[0], (persona) => {
        //console.log(persona.idEjecutivo);
        return persona.nombre == a;
      });

      return filtro;
    },
    async cambiarEjecutivo() {
      this.btnCerrar = true;
      this.botonAsignar = true;
      this.estadoP = "Espere por favor...";
      //console.log("inició de cambio");

      //console.log(this.nuevoEjecutivo);
      const nuevoEjecutivo = await this.conocerEjecutivo(this.nuevoEjecutivo);
      const antiguoEjecutivo = await this.conocerEjecutivo(
        this.ejecutivoActual
      );

      //console.log(antiguoEjecutivo);
      //console.log(nuevoEjecutivo);

      const resultCambioE = await FBcambiarEjecutivo(
        this.datos,
        antiguoEjecutivo,
        nuevoEjecutivo
      );
      //console.log(resultCambioE);
      if (resultCambioE[0]) {
        this.estadoP = resultCambioE[1];
      } else {
        this.estadoP = resultCambioE[1];
      }

      //console.log(this.datos);
      /*console.log("Nit: ", this.datos.nitCliente);
      console.log("idCartera: ", this.datos.idCartera);
      console.log("idRegistro: ", this.datos.idRegistro);
      console.log("Cedula: ", this.datos.data.Cedula);
      console.log("idEjecutivoActual: ", this.datos.asignadoActual[0]); */

      this.btnCerrar = false;
      this.botonAsignar = false;
    },
  },
};
</script>