<template>
  <v-dialog v-model="dialog" width="500" persistent dark>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="pa-2" v-bind="attrs" v-on="on"   x-small fab color="green darken-4">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 green">Editar</v-card-title>

      <v-card-text class="mt-3">
        <p>
          Lo editado aquí no afectará la ficha del cliente y/o proceso, es
          totalmente independiente.
        </p>
        <v-row class="mt-4">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="usuario"
              label="Actualizar Nombre"
              outlined
              dense
              @change="actualizar('usuario')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="empresa"
              label="Empresa"
              outlined
              dense
              @change="actualizar('empresa')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col>
            <v-switch
              v-model="switchEstado"
              :label="`Requiere Seguimiento ${texto(switchEstado)}`"
              color="green"
              @change="actualizar('seguimiento')"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" text @click="limpiar">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["usuarioLlega", "telefonoLlega", "empresaLlega", "seguimientoLlega"],
  data() {
    return {
      dialog: false,
      switchEstado: false,
      usuario: "",
      empresa: "",
    };
  },

  created() {
    this.inicializar();
  },

  methods: {
    inicializar() {
      this.usuario = this.usuarioLlega;
      this.empresa = this.empresaLlega;
      this.switchEstado = this.seguimientoLlega;
    },

    actualizar(dato) {
      let valor;
      if (dato === "usuario") {
        valor = this.usuario;
      } else if (dato === "empresa") {
        valor = this.empresa;
      } else if (dato === "seguimiento") {
        valor = this.switchEstado;
      }
      this.$emit("enviandoCambio", valor, dato);
    },

    texto(texto) {
      return texto ? "SI" : "NO";
    },

    limpiar() {
      this.dialog = false;
      /*this.switchEstado = false;
      this.usuario = "";
      this.empresa = "";*/
    },
  },

  watch: {
    usuarioLlega: function (nuevoUsuario) {
      this.usuario = nuevoUsuario;
    },
    empresaLlega: function (nuevaEmpresa) {
      this.empresa = nuevaEmpresa;
    },
    seguimientoLlega: function (nuevoSeguimiento) {
      this.switchEstado = nuevoSeguimiento;
    },
  },
};
</script>
