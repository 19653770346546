import { firebase, db, storage } from "../../../firebase/config";
import moment from 'moment';
const ref = storage.ref();

//Listado para llamar hoy
export async function FBLlamarHoy(idUsuario) {

    try {
        ///cf-cartera/Asignaciones/g5XK9n5Fdche1oHnYM6x

        //Se debe traer dinámicamente el id del ejecutivo-------------------------
        const asignacionesRef = db.collection("cf-cartera").doc("Asignaciones").collection(idUsuario);
        const today = moment().startOf('day');
        const querySnapshot = await asignacionesRef.where("proxToque", ">=", firebase.firestore.Timestamp.fromDate(today.toDate()))
            .where("proxToque", "<", firebase.firestore.Timestamp.fromDate(today.add(1, 'day').toDate()))
            .get();
        const asignaciones = [];
        querySnapshot.forEach((doc) => {
            const asignacion = doc.data();
            asignaciones.push(asignacion);
        });
        return asignaciones;

    } catch (error) {
        console.log(error);
    }

}

export async function FBLlamarEnCincoDias(idUsuario) {

    try {
        ///cf-cartera/Asignaciones/g5XK9n5Fdche1oHnYM6x

        //Se debe traer dinámicamente el id del ejecutivo-------------------------
        // Obtener la fecha actual
        const today = new Date();

        // Crear una fecha límite para dentro de 5 días
        const limit = new Date();
        limit.setDate(today.getDate() + 5);

        // Obtener la colección de documentos
        const querySnapshot = await db.collection(`/cf-cartera/Asignaciones/${idUsuario}`)
            .where('proxToque', '>=', today)
            .where('proxToque', '<', limit)
            .get();

        // Obtener los datos de cada documento
        const documentos = querySnapshot.docs.map(doc => doc.data());
        return documentos;

    } catch (error) {
        console.log(error);
    }

}



