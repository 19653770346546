import { db } from "@/firebase/config.js";

export async function FBeliminaOportunidad(jsonLLega) {
    try {
        //console.log(jsonLLega);
        const documentId = jsonLLega.id; // Obtén el ID del documento

        const documentPath = `cf-pedidos/${documentId}`; // Ruta completa del documento
        
        try {
          const docRef = db.doc(documentPath); // Obtiene la referencia al documento
          const docSnapshot = await docRef.get(); // Obtiene el snapshot del documento
        
          if (docSnapshot.exists) {
            await docRef.delete(); // Elimina el documento si existe
            //console.log("Registro eliminado con éxito");
            return true;
          } else {
            //console.log("No se encontró el documento");
            return false;
          }
        } catch (error) {
          console.error("Error al eliminar el registro:", error);
          return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}