import { firebase, db, storage } from "../../firebase/config";
import { FBverClientesNit } from "./gestionCartera/consultasBasicas";

const ref = storage.ref();

//Sirve para mirar que cartera hay nueva y cargarla al sistema
// 1. Se mira el asesor que carteras tiene asignadas
//listadoNits, bolsaSinFiltro, asesor
export async function FBrecorrerCarteras(usuario) {

    //console.log(usuario);
    //console.log('Recorrer carteras');

    let idAsesor = usuario;


    try {
        //0. Traer todos los numero de nit activos
        const resultClientes = await FBverClientesNit();

        //1. Comprobar si existe la base
        const totalNuevoAsignados = await FBverificaNuevasCarteras(resultClientes, idAsesor);

        //console.log(totalNuevoAsignados.length);
        //Si es > 0 entonces hay carteras pendientes, de lo contrario continuar. 
        if (totalNuevoAsignados.length > 0) {
            //console.log("Agregar la bolsa de nuvos asignados al ejecutivo");
            //3. Guardar todos los asignados por id +  
            const resultLlenarBolsaTitular = await FBllenarAsignaciones(totalNuevoAsignados);

            //console.log(resultLlenarBolsaTitular);
            //console.log("Se han cargado los nuevos Titulares");
        } else {
            //console.log("No hay NADA nuevo");
        }

        //console.log("FIN Nuevos Asignados");
    } catch {
        console.log(error);
    }


    return 0;
    //const resultFiltroA = await FBEstrategiaPorItem(listadoNits, bolsaSinFiltro, 'montos_altos', asesor);

    //console.log(resultFiltroA);

    //return resultFiltroA;
}

async function FBverificaNuevasCarteras(resultClientes, idAsesor) {
    try {
        let listadoNits = resultClientes;
        let idCarterasActivas = [];
        let totalMegaBolsa = [];
        //console.log(listadoNits);

        //1. Verificar que cartera hay nueva
        // ruta: /cf-cartera/Carteras/1017131215/UZXsgZJYfXXPR95cUWHC
        //console.log("Paso 1");
        for (let index = 0; index < listadoNits.length; index++) {
            const nitActual = listadoNits[index];

            const empresaRef = db.collection('cf-cartera').doc('Carteras').collection(nitActual);
            const snapshot = await empresaRef.where('estado', '==', true).get();

            if (snapshot.empty) {
                //console.log('No matching documents.');
                //return false;
            }
            //Traemos todas las carteras. que estan estado activo.
            //Y las asociamos a su nit respectivo
            snapshot.forEach(async doc => {
                let idCartera = doc.id;
                idCarterasActivas.push([{ nit: nitActual, cartera: idCartera }]);
            });
        }

        //console.log(idCarterasActivas);
        //console.log(idCarterasActivas.length);
        //console.log("Paso 2");
        //3. Pendiente por analizar los estados de cada ejecutivo en cada cartera. Guardar todos los false.
        const carterasPendientes = await detectaCarteraPendiente(idCarterasActivas, idAsesor);

        //console.log(carterasPendientes);
        //console.log(carterasPendientes.length);

        for (let index = 0; index < carterasPendientes.length; index++) {
            const nitActual = carterasPendientes[index].nit;
            const idCartera = carterasPendientes[index].cartera;
            //console.log(nitActual, idCartera, idAsesor);

            // TRAE TODOS LOS TITULARES ASIGNADOS DE TODAS LAS CARTERAS
            const MegaBolsaTitularesAsignados = await MegaBolsa(nitActual, idCartera, idAsesor);
            //console.log('Bolsa:', nitActual);
            // Retorna todos los titulares asignados a ese Ejecutivo o Asesor.
            //console.log('Retornó MEGA-bolsa');
            //console.log(MegaBolsaTitularesAsignados);
            totalMegaBolsa = _.concat(totalMegaBolsa, MegaBolsaTitularesAsignados);
        }


        //console.log("MegaBolsa de: ", totalMegaBolsa.length);


        //console.log('Sale de VNC');
        //Sale MegaBolsa para ser cargada al Ejecutivo
        return totalMegaBolsa;
        //return true

    } catch (error) {
        console.log('Error:', error);
    }
}

async function detectaCarteraPendiente(idCA, idAsesor) {

    let pendientes = [];

    for (let index = 0; index < idCA.length; index++) {
        const nitActual = idCA[index][0].nit;
        const idCartera = idCA[index][0].cartera;

        const asesoresRef = db.collection('cf-cartera').doc('Carteras').collection(nitActual).doc(idCartera);


        //.where('asesores', 'array-contains', 'west_coast')//.where('tipiUlti', '==', 'Fallecido');
        const snapshot = await asesoresRef.get();

        if (!snapshot.exists) {
            //console.log('No such document!');
            return;
        } else {
            //console.log('Document data:', snapshot.data().asesores);

            let asesores = snapshot.data().asesores;

            //1. Buscar en el array traido  // '0N27BVAKtfhnaOsB8pv2'
            const resultado = asesores.find(asesorS => asesorS.idAsesor === idAsesor);

            //console.log(resultado);

            //2. Validar si esa cartera ya fue cargada
            if (resultado != undefined) {

                if (resultado.barrio == true) {
                    //console.log('Ha sido cargada, siguiente');
                } else {
                    //console.log('Nuevos titulares, se deben cargar. ');
                    pendientes.push({ nit: nitActual, cartera: idCartera });
                }
            } else {
                //console.log('No tiene asignaciones en esta cartera');
            }



            //if (snapshot.data().asesores != undefined) {}
        }
    }


    return pendientes;
}


//1. Rretorna Array de titulares Asginados por cartera. 
async function MegaBolsa(clienteNit, idCartera, asesor) {

    //console.log('Cartera Nueva - MEGABOLSA ');
    try {
        let arayTitularCarteras = [];
        //console.log('Bienvenido Mega Bolsa');
        /*console.log('id:', idCartera);
        console.log('Nit: ', clienteNit);
        console.log('Asesor:', asesor);
        console.log('Datos para megaB');*/

        const totalRegistrosCarteraRef = db.collection('cf-cartera').doc('Carteras').collection(clienteNit).doc(idCartera).collection('registros').where('asignadoActual', 'array-contains-any', [asesor]);

        const consulta = await totalRegistrosCarteraRef.get();//dividr por el total de carteras.
        //console.log('Sale de consulta -------', consulta.size);

        if (consulta.empty) {
            //console.log('No matching documents.');
            return;
        }
        consulta.forEach(async doc => {
            //console.log(doc.id, '=>', doc.data());
            //doc.data() Trae todos los datos que contiene el titular 
            let registro = doc.data();
            registro.idCartera = idCartera;
            registro.idRegistro = doc.id;
            registro.Cedula = doc.data().data.Cedula;//Nuevo para que trabaje el buscador por cedula
            registro.nitCliente = clienteNit;
            arayTitularCarteras.push(registro);
        });

        //console.log('Sale:' + arayTitularCarteras.length);
        //Actualiza el barrido del asesor para que no vuelva a entrar, encaso que sea false deberá volver a entrar.
        const resultCambioBarrido = await actualizaBarridoAgente(clienteNit, idCartera, asesor);

        //Retorna MegaBolsa por Cartera.
        return arayTitularCarteras;

    } catch (error) {
        //console.log('Error::', error);
    }
}

// Actualiza el estado del Ejecutivo en la nueva Cartera Asignada
async function actualizaBarridoAgente(element, idCartera, asesor) {
    try {
        //Entrar a actualizar el asesor en la cartera para informar que esos titulares ya han sido cargados. y el barrido ha sido exitoso
        //let AsesoresLimpios = { idAsesor: idAsesor, puntajeCartera: 0, monto: element.TOTAL, asignados: element.totalAsignados, barrio: false };
        //console.log('Nit: ', element);
        //console.log('idCartera: ', idCartera);
        //console.log('asesor: ', asesor);
        let nitCliente = element;
        let AsesorLogueado = asesor;
        let AsesoresLimpios = { barrio: true };
        const arrayUnionB = firebase.firestore.FieldValue.arrayUnion;
        //.doc(idCartera)
        const guardaAsesoresEnCartera = db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(idCartera);

        const resultCarteraAsesores = await guardaAsesoresEnCartera.get();
        //console.log('Resultado *******************************');
        if (!resultCarteraAsesores.exists) {
            //console.log('No such document!');
            return;
        } else {
            //console.log('Document data:', resultCarteraAsesores.data());

            if (resultCarteraAsesores.data().asesores != undefined) {
                let juegoAsesores = resultCarteraAsesores.data().asesores;

                juegoAsesores.forEach(async element => {
                    //console.log('element: ', element.idAsesor);
                    //console.log('Asesor Actual:', AsesorLogueado);
                    if (element != undefined) {
                        if (element.idAsesor == AsesorLogueado && element.idAsesor != undefined) {
                            //console.log('EXISTE ASESOR');
                            //console.log(element);

                            let modificacion = element;
                            modificacion.barrio = true;

                            let posicion = _.findIndex(juegoAsesores, ['idAsesor', element.idAsesor]);

                            juegoAsesores.splice(posicion, 1, modificacion);


                            //console.log('Modifica...');
                            //console.log(juegoAsesores);


                            //encontrarElemento Posición del elemento que se encontró

                            // Replace item at index using native splice
                            //arr.splice(index, 1, {id: 100, name: 'New object.'});

                            //console.log(posicion);

                            //Eso marca que ese Asesor ya pasó por aquí y no tiene porque volver a pasar a no ser que sea false, (Se agrega titulares ejemplo)
                            const guardaAsesoresEnCartera = await db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(idCartera).update({ asesores: juegoAsesores });

                            //console.log(guardaAsesoresEnCartera);



                            return;
                        } else {
                            //console.log('No Existe');
                            return;
                        }
                    } else {
                        return;
                    }
                });
            } else {
                //console.log('No existen datos...');

            }


            return;
        }



        //return true;
    } catch (error) {
        console.log('Error Barrido: ', error);
    }

}

//Guarda todos los asginados del titular por primera vez
export async function FBllenarAsignaciones(bolsaAgente) {
    let totalAsignados = bolsaAgente;
    //console.log(totalAsignados);
    //console.log('FBllenarAsignaciones');
    //console.log(totalAsignados.length);
    //let gfg = _.orderBy(totalAsignados, ['Cedula'], ['desc']);
    //console.log(gfg);

    var limpiandoTA = totalAsignados.filter((dato) => {
        return dato !== undefined;
    });

    //console.log(cars);

    try {
        let bolsaCortaAsesor = [];
        limpiandoTA.forEach(async element => {
            //console.log('***************************************************************');
            //console.log(element.asignadoActual);
            //console.log("Titular: ", element.idRegistro);
            //console.log("Agente: ", element.asignadoActual[0]);


            //Se agregó la cedula para el buscador. 
            let idAsesor = element.asignadoActual[0];
            let datosPrincipales = ({ 'idRegistro': element.idRegistro, 'toques': 0, 'Cedula': element.Cedula, 'idCartera': element.idCartera, 'idRegistro': element.idRegistro, 'nitCliente': element.nitCliente });
            ///cf-cartera/Asignaciones/80852032/PU9p9iyOQ4z5MZ3TQMQB
            const resulBolsa = await db.collection('cf-cartera').doc('Asignaciones').collection(idAsesor).add(datosPrincipales);
            bolsaCortaAsesor.push(element.idRegistro);

        });
        //Devuelve solo los id para iniciar a trabajar
        //console.log(bolsaCortaAsesor);
        //return true;
        return limpiandoTA;
    } catch (error) {
        //console.log(error);
    }
}