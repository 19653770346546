<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          icon
          color="teal accent-3"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark> mdi-account-box-outline </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ titulo }}
        </v-card-title>

        <v-card-text>
          <v-list dense>
            <v-subheader>Contacto(s)</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in contactos" :key="i">
                {{ item }}
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list dense>
            <v-subheader>Celular(es)</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in cel"
                :key="i"
                @click="copia(item)"
              >
                {{ item }}
                <v-divider vertical inset class="pa-3"></v-divider>
                <v-list-item-icon class="pa-3">
                  <v-icon left> mdi-content-copy </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list dense>
            <v-subheader>Correo(s) Electrónico(s)</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in mail"
                :key="i"
                @click="copia(item)"
              >
                {{ item }}

                <v-divider vertical inset class="pa-3"></v-divider>
                <v-list-item-icon class="pa-3">
                  <v-icon left> mdi-content-copy </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { copiarTexto } from "../../../components/Copiar/copiar";

export default {
  name: "detalleCliente",
  props: ["titulo", "contactos", "cel", "mail"],
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    async copia(event) {
      //console.log(event);
      const rest = await copiarTexto(event);

      //console.log("Ha copiado...");
      //console.log(rest);
    },
  },
};
</script>