import { db } from "@/firebase/config.js";
import axios from "axios";
import { FBconsultaToken } from "./tokens";


// Guardar en base de datos
async function saveMessageToDatabase(senderPhoneNumber, messaging, timestamp, contacts, doliente) {
    try {
        //console.log(senderPhoneNumber, messaging, timestamp, contacts, doliente);
        const userRef = db.collection("cf-whatsapp").doc(senderPhoneNumber);

        // Obtener el documento existente o crear uno nuevo si no existe
        const doc = await userRef.get();

        if (doc.exists) {
            //Segundo mensaje en adelante
            const existingMessages = doc.data().messages;
            existingMessages.push({ body: messaging, timestamp: timestamp, usuario: doliente });
            const updatedMessages = existingMessages.sort((a, b) => a.timestamp - b.timestamp);
            await userRef.update({ messages: updatedMessages, mensajePendiente: false });
        } else {
            //Primera vez 
            await userRef.set({ messages: [{ body: messaging, timestamp: timestamp, usuario: doliente }], usuario: contacts, mensajePendiente: false });
        }
    } catch (error) {
        console.log('Error al guardar el mensaje en la base de datos:', error);
    }
}

export async function FBenviarMensajeWhatsapp(jsonLlega) {
    try {

        let phoneModificado = "";

        if (jsonLlega?.fuente === "nuevoUsuario") {
            phoneModificado = (jsonLlega.indicativo ? jsonLlega.indicativo : "57") + jsonLlega.telefonoSelect;

        } else {
            //phoneModificado = jsonLlega.indicativo + jsonLlega.telefonoSelect;
            phoneModificado = (jsonLlega.indicativo ? jsonLlega.indicativo : "57") + jsonLlega.telefonoSelect;

        }

        const TOKEN = await FBconsultaToken();

        const accessToken = TOKEN[0];
        const IDENTIFICADORPHONE = TOKEN[1];
        //const IDENTIFICADORWHATSAPP = TOKEN[2];

        // Numero TEST https://graph.facebook.com/v17.0/102503192897456/messages 
        //const apiUrl = `https://graph.facebook.com/v17.0/${IDENTIFICADORPHONE}/messages`;
        const apiUrl = `https://graph.facebook.com/v17.0/${IDENTIFICADORPHONE}/messages`;

        const requestData = {
            messaging_product: "whatsapp",
            to: phoneModificado,
            type: "text",
            text: {
                //body: "Hola..."
                body: jsonLlega.mensaje
            }
        };

        const response = await axios.post(apiUrl, requestData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });


        //console.log("Mensaje enviado:", response.data);

        // Si se envia el mensaje que se agregue a la cola de mensajes. 
        const timestamp = new Date();


        saveMessageToDatabase(phoneModificado, requestData.text.body, timestamp, jsonLlega.contacto, jsonLlega.doliente);

        return true;

    } catch (error) {
        console.error("Error al enviar el mensaje:", error.response.data);
    }
}

export async function FBBienvenidaMensajeWhatsapp(jsonLlega) {
    try {
        //console.log(jsonLlega);
        const phoneModificado = (jsonLlega.indicativo ? jsonLlega.indicativo : "57") + jsonLlega.telefonoSelect;


        const TOKEN = await FBconsultaToken();
        const accessToken = TOKEN[0];
        const IDENTIFICADORPHONE = TOKEN[1];

        const apiUrl = `https://graph.facebook.com/v17.0/${IDENTIFICADORPHONE}/messages`;

        const requestData = {
            messaging_product: "whatsapp",
            to: phoneModificado,
            type: "template",
            template: {
                //name: "autorizacion_inicio",
                name: jsonLlega.template,
                language: {
                    code: "es" // Código de idioma (en_US para inglés, es_ES para español, etc.)
                }
            }
        };

        const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        };

        const response = await axios.post(apiUrl, requestData, { headers });

        console.log("Mensaje enviado:", response.data);

        // Si se envía el mensaje, se agrega a la cola de mensajes.
        const timestamp = new Date();

        saveMessageToDatabase(phoneModificado, `Plantilla: ${jsonLlega.template}`, timestamp, jsonLlega.contacto, jsonLlega.doliente);

        return true;
    } catch (error) {
        console.error("Error al enviar el mensaje:", error.response.data);
    }
}

