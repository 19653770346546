import { firebase, db, storage } from "../../firebase/config"

const ref = storage.ref();

//Busca Clientes
export async function FBverClientesCarteras() {
    let data = [];
    try {
        const clientesRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const snapshot = await clientesRef.get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            //False no existe
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            data.push({ id: doc.id, info: doc.data() });
        });

        //True es existe
        return data;

    } catch (error) {
        console.log(error);
    }

}

export async function FBtotalClientes(nits, tipificaciones) {
    var juegoNits = nits;
    var juegoTipi = tipificaciones;

    const registroFiltrado = [];
    let esValido = true;

    //console.log(juegoNits, juegoTipi);

    try {
        // Todas las Carteras...
        var idCarteras = [];
        for (let index = 0; index < juegoNits.length; index++) {
            const nit = juegoNits[index];

            const resultBasico = await db.collection('cf-cartera').doc('Carteras').collection(nit).get();

            if (resultBasico.empty) {
                //console.log('No matching documents.');
                return;
            }

            resultBasico.forEach(doc => {
                //console.log(doc.id, '=>', doc.data());
                idCarteras.push({ nit: nit, idCartera: doc.id })
            });
        }//Cierra for de id carteras

        //console.log(idCarteras);
        // Analizar todas las carteras con las tipificaciones que se indicaron

        if (juegoTipi.length > 0) {
            //console.log('Iniciamos el análisis');
            //console.log('Total idCartera: ', idCarteras.length);
            for (let index = 0; index < idCarteras.length; index++) {
                //console.log(idCarteras[index]);
                const nit = idCarteras[index].nit.toString();
                const idCartera = idCarteras[index].idCartera.toString();



                //console.log(nit , idCartera);
                // /cf-cartera/Carteras/900985264/vT6bTDwqi9Ar89QuoTpO/registros
                const arreglotitulares = await db.collection('cf-cartera').doc('Carteras').collection(nit).doc(idCartera).collection('registros').get();

                arreglotitulares.forEach(doc => {
                    //console.log(doc.id, '=>', doc.data());


                    var dataUsuario = doc.data().observaciones; // Es un array
                    for (let index of dataUsuario) {

                        //let tipificaciones = ['Fallecido', 'Ilocalizado', 'Incobrable', 'Paz y Salvo - Notificado'];
                        let tipificaciones = juegoTipi;
                        // 'Notificar Paz y Salvo', 'Posible Fallecido', 'Posible Incobrable', 'Posible Ilocalizado', 'Solicitud Titulo Valor','Demanda Radicada', 'Iniciar Demanda', 'Posible Demanda','Sin Titulo Valor', 'Novedad ADM', 'Confirmar Pago', 'Verificar Pago', NO aplica porque no es una tifipicación final
                        //se quitan porque sí deben aparecer. no se deben excluir. 

                        //Localizamos si la tipificación está almenos 1 vez en el proceso.
                        let aA = _.find(tipificaciones, (tipi) => {
                            //console.log('-------', datos.Cedula);
                            //console.log('-------', datos.tipiUlti);
                            //console.log('+++++', tipi);
                            //console.log('-------');
                            return tipi == index.efecto;
                        });

                        if (aA == undefined) {
                            //console.log(aA);
                        } else {
                            //console.log(aA);
                            esValido = false;
                            break;
                        }
                    }
                    var dataTitular = doc.data().data.Cedula;

                    //console.log(dataTitular);
                    //Guardamos todos los que nos arrojó las tipificaciones que debemos eliminar de la bolsa...
                    if (!esValido) {
                        registroFiltrado.push({ idTitular: doc.id, asignadoActual: doc.data().asignadoActual[0], cedulaTitular: dataTitular });
                    }
                    esValido = true;
                });

            }// Cierra for 

            //Vamos a eliminar de la bolsa del ejecutivo el titular para que no lo trabaje.



            return registroFiltrado;

        } else {
            console.log('No se analizaron, porque viene vacio');
        }



    } catch (error) {
        console.log(error);
    }
}

export async function FBeliminarRegistrosBolsaEjecutivo(titulares) {
    try {

        for (let index = 0; index < titulares.length; index++) {
            const element = titulares[index];
            //console.log(element);
            //Buscar

            var asignado = element.asignadoActual;
            var idTitularLLega = element.idTitular.toString();

            //console.log(idTitularLLega);

            const titularRef = db.collection('cf-cartera').doc('Asignaciones').collection(asignado);
            //const doc = await cityRef.where('idRegistro', '==', idTitularLLega).get();
            const snapshot = await titularRef.where('idRegistro', '==', idTitularLLega).get();
            if (snapshot.empty) {
                console.log('No matching documents.');
               // return;
            }

            snapshot.forEach(async doc => {
                //console.log(doc.id, '=>', doc.data());
                await db.collection('cf-cartera').doc('Asignaciones').collection(element.asignadoActual).doc(doc.id).delete();
            });


            //Eliminar
            // /cf-cartera/Asignaciones/g5XK9n5Fdche1oHnYM6x/wdhPQtvv7hxuiN2p6Ym8
            // await db.collection('cf-cartera').doc('Asignaciones').collection(element.asignadoActual).doc(element.idTitular).delete();
        }

        //const res = await db.collection('cities').doc('DC').delete();
        return true;
    } catch (error) {
        console.log(error);
    }
}

