import { db } from "@/firebase/config.js";

export async function FBconfiguracionWhatsapp(jsonLlega) {
    try {
        //console.log(jsonLlega);

        // /recursos/whatsapp

        if (jsonLlega.accion === "consulta") {
            // Obtén una referencia al documento que deseas eliminar
            const docRef = db.doc(`recursos/whatsapp`);

            try {
                // Utiliza el método get() para obtener los datos del documento
                const docSnapshot = await docRef.get();

                if (docSnapshot.exists) {
                    //console.log(docSnapshot.data()); // Obtenemos los datos del documento usando data()
                    const configuracion = docSnapshot.data();
                    return [true, configuracion];
                } else {
                    // El documento no existe, puedes agregar aquí la lógica para crear el documento si es necesario
                    //console.log("El documento no existe.");
                    return [false, null];
                }
            } catch (error) {
                //console.error("Error al obtener el documento:", error);
                return [false, null];
            }
        }

        if (jsonLlega.accion === "actualiza") {
            // Obtén una referencia al documento que deseas actualizar
            const docRef = db.doc(`recursos/whatsapp`);

            try {
                // Utiliza el método get() para obtener los datos del documento
                const docSnapshot = await docRef.get();

                if (docSnapshot.exists) {
                    // Obtenemos los datos del documento usando data()
                    const configuracion = docSnapshot.data();

                    //console.log(configuracion);

                    // Actualiza el campo deseado en el objeto configuracion
                    configuracion[jsonLlega.clave] = jsonLlega.valor;

                    // Utiliza el método update() para guardar los cambios en el documento
                    await docRef.update(configuracion);

                    // Retorna true para indicar que la actualización fue exitosa
                    return [true, null];
                } else {
                    // El documento no existe, puedes agregar aquí la lógica para crear el documento si es necesario
                    //console.log("El documento no existe.");
                    return [false, null];
                }
            } catch (error) {
                //console.error("Error al obtener el documento:", error);
                return [false, null];
            }
        }




    } catch (error) {
        console.log("Error Whatsapp Configuracion" + error);
    }
}

export async function FBtemplateWhatsapp() {
    try {
        // Obtener referencia a la colección 'whatsapp'
        const whatsappRef = db.collection("recursos").doc("whatsapp");

        // Obtener el documento y extraer el array 'plantillas'
        const doc = await whatsappRef.get();

        if (doc.exists) {
            const datos = doc.data();
            return datos;
        } else {
            console.log("No hay plantillas");
            return false;
        }


    } catch (error) {
        console.log("Error:" + error)
    }
}