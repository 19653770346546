<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber" dark v-bind="attrs" v-on="on" fab x-small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card dark>
        <v-card-title class="text-h5">Apoyo</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-if="departamento != null"
                v-model="selectedDepartamento"
                :items="departamento"
                label="Departamento"
                item-text="title"
                item-value="coleccion"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="selectedColaborador"
                :items="colaboradores"
                label="Colaborador"
                disabled
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="observacion"
                outlined
                name="input-7-4"
                label="Describa el apoyo que requiere"
                value=""
              ></v-textarea>
            </v-col>
          </v-row>

          <div class="body-2">
            El colaborador tiene x tareas pendientes, fecha aprox de entrega x
            Responsable Traer numero de tareas de cada persona para que las
            cosas se vuelvan equitativas.
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="agregar">Agregar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ayudaDep",
  props: ["modulos"],
  components: {},
  data() {
    return {
      dialog: false,
      departamento: [
        { coleccion: "Contabilidad", title: "Contabilidad" },
        {
          coleccion: "Marketing y Publicidad",
          title: "Marketing y Publicidad",
        },
        { coleccion: "Juridico", title: "Jurídico" },
        { coleccion: "Buzz", title: "Buzz" },
      ],
      colaboradores: ["Javier", "Mauricio"],
      selectedDepartamento: null,
      selectedColaborador: null,
      observacion: null,
    };
  },
  created() {
    //console.log(this.modulos);
    this.departamento = this.modulos;
  },
  methods: {
    agregar() {
      //console.log(this.selectedDepartamento);
      if (this.selectedDepartamento) {
        const departamentoObj = this.departamento.find(
          (item) => item.coleccion === this.selectedDepartamento
        );
        const data = {
          departamento: departamentoObj ? departamentoObj.coleccion : null,
          title: departamentoObj ? departamentoObj.title : null,
          colaborador: this.selectedColaborador
            ? this.selectedColaborador
            : "N/A",
          observacion: this.observacion,
        };
        this.$emit("agregarApoyo", data);
        this.dialog = false;
        this.selectedDepartamento = null;
        this.selectedColaborador = null;
        this.observacion = null;
      }
    },
  },
};
</script>
