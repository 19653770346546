<template>
  <div>
    <v-icon class="mr-2" @click="downloadFile" v-show="!progreso">
      mdi-arrow-down-thin-circle-outline
    </v-icon>

    <v-progress-circular
      v-show="progreso"
      indeterminate
      color="primary"
    ></v-progress-circular>
    {{ mensaje }}
  </div>
</template>

<!-- 

  <v-col>
                                          <v-icon
                                            small
                                            class="mr-2"
                                            @click="consultaFecha(item.detalle)"
                                          >
                                            mdi-phone-forward
                                          </v-icon>
                                          <v-icon
                                            small
                                            class="mr-2"
                                            @click="consultaFecha(item.detalle)"
                                          >
                                            mdi-email
                                          </v-icon>
                                        </v-col>
-->
  
  
  <script>
import exportFromJSON from "export-from-json";
//C:\Users\User\Firebase\byb-1\src\components\Cartera\gestionDCM\consultaACM-exportarCSV.js
import { FBCSV1 } from "@/components/Cartera/gestionDCM/consultaACM-exportarCSV.js";

export default {
  name: "export",
  components: {},
  props: ["datos", "tipificacion"],
  data: () => ({
    investorsList: [
      {
        id: 0,
        name: "Gautam",
        email: "gautam@example.com",
        investment: "Stocks",
      },
      {
        id: 1,
        name: "Sam",
        email: "sam@example.com",
        investment: "Bonds",
      },
      {
        id: 2,
        name: "Tim",
        email: "tim@example.com",
        investment: "Options",
      },
      {
        id: 3,
        name: "Kim",
        email: "kim@example.com",
        investment: "Stocks",
      },
      {
        id: 4,
        name: "John",
        email: "john@example.com",
        investment: "Options",
      },
      {
        id: 5,
        name: "Lee",
        email: "lee@example.com",
        investment: "Stocks",
      },
      {
        id: 6,
        name: "Charlotte",
        email: "charlotte@example.com",
        investment: "Options",
      },
      {
        id: 7,
        name: "Amy",
        email: "amy@example.com",
        investment: "Stocks",
      },
      {
        id: 8,
        name: "Mark",
        email: "mark@example.com",
        investment: "Bonds",
      },
      {
        id: 9,
        name: "Rose",
        email: "rose@example.com",
        investment: "Stocks",
      },
    ],
    datosLlegan: [],
    mensaje: "",
    progreso: false,
  }),
  mounted() {
    //console.log("Montado");
    //console.log(this.datos.detalle);
  },
  updated() {
    //console.log("Actualizado");
    //console.log(this.datos.detalle);
  },
  methods: {
    async organizarDatos() {
      //console.log("Organizar Datos...");

      let datosLlegan = this.datos.detalle;

      for (let index = 0; index < datosLlegan.length; index++) {
        const element = datosLlegan[index];

        var Cliente = element.Cliente;
        var Cedula = element.cedula;

        const resultado = await FBCSV1(element);
        // console.log("Sale! " + index);
        //console.log(resultado);
        /*
         Nombre: datosNuevos.Nombre,
                Cuenta: datosNuevos.Cuenta,
                Email: datosNuevos.email,
                Telefonos: Telefono,
                Observaciones: obsevaciones,
                */

        this.datosLlegan.push({
          Cliente: Cliente,
          Cedula: Cedula,
          Nombre: resultado.Nombre,
          Cuenta: resultado.Cuenta,
          Monto: resultado.Monto,
          Email: resultado.Email,
          Telefonos: resultado.Telefonos,
          Proceso: resultado.Observaciones,
        });
        //this.datosLlegan.push(element);
        //console.log(element);
      }
    },
    async downloadFile() {
      //Vaciar el array para que no se peguen.
      this.datosLlegan = [];
      //this.mensaje = "Organizando Datos...";
      this.progreso = true;
      let tipificacionFinal = this.tipificacion;

      await this.organizarDatos();

      this.mensaje = "Trabajando CSV...";
      //const data = this.investorsList;
      const data = this.datosLlegan;
      const fileName = "B&B-" + tipificacionFinal;
      const exportType = exportFromJSON.types.csv;

      if (data) exportFromJSON({ data, fileName, exportType });

      this.mensaje = "..";
      this.progreso = false;
    },

    fechas(datoFecha) {
      //console.log(datoFecha.nanoseconds, " --//-- ", datoFecha.seconds);
      let nanosecondsD = datoFecha.nanoseconds;
      let secondsD = datoFecha.seconds;

      let fechaReturn = new Date(secondsD * 1000 + nanosecondsD / 1000000);
      //console.log(fechaReturn);
      //console.log(fechaReturn.getMonth() + 1);

      return fechaReturn;
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.np-list {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #107fda;
  background: #ffffff;
  border-radius: 6px;
  color: #107fda;
}
.np-btn {
  padding: 2px 8px;
  margin: 12px 8px;
  border: 1px solid #107fda;
  width: 110px;
  background: #107fda;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
}
</style>
  