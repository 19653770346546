<template>
  <v-container>
    <v-row
      class="mb-6"
      justify="center"
      align="center"
      no-gutters
      v-if="modulos == null"
    >
      <v-progress-circular size="50" color="primary" indeterminate>
        Cartera
      </v-progress-circular>
    </v-row>

    <v-row class="pa-2" v-else dense>
      <!-- Dependiendo los roles se ve el modulo, sino está en el rol no lo muestra.-->
      <v-col
        v-for="card in modulos.cards"
        :key="card.title"
        :md="card.flex"
        v-show="roles(card.roles)"
      >
        <v-card :color="card.color" v-if="card.visible" shaped outlined hover>
          <v-img
            :src="card.src"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
            <v-card-title class="titulos">{{ card.title }}</v-card-title>
          </v-img>

          <v-card-actions>
            <EditarModulo
              :title="card.title"
              :datos="card"
              :modulos="modulos"
              :rolPrueba="rolPrueba"
            />

            <v-spacer>
              <div v-if="card.verMensaje">
                <marquee>{{ card.mensaje }}</marquee>
              </div>
            </v-spacer>

            <v-btn v-if="card.alert" disabled depressed icon>
              <v-badge dot overlap bordered color="green" :content="card.badge">
                <v-icon>mdi-alarm-light-outline</v-icon>
              </v-badge>
            </v-btn>

            <v-btn
              v-else
              @click="abrirPanel(card.title)"
              :disabled="!card.estado"
              icon
            >
              <v-icon>mdi-arrow-top-right-thin</v-icon>
            </v-btn>
            <Localizadores v-if="card.modalCard" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import Localizadores from "./localizadores.vue";
import EditarModulo from "./adminModulos.vue";
import store from "../../../store";

export default {
  name: "card01",
  data() {
    return {
      rolPrueba: store.state.user[2][0].data.cargo,
    };
  },
  components: {
    Localizadores,
    EditarModulo,
  },
  props: {
    modulos: {
      type: Object,
      default: null,
    },
  },
  methods: {
    abrirPanel(panel) {
      const panelRoutes = {
        Contabilidad: "/Cartera",
        "Recurso Humano": "/rh",
        Secretaria: "/sc",
        Jurídico: "/jd",
        Administrativo: "/adm",
        "Mercadeo y Publicidad": "/Mercadeo",
        PESV: "/PESV",
        Negocios: "/Ventas", // Se cambió aquí y en la base de datos en "modulos"
        Producción: "/Produccion",
      };

      if (panelRoutes[panel]) {
        this.$router.push(panelRoutes[panel]);
      }
    },
    roles(llega) {
      return _.includes(llega, this.rolPrueba);
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
