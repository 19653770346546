<template>
  <v-row>
    <v-col cols="8">
      <span class="caption"> {{ texto }} </span> 
    </v-col>
    <v-col cols="4">
      <h3>{{ tiempo }}</h3>
    </v-col>
  </v-row>
</template>
  
  <script>
export default {
  data() {
    return {
      tiempo: 90,
      intervalId: null,
    };
  },
  props: {
    tiempoLimite: {
      type: Number,
      required: true,
    },
    texto: {
      type: String,
      required: true,
    },
  },
  created() {
    this.startContador();
  },
  methods: {
    startContador() {
      this.intervalId = setInterval(() => {
        this.tiempo++;
        if (this.tiempo === this.tiempoLimite) {
          clearInterval(this.intervalId);
          this.$emit("tiempo-alcanzado");
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.card-small {
  height: 50px; /* Ajusta la altura según tus necesidades */
}
</style>
  