<template>
  <div justify="center">
    <v-dialog v-model="dialog" scrollable max-width="80%" persistent>
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
              <v-icon>mdi-cash-100</v-icon>
            </v-btn>
          </template>
          <span>Mi Avance</span>
        </v-tooltip>
      </template>

      <v-card class="mx-auto">
        <v-toolbar color="primary" dark>Cargar Cartera</v-toolbar>

        <v-card-text>
          <p>En desarrollo</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
          <!--
          <v-btn color="blue darken-1" text @click="dialog = false">
            Save
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import loadCartera from "../../../components/loadFile/carteras/cartera.vue";

export default {
  name: "misIndicadores",
  props: [],
  components: {
    loadCartera,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {},
  computed: {
    retornaEstadoMensaje() {
      //return (this.sheet = this.estadoMensaje);
    },
  },
};
</script>