import { storage, db } from "@/firebase/config.js";


export async function eliminarUnaImagen(url) {
    try {

        // Decodificar la URL
        let decodedUrl = decodeURIComponent(url);

        // Eliminar los parámetros de consulta
        decodedUrl = decodedUrl.split('?')[0];

        // Extracción del nombre del archivo desde la URL decodificada
        let fileName = decodedUrl.split('/').pop();

        // Crear una referencia a la imagen en Firebase Storage
        let imageRef = storage.ref(`st-mercadeo/repositorio/${fileName}`);


        // Eliminar el archivo
        await imageRef.delete();

        return true;

        // Notificar al usuario que la imagen ha sido eliminada
        //this.$toasted.show("Imagen eliminada con éxito.", { type: "success" });

    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function todasLasImagenes() {
    try {
        // Crear referencia a la ruta del directorio
        const imageDirectoryRef = storage.ref('st-mercadeo/repositorio');

        // Listar todos los archivos en el directorio
        const result = await imageDirectoryRef.listAll();

        // Iniciar la recopilación de todas las promesas para obtener las URLs
        const urlPromises = result.items.map(item => item.getDownloadURL());

        // Esperar a que todas las promesas se resuelvan y devolver el resultado
        return await Promise.all(urlPromises);

    } catch (error) {
        console.error("Error obteniendo las imágenes:", error);
        throw error;  // Esto propagará el error para que pueda ser manejado por el llamante si es necesario
    }
}

export async function subirImageFB(json) {
    try {
        //console.log(json);

        // Usamos map para devolver una lista de promesas
        const uploadPromises = json.imageFile.map(async (file, index) => {
            // Extraer la extensión del archivo
            const fileExtension = file.name.split('.').pop();

            // Obtener el nombre del archivo sin la extensión
            const fileNameWithoutExtension = file.name.slice(0, - (fileExtension.length + 1));  // +1 para incluir el punto

            // Crear una referencia al archivo con su extensión original
            const fileRef = storage.ref(`st-mercadeo/repositorio/${fileNameWithoutExtension}.${fileExtension}`);

            // Subir el archivo a Firebase
            await fileRef.put(file);
        });

        // Espera hasta que todas las imágenes estén cargadas
        await Promise.all(uploadPromises);

        // Devuelve la lista de URLs de descarga
        return [true, "ok"];

    } catch (error) {
        //console.log(error);
        return [false, error];
    }
}

export async function subirRegistroFB(json) {
    try {
        //console.log(json);

        //Eliminamos el archivo
        delete json.imageFile;

        const docRef = await db.collection("cf-catalogo").add(json);;
        //console.log("Document written with ID: ", docRef.id);

        return [true, docRef.id];
    } catch (error) {
        //console.log(error);
        return [false, error];
    }
}

