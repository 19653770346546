
import { auth, signInWithEmailAndPassword, db, firebase } from "../../firebase/config"

export async function FBvalidarUsuario(correo, contra) {

  try {
    const result = await signInWithEmailAndPassword(auth, correo, contra);
    const user = result.user;
    //console.log(user.email);

    const refUsuario = await FBlocalizaUsuario(user);


    if (refUsuario != undefined) {
      //Entra a marcar time de login
      //console.log(refUsuario[0].data.cedula);
      const resultMarca = await FBmarcaLogin(refUsuario[0].data.cedula, 1);

      if (resultMarca) {
        //console.log(user, refUsuario);
        return [true, user, refUsuario];
      }
    } else {
      return [false]
    }


  } catch (error) {
    //console.log(error);
    return [false];
  }
}

//Busca empleado por correo y estado true
export async function FBlocalizaUsuario(user) {
  //console.log('Localiza Usuario');
  //console.log(user);

  let correoUsuario = user.email;
  //console.log(correoUsuario);

  try {
    //CONSULTA USUARIO
    const empleadosRef = db.collection('cf-recursoHumano').doc('RecursoHumano').collection('Empleados')
      .where('data.correo', '==', correoUsuario)
      /*.where(
        firebase.firestore.FieldPath.documentId(),
        '==',
        correoUsuario,
      )
      .where(
        firebase.firestore.FieldPath.documentId(),
        'in',
        [correoUsuario, ''],
      )*/
      .where(
        'data.estado',
        '==',
        true,
      );

    const snapshot = await empleadosRef.get();

    if (snapshot.empty) {
      return;
    }

    let datos = [];

    snapshot.forEach(doc => {
      let jsonData = doc.data();
      jsonData.id = doc.id;
      datos.push(jsonData);
    });
    //END CONSULTA USUARIO

    //CONSULTAR SI EL CLIENTE YA PAGÓ
    ///cf-inicial/administrador

    const clienteRef = db.collection('cf-inicial').doc('administrador');
    const doc = await clienteRef.get();
    if (!doc.exists) {
      //console.log('No such document!');
    } else {
      //console.log('Document data:', doc.data());
      var estadoCliente = doc.data().estado;

      //console.log(estadoCliente);
      if (!estadoCliente) {
        //console.log('No puede ingresar por pago.');
        return false;//Cierra el programa porque no ha pagado....
      }
    }

    //console.log(datos);

    return datos;
    //return ([true, res.id])

  } catch (error) {
    //console.error('error: ' + error);
    return false
  }/**/
}

//Agregar marca de login
export async function FBmarcaLogin(cedula, accion) {

  try {
    const nueveIngreso = { fecha: firebase.firestore.Timestamp.now(), accion: accion };
    const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

    const docRef = db.collection('cf-recursoHumano').doc('RecursoHumano').collection('Login').doc(cedula);

    await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(docRef);
      if (!doc.exists) {
        transaction.set(docRef, { marca: [nueveIngreso] });
      } else {
        transaction.update(docRef, { marca: arrayUnion(nueveIngreso) });
      }
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/*export const ipActual = https.onRequest((request, response) => {
  console.log(`My IP is ${request.ip}`);
});*/