<template>
  <div>
    <v-col cols="12" v-if="empleados.length > 0">
      <h3>Historial de cambios</h3>
      <v-spacer class="mt-5"></v-spacer>
      <ul id="example-1">
        <li
          v-for="(item, key) in ultimosElementosAray(
            titularActual.timeRegistroModificado
          )"
          :key="key"
        >
          <span class="font-weight-black text-capitalize">
            💬 {{ item.tipoCambio }}
          </span>
          <span> 📆 {{ timeTodate(item.fecha) }}</span>
          🤠
          <span class="text-decoration-none text-capitalize">
            {{ ubicandoEjecutivo(item.usuario) }}
          </span>
        </li>
      </ul>
    </v-col>
    <v-progress-circular
      v-else
      indeterminate
      color="amber"
    ></v-progress-circular>
  </div>
</template>

<script>
import { FBLocalizarEjecutivo } from "../../../../components/RecursoHumano/listadoEmpleados";

export default {
  name: "gestionCarteraHistorialTitular",
  props: ["titularActual"],
  data: () => ({
    empleados: [],
  }),
  mounted() {
    //Ejecutamos la busqueda de Ejecutivos
    //console.log("Se ha montado Historial de pagos.");
    //console.log(this.empleados);
    //console.log(this.titularActual);
    this.buscaEjecutivoFB();
  },
  methods: {
    ultimosElementosAray(elemento) {
      //let aNumeros = [1, 5, 6, 7, 8, 9, 10, 12];
      //console.log("UltimomElementoAray");
      //console.log(elemento.length);

      if (elemento != undefined) {
        let aNuevo = null;
        //1. Los ultimos 6 elementos del array
        aNuevo = elemento.slice(-40);
        //2. Se invierte el array para leer los ultimos cambios de primera.
        aNuevo = _.reverse(aNuevo);

        //console.log(aNuevo);
        return aNuevo;
      } else {
        var array = [];
        return array;
      }
    },
    timeTodate(a, b) {
      //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.

      if (typeof a == "object" && a != null) {
        //console.log(a);
        let time = {
          seconds: a.seconds,
          nanoseconds: a.nanoseconds,
        };

        const fireBaseTime = new Date(time.seconds * 1000);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        //var convertida = fireBaseTime.toDateString();

        return fireBaseTime.toLocaleDateString("es-ES", options);
      } else if (typeof a == "string" && b == "pagos") {
        //this.fechaComporobantePago
        //pagos-431800506-063779341166.378000000.pdf
        let array1 = _.split(a, "-");
        //console.log("Fichas -------------------------------");
        //console.log(array1);
        const fireBaseTime = new Date(array1[0] * 1000 + array1[1] / 1000000);
        const date = fireBaseTime.toDateString();
        const atTime = fireBaseTime.toLocaleTimeString();
        //console.log("Es un Timespam");
        //console.log(date, atTime);
        const resultado = date + " / " + atTime;

        return resultado;
      } else {
        return a;
      }
    },
    async buscaEjecutivoFB() {
      const ejecutivoLlega = await FBLocalizarEjecutivo();
      //this.empleados.push(ejecutivoLlega.data.empleado);
      //console.log(ejecutivoLlega);
      this.empleados = ejecutivoLlega;
    },
    ubicandoEjecutivo(a) {
      //console.log("Llega: ", a);
      //console.log(this.empleados.length);

      var filtro = _.filter(this.empleados, (nombres) => {
        //console.log(nombres);
        return nombres.id === a;
      });

      //console.log(filtro[0].info.data);
      if (filtro[0] != undefined) {
        return filtro[0].info.data.empleado;
      } else {
        return a;
      }
    },
  },
};
</script>

<style scoped>
</style>