<template>
  <v-card color="amber">
    <v-card-title>
      Listado Prospectos y Clientes
      <v-spacer></v-spacer>
      <!-- BOTONERA BARRA AMARILLA -->
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn color="secondary" fab x-small dark>
        <v-icon>mdi-update</v-icon>
      </v-btn>

      <agregarTarea @agregaItem="agregarItemF" />
    </v-card-title>

    <v-data-table
      dark
      :headers="headers"
      :items="desserts"
      :search="search"
      sort-by="calories"
      class="elevation-1"
      fixed-header
      height="200px"
    >
      <template v-slot:top>
        <!-- EL MODAL -->
        <v-toolbar flat>
          <v-toolbar-title>Buscar</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- BUSCADOR -->
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar por cualquier item"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <v-select
            :items="items"
            label="Filtra por Persona"
            dense
            outlined
            single-line
            hide-details
            max-height="10px"
          ></v-select>

        

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- LA TABLA -->
      <!-- Se debe hacer por item calories-->
      <template v-slot:item.calories="{ item }">
        <v-chip :color="getColor(item.calories)" dark>
          {{ item.calories }}
        </v-chip>
      </template>

      <!-- Se debe hacer por item actions-->
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col>
            <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          </v-col>
          <v-col>
             <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </v-col>
        </v-row>
        
       
      </template>

      <template v-slot:no-data>
        <v-btn color="black" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>



<script>
import agregarTarea from "./agregarTarea.vue";
export default {
  data: () => ({
    dialog: false,
    search: "",
    dialogDelete: false,
    headers: [
      {
        text: "Dessert (100g serving)",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Calories", value: "calories" },
      { text: "Fat (g)", value: "fat" },
      { text: "Carbs (g)", value: "carbs" },
      { text: "Protein (g)", value: "protein" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    items: ["Prospección", "Cierre", "X", "Y"],
  }),
  components: {
    agregarTarea,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    getColor(calories) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },

    initialize() {
      this.desserts = [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
        },
      ];
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    agregarItemF(newItem) {
      //console.log(newItem);
      this.desserts.push(newItem);
    },
  },
};
</script>