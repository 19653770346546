<template>
  <div class="text-center">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="font-weight-bold"
          color="blue-grey darken-4"
        >
          Envio de Emails
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2 text-center">
          <p>
            Por favor escriba un comunicado corto y concreto para aumetnar la
            probabilidad de recibido.
          </p>

          <v-row>
            <v-col cols="4">
              <p>Mensaje SMS</p>
            </v-col>
            <v-col cols="8">
              <v-select
                v-model="selectTelefono"
                :items="celulares"
                label="Selecciona el Celular a notificar"
                solo
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mt-n10">
            <v-col cols="4">
              <p>Mensaje Email</p>
            </v-col>
            <v-col cols="8">
              <v-select
                v-model="selectCorreo"
                :items="email"
                label="Selecciona Correo a notificar"
                solo
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mt-n10">
            <v-col cols="4">
              <p>Tipo de Cobro</p>
            </v-col>
            <v-col cols="8">
              <v-select
                v-model="selectTipocobro"
                :items="tipoDeCobro"
                label="Selecciona tipo de cobro"
                solo
              ></v-select>
            </v-col>
          </v-row>

          <v-row v-if="cargador">
            <v-col cols="12">
              <v-textarea
                v-model="mensaje"
                outlined
                name="input-7-4"
                label="Redacta aquí"
                value="..."
                class="mt-n10"
              ></v-textarea>
              <p>El titular debe tener mínimo un correo electrónico</p>
            </v-col>
            <v-col cols="12" class="mt-n10">
              <v-btn @click="enviarEmail" block>ENVIAR</v-btn>
            </v-col>
          </v-row>

          <v-row v-else class="d-flex flex-column align-center justify-center">
            <v-progress-circular
              :size="50"
              color="amber"
              indeterminate
            ></v-progress-circular>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
  
<script>
import { FBagregaNotificacionEmail } from "@/components/Cartera/gestionCartera/notificacionesEmail.js";
export default {
  props: ["titularActual", "celulares", "email"],
  data() {
    return {
      mensaje: "",
      cargador: true,
      selectTelefono: null,
      selectCorreo: null,
      selectTipocobro: null,
      tipoDeCobro:["Juridico", "Pre Juridico"],
    };
  },
  created() {
    // Lógica para cuando se crea el componente
    this.inicializar();
  },
  methods: {
    // Métodos del componente
    async inicializar() {},

    async enviarEmail() {
      this.cargador = false;

      const json = {
        mensaje: this.mensaje,
        //datosTitular: this.titularActual,
        selectTelefono: this.selectTelefono,
        selectCorreo: this.selectCorreo,
        tipoCobro: this.selectTipocobro,
        idCartera: this.titularActual.idCartera,
        idRegistro: this.titularActual.idRegistro,
        nitCliente: this.titularActual.nitCliente,
        deudor: this.titularActual.data.Nombre,
      };

      const respuesta = await FBagregaNotificacionEmail(json);

      if (respuesta) {
        console.log("Se procesa con éxito.");
        this.cargador = true;
      } else {
        console.log("No se pudo procesar.");
        this.cargador = true;
      }
    },
  },
};
</script>
  
<style scoped>
</style>
  