<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500px" persistent scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="pa-2"
          outlined
          small
          icon
          color="green darken-4"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-tools</v-icon>
        </v-btn>
      </template>

      <v-card dark>
        <v-card-title class="text-h5 green">
          Configuración WhatsApp
        </v-card-title>

        <v-card-text class="mt-10">
          <p>
            Por favor no modifique los datos. En caso de conocer las propiedades
            lo podrá hacer sin problemas. Esto afectará el funcionamiento de
            envio de mensajes.
          </p>

          <v-row>
            <v-col>
              <v-text-field
                v-model="campoAFormateado"
                :value="campoAFormateado"
                color="green"
                label="Identificador Phone"
                placeholder="Pegue la información"
                outlined
                @change="actualizando('ip')"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n8">
            <v-col>
              <v-text-field
                v-model="campoB"
                :value="campoBCompleto"
                color="green"
                label="Identificador WhatsApp Business"
                placeholder="Pegue la información"
                outlined
                @change="actualizando('iwb')"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n8">
            <v-col>
               <!-- :disabled="!tieneCuenta"   :value="campoC" -->
              <v-text-field
                v-model="campoCFormateado"
                :value="campoC"
                color="green"
                label="Token"
                placeholder="Pegue la información"
                outlined
                @change="actualizando('tk')"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider color="green"></v-divider>

        <v-card-actions>
          Tiene Cuenta: {{ tieneCuenta === true ? "Si" : "No" }}
          <v-spacer></v-spacer>
          <v-btn color="green" outlined @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FBconfiguracionWhatsapp } from "@/components/Ventas/Tableros/whatsapp/configuracion.js";

export default {
  data() {
    return {
      dialog: false,
      campoA: "CHJHSGDKJHASGDFGASDFSFD",
      campoACompleto: "", // Valor completo de campoA
      campoB: "1234567890",
      campoBCompleto: "", // Valor completo de campoA
      campoC: "0987654321",
      campoCCompleto: "", // Valor completo de campoA
      tieneCuenta: false,
    };
  },

  created() {
    this.iniciar();
  },

  methods: {
    async iniciar() {
      const json = {
        accion: "consulta",
      };
      const result = await FBconfiguracionWhatsapp(json);

      //console.log(result);
      if (result[0]) {
        this.campoA = result[1].IdentificadorPhone;
        this.campoB = result[1].IdentificadorWhatsappBusiness;
        this.campoC = result[1].token;
        this.tieneCuenta = result[0];

        this.$emit('estadoWhatsappFuncion', result[1].estado);

      } else {
        this.tieneCuenta = false;

        this.$emit('estadoWhatsappFuncion', this.tieneCuenta);
      }

      
    },

    async actualizando(campo) {
      //console.log(campo);
      //console.log(this.campoA);

      const json = {
        accion: "actualiza",
      };

      switch (campo) {
        case "ip":
          // Actualizar el campo 'campoA' en tu base de datos o hacer lo que necesites
          // Agregar una nueva propiedad llamada "tipo" con el valor "documento"
          if (this.campoA === "") {
            return;
          }
          json.clave = "IdentificadorPhone";
          json.valor = this.campoA;

          const resultA = await FBconfiguracionWhatsapp(json);
          break;
        case "iwb":
          // Actualizar el campo 'campoB' en tu base de datos o hacer lo que necesites
          if (this.campoB === "") {
            return;
          }
          json.clave = "IdentificadorWhatsappBusiness";
          json.valor = this.campoB;

          const resultB = await FBconfiguracionWhatsapp(json);
          break;
        case "tk":
          // Actualizar el campo 'campoC' en tu base de datos o hacer lo que necesites
          if (this.campoC === "") {
            return;
          }
          json.clave = "token";
          json.valor = this.campoC;

          const resultC = await FBconfiguracionWhatsapp(json);
          break;
        default:
          break;
      }
    },
  },

  watch: {
    campoA(newValue) {
      this.campoACompleto = newValue; // Actualizar campoACompleto cuando cambie campoA
    },

    campoB(newValue) {
      this.campoBCompleto = newValue; // Actualizar campoACompleto cuando cambie campoA
    },

    campoC(newValue) {
      this.campoBCompleto = newValue; // Actualizar campoACompleto cuando cambie campoA
    },
  },

  computed: {
    campoAFormateado() {
      const maxVisibleChars = 8; // Define cuántos caracteres visibles deseas mostrar
      const placeholder = "..."; // Puedes cambiarlo a "XXXXX" si prefieres

      if (this.campoA.length <= maxVisibleChars) {
        return this.campoA;
      } else {
        return this.campoA.substr(0, maxVisibleChars) + placeholder;
      }
    },
    campoBFormateado() {
      // Mismo código de formateo para el campoB
      const maxVisibleChars = 8; // Define cuántos caracteres visibles deseas mostrar
      const placeholder = "..."; // Puedes cambiarlo a "XXXXX" si prefieres

      if (this.campoB.length <= maxVisibleChars) {
        return this.campoB;
      } else {
        return this.campoB.substr(0, maxVisibleChars) + placeholder;
      }
    },
    campoCFormateado() {
      // Mismo código de formateo para el campoC
      const maxVisibleChars = 8; // Define cuántos caracteres visibles deseas mostrar
      const placeholder = "..."; // Puedes cambiarlo a "XXXXX" si prefieres

      if (this.campoC.length <= maxVisibleChars) {
        return this.campoC;
      } else {
        return this.campoC.substr(0, maxVisibleChars) + placeholder;
      }
    },
  },
};
</script>