<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      scrollable
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="orange lighten-2" text v-bind="attrs" v-on="on">
          Acceder
        </v-btn>
      </template>

      <!-- INICIA TARJETA -->
      <v-card>
        <v-card-title class="text-h5 amber bg-black">
          Galeria de Imágenes
        </v-card-title>

        <v-card-text v-if="!estadoProceso" style="height: 20vh">
          <!-- FORMULARIO AGREGA PRODUCTO -->
          <v-form ref="form" v-model="valid" @submit.prevent="submit">
            <v-row class="mb-6 mt-3">
              <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mb-n10">
                <!-- Puedes usar v-file-input para subir imágenes si es necesario -->
                <v-file-input
                  multiple
                  color="black"
                  v-model="product.imageFile"
                  label="Imagen"
                  placeholder="Sube las imágenes.."
                  prepend-icon="mdi-paperclip"
                  @change="previewFiles"
                  counter
                  accept="image/jpeg, image/png, image/gif, image/webp"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="amber">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>

                <!-- Aquí mostramos las imágenes en miniatura -->
                <div
                  v-for="(url, index) in imagePreviews"
                  :key="url.img"
                  class="d-flex align-center"
                >
                  <v-badge :content="index + 1" color="black" overlap>
                  </v-badge>
                  <v-img
                    :src="url.img"
                    max-height="120"
                    max-width="120"
                    class="rounded-xl ml-3"
                    color="black"
                  ></v-img>

                  <span class="ml-2">{{ url.name }}</span>
                  <!-- añadido un margen a la izquierda para dar espacio entre la imagen y el texto -->
                </div>
              </v-col>
            </v-row>

            <!-- ... añade los otros campos de texto aquí ... -->
            <v-divider class="mt-5"></v-divider>

            <div class="d-flex justify-center align-center" style="height: 5vh">
              <v-btn color="black" block outlined @click="submit" class="mt-5"
                >Añadir Imágenes al Repositorio</v-btn
              >
            </div>
          </v-form>
        </v-card-text>

        <v-card-text v-else>
          {{ proceso }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text v-if="imagenesGaleria" style="height: 80vh">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              v-for="(url, index) in imagenesGaleria"
              :key="url"
              class="ma-2 position-relative"
            >
              <div
                class="d-flex align-items-center justify-content-center position-relative"
              >
                <v-badge :content="index + 1" color="black" overlap></v-badge>
                <v-img
                  :src="url"
                  max-height="120"
                  max-width="120"
                  class="rounded-xl ml-3"
                  @click="copyToClipboard(url)"
                  title="Haga clic para copiar la URL"
                ></v-img>

                <!-- Botón para eliminar la imagen de Firebase -->
                <v-btn
                  small
                  icon
                  color="red"
                  class="position-absolute top-0 right-0"
                  @click="deleteImageFromFirebase(url)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-else>
          {{ mensajeLoadImagenes }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions style="background-color: black">
          <v-btn @click="obtenerImagenes" color="amber" outlined>
            Consultar Galeria...
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="amber" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
      
   
  
<script>
import {
  todasLasImagenes,
  subirImageFB,
  eliminarUnaImagen,
} from "@/components/Mercadeo/Catalogo/galeria.js";

export default {
  data() {
    return {
      imagenesGaleria: null,
      mensajeLoadImagenes: "No se han cargado las imagenes de la galeria",
      dialog: false,
      valid: true,
      product: {
        nombre: "",
        descripcion: "",
        base_price: null,
        categoria: "",
        fabricante: "",
        imageFile: null,
      },
      imagePreviews: [],
      categorias: ["Editorial Rehobot" /*...otros valores...*/],
      fabricantes: ["Rehobot" /*...otros valores...*/],
      subCategorias: ["Educación" /*...otros valores...*/],
      estado: [
        { etiqueta: "Activo", valor: true },
        { etiqueta: "No Disponible", valor: false },
      ],
      proceso: "",
      estadoProceso: false,
    };
  },

  methods: {
    async deleteImageFromFirebase(url) {
      const resultElimina = await eliminarUnaImagen(url);

      //console.log(resultElimina);

      if(resultElimina){
         // Si se desea, se puede eliminar la URL de la lista de imágenes tras una eliminación exitosa:
      this.imagenesGaleria = this.imagenesGaleria.filter(
        (imgUrl) => imgUrl !== url
      );
      }else{
        //console.log("No se eliminó")
      }

     
    },

    copyToClipboard(url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          //alert("URL copiada al portapapeles!");
        })
        .catch((err) => {
          //alert("Error al copiar URL:", err);
        });
    },

    async obtenerImagenes() {
      this.imagenesGaleria = null;
      this.mensajeLoadImagenes = "Se están cargando las imágenes...";
      try {
        const urls = await todasLasImagenes();
        //console.log(urls); // Aquí tendrías todas las URLs de las imágenes
        this.imagenesGaleria = urls;
        this.mensajeLoadImagenes = "Perfecto!";
      } catch (error) {
        console.error("Error:", error);
        this.mensajeLoadImagenes = "Error al hacer la consulta";
      }
    },

    previewFiles() {
      this.imagePreviews = [];
      for (let file of this.product.imageFile) {
        //console.log(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.imagePreviews.push({ img: e.target.result, name: file.name });
        };
      }
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.estadoProceso = true;

      this.proceso = "Procesando Solicitud";

      const resultImg = await subirImageFB(this.product);

      if (resultImg[0]) {
        this.proceso = "Se han guardado las imágene con éxito...";
        this.product.imagen = resultImg[1];

        setTimeout(async () => {
          //Ahora subir datos

          this.proceso = "";
          this.estadoProceso = false;
          this.reiniciarFormulario();
        }, 3000); // 3000 milisegundos equivalen a 3 segundos
      } else {
        this.proceso = "Error guardar las imágenes.";

        setTimeout(() => {
          this.proceso = "";
          this.estadoProceso = false;
          this.reiniciarFormulario();
        }, 3000);
      }
    },

    reiniciarFormulario() {
      (this.product = {
        nombre: "",
        descripcion: "",
        base_price: null,
        categoria: "",
        fabricante: "",
        imageFile: null,
      }),
        (this.imagePreviews = []);
    },
  },
};
</script>
  