// utils/slug.ts

/*export function toSlug(str: string): string {
    return str
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "") // Remover caracteres no permitidos
      .replace(/[\s-]+/g, "-"); // Reemplazar espacios y guiones por un solo guión
 }*/


 function toSlug(str) {
    const map = {
        'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
        'ü': 'u', 'ñ': 'n', 'Á': 'A', 'É': 'E', 'Í': 'I',
        'Ó': 'O', 'Ú': 'U', 'Ü': 'U', 'Ñ': 'N'
    };

    str = str.replace(/[áéíóúüñÁÉÍÓÚÜÑ]/g, function(match) {
        return map[match];
    });

    return str
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9\s-]/g, "")  // Remover caracteres no permitidos
        .replace(/[\s-]+/g, "-");      // Reemplazar espacios y guiones por un solo guión
}

export { toSlug };