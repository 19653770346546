<template>
  <div justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="80%"
      v-if="!isUpdating"
      persistent
    >
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Crear Prospecto</span>
        </v-tooltip>
      </template>

      <v-card class="mx-auto">
        <v-toolbar color="black" dark>
          Crear Prospecto
          <v-divider></v-divider>
          {{ modulo }}
        </v-toolbar>

        <v-card-text>
          <v-container>
            <span class="grey--text text--lighten-1"
              >Datos Prospecto | EMPRESA</span
            >
            <v-divider></v-divider>
            <!-- Sección 1 CREA PROSPECTO -->
            <v-row class="mt-n1">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.trim="datosFormulario.cliente"
                  filled
                  color="blue-grey lighten-2"
                  label="Cliente"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.trim="datosFormulario.nit"
                  filled
                  color="blue-grey lighten-2"
                  label="Nit / CC / ID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  :items="paises"
                  v-model.trim="datosFormulario.pais"
                  :disabled="isUpdating"
                  filled
                  color="black"
                  label="País"
                  @change="cargarCiudades"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model.trim="datosFormulario.ciudad"
                  :items="ciudades"
                  filled
                  color="blue-grey lighten-2"
                  label="Ciudad"
                ></v-select>
              </v-col>
            </v-row>
            <!-- Sección 1.1 DATO PROSPECTO -->
            <v-row class="mt-n10">
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.trim="datosFormulario.sitio_web"
                  filled
                  color="blue-grey lighten-2"
                  label="Sitio Web | https://"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.trim="datosFormulario.telefono"
                  filled
                  color="blue-grey lighten-2"
                  label="Teléfonos (separar con , )"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.trim="datosFormulario.direccion"
                  filled
                  color="blue-grey lighten-2"
                  label="Direccion"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model.trim="datosFormulario.correos"
                  filled
                  color="blue-grey lighten-2"
                  label="Correos"
                ></v-text-field>
              </v-col>
            </v-row>

            <span class="grey--text text--lighten-1">Enfoque | EMPRESA</span>
            <v-divider></v-divider>

            <!-- Sección 2 Enfoque-->
            <v-row class="mt-n1">
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="10">
                    <v-select
                      v-model.trim="datosFormulario.proyecto"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Seleccione Proyecto"
                      :items="proyectos"
                      item-text="proyecto"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Indique a que Proyecto va su gestión</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="10">
                    <v-select
                      :items="objetivos"
                      v-model.trim="datosFormulario.objetivos"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Objetivo General"
                      item-text="objetivo"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Indique a que Objetivo va su gestión</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <!-- Calendario -->
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="6">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="datosFormulario.date"
                          label="Prox. Contacto"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="datosFormulario.date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="black" @click="modal = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="black"
                          @click="$refs.dialog.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <!-- ${switch1.toString()}-->
                        <div v-bind="attrs" v-on="on">
                          <v-switch
                            color="amber"
                            v-model="datosFormulario.llamar"
                            :label="`Llamar`"
                          ></v-switch>
                        </div>
                      </template>
                      <span
                        >Activar, si desea que el sistema notifique esta llamada
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- -->
            <v-row class="mt-n10">
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="10">
                    <v-select
                      v-model.trim="datosFormulario.doliente"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Responsable"
                      :items="personalRol"
                      item-text="nombreCargo"
                      item-value="nombre"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Persona que hará seguimiento</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row>
                  <!-- CONTACTO -->
                  <v-col cols="10">
                    <v-text-field
                      v-model.trim="datosFormulario.contacto"
                      filled
                      color="blue-grey lighten-2"
                      label="Contacto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Nombre y Apellidos completos del Contacto</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="10">
                    <v-select
                      v-model.trim="datosFormulario.depContacto"
                      :disabled="isUpdating"
                      filled
                      color="blue-grey lighten-2"
                      label="Departamento"
                      :items="departamentosDoliente"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Departamento del Contacto</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model.trim="datosFormulario.cargoContacto"
                      filled
                      color="blue-grey lighten-2"
                      label="Cargo"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Cargo del Contaco, debe ir relacionado al
                        Departamento.</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Datos Correo y whatsapp contacto-->
            <v-row class="mt-n10">
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model.trim="datosFormulario.correoContacto"
                      filled
                      color="black"
                      label="Correo Contacto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Puede agregar varios, separados por comas y sin espacio
                        entre ellos. El primer correo del campo es el que se
                        usará para enviar eMail de Seguimiento al
                        Contacto.</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <!-- CONTACTO -->
                  <v-col cols="10">
                    <v-text-field
                      v-model.trim="datosFormulario.whatsappContacto"
                      filled
                      color="black"
                      label="Contacto Whatsapp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Puede agregar varios, separados por comas y sin espacio
                        entre ellos. El primer número se usará para enviar un
                        mensaje de texto al contacto.</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Estado de Envia Correo a Prospecto -->
            <v-row class="mt-n10">
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="10">
                    <v-select
                      v-model.trim="datosFormulario.etapa"
                      :disabled="isUpdating"
                      filled
                      color="black"
                      label="Proceso.."
                      :items="etapas"
                      item-text="title"
                      item-value="panel"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span class="pa-2"
                        >{{ etapaSeleccionada.descripcion }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="10">
                    <v-select
                      v-model.trim="datosFormulario.prioridad"
                      :disabled="isUpdating"
                      filled
                      color="black"
                      label="Tiempo de seguimiento"
                      :items="tiempos"
                      item-text="title"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span class="pa-2 text-right">
                        Tiempo Mínimo: {{ prioridadSeleccionada.plazoMinimo
                        }}<br />
                        Tiempo Máximo: {{ prioridadSeleccionada.plazoMaximo
                        }}<br />
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Estado de propuesta eMail y SMS -->
            <v-row class="mt-n10">
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="10">
                    <v-switch
                      v-model="datosFormulario.switchEnviarBienvenida"
                      :label="`Enviar bienvenida por eMail`"
                      color="amber"
                    ></v-switch>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span class="pa-2"
                        >Envia un correo de bienvenida al Contacto, con su
                        nombre, proceso, fecha proximo contacto. Se toma el
                        primer correo del listado de correos de contacto.</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="10">
                    <!--  NUEVA FUNCIONALIDAD -->
                    <v-switch
                      v-model="datosFormulario.switchEnviarBienvenidaSMS"
                      :label="`Enviar bienvenida por SMS`"
                      color="amber"
                    ></v-switch>
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="pa-1 mt-4 ml-n5"
                          outlined
                          fab
                          x-small
                          color="black"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-alert-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span class="pa-2 text-right">
                        Se envia SMS al usuario. Al primer número que se
                        encuentre en el campo Whatsapp Contacto.</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Observaciones -->
            <v-row class="mt-n5">
              <v-col cols="12">
                <v-textarea
                  v-model.trim="datosFormulario.observaciones"
                  :disabled="isUpdating"
                  filled
                  color="black"
                  label="Redacta el proceso inicial para seguimiento ..."
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Ayuda de otro departamento -->
            <v-row align="center" no-gutters>
              <v-col cols="auto">
                <span class="grey--text text--lighten-1">
                  Apoyo de otra Área | Haz un requerimiento para esta actividad
                  | APOYO
                </span>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="auto">
                <ayudaDep @agregarApoyo="agregaApoyo" :modulos="modulos" />
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <!-- Listado de departamentos para apoyo -->
            <v-row>
              <v-col cols="12">
                <v-list>
                  <v-list-item v-for="(item, index) in apoyo" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-black">
                          {{ item.title }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Colab:
                        <span class="font-weight-black">
                          {{ item.colaborador }}
                        </span>
                        - Req.
                        <span class="font-weight-black">
                          {{ item.observacion }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="eliminarApoyo(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

            <!-- Documentos -->
            <span class="grey--text text--lighten-1"
              >Documentos y/o Referencias</span
            >
            <v-divider></v-divider>

            <v-row>
              <v-col class="mt-1" cols="12">
                <RepositoriosLogos @save-data="handleSaveData" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          
          <v-btn text @click="(dialog = false), guardarApoyo()">
            GUARDAR
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> CERRAR </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="dialogCargador" hide-overlay persistent width="300">
        <v-card color="black" dark>
          <v-card-text>
            {{ textoProcesoAwait }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>

    <v-progress-linear v-else indeterminate color="amber"></v-progress-linear>
  </div>
</template>

<script>
import loadCartera from "../../../components/loadFile/carteras/cartera.vue";
import RepositoriosLogos from "./DocumentosReferencias.vue";
import ayudaDep from "./ayudaDep.vue";
import { FBcreaProspecto } from "@/components/Ventas/prospectos.js";
import {
  FBconsultarEtapasProspectos,
  FBconsultarTiempos,
  FBconsultarProyectos,
  FBconsultaObjetivos,
  FBconsultaPaises,
  FBconsultaEmpleados,
  FBconsultarDepartamentos,
  FBconsultarModulos,
} from "@/components/Ventas/consultas.js";

export default {
  name: "agregaProspecto",
  props: ["modulo"],
  components: {
    loadCartera,
    RepositoriosLogos,
    ayudaDep,
  },
  data() {
    return {
      textoProcesoAwait: "Iniciando Proceso...",
      dialogCargador: false,
      modal: false,
      dialog: false,
      isUpdating: false,
      datosFormulario: {
        cliente: "",
        nit: "",
        pais: "",
        ciudad: "",
        sitio_web: "",
        telefono: "",
        direccion: "",
        correos: "",
        proyecto: "",
        objetivos: "",
        date: "",
        llamar: false,
        doliente: "",
        contacto: "",
        depContacto: "",
        cargoContacto: "",
        correoContacto: "",
        whatsappContacto: "",
        etapa: "",
        prioridad: "",
        observaciones: "",
        apoyo: "",
        estado: true,
        switchEnviarBienvenida: false,
        switchEnviarBienvenidaSMS: false,
      },
      items: ["Bajo", "Medio", "Alto"],
      objetivos: ["Procesando"],
      proyectos: ["Procesando..."],
      personalRol: [],
      //date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //.toISOString()
      // .substr(0, 10),
      //dates: ["2019-09-10", "2019-09-20"],//Cuando es multiple
      dates: ["2023-09-10"], //Cuando es sencillo
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      paises: ["Cargando.."],
      resultPaises: {}, // Agrega esta línea
      ciudades: [],
      departamentosDoliente: ["Comercial", "Contabilidad", "Tecnologia"],
      switch1: true,
      apoyo: [],
      etapas: [],
      etapaSelec: "",
      tiempos: [],
      tiempoSelec: "",
      urls: {},
      modulos: [],
    };
  },
  async created() {
    const resultModulos = await FBconsultarModulos();
    this.modulos = Object.values(resultModulos);

    const resultEtapas = await FBconsultarEtapasProspectos();
    this.etapas = Object.values(resultEtapas);

    const resultTiempos = await FBconsultarTiempos();
    this.tiempos = Object.values(resultTiempos);

    const resultProyectos = await FBconsultarProyectos();
    this.proyectos = Object.values(resultProyectos);

    const resultObjetivos = await FBconsultaObjetivos();
    this.objetivos = Object.values(resultObjetivos);

    await this.cargarPaises();

    const resultEmpleados = await FBconsultaEmpleados();
    this.personalRol = Object.values(resultEmpleados);

    const resultDepartamentos = await FBconsultarDepartamentos();
    this.departamentosDoliente = Object.values(resultDepartamentos);
  },
  methods: {
    async cargarPaises() {
      this.resultPaises = await FBconsultaPaises(); // Actualiza la asignación
      this.paises = Object.keys(this.resultPaises); // Actualiza el acceso a la variable
    },

    cargarCiudades() {
      const paisSeleccionado = this.datosFormulario.pais;
      if (paisSeleccionado && paisSeleccionado in this.resultPaises) {
        this.ciudades = this.resultPaises[paisSeleccionado].ciudades; // Actualiza el acceso a la variable
      } else {
        this.ciudades = [];
      }
    },

    agregaApoyo(data) {
      // Aquí puedes realizar las acciones necesarias con los datos guardados
      //console.log(data.departamento);
      //console.log(data.colaborador);
      //console.log(data);
      this.apoyo.push(data);
    },

    eliminarApoyo(index) {
      this.apoyo.splice(index, 1);
    },

    async guardarApoyo() {
      this.dialogCargador = true;
      this.textoProcesoAwait = "Procesando datos...";

      // Convertir cadena de telefonos separados por comas en un array
      const telefonosArray = this.datosFormulario.telefono
        ? this.datosFormulario.telefono.split(",").map((item) => item.trim())
        : [];

      // Convertir cadena de correos separados por comas en un array
      const correosArray = this.datosFormulario.correos
        ? this.datosFormulario.correos.split(",").map((item) => item.trim())
        : [];

      // Convertir cadena de correos separados por comas en un array
      const correosContactoArray = this.datosFormulario.correoContacto
        ? this.datosFormulario.correoContacto
            .split(",")
            .map((item) => item.trim())
        : [];

      // Convertir cadena de correos separados por comas en un array
      const correosContactoWhtsappArray = this.datosFormulario.whatsappContacto
        ? this.datosFormulario.whatsappContacto
            .split(",")
            .map((item) => item.trim())
        : [];

      // Actualizar los campos de telefonos y correos en datosFormulario
      this.datosFormulario = {
        ...this.datosFormulario,
        telefono: telefonosArray,
        correos: correosArray,
        correoContacto: correosContactoArray,
        whatsappContacto: correosContactoWhtsappArray,
        apoyo: this.apoyo,
      };

      setTimeout(() => {
        this.textoProcesoAwait = "Guardando datos..."; // Oculta el diálogo de cargador después de aproximadamente medio minuto
      }, 100); // 30000 milisegundos = 30 segundos

      //console.log(this.datosFormulario);
      // Lógica adicional si deseas guardar los datos en Firebase
      const resultado = await FBcreaProspecto(this.datosFormulario);
      //console.log(resultado);

      if (resultado) {
        this.textoProcesoAwait = "Se han guardado con Exito";
      } else {
        this.textoProcesoAwait =
          "Hubo un error en el proceso, comuniquese con el Administrador del Sistema. Caso FRP-01";
      }

      // Restablecemos el formulario a sus valores iniciales
      this.datosFormulario = {
        cliente: "",
        nit: "",
        pais: "",
        ciudad: "",
        sitio_web: "",
        telefono: "",
        direccion: "",
        correos: "",
        proyecto: "",
        objetivos: "",
        date: "",
        llamar: false,
        doliente: "",
        contacto: "",
        depContacto: "",
        cargoContacto: "",
        correoContacto: "",
        whatsappContacto: "",
        etapa: "",
        prioridad: "",
        observaciones: "",
        apoyo: "",
        estado: true,
        switchEnviarBienvenida: false,
        switchEnviarBienvenidaSMS: false,
      };
      this.apoyo = [];

      setTimeout(() => {
        this.dialogCargador = false; // Oculta el diálogo de cargador después de aproximadamente medio minuto
      }, 1000); // 30000 milisegundos = 30 segundos
    },

    handleSaveData(data) {
      this.urls[data.button.icon] = data.url;
    },
  },
  computed: {
    retornaEstadoMensaje() {
      //return (this.sheet = this.estadoMensaje);
    },

    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    etapaSeleccionada() {
      return (
        this.etapas.find(
          (etapa) => etapa.panel === this.datosFormulario.etapa
        ) || {}
      );
    },

    prioridadSeleccionada() {
      return (
        this.tiempos.find(
          (tiempo) => tiempo.title === this.datosFormulario.prioridad
        ) || {}
      );
    },
    
  },
};
</script>
