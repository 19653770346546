import { firebase, db, storage } from "../../../firebase/config"
import store from "../../../store";

const moment = require('moment');

const ref = storage.ref();

//Listado de nits con sus datos
export async function FBverClientesNit() {
    let data = [];
    try {
        const clientesRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        const snapshot = await clientesRef.where('data.estado', '==', true).get();
        //const snapshot = await clientesRef.orderBy('nombre').get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            //False no existe
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            data.push({ idCliente: doc.id, datos: doc.data(), nit: doc.data().data.nit });
        });

        //True es existe
        return data;

    } catch (error) {
        //console.log(error);
        return false;
    }

}

//Total Carteras ACTIVAS
export async function FBverCarteras(nits, datosCliente, rangoFecha) {
    console.log('Entra a ver carteras');
    console.log(datosCliente);
    console.log(nits);
    console.log(rangoFecha[0], rangoFecha[1]);
    let nitsLlega = nits[0];
    let carteraLlega = nits[1];

    let data = [];//Salen datos...
    let fusionArray = [];

    try {


        // /cf-cartera/Carteras
        //const clientesRef = db.collection('cf-cartera').doc('Carteras').collection(variable.datos.data.nit);
        const clientesRef = db.collection('cf-cartera').doc('Carteras').collection(nitsLlega);
        const snapshot = await clientesRef.where('nombreCartera', '==', carteraLlega).get();
        //const snapshot = await clientesRef.where('estado', '==', true).get();
        //const snapshot = await clientesRef.get();

        if (snapshot.empty) {
            console.log('No matching documents.');
            //False no existe
            return false;
        }
        let idCartera = [];

        snapshot.forEach(doc => {
            console.log(doc.id, '=>', doc.data());
            data.push({ idCliente: nitsLlega, datosCliente: datosCliente.datos.data, idCartera: doc.id, datosCartera: doc.data() });
            idCartera.push(doc.id);
        });

        const DetallesCartera = await FBdetallesCarteras(nitsLlega, idCartera, rangoFecha);

        console.log(" ------------------------------- ");
        
        console.log(DetallesCartera);

        console.log(" ------------------------------- ");
        //Fusionamos los array para luego inyectar
        fusionArray = fusionArray.concat(DetallesCartera);


        //console.log('FusionArray');
        //console.log(fusionArray);
        //Necesitamos recorre las carteras para cargar la bolsa
        for (let [i, datos] of data.entries()) {
            //console.log(i);
            //console.log(bolsa.idCartera);
            //Buscar para inyectar la bolsa
            _.filter(fusionArray, (items) => {

                if (items.cartera == datos.idCartera) {
                    data[i].bolsa = items.bolsa;
                    data[i].TotalMonto = items.totalMonto;
                    data[i].TotalFecha = items.trabajadosFecha;
                    data[i].TotalUltimaObservacion = items.ultimasObservaciones;
                    data[i].TotalAcuerdosDP = items.acuerdosDePago;
                    data[i].TotalHhistorialPagos = items.historialPagos;
                    data[i].TotalPPHistorial = items.paquetePagosHistorial;
                    data[i].TotalDemandados = items.demandados;
                    data[i].TotalAuto = items.auto;
                    data[i].TotalOficio = items.oficio;

                    data[i].TotalPazySalvoV1 = items.pazYsalvoV1

                    data[i].totalRegistrosSinFiltros = items.totalRegistrosConsulta

                    return items.cartera == datos.idCartera;
                }

            });
            //console.log('Filtro');
            //console.log(filtro.bolsa);
        }

        console.log('---------------FUERA---------------');
        //console.log(data);
        //console.log('Sale de FBverCarteras');
        //True es existe

        //Antes de Salir analizar BOLSA de cada CARTERA
        return data;

    } catch (error) {
        //console.log(error);
        return false;
    }

}

//COnsulta las carteras
export async function FBdetallesCarteras(nitCliente, idCartera, rangoFecha) {
    console.log('Entra a detalle carteras');
    console.log('nit: ', nitCliente);
    console.log('idCartera: ', idCartera); /**/
    console.log('rango fecha:', rangoFecha);
    //console.log(nits);



    let data = [];//Salen datos...
    let bolsa = [];//Garda todos los registros

    try {

        for (let variable of idCartera) {
            console.log(variable); //datos / idCliente
            console.log(nitCliente);
            var sumaMonto = 0;

            // /cf-cartera/Carteras/805028053/A3YvLMeLBBDbQYQAF7qE/registros
            //const titularesCarteraRef = db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(variable).collection('registros');
            //const snapshot = await titularesCarteraRef.where('estado', '==', true).get();

            const titularesCarteraRef = db.collection('cf-cartera').doc('Carteras').collection(nitCliente).doc(variable).collection('registros');

            const snapshot = await titularesCarteraRef.get();

            const totalRegistros = snapshot.size;

            console.log(totalRegistros);

            //Filtro por rango de fecha
            const resultadosFiltrados = snapshot.docs.filter(doc => {
                const observaciones = doc.data().observaciones;
                const fechasDentroDeRango = observaciones.filter(observacion => {
                    const fecha = moment(observacion.fecha.toDate()).format("YYYY-MM-DD");
                    //console.log(fecha);
                    //console.log(rangoFecha[0]);
                    return fecha >= rangoFecha[0] && fecha <= rangoFecha[1];
                });
                return fechasDentroDeRango.length > 0;
            });


            if (resultadosFiltrados.empty) {
                console.warn('No matching documents.');
                //False no existe, pero no retorna porque borra.
                //return false;
            }

            var proximoContacto = [];//Guarda todos los que tienen fecha 
            var ultimaObservacion = [];//Guarda ultima tipifaciacion
            var acuerdosDePago = [];//Guarda todos los acuerdos de pago. 
            var historialDePago = [];//Guarda todos los acuerdos de pago.
            var paqueteHistorialDePago = [];//Guarda todos los acuerdos de pago. 
            var pazYsalvov1 = [];
            var demandados = [];
            var auto = [];
            var oficio = [];

            resultadosFiltrados.forEach(doc => {
                //console.log(doc.id, '=>', doc.data());
                //console.log(doc.data().data);
                var datosLlegan = doc.data();

                bolsa.push({ idTitular: doc.id, datosTitular: datosLlegan });
                //ANALIZAR LA BOLSA POR MONTO
                if (datosLlegan.data.Monto != undefined) {
                    sumaMonto = sumaMonto + datosLlegan.data.Monto;
                }
                //ULTIMO ESTADO FECHA
                if (datosLlegan.proximoContactoCalendario != undefined || datosLlegan.proximoContactoCalendario != null) {
                    proximoContacto.push(datosLlegan);
                }
                //ULTIMA OBSERVACION
                if (datosLlegan.observaciones != undefined || datosLlegan.observaciones != null || datosLlegan.observaciones != '') {

                    if (datosLlegan.observaciones[datosLlegan.observaciones.length - 1].observacion != '') {
                        ultimaObservacion.push(datosLlegan.observaciones[datosLlegan.observaciones.length - 1])
                    }

                    //ultimaObservacion.push()
                }
                //ACUERDOS DE PAGO
                if (datosLlegan.acuerdoPago != undefined) {
                    acuerdosDePago.push(datosLlegan.acuerdoPago);
                }

                //HISTORIAL DE PAGO
                if (datosLlegan.historialPagos != undefined) {
                    //SUmar el total de las carteras.
                    for (let [i, datos] of datosLlegan.historialPagos.entries()) {
                        //console.log(datos);
                        //sumaMontoHistorialPago = sumaMontoHistorialPago + datos.pago.monto
                        paqueteHistorialDePago.push(datos.pago.monto)
                    }
                    historialDePago.push(datosLlegan.historialPagos);
                    //paqueteHistorialDePago.push({total: sumaMontoHistorialPago})
                }

                //PazySalvov1
                if (datosLlegan.PazySalvov1 != undefined && datosLlegan.PazySalvov1 == true) {
                    pazYsalvov1.push(datosLlegan.PazySalvov1);
                }

                //pdfDemanda
                if (datosLlegan.pdfDemanda != undefined && datosLlegan.pdfDemanda == true) {
                    demandados.push(datosLlegan.pdfDemanda);
                }

                //pdfAuto
                if (datosLlegan.pdfAuto != undefined && datosLlegan.pdfAuto == true) {
                    auto.push(datosLlegan.pdfAuto);
                }

                //pdfOficio
                if (datosLlegan.pdfOficio != undefined && datosLlegan.pdfOficio == true) {
                    oficio.push(datosLlegan.pdfOficio);
                }


            });/* */

            data.push({
                nit: nitCliente,
                cartera: variable,
                bolsa: bolsa,
                totalBolsa: bolsa.length,
                totalMonto: sumaMonto,
                trabajadosFecha: proximoContacto,
                ultimasObservaciones: ultimaObservacion,
                acuerdosDePago: acuerdosDePago,
                historialPagos: historialDePago,
                paquetePagosHistorial: paqueteHistorialDePago,
                pazYsalvoV1: pazYsalvov1,
                demandados: demandados,
                auto: auto,
                oficio: oficio,
                totalRegistrosConsulta: totalRegistros,
            });
            bolsa = [];//vaciar de nuevo el array.

        }

        console.log('Sale de CARGAR CARTERA...');
        //True es existe
        //detectaCambios();
        return data;

    } catch (error) {
        console.log(error);
        return false;
    }

}

export async function FBverClientesNitResportes() {
    let data = [];
    try {
        const clientesRef = db.collection('cf-cartera').doc('Clientes').collection('Listado');
        //const snapshot = await clientesRef.where('data.estado', '==', true).get();
        const snapshot = await clientesRef.orderBy('data.nit', 'desc').get();

        if (snapshot.empty) {
            //console.log('No matching documents.');
            //False no existe
            return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            data.push({ idCliente: doc.id, datos: doc.data(), nit: doc.data().data.nit });
        });

        //True es existe
        return data;

    } catch (error) {
        //console.log(error);
        return false;
    }

}

export async function FBverClientesCarteras(nit) {
    let data = [];
    try {
        try {
            const snapshot = await db.collection('cf-cartera')
                .doc('Carteras')
                .collection(nit)
                .get();

            snapshot.forEach((doc) => {
                data.push({ id: doc.id, nombreCartera: doc.data().nombreCartera });
            });

            return data;
        } catch (error) {
            console.error(error);
        }

    } catch (error) {
        //console.log(error);
        return false;
    }

}