<template>
  <!-- fill-height fluid class="grey lighten-5" -->
  <v-container>
    <!-- <Letrero /> -->

    <!-- MODULO DE SEGUIMIENTO -->
    <v-row>
      <v-col cols="12">
        <!-- MODULO DE SEGUIMIENTO 
        <gestionBasicaDCM v-if="rolPrueba == 'dcm'" />-->
        <gestionBasicaDCM v-if="rolPrueba == 'dcm'" />
      </v-col>
    </v-row>

    <!-- MODULOS VARIOS DE GESTION -->
    <v-row class="pa-2" dense>
      <!-- :cols="" -->
      <v-col
        v-for="card in cards"
        :key="card.title"
        :md="card.flex"
        v-show="roles(card.roles)"
      >
        <v-card
          @click="abrirPanel(card.title)"
          :color="card.color"
          shaped
          :outlined="true"
          :hover="true"
        >
          <v-img
            :src="card.src"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
            <v-card-title class="titulos">{{ card.title }}</v-card-title>
          </v-img>

          <v-card-actions>
            <v-spacer>
              <div v-if="card.verMensaje">
                <marquee>{{ card.mensaje }}</marquee>
              </div>
            </v-spacer>

            <v-btn v-if="card.alert" disabled depressed icon>
              <v-badge dot overlap bordered color="green" :content="card.badge">
                <v-icon>mdi-alarm-light-outline</v-icon>
              </v-badge>
            </v-btn>
            <div v-else>
              <!--
            <v-btn  icon>
              <v-icon>mdi-check</v-icon>
            </v-btn> -->
            </div>
            <crearCliente
              v-if="card.title == 'Clientes' && card.modalCard == true"
              v-show="rolPrueba == 'g'"
            />
            <verClientes
              v-if="card.title == 'Clientes' && card.modalCard == true"
              v-show="rolPrueba == 'g'"
            />

            <creaCartera
              v-if="card.title == 'Carteras' && card.modalCard == true"
              v-show="rolPrueba == 'g'"
            />

            <cargarCartera
              v-if="card.title == 'Carteras' && card.modalCard == true"
              v-show="rolPrueba == 'g'"
            />

            <asignaCartera
              v-if="card.title == 'Carteras' && card.modalCard == true && rolPrueba == 'g'"
            />

            <!-- No se muestra para nadie -->
            <reAsingCartera
              v-if="card.title == 'Carteras' && card.modalCard == true"
              v-show="rolPrueba == 'g'"
            />

            <gestionaCartera
              v-if="card.title == 'Carteras' && card.modalCard == true"
              v-show="rolPrueba == 'acm' || rolPrueba == 'd'"
            />

            <buscaTitular
              v-if="card.title == 'Carteras' && card.modalCard == true"
              v-show="
                rolPrueba == 'dcm' ||
                rolPrueba == 'acm' ||
                rolPrueba == 'g' ||
                rolPrueba == 's' ||
                rolPrueba == 'd'
              "
            />

            <indicadoresACM
              v-if="card.title == 'Carteras' && card.modalCard == true"
              v-show="rolPrueba == 'acm' || rolPrueba == 'g'"
            />

            <indicadoresGerencia
              v-if="card.title == 'Indicadores' && card.modalCard == true"
              v-show="rolPrueba == 'g'"
            />

            <indicadores
              v-if="card.title == 'Indicadores' && card.modalCard == true"
              v-show="rolPrueba == 'dcm' || rolPrueba == 'g'"
            />

            <!-- -->
            <comisiones
              v-if="card.title == 'Procesos Cartera' && card.modalCard == true"
              v-show="rolPrueba == 'g'"
            />

            <misIndicadores
              v-if="card.title == 'Procesos Cartera' && card.modalCard == true"
              v-show="rolPrueba == 'g'"
            />

            <masivosA
              v-if="card.title == 'Procesos Cartera' && card.modalCard == true"
              v-show="rolPrueba == 'g'"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-tabs v-model="tab" background-color="amber" centered dark>
            <v-tab href="#tab-1">Cartera Morosa</v-tab>
            <!-- disabled -->
            <v-tab href="#tab-2">Cartera Al día</v-tab>

            <v-tabs-items v-model="tab">
              <v-tab-item :value="'tab-1'">
                <v-card flat>
                  <v-card-text>

                    <!-- IA -->
                    <v-row>
                      <v-col sm="12" xs="12" v-if="datosGraficaAi != undefined">
                        <aiBasico
                          :rolUsuario="rolPrueba"
                          :idUsuario="idUsuario"
                          :grafica="datosGraficaAi"
                        />
                      </v-col>
                    </v-row>

                    <!-- PROVISIONAL 
                    <v-row>
                      <v-col>
                        <v-btn @click="sendSMS">Mensaje SMS</v-btn>
                        <v-btn @click="sendMensaje">Mensaje eMail</v-btn>
                        <v-btn @click="llamadas">Llamar</v-btn>
                        <v-btn @click="wahstappTW">Whatsapp</v-btn>
                      </v-col>
                    </v-row>
                    -->
                     <!-- GRAFICA -->
                    <v-row>
                      <v-col sm="12" xs="12">
                        <grafica
                          :rolUsuario="rolPrueba"
                          :idUsuario="idUsuario"
                          @aigrafica="datosGrafica"
                        />
                      </v-col>
                    </v-row>

                     <!-- LLAMAR HOY Y LLAMADAS PRÓXIMOS DIAS -->
                    <v-row class="mt-n10">
                      <v-col sm="6" xs="12">
                        <llamarHoy
                          :rolUsuario="rolPrueba"
                          :idUsuario="idUsuario"
                        />
                      </v-col>
                      <v-col sm="6" xs="12">
                        <llamarProximosDias
                          :rolUsuario="rolPrueba"
                          :idUsuario="idUsuario"
                        />
                      </v-col>
                    </v-row>

                     <!-- LLAMADAS VENCIDAS -->
                    <v-row>
                      <v-col sm="6" xs="12">
                        <llamarVencidas
                          :rolUsuario="rolPrueba"
                          :idUsuario="idUsuario"
                        />
                      </v-col>
                      <v-col sm="6" xs="12"> </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item :value="'tab-2'">
                <v-card flat>
                  <v-card-text> Sección - Cartera Al día </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="py-3 rounded-pill amber text-center" >
          <buscador />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

import store from "../../store";
import crearCliente from "./CrearCliente/CrearCliente.vue";
import verClientes from "./CrearCliente/VerClientes.vue";
import cargarCartera from "./CargarCartera/CargarCartera.vue";
import asignaCartera from "./CargarCartera/asignaCartera.vue";
import reAsingCartera from "./CargarCartera/reAsignaCartera.vue";
import creaCartera from "./CargarCartera/CreaCartera.vue";
import indicadores from "./Indicadores/Indicadores.vue";
import indicadoresGerencia from "./Indicadores/indicadoresG.vue";
import indicadoresACM from "./Indicadores/IndicadoresACM.vue";
import comisiones from "./Comisiones/Comisiones.vue";
import misIndicadores from "./Comisiones/misIndicadores.vue";
import gestionaCartera from "./GestionCartera/gestionaCartera.vue";
import buscaTitular from "./GestionCartera/buscadorTitular.vue";
import gestionBasicaDCM from "./GestionCartera/dcm/gestionBasica.vue";
import buscador from "./GestionCartera/buscadorTitular.vue";
import masivosA from "./ProcesosCartera/masivos.vue";
import llamarHoy from "./GestionCartera/acm/llamarHoy.vue";
import llamarProximosDias from "./GestionCartera/acm/llamarManana.vue";
import llamarVencidas from "./GestionCartera/acm/llamarViejitos.vue";
import grafica from "./GestionCartera/acm/analisisBolsa.vue";
import aiBasico from "../../components/GPT/index.vue";
import { senMensaje, llamadasTW, sendWhatsapp } from "../../Util/twilio/index";

export default {
  name: "PanelesCartera",
  data: () => ({
    rolPrueba: store.state.user[2][0].data.cargo,
    idUsuario: store.state.user[2][0].id,
    cards: [
      {
        title: "Clientes",
        src: "https://image.freepik.com/vector-gratis/hombre-muestra-gesto-gran-idea_10045-637.jpg",
        flex: 4,
        mensaje: "...",
        verMensaje: true, //Muestra mensaje
        alert: false, //General
        click: false, //General
        badge: 1,
        modalCard: true,
        color: "#E0E0E0",
        roles: ["g"],
      },
      {
        title: "Carteras",
        src: "https://image.freepik.com/vector-gratis/caida-monedas-dolar-exito-suerte-dinero-concepto-inversion_1262-13463.jpg",
        flex: 4,
        alert: false,
        click: false,
        modalCard: true,
        color: "#E0E0E0",
        roles: ["g", "acm"],
      },
      {
        title: "Indicadores",
        src: "https://image.freepik.com/vector-gratis/analisis-estadistico-personaje-dibujos-animados-hombre-lupa-analizando-datos-diagrama-circular-segmentos-coloridos-estadisticas-auditoria-ilustracion-concepto-investigacion_335657-2063.jpg",
        flex: 4,
        alert: false,
        modalCard: true,
        color: "#E0E0E0",
        roles: ["g"],
      },
      {
        title: "Procesos Cartera",
        src: "https://img.freepik.com/free-vector/communication-flat-icon_1262-18771.jpg?w=1480&t=st=1665761188~exp=1665761788~hmac=1707dc7945ced46fad04fcd7b95cf1d21483292e0560b23665ddf5231a2f378b",
        flex: 4,
        alert: false,
        modalCard: true,
        color: "#E0E0E0",
        roles: ["g"],
      },
    ],
    //estadoMensaje: "aaa",
    datosGraficaAi: undefined,
    tab: null,
  }),
  props: {},
  components: {
    crearCliente,
    verClientes,
    creaCartera,
    cargarCartera,
    asignaCartera,
    reAsingCartera,
    indicadores,
    indicadoresGerencia,
    indicadoresACM,
    comisiones,
    misIndicadores,
    gestionaCartera,
    buscaTitular,
    gestionBasicaDCM,
    buscador,
    masivosA,
    llamarHoy,
    llamarProximosDias,
    llamarVencidas,
    grafica,
    aiBasico,
  },
  mounted() {},
  methods: {
    async wahstappTW() {
      try {
        const apiUrl =
          "https://graph.facebook.com/v17.0/102503192897456/messages";
        const accessToken =
          "EAASfe3vP0N8BAArUDnvHKqM8c8bSZBBGhTP4AG595J85rbHFofEcw6OmOX4v9fOF48Ld6duysWntGRGzVjc8WjCM2F3LziNPmkZAgZAGi0h8gxWtHWZCEFptoEPuwNWoc39m9mhWUAhqUokW5rx2X2T248rwzOl8hLHpE7UKVAuPFCIYsoJQsxZAV2QR9OZBAZD";

        //Plantilla
        const requestData = {
          messaging_product: "whatsapp",
          to: "573006386111",
          type: "template",
          template: {
            name: "hello_world",
            language: { code: "en_US" },
          },
        };

        /*const requestData = {
          messaging_product: "whatsapp",
          to: "573006386111",
          type: "text",
          text: "Hola...",
        };*/

        const response = await axios.post(apiUrl, requestData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        console.log("Mensaje enviado:", response.data);
      } catch (error) {
        console.error("Error al enviar el mensaje:", error.response.data);
      }
      //const respuesta = await sendWhatsapp();
      //console.log(respuesta);
    },
    async llamadas() {
      const respuesta = await llamadasTW();
      console.log(respuesta);
    },
    async sendMensaje() {
      console.log("Envio de eMail");
      // Llamar a la función de envío de correos electrónicos con los datos necesarios
      const to = "vanegas.r.javier@gmail.com";
      const subject = "Hola desde Vue";
      const body =
        "Este es un correo electrónico enviado desde mi aplicación Vue";

      try {
        const response = await axios.post("/sendEmail", { to, subject, body });
        console.log("Correo electrónico enviado con éxito:", response.data);
        // Manejar la respuesta exitosa aquí
      } catch (error) {
        console.error("Error al enviar el correo electrónico:", error);
        // Manejar el error aquí
      }
    },
    async sendSMS() {
      const respuestaEnvio = await senMensaje();
      console.log(respuestaEnvio);
    },
    datosGrafica(datos) {
      //console.log(datos);
      this.datosGraficaAi = datos;
    },
    validaModulo(llega) {
      /*switch (llega) {
         case "":
             
             break;
     
         default:
             break;
     }*/
    },
    roles(llega) {
      //console.log("roles: ", llega);
      const result = _.indexOf(llega, this.rolPrueba);
      //console.log(result);
      if (result !== -1) {
        return true;
      } else {
        return false;
      }
    },
    abrirPanel(panel) {
      //console.log("Hola" + estado);
      switch (panel) {
        case "Carteras":
          //Genera error porque ya se encuentra en la ruta
          //this.$router.push("/Cartera");
          break;

        default:
          break;
      }
    },
  },
};
</script>


<style>
@import url("https://fonts.googleapis.com/css2?family=Sarpanch&display=swap");
@import url("https://fonts.googleapis.com/css2?family=B612+Mono&display=swap");

.titulos {
  font-family: "Sarpanch", sans-serif;
  color: white;
  font-weight: 700;
}
marquee {
  font-family: "B612 Mono", monospace;
  color: rgb(122, 122, 122);
  font-weight: 200;
}
</style>