<template>
  <div class="text-center">
    <v-row>
      <v-col cols="12"
        ><h2>Análisis Básico AI</h2>
        <h6>{{ generatedText }}</h6></v-col
      >
      <v-col cols="12">
        <div v-if="recommendations.length == 0">
          <v-btn @click="generateText()" block>
            Recomendaciones con mis métricas de gestión:
            {{ filteredRecommendations.length }}
          </v-btn>
        </div>

        <v-expansion-panels v-else>
          <v-expansion-panel
            v-for="(group, index) in recommendations"
            :key="index"
          >
            <v-expansion-panel-header>
              {{ group.porcentaje.toFixed(2) }}% ({{ group.totalCount }}
              {{ group.totalCount === 1 ? "titular" : "titulares" }}) -
              {{ group.tipificacion }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul>
                <li
                  v-for="(recommendation, i) in group.recommendations"
                  :key="i"
                >
                  {{ recommendation }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OpenAI from "openai-api";

export default {
  name: "aiAnalisisBasico",
  props: ["grafica"],
  data: () => ({
    filteredRecommendations: [],
    recommendations: [],
    generatedText: "",
    apiKey: "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", // Agrega tu clave de API aquí
  }),
  mounted() {
    //this.generateText();
  },
  methods: {
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async generateText() {
      this.filteredRecommendations = [];
      this.recommendations = []; //recetear el array
      this.generatedText = "Iniciando...";
      const openai = new OpenAI(this.apiKey);
      const tipificaciones = this.grafica;

      //console.log("Tipificaciones:", tipificaciones);

      const promptAI =
        "Se ha realizado un análisis de las tipificaciones y sus cantidades correspondientes para mejorar la gestión diaria en la cartera morosa. A continuación, se presentan recomendaciones prácticas:\n\n";

      try {
        const groupedRecommendations = [];
        const totalTipificaciones = tipificaciones.length;

        for (const tipificacion of tipificaciones) {
          if (typeof tipificacion !== "undefined") {
            const existingGroup = groupedRecommendations.find(
              (group) => group.tipificacion === tipificacion
            );

            if (existingGroup) {
              existingGroup.cantidad++;
              existingGroup.totalCount++;
            } else {
              groupedRecommendations.push({
                tipificacion: tipificacion,
                cantidad: 1,
                totalCount: 1,
                recommendations: [],
              });
            }
          }
        }

        this.filteredRecommendations = groupedRecommendations.filter(
          (group) => (group.totalCount / totalTipificaciones) * 100 >= 16
        );

        //console.log("Filtered Recommendations:", this.filteredRecommendations);

        for (const group of this.filteredRecommendations) {
          group.porcentaje = (group.totalCount / totalTipificaciones) * 100;

          const prompt = `${promptAI}- ${group.tipificacion}: ${
            group.totalCount
          } ${
            group.totalCount === 1
              ? "titular gestionado"
              : "titulares gestionados"
          }.\n\n¿Qué consejo práctico tienes para esta tipificación?`;

          await this.sleep(2000);

          //console.log("Prompt:", prompt);

          const gptResponse = await openai.complete({
            engine: "text-davinci-003",
            prompt: prompt,
            max_tokens: 200,
            n: 5, // Obtener como máximo 5 recomendaciones por grupo
          });

          //console.log("GPT Response:", gptResponse);

          const choices = gptResponse.data.choices;
          let uniqueRecommendations = new Set();

          for (const choice of choices) {
            let recommendation = choice.text.trim();

            // Asegurarse de obtener la recomendación completa
            const lastNewlineIndex = recommendation.lastIndexOf("\n");
            if (lastNewlineIndex !== -1) {
              recommendation = recommendation.substring(0, lastNewlineIndex);
            }

            if (recommendation) {
              uniqueRecommendations.add(recommendation);
            }
          }

          group.recommendations = [...uniqueRecommendations];
        }

        this.recommendations = this.filteredRecommendations;
        this.generatedText = "Terminó";
        // Almacenar las recomendaciones en caché
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>