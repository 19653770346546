<template>
  <!--PANEL DE DCM, JURIDICO -->
  <div justify="center">
    <v-dialog v-model="dialog" max-width="80%" scrollable persistent>
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <!--onlyTitular()" -->
          <template v-slot:activator="{ on }">
            <v-btn
              block
              class="mx-2 white--text"
              color="blue darken-4"
              v-on="on"
              @click="dialog = !dialog"
              :disabled="titularBuscadoEstado"
            >
              <v-icon>mdi-account-circle-outline</v-icon>
              Titular Activo -
              <div v-if="datosTitular != undefined">
                {{ datosTitular.Cedula }}
              </div>
              {{ resultadoTitularBusqueda }}
            </v-btn>
          </template>
          <span>Titular Activo</span>
        </v-tooltip>
      </template>

      <!-- Card -->
      <!-- llegaTitulares.length > 0  -->
      <v-card
        color="blue-grey darken-1"
        :dark="true"
        v-if="llegaTitulares.length > 0"
      >
        <!-- CABEZOTE INFO  -->
        <v-toolbar class="text-center blue-grey darken-4" extended>
          <v-row>
            <!-- CABEZOTE INFO  
            <v-col cols="6">...</v-col>-->

            <v-col cols="12" class="d-flex justify-space-around">
              <v-toolbar-title class="mt-10 text-center">
                <!--
                <span class="subtitle-1">
                
                  Total titulares para gestionar hoy

                  {{ llegaTitulares.length }}
                  <br />
                  LLamar hoy: {{ totalLlamarHoyFB }}
                  <v-spacer></v-spacer>
                  Sin tocar: {{ sinTocarFB }}</span
                >
                -->
              </v-toolbar-title>
            </v-col>

            <v-spacer></v-spacer>
          </v-row>
          <modalAgregaObservacion v-bind:observacionesModal="titularActual" />
          <v-btn @click="(dialog = !dialog), (pausa = !pausa)" icon>
            <v-icon>mdi-pause-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- INICIO DE DATOS  -->
        <v-card-text>
          <!-- PUNTAJE START Y EJECUTIVO ASIGNADO -->
          <div class="text-center">
            <v-row align="center" justify="center">
              <v-col>
                <v-rating
                  v-model="titularActual.puntajeTitular"
                  background-color="amber"
                  color="yellow"
                  half-increments
                  hover
                  @input="puntajeEjecutivoTitular(titularActual.puntajeTitular)"
                ></v-rating>
                ({{ titularActual.puntajeTitular }})
              </v-col>
              <v-col>
                <v-row style="margin-top: 5px">
                  <v-col cols="10">
                    <p class="font-weight-black">
                      Ejecutivo Asignado:
                      <span class="font-weight-thin">
                        {{ ubicandoEjecutivo(titularActual.asignadoActual[0]) }}
                        <span
                          v-if="titularActual.respetable"
                          style="width: 46px; height: 46px"
                        >
                          🥷
                        </span>
                        <span style="width: 46px; height: 46px" v-else>
                          🦸‍♂️
                        </span>
                      </span>
                    </p>
                  </v-col>
                  <v-col cols="2">
                    <cambioEjecutivo
                      :datos="titularActual"
                      v-if="usuarioRol == 'g' || usuarioRol == 'adm'"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <v-divider class="pa-3 mt-3"></v-divider>

          <v-row>
            <!-- DATOS TITULAR -->
            <v-col cols="6">
              <v-card max-width="100%" class="mx-auto rounded-xl">
                <v-img
                  src="https://picsum.photos/200/300?grayscale?random=2"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  max-height="150"
                >
                  <v-row class="fill-height">
                    <v-card-title>
                      <!-- class="pa-3 blue-grey darken-4" 
                      <div
                        
                        style="
                          background: #222;
                          text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, #fff;
                          border-radius: 50px;
                          width: 100%;
                        "
                      >-->
                      <h4 class="text-capitalize font-weight-black">
                        <v-chip @click="copiaTexto(titularActual.data.Nombre)">
                          {{ titularActual.data.Nombre }}
                        </v-chip>
                        <!--
                          <v-btn
                            color="blue-grey darken-1"
                            fab
                            small
                            outlined
                            @click="copiaTexto(titularActual.data.Nombre)"
                          >
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                        -->
                        <v-divider class="pa-1 mt-3"></v-divider>
                        <v-chip
                          @click="copiaTexto(titularActual.data.Cedula)"
                          color="orange darken-4
"
                        >
                          ID. {{ titularActual.data.Cedula }}
                        </v-chip>
                      </h4>
                      <!--
                        <v-row class="text-center">
                          <v-col>
                            <v-btn
                              color="blue-grey darken-1"
                              fab
                              small
                              outlined
                              @click="copiaTexto(titularActual.data.Cedula)"
                            >
                              <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col>
                            <cambioCedulaTT
                              :datos="titularActual"
                              v-if="usuarioRol == 'g' || usuarioRol == 'adm'"
                            />
                          </v-col>
                        </v-row>
                      </div>-->
                    </v-card-title>
                  </v-row>
                </v-img>
                <!-- Lis Celular -->
                <v-card-text class="blue-grey darken-4">
                  <v-list
                    v-if="titularActual.data['Telefono 1'] != undefined"
                    two-line
                    class="blue-grey darken-3 rounded-xl"
                  >
                    <!-- Telefonos dinámicos -->
                    <v-list-item
                      v-for="(item, key) in arrayTelefonos(
                        titularActual.data['Telefono 1']
                      )"
                      :key="key"
                    >
                      <v-list-item-icon>
                        <v-icon dark> mdi-phone </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                        <v-list-item-subtitle>Celular</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon>mdi-message-text</v-icon>
                      </v-list-item-icon>
                      <v-list-item-icon>
                        <v-btn
                          color="teal darken-2"
                          fab
                          small
                          @click="linksWhatsapp('whatsapp', item)"
                        >
                          <v-icon>mdi-whatsapp</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                  <v-spacer class="pa-1"></v-spacer>
                  <!-- Lis Correo -->
                  <v-list
                    v-if="titularActual.data['email'] != undefined"
                    two-line
                    class="blue-grey darken-3 rounded-xl"
                  >
                    <!-- Telefonos dinámicos -->
                    <v-list-item
                      v-for="(item, key) in arrayTelefonos(
                        titularActual.data['email']
                      )"
                      :key="key"
                    >
                      <v-list-item-icon>
                        <v-icon dark> mdi-at </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-btn fab small @click="linksWhatsapp('mail', item)">
                          <v-icon>mdi-email-fast</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                  <v-spacer class="pa-1"></v-spacer>
                  <!-- Lis Direccion -->
                  <v-list
                    v-if="titularActual.data['Direccion'] != undefined"
                    two-line
                    class="blue-grey darken-3 rounded-xl"
                  >
                    <!-- Telefonos dinámicos -->
                    <v-list-item
                      v-for="(item, key) in arrayTelefonos(
                        titularActual.data['Direccion']
                      )"
                      :key="key"
                    >
                      <v-list-item-icon>
                        <v-icon dark> mdi-map-marker-account-outline </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                        <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- Doc Empresa Zona Whatsapp -->
            <v-col cols="6">
              <div v-show="false">
                <!-- Solo sirve para traer el nombre de la empresa -->
                {{ nombreCliente(titularActual.nitCliente) }}
              </div>
              <v-col cols="12" class="text-center mt-n5">
                <h2>
                  <span class="subtitle-1"> Empresa </span><br />{{
                    nombreClienteFinal
                  }}
                </h2>
              </v-col>

              <v-col cols="12" class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="purple"
                      @click="pdfLink(titularActual.nitCliente, 'cuentas')"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark> mdi-file-pdf-box </v-icon>
                    </v-btn>
                  </template>
                  <span>Cuentas Autorizadas</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="amber"
                      @click="pdfLink(titularActual.nitCliente, 'endoso')"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark> mdi-file-pdf-box </v-icon>
                    </v-btn>
                  </template>
                  <span>Carta Endoso</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12">
                <!-- Select de telefonos que tiene el titular -->
                <!-- :items="items" -->
                <v-row>
                  <v-col cols="4">
                    <v-select
                      v-model="indicativoSMS"
                      :items="itemsIndicativos"
                      label="Indicativo"
                      solo
                    ></v-select>
                  </v-col>
                  <v-col cols="8">
                    <v-select
                      v-if="titularActual.data['Telefono 1']"
                      v-model="selectTelefonoA"
                      :items="arrayTelefonos(titularActual.data['Telefono 1'])"
                      label="# Celulares titular"
                      solo
                    ></v-select>
                  </v-col>
                </v-row>

                <v-select
                  v-model="selectMensajePredeterminadoWhatsApp"
                  :items="itemsMensajePredeterminadoWhatsApp"
                  label="Mensajes Predeterminados"
                  solo
                  @change="
                    mensajePersonalizadoWhatsApp =
                      selectMensajePredeterminadoWhatsApp
                  "
                ></v-select>
                <v-textarea
                  v-model="mensajePersonalizadoWhatsApp"
                  outlined
                  name="input-7-4"
                  label="Mensaje personalizado no mayor a 160 caracteres"
                  value="..."
                  max-height="160"
                  :disabled="EnviandoMensaje"
                ></v-textarea>

                <v-btn
                  @click="
                    linksWhatsapp('whatsapp-personalizado', selectTelefonoA)
                  "
                  color="teal darken-2"
                  block
                >
                  <v-icon>mdi-whatsapp</v-icon>
                  <v-spacer></v-spacer>
                  Enviar WhatsApp
                </v-btn>
                <br />
                <v-btn
                  @click="linksWhatsapp('SMSTW', selectTelefonoA)"
                  color="teal darken-2"
                  block
                  :disabled="EnviandoMensaje"
                >
                  <v-icon>mdi-send-circle-outline</v-icon>
                  <v-spacer></v-spacer>
                  Enviar SMS
                </v-btn>
              </v-col>
            </v-col>
          </v-row>

          <!-- ADRES -->
          <v-row no-gutters>
            <v-col cols="12">
              <adres :titularActual="titularActual" v-if="updateComponente" />
            </v-col>
          </v-row>

          <v-spacer class="mt-5"></v-spacer>

          <!-- AGREGAR OBSERVACION -->
          <v-row no-gutters>
            <v-col cols="12">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel v-for="(item, i) in 1" :key="i">
                  <v-expansion-panel-header color="blue-grey darken-3">
                    Agregar una OBSERVACIÓN

                    <v-divider> </v-divider>
                    <template v-slot:actions>
                      <v-icon color="blue"> 👀 </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="blue-grey darken-4">
                    <v-row>
                      <v-col class="d-flex" cols="4" sm="2">
                        <v-select
                          v-model="selectContacto"
                          :items="itemsContacto"
                          label="Seleccione"
                          chips
                          persistent-hint
                          @change="cambioitemsContacto(selectContacto)"
                        ></v-select>
                      </v-col>
                      <v-col class="d-flex" cols="4" sm="6">
                        <v-select
                          v-model="selectEfecto"
                          :items="itemsEfecto"
                          label="Efecto"
                          chips
                        ></v-select>
                      </v-col>
                      <v-col cols="4" sm="4" md="4">
                        <!-- Seccion Calendario -->

                        <v-dialog
                          ref="dialogDate"
                          v-model="modalPiker"
                          :value="date"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Fecha próximo toque"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :min="fechaHoy()"
                            v-model="date"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modalPiker = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="modalPiker = false"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-row class="text-center">
                      <v-col cols="12" sm="12">
                        <v-textarea
                          v-model="textTareaBoservaciones"
                          name="input-7-1"
                          filled
                          label="Observaciones - No más de 300 Caracteres"
                          auto-grow
                          value=""
                          :rules="[
                            (v) =>
                              (v || '').length <= 500 ||
                              'No debe superar más de 500 caracteres.',
                          ]"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-divider></v-divider>
                      <v-btn
                        color="orange"
                        @click="agregarObservacion('Observacion')"
                        >Agregar Observción</v-btn
                      >
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <v-divider class="mt-5"></v-divider>
          <v-spacer class="mt-5"></v-spacer>

          <v-row>
            <v-col class="text-center" cols="12" md="12"
              ><h3>Procesos y Ficha Producto</h3>
            </v-col>
            <!-- LISTADO PROCESOS -->
            <v-col cols="6">
              <div class="font-weight-bold ml-8 mb-2">Procesos Recientes</div>
              <v-responsive class="overflow-y-auto" max-height="400">
                <!-- Linea de proceso. -->
                <v-card
                  width="100%"
                  max-height="100%"
                  class="overflow-auto rounded-xl"
                  color="blue-grey darken-4"
                >
                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="message in ultimosElementosAray(
                        titularActual.observaciones
                      )"
                      :key="message.time"
                      :color="semaforoObservaciones(message.efecto)"
                      icon-color="success"
                      :icon="iconsDinamic(message.efecto)"
                      large
                      max-height="30%"
                      fill-dot
                    >
                      <div>
                        <div class="font-weight-normal">
                          <strong>{{ message.efecto }}</strong>
                          <br />
                          Contacto:
                          {{ message.contacto }}
                        </div>
                        <div>{{ message.observacion }}</div>
                        <div class="caption">
                          P.C: {{ timeTodate(message.fecha) }}
                        </div>
                        <div class="caption" v-if="message.fechaR != undefined">
                          F.R: {{ timeTodate(message.fechaR) }}
                        </div>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                  <!-- -->
                </v-card>
              </v-responsive>
            </v-col>
            <!-- DATOS DEL PRODUCTO ADQUIRIDO -->
            <v-col cols="6">
              <v-card
                class="mx-auto rounded-xl blue-grey darken-3"
                max-width="100%"
                tile
              >
                <v-card-title class="subtitle-1 blue-grey darken-4">
                  Datos del Producto Adquirido
                </v-card-title>

                <v-list-item v-if="titularActual.data.Producto != undefined">
                  <v-list-item-content>
                    <v-list-item-title>Producto</v-list-item-title>
                    <v-list-item-subtitle>{{
                      titularActual.data.Producto
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- MONTO -->
                <v-list-item v-if="titularActual.data.Monto != undefined">
                  <v-list-item-content>
                    <v-list-item-title>Valor Inicial</v-list-item-title>
                    <v-list-item-subtitle
                      v-if="titularActual.data.Monto != undefined"
                    >
                      {{ decimales(titularActual.data.ValorInicial) }}= |

                      <span
                        v-if="
                          titularActual.data['Valor de cuotas'] != undefined
                        "
                      >
                        Valor Cuota:
                        {{ decimales(titularActual.data["Valor de cuotas"]) }}=
                      </span>
                    </v-list-item-subtitle>
                    <v-list-item-title class="mt-3"
                      >Financiación / Saldo</v-list-item-title
                    >
                    <v-list-item-subtitle
                      v-if="titularActual.data.Monto != undefined"
                    >
                      {{ decimales(titularActual.data.Monto) }}=
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else>
                  <v-list-item-content>
                    <v-list-item-title>Financiación</v-list-item-title>
                    <v-list-item-subtitle>
                      No existe ó El formato n/a
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- Ultimo Pago -->
                <v-list-item
                  v-if="titularActual.data['Fecha ultimo pago'] != undefined"
                >
                  <v-list-item-content>
                    <v-list-item-title>Fecha último pago</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ titularActual.data["Fecha ultimo pago"] }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else>
                  <v-list-item-content>
                    <v-list-item-title>Fecha último pago</v-list-item-title>
                    <v-list-item-subtitle>
                      No existe ó El formato n/a
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="titularActual.data['Numero de cuotas'] != undefined"
                >
                  <v-list-item-content>
                    <v-list-item-title># Cuotas</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ titularActual.data["Numero de cuotas"] }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  two-line
                  v-if="titularActual.data['Cuenta'] != undefined"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >Cuenta / Factura / Pagaré</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <v-row>
                        <v-col cols="4">
                          {{ titularActual.data["Cuenta"] }}
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <!-- ZONA DOCUMENTOS PDF's -->
                <v-row>
                  <v-col>
                    <!-- DOC TITULO VALOR -->
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title></v-list-item-title>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col
                              v-if="titularActual.docPedido != undefined"
                              cols="3"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                class="mx-5 pa-3"
                                fab
                                dark
                                small
                                color="pink"
                                v-if="titularActual.docPedido == true"
                                @click="pdfTituloValorTitular('tituloValor')"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" dark>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </template>
                                  <span>Titulo Valor</span>
                                </v-tooltip>
                              </v-btn>

                              <v-btn
                                class="mx-5 pa-3"
                                fab
                                dark
                                small
                                color="blue"
                                v-else
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" dark>
                                      mdi-text-box-remove
                                    </v-icon>
                                  </template>
                                  <span>Pendiente Titulo Valor</span>
                                </v-tooltip>
                              </v-btn>
                            </v-col>
                            <v-col v-else>
                              <v-btn
                                class="mx-5 pa-3"
                                fab
                                dark
                                small
                                color="blue"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" dark>
                                      mdi-text-box-remove
                                    </v-icon>
                                  </template>
                                  <span>Pendiente Título Valor</span>
                                </v-tooltip>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col>
                    <!-- DOC ACUERDO DE PAGO -->
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title></v-list-item-title>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col
                              v-if="titularActual.acuerdoPagoDoc != undefined"
                              cols="3"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                class="mx-5 pa-3"
                                fab
                                dark
                                small
                                color="pink"
                                v-if="titularActual.acuerdoPagoDoc == true"
                                @click="
                                  pdfTituloValorTitular('acuerdo-de-pago')
                                "
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" dark>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </template>
                                  <span>Acuerdo de Pago</span>
                                </v-tooltip>
                              </v-btn>
                            </v-col>
                            <v-col v-else>
                              <v-btn
                                class="mx-5 pa-3"
                                fab
                                dark
                                small
                                color="blue"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" dark>
                                      mdi-text-box-remove
                                    </v-icon>
                                  </template>
                                  <span>Pendiente Acuerdo de Pago</span>
                                </v-tooltip>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col>
                    <!-- DOC PAZ Y SALVO V1 -->
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title></v-list-item-title>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col
                              v-if="titularActual.PazySalvov1 != undefined"
                              cols="3"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                class="mx-5 pa-3"
                                fab
                                dark
                                small
                                color="orange"
                                v-if="titularActual.PazySalvov1 == true"
                                @click="pdfTituloValorTitular('paz-salvo-v1')"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" dark>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </template>
                                  <span>Paz y Salvo v1</span>
                                </v-tooltip>
                              </v-btn>
                            </v-col>
                            <v-col v-else>
                              <v-btn
                                class="mx-5 pa-3"
                                fab
                                dark
                                small
                                color="blue"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" dark>
                                      mdi-text-box-remove
                                    </v-icon>
                                  </template>
                                  <span>Pendiente Paz y Salvo v1</span>
                                </v-tooltip>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <!-- DOC PAZ Y SALVO V2 -->
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title></v-list-item-title>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col
                              v-if="titularActual.PazySalvov2 != undefined"
                              cols="3"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                class="mx-5 pa-3"
                                fab
                                dark
                                small
                                color="orange"
                                v-if="titularActual.PazySalvov2 == true"
                                @click="pdfTituloValorTitular('paz-salvo-v2')"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" dark>
                                      mdi-file-pdf-box
                                    </v-icon>
                                  </template>
                                  <span>Paz y Salvo v2</span>
                                </v-tooltip>
                              </v-btn>
                            </v-col>
                            <v-col v-else>
                              <v-btn
                                class="mx-5 pa-3"
                                fab
                                dark
                                small
                                color="blue"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" dark>
                                      mdi-text-box-remove
                                    </v-icon>
                                  </template>
                                  <span>Pendiente Paz y Salvo v2</span>
                                </v-tooltip>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
                <v-row>
                  <v-col class="text-center">
                    <!-- ABONOS -->
                    <abonos
                      @callAbono="agregaAbono"
                      :respuestaServerAbono="respuetaAbono"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <!-- HISTORIAL DE PAGOS -->
          <v-divider class="mt-5"></v-divider>
          <v-spacer class="mt-5"></v-spacer>

          <v-row>
            <v-col class="text-center" cols="12" md="12"
              ><h3>Historial y Acuerdo de Pago</h3>
            </v-col>
            <v-row>
              <v-col>
                <historialPagos
                  :titularActual="titularActual"
                  v-if="titularActual.historialPagos != undefined"
                />
                <div
                  class="pa-4 blue-grey darken-3 text-no-wrap rounded-pill text-center"
                  v-else
                >
                  No tiene historial de pagos.
                </div>
              </v-col>
            </v-row>
          </v-row>
          <v-spacer class="mt-5"></v-spacer>
          <!-- DATOS ACUERDOS DE PAGO CHECK LIST -->
          <v-row>
            <v-col v-if="titularActual.acuerdoPago != undefined">
              <v-container class="px-0" fluid>
                <v-card elevation="2" color="blue-grey darken-4">
                  <v-card-title class="text-center"
                    >Acuerdo de pago
                    {{ titularActual.acuerdoPago.length }}</v-card-title
                  >
                  <v-divider></v-divider>
                  <v-card-text
                    v-for="(item, index) in titularActual.acuerdoPago"
                    :key="index"
                  >
                    <v-row>
                      <v-col cols="8">
                        <v-checkbox
                          hide-details
                          class="shrink mr-2 mt-0"
                          v-model="item.estado"
                          :label="`Acuerdo ${index + 1} - Monto: ${decimales(
                            item.monto
                          )} - Realizó el pago? ${cambioBooleam(item.estado)} `"
                          :disabled="item.estado"
                          @change="
                            actualizaAcuerdoPago(
                              'acuerdoPago',
                              item.estado,
                              index
                            )
                          "
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="4">
                        <span class="caption">
                          {{ timeTodate(item.fecha) }}</span
                        >
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col
              class="pa-4 blue-grey darken-3 text-no-wrap rounded-pill text-center"
              v-else
            >
              No tiene acuerdos de pago.
            </v-col>
          </v-row>
          <v-spacer class="mt-5"></v-spacer>
          <!-- ACUERDOS DE PAGOS -->
          <v-row>
            <v-col cols="12">
              <v-expansion-panels
                v-if="
                  usuarioRol == 'acm' ||
                  usuarioRol == 'dcm' ||
                  usuarioRol == 'g'
                "
                v-model="panelAP"
                multiple
              >
                <v-expansion-panel
                  v-for="(item, i) in 1"
                  :key="i"
                  class="blue-grey darken-4"
                  @click="
                    interesesFinales(
                      titularActual.data.Monto,
                      titularActual.data['Fecha ultimo pago']
                    ),
                      calculandoDescuento()
                  "
                >
                  <v-expansion-panel-header>
                    Gestionar Acuerdo de Pago
                    <v-spacer />
                    <span
                      >Deuda {{ decimales(titularActual.data.Monto) }}
                    </span>

                    <span>
                      Interes
                      {{ decimales(Math.floor(newInteresesDescuento)) }}
                    </span>

                    <span>
                      Total
                      {{
                        decimales(
                          Math.floor(
                            titularActual.data.Monto + newInteresesDescuento
                          )
                        )
                      }}
                    </span>
                  </v-expansion-panel-header>

                  <v-divider></v-divider>

                  <v-expansion-panel-content>
                    <v-row>
                      <v-col class="d-flex" cols="6" sm="6">
                        <v-container>
                          <v-row
                            v-for="(item, index) in dateAcuerdosPago"
                            :key="index"
                            color="primary"
                          >
                            <v-spacer />
                            <v-col cols="12" sm="12" md="12" color="red">
                              <p class="font-weight-regular">
                                Cuotas {{ index + 1 }} fecha {{ item }}
                              </p>
                              <v-text-field
                                disabled
                                :value="
                                  decimales(
                                    Math.floor(
                                      Math.floor(
                                        titularActual.data.Monto +
                                          newInteresesDescuento
                                      ) /
                                        (index + 1)
                                    )
                                  )
                                "
                                label="Valor"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col cols="6" sm="6" md="6">
                        <!-- Seccion Calendario -->
                        <v-dialog
                          ref="dialogDate"
                          v-model="modalPikerAcuerdosPago"
                          :value="dateAcuerdosPago"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateAcuerdosPago"
                              label="Conjunto de Fechas..."
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateAcuerdosPago"
                            multiple
                            scrollable
                            :min="fechaHoy()"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modalPikerAcuerdosPago = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="modalPikerAcuerdosPago = false"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>

                        <v-row>
                          <v-col cols="12">
                            Descuento sobre Interés
                            {{ descuentoSobreIntereses }}%</v-col
                          >
                          <v-col cols="12">
                            <v-slider
                              v-model="descuentoSobreIntereses"
                              hint="Desplaza con las flechas..."
                              max="100"
                              min="-100"
                              thumb-color="red"
                              thumb-label="always"
                              value="0"
                              @change="calculandoDescuento()"
                            ></v-slider>

                            Total Intereses:
                            {{ decimales(totalInteresesJS) }} <br />
                            Total dias mora: {{ totalDiasJS }} <br />
                            Total semanas en mora: {{ semanasJS }} <br />
                            <v-divider />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="text-center">
                      <v-col cols="12" sm="12">
                        <v-textarea
                          v-model="textTareaBoservacionesAcuerdoPago"
                          name="input-7-1"
                          filled
                          label="Observaciones - No más de 200 Caracteres"
                          auto-grow
                          value=""
                          :rules="[
                            (v) =>
                              (v || '').length <= 200 ||
                              'No debe superar más de 200 caracteres.',
                          ]"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-divider class="pa-3"></v-divider>
                    <v-row>
                      <v-spacer />
                      <v-btn
                        color="amber darken-4"
                        @click="agregarObservacion('AcuerdoPago')"
                        >Generar Acuerdo de Pago</v-btn
                      >
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <!-- CREA PDF ACUERDO DE PAGO -->
          <v-spacer class="mt-5"></v-spacer>
          <creaPdf
            :acuerdos="dateAcuerdosPago"
            :monto="titularActual.data.Monto"
            :interesesDescuento="newInteresesDescuento"
            :titularActual="titularActual"
            v-if="
              usuarioRol == 'acm' ||
              usuarioRol == 'dcm' ||
              usuarioRol == 'g' ||
              usuarioRol == 'adm'
            "
          />

          <creaPdfPazySalvo2
            :titularActual="titularActual"
            v-if="usuarioRol == 'adm' || usuarioRol == 'g'"
          />

          <v-divider class="mt-5"></v-divider>
          <v-spacer class="mt-5"></v-spacer>
          <!-- ROW FINAL -->
          <!-- FORMULARIO PARA ACTULIZACION DE CAMPOS -->
          <v-row>
            <v-col class="text-center" cols="12" md="12"
              ><h3>Actualización de Datos Titular</h3>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="titularActual.data.Nombre"
                :disabled="isUpdating"
                filled
                color="blue-grey lighten-2"
                label="Titular"
                @change="actualizarTitular('Nombre', titularActual.data.Nombre)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <!--  v-if="titularActual.data['Telefono 1'] != undefined" -->
              <v-text-field
                v-model="titularActual.data['Telefono 1']"
                :disabled="isUpdating"
                color="blue-grey lighten-2"
                label="Teléfonos (#,#,#,...etc)"
                filled
                @change="
                  actualizarTitular(
                    'Telefono 1',
                    titularActual.data['Telefono 1']
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- SEGUNDA SECCIÓN DATOS CLIENTE -->
          <v-row class="mt-n10">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="titularActual.data.email"
                :disabled="isUpdating"
                filled
                color="blue-grey lighten-2"
                label="Correo (@,@,@, etc...)"
                @change="actualizarTitular('email', titularActual.data.email)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="titularActual.data['Direccion']"
                :disabled="isUpdating"
                color="blue-grey lighten-2"
                label="Dirección"
                filled
                @change="
                  actualizarTitular(
                    'Direccion',
                    titularActual.data['Direccion']
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-divider class="mt-5"></v-divider>
          <v-spacer class="mt-5"></v-spacer>
          <!-- DATOS ADICIONALES TITULAR INVESTIGACIÓN -->
          <v-row>
            <v-col class="text-center" cols="12" md="12"
              ><h3>Investigación</h3>
            </v-col>
            <v-col cols="12">
              <datosAdicionales
                :titularActual="titularActual"
                v-if="updateComponente"
              />
            </v-col>
            <v-col cols="12">
              <datosInmuebles
                :titularActual="titularActual"
                v-if="updateComponente"
              />
            </v-col>
            <v-col cols="12">
              <datosInmueblesFincaRaiz
                :titularActual="titularActual"
                v-if="updateComponente"
              />
            </v-col>

            <!-- ANTERIORMENTE ADRES -->
          </v-row>
          <!-- DATOS Referencia y Demás.... 
            <v-row>
              <v-col cols="12">
                <datosSobrantes
                  :titularActual="titularActual"
                  v-if="updateComponente"
                />
              </v-col>
            </v-row>-->
          <v-divider class="mt-5"></v-divider>
          <v-spacer class="mt-8"></v-spacer>

          <!-- files .PDF Pedido o Pagaré -->
          <v-row class="text-center">
            <v-col cols="12" md="12"><h3>Zona PDF'S</h3> </v-col>
            <v-col
              class="text-center pa-4 blue-grey darken-4 text-no-wrap rounded-pill text-center"
              cols="12"
            >
              <div class="subtitle-2 cyan darken-4 text-no-wrap rounded-pill">
                Respuesta PDF's: {{ respuestaFile }}
              </div>
            </v-col>
            <!-- files .PDF Titulo Valor -->
            <v-col cols="12" md="4">
              <v-file-input
                v-model="file1"
                @change="validaSubidaPDFS(file1, 'tituloValor')"
                :disabled="isUpdating"
                :rules="fileRules"
                accept=".pdf"
                show-size
                label="PDF Pedido o Pagaré"
                filled
                chips
                v-if="
                  usuarioRol == 'g' ||
                  usuarioRol == 'j' ||
                  usuarioRol == 'adm' ||
                  usuarioRol == 'dcm' ||
                  usuarioRol == 'acm'
                "
              ></v-file-input>
              <span v-else>Zona Titulo Valor</span>
            </v-col>
            <!-- files .PDF Pagos -->
            <v-col cols="12" md="4">
              <v-file-input
                v-model="file2"
                @change="validaSubidaPDFS(file2, 'pagos')"
                :disabled="isUpdating"
                :rules="fileRules"
                accept=".pdf"
                show-size
                label="PDF Pago(s)"
                filled
                chips
                v-if="
                  usuarioRol == 'acm' ||
                  usuarioRol == 'dcm' ||
                  usuarioRol == 'g' ||
                  usuarioRol == 'j' ||
                  usuarioRol == 'adm'
                "
              ></v-file-input>
            </v-col>
            <!-- files .PDF Acuerdo de Pago -->
            <v-col cols="12" md="4">
              <v-file-input
                v-model="file3"
                @change="validaSubidaPDFS(file3, 'acuerdoPago')"
                :disabled="isUpdating"
                :rules="fileRules"
                accept=".pdf"
                show-size
                label="PDF Acuerdo Pago"
                filled
                chips
                v-if="
                  usuarioRol == 'acm' ||
                  usuarioRol == 'dcm' ||
                  usuarioRol == 'g' ||
                  usuarioRol == 'j' ||
                  usuarioRol == 'adm'
                "
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row class="mt-n8">
            <!-- files .PDF Paz y Salvo Antiguo -->
            <v-col
              cols="12"
              md="4"
              v-if="titularActual.PazySalvov1 == undefined"
              v-show="ocultaPazySalvoSubida"
            >
              <v-file-input
                v-model="file4"
                @change="validaSubidaPDFS(file4, 'pazysalvoOld')"
                :disabled="isUpdating"
                :rules="fileRules"
                accept=".pdf"
                show-size
                label="PDF Paz y Salvo v.1"
                filled
                chips
                v-if="usuarioRol == 'g' || usuarioRol == 'adm'"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-else-if="titularActual.PazySalvov1 == true"
            >
              <span
                >Se ha generado el Paz y Salvo v1, el titular lo podrá
                descargar.</span
              >
            </v-col>
            <!-- files .PDF Paz y Salvo NUEVO isUpdating en disabled-->
            <v-col
              cols="12"
              md="4"
              v-if="titularActual.PazySalvov2 == undefined"
              v-show="ocultaPazySalvoSubida"
            >
              <v-file-input
                v-model="file5"
                @change="validaSubidaPDFS(file5, 'pazysalvoV2')"
                :disabled="false"
                :rules="fileRules"
                accept=".pdf"
                show-size
                label="PDF Paz y Salvo v.2"
                filled
                chips
                v-if="usuarioRol == 'g' || usuarioRol == 'adm'"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-else-if="titularActual.PazySalvov2 == true"
            >
              <span>
                Se ha generado el Paz y Salvo v2, el titular lo podrá descargar.
              </span>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <!--JURIDICO-->
          <v-container>
            <v-row class="pa-2 text-center" justify="center">
              <v-col cols="12" md="6"><h3>Módulo Jurídico</h3> </v-col>
              <!--JURIDICO PDF Demanda-->
              <v-col cols="12" md="6" v-if="usuarioRol == 'j'">
                <v-file-input
                  v-model="file6"
                  :disabled="false"
                  :rules="fileRules"
                  accept=".pdf"
                  show-size
                  label="PDF Demanda"
                  filled
                  chips
                  rounded
                  dense
                  @change="validaSubidaPDFS(file6, 'PDF-Demanda')"
                ></v-file-input>
              </v-col>
            </v-row>

            <!-- files 1.PDF JURIDICO -->
            <v-row class="pa-2 mt-n5" align="center" justify="center">
              <!--JURIDICO PDF Acta Reparto-->
              <v-col cols="12" md="4">
                <v-file-input
                  v-model="file7"
                  :disabled="false"
                  :rules="fileRules"
                  accept=".pdf"
                  show-size
                  label="PDF Acta Reparto"
                  filled
                  chips
                  rounded
                  dense
                  @change="validaSubidaPDFS(file7, 'ActaReparto')"
                  v-if="usuarioRol == 'j'"
                ></v-file-input>
              </v-col>
              <!--JURIDICO PDF Auto-->
              <v-col cols="12" md="4">
                <v-file-input
                  v-model="file8"
                  :disabled="false"
                  :rules="fileRules"
                  accept=".pdf"
                  show-size
                  label="PDF Auto"
                  filled
                  chips
                  rounded
                  dense
                  @change="validaSubidaPDFS(file8, 'Auto')"
                  v-if="usuarioRol == 'j'"
                ></v-file-input>
              </v-col>
              <!--JURIDICO PDF Oficio-->
              <v-col cols="12" md="4">
                <v-file-input
                  v-model="file9"
                  :disabled="false"
                  :rules="fileRules"
                  accept=".pdf"
                  show-size
                  label="PDF Oficio"
                  filled
                  chips
                  rounded
                  dense
                  @change="validaSubidaPDFS(file9, 'Oficio')"
                  v-if="usuarioRol == 'j'"
                ></v-file-input>
              </v-col>
            </v-row>

            <!-- files 2 .PDF JURIDICO -->
            <v-row class="pa-2 mt-n5" align="center" justify="center">
              <!--JURIDICO PDF Terminación de Proceso-->
              <v-col cols="12" md="6">
                <v-file-input
                  v-model="file10"
                  :disabled="false"
                  :rules="fileRules"
                  accept=".pdf"
                  show-size
                  label="PDF Terminación de Proceso"
                  filled
                  chips
                  rounded
                  dense
                  @change="validaSubidaPDFS(file10, 'TerminacionProceso')"
                  v-if="usuarioRol == 'j'"
                ></v-file-input>
              </v-col>
              <!--JURIDICO PDF Liquidación Titulos-->
              <v-col cols="12" md="6">
                <v-file-input
                  v-model="file11"
                  :disabled="false"
                  :rules="fileRules"
                  accept=".pdf"
                  show-size
                  label="PDF Liquidación Titulos"
                  filled
                  chips
                  rounded
                  dense
                  @change="validaSubidaPDFS(file11, 'LiquidacionTitulos')"
                  v-if="usuarioRol == 'j'"
                ></v-file-input>
              </v-col>
              <!--JURIDICO PDF n/a
              <v-col cols="12" md="4">
                <v-file-input
                  v-model="file9"
                  :disabled="false"
                  :rules="fileRules"
                  accept=".pdf"
                  show-size
                  label="PDF Oficio"
                  filled
                  chips
                  rounded
                  dense
                  @change="validaSubidaPDFS(file9, 'Oficio')"
                  v-if="usuarioRol == 'j'"
                ></v-file-input>
              </v-col>-->
            </v-row>

            <!-- ICONS .PDF JURIDICO -->
            <v-row
              class="pa-2 text-center mt-n15"
              align="center"
              justify="center"
            >
              <v-col v-if="titularActual.pdfDemanda != undefined">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      class="mx-2"
                      fab
                      dark
                      large
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                      @click="pdfTituloValorTitular('demanda')"
                    >
                      <v-icon dark> mdi-layers</v-icon>
                    </v-btn>
                  </template>
                  <span>Demanda</span>
                </v-tooltip>
              </v-col>
              <v-col v-else>
                <v-btn disabled class="mx-2" fab dark large>
                  <v-icon dark> mdi-layers </v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="titularActual.pdfActaReparto != undefined">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      class="mx-2"
                      fab
                      dark
                      large
                      color="purple"
                      v-bind="attrs"
                      v-on="on"
                      @click="pdfTituloValorTitular('reparto')"
                    >
                      <v-icon dark> mdi-bicycle-basket </v-icon>
                    </v-btn>
                  </template>
                  <span>Acta de Reparto</span>
                </v-tooltip>
              </v-col>
              <v-col v-else>
                <v-btn disabled class="mx-2" fab dark large>
                  <v-icon dark> mdi-bicycle-basket </v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="titularActual.pdfAuto != undefined">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      class="mx-2"
                      fab
                      dark
                      large
                      color="light-blue"
                      v-bind="attrs"
                      v-on="on"
                      @click="pdfTituloValorTitular('auto')"
                    >
                      <v-icon dark> mdi-car-wireless </v-icon>
                    </v-btn>
                  </template>
                  <span>Auto</span>
                </v-tooltip>
              </v-col>
              <v-col v-else>
                <v-btn disabled class="mx-2" fab dark large>
                  <v-icon dark> mdi-car-wireless </v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="titularActual.pdfOficio != undefined">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      :disabled="!titularActual.pdfOficio"
                      class="mx-2"
                      fab
                      dark
                      large
                      color="pink"
                      v-bind="attrs"
                      v-on="on"
                      @click="pdfTituloValorTitular('oficio')"
                    >
                      <v-icon dark> mdi-chair-rolling </v-icon>
                    </v-btn>
                  </template>
                  <span>Oficio</span>
                </v-tooltip>
              </v-col>
              <v-col v-else>
                <v-btn disabled class="mx-2" fab dark large>
                  <v-icon dark> mdi-chair-rolling </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-spacer class="mt-8"></v-spacer>
            <!-- ICONS 2 .PDF JURIDICO -->
            <!-- Terminación de proceso y Liquidación de Titulos -->
            <v-row
              class="pa-2 text-center mt-n15"
              align="center"
              justify="center"
            >
              <v-col v-if="titularActual.pdfTp != undefined">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      class="mx-2"
                      fab
                      dark
                      large
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                      @click="pdfTituloValorTitular('tp')"
                    >
                      <v-icon dark> mdi-gavel</v-icon>
                    </v-btn>
                  </template>
                  <span>Terminación De Proceso</span>
                </v-tooltip>
              </v-col>
              <v-col v-else>
                <v-btn disabled class="mx-2" fab dark large>
                  <v-icon dark> mdi-gavel </v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="titularActual.pdfLt != undefined">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      class="mx-2"
                      fab
                      dark
                      large
                      color="purple"
                      v-bind="attrs"
                      v-on="on"
                      @click="pdfTituloValorTitular('lt')"
                    >
                      <v-icon dark> mdi-format-title </v-icon>
                    </v-btn>
                  </template>
                  <span>Liquidación Titulos</span>
                </v-tooltip>
              </v-col>
              <v-col v-else>
                <v-btn disabled class="mx-2" fab dark large>
                  <v-icon dark> mdi-format-title </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <v-divider></v-divider>
          <v-spacer class="mt-5"></v-spacer>
          <!--LISTADO DE imagenes o PAGOS-->
          <v-row class="text-center">
            <v-col cols="12" md="12"><h3>Zona COMPROBANTES DE PAGO</h3> </v-col>
            <!--MUESTRA COMPROBANTES DE PAGO -->
            <v-col>
              <v-sheet
                v-if="listadoPagos.length > 0"
                class="mx-auto rounded-xl"
                elevation="8"
                max-width="80%"
                color="blue-grey darken-3"
              >
                <v-slide-group
                  v-model="modelShell"
                  class=""
                  active-class="success"
                  show-arrows
                >
                  <v-slide-item
                    v-for="(n, index) in listadoPagos"
                    :key="n.time"
                    v-slot="{ active, toggle }"
                  >
                    <v-card
                      :color="active ? undefined : 'orange darken-2'"
                      class="ma-4"
                      height="200"
                      width="100"
                      @click="toggle"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-scale-transition>
                          <v-icon
                            v-if="active"
                            color="white"
                            size="48"
                            @click="linksWhatsapp('ver-pago', n.url)"
                            >mdi-file-eye</v-icon
                          >
                          <div class="text-center" v-else>
                            # {{ index + 1 }}

                            <br />
                            <p class="text-justify pa-5">
                              {{ timeTodate(n.time, "pagos") }}
                            </p>
                          </div>
                        </v-scale-transition>
                      </v-row>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>

                <v-expand-transition>
                  <v-sheet
                    color="blue-grey darken-4"
                    v-if="modelShell != null"
                    height="80"
                    tile
                  >
                    <v-row class="fill-height" align="center" justify="center">
                      <h3 class="text-h6">Archivo # {{ modelShell + 1 }}</h3>
                    </v-row>
                  </v-sheet>
                </v-expand-transition>
              </v-sheet>
              <v-col
                class="pa-4 blue-grey darken-3 text-no-wrap rounded-pill text-center"
                cols="12"
                v-else
              >
                <p>
                  Para ver los comprobantes de pago, de clic en el botón azul
                  que se encuentra en la parte inferior izquierda.
                </p>
              </v-col>
            </v-col>
          </v-row>

          <v-divider class="mt-5"></v-divider>
          <v-spacer class="mt-5"></v-spacer>

          <!-- Historial de Cambios -->
          <v-row>
            <historialCambios :titularActual="titularActual" />
          </v-row>
          <v-divider class="pa-3 mt-3"></v-divider>
          <!-- Contacto Secundario -->
          <p>Requerimientos Especiales...</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue"
                @click="listadoPDFpagos"
                v-bind="attrs"
                v-on="on"
                fab
                dark
              >
                <v-icon>mdi-ticket-percent</v-icon>
              </v-btn>
            </template>
            <span>Listado de Pagos</span>
          </v-tooltip>

          <v-divider></v-divider>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="dialog = false"
                color="amber darken-1"
                v-bind="attrs"
                v-on="on"
                fab
                dark
                v-show="llegaTitulares.length == 1"
              >
                <v-icon>mdi-exit-run</v-icon>
                {{ titularActual.length }}
              </v-btn>
            </template>
            <span>Salir</span>
          </v-tooltip>

          <v-divider></v-divider>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="cargarTitular()"
                color="red"
                v-bind="attrs"
                v-on="on"
                fab
                dark
                :disabled="activarSiguienteTitular"
              >
                <v-icon>mdi-chevron-right</v-icon>
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <span>Siguiente Titular</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>

      <!-- CARGANDO CUANDO NO HAN LLEGADO DATOS EN TITULAR 
      <div >
        <v-card color="primary" dark>
          <v-card-text>
            Espere por favor
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </div>
      Deshabilita porque se montaba sobre el siguiente load Dialog
      -->

      <!-- Card Proceso  titular -->
      <v-dialog hide-overlay persistent width="300" v-else>
        <v-card color="primary" dark>
          <v-card-text>
            Espere por favor A
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Bloquea el fondo -->
      <v-overlay :value="isUpdating"></v-overlay>
      <!-- Card Proceso todo el modulo -->
      <v-dialog v-model="isUpdating" hide-overlay persistent max-width="290">
        <v-card color="primary" dark>
          <v-card-text class="text-center">
            Espere por favor <br />
            {{ mensajeGeneralCargador }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { FBLocalizarEjecutivo } from "../../../components/RecursoHumano/listadoEmpleados";
import { FBpuntajeTitular } from "../../../components/Cartera/puntajeTitular/puntajeTitular";
import {
  FBcargaTitulares,
  FBagregaObservacion,
  FBupdateTitular,
  FBverPDFClientes,
  FB_PDF_TituloValor_Titular,
  FBverPDFTituloValorTitular,
  FBverPDFpagosTitular,
  FBupdateTitularAcuerdoPago,
  FBTodosLosDatosCliente,
  FBconsultaTitularPorCedulaDCM,
  FBAddAbono,
} from "../../../components/Cartera/gestionaCartera";
import store from "../../../store";
import modalAgregaObservacion from "./subProcesos/agregaObservaciones.vue";
import {
  JSvalidaCamposObservaciones,
  JSdecimales,
  JSLiquidador,
} from "../../../components/Cartera/validacionesGestionC";
import creaPdf from "./subProcesos/creaPdfAcuerdoV2";
import creaPdfPazySalvo2 from "./subProcesos/creaPdfPazySalvoV2.vue";
import historialPagos from "./subProcesos/hisotorialPagosT.vue";
import datosAdicionales from "./subProcesos/datosAdicionales";
import datosInmuebles from "./subProcesos/datosInmuebles.vue";
import datosInmueblesFincaRaiz from "./subProcesos/datosInmuebles2.vue";
import adres from "./subProcesos/adres.vue";
import datosSobrantes from "./subProcesos/datosSobrantes";
import historialCambios from "./subProcesos/historialCambios.vue";
import abonos from "./abono.vue";
import cambioCedulaTT from "./subProcesos/cambioCedula.vue";
import cambioEjecutivo from "./subProcesos/cambioEjecutivo.vue";
import {
  senMensaje,
  llamadasTW,
  sendWhatsapp,
} from "../../../Util/twilio/index";

//Panel de DCM
export default {
  name: "gestionCartera",
  props: ["datosTitular"],
  components: {
    modalAgregaObservacion,
    creaPdf,
    creaPdfPazySalvo2,
    historialPagos,
    datosAdicionales,
    datosInmuebles,
    datosInmueblesFincaRaiz,
    adres,
    datosSobrantes,
    historialCambios,
    abonos,
    cambioCedulaTT,
    cambioEjecutivo,
  },
  data: () => ({
    empleados: [],
    rating: 0,
    titularBuscadoEstado: false,
    resultadoTitularBusqueda: "",
    benched: 0,
    pausa: false,
    nombreClienteFinal: "",
    activarSiguienteTitular: true,
    mensajeGeneralCargador: "",
    totalLlamarHoyFB: 0,
    sinTocarFB: 0,
    titularesPorDia: 60,
    usuario: store.state.user[2][0].id, //"JkjkRimf3fFN4MTeQj41", //Iury
    usuarioRol: store.state.user[2][0].data.cargo,
    dialog: false,
    disabledCrear: false,
    isUpdating: false,
    //formulario
    dataFormulario: {
      cliente: null,
      nit: null,
      servicio: [],
      contactoP: [],
      celCP: [],
      mailCP: [],
      cargoCP: null,
      contactoS: [],
      celCS: [],
      mailCS: [],
      cargoCS: null,
      estado: false,
    },
    nitValidacion: false,
    errorMessages: "",
    mensajeNit: "",
    file1: null,
    fileRules: [
      //(value) => !!value || "Obligatorio.",
      (value) => !value || value.size < 2500000 || "No puede superar las 2 MB!",
      (value) =>
        !value ||
        value.type == "application/pdf" ||
        "El archivo debe ser un PDF",
    ],
    file2: null,
    file3: null,
    file4: null,
    file5: null,
    file6: null,
    file7: null,
    file8: null,
    file9: null,
    file10: null,
    file11: null,
    ocultaPazySalvoSubida: true,
    messagesTimeRegistroModificado: [
      {
        from: "You",
        message: `Sure, I'll see you later.`,
        time: "10:42am",
        color: "deep-purple lighten-1",
        estado: false,
      },
    ],
    seccionesTitular: [
      {
        seccion: "Titular",
        titular: "Javier Prueba Contacte al programador.",
        cedula: "80852032",
        cuenta: 1024,
        direccion: "Cra 2",
        telefonos: "300,325,354",
        correo: "javier@gmail.com",
      },
    ],
    llegaTitulares: [],
    titularActual: [],
    selectContacto: [],
    itemsContacto: ["Deudor", "Tercero", "N/A"],
    selectEfecto: [],
    itemsEfecto: [],
    date: null,
    menu: false,
    modalPiker: false,
    menu2: false,
    textTareaBoservaciones: "",
    modelShell: null,
    selectTelefonoA: null,
    mensajePersonalizadoWhatsApp: "",
    selectMensajePredeterminadoWhatsApp: [],
    itemsMensajePredeterminadoWhatsApp: [
      "Comuniquese al xxxxxxxx para poner al día su vida crediticia.",
    ],
    respuestaFile: "...",
    listadoPagos: [],
    panel: [], //Observaciones
    panelAP: [], //Acuerdos pago
    loadinAcuerdos: false,
    dateAcuerdosPago: [],
    modalPikerAcuerdosPago: false,
    NumeroCuotasAP: 0,
    descuentoSobreIntereses: 100,
    interesesDescuento: 0,
    newInteresesDescuento: 0,
    totalAjustado: 0,
    newTotalAjustado: 0,
    textTareaBoservacionesAcuerdoPago: "",
    totalInteresesJS: 0,
    totalDiasJS: 0,
    semanasJS: 0,
    detalleLiquidador: [],
    updateComponente: true,
    respuetaAbono: "",
    EnviandoMensaje: false, //SMS
    itemsIndicativos: ["+57", "+52", "+56", "+34", "+54"],
    indicativoSMS: null,
  }),
  mounted() {
    //1. Genera el primer arreglo de titulares
    //this.buscaTitular();
    //console.log(store.state.user[2][0].id);
    //console.log(store.state.user[2][0].data.cargo);
    this.buscaEjecutivoFB();
  },
  methods: {
    async puntajeEjecutivoTitular(a) {
      //console.log(a);
      const resultPuntaje = await FBpuntajeTitular(
        this.titularActual,
        "puntajeTitular",
        a,
        this.usuario
      );
      //console.log("Respusta");
      //console.log(resultPuntaje);
    },
    async agregaAbono(valor) {
      //console.log(valor);
      const resultAbono = await FBAddAbono(
        this.titularActual,
        "abono",
        valor,
        this.usuario
      );

      if (resultAbono) {
        //this.isUpdating = false;
        //console.log("Abono agregado...");
        this.respuetaAbono = "¡Abono Agregado!";
      } else {
        //console.log("No se pudo guardar el pago. Intente en otra oportunidad.");
        this.respuetaAbono = "Error, al ingresar el abono...";
      }
    },
    async onlyTitular() {
      //console.log("Inicia busqueda unica");
      //console.log(this.datosTitular);
      //console.log(this.cedulaTitular);
      // this.dialogBuscar = true;
      const resultadoBusqueda = await FBconsultaTitularPorCedulaDCM(
        this.datosTitular
      );
      //console.log(resultadoBusqueda);

      if (!resultadoBusqueda[0]) {
        this.resultadoTitularBusqueda = "No se encontró titular.";
        this.titularBuscadoEstado = true;
      } else {
        this.resultadoTitularBusqueda = "";
        this.titularBuscadoEstado = false;
        //this.titularActual = resultadoBusqueda[1];
        this.llegaTitulares = resultadoBusqueda[1];
        this.dialog = true;
        this.cargarTitular();
      }

      /*if (resultadoBusqueda[0]) {
        this.rtBuscador = resultadoBusqueda[1];
        //this.titularesPorDia
        console.log(resultadoBusqueda);
        const result = await FBlocalizarTitularPorCedula(
          this.usuario,
          resultadoBusqueda[2]
        );
        console.log("Final...");
        console.log(result);
        this.llegaTitulares = result;
        this.titularActual = this.llegaTitulares[0];

        this.dialogBuscar = false;
        this.cedulaTitular = 0;
        //const buscaTitular = await FB(resultadoBusqueda[2])
      } else {
        this.dialogBuscar = false;
        this.cedulaTitular = 0;
        this.rtBuscador = resultadoBusqueda[1];
      }*/
      //console.log(resultadoBusqueda);
    },
    async listadoPDFpagos() {
      const result = await FBverPDFpagosTitular(this.titularActual);

      this.listadoPagos = result[1];
      //console.log(result);
      //FALTA REPONDER AL USUARIO....
    },
    async pdfTituloValorTitular(a) {
      //console.log("Llega a la function ", a);
      //console.log(id, nit, tipo);

      //acuerdoPago
      this.isUpdating = true;
      const rest = await FBverPDFTituloValorTitular(this.titularActual, a);
      //console.log(rest);

      if (rest) {
        this.isUpdating = false;

        this.copiaTexto(rest);

        //window.open(rest, "_blank");
        //this.resultadoPDF = "Abriendo archivo.";
      } else {
        this.isUpdating = false;
        //console.log("No existe el archivo");
        // this.resultadoPDF = "No existe el archivo.";
      }
    },
    async copiaTexto(dato) {
      this.iconoCopy = true;
      //this.$emit();
      let textoCopiado = dato;

      //Copia el texto en el portapapeles
      const a = await navigator.clipboard.writeText(textoCopiado);
      //console.log(a);
    },
    async pdfLink(nit, tipo) {
      //console.log(id, nit, tipo);
      this.isUpdating = true;
      const rest = await FBverPDFClientes(nit, tipo);
      //console.log(rest);

      if (rest) {
        this.isUpdating = false;
        this.copiaTexto(rest);
        //window.open(rest, "_blank");
        //this.resultadoPDF = "Abriendo archivo.";
      } else {
        this.isUpdating = false;
        //console.log("No existe el archivo");
        // this.resultadoPDF = "No existe el archivo.";
      }
    },
    async actualizaAcuerdoPago(campo, valor, index) {
      //'acuerdoPago', item.estado, index
      //campo, valor, poasición

      //console.log(this.titularActual.acuerdoPago);

      this.isUpdating = true;
      const result = await FBupdateTitularAcuerdoPago(
        this.titularActual,
        campo,
        valor,
        this.titularActual.acuerdoPago,
        this.usuario //Es el id del ejecutivo
      );

      //console.log("Lo que llega de Procesar acuerdo");
      //console.log(result);
      if (result) {
        this.isUpdating = false;
      } else {
        console.log("No se pudo guardar el pago. Intente en otra oportunidad.");
      }
    },
    async actualizarTitular(campo, valor) {
      this.isUpdating = true;
      // Estructura actualizarTitular('Nombre', titularActual.data.Nombre)"
      //console.log("Actualiza Titular");

      //console.log(campo, valor);
      //1. Valores salen titularActual, campo, valor
      const result = await FBupdateTitular(
        this.titularActual,
        campo,
        valor,
        this.usuario
      );

      if (result) {
        this.isUpdating = false;
        //console.log("Se ha actualizado con éxito");
      } else {
        this.isUpdating = false;
        //console.log("No se ha podido actulizar el campo!");
      }
    },
    //Funcion de INICIACIÓN PARA TRAER TODOS LOS TITULARES----------------------------------------------------------
    async buscaTitular() {
      //1. Es la Carga inicial que llega toda la posición de titulares para luego
      // hacer el ramdom
      //usiarios por dia 60
      /* this.isUpdating = true;

      //console.log("Pause: ", this.pausa);
      //Pausa siver para poder apagar la zona de cliente.

      if (!this.pausa) {
        //console.log("Arranca a cargar titulares");
        const result = await FBcargaTitulares(
          this.titularesPorDia,
          this.usuario
        );
        //Resultado Final
        //console.log("Resultado Final");
        //console.log(result);

        if (result[0] == true && result[1] == "titularLlega") {
          //1. Se deberia llenar el total de array para ir eliminando
          //la posicion 0 trae true
          //la posicion 1 trae todos los titulares asignados.
          this.llegaTitulares = result[2]; //Titulares asignado
          //console.log(this.llegaTitulares.length);
          //this.titularActual = _.sample(this.llegaTitulares); //Titular Random
          this.titularActual = this.llegaTitulares[0]; //Titular Random
          this.totalLlamarHoyFB = result[3];
          this.sinTocarFB = result[4];
          //console.log(this.titularActual);
          this.isUpdating = false;
          return true;
        } else if (
          result[0] == true &&
          result[1] == "recargar"
          // && result[2].length > 0
        ) {
          //Se llena la bolsa por primera vez y reiniciamos el programa.
          // console.log("Ejecutivo Nuevo - Asignación Realizada - RECARGANDO");

          this.mensajeGeneralCargador = "Cargando Titulares...";

          setTimeout(() => {
            //this.isUpdating = false;
            //this.dialog = false;
            this.cargarTitular();
          }, 4000);
        } else if (result[0] == false && result[1] == "noAsignados") {
          //No hay titulares asignados.
          //console.log("Ejecutivo Nuevo - No tiene titulares asignados.");

          this.mensajeGeneralCargador = "No tiene titulares asignados...";

          setTimeout(() => {
            this.isUpdating = false;
            this.dialog = false;
          }, 3000);
        }
      } else {
        this.isUpdating = false;
        this.pausa = false;
      }*/
    },
    //Funcion Reunicia porque ya termino los llamados
    async cargarTitular() {
      //console.log("Carga Titular ");
      this.isUpdating = true;
      this.updateComponente = false; //Recarga el componente de investigacion
      // console.log(this.llegaTitulares);

      //Reiniciar algunas varialbes.
      this.file1 = null;
      this.file2 = null;
      this.listadoPagos = [];
      this.panelAP = [];
      this.dateAcuerdosPago = [];
      this.selectContacto = [];
      this.selectEfecto = [];

      //Carga 1 titular
      this.titularActual = this.llegaTitulares[0];

      this.activarSiguienteTitular = true; //Activa el botón de siguiente
      this.isUpdating = false;
      //console.log(this.titularActual);

      //Reiniciar el componente Investigación....
      this.$nextTick().then(() => {
        // Add the component back in
        this.updateComponente = true; //Recarga el componente de investigacion
      });
    },
    async buscaCliente() {
      //console.log("Busca cliente");
      //console.log(this.dataFormulario.nit);
      this.isUpdating = true;
      this.mensajeNit = `Buscando...`;

      const rest = await FBbuscaCliente(this.dataFormulario.nit);
      //console.log("reusltado rest: ");
      //console.log(rest);
      if (rest) {
        this.isUpdating = false;
        //Existe usuario
        this.nitValidacion = true;
        this.mensajeNit = "";
        this.errorMessages = "Existe cliente";
        this.disabledCrear = true;
        return rest;
      } else if (!rest) {
        this.isUpdating = false;
        this.nitValidacion = false;
        //"Perfecto!, continua."=
        this.mensajeNit = "Perfecto!, continua.";
        this.errorMessages = "";
        this.disabledCrear = false;
        return rest;
      }
    },
    async creaCliente() {
      this.isUpdating = true;

      const arrays = await this.creaArray();

      //console.log(arrays);

      //File 1: es el certificado de cuenta
      //File 2: es el carta de endoso
      const res = await FBcreaCliente(
        this.dataFormulario,
        this.file1,
        this.file2
      );

      if (res[0]) {
        //respuesta servidor
        this.dataFormulario = {
          cliente: null,
          nit: null,
          servicio: [],
          contactoP: [],
          celCP: [],
          mailCP: [],
          cargoCP: null,
          contactoS: [],
          celCS: [],
          mailCS: [],
          cargoCS: null,
          estado: false,
        };
        this.file1 = null;
        this.file2 = null;
        this.isUpdating = false;
        this.$refs.form.reset();
      } else {
        this.dataFormulario = {
          cliente: null,
          nit: null,
          servicio: [],
          contactoP: [],
          celCP: [],
          mailCP: [],
          cargoCP: null,
          contactoS: [],
          celCS: [],
          mailCS: [],
          cargoCS: null,
          estado: false,
        };
        this.file1 = null;
        this.file2 = null;
        this.$refs.form.reset();
        this.isUpdating = false;
      }
    },
    async creaArray() {
      //convirtiendo los string en array
      for (let ele in this.dataFormulario) {
        //console.log(ele); //El indice, ejemplo: cliente
        switch (ele) {
          case "contactoP":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "celCP":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "mailCP":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "contactoS":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "celCS":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "mailCS":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          default:
            break;
        }
      }
      return true;
    },
    async agregarObservacion(tipoFormulario) {
      //console.log(this.titularActual);
      this.isUpdating = true;
      this.mensajeGeneralCargador = "Inicia Validador...";

      let validadorCampos = null;
      var total = this.titularActual.data.Monto + this.newInteresesDescuento; //Nuevo total generado por el Ejecutivo

      if (tipoFormulario == "AcuerdoPago") {
        //1. Validar si vienen todos los campos diligenciados.
        validadorCampos = await JSvalidaCamposObservaciones({
          a: tipoFormulario,
          b: this.dateAcuerdosPago,
          c: this.textTareaBoservacionesAcuerdoPago,
          d: total, //this.newTotalAjustado,
        });
      } else if (tipoFormulario == "Observacion") {
        //1. Validar si vienen todos los campos diligenciados.
        validadorCampos = await JSvalidaCamposObservaciones({
          a: tipoFormulario,
          b: this.selectContacto,
          c: this.selectEfecto,
          d: this.date,
          e: this.textTareaBoservaciones,
        });
      }

      if (tipoFormulario == "AcuerdoPago" && validadorCampos == true) {
        this.mensajeGeneralCargador = "Ingresando Acuerdo de Pago";
        /*console.log(
          "---------------ENVIO OBSERVACION Acuerdo Pago---------------"
        );
        console.log(
          this.newTotalAjustado,
          this.newTotalAjustado / this.dateAcuerdosPago.length,
          this.dateAcuerdosPago,
          this.textTareaBoservacionesAcuerdoPago
        );*/
        const resultadoFechas = await this.compiladoFechas(
          this.dateAcuerdosPago
        );
        //console.log(resultadoFechas[0]);
        //console.log("--------------------////----------------------");
        //1. Enviar nuevo elemento a la base de datos
        const rest = await FBagregaObservacion({
          titularActual: this.titularActual,
          selectContacto: "Deudor", //this.selectContacto,
          selectEfecto: "Acuerdo de pago", //this.selectEfecto,
          date: resultadoFechas[0], //fechaConvertida,
          textTareaBoservaciones: this.textTareaBoservacionesAcuerdoPago, //this.textTareaBoservaciones,
          resultadoFechas: resultadoFechas,
          newTotalAjustado: Math.floor(total),
          usuario: this.usuario,
        });
        //console.log(rest);
        //2. Agregar elemento al array que existe, puesto que ya existe en la base de datos.
        if (rest) {
          //3. Vamor a unir el array
          const unionArray = await this.fusionArray(
            "Deudor",
            "Acuerdo de pago",
            this.dateAcuerdosPago[0], //resultadoFechas[0],
            this.textTareaBoservacionesAcuerdoPago
          );
          this.titularActual.observaciones = unionArray;

          this.panelAP = []; //Se cierra el panel
          //reiniciar variables
          this.textTareaBoservacionesAcuerdoPago = "";
          this.dateAcuerdosPago = [];
          this.newTotalAjustado = 0;

          this.mensajeGeneralCargador = "Se ha agregado la observación.";

          setTimeout(() => {
            this.isUpdating = false; //oculta el loading...
            this.activarSiguienteTitular = false; //Activa el botón de siguiente
          }, 3000); //Apagar alerta
        } else {
          this.isUpdating = false;
          console.log(
            "Se ha tenido problemas para agregar la observación - Acuerdo de pago"
          );
        }
      } else if (tipoFormulario == "Observacion" && validadorCampos == true) {
        //console.log("Inicia envio");
        (this.mensajeGeneralCargador = "Ingresando observación "),
          this.selectEfecto;
        /*console.log("---------------ENVIO OBSERVACION---------------");
        
        console.log(
          this.selectContacto,
          this.selectEfecto,
          this.date,
          this.textTareaBoservaciones
        );*/

        //0. Convertir fecha para Firebase
        const fechaConvertida = await this.convertirFecha();
        //1. Enviar nuevo elemento a la base de datos
        const rest = await FBagregaObservacion({
          titularActual: this.titularActual,
          selectContacto: this.selectContacto,
          selectEfecto: this.selectEfecto,
          date: fechaConvertida,
          textTareaBoservaciones: this.textTareaBoservaciones,
          usuario: this.usuario,
        });

        console.log(rest);
        //2. Agregar elemento al array que existe, puesto que ya existe en la base de datos.
        if (rest) {
          //3. Vamor a unir el array
          const unionArray = await this.fusionArray();
          this.titularActual.observaciones = unionArray;

          this.panel = []; //Se cierra el panel
          //reiniciar variables
          this.textTareaBoservaciones = "";
          this.date = null;
          this.selectEfecto = [];
          this.selectContacto = [];
          this.mensajeGeneralCargador = "Registro exitoso";

          setTimeout(() => {
            this.isUpdating = false;
            this.activarSiguienteTitular = false; //Activa el botón de siguiente
          }, 3000); //Apagar alerta
        } else {
          //console.log("Se ha tenido problemas para agregar la observación");
          this.mensajeGeneralCargador =
            "Se ha tenido problemas para agregar la observación";

          setTimeout(() => {
            this.isUpdating = false;
          }, 3000); //Apagar alerta
        }
      } else {
        //console.log("Error, algún campo no está diligenciado.");
        this.mensajeGeneralCargador = "Verifique todos los campos.";

        setTimeout(() => {
          this.isUpdating = false;
        }, 3000); //Apagar alerta
        //this.isUpdating = true;
      } //END TIPO FORMULARIO
    },
    async fusionArray(a, b, c, d) {
      let newArray = [];
      if (b == "Acuerdo de pago") {
        newArray.push({
          contacto: a,
          efecto: b,
          fecha: c,
          observacion: d,
        });
        let unionArray = _.concat(this.titularActual.observaciones, newArray);
        return unionArray;
      } else {
        //Es una observacion normal.
        newArray.push({
          contacto: this.selectContacto,
          efecto: this.selectEfecto,
          fecha: this.date,
          observacion: this.textTareaBoservaciones,
        });
        let unionArray = _.concat(this.titularActual.observaciones, newArray);
        return unionArray;
      }
    },
    //PDF's Titulo Valor y Pagos
    async validaSubidaPDFS(archive, tipo) {
      //(file2, 'pagos') // (file1, 'tituloValor')
      //console.log(archive, tipo);
      this.isUpdating = true;

      if (archive == null || archive == undefined) {
        this.respuestaFile = "El archivo no se ha detectado, intente de nuevo.";
        this.isUpdating = false;
      } else {
        if (archive.type == "application/pdf") {
          //console.log("Es un PDF");
          const result = await FB_PDF_TituloValor_Titular(
            this.titularActual,
            archive,
            tipo,
            this.usuario
          );
          //console.log(result);
          if (result[0]) {
            //console.log("Se ha subido con éxito el documento");
            this.isUpdating = false;
            this.respuestaFile =
              "Se ha subido con éxito el documento (" +
              tipo +
              "), y se ha copiado la Ruta del archivo en el porta papeles.";

            this.copiaTexto(result[1]);
            //console.log(result[1]);
            this.file1 = null;
            this.file2 = null;
            this.file3 = null;
            this.file4 = null;
            this.file5 = null;
            this.file6 = null;
            this.file7 = null;
            this.file8 = null;
            this.file9 = null;
            this.file10 = null;
            this.file11 = null;

            if (tipo == "pazysalvoOld") {
              //Ocultar el campo para no volver a subir el pdf
              this.ocultaPazySalvoSubida = false;
            }
          } else {
            //console.log("No se ha subido el PDF - Titulo Valor");
            this.isUpdating = false;
            this.respuestaFile = "El archivo no se ha subido.";
            this.file1 = null;
            this.file2 = null;
            this.file3 = null;
            this.file4 = null;
            this.file5 = null;
            this.file6 = null;
            this.file7 = null;
            this.file8 = null;
            this.file9 = null;
            this.file10 = null;
            this.file11 = null;
          }
        } else {
          //console.log("No es un pdf");
          this.isUpdating = false;
          this.respuestaFile =
            "Problemas para subir su archivo, verifique formato y peso.";
          this.file1 = null; //Titulo Valor
          this.file2 = null; //Pagos...
          this.file3 = null; //Acuerdo Pago
          this.file4 = null; //Paz y Salvo v1
          this.file5 = null; //Paz y Salvo v2
          this.file6 = null; //Demanda
          this.file7 = null; //Acta de Reparto
          this.file8 = null; //Auto
          this.file9 = null; //Oficio
          this.file10 = null; //Terminacion de Proceso
          this.file11 = null; //Liquidación de titulos

          return false;
        }
      }
    },
    detectImagen(e) {
      //console.log(e);
      //console.log(this.file1);
      //this.imagen = e.target.files[0];
      //console.log(this.imagen);
    },
    ultimosElementosAray(elemento) {
      //let aNumeros = [1, 5, 6, 7, 8, 9, 10, 12];
      //console.log("UltimomElementoAray");
      //console.log(elemento.length);

      if (elemento != undefined) {
        let aNuevo = null;
        //1. Los ultimos 6 elementos del array
        aNuevo = elemento.slice(-20);
        //2. Se invierte el array para leer los ultimos cambios de primera.
        aNuevo = _.reverse(aNuevo);

        //console.log(aNuevo);
        return aNuevo;
      } else {
        var array = [];
        return array;
      }
    },
    async linksWhatsapp(a, adi1) {
      //https://wa.me/1XXXXXXXXXX
      //document.getElementById('google.com').setAttribute('href', baseUrl+'/id/'+id);
      switch (a) {
        case "acuerdo-de-pago":
          //console.log("acuerdo-de-pago");
          break;
        case "ver-pago":
          //console.log("Ver pago");
          if (adi1 != undefined || adi1 != null) {
            window.open(adi1, "_blank");
          }
          break;
        case "doc-pagare":
          //console.log("Solicitar pagaré");
          break;
        case "doc-pagare-solicitud":
          //console.log("doc-pagare-solicitud");
          break;
        case "whatsapp-personalizado":
          //console.log("whatsapp-personalizado");
          window.open(
            "https://wa.me/57" +
              adi1 +
              "?text=" +
              this.mensajePersonalizadoWhatsApp,
            "_blank"
          );
          break;
        case "whatsapp":
          //console.log("whatsapp");
          window.open("https://wa.me/57" + adi1, "_blank");
          break;
        case "mail":
          //console.log("mail titular");
          //https://mail.google.com/mail/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com
          /*window.open(
            "https://mail.google.com/mail/?view=cm&fs=1&to=" + adi1,
            "_blank"
          );*/

          window.open(
            "mailto:" +
              adi1 +
              "?subject=Notificación%20Cobranza&body=Buen%20día%20 - %20message%20 cuerpo",
            "_blank"
          );
          break;
        case "SMSTW":
          this.EnviandoMensaje = true;
          //console.log("SMS");
          const respuestaEnvio = await senMensaje(
            adi1,
            this.mensajePersonalizadoWhatsApp,
            this.indicativoSMS,
            this.titularActual
          );
          //console.log(respuestaEnvio);
          if (respuestaEnvio[0] == true) {
            this.mensajePersonalizadoWhatsApp =
              "Se ha enviado con éxito. Al número: " +
              respuestaEnvio[1][0].numero;
            //console.log(respuestaEnvio);
          } else {
            this.mensajePersonalizadoWhatsApp =
              "No se ha enviado " + respuestaEnvio[1][0].numero;
          }
          this.EnviandoMensaje = false;
          //console.log(respuestaEnvio);
          break;
        default:
          break;
      }
    },
    siguienteTitular() {
      //0. La base de datos debe ordenar todos los registros que salieron y priorizar por los que tienen algo para la fecha actual. Deacuerdo
      // al campo de observaciones.
      //1. Tomar el array que llegó con los 30 primeros titulares
      //2. Mostrar de manera aleatoria el primer titular
      //3. Luego del primer titular, eliminar el que llega y continuar con el siguiente, hasta llegar a 0
      //4. Si llega a 0 debe volver a cargar 30 titulares.
    },
    async convertirFechaAcuerdoPago(a) {
      //console.log(a);
      var dateArray = a.split("-");
      var year = dateArray[0];
      var month = parseInt(dateArray[1], 10) - 1;
      var date = dateArray[2];
      var _entryDate = new Date(year, month, date);

      return _entryDate;
    },
    async convertirFecha() {
      var dateArray = this.date.split("-");
      var year = dateArray[0];
      var month = parseInt(dateArray[1], 10) - 1;
      var date = dateArray[2];
      var _entryDate = new Date(year, month, date);

      return _entryDate;
    },
    async compiladoFechas(a) {
      let newJuegoDeFechas = [];

      a.forEach(async (element) => {
        //0. Convertir fecha para Firebase
        const fechaConvertida = await this.convertirFechaAcuerdoPago(element);
        newJuegoDeFechas.push(fechaConvertida);
      });

      return newJuegoDeFechas;
    },
    timeTodate(a, b) {
      //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.
      //console.log(typeof a);
      //console.log(b);
      //console.log(a);
      //console.log("-----------------------------------------");

      if (typeof a == "object" && a != null) {
        //console.log(a);
        let time = {
          seconds: a.seconds,
          nanoseconds: a.nanoseconds,
        };

        /*const fireBaseTime = new Date(
          time.seconds * 1000 + time.nanoseconds / 1000000
        );
        const date = fireBaseTime.toDateString();
        const atTime = fireBaseTime.toLocaleTimeString();
        //console.log("Es un Timespam");
        //console.log(date, atTime);
        const resultado = date + " / " + atTime;*/
        const fireBaseTime = new Date(time.seconds * 1000);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        //var convertida = fireBaseTime.toDateString();

        return fireBaseTime.toLocaleDateString("es-ES", options);
      } else if (typeof a == "string" && b == "pagos") {
        //this.fechaComporobantePago
        //pagos-431800506-063779341166.378000000.pdf
        let array1 = _.split(a, "-");
        //console.log("Fichas -------------------------------");
        //console.log(array1);
        const fireBaseTime = new Date(array1[0] * 1000 + array1[1] / 1000000);
        const date = fireBaseTime.toDateString();
        const atTime = fireBaseTime.toLocaleTimeString();
        //console.log("Es un Timespam");
        //console.log(date, atTime);
        const resultado = date + " / " + atTime;

        return resultado;
      } else {
        return a;
      }
    },
    arrayTelefonos(a) {
      //console.log(a);
      var array = [];
      if (a != undefined) {
        //1. Convertir en array.
        array = String(a).split(",");
        //console.log(array);

        return array;
      } else {
        return array;
      }
    },
    semaforoObservaciones(efecto) {
      //console.log(efecto);
      //1. El objetivo es generar un semáforo de efectos.
      var colores = "#" + ((Math.random() * 0xffffff) << 0).toString(16);

      return colores;
    },
    cambioitemsContacto(a) {
      //console.log(a);
      //console.log(this.usuarioRol);
      if (this.usuarioRol == "acm") {
        switch (a) {
          case "Tercero":
            this.itemsEfecto = [
              "Mensaje Empresa",
              "Mensaje con Tercero",
              "Tercero no Recibe Mensaje",
            ];
            break;
          case "Deudor":
            this.itemsEfecto = [
              //"Acuerdo de pago",
              "Cliente se acerca a oficina",
              "Verificar Pago",
              "Posible Incobrable",
              "Modificar Datos",
              "Novedad",
              "Posible Negociación",
              "Reclamación",
              "Recordatorio de Pago",
              "Renuente",
              "Solicitud Titulo Valor",
              "Solicitud Descuento",
              "Posible Demanda",
              "Paz y Salvo - Notificado",
              //"Iniciar Demanda",
              "Demanda - Subsanada",
              "Demandado - Notificado Acta Reparto",
              "Demandado - Notificado Auto",
              "Demandado - Notificado Oficio",
              //"Demandado - Novedad Interna",
              "Demandado - Novedad Dep. Juridico",
            ];
            break;
          case "N/A":
            this.itemsEfecto = [
              "Correo",
              "Posible Ilocalizado",
              "No contesta / Ocupado",
              "Dañado, Fuera de servicio",
              "Buzón",
              "Posible Fallecido",
            ];
            break;

          default:
            break;
        }
      } else if (this.usuarioRol == "dcm") {
        switch (a) {
          case "Tercero":
            this.itemsEfecto = [
              "Mensaje Empresa",
              "Mensaje con Tercero",
              "Tercero no Recibe Mensaje",
            ];
            break;
          case "Deudor":
            this.itemsEfecto = [
              //"Acuerdo de pago",
              "Cliente se acerca a oficina",
              "Verificar Pago",
              "Posible Incobrable",
              "Modificar Datos",
              "Novedad",
              "Posible Negociación",
              "Reclamación",
              "Recordatorio de Pago",
              "Renuente",
              "Solicitud Titulo Valor",
              "Solicitud Descuento Gerencia",
              "Iniciar Demanda",
              "Confirmar Pago",
              "Pago Realizado",
              "Paz y Salvo - Notificado",
              //"Pago Rechazado",
              "Pago Rechazado DCM",
              "Incobrable",
              "Sin Titulo Valor",
              "Titulo Valor Cargado",
              "Descuento Autorizado",
              "Descuento Rechazado",
              "Modifica Datos Rechazado",
              "Posible Demanda",
              "Rechazo Posible Demanda",
              "Iniciar Demanda",
              "Demanda - Subsanada",
              "Demandado - Notificado Acta Reparto",
              "Demandado - Notificado Auto",
              "Demandado - Notificado Oficio",
              "Demandado - Novedad Dep. Juridico",
            ];
            break;
          case "N/A":
            this.itemsEfecto = [
              "Correo",
              "Ilocalizado",
              "No contesta / Ocupado",
              "Dañado, Fuera de servicio",
              "Buzón",
              "Fallecido",
            ];
            break;
          default:
            break;
        }
      } else if (this.usuarioRol == "g") {
        switch (a) {
          case "Tercero":
            this.itemsEfecto = [
              "Mensaje Empresa",
              "Mensaje con Tercero",
              "Tercero no Recibe Mensaje",
            ];
            break;
          case "Deudor":
            this.itemsEfecto = [
              //"Acuerdo de pago",
              "Cliente se acerca a oficina",
              "Verificar Pago",
              "Posible Incobrable",
              "Modificar Datos",
              "Novedad",
              "Posible Negociación",
              "Reclamación",
              "Recordatorio de Pago",
              "Renuente",
              "Solicitud Titulo Valor",
              "Solicitud Descuento Gerencia",
              "Iniciar Demanda",
              "Confirmar Pago",
              "Pago Realizado",
              "Pago Rechazado",
              "Novedad Gerencia",
              "Incobrable",
              "Sin Titulo Valor",
              "Titulo Valor Cargado",
              "Descuento Autorizado",
              "Descuento Rechazado",
              "Modifica Datos Rechazado",
              "Paz y Salvo",
              "Posible Demanda",
              "Iniciar Demanda",
              "Demanda - Subsanada",
              "Demandado - Notificado Acta Reparto",
              "Demandado - Notificado Auto",
              "Demandado - Notificado Oficio",
              "Demandado - Novedad Dep. Juridico",
            ];
            break;
          case "N/A":
            this.itemsEfecto = [
              "Correo",
              "Ilocalizado",
              "No contesta / Ocupado",
              "Dañado, Fuera de servicio",
              "Buzón",
              "Fallecido",
            ];
            break;
          default:
            break;
        }
      } else if (this.usuarioRol == "cliente") {
      } else if (this.usuarioRol == "adm") {
        switch (a) {
          case "Tercero":
            /*this.itemsEfecto = [
              "Mensaje Empresa",
              "Mensaje con Tercero",
              "Tercero no Recibe Mensaje",
            ];*/
            break;
          case "Deudor":
            this.itemsEfecto = [
              //"Acuerdo de pago",
              //"Cliente se acerca a oficina",
              //"Verificar Pago",
              //"Posible Incobrable",
              //"Modificar Datos",
              //"Novedad",
              //"Posible Negociación",
              //"Reclamación",
              "Recordatorio de Pago",
              //"Renuente",
              //"Solicitad Titulo Valor",
              //"Solicitud Descuento Gerencia",
              //"Iniciar Demanda",
              //"Confirmar Pago",
              //"Pago Realizado",
              //"Pago Rechazado",
              "Pago Rechazado ADM",
              "Pago Pendiente",
              "Novedad ADM",
              "Paz y Salvo",
              /*"Incobrable",
              "Sin Titulo Valor",
              "Descuento Autorizado",
              "Descuento Rechazado",
              "Modifica Datos Rechazado",
              "Demandado - Notificado Acta Reparto",
              "Demandado - Notificado Auto",
              "Demandado - Notificado Oficio",
              "Demandado - Novedad Dep. Juridico",*/
            ];
            break;
          case "N/A":
            /*this.itemsEfecto = [
              "Correo",
              "Ilocalizado",
              "No contesta / Ocupado",
              "Dañado, Fuera de servicio",
              "Buzón",
              "Fallecido",
            ];*/
            break;
          default:
            break;
        }
      } else if (this.usuarioRol == "j") {
        switch (a) {
          case "Tercero":
            this.itemsEfecto = [
              "Mensaje Empresa",
              "Mensaje con Tercero",
              "Tercero no Recibe Mensaje",
            ];
            break;
          case "Deudor":
            this.itemsEfecto = [
              //"Acuerdo de pago",
              //"Cliente se acerca a oficina",
              //"Verificar Pago",
              //"Posible Incobrable",
              //"Modificar Datos",
              //"Novedad",
              //"Posible Negociación",
              //"Reclamación",
              //"Recordatorio de Pago",
              //"Renuente",
              //"Solicitad Titulo Valor",
              //"Solicitud Descuento Gerencia",
              //"Iniciar Demanda",
              //"Confirmar Pago",
              //"Pago Realizado",
              //"Incobrable",
              //"Sin Titulo Valor",
              //"Descuento Autorizado",
              //"Descuento Rechazado",
              //"Modifica Datos Rechazado",
              "Demanda Radicada",
              "Volver a Demandar",
              "Demanda - Subsanar",
              "Demandado - Notificar Acta Reparto",
              "Demandado - Notificar Auto",
              "Demandado - Notificar Oficio",
              //"Demandado - Novedad Interna",
              "Demandado - Novedad Ejecutivo",
              "Demandado - Terminación Proceso",
              "Demandado - Liquidación de Titulos",
              "Paz y Salvo v2",
            ];
            break;
          case "N/A":
            this.itemsEfecto = [
              "Correo",
              "Ilocalizado",
              "No contesta / Ocupado",
              "Dañado, Fuera de servicio",
              "Buzón",
              "Fallecido",
            ];
            break;
          default:
            break;
        }
      } else if (this.usuarioRol == "s") {
      } else {
        return;
      }
    },
    interesMasMonto(a) {
      this.totalAjustado = a;
      //this.totalAjustado = (a * 20) / 100 + a;
      //return this.totalAjustado = (a * 20) / 100 + a;
    }, //Slider
    calculandoDescuento() {
      //console.log("Calculando descuento...");
      this.newInteresesDescuento =
        (this.totalInteresesJS * this.descuentoSobreIntereses) / 100;
    },
    cambioBooleam(a) {
      switch (a) {
        case true:
          return "Si";
          break;
        case false:
          return "No";
          break;

        default:
          break;
      }
    },
    fechaHoy() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;

      //console.log("Hoy", today);
      return today;
    },
    decimales(a) {
      //console.log(a);
      const decimalesLlega = JSdecimales(a);

      return decimalesLlega;
    },
    interesesFinales(a, b) {
      const resultado = JSLiquidador(a, b);

      //console.log("Desde Intereses finales");
      //console.log(resultado);
      this.detalleLiquidador = resultado[0];
      this.totalInteresesJS = resultado[1];
      this.totalDiasJS = resultado[5];
      this.semanasJS = resultado[4];
      //this.interesesDescuento = resultado[1];

      return this.decimales(resultado[1]);
    },
    generaAcuerdoDePagoPDF() {
      //https://mrrio.github.io/jsPDF/examples/basic.html
      //https://artskydj.github.io/jsPDF/docs/module-html.html
      //console.log("Genera PDF");
    },
    async nombreCliente() {
      //console.log(this.titularActual.nitCliente);
      const resultadoDatosEmpresa = await FBTodosLosDatosCliente(
        this.titularActual.nitCliente
      );
      //console.log("Llega Titular");
      this.nombreClienteFinal = resultadoDatosEmpresa[0].data.data.cliente;
      //return resultadoDatosEmpresa[0].data.data.cliente;
    },
    iconsDinamic() {
      const icon = [
        "🧠",
        "👄",
        "👦",
        "👶",
        "👴",
        "👧",
        "👨‍🍼",
        "👨‍🍳",
        "👩‍🍼",
        "👮‍♂️",
        "👮‍♀️",
        "🧑‍⚖️",
        "🦸‍♂️",
        "🦸‍♀️",
        "🧜‍♂️",
        "🧞",
        "🏃",
        "💇",
        "🚣‍♂️",
        "🛀",
        "👣",
        "🐇",
        "🐭",
        "🐻",
        "🐉",
        "🌷",
        "🥝",
        "🍟",
        "🍣",
        "🦀",
        "🚢",
        "🛸",
        "🧸",
        "💍",
        "🏹",
        "🤑",
        "😍",
        "🤐",
      ];

      const resultIcon = _.sample(icon);

      return resultIcon;
    },
    async buscaEjecutivoFB() {
      const ejecutivoLlega = await FBLocalizarEjecutivo();
      //this.empleados.push(ejecutivoLlega.data.empleado);
      //console.log(ejecutivoLlega);
      this.empleados = ejecutivoLlega;
    },
    ubicandoEjecutivo(a) {
      //console.log("Llega: ", a);
      //console.log(this.empleados.length);

      var filtro = _.filter(this.empleados, (nombres) => {
        //console.log(nombres);
        return nombres.id === a;
      });

      //console.log(filtro[0].info.data);
      if (filtro[0] != undefined) {
        return filtro[0].info.data.empleado;
      } else {
        return a;
      }
    },
  },
  computed: {
    retornaEstadoMensaje() {
      //return (this.sheet = this.estadoMensaje);
    },
  },
  watch: {
    rating(val) {
      //console.log(val);
      if (!val) return;
      setTimeout(() => this.puntajeEjecutivoTitular(val), 1000);
    },
    respuestaFile(val) {
      //console.log("cambio");
      if (!val) return;
      setTimeout(() => (this.respuestaFile = "..."), 8000);
    },
    datosTitular() {
      this.onlyTitular();
    },
  },
};
</script>