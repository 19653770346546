import { db, firebase } from "@/firebase/config.js";

export async function FBaccionesWhatsapp(jsonLlega) {
    try {
        //console.log(jsonLlega);

        if (jsonLlega.accion === "delete") {
            // Obtén una referencia al documento que deseas eliminar
            const docRef = firebase.firestore().doc(`cf-whatsapp/${jsonLlega.numero}`);

            // Utiliza el método delete() para eliminar el documento
            await docRef.delete();
            //console.log('Documento eliminado con éxito');
            return true;
        }

        if (jsonLlega.accion === "editar") {
            // Obtén una referencia al documento
            const docRef = firebase.firestore().doc(`cf-whatsapp/${jsonLlega.numero}`);

            // Utiliza el método get() para obtener los datos del documento
            const docSnapshot = await docRef.get();

            if (docSnapshot.exists) {
                // El documento ya existe, realiza la actualización
                await docRef.update({
                    [jsonLlega.clave]: jsonLlega.valor,
                });
                return true;
            } else {
                // El documento no existe, créalo con el campo
                await docRef.set({
                    [jsonLlega.clave]: jsonLlega.valor,
                });
                return true;
            }
        }


        if (jsonLlega.accion === "visto") {
            // Obtén una referencia al documento
            const docRef = firebase.firestore().doc(`cf-whatsapp/${jsonLlega.numero}`);

            // Utiliza el método get() para obtener los datos del documento
            const docSnapshot = await docRef.get();

            if (docSnapshot.exists) {
                // El documento ya existe, realiza la actualización
                await docRef.update({
                    "mensajePendiente": false,
                });
                return true;
            } else {
                // El documento no existe, créalo con el campo
                // NO se hace nada... no existe el registro
                return true;
            }
        }


    } catch (error) {
        console.log(error);
    }
}