import { fechaseInteres } from './liquidador/fechas';

//antes validateObservationFields
//ahora, lo más seguro es que GPT cmabió el nombre
export function JSvalidaCamposObservaciones(data) {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            if (data[key] === undefined || data[key] === null || data[key] === '' || data[key] === 0) {
                return false;
            }
        }
    }

    return true;
}

export function JSdecimales(a) {
    try {
        var pesosCOP = new Intl.NumberFormat("es-CO", { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(a);
        return pesosCOP;
    } catch (error) {
        console.log(error);
    }
}

function monthDiff(d1, d2) {
    var months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

function daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
}

export function JSLiquidador(montoLlega, fechaLlega) {
    try {
        if (montoLlega !== undefined && fechaLlega !== undefined) {
            const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
            const fechasIntereses = fechaseInteres();
            const monto = montoLlega;
            let fechaUltimoPago = new Date(fechaLlega);

            if (fechaLlega.includes('/')) {
                const [day, month, year] = fechaLlega.split('/');
                fechaUltimoPago = new Date(+year, +month - 1, +day);
            }

            const diaFechaLlega = new Date(fechaUltimoPago.toISOString().split('T')[0]);
            const dateBase = new Date();
            const fechaHoy = dateBase.getFullYear() + '-' + (dateBase.getMonth() + 1) + '-' + dateBase.getDate();
            const formatoHoy = new Date(fechaHoy);
            const montoAcumulado = [];
            const fechaLlegaanoMes = diaFechaLlega.getFullYear() + '-' + (diaFechaLlega.getMonth() + 1);
            const totalMeses = monthDiff(diaFechaLlega, formatoHoy);
            const totalDiasFinal = Math.floor((formatoHoy - fechaUltimoPago) / (1000 * 60 * 60 * 24));

            while (formatoHoy.getTime() >= diaFechaLlega.getTime()) {
                diaFechaLlega.setDate(diaFechaLlega.getDate() + 1);
                let recorridoFecha = diaFechaLlega.getFullYear() + '-' + (diaFechaLlega.getMonth() + 1) + '-' + diaFechaLlega.getDate();
                let totalAcumulado = 0;

                fechasIntereses.forEach(element => {
                    let fechaComodin = new Date(element.fecha).getFullYear() + '-' + (new Date(element.fecha).getMonth() + 1);

                    if (diaFechaLlega.getDate() > 1 && montoAcumulado.length === 0 && fechaComodin === fechaLlegaanoMes) {
                        const totalDias = daysInMonth(diaFechaLlega.getMonth(), diaFechaLlega.getFullYear());
                        const valorDiaInteres = (monto * element.interes) / 360;
                        const diasMoraMes = Math.ceil(totalDias * valorDiaInteres);

                        montoAcumulado.push({
                            mes: recorridoFecha,
                            tasa: element.interes,
                            valorDia: valorDiaInteres,
                            totalDiasMes: totalDias,
                            diasValidos: totalDias,
                            totalMes: diasMoraMes,
                        });
                    }

                    if (recorridoFecha === element.fecha) {
                        const totalDias = daysInMonth(diaFechaLlega.getMonth(), diaFechaLlega.getFullYear());
                        const valorDiaInteres = (monto * element.interes) / 360;
                        const diasMoraMes = Math.ceil(totalDias * valorDiaInteres);
                        totalAcumulado += diasMoraMes;

                        montoAcumulado.push({
                            mes: recorridoFecha,
                            tasa: element.interes,
                            valorDia: valorDiaInteres,
                            totalDiasMes: totalDias,
                            diasValidos: totalDias,
                            totalMes: diasMoraMes,
                        });
                    }
                });
            }

            let totalAcumulado2 = 0;
            let totalDiasAcumulados = 0;

            for (let index = 0; index < montoAcumulado.length; index++) {
                const element = montoAcumulado[index];

                if (index === 0) {
                    var valorDia = montoAcumulado[index].valorDia;
                    var totalDias = montoAcumulado[index].diasValidos - fechaUltimoPago.getDate();
                    var totalNuevo = Math.ceil(valorDia * totalDias);

                    montoAcumulado[index].totalDiasMes = totalDias;
                    montoAcumulado[index].totalMes = totalNuevo;
                }

                if ((montoAcumulado.length - 1) === index) {
                    var valorDia = montoAcumulado[index].valorDia;
                    var totalDias = formatoHoy.getDate();
                    var totalNuevo = Math.ceil(valorDia * totalDias);

                    montoAcumulado[index].totalDiasMes = totalDias;
                    montoAcumulado[index].totalMes = totalNuevo;
                }
            }

            for (let x of montoAcumulado) {
                totalAcumulado2 += x.totalMes;
                totalDiasAcumulados += x.totalDiasMes;
            }

            const diasTotales = totalDiasFinal + totalDiasAcumulados;
            const semanasTotales = Math.floor(diasTotales / 7);
            var totalFinal = totalAcumulado2 + monto;


            //// INICIO ------- Versión para mejorar el calculo de dias y semanas en mora
            if (montoLlega !== undefined && fechaLlega !== undefined) {
                const monto = montoLlega;
                let fechaUltimoPago = new Date(fechaLlega);

                if (fechaLlega.includes('/')) {
                    const [day, month, year] = fechaLlega.split('/');
                    fechaUltimoPago = new Date(+year, +month - 1, +day);
                }

                const fechaHoy = new Date();
                const fechaActual = new Date(fechaHoy.getFullYear(), fechaHoy.getMonth(), fechaHoy.getDate());
                const fechaUltimoPagoSinHora = new Date(fechaUltimoPago.getFullYear(), fechaUltimoPago.getMonth(), fechaUltimoPago.getDate());
                const totalDias = Math.floor((fechaActual - fechaUltimoPagoSinHora) / (1000 * 60 * 60 * 24));
                const totalSemanas = Math.floor(totalDias / 7);

                //return [totalDias, totalSemanas];
                //console.log(montoAcumulado, totalAcumulado2, totalFinal, totalMeses, totalSemanas, totalDias, diasTotales, semanasTotales);
                return [montoAcumulado, totalAcumulado2, totalFinal, totalMeses, totalSemanas, totalDias];
            }
            //// END ------- Versión para mejorar el calculo de dias y semanas en mora

            //return [montoAcumulado, totalAcumulado2, totalFinal, totalMeses, semanasTotales, diasTotales];
        }
    } catch (error) {
        console.log('Error: ', error);
    }
}

