import { db } from "@/firebase/config.js";

export async function FBconsultarProcesos(categoria) {
  try {
    const estado = categoria;
    const collectionPath = "cf-mercadeo";
    const etapasDocument = "tareasExternas";
    const etapa = "listado";

    let docRef = db.collection(collectionPath).doc(etapasDocument).collection(etapa);

    if (estado !== "todas") {
      docRef = docRef.where("estado", "==", estado);
    }

    const querySnapshot = await docRef.get();

    const array = querySnapshot.docs
      .filter((docSnapshot) => !docSnapshot.exists || docSnapshot.data().a !== "no borrar")
      .map((docSnapshot) => Object.assign({ idConsulta: docSnapshot.id }, docSnapshot.data()));

    return [true, array, null];
  } catch (error) {
    console.error("Error al consultar las etapas de prospectos:", error);
    return [false, [], null];
  }
}















