import { db, firebase } from "@/firebase/config.js";

export async function FBeliminaOportunidad(jsonLLega) {
    try {
        console.log(jsonLLega);
        //const nuevoId = jsonLLega.id.split("-")[0];

        const collectionPath = `cf-ventas/etapas/${jsonLLega.etapa}`;
        const querySnapshot = await db
            .collection(collectionPath)
            .where("seguimientoId", "==", jsonLLega.id)
            .get();

        if (!querySnapshot.empty) {
            const docRef = querySnapshot.docs[0].ref;
            await docRef.delete();
            //console.log("Registro eliminado con éxito");
            return true;
        } else {
            //console.log("No se encontró el documento");
            return false;
        }

    } catch (error) {
        console.log(error);
        return false;
    }
}