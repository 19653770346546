<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="650px">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="mensajeCliente mt-5"
          v-bind="attrs"
          v-on="on"
          v-html="textoFaltante.mensajeCliente"
        >
         
        </div>
      </template>

      <v-card dark>
        <v-card-title class="text-h5 amber"> Contenido Adicional </v-card-title>

        <v-card-text class="mt-8">
          <div v-html="textoFaltante.mensajeCliente"></div>

          <v-divider color="orange" class="mt-3"></v-divider>

          <p class="pa-3">
            Se envió al correo: {{ textoFaltante.correoSelect }}
            <br />
            Fecha registro:
            {{ fechaConvertirFirebase(textoFaltante.fechaRegistro) }}
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="orange" @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["textoFaltante"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    fechaConvertirFirebase(timestamp) {
      // Convierte el objeto Timestamp a una fecha JavaScript
      const fecha = new Date(timestamp.seconds * 1000); // Multiplica por 1000 para convertir segundos a milisegundos

      // Obtén la fecha y hora en un formato legible
      const fechaHoraLegible = fecha.toLocaleString(); // Utiliza el método toLocaleString() para obtener la fecha y hora en el formato local del usuario

      //console.log(fechaHoraLegible);
      return fechaHoraLegible;
    },

    textoFormateadoFuncion(llegaTexto) {
      console.log(llegaTexto);
      let textoConFormato = llegaTexto;

      // Reemplazar los saltos de línea con etiquetas <br>
      textoConFormato = textoConFormato.replace(/\n/g, "<br>");

      // Agregar negrita a ciertas partes del texto
      textoConFormato = textoConFormato.replace(
        /(\*\*|__)(.*?)\1/g,
        "<strong>$2</strong>"
      );

      // Devolver el texto formateado
      return textoConFormato;
    },
  },
};
</script>

<style>
.mensajeCliente {
  border-bottom: 1px solid #00fefa;
  padding: 10px;
  font-size: 14px;
  color: #474747;
  margin-top: 0;
  padding: 10px;
  border-radius: 15px;
  background-color: #b7fffe;
  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  max-height: 50px; /* Sin comillas alrededor del valor numérico */
  text-align: justify; /* Ajusta el texto al centro */
}

.mensajeClienteB {
  max-width: 300px; /* Ajusta el ancho máximo según tus preferencias */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
</style>