<template>
  <div class="text-center">
    <v-card scrollable max-width="100%" max-height="80%" dark>
      <v-card-title class="green">
        WhatsApp
        <v-badge v-if="alertaGeneral" color="red" dot></v-badge>

        <v-divider class="mx-4" inset vertical></v-divider>
        <div class="caption">General</div>

        <v-spacer></v-spacer>

        <div v-if="celular !== null && celular !== ''">
          <v-row>
            <v-col>
              <v-tooltip color="green" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    @click="copiarTexto(celular)"
                    color="green darken-4"
                    >{{ empresa !== undefined ? empresa + " - " : "N/A - " }}
                    {{ celular }}
                  </v-chip>
                </template>
                <span>Copiar Movil</span>
              </v-tooltip>
            </v-col>
            <v-col>
              <v-tooltip color="green" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    @click="acciones('visto')"
                    color="green darken-4"
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-chip>
                </template>
                <span> Marcar como Visto</span>
              </v-tooltip>
            </v-col>
            <v-col>
              <Editar
                :usuarioLlega="usuarioW"
                :telefonoLlega="celular"
                :empresaLlega="empresa"
                :seguimientoLlega="seguimiento"
                @enviandoCambio="datosActualizar"
              />
            </v-col>
            <v-col>
              <CrearOportunidadPNatural />
            </v-col>
            <v-col>
              <v-tooltip color="red" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="acciones('delete')"
                    class="pa-2"
                    color="red"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar Chat</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>

        <v-divider class="ml-5" vertical></v-divider>

        <div class="ml-5">
          <v-row>
            <v-col>
              <NuevoUsuario
                :NombreEmpleado="rolPrueba"
                @cerrarTienda="listenToMessages('ok')"
                @click="listenToMessages"
              />
            </v-col>
            <v-col>
              <ConfiguracionWhatsapp @estadoWhatsappFuncion="estadoW" />
            </v-col>
          </v-row>
        </div>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row>
          <!-- LISTADO DE NUMEROS -->
          <v-col cols="4">
            <v-card
              v-if="switchTiempoReal === true"
              scrollable
              max-width="500px"
              elevation="0"
            >
              <div class="message-scroll">
                <v-list>
                  <v-list-item-group v-model="selectedItem" color="orange">
                    <v-list-item v-for="(item, index) in items" :key="index">
                      <!--
                        <v-list-item-avatar>
                          <v-avatar color="grey darken-3" size="24">
                            <span class="white--text"></span>
                          </v-avatar>
                        </v-list-item-avatar>
                      -->

                      <v-list-item-content>
                        <v-list-item-title class="text-left">
                          <v-icon
                            v-if="item.contenido.seguimiento"
                            text
                            icon
                            x-small
                            color="red"
                          >
                            mdi-alert
                          </v-icon>
                          {{
                            item.contenido.usuario !== undefined &&
                            item.contenido.usuario !== null
                              ? item.contenido.usuario
                              : usuarioW
                          }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="overline">
                          <v-chip
                            class="ma-2"
                            :color="
                              colorMensaje(item.contenido.mensajePendiente)
                            "
                            x-small
                          >
                            {{ item.numero }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-card>
          </v-col>

          <v-divider vertical></v-divider>

          <!--  LISTADO CLIENTES-->
          <v-col cols="8">
            <v-card elevation="0" class="mt-5">
              <div class="message-scroll" ref="scrollContainer">
                <!--
                <div>
                  Por favor seleccionar un número del contacto para poder
                  consultar los mensajes.
                </div>
               -->

                <v-list-item
                  v-for="(item, index) in contenidoSelectItem.messages"
                  :key="index"
                >
                  <v-list-item-content>
                    <!-- Para cuando es un BUTTON -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-if="item.body.type === 'button'"
                    >
                      <div
                        class="secondary text-no-wrap rounded-pill d-inline pa-1 deep-purple accent-4 white--text"
                      >
                        {{ item.body.button.text }}
                      </div>
                    </v-list-item-title>

                    <!-- Para cuando es un STICKER -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'sticker'"
                    >
                      <img
                        class="rounded-xxl"
                        :src="item.urlFinal"
                        contain
                        lazy-src="https://picsum.photos/id/11/10/6"
                        :aspect-ratio="16 / 9"
                        height="150"
                      />
                    </v-list-item-title>

                    <!-- Para cuando es un IMAGEN -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'image'"
                    >
                      <button>
                        <img
                          class="rounded-xxl elevation-5 row-pointer"
                          :src="item.urlFinal"
                          contain
                          lazy-src="https://picsum.photos/id/11/10/6"
                          :aspect-ratio="16 / 9"
                          height="150"
                          @click="abrirEnlace(item.urlFinal)"
                        />
                      </button>

                      <div v-if="item.body.image?.caption">
                        <br />
                        <p>{{ item.body.image?.caption }}</p>
                      </div>
                    </v-list-item-title>

                    <!-- Para cuando es un REACTION -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'reaction'"
                    >
                      {{ item.body.reaction.emoji }}
                    </v-list-item-title>

                    <!-- Para cuando es un VIDEO -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'video'"
                    >
                      <button @click="abrirEnlace(item.urlFinal)">
                        <div
                          class="secondary rounded-pill d-inline pa-1 mt-3s deep-purple accent-4 white--text"
                        >
                          <v-icon>mdi-movie</v-icon>
                          Video - Multimedia
                        </div>
                      </button>

                      <div v-if="item.body.video?.caption">
                        <br />
                        <p>{{ item.body.video?.caption }}</p>
                      </div>
                    </v-list-item-title>

                    <!-- Para cuando es un AUDIO -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else-if="item.body.type === 'audio'"
                    >
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        @click="playSound(item.urlFinal)"
                      >
                        <v-icon dark> mdi-play </v-icon>
                      </v-btn>

                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="green"
                        @click="stopSound()"
                      >
                        <v-icon dark> mdi-stop </v-icon>
                      </v-btn>
                    </v-list-item-title>

                    <!-- CUando no se identifica el INdefinido o Text -->
                    <v-list-item-title
                      :class="[colorChat(item.type), 'text-left', 'text-wrap']"
                      v-else
                    >
                      {{ item.body.text?.body || item.body }}
                    </v-list-item-title>
                    
                    <!-- Copia texto de cualquier mensaje que contenga -->
                    <v-list-item-title class="text-right caption mt-2">
                      <v-btn
                        :disabled="
                          item.body.type === 'audio' ||
                          item.body.type === 'sticker' ||
                          item.body.type === 'button'
                        "
                        icon
                        color="amber"
                        @click="
                          copiarTexto(
                            item.body.text?.body ||
                              item.body.image?.caption ||
                              item.body.video?.caption
                          )
                        "
                        x-small
                        class="mr-3"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>

                      <v-chip
                        v-if="item.type !== undefined"
                        x-small
                        color="orange"
                        class="mr-3"
                        outlined
                      >
                        {{ item.type }}
                      </v-chip>

                      <v-chip small color="green" outlined>
                        {{
                          item.usuario !== undefined && item.usuario !== null
                            ? item.usuario
                            : usuarioW
                        }}
                        -
                        {{ fechaConvertirFirebase(item.timestamp) }}
                      </v-chip>
                    </v-list-item-title>

                    <v-divider color="green" class="mt-2"></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <!-- SECCIÓN ENVIAR MENSAJE -->
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="8">
            <!-- SECCIÓN DE CHAT-->
            <v-row v-if="celular !== null && celular !== ''">
              <v-col cols="11">
                <v-textarea
                  v-model="mensaje"
                  label="Escribe..."
                  rows="2"
                  row-height="25"
                  color="green"
                  auto-grow
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="1" class="d-flex align-center justify-center">
                <v-btn @click="conversar" icon color="green">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-switch
          :disabled="!estadoWhatsapp"
          v-model="switchTiempoReal"
          :label="
            switchTiempoReal
              ? 'Abierto - Atención Whatsapp'
              : 'Cerrado - Atención Whatsapp'
          "
          color="green"
          @change="listenToMessages"
        ></v-switch>
        <v-spacer></v-spacer>
        <!-- 
        <v-btn
          v-show="switchTiempoReal"
          color="green"
          text
          @click="stopListening"
        >
          Cerrar Servicio...
        </v-btn>
        -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import { db } from "@/firebase/config.js";
import { FBenviarMensajeWhatsapp } from "@/components/Ventas/Tableros/whatsapp/conversarGeneral.js";
import { FBaccionesWhatsapp } from "@/components/Ventas/Tableros/whatsapp/acciones.js";
import Editar from "./wappEditar.vue";
import VermillionAudio from "@/assets/notificaciones/Vermillion.mp3";
import ConfiguracionWhatsapp from "./configuracion.vue";
import NuevoUsuario from "./nuevoUsuario.vue";
import CrearOportunidadPNatural from "../../Prospectos/agregaOportunidaNatural.vue";

new Audio(VermillionAudio);
const audio = new Audio(VermillionAudio);

export default {
  props: ["datos", "correo"],
  data() {
    return {
      rolPrueba: store.state.user[2][0].data.empleado,
      dialog: false,
      celular: null,
      switchTiempoReal: false,
      items: [],
      mensaje: "",
      unsubscribe: null, // Variable reactiva para almacenar la función de desactivación
      usuarioW: null,
      numeros: [],
      selectedItem: null,
      contenidoSelectItem: "",
      alertaGeneral: false,
      empresa: "",
      seguimiento: false,
      estadoWhatsapp: false,
      audio: null,
    };
  },

  components: {
    Editar,
    ConfiguracionWhatsapp,
    NuevoUsuario,
    CrearOportunidadPNatural,
  },

  mounted() {
    //Va al final de DIV de chat
    this.scrollToBottom();
  },

  updated() {
    //Va al final de DIV de chat
    this.scrollToBottom();
  },

  methods: {
    abrirEnlace(enlace) {
      window.open(enlace, "_blank");
    },

    //Whatsapp
    playSound(sound) {
      if (sound) {
        this.audio = new Audio(sound);

        this.audio.addEventListener("loadedmetadata", () => {
          const duration = this.audio.duration; // Duración en segundos
          //console.log(`Duración del audio: ${duration} segundos`);
          const minutes = Math.floor(duration / 60);
          this.audio.play();
          //console.log(minutes);
        });

        this.audio.addEventListener("ended", () => {
          // El audio ha terminado de reproducirse
        });
      }
    },

    //Whatsapp
    stopSound() {
      if (this.audio) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
    },

    //Va al final de DIV de chat
    scrollToBottom() {
      const scrollContainer = this.$refs.scrollContainer;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    },

    async datosActualizar(llega, clave) {
      //console.log(llega, clave);

      const json = {
        numero: this.celular,
        accion: "editar",
        clave: clave,
        valor: llega,
      };

      await FBaccionesWhatsapp(json);
      //const respuesta =
      //console.log(respuesta);
    },

    async acciones(accion) {
      //console.log(accion);
      //console.log(this.contenidoSelectItem);

      const json = {
        numero: this.celular,
        accion: accion,
      };

      await FBaccionesWhatsapp(json);
      //const respuesta =
      //console.log(respuesta);
    },

    mostrarMensajes() {
      if (this.selectedItem !== null) {
        this.contenidoSelectItem = this.items[this.selectedItem].contenido;
        console.log(this.contenidoSelectItem);
      }
    },

    fechaConvertirFirebase(timestamp) {
      if (!timestamp || !timestamp.seconds) {
        return "Fecha inválida";
      }

      const fecha = new Date(timestamp.seconds * 1000);
      const fechaHoraLegible = fecha.toLocaleString();

      return fechaHoraLegible;
    },

    async conversar() {
      const jsonSale = {
        telefonoSelect: this.celular,
        contacto: this.usuarioW,
        doliente: this.rolPrueba, //this.datos.doliente,
        correoSelect: this.correo,
        mensaje: this.mensaje,
        //indicativo: "57"
      };
      const result = await FBenviarMensajeWhatsapp(jsonSale);
      if (result) {
        this.mensaje = "";
      } else {
        console.log("falló en mensaje Whatsapp");
      }
    },

    listenToMessages(chat) {
      if (chat === "ok") {
        this.switchTiempoReal = false;
      }

      if (this.switchTiempoReal) {
        //console.log("Activa el chat");
        const docRef = db.collection("cf-whatsapp");

        this.unsubscribe = docRef.onSnapshot((querySnapshot) => {
          const sortedItems = [];
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            sortedItems.push({ numero: id, contenido: data });
          });

          //console.log(sortedItems);
          // Ordenar los elementos basados en la propiedad "seguimiento"
          sortedItems.sort(
            (a, b) =>
              b.contenido.mensajePendiente - a.contenido.mensajePendiente
          );

          this.items = sortedItems;
        });
      } else {
        //console.log("Desactiva el chat");
        this.stopListening();
      }
    },

    stopListening() {
      if (this.unsubscribe) {
        this.unsubscribe();
        this.unsubscribe = null;
        this.reiniciar();
      }
    },

    reiniciar() {
      this.switchTiempoReal = false;
      this.items = [];
      this.mensaje = "";
      this.selectedItem = null;
      this.celular = null;
      this.mensaje = "";
      this.contenidoSelectItem = "";
    },

    colorMensaje(estado) {
      if (estado) {
        return "red";
      } else {
        return "green";
      }
    },

    async copiarTexto(textoLlega) {
      if (!navigator.clipboard) {
        console.error(
          "La API navigator.clipboard no es compatible en este navegador."
        );
        return;
      }

      let textoCopiado = textoLlega;

      try {
        await navigator.clipboard.writeText(textoCopiado);
        //console.log("Texto copiado al portapapeles: ", textoCopiado);
      } catch (error) {
        //console.error("Error al copiar el texto al portapapeles:", error);
      }
    },

    reproducirAudio() {
      audio.play();
    },

    colorChat(llega) {
      if (llega === undefined) {
        return "custom-backgroundInterno";
      } else {
        return "custom-background";
      }
    },

    estadoW(estado) {
      //console.log(estado);
      this.estadoWhatsapp = estado;
    },
  },

  watch: {
    items() {
      if (this.selectedItem !== null) {
        this.celular = this.items[this.selectedItem].numero;
        this.contenidoSelectItem = this.items[this.selectedItem].contenido;
        this.usuarioW = this.contenidoSelectItem.usuario;
        this.empresa = this.contenidoSelectItem.empresa;
        this.seguimiento = this.contenidoSelectItem.seguimiento;
        //console.log(this.contenidoSelectItem);
      } else {
        this.celular = null;
        this.contenidoSelectItem = "";
        this.usuarioW = null;
        this.empresa = "";
        this.seguimiento = false;
      }

      // Verificar el valor de tieneEntrega y asignar a this.alertaGeneral
      const tieneEntrega = this.items.some(
        (item) => item.contenido && item.contenido.mensajePendiente === true
      );

      this.alertaGeneral = tieneEntrega;

      if (this.alertaGeneral) {
        this.reproducirAudio();
      }
    },

    selectedItem() {
      if (this.selectedItem !== null) {
        this.celular = this.items[this.selectedItem]?.numero ?? null;
        this.contenidoSelectItem =
          this.items[this.selectedItem]?.contenido ?? "";
        this.usuarioW = this.contenidoSelectItem?.usuario ?? null;
        this.empresa = this.contenidoSelectItem?.empresa ?? "";
        this.seguimiento = this.contenidoSelectItem?.seguimiento ?? false;
        // ...
      } else {
        this.celular = null;
        this.contenidoSelectItem = "";
        this.usuarioW = null;
        this.empresa = "";
        this.seguimiento = false;
      }
    },
  },

  beforeDestroy() {
    this.stopListening();
  },
};
</script>

<style scoped>
.message-scroll {
  max-height: 300px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto;
}

.custom-background {
  background-color: #fff7a3; /* Reemplaza con el color de fondo deseado */
  border-radius: 10px; /* Ajusta el valor para redondear las puntas */
  padding: 10px; /* Ajusta el valor del relleno según tus necesidades */
  color: rgb(58, 58, 58);
}

.custom-backgroundInterno {
  background-color: #a1ffa5; /* Reemplaza con el color de fondo deseado */
  border-radius: 10px; /* Ajusta el valor para redondear las puntas */
  padding: 10px; /* Ajusta el valor del relleno según tus necesidades */
  color: rgb(0, 58, 0);
}
</style>