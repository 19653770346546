<template>
  <v-card color="amber">
    <v-card-title>
      Tareas Externas

      <v-divider class="mx-4" inset vertical></v-divider>
      <div class="caption">
        {{ desserts.length }} - {{ filtroCapitalizado }}
      </div>

      <v-spacer></v-spacer>
      <!-- BOTONERA BARRA AMARILLA -->
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn color="secondary" @click="consultaDatosListado()" fab x-small dark>
        <v-icon>mdi-update</v-icon>
      </v-btn>

      <!-- 
        <agregarTarea @agregaItem="agregarItemF" />
      -->
    </v-card-title>

    <v-data-table
      v-if="desserts.length >= 0"
      dark
      :headers="headers"
      :items="desserts"
      :search="search"
      sort-by="cliente"
      class="elevation-1"
      fixed-header
      height="350px"
      :items-per-page="5"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        'items-per-page-text': 'products per page',
        itemsPerPageText: 'Filas por página:',
        itemsPerPageAll: 'Todos',
        prevPage: 'Anterior',
        nextPage: 'Siguiente',
        pageText: '{0}-{1} de {2}',
      }"
    >
      <template v-slot:top>
        <!-- EL MODAL -->
        <v-toolbar flat>
          <v-toolbar-title>Buscar</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- BUSCADOR -->
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar por cualquier item"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
          <!-- SELECT de CATEGORIA-->

          <v-select
            v-model="filtro"
            :items="items"
            label="Filtra por estados"
            dense
            outlined
            single-line
            hide-details
            max-height="10px"
            @change="consultaDatosListado()"
          ></v-select>
          <!-- DIALOGO para eliminar registro-->
          <v-dialog v-model="dialogDelete" max-width="500px" dark>
            <v-card>
              <v-card-title>
                <h4>¿Seguro deseas eliminar el registro?</h4>

                <p>
                  Se eliminará el registró y sus sub procesos. No se podrá
                  reversar la opreación.
                </p>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="deleteItemConfirm"
                  >Si quiero eliminar
                </v-btn>
                <v-btn color="green" outlined text @click="closeDelete"
                  >No quiero</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- LA TABLA -->
      <!-- Se debe hacer por item.clave-->

      <!-- PROXIMO CONTACTO -->
      <template v-slot:item.proxContacto="{ item }">
        <v-chip :color="getFecha(calculoFechaLlamar(item.proxContacto))" dark>
          {{ calculoFechaLlamar(item.proxContacto) }}
          <v-tooltip color="black" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-2" icon v-bind="attrs" v-on="on" x-small>
                <v-icon dark>{{ getLlamarIcon(item.llamar) }} </v-icon>
              </v-btn>
            </template>
            <span>{{ fechaConvertirFirebase(item.proxContacto) }}</span>
          </v-tooltip>
        </v-chip>
      </template>

      <!-- FECHA REGISTRO-->
      <template v-slot:item.timeR="{ item }">
        <v-tooltip color="black" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" dark>
              {{ calcularDiferenciaEnDias(item.timeR) }} dias
            </v-chip>
          </template>
          <span>{{ fechaConvertirFirebase(item.timeR) }}</span>
        </v-tooltip>
      </template>

      <!--  PRIORIDAD -->
      <template v-slot:item.prioridad="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :color="getPrioridad(item.prioridad)"
              v-bind="attrs"
              v-on="on"
              dark
            >
              {{ item.prioridad }}
            </v-chip>
          </template>
          <span>{{ item.prioridad }}</span>
        </v-tooltip>
      </template>

      <!--  ESTADO -->
      <template v-slot:item.estado="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ma-2"
              text
              icon
              :color="getProcesoIcon(item.estado)[1]"
            >
              <v-icon v-bind="attrs" v-on="on">{{
                getProcesoIcon(item.estado)[0]
              }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.estado }}</span>
        </v-tooltip>
      </template>

      <!-- Botones Actions-->
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col cols="4">
            <FichaApoyo :todosLosDatos="item" />
          </v-col>
          <v-col cols="4">
            <!-- items   item.etapa -->
            <CambiarCategoria
              :idRegistro="item.seguimientoId"
              :etapaEspecifica="item.etapa"
              :etapa="items"
              :departamento="item.departamento"
              :posicion="item.posicion"
              @my-event="actualizaListado()"
            />
          </v-col>
          <v-col cols="4">
            <v-icon small @click="deleteItem(item)" color="red" disabled>
              mdi-trash-can
            </v-icon>
          </v-col>
        </v-row>

        <!-- Ficha cliente -->

        <!-- 
           <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        -->
      </template>

      <template v-slot:no-data>
        <v-btn
          color="black"
          @click="(filtro = 'todas'), consultaDatosListado()"
        >
          No hay registros, mostrar todas.
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";

import agregarTarea from "./agregarTarea.vue";
import { FBconsultarProcesos } from "@/components/Ventas/TareasExternas/consultaPanelProcesos.js";
import { FBconsultaEstadosTareas } from "@/components/Ventas/TareasExternas/consultas.js";
import FichaApoyo from "../Clientes/fichaApoyo.vue";
import CambiarCategoria from "./cambiarCategoriaTareasExternas.vue";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      dialogDelete: false,
      headers: [
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "cliente",
        },
        { text: "Prox. Contacto", value: "proxContacto" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Tiempo Transcurrido", value: "timeR" },
        { text: "Prioridad", value: "prioridad" },
        { text: "Estado", value: "estado" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      defaultItem: {
        cliente: "",
        proxContacto: 0,
        proyecto: 0,
        timeR: 0,
        apoyo: 0,
      },
      items: [],
      consultaDatos: [],
      filtro: "todas", //El primer documento
      diasLlamar: "",
    };
  },
  components: {
    agregarTarea,
    FichaApoyo,
    CambiarCategoria,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.consultaDatosListado();
  },

  methods: {
    getLlamarIcon(llamar) {
      if (llamar) {
        return "mdi-calendar-clock-outline";
      } else {
        return "mdi-calendar-clock-outline";
      }
    },

    getFecha(textoLlega) {
      //console.log(textoLlega);
      if (textoLlega === null) {
        return "grey darken-4"; // Color predeterminado para null
      } else if (textoLlega === "Llamar") {
        return "red"; // Color para "Llamar"
      }

      const texto = `${textoLlega} día(s) faltante(s)`;
      const match = texto.match(/\d+/);
      const daysDiff = match ? parseInt(match[0]) : null;

      if (daysDiff === null) {
        return "grey darken-4"; // Color predeterminado para valores no numéricos
      }

      if (daysDiff >= 30) {
        return "blue darken-4"; // Mayor a 30 días
      } else if (daysDiff >= 20) {
        return "blue"; // De 20 a 30 días
      } else if (daysDiff >= 15) {
        return "cyan darken-3"; // De 15 a 19 días
      } else if (daysDiff >= 10) {
        return "cyan"; // De 10 a 14 días
      } else if (daysDiff >= 8) {
        return "yellow darken-2"; // De 8 a 9 días
      } else if (daysDiff >= 5) {
        return "orange darken-1"; // De 5 a 7 días
      } else if (daysDiff >= 3) {
        return "deep-orange darken-2"; // De 3 a 4 días
      } else if (daysDiff >= 2) {
        return "deep-orange darken-3"; // De 2 días
      } else if (daysDiff >= 1) {
        return "red lighten-2"; // De 1 día
      } else {
        return "red"; // Sin retraso
      }
    },

    getPrioridad(prioridad) {
      const bajo = 3;
      const medio = 2;
      const alto = 1;

      if (prioridad == "Bajo") {
        return "cyan"; // Mayor a 30 días
      } else if (prioridad == "Medio") {
        return "purple"; // De 20 a 30 días
      } else if (prioridad == "Alto") {
        return "red"; // De 15 a 19 días
      }
    },

    fechaConvertirFirebase(timestamp) {
      // Convierte el objeto Timestamp a una fecha JavaScript
      const fecha = new Date(timestamp.seconds * 1000); // Multiplica por 1000 para convertir segundos a milisegundos

      // Obtén la fecha y hora en un formato legible
      const fechaHoraLegible = fecha.toLocaleString(); // Utiliza el método toLocaleString() para obtener la fecha y hora en el formato local del usuario

      //console.log(fechaHoraLegible);
      return fechaHoraLegible;
    },

    calculoFechaLlamar(fecha) {
      const fechaFormateada = moment.unix(fecha.seconds).toDate();
      const hoy = moment().startOf("day");
      const fecha2 = moment(fechaFormateada).startOf("day");

      const diffDays = fecha2.diff(hoy, "days");

      if (diffDays === 0) {
        return "Llamar";
      } else if (diffDays > 0) {
        return `${diffDays} día(s) faltante(s)`;
      } else {
        return `${-diffDays} día(s) vencido(s)`;
      }
    },

    calcularDiferenciaEnDias(fecha) {
      const today = new Date();
      const targetDate = new Date(fecha.seconds * 1000); // Convierte los segundos en milisegundos
      const timeDiff = Math.abs(targetDate - today);
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Diferencia en días

      return daysDiff;
    },

    actualizaListado() {
      this.consultaDatosListado();
    },

    async consultaDatosListado() {
      this.consultaDatos = [];
      this.desserts = []; // Total de Tareas

      const resultEtapas = await FBconsultaEstadosTareas();
      this.items = resultEtapas;
      this.items.push("todas");

      const resultadoConsultaListado = await FBconsultarProcesos(this.filtro);
      //console.log(resultadoConsultaListado);

      if (resultadoConsultaListado[0]) {
        this.consultaDatos = resultadoConsultaListado[1];
        this.desserts = this.consultaDatos;
      } else {
        console.log("Falló la consulta del listado");
      }
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    agregarItemF(newItem) {
      this.desserts.push(newItem);
    },

    getProcesoIcon(proceso) {
      if (proceso === "pendiente") {
        return ["mdi-timer-outline", "red"];
      } else if (proceso === "terminado") {
        return ["mdi-check", "green"];
      } else if (proceso === "trabajando") {
        return ["mdi-account-hard-hat", "yellow"];
      } else if (proceso === "cancelado") {
        return ["mdi-cancel", "red darken-4"];
      } else {
        return ["mdi-help", "red"]; // Si el estado no está definido, mostrará el icono "mdi-alpha-x"
      }
    },
  },

  computed: {
    filtroCapitalizado() {
      if (!this.filtro) return ""; // Si filtro es null o vacío, retornar cadena vacía

      return this.filtro.charAt(0).toUpperCase() + this.filtro.slice(1);
    },
  },

  beforeDestroy() {},
};
</script>