//import { firebase } from "../../firebase";
import twilio from "twilio";
import axios from "axios";


const accountSid = "AC4ccfc967da0798de677c84e36f46b150"; // Reemplaza con tu Account SID de Twilio
const authToken = "43fe40bfbc29c8ba98444d979998b2bf"; // Reemplaza con tu Auth Token de Twilio

//const accountSid = "AC15dd4d7705acb776be8c0e3fedf168bc"; // Reemplaza con tu Account SID de Twilio
//const authToken = "a3b63ab629ad50875a6eb396d6045702"; // Reemplaza con tu Auth Token de Twilio

const baseUrl = 'https://api.twilio.com/2010-04-01'; //'https://demo.twilio.com/welcome/voice/'; //
const apiUrl = `${baseUrl}/Accounts/${accountSid}/Calls.json`;

const fromNumber = "+12707388703"; // Número de teléfono de origen

//USANDO TWILIO -- senMensaje es para la opreacion de cartare Únicamente
export async function senMensaje(telefono, mensaje, indicativo, titularActual) {
    //QUEDA PENDIENTE AGREGAR EL MENSAJE ENVIADO PARA QUE DESCUENTE DEL LA BOLSA COMPRADA
    //titularActual
    const pais = indicativo;//"+57";
    const toNumbers = [`${pais}${telefono}`]; // Número de teléfono del destinatario
    const message = mensaje; // Mensaje a enviar

    const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;

    const basicAuth = `Basic ${btoa(`${accountSid}:${authToken}`)}`;
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: basicAuth
    };

    //GUARDAR EN UN ARRAY LOS QUE SE ENVIARON CON ÉXITO
    let resultadoOperacion = [];

    try {

        for (const toNumber of toNumbers) {
            const bodyParams = new URLSearchParams();
            bodyParams.set("From", fromNumber);
            bodyParams.set("To", toNumber);
            bodyParams.set("Body", message);

            const response = await axios.post(url, bodyParams.toString(), {
                headers
            });

            //console.log("Mensaje enviado a " + toNumber + " con SID: " + response.data.sid);
            if (response.data.sid) {
                resultadoOperacion.push({ numero: toNumber, resultado: true });
            } else {
                resultadoOperacion.push({ numero: toNumber, resultado: false });
            }

        }

        return [true, resultadoOperacion];
    } catch (error) {
        console.error("Error al enviar el mensaje: " + error);
        return [false];
    }
}

export async function llamadasTW() {

    //CODIGO 3
    try {
        const response = await axios.post(apiUrl, {
            To: '+573006386111',
            From: '+12707388703',
            Twiml: '<Response><Say>Ahoy, World!</Say></Response>',
        }, {
            auth: {
                username: accountSid,
                password: authToken,
            },
        });

        console.log(response.data.sid);
    } catch (error) {
        console.error('Error al realizar la llamada:', error);
    }

    //CODIGO 2
    /* const toNumber = '+573006386111'; // Número de teléfono del destinatario
     const twiml = '<Response><Say>Ahoy, World!</Say></Response>'; // TwiML para la llamada
 
     const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Calls.json`;
 
     const basicAuth = `Basic ${Buffer.from(`${accountSid}:${authToken}`).toString('base64')}`;
     const headers = {
         'Content-Type': 'application/x-www-form-urlencoded',
         Authorization: basicAuth,
     };
 
     try {
         const response = await axios.post(
             url,
             new URLSearchParams({
                 From: fromNumber,
                 To: toNumber,
                 twiml: twiml, // Cambio de Twiml a twiml
             }).toString(),
             { headers }
         );
 
         console.log(response.data.sid);
     } catch (error) {
         console.error(error);
     }*/

    //CODIGO 1
    /* const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Calls.json`;
     const toNumber = "+573006386111"; // Número de teléfono al que se realizará la llamada
 
     try {
         const response = await axios.post(url, {
             From: fromNumber,
             To: toNumber,
             Url: "http://demo.twilio.com/docs/voice.xml"
         });
 
         console.log(response.data);
 
         if (response.data.sid) {
             console.log("Llamada iniciada con éxito");
             return true;
         } else {
             console.log("Error al iniciar la llamada");
             return false;
         }
     } catch (error) {
         console.error("Error al iniciar la llamada:", error);
         return false;
     }*/

}

export async function sendEmail() {

}

export async function sendWhatsapp() {
    let toNumber2 = "+573006386111";
    let message = "Hola, este es un mensaje de prueba";
    const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    try {
        const response = await axios.post(
            url,
            new URLSearchParams({
                To: `whatsapp:${toNumber2}`, // Reemplaza con el número de destino en formato 'whatsapp:+NUMERO_DESTINO'
                From: 'whatsapp:+12707388703', // Reemplaza con tu número de origen en formato 'whatsapp:+XXXXXXXXXXX'
                Body: message,
            }).toString(),
            {
                headers: {
                    ...headers,
                    Authorization: `Basic ${Buffer.from(`${accountSid}:${authToken}`).toString('base64')}`,
                },
            }
        );

        console.log('Mensaje enviado con SID:', response.data.sid);
    } catch (error) {
        console.error('Error al enviar el mensaje:', error.response.data);
    }
}

//Para el formulario de Registro Prospecto
export async function sendMensajeGeneral(telefono, mensaje, indicativo) {
    //QUEDA PENDIENTE AGREGAR EL MENSAJE ENVIADO PARA QUE DESCUENTE DEL LA BOLSA COMPRADA
    //titularActual
    const pais = indicativo;//"+57";
    const toNumbers = [`${pais}${telefono}`]; // Número de teléfono del destinatario
    const message = mensaje; // Mensaje a enviar

    const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;

    const basicAuth = `Basic ${btoa(`${accountSid}:${authToken}`)}`;
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: basicAuth
    };

    //GUARDAR EN UN ARRAY LOS QUE SE ENVIARON CON ÉXITO
    let resultadoOperacion = [];

    try {

        for (const toNumber of toNumbers) {
            const bodyParams = new URLSearchParams();
            bodyParams.set("From", fromNumber);
            bodyParams.set("To", toNumber);
            bodyParams.set("Body", message);

            const response = await axios.post(url, bodyParams.toString(), {
                headers
            });

            //console.log("Mensaje enviado a " + toNumber + " con SID: " + response.data.sid);
            if (response.data.sid) {
                resultadoOperacion.push({ numero: toNumber, resultado: true });
            } else {
                resultadoOperacion.push({ numero: toNumber, resultado: false });
            }

        }

        return [true, resultadoOperacion];
    } catch (error) {
        console.error("Error al enviar el mensaje: " + error);
        return [false];
    }
}