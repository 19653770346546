import { db, firebase } from "@/firebase/config.js";


export async function FBagregaProcesoApoyo(json) {
    try {
        //console.log("Agrega Proceso Apoyo");
        //console.log(json);

        const datos = json;
        //SE GUARDA O AGREGA EN LA ESTA CORRESPONDIENTE -----------------------------#1
        const collectionPathCreaProspecto = `${datos.departamento}/tareasExternas/listado/${datos.idTarea}`;

        // Obtén una referencia al documento
        const docRef = db.doc(collectionPathCreaProspecto);

        // Actualiza el campo 'procesos' agregando los elementos en datos.observacion
        await docRef.update({
            'procesos': firebase.firestore.FieldValue.arrayUnion({ contenido: datos.obserbacion, enlaces: datos.enlaces, usuario: datos.usuarioRegistra, registro: firebase.firestore.Timestamp.now() }),
            'colaborador': datos.asignado
        });

        //Se debe actualizar la fecha de cambio del apoyo
        // /cf-ventas/etapas/prospeccion/IboepQlujASZwc2gApSe
        const etapasActuales = await etapas();

        //console.log(etapasActuales);

        const actualizaApoyo = await buscarIdSeguimiento(etapasActuales, datos.idSeguimiento, datos.departamento);

        //console.log(actualizaApoyo);

        if (actualizaApoyo[0]) {
            //Se encuentra el registro
            //Vamos a Actualizar el registro en la Fuente
            //console.log(actualizaApoyo[1], actualizaApoyo[2]);

            const resActulizacion = await actualizarApoyo(actualizaApoyo, datos.posicion, datos.departamento);

            if (resActulizacion) {
                return true;
            } else {
                return false;
            }

        } else {
            return false;
            //No se encuentra el registro 
            //No se puede actualizar el registro
            console.log(actualizaApoyo[1]);
        }

        //return true;

    } catch (error) {
        console.log(error);
        return false;
    }
}

async function actualizarApoyo(actualizaApoyo, posicion, departamento) {
    try {
        //const apoyoActualizar1 = actualizaApoyo[1].apoyo[posicion];

        const json = {
            idDocumento: actualizaApoyo[2],
            estapa: actualizaApoyo[1].etapa,
        }


        // /cf-ventas/etapas/contactoI/kbudkBVgwwFWzZd2UV1y
        const coleccionRef = db.collection(departamento).doc("etapas").collection(json.estapa).doc(json.idDocumento);

        // Obtener el documento actual
        const documentoActual = await coleccionRef.get();

        if (documentoActual.exists) {
            // Obtener el array 'apoyo' del documento actual
            const apoyoActual = documentoActual.data().apoyo || [];

            // Verificar si la posición existe en el array 'apoyo'
            if (posicion >= 0 && posicion < apoyoActual.length) {
                // Crear un objeto parcial con el nuevo campo 'fechaActualizacion'
                const nuevoObjeto = {
                    ...apoyoActual[posicion], // Mantener los campos actuales
                    fechaActualizacion: firebase.firestore.Timestamp.now(),// Agregar el nuevo campo
                };

                // Actualizar solo el objeto en la posición específica del array 'apoyo'
                apoyoActual[posicion] = nuevoObjeto;

                // Actualizar todo el array 'apoyo' en el documento con el objeto modificado
                await coleccionRef.update({ apoyo: apoyoActual });

                return true;

                //console.log("El campo 'fechaActualizacion' en el array 'apoyo' ha sido actualizado o creado.");
            } else {
                return false;
                //console.log("La posición indicada no existe en el array 'apoyo'.");
            }
        } else {
            //console.log("El documento no existe en Firestore.");
            return false;
        }
    } catch (error) {
        console.log("Error Actualizando apoyo: " + error);
        return false;
    }
}



async function buscarIdSeguimiento(etapasActuales, idSeguimiento, departamento) {
    try {
        //console.log(idSeguimiento);
        //console.log(etapasActuales);
        const rutaColeccionPrincipal = departamento;//"cf-ventas";
        const rutaSubColeccion = "etapas";

        // Recorrer las subcolecciones
        for (const subColeccionId of etapasActuales) {
            const coleccionesRef = db.collection(`${rutaColeccionPrincipal}/${rutaSubColeccion}/${subColeccionId}`);

            // Realizar la consulta para encontrar el documento que coincida con "seguimientoId" igual a idSeguimiento
            const querySnapshot = await coleccionesRef.where("seguimientoId", "==", idSeguimiento).get();

            // Variable para almacenar el ID del documento encontrado
            let idDocumentoEncontrado = null;

            // Verificar si se encontraron documentos que cumplen las condiciones
            if (!querySnapshot.empty) {
                // Encontramos el resultado, establecer la variable bandera a true
                let encontrado = false;

                // Recorrer el resultado con un bucle for...of para obtener los documentos que coinciden
                querySnapshot.forEach((doc) => {
                    const documento = doc.data();
                    //console.log(`Colección: ${subColeccionId}, Documento: ${doc.id}, idSeguimiento: ${documento.seguimientoId}`);

                    // Establecer la variable 'encontrado' a true para indicar que hemos encontrado el resultado
                    encontrado = true;

                    // Almacenar el ID del primer documento encontrado
                    if (!idDocumentoEncontrado) {
                        idDocumentoEncontrado = doc.id;
                    }
                });

                // Si hemos encontrado el resultado, retornar los valores
                if (encontrado) {
                    return [true, querySnapshot.docs[0].data(), idDocumentoEncontrado];
                }
            } else {
                //console.log(`No se encontró el campo 'seguimientoId' igual a '${idSeguimiento}' en la colección ${subColeccionId}.`);
            }
        }

        // Si no se encontró el resultado en ninguna de las subcolecciones, retornar false
        return [false, null];
    } catch (error) {
        //console.error("Error al buscar el campo 'idSeguimiento':", error);
        return [false, null];
    }
}

async function etapas() {

    try {
        const rutaColeccion = "recursos";
        const documentoId = "etapasProspectos";
        const documentoRef = db.collection(rutaColeccion).doc(documentoId);
        const documentoSnapshot = await documentoRef.get();

        if (documentoSnapshot.exists) {
            const nombresCampos = Object.keys(documentoSnapshot.data());
            //console.log("Nombres de los campos:", nombresCampos);
            return nombresCampos;
        } else {
            console.log("El documento no existe.");
            return [];
        }
    } catch (error) {
        console.log("Error en Desarrollo Apoyo: " + error);
    }
}