<template>
  <div justify="center">
    <v-dialog v-model="dialog" max-width="80%" scrollable persistent>
      <!-- Boton de Dialog  -->
      <template v-slot:activator="{}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" small icon v-on="on" @click="dialog = !dialog">
              <v-icon>mdi-account-plus </v-icon>
            </v-btn>
          </template>
          <span>Crear Cliente</span>
        </v-tooltip>
      </template>

      <!-- Card -->
      <v-card dark>
        <v-card-title color="amber"> Crea Cliente </v-card-title>
        
        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.cliente"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="Cliente (Razón Social)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.nit"
                    :disabled="isUpdating"
                    :rules="[
                      (value) => !!value || 'Campo obligatorio',
                      //(value) => this.nitValidacion || 'Existe cliente',
                    ]"
                    @input="buscaCliente()"
                    color="blue-grey lighten-2"
                    label="Nit (sin numero de verificacion)"
                    filled
                    :error="nitValidacion"
                    :error-messages="errorMessages"
                    :messages="mensajeNit"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- ChekBox -->
              <v-row no-gutters>
                <v-col cols="6" md="6">
                  <v-checkbox
                    :disabled="isUpdating"
                    v-model="dataFormulario.servicio"
                    label="Cartera al día"
                    value="CAD"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" md="6">
                  <v-checkbox
                    :disabled="isUpdating"
                    v-model="dataFormulario.servicio"
                    label="Cartera Morosa"
                    value="CM"
                  ></v-checkbox>
                  <!--
                  <p>{{ tipoServicio }}</p>-->
                </v-col>
              </v-row>

              <v-divider class="pa-3"></v-divider>
              <!-- files .PDF -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-file-input
                    v-model="file1"
                    @change="detectImagen($event)"
                    :disabled="isUpdating"
                    :rules="fileRules"
                    accept=".pdf"
                    show-size
                    label="PDF Certificados"
                    filled
                    chips
                  ></v-file-input>
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input
                    v-model="file2"
                    @change="detectImagen($event)"
                    :disabled="isUpdating"
                    :rules="fileRules"
                    accept=".pdf"
                    show-size
                    label="PDF Carta de Endoso"
                    filled
                    chips
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-divider class="pa-3"></v-divider>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.contactoP"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="Contacto Principal (Nombres 1, Nombre 2)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.celCP"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="Whatsapp (300599999, 304877777)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.mailCP"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="e-Mail (corre@gmail.com, muestra@muestra.com)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.cargoCP"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="Cargo o Departamento"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-divider class="pa-3"></v-divider>
              <!-- Contacto Secundario -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.contactoS"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="Contacto Secundario (Nombres 1, Nombre 2)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.celCS"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="Whatsapp (300599999, 304877777)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.mailCS"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="e-Mail (corre@gmail.com, muestra@muestra.com)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataFormulario.cargoCS"
                    :disabled="isUpdating"
                    filled
                    color="blue-grey lighten-2"
                    label="Cargo o Departamento"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="disabledCrear"
            :loading="isUpdating"
            color="amber"
            depressed
            @click="creaCliente()"
          >
            Crear
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="amber" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="isUpdating" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Espere por favor
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import {
  FBcreaCliente,
  FBbuscaCliente,
} from "../../../components/Cartera/creaCliente";

export default {
  name: "crearCliente",
  props: [],
  data: () => ({
    dialog: false,
    disabledCrear: false,
    isUpdating: false,
    //formulario
    dataFormulario: {
      cliente: null,
      nit: null,
      servicio: [],
      contactoP: [],
      celCP: [],
      mailCP: [],
      cargoCP: null,
      contactoS: [],
      celCS: [],
      mailCS: [],
      cargoCS: null,
      estado: false,
    },
    nitValidacion: false,
    errorMessages: "",
    mensajeNit: "",
    file1: null,
    fileRules: [
      (value) => !!value || "Obligatorio.",
      (value) =>
        !value || value.size < 2000000 || "No puede superar las 2 MB! 2 MB!",
    ],
    file2: null,
  }),
  methods: {
    async buscaCliente() {
      //console.log("Busca cliente");
      //console.log(this.dataFormulario.nit);

      this.mensajeNit = `Buscando...`;

      const rest = await FBbuscaCliente(this.dataFormulario.nit);
      //console.log("reusltado rest: ");
      //console.log(rest);
      if (rest) {
        //Existe usuario
        this.nitValidacion = true;
        this.mensajeNit = "";
        this.errorMessages = "Existe cliente";
        this.disabledCrear = true;
        return rest;
      } else if (!rest) {
        this.nitValidacion = false;
        //"Perfecto!, continua."=
        this.mensajeNit = "Perfecto!, continua.";
        this.errorMessages = "";
        this.disabledCrear = false;
        return rest;
      }
    },
    async creaCliente() {
      this.isUpdating = true;

      const arrays = await this.creaArray();

      //console.log(arrays);

      //File 1: es el certificado de cuenta
      //File 2: es el carta de endoso
      const res = await FBcreaCliente(
        this.dataFormulario,
        this.file1,
        this.file2
      );

      if (res[0]) {
        //respuesta servidor
        this.dataFormulario = {
          cliente: null,
          nit: null,
          servicio: [],
          contactoP: [],
          celCP: [],
          mailCP: [],
          cargoCP: null,
          contactoS: [],
          celCS: [],
          mailCS: [],
          cargoCS: null,
          estado: false,
        };
        this.file1 = null;
        this.file2 = null;
        this.isUpdating = false;
        this.$refs.form.reset();
      } else {
        this.dataFormulario = {
          cliente: null,
          nit: null,
          servicio: [],
          contactoP: [],
          celCP: [],
          mailCP: [],
          cargoCP: null,
          contactoS: [],
          celCS: [],
          mailCS: [],
          cargoCS: null,
          estado: false,
        };
        this.file1 = null;
        this.file2 = null;
        this.$refs.form.reset();
        this.isUpdating = false;
      }
    },
    async creaArray() {
      //convirtiendo los string en array
      for (let ele in this.dataFormulario) {
        //console.log(ele); //El indice, ejemplo: cliente
        switch (ele) {
          case "contactoP":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "celCP":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "mailCP":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "contactoS":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "celCS":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          case "mailCS":
            var result = _.split(this.dataFormulario[ele], ",");
            //console.log(result);
            this.dataFormulario[ele] = result;
            //console.log(this.dataFormulario);
            break;
          default:
            break;
        }
      }
      return true;
    },
    detectImagen(e) {
      //console.log(e);
      //console.log(this.file1);
      //this.imagen = e.target.files[0];
      //console.log(this.imagen);
    },
  },
  computed: {
    retornaEstadoMensaje() {
      //return (this.sheet = this.estadoMensaje);
    },
  },
};
</script>