<template>
  <div v-show="rolUsuario == 'acm' || rolUsuario == 'g'">
    <v-row>
      <v-col>
        <v-toolbar  dark dense>
          <v-toolbar-title>Llamar Proximos 5 días</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn @click="consultaBase()" icon>
            <v-icon>mdi-update</v-icon>
          </v-btn>
        </v-toolbar>
        <v-simple-table height="100%">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Cédula</th>
                <th class="text-left">Tipificación</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in desserts" :key="item.name">
                <td>{{ item.Cedula }}</td>
                <td>{{ item.tipiUlti }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
        
<script>
import { FBLlamarEnCincoDias } from "../../../../components/Cartera/panelesACM/consultasBasicas";

export default {
  name: "gestionMananaACM",
  components: {},
  props: ["rolUsuario", "idUsuario"],
  data: () => ({
    desserts: [
      {
        name: "Cargando...",
        calories: 0,
      },
    ],
  }),
  mounted() {
    this.consultaBase();
  },
  methods: {
    async consultaBase() {
      this.desserts = [];
      const result = await FBLlamarEnCincoDias(this.idUsuario);
      //console.log(result);
      this.desserts = result;
    },
  },
};
</script>
