import { db, firebase } from "@/firebase/config.js";
import moment from 'moment';

export async function FBactualizaApoyo(jsonLlega) {
    try {

        const departamentoActual = "cf-ventas"; // Se deberia cambiar dependiendo el departamento Actual de Panel

        const json = {
            etapa: jsonLlega.etapa,
            idRegistro: jsonLlega.idRegistro,
            posicion: jsonLlega.posicion,
            estado: jsonLlega.estado,
            departamento: jsonLlega.departamento,
            seguimientoId: jsonLlega.seguimientoId,
        };

        //console.log(json);

        const collectionPathCreaProspecto = `${departamentoActual}/etapas/${json.etapa}`;

        const querySnapshot = await db
            .collection(collectionPathCreaProspecto)
            .where('seguimientoId', '==', json.idRegistro)
            .get();

        if (querySnapshot.empty) {
            return [false, "No existe Documento"];
        }

        const docRef = db.collection(collectionPathCreaProspecto).doc(querySnapshot.docs[0].id);

        // Accede al campo "apoyo" del documento
        const apoyo = querySnapshot.docs[0].data().apoyo || [];

        // Realiza las modificaciones necesarias en la posición específica del array

        const fechaActual = new Date(); // Obtiene la fecha actual
        const timestamp = convertirFechaATimestamp(fechaActual);


        apoyo[json.posicion].estado = json.estado;
        apoyo[json.posicion].fechaActualizacion = timestamp;

        const resElimina = await eliminarApoyo(json);

        if (resElimina) {
            // Actualiza el documento con los cambios realizados
            await docRef.update({
                apoyo: apoyo
            });
            return [true, "Se ha actualizado con éxito"];
        } else {
            return [false];
        }

    } catch (error) {
        console.error('Error al actualizar el documento:', error);
        return [false, "Error.."];
    }
}

async function eliminarApoyo(json) {
    try {
        //console.log(json);
        const collectionPathCreaProspecto = `${json.departamento}/tareasExternas/listado`;

        // Obtener la referencia de la colección y hacer una consulta para encontrar el documento
        const querySnapshot = await db.collection(collectionPathCreaProspecto)
            .where("seguimientoId", "==", json.idRegistro)
            .where("posicion", "==", json.posicion)
            .get();

        // Verificar si se encontraron documentos que cumplen las condiciones
        if (!querySnapshot.empty) {
            // Suponiendo que solo hay un documento que coincide, obtenemos su referencia y lo eliminamos
            const documentoRef = querySnapshot.docs[0].ref;
            await documentoRef.delete();

            //console.log("Documento eliminado correctamente.");
            return true;
        } else {
            //console.log("No se encontró ningún documento que cumpla las condiciones para eliminar.");
            return false;
        }


    } catch (error) {
        console.log("Error al Eliminar Apoyo:" + error);
        return false;
    }
}

export async function FBactualizaApoyoObservacion(jsonLlega) {
    try {
        //console.log(jsonLlega);

        const json = {
            etapa: jsonLlega.etapa,
            idRegistro: jsonLlega.idRegistro,
            posicion: jsonLlega.posicion,
            observacion: jsonLlega.observacion,
            departamento: jsonLlega.departamento,
            seguimientoId: jsonLlega.seguimientoId
        };

        const collectionPathCreaProspecto = `cf-ventas/etapas/${json.etapa}`;

        const querySnapshot = await db
            .collection(collectionPathCreaProspecto)
            .where('seguimientoId', '==', json.idRegistro)
            .get();

        if (querySnapshot.empty) {
            return [false, "No existe Documento"];
        }

        const docRef = db.collection(collectionPathCreaProspecto).doc(querySnapshot.docs[0].id);

        // Accede al campo "apoyo" del documento
        const apoyo = querySnapshot.docs[0].data().apoyo || [];

        // Realiza las modificaciones necesarias en la posición específica del array
        const fechaActual = new Date(); // Obtiene la fecha actual
        const timestamp = convertirFechaATimestamp(fechaActual);

        if (Array.isArray(apoyo) && json.posicion >= 0 && json.posicion < apoyo.length) {
            // Obtén el objeto de la posición específica
            const posicionObjeto = apoyo[json.posicion];


            // Verifica si el objeto existe
            if (posicionObjeto) {
                // Actualiza la clave 'observacion' del objeto
                posicionObjeto.observacion = json.observacion;

                // Verifica si la clave 'fechaActualizacion' ya existe en el objeto
                if (posicionObjeto.hasOwnProperty('fechaActualizacion')) {
                    // Si existe, actualiza su valor
                    posicionObjeto.fechaActualizacion = timestamp;
                } else {
                    // Si no existe, crea la clave 'fechaActualizacion' con su valor
                    posicionObjeto.fechaActualizacion = timestamp;
                }
            }
        }

        // Actualiza el documento con los cambios realizados
        await docRef.update({
            apoyo: apoyo
        });

        //ACTUALIZAR LA TAREA EXTERNA 

        const collectionPathActualizaTareaExterna = `${json.departamento}/tareasExternas/listado`;

        try {
            const querySnapshot = await db
                .collection(collectionPathActualizaTareaExterna)
                .where('seguimientoId', '==', json.seguimientoId)
                .get();

            if (!querySnapshot.empty) {
                const docRef = querySnapshot.docs[0].ref;

                await docRef.update({
                    observacion: json.observacion
                });

                //console.log("La clave 'observacion' se ha actualizado con éxito.");
                return [true, "Se ha actualizado con éxito"];
            } else {
                //console.log("No se encontró ningún documento con el seguimientoId especificado.");
                return [false, "Error.."];
            }
        } catch (error) {
            //console.error("Error al actualizar la clave 'observacion':", error);
            return [false, "Error.."];
        }




    } catch (error) {
        console.error('Error al actualizar el documento:', error);
        return [false, "Error.."];
    }
}

function convertirFechaATimestamp(fecha) {
    const fechaObjetivo = moment(fecha, 'YYYY-MM-DD').toDate();
    const timestamp = firebase.firestore.Timestamp.fromDate(fechaObjetivo);
    return timestamp;
}