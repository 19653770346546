import { firebase, db } from "../../firebase/config"


async function timeTodate(a) {
    //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.
    /*console.log('Entra a leer fecha');
    console.log(a);
    console.log('**********************************');
    console.log(new Date(a.seconds * 1000));*/

    if (typeof a == "object" && a != null) {
        //console.log(a);
        let time = {
            seconds: a.seconds,
            nanoseconds: a.nanoseconds,
        };

        const fireBaseTime = new Date(
            time.seconds * 1000
        );

        //const date = fireBaseTime.toDateString();
        //const atTime = fireBaseTime.toLocaleTimeString();
        //console.log("Es un Timespam");
        //console.log(date, atTime);
        //const resultado = date + " / " + atTime;
        //console.log(resultado);
        return new Date(fireBaseTime);
    } /**/
}


export async function FBEstrategiaPorItem(listadoNits, bolsaTitulares, estrategia, asesor) {
    let asesorLlega = asesor;
    //console.log("Entra a Estrategia Por Item");
    //console.log(bolsaTitulares);
    try {
        let conFecha = bolsaTitulares.filter(n => n.proxToque != null); // Filtrar los elementos con fecha definida
        let conFechaOrderBy = _.orderBy(conFecha, 'proxToque', 'desc'); // Ordenar los elementos con fecha en orden descendente

        let sinTocar = bolsaTitulares.filter(n => n.toques === 0); // Filtrar los elementos sin toques

        const resultMontosA = await montosXejecutivo(asesorLlega); // Consultar la configuración por montos en la base de datos

        //console.log(resultMontosA);

        let soloNitPreferencial;

        if (resultMontosA[3] !== "0") {
            soloNitPreferencial = sinTocar.filter(n => n.nitCliente !== resultMontosA[3]); // Filtrar los elementos que no tienen el nit preferencial
            //console.log(soloNitPreferencial);
        }

        let conjuntosDeTitulares = 90; // Número de conjuntos en los que se divide el arreglo sinTocar
        let bolsaEstrategica = [];

        const loteActual = await contadorLotes(asesorLlega); // Obtener el lote actual
        let loteLlega = loteActual;

        //console.log(loteLlega);

        let arregloDeArreglos = [];
        for (let i = 0; i < sinTocar.length; i += conjuntosDeTitulares) {
            let pedazo = sinTocar.slice(i, i + conjuntosDeTitulares); // Dividir el arreglo sinTocar en conjuntos más pequeños
            arregloDeArreglos.push(pedazo);
        }

        //console.log(arregloDeArreglos);

        let resultAnalisisBolsa = [];
        let acumuladoElementosAnidados = 0;

        for (let index = 0; index < arregloDeArreglos.length; index++) {
            resultAnalisisBolsa.push(await trabajandoLotes(arregloDeArreglos, index, asesorLlega)); // Realizar el análisis de cada conjunto de titulares

            acumuladoElementosAnidados = resultAnalisisBolsa[index].length + acumuladoElementosAnidados;

            if (acumuladoElementosAnidados >= 16) {
                loteLlega++;
                index = arregloDeArreglos.length; // Salir del bucle for
            }
        }

        let arrayFinal = conFechaOrderBy.concat(_.flatten(resultAnalisisBolsa)); // Unir los elementos con fecha y los elementos analizados

        //console.log(arrayFinal);

        let totalLotes = arregloDeArreglos.length - 1;
        if (loteLlega >= totalLotes) {
            loteLlega = 0;
            await actualizaLotes(loteLlega, asesorLlega); // Actualizar el lote próximo
        } else if (loteLlega < totalLotes) {
            loteLlega++
            await actualizaLotes(loteLlega, asesorLlega);
        }

        return arrayFinal; // Retornar el resultado final
    } catch (error) {
        return error;
    }
}


async function contadorLotes(asesor) {
    try {
        const asesorLlega = asesor;

        // Referencia al documento 'Estrategia'
        const estrategiaDocRef = db.collection('cf-cartera').doc('Estrategia');
        const estrategiaDoc = await estrategiaDocRef.get();

        if (!estrategiaDoc.exists) {
            // Si el documento 'Estrategia' no existe, lo creamos
            await estrategiaDocRef.set({});
        }

        // Obtener la colección de documentos correspondiente al asesor
        const ref = estrategiaDocRef.collection(asesorLlega);

        // Obtener los documentos de la colección
        const snapshot = await ref.get();

        let lote = 0;

        if (!snapshot.empty) {
            // Si hay documentos en la colección, obtener el valor del lote del último documento
            snapshot.forEach(doc => {
                const datos = doc.data();
                lote = datos.configuracion.lote;
            });
        }

        return lote;

    } catch (error) {
        console.log(error);
        return 0; // En caso de cualquier error, retornamos 0 como valor predeterminado del lote
    }
}



async function actualizaLotes(loteProximo, asesorLlega) {
    let lote = loteProximo;
    //console.log("Actualiza Lotes");
    //console.log(lote);
    //console.log(asesorLlega);

    const docRef = db.collection('cf-cartera')
        .doc('Estrategia')
        .collection(asesorLlega)
        .doc('carga');

    const doc = await docRef.get();

    if (!doc.exists) {
        // Si el documento no existe, lo creamos.
        await docRef.set({
            'configuracion': {
                'lote': lote
            }
        });
    } else {
        // Si el documento ya existe, simplemente lo actualizamos.
        await docRef.update({
            'configuracion.lote': lote
        });
    }
}


async function trabajandoLotes(totalFracciones, lotedb, asesorLlega) {
    // Consultar la configuración por montos en la base de datos
    const resultMontos = await montosXejecutivo(asesorLlega);

    let montoMayor = resultMontos[0];
    let montoMenor = resultMontos[1];
    let habilitado = resultMontos[2];

    // Verificar si la configuración de montos está habilitada
    if (!habilitado || habilitado === undefined || resultMontos === null) {
        montoMayor = 0;
        montoMenor = 0;
    }

    const bolsaEstrategica = [];
    const bolsaSecundaria = [];

    for (const elementIdCartera of totalFracciones[lotedb]) {
        const totalRegistrosCarteraRef = db.collection('cf-cartera')
            .doc('Carteras')
            .collection(elementIdCartera.nitCliente)
            .doc(elementIdCartera.idCartera)
            .collection('registros')
            .doc(elementIdCartera.idRegistro);

        const doc = await totalRegistrosCarteraRef.get();

        if (doc.exists) {
            const monto = doc.data().data.Monto;

            if (monto >= montoMayor && monto <= montoMenor) {
                const registro = {
                    nitCliente: elementIdCartera.nitCliente,
                    idCartera: elementIdCartera.idCartera,
                    idRegistro: doc.id,
                    Cedula: doc.data().data.Cedula,
                    toques: 0,
                    monto
                };

                bolsaEstrategica.push(registro);
            } else {
                const registroB = {
                    nitCliente: elementIdCartera.nitCliente,
                    idCartera: elementIdCartera.idCartera,
                    idRegistro: doc.id,
                    Cedula: doc.data().data.Cedula,
                    toques: 0,
                    monto
                };

                bolsaSecundaria.push(registroB);
            }
        }
    }

    return bolsaEstrategica.length === 0 ? bolsaSecundaria : bolsaEstrategica;
}



//Trae la info de los montos configurados y el nit de prelación
async function montosXejecutivo(asesorLlega) {
    try {
        const idEjecutivo = String(asesorLlega);
        let altos = 0;
        let bajos = 0;
        let estado = false;
        let clientePrelacion = '0';

        // Obtener la configuración de montos desde la base de datos
        const cityRef = db.collection('cf-cartera').doc('Estrategia').collection(idEjecutivo).doc('carga');
        const doc = await cityRef.get();

        if (!doc.exists) {
            // Si no existe el documento, establecer los valores predeterminados
            altos = 0;
            bajos = 0;
            estado = false;
            clientePrelacion = '0';
        } else {
            // Si existe el documento, obtener los valores de configuración si están disponibles
            const data = doc.data();
            if (data && data.montos) {
                altos = data.montos.Altos || 0;
                bajos = data.montos.Bajos || 0;
                estado = data.montos.estado || false;
                clientePrelacion = data.montos.cliente || '0';
            }
        }

        return [altos, bajos, estado, clientePrelacion];
    } catch (error) {
        console.log(error);
    }
}


async function nitPrelacion(totalFracciones, clientePrelacion) {
    try {
        //console.log(totalFracciones, clientePrelacion);
        let nuevoArrayOrden = []
        for (let index = 0; index < totalFracciones.length; index++) {
            const element = totalFracciones[index];

            //console.log(element);
            /*element.sort((a, b) => {
                return a.nitCliente - b.nitCliente;
            });*/
        }

        return 'OK'

    } catch (error) {
        console.error(error);
    }
}