<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" dark>
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" v-on="on" color="orange">
          mdi-swap-horizontal
        </v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h5 amber"> Cambio de Proceso </v-card-title>

        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              El siguiente proceso es para cambiar a todo un prospecto a otra
              categoría de proceso.
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col class="d-flex" cols="12">
              <v-select
                v-model="selectedItem"
                :items="items"
                item-text="title"
                item-value="panel"
                label="Cambiar a..."
              ></v-select>
            </v-col>
            <v-col>
              <v-card elevation="2">
                {{ selectedItemDescription }}
              </v-card>
            </v-col>
          </v-row>
          <br />
          <v-row align="center">
            <v-col class="d-flex" cols="12">
              <v-btn block elevation="2" @click="cambiarProceso">Cambiar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="amber" text  @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import { FBconsultarEtapasProspectos } from "@/components/Ventas/consultas.js";
import { FBcambioProcesoProspecto } from "@/components/Ventas/cambioCategoriaProspecto.js";

export default {
  props: ["idRegistro", "etapa"],
  data() {
    return {
      dialog: false,
      items: [],
      selectedItem: null,
    };
  },

  computed: {
    selectedItemDescription() {
      if (this.selectedItem) {
        const selectedItem = this.items.find(
          (item) => item.panel === this.selectedItem
        );
        return selectedItem ? selectedItem.descripcion : "";
      }
      return "";
    },
  },

  created() {
    this.inicializar();
  },

  methods: {
    async inicializar() {
      const resultado = await FBconsultarEtapasProspectos();
      this.items = resultado;
    },
    async cambiarProceso() {
      if (this.selectedItem) {
        //console.log("Cambiando a:", this.selectedItem);

        // Realiza las acciones necesarias para cambiar el proceso

        const json = {
          etapa: this.etapa,
          idRegistro: this.idRegistro,
          nuevoProceso: this.selectedItem,
        };

        const result = await FBcambioProcesoProspecto(json);
        //console.log(result);
        if (result) {
          this.selectedItem = null;
          this.dialog = true;
        }

        this.$emit('my-event');
      }
    },
  },
};
</script>
  