<template>
  <div class="text-center">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate color="red"></v-progress-circular>
    </v-overlay>

    <v-card
      v-if="items2.length > 0"
      class="mx-auto"
      color="transparent"
      outlined
    >
      <v-list three-line>
        <template v-for="(item, index) in items2">
          <v-subheader v-if="item.header" :key="item.header">
            {{ item.header }}
          </v-subheader>

          <div
            v-if="item.data.correo !== 'javier@rehobot.com.co'"
            :key="item.data.cedula"
          >
            <v-divider :key="index"></v-divider>

            <v-list-item :key="item.data.cedula">
              <v-list-item-avatar color="black" size="30">
                <span class="white--text text-h5">{{
                  item.data.empleado.substr(0, 1)
                }}</span>
              </v-list-item-avatar>

              <v-list-item-content :oncopy="true" class="noselect">
                <v-row>
                  <v-col>
                    <v-list-item-title>{{
                      item.data.empleado
                    }}</v-list-item-title>
                    <v-list-item-title v-if="rolesConsultaLoaded">{{
                      cargo(item.data.cargo)
                    }}</v-list-item-title>

                    <v-list-item-subtitle>{{
                      "Correo: " + item.data.correo
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{
                      "Cel. Corpo: " + item.data.telefonoCorp
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle
                      :id="'Listado Empleados: ' + item.data.extencion"
                    >
                      {{ "Extensión: " + item.data.extencion }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{
                      "Puntaje: " + item.puntaje
                    }}</v-list-item-subtitle>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch
                      :disabled="rolPrueba !== 'g'"
                      v-model="item.data.estado"
                      :label="`${textoSw(item.data.estado)}`"
                      @change="
                        actualizaCampo('estado', item.id, item.data.estado)
                      "
                      inset
                      color="amber"
                    ></v-switch>
                  </v-col>
                  <!-- <v-col cols="3">
                    
                    <v-text-field
                      :disabled="rolPrueba !== 'g'"
                      v-model="item.puntaje"
                      label="Puntaje"
                      placeholder="Placeholder"
                      outlined
                      type="number"
                      @change="actualizaCampo('puntaje', item.id, item.puntaje)"
                    ></v-text-field>
                    
                    
                  </v-col>-->
                  <v-col>
                    <v-btn
                      :disabled="rolPrueba !== 'g'"
                      class="mt-5"
                      @click="abrePDF(item.id, item.data.cedula)"
                      target="_blank"
                      color="deep-orange"
                      icon
                    >
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      class="mt-5"
                      :href="'https://wa.me/57' + item.data.telefonoCorp"
                      @click="huellaD1()"
                      target="_blank"
                      icon
                      color="green"
                    >
                      <v-icon>mdi-whatsapp</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      class="mt-5"
                      @click="linkCorreo(item.data.correo)"
                      icon
                      color="blue"
                    >
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>

              <v-list-item-action>
                <v-row>
                  <v-col>
                    <v-btn @click="copiaTexto(item.data)" icon>
                      <v-icon color="grey lighten-1">{{
                        cambioIcono()
                      }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </div>
        </template>
      </v-list>

      <v-divider class="mx-4"></v-divider>
      
      <v-row>
        <v-col>
          Para notificaciones / notificaciones@beracabg.com
        </v-col>
      </v-row>

    </v-card>

    <v-progress-circular
      v-if="items2.length <= 0 || !items2"
      indeterminate
      color="red"
    ></v-progress-circular>
  </div>
</template>

<script>
import { FBtraeRoles } from "@/components/Roles/index.js";
import {
  FBlistadoEmpleado,
  FBpdfHdv,
  FBactualizaEmpleado,
} from "../../../components/RecursoHumano/listadoEmpleados";
import store from "../../../store";

export default {
  name: "listadoEmpleados",
  props: [],
  components: {},
  data: () => ({
    rolPrueba: store.state.user[2][0].data.cargo,
    overlay: false,
    iconoCopy: false,
    switch1: true,
    items2: [],
    puntaje: null,
    roles: [],
    rolesConsultaLoaded: false, // Nueva propiedad
  }),
  async created() {
    this.cargaRoles();
    this.cargaEmpleados();
  },
  async mounted() {},
  methods: {
    async cargaEmpleados() {
      this.items2 = [];
      const result = await FBlistadoEmpleado();

      if (result[0]) {
        let dataLlega = result[1];
        this.items2 = dataLlega;
        this.rolesConsultaLoaded = true; // Marcar como cargados los datos de rolesConsulta
      } else {
        console.error("Problemas en la consulta.");
      }
    },
    async copiaTexto(dato) {
      this.iconoCopy = true;
      let textoCopiado =
        " " +
        dato.empleado +
        "\nCargo: " +
        this.cargo(dato.cargo) +
        "\nCorreo: " +
        dato.correo +
        "\nTel. Corporativo: " +
        dato.telefonoCorpo +
        "\next: " +
        dato.extencion;

      const a = await navigator.clipboard.writeText(textoCopiado);
    },
    cambioIcono() {
      if (this.iconoCopy) {
        this.iconoCopy = true;
        return "mdi-check-circle";
      } else {
        this.iconoCopy = false;
        return "mdi-content-copy";
      }
    },
    async abrePDF(id, cedula) {
      const rest = await FBpdfHdv(id, cedula);
      window.open(rest, "_blank");
    },
    async linkCorreo(dato) {
      window.open("mailto:" + dato, "_blank");
    },
    async actualizaCampo(a, b, c) {
      this.overlay = true;
      const result = await FBactualizaEmpleado(a, b, c);
      this.overlay = false;

      if (!result) {
        this.overlay = true;
      }
    },
    textoSw(a) {
      switch (a) {
        case true:
          return "Activo";
          break;
        case false:
          return "Inactivo";
          break;
        default:
          break;
      }
    },
    huellaD1() {
      //console.log("Huella");
    },
    async cargaRoles() {
      const response = await FBtraeRoles();
      this.roles = response.datos;
    },
    cargo(a) {
      const rolesConsulta = this.roles;

      for (const clave in rolesConsulta) {
        let valor = rolesConsulta[clave];

        if (valor === a) {
          return clave;
        }
      }

      return "Cargo desconocido";
    },
    limpia() {
      //console.log("detrurTodo");
    },
  },
  updated() {
    //console.log("updated");
  },
  destroyed() {
    //console.log("destroyed");
  },

  watch: {
    iconoCopy(val) {
      if (!val) return;
      setTimeout(() => {
        this.iconoCopy = false;
        this.cambioIcono(true);
      }, 4000);
    },
  },
};
</script>

<style scoped>
.border {
  border: 2px dashed orange;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
