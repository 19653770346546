<template>
  <div>
    <v-expansion-panels v-if="empleados.length > 0">
      <v-expansion-panel>
        <v-expansion-panel-header color="cyan darken-3">
          💰 Historial de pagos - TOTAL RECAUDADO
          {{ decimales(totalReacudado) }} vs Capital
          {{ decimales(titularActual.data.Monto) }} Total:
          {{ decimales(titularActual.data.Monto - totalReacudado) }}
        </v-expansion-panel-header>

        <v-expansion-panel-content
          v-for="(item, index, key) in titularActual.historialPagos"
          :key="key"
          class="blue-grey darken-4"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title
                >Pago Registrado {{ timeTodate(item.fecha) }}</v-list-item-title
              >
              <v-list-item-subtitle>
                📅 Fecha de

                <span v-if="item.pago.tipo == 'Abono'">Abono</span>
                <span v-else>Acuerdo</span>
                {{ timeTodate(item.pago.fecha) }}, realizó pago por: 💰
                {{ decimales(item.pago.monto) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Ejecutivo quien registró el pago:
                {{ ubicandoEjecutivo(item.usuario) }}
                <!-- buscaEjecutivo(item.usuario) -->
              </v-list-item-subtitle>
            </v-list-item-content>
            <div>
              <v-btn disabled color="red" fab x-small dark>
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </div>
          </v-list-item>
          <v-divider class="pa-1"></v-divider>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-progress-circular
      v-else
      indeterminate
      color="amber"
    ></v-progress-circular>
  </div>
</template>

<script>
import { JSdecimales } from "../../../../components/Cartera/validacionesGestionC";
import { FBLocalizarEjecutivo } from "../../../../components/RecursoHumano/listadoEmpleados";

export default {
  name: "gestionCartera",
  props: ["titularActual"],
  data: () => ({
    empleados: [],
    totalReacudado: 0,
  }),
  mounted() {
    //Ejecutamos la busqueda de Ejecutivos
    //console.log("Se ha montado Historial de pagos.");
    //console.log(this.empleados);
    //console.log(this.titularActual);
    this.buscaEjecutivoFB();
    this.totalRecaudado();
  },
  methods: {
    decimales(a) {
      //console.log(a);
      const decimalesLlega = JSdecimales(a);

      return decimalesLlega;
    },
    timeTodate(a, b) {
      //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.

      if (typeof a == "object" && a != null) {
        //console.log(a);
        let time = {
          seconds: a.seconds,
          nanoseconds: a.nanoseconds,
        };

        /* const fireBaseTime = new Date(
          time.seconds * 1000 + time.nanoseconds / 1000000
        );
        const date = fireBaseTime.toDateString();
        const atTime = fireBaseTime.toLocaleTimeString();
        //console.log("Es un Timespam");
        //console.log(date, atTime);
        const resultado = date + " / " + atTime;*/

        const fireBaseTime = new Date(time.seconds * 1000);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        //var convertida = fireBaseTime.toDateString();

        return fireBaseTime.toLocaleDateString("es-ES", options);
      } else if (typeof a == "string" && b == "pagos") {
        //this.fechaComporobantePago
        //pagos-431800506-063779341166.378000000.pdf
        let array1 = _.split(a, "-");
        //console.log("Fichas -------------------------------");
        //console.log(array1);
        const fireBaseTime = new Date(array1[0] * 1000 + array1[1] / 1000000);
        const date = fireBaseTime.toDateString();
        const atTime = fireBaseTime.toLocaleTimeString();
        //console.log("Es un Timespam");
        //console.log(date, atTime);
        const resultado = date + " / " + atTime;

        return resultado;
      } else {
        return a;
      }
    },
    async buscaEjecutivoFB() {
      const ejecutivoLlega = await FBLocalizarEjecutivo();
      //this.empleados.push(ejecutivoLlega.data.empleado);
      //console.log(ejecutivoLlega);
      this.empleados = ejecutivoLlega;
    },
    ubicandoEjecutivo(a) {
      //console.log("Llega: ", a);
      //console.log(this.empleados.length);

      var filtro = _.filter(this.empleados, (nombres) => {
        //console.log(nombres);
        return nombres.id === a;
      });

      //console.log(filtro[0].info.data.empleado);
      return filtro[0].info.data.empleado;
    },
    totalRecaudado() {
      this.totalReacudado = 0;

      this.titularActual.historialPagos.forEach((element) => {
        //console.log(element.pago.monto);
        this.totalReacudado =
          Number(element.pago.monto) + Number(this.totalReacudado);
      });
      //console.log(this.totalReacudado);
    },
  },
};
</script>

<style scoped>
</style>