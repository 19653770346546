<template>
  <v-card color="amber">
    <v-card-title>
      Procesos
      <v-divider class="mx-4" inset vertical></v-divider>
      <div class="caption">{{ traerTesto(filtro) }}:{{ desserts.length }}</div>
      <v-spacer></v-spacer>
      <!-- BOTONERA BARRA AMARILLA -->
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn color="secondary" @click="consultaDatosListado()" fab x-small dark>
        <v-icon>mdi-update</v-icon>
      </v-btn>
      <!-- 
      <agregarTarea @agregaItem="agregarItemF" />
      -->
    </v-card-title>

    <!-- ELIMINAR REGISTRO-->
    <v-dialog v-model="dialogDelete" max-width="500px" persistent dark>
      <v-card>
        <v-card-title>
          <h4>¿Seguro deseas eliminar el registro?</h4>

          <p>
            Se eliminará el registró y sus sub procesos. No se podrá reversar la
            opreación.
          </p>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteItemConfirm"
            :disabled="respuestaElimnina"
            >Si quiero eliminar
          </v-btn>
          <v-btn
            color="green"
            outlined
            text
            @click="closeDelete"
            :disabled="respuestaElimnina"
            >No quiero</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- INICIA TABLA -->
    <v-data-table
      v-if="desserts.length >= 0"
      dark
      :headers="headers"
      :items="desserts"
      :search="search"
      sort-by="cliente"
      class="elevation-1"
      fixed-header
      height="400px"
      :items-per-page="10"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        'items-per-page-text': 'products per page',
        itemsPerPageText: 'Filas por página:',
        itemsPerPageAll: 'Todos',
        prevPage: 'Anterior',
        nextPage: 'Siguiente',
        pageText: '{0}-{1} de {2}',
      }"
    >
      <template v-slot:top>
        <!-- EL MODAL -->
        <v-toolbar flat>
          <v-toolbar-title>Buscar</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- BUSCADOR -->
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar por cualquier item"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
          <!-- SELECT de CATEGORIA-->
          <v-select
            v-model="filtro"
            :items="items"
            label="Filtra por Procesos"
            dense
            outlined
            single-line
            hide-details
            max-height="10px"
            @change="consultaDatosListado()"
            item-text="title"
            item-value="panel"
          ></v-select>
        </v-toolbar>
      </template>

      <!-- LA TABLA -->
      <!-- Se debe hacer por item.clave-->

      <!-- PROXIMO CONTACTO -->
      <template v-slot:item.proxContacto="{ item }">
        <v-chip :color="getFecha(calculoFechaLlamar(item.proxContacto))" dark>
          {{ calculoFechaLlamar(item.proxContacto) }}
          <v-tooltip color="black" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ma-2" icon v-bind="attrs" v-on="on" x-small>
                <v-icon dark>{{ getLlamarIcon(item.llamar) }} </v-icon>
              </v-btn>
            </template>
            <span>{{ fechaConvertirFirebase(item.proxContacto) }}</span>
          </v-tooltip>
        </v-chip>
      </template>

      <!-- FECHA REGISTRO-->
      <template v-slot:item.timeR="{ item }">
        <v-tooltip color="black" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" dark>
              {{ calcularDiferenciaEnDias(item.timeR) }} dias
            </v-chip>
          </template>
          <span>{{ fechaConvertirFirebase(item.timeR) }}</span>
        </v-tooltip>
      </template>

      <!--  PRIORIDAD -->
      <template v-slot:item.prioridad="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :color="getPrioridad(item.prioridad)"
              v-bind="attrs"
              v-on="on"
              dark
            >
              {{ item.prioridad }}
            </v-chip>
          </template>
          <span></span>
        </v-tooltip>
      </template>

      <!-- APOYO -->
      <template v-slot:item.apoyo="{ item }">
        <Apoyos
          :datosApoyo="item.apoyo"
          :idRegistro="item.seguimientoId"
          :etapa="item.etapa"
          :todosLosDatos="item"
        />
      </template>

      <!-- Botones Actions-->
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-col cols="4">
            <FichaCliente :todosLosDatos="item" />
          </v-col>
          <v-col cols="4">
            <CambiarCategoria
              :idRegistro="item.seguimientoId"
              :etapa="item.etapa"
              @my-event="actualizaListado()"
            />
          </v-col>
          <v-col cols="4">
            <v-icon small @click="deleteItem(item)" color="red">
              mdi-trash-can
            </v-icon>
          </v-col>
        </v-row>

        <!-- Ficha cliente -->

        <!-- 
           <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        -->
      </template>

      <template v-slot:no-data>
        <v-btn
          color="black"
          @click="(filtro = 'prospeccion'), consultaDatosListado()"
        >
          No hay registros. Buscar en otra categoria.
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";

import agregarTarea from "./agregarTarea.vue";
import { FBconsultarProcesos } from "@/components/Ventas/consultaPanelProcesos.js";
import { FBconsultarEtapasProspectos } from "@/components/Ventas/consultas.js";
import Apoyos from "./dialogoApoyo.vue";
import FichaCliente from "../Clientes/fichaCliente.vue";
import CambiarCategoria from "./cambiarCategoria.vue";
import { FBeliminaOportunidad } from "@/components/Ventas/Tableros/eliminarOporutnidad.js";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      dialogDelete: false,
      headers: [
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "cliente",
        },
        { text: "Prox. Contacto", value: "proxContacto" },
        { text: "Proyecto", value: "proyecto" },
        { text: "Tiempo Transcurrido", value: "timeR" },
        { text: "Prioridad", value: "prioridad" },
        { text: "Apoyo", value: "apoyo" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      defaultItem: {
        cliente: "",
        proxContacto: 0,
        proyecto: 0,
        timeR: 0,
        apoyo: 0,
      },
      items: ["prospeccion"],
      consultaDatos: [],
      filtro: "prospeccion",
      diasLlamar: "",
      idDeletTrans: null, //Lo uso para eliminar el registro
      respuestaElimnina: false,
    };
  },
  components: {
    agregarTarea,
    Apoyos,
    FichaCliente,
    CambiarCategoria,
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.consultaDatosListado();
  },

  methods: {
    traerTesto(filtro) {
      const elementoEncontrado = this.items.find(
        (item) => item.panel === this.filtro
      );

      if (elementoEncontrado) {
        return elementoEncontrado.title;
      } else {
        return "Título desconocido..."; // O cualquier otro valor que desees mostrar cuando no hay coincidencia
      }
    },

    getLlamarIcon(llamar) {
      if (llamar) {
        return "mdi-calendar-clock-outline";
      } else {
        return "mdi-calendar-clock-outline";
      }
    },

    getFecha(textoLlega) {
      //console.log(textoLlega);
      if (textoLlega === null) {
        return "grey darken-4"; // Color predeterminado para null
      } else if (textoLlega === "Llamar") {
        return "red"; // Color para "Llamar"
      }

      const texto = `${textoLlega} día(s) faltante(s)`;
      const match = texto.match(/\d+/);
      const daysDiff = match ? parseInt(match[0]) : null;

      if (daysDiff === null) {
        return "grey darken-4"; // Color predeterminado para valores no numéricos
      }

      if (daysDiff >= 30) {
        return "blue darken-4"; // Mayor a 30 días
      } else if (daysDiff >= 20) {
        return "blue"; // De 20 a 30 días
      } else if (daysDiff >= 15) {
        return "cyan darken-3"; // De 15 a 19 días
      } else if (daysDiff >= 10) {
        return "cyan"; // De 10 a 14 días
      } else if (daysDiff >= 8) {
        return "yellow darken-2"; // De 8 a 9 días
      } else if (daysDiff >= 5) {
        return "orange darken-1"; // De 5 a 7 días
      } else if (daysDiff >= 3) {
        return "deep-orange darken-2"; // De 3 a 4 días
      } else if (daysDiff >= 2) {
        return "deep-orange darken-3"; // De 2 días
      } else if (daysDiff >= 1) {
        return "red lighten-2"; // De 1 día
      } else {
        return "red"; // Sin retraso
      }
    },

    getPrioridad(prioridad) {
      const bajo = 3;
      const medio = 2;
      const alto = 1;

      if (prioridad == "Bajo") {
        return "cyan"; // Mayor a 30 días
      } else if (prioridad == "Medio") {
        return "purple"; // De 20 a 30 días
      } else if (prioridad == "Alto") {
        return "red"; // De 15 a 19 días
      }
    },

    fechaConvertirFirebase(timestamp) {
      if (!timestamp || !timestamp.seconds) {
        return "Fecha inválida";
      }

      const fecha = new Date(timestamp.seconds * 1000);
      const fechaHoraLegible = fecha.toLocaleString();

      return fechaHoraLegible;
    },

    calculoFechaLlamar(fecha) {
      if (!fecha || !fecha.seconds) {
        return "Fecha inválida";
      }

      const fechaFormateada = moment.unix(fecha.seconds).toDate();
      const hoy = moment().startOf("day");
      const fecha2 = moment(fechaFormateada).startOf("day");

      const diffDays = fecha2.diff(hoy, "days");

      if (diffDays === 0) {
        return "Llamar";
      } else if (diffDays > 0) {
        return `${diffDays} día(s) faltante(s)`;
      } else {
        return `${-diffDays} día(s) vencido(s)`;
      }
    },

    calcularDiferenciaEnDias(fecha) {
      const today = new Date();
      const targetDate = new Date(fecha.seconds * 1000); // Convierte los segundos en milisegundos
      const timeDiff = Math.abs(targetDate - today);
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Diferencia en días

      return daysDiff;
    },

    actualizaListado() {
      this.consultaDatosListado();
    },

    async consultaDatosListado() {
      this.consultaDatos = [];
      this.desserts = [];

      const resultEtapas = await FBconsultarEtapasProspectos();
      this.items = resultEtapas;

      //console.log(this.filtro);
      const resultadoConsultaListado = await FBconsultarProcesos(this.filtro);

      //console.log(resultadoConsultaListado);

      if (resultadoConsultaListado[0]) {
        this.consultaDatos = resultadoConsultaListado[1];
        this.desserts = this.consultaDatos;
      } else {
        console.log("Falló la consulta del listado");
      }
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      //console.log(this.dialogDelete);
      this.dialogDelete = true;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.idDeletTrans = {
        id: item.seguimientoId,
        etapa: item.etapa,
      };
      //console.log(item);
    },

    async deleteItemConfirm() {
      //console.log(this.idDeletTrans);
      this.respuestaElimnina = true;
      const resultElimina = await FBeliminaOportunidad(this.idDeletTrans);
      //console.log(resultElimina);
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
      this.respuestaElimnina = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    agregarItemF(newItem) {
      this.desserts.push(newItem);
    },
  },
  beforeDestroy() {},
};
</script>
