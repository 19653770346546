<template>
  <div class="text-center">
    <marquee scrollamount="8" hspace="50" vspace="20" v-show="switch1">
      {{ estadoMensaje }}
    </marquee>

    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="rol === 'g'"
          class="mx-2"
          fab
          dark
          small
          color="black"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
        <v-icon
          class="mx-2"
          fab
          dark
          small
          color="black"
          v-bind="attrs"
          v-on="on"
          disabled
          v-else
        >
          mdi-pencil
        </v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Modificar mensaje
        </v-card-title>

        <v-card-text>
          El siguiente mensaje se mostrará en tiempo real. En caso de que desee
          ocultar el mensaje, mueva el interruptor.

          <v-textarea
            class="mt-5"
            name="input-7-1"
            label="Texto Actual"
            v-model="estadoMensaje"
            @input="modificarLetrero"
          ></v-textarea>

          <p>Switch</p>
          <v-switch
            v-model="switch1"
            :label="texto()"
            @change="modificarLetrero"
          ></v-switch>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "../../firebase/config";

export default {
  name: "letrero",
  props: ["rol"],
  data() {
    return {
      dialog: false,
      switch1: true,
      estadoMensaje: "",
      newMensaje: "",
    };
  },
  methods: {
    async montarLetrero() {
      try {
        const mensajeRef = db.collection("recursos").doc("mensajes");

        const observer = mensajeRef.onSnapshot((docSnapshot) => {
          this.estadoMensaje = docSnapshot.data().mensajes.home;
          this.switch1 = docSnapshot.data().mensajes.estado;
        });
      } catch (error) {
        console.error("error: " + error);
      }
    },
    async modificarLetrero() {
      try {
        const dato = {
          mensajes: { home: this.estadoMensaje, estado: this.switch1 },
        };
        const mensajeRef = db.collection("recursos").doc("mensajes");
        await mensajeRef.update(dato);
      } catch (error) {
        console.log(error);
      }
    },
    texto() {
      return this.switch1 ? "Mostrando mensaje." : "Ocultando mensaje.";
    },
  },
  mounted() {
    this.montarLetrero();
  },
};
</script>

<style scoped>
marquee {
  width: 80%;
  color: #ffc107;
}
</style>
