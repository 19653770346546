import { firebase, db, storage } from "../../../firebase/config"
import store from "../../../store";

const ref = storage.ref();

//
export async function FBverRegistros(ejecutivo) {
    let data = [];
    const fecha = new Date();

    try {
        ///cf-recursoHumano/RecursoHumano/Empleados/
        // /cf-cartera/Asignaciones/idRegistroDeEmpleados
        //  0N27BVAKtfhnaOsB8pv2  -Fantasma   --  1sl11rzdWQRdD9M4ZokJ Yobani
        const clientesRef = db.collection('cf-cartera').doc('Asignaciones').collection(ejecutivo);//.where('toques', '>=', 1);
        const snapshot = await clientesRef.get()//.where('data.estado', '==', true).get();   .limit(10)
        //.orderBy('fechaRegistro', 'desc')

        if (snapshot.empty) {
            //console.log('No matching documents.');
            //False no existe
            //return false;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            data.push(doc.data());
        });

        //console.log(data);

        let gfg = _.orderBy(data, 'fechaRegistro',
            'desc');

        //console.log(gfg);

        //True es existe
        return gfg;
        //console.log('Termina');

    } catch (error) {
        console.log(error);
        return false;
    }

}

export async function FBsaveHistorial(jsonHistorial) {
    try {
        //console.log(jsonHistorial);
        jsonHistorial.fechaRegistro = firebase.firestore.Timestamp.now();
        //var fechaRegistro = firebase.firestore.Timestamp.now();
        // /cf-cartera/Historial
        const res = await db.collection('cf-cartera').doc('Historial').collection(jsonHistorial.ejecutivo).add(jsonHistorial);

        return true;

    } catch (error) {
        //console.log(error);
        return false;
    }
}

export async function FBconsultaListadoEjecutivos() {
    //console.log('Consultar listado de Ejecutivos');
    try {
        let ejecutivos = [];

        const snapshot = await db.collection('cf-recursoHumano')
            .doc('RecursoHumano')
            .collection('Empleados')
            .where('data.cargo', '==', 'acm').where('data.estado', '==', true).get();

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            ejecutivos.push({
                id: doc.id,
                datos: doc.data()
            })
        });


        return ejecutivos;
        //return true;

    } catch (error) {
        //console.log(error);
        return false;
    }
}

export async function FBconsultaMontosAltosActuales(idEjecutivo) {
    try {
        let montoActuales = [];
        let crearMontos = false;
        let montos = {
            Altos: 0,
            Bajos: 0,
            estado: true
        }
        ///cf-cartera/Estrategia/g5XK9n5Fdche1oHnYM6x/carga
        const estrategiaRef = db.collection('cf-cartera').doc('Estrategia').collection(idEjecutivo).doc('carga');
        const doc = await estrategiaRef.get();
        if (!doc.exists) {
            //console.log('No such document!');
            //Como no existe, vamos a gregar la variables básicas.
            crearMontos = true;
        } else {
            //console.log('Document data:', doc.data());
            if (doc.data().montos == undefined) {
                crearMontos = true;
            } else {
                crearMontos = false;
            }
        }

        if (crearMontos == true) {
            //1. Crear montos
            const montosRef = db.collection('cf-cartera').doc('Estrategia').collection(idEjecutivo).doc('carga');
            const montosDoc = await montosRef.update({ montos: montos });

            //console.log('Se creó');

        } else {
            //console.log('Ya existe');
            //1. Consultamos los montos actuales
            const estrActualRef = db.collection('cf-cartera').doc('Estrategia').collection(idEjecutivo).doc('carga');
            const montosActDoc = await estrActualRef.get();

            if (!montosActDoc.exists) {
                //console.log('No such document!');
                //Como no existe, vamos a gregar la variables básicas.

            } else {
                //montos
                //console.log('Document data:', montosActDoc.data());
                montoActuales.push(montosActDoc.data());
            }

        }

        return montoActuales;

    } catch (error) {
        console.log(error);
    }
}

export async function FBactualizaMontos(eje, ma, mb, nitAsesorActual) {
    try {
        //console.log(eje, ma, mb);
        let montos = {
            Altos: Number(ma),
            Bajos: Number(mb),
            cliente: String(nitAsesorActual),
            estado: true
        }

        const montosRef = db.collection('cf-cartera').doc('Estrategia').collection(eje).doc('carga');
        const montosDoc = await montosRef.update({ montos: montos });

        //console.log(montosDoc);

        return true;

    } catch (error) {
        console.log(error);
    }
}