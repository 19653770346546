<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="700" persistent scrollable dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber"
          v-bind="attrs"
          v-on="on"
          @click="consultaRegistro"
          icon
          outlined
        >
          <v-tooltip color="orange" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-eye-outline</v-icon>
            </template>
            <span>Ver Proceso</span>
          </v-tooltip>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 amber">
          Apoyo - {{ totalDatos.cliente }}
        </v-card-title>

        <v-card-text v-if="!isUpdating">
          <v-row class="mt-3">
            <v-col cols="3">
              <v-chip class="ma-2" color="orange" label outlined>
                Etapa: {{ totalDatos.etapa }}
              </v-chip>
            </v-col>
            <v-col cols="6">
              <v-chip class="ma-2" color="orange" label outlined>
                Asignado:
                {{
                  totalDatos.colaborador === "N/A"
                    ? totalDatos.colaborador
                    : value
                }}
              </v-chip>
            </v-col>
            <v-col cols="3">
              <v-chip class="ma-2" color="orange" label outlined>
                Prioridad: {{ totalDatos.prioridad }}
              </v-chip>
            </v-col>
          </v-row>

          <v-row class="mt-n8">
            <v-col cols="12">
              <div class="ma-2" color="orange" label outlined>
                <div class="body-2 mt-5 custom-div pa-3">
                  <span class="font-weight-bold"> Requerimiento:</span>
                  <br /><br />
                  {{ totalDatos.observacion }}
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                color="orange"
                outlined
                name="input-7-4"
                label="Observación | Gestión"
                v-model="observacion"
                @input="capturarEnlaces"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="mt-n10">
            <v-col cols="12">
              Tus links: {{ enlaces.length }}
              <!-- Mostrar los enlaces capturados -->
              <ul class="pa-2" v-if="enlaces.length > 0">
                <li
                  class="custom-div pa-1"
                  v-for="(enlace, index) in enlaces"
                  :key="index"
                  style="margin-bottom: 10px"
                >
                  {{ enlace }}

                  <v-tooltip color="orange" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="orange"
                        v-bind="attrs"
                        v-on="on"
                        @click="abrirEnlace(enlace)"
                      >
                        <v-icon>mdi-link</v-icon>
                      </v-btn>
                    </template>
                    <span>Prueba el link de tu contenido</span>
                  </v-tooltip>
                </li>
              </ul>
            </v-col>
          </v-row>

          <!-- v-if="todosLosDatos.colaborador === 'N/A'" -->
          <v-row class="mt-n5">
            <v-col>
              <v-autocomplete
                color="orange"
                v-model="value"
                :items="items"
                item-text="empleado"
                dense
                filled
                label="Asignar a..."
                small-chips
                multiple
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row class="mt-n5">
            <v-col>
              <v-btn color="amber" text outlined block @click="guardarDatos">
                Guardar
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="amber mt-3"></v-divider>
          <p>Gestiones: {{ totalDatos.procesos ? totalDatos.procesos.length : 0 }}</p>

          <v-row class="mt-5">
            <v-col cols="12">
              <div
                v-if="
                  totalDatos.procesos !== undefined &&
                  totalDatos.procesos.length > 0
                "
              >
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(item, index) in totalDatos.procesos"
                    :key="index"
                  >
                    <v-divider class="amber"></v-divider>
                    <v-expansion-panel-header>
                      Proceso: {{ index + 1 }}</v-expansion-panel-header
                    >

                    <v-expansion-panel-content>
                      {{ item.contenido }}

                      <div v-if="item.enlaces && item.enlaces.length > 0">
                        <v-divider></v-divider>

                        <ul class="pa-2">
                          <li
                            class="custom-div pa-1"
                            v-for="(url, index) in item.enlaces"
                            :key="index"
                            style="margin-bottom: 10px"
                          >
                            {{ index + 1 }}: {{ url }}
                            <v-tooltip color="orange" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  color="orange"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="abrirEnlace(url)"
                                >
                                  <v-icon>mdi-link</v-icon>
                                </v-btn>
                              </template>
                              <span>Prueba el link del contenido</span>
                            </v-tooltip>
                          </li>
                        </ul>
                      </div>

                      <v-divider class="mt-5"></v-divider>
                      <br />
                      Usuario que gestionó: {{ item.usuario }}
                      <br />
                      Registro: {{ fechaConvertirFirebase(item.registro) }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <div v-else>No hay observaciones o gestiones</div>
            </v-col>
          </v-row>

          <!-- {{ todosLosDatos }} -->
        </v-card-text>

        <!-- CARGADOR DE CAMBIOS -->
        <v-card v-else>
          <v-card-title class="text-h5" color="transparent">
            {{ respuesta }}
          </v-card-title>

          <v-progress-linear indeterminate color="amber"></v-progress-linear>
        </v-card>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="amber" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FBagregaProcesoApoyo } from "@/components/Ventas/Tableros/desarrolloApoyo.js";
import { FBconsultaEmpleados } from "@/components/Ventas/Tableros/consultasFichaApoyo.js";
import { FBconsultaProceso } from "@/components/Ventas/Tableros/consultaProcesoFichaApoyo.js";
import store from "../../../store";

export default {
  props: [ "todosLosDatosReales", "todosLosDatos", "seguimientoId", "posicion"],
  data() {
    return {
      isUpdating: false,
      empleadoRegistra: store.state.user[2][0].data.empleado,
      dialog: false,
      respuesta: "...",
      items: [], //autocomplete
      //values: ["foo", "bar"], //autocomplete
      value: [], // Asegúrate de que la propiedad 'value' esté declarada y se inicialice con un valor válido
      observacion: "",
      enlaces: [],
      totalDatos: [],
      idDocumento: "",
    };
  },
  created() {
    this.iniciar();
  },

  methods: {
    async iniciar() {
      this.totalDatos = this.todosLosDatos;

      const empleados = await FBconsultaEmpleados();

      this.items = empleados;

      //console.log(this.items);
      // Variable que quieres verificar
      const variable = this.todosLosDatos.colaborador;

      // Verificar si la variable es un string
      if (typeof variable === "string") {
        // La variable es un string, convertir a un array utilizando split()
        this.value = variable.split(","); // Puedes especificar el separador según tus necesidades

        //console.log("La variable convertida a array:", this.value);
      } else {
        this.value = variable;
        // La variable no es un string, no se realiza ninguna conversión
        //console.log("La variable no es un string:", variable);
      }
    },

    async consultaRegistro() {
      const json = {
        seguimientoId: this.seguimientoId,
        posicion: this.posicion,
        departamento: this.todosLosDatos.departamento,
      };

      const result = await FBconsultaProceso(json);

      //console.log(result);

      if (result[0]) {
        this.totalDatos = result[1].documento;
        this.idDocumento = result[1].idDocumento;
        //console.log(this.totalDatos);
      } else {
      }
    },

    fechaConvertirFirebase(timestamp) {
      if (!timestamp || !timestamp.seconds) {
        return "Fecha inválida";
      }

      const fecha = new Date(timestamp.seconds * 1000);
      const fechaHoraLegible = fecha.toLocaleString();

      return fechaHoraLegible;
    },

    async guardarDatos() {
      if (this.observacion.trim().length === 0) {
        return;
      }

      //console.log(this.totalDatos);

      this.isUpdating = true;
      this.respuesta = "Procesando...";

      const json = {
        obserbacion: this.observacion,
        departamento: this.totalDatos.departamento,
        idTarea: this.idDocumento,
        idSeguimiento: this.totalDatos.seguimientoId,
        usuarioRegistra: this.empleadoRegistra,
        enlaces: this.enlaces,
        posicion: this.totalDatos.posicion,
      };

      if (this.value !== undefined && this.value.length !== 0) {
        json.asignado = this.value;
      }

      if (
        !json.asignado ||
        json.asignado.length === 0 ||
        (json.asignado.length === 1 && json.asignado[0] === "N/A")
      ) {
        json.asignado = ["N/A"];
        this.value = ["N/A"];
      } else if (json.asignado.includes("N/A")) {
        const nuevoArray = json.asignado.filter(
          (elemento) => elemento !== "N/A"
        );
        json.asignado = nuevoArray;
        this.value = nuevoArray;
      }

      const fechaHoy = await this.fechaHoy();

      console.log(json);

      const resultAgrega = await FBagregaProcesoApoyo(json);

      if (resultAgrega) {

        // totalDatos.procesos  -> Se debe actualizar 
        this.respuesta = "Proceso agregado exitosamente";

        // Verificar si this.todosLosDatos.procesos es undefined o no existe
        if (!this.todosLosDatos.procesos) {
          // Si no existe, crear el arreglo procesos
          this.todosLosDatos.procesos = [];
        }

        // Realizar el push al arreglo procesos
       /* this.todosLosDatos.procesos.push({
          enlaces: json.enlaces,
          contenido: json.obserbacion,
          usuario: this.empleadoRegistra,
          registro: fechaHoy,
        });*/

        this.totalDatos.procesos.push({
          enlaces: json.enlaces,
          contenido: json.obserbacion,
          usuario: this.empleadoRegistra,
          registro: fechaHoy,
        });

        // Retrasar la desactivación de la bandera y la eliminación de la respuesta
        setTimeout(() => {
          this.isUpdating = false;
          this.respuesta = "";
          this.observacion = "";
        }, 2000); // Retraso de 2 segundos (ajusta el valor según tus necesidades)
      } else {
        this.respuesta = "Error al agregar el proceso, no se guardó.";

        // Retrasar la desactivación de la bandera y la eliminación de la respuesta
        setTimeout(() => {
          this.isUpdating = false;
          this.respuesta = "";
          this.observacion = "";
        }, 2000); // Retraso de 2 segundos (ajusta el valor según tus necesidades)
      }
    },

    capturarEnlaces() {
      const expresionRegular = /(https?:\/\/\S+)/g;
      const matches = this.observacion.match(expresionRegular);

      if (matches) {
        this.enlaces = matches;
      } else {
        this.enlaces = [];
      }
    },

    abrirEnlace(enlace) {
      window.open(enlace, "_blank");
    },

    async fechaHoy() {
      // Obtén la fecha actual
      const fechaActual = new Date();

      // Obtiene los componentes de la fecha
      const dia = fechaActual.getDate();
      const mes = fechaActual.getMonth() + 1; // Los meses en JavaScript son base 0, por lo que se suma 1 al valor
      const anio = fechaActual.getFullYear();
      const horas = fechaActual.getHours();
      const minutos = fechaActual.getMinutes();
      const segundos = fechaActual.getSeconds();

      // Crea un nuevo objeto de fecha con los componentes
      const fechaTimestamp = new Date(
        anio,
        mes - 1,
        dia,
        horas,
        minutos,
        segundos
      );

      // Crea el objeto de timestamp de Firestore
      const timestamp = {
        seconds: Math.floor(fechaTimestamp.getTime() / 1000),
        nanoseconds: fechaTimestamp.getMilliseconds() * 1000000,
      };

      return timestamp;
    },
  },
};
</script>

<style scoped>
.custom-div {
  border-radius: 10px; /* Ajusta el valor según el redondeo deseado */
  background-color: #424242; /* Cambia el color de fondo según tus preferencias */
}
</style>