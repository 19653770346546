<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="700" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange lighten-2"
          text
          v-bind="attrs"
          v-on="on"
          @click="inicializar"
        >
          Categorias
        </v-btn>
      </template>

      <!-- INICIA TARJETA -->
      <v-card>
        <v-card-title class="text-h5 amber bg-black">
          Administra tus Categorias
        </v-card-title>

        <v-card-text v-if="!estadoProceso">
          <!-- CATEGORIA -->
          <v-row
            class="mt-5 text-center yellow lighten-5 text-no-wrap rounded-xl elevation-1"
          >
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="text-left"
              ><h3>Crear Categoria</h3></v-col
            >
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                color="black"
                v-model="product.nombre"
                label="Nombre Nueva Categoria"
                filled
                rounded
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mt-2">
              <v-btn outlined> Agregar Categoria </v-btn>
            </v-col>
          </v-row>
          <!-- END CATEGORIA -->


           <!-- CATEGORIA -->
           <v-row
            class="mt-5 text-center yellow lighten-5 text-no-wrap rounded-xl elevation-1"
          >
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="text-left"
              ><h3>Crear Categoria</h3></v-col
            >
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                color="black"
                v-model="product.nombre"
                label="Nombre Nueva Categoria"
                filled
                rounded
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mt-2">
              <v-btn outlined> Agregar Categoria </v-btn>
            </v-col>
          </v-row>
          <!-- END CATEGORIA -->
          

          <!-- FORMULARIO AGREGA PRODUCTO -->
          <v-form ref="form" v-model="valid" @submit.prevent="submit">
            <v-row class="mb-6 mt-3">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Listado Categorias -->
                <v-select
                  color="black"
                  :items="categorias"
                  v-model="product.categoria"
                  item-text="nombre"
                  :label="'Categorías / ' + categorias?.length + ' en total'"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- URL Imagen Categoria -->
                <v-text-field
                  color="black"
                  v-model="product.urlCategoria"
                  label="Url Categoria"
                ></v-text-field>
                <v-btn block>
                  {{ urlCategoria ? "Actualizar imagen" : "Asingar imagen" }}
                </v-btn>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Imagen Categoria -->
                <v-img
                  lazy-src="https://firebasestorage.googleapis.com/v0/b/bybabogados-138f2.appspot.com/o/st-catalogo%2Fcategorias%2Fcategoria-1.jpg?alt=media&token=7e2930a3-3c40-4092-a2d1-b33f9bfc8bff"
                  max-height="200"
                  max-width="200"
                  :src="
                    urlCategoria
                      ? urlCategoria
                      : 'https://img.freepik.com/vector-gratis/ups-error-404-ilustracion-concepto-robot-roto_114360-5529.jpg?w=1480&t=st=1692042301~exp=1692042901~hmac=df5d042f15c976721852f8a492f514e312c64af17cd468ba4422b319ce9b8a42'
                  "
                ></v-img>
              </v-col>
            </v-row>

            <v-divider class="mt-10"></v-divider>

            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Nombre Sub Categoria -->
                <v-text-field
                  color="black"
                  v-model="product.subcategoria"
                  label="Nombre Nueva Sub Categoria"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Sub Categoria -->
                <v-select
                  color="black"
                  :items="filteredSubCategorias"
                  v-model="product.subCategoria"
                  item-text="nombre"
                  :label="
                    'Sub. Categorías / ' +
                    filteredSubCategorias.length +
                    ' en total'
                  "
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Nombre Fabricantes -->
                <v-text-field
                  color="black"
                  v-model="product.fabricante"
                  item-text="nombre"
                  label="Nombre fabricante"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Fabricante -->
                <v-select
                  color="black"
                  :items="fabricantes"
                  v-model="product.fabricante"
                  item-text="nombre"
                  :label="'Fabricante(s) / ' + fabricantes.length"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Nombre Tienda -->
                <v-text-field
                  color="black"
                  v-model="product.tienda"
                  label="Nombre tienda"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-n10">
                <!-- Estado Producto  -->
                <v-select
                  color="black"
                  :items="estado"
                  v-model="product.estadoTienda"
                  item-text="etiqueta"
                  item-value="valor"
                  label="Estado Tienda"
                ></v-select>
              </v-col>
            </v-row>

            <!-- ... añade los otros campos de texto aquí ... -->

            <!-- Puedes usar v-file-input para subir imágenes si es necesario -->
            <v-file-input
              multiple
              color="black"
              v-model="product.imageFile"
              label="Imagen"
              placeholder="Sube las imágenes.."
              prepend-icon="mdi-paperclip"
              @change="previewFiles"
              counter
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="amber">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <!-- Aquí mostramos las imágenes en miniatura -->
            <div
              v-for="(url, index) in imagePreviews"
              :key="url.img"
              class="d-flex align-center"
            >
              <v-badge :content="index + 1" color="black" overlap> </v-badge>
              <v-img
                :src="url.img"
                max-height="120"
                max-width="120"
                class="rounded-xl ml-3"
              ></v-img>

              <span class="ml-2">{{ url.name }}</span>
              <!-- añadido un margen a la izquierda para dar espacio entre la imagen y el texto -->
            </div>

            <v-divider class="mt-3"></v-divider>

            <div class="d-flex justify-center align-center" style="height: 5vh">
              <v-btn
                color="black"
                block
                text
                outlined
                @click="submit"
                class="mt-5"
                >Añadir Producto</v-btn
              >
            </div>
          </v-form>
        </v-card-text>

        <v-card-text v-else>
          {{ proceso }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions style="background-color: black">
          <v-spacer></v-spacer>
          <v-btn color="amber" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
      
   
  
<script>
import { consultaCategoriasFB } from "@/components/Mercadeo/Catalogo/categorias.js";

export default {
  data() {
    return {
      dialog: false,
      valid: true,
      product: {
        nombre: "",
        descripcion: "",
        base_price: null,
        categoria: "",
        fabricante: "",
        tienda: "",
        estadoTienda: false,
        imageFile: null,
      },
      imagePreviews: [],
      categorias: ["cargando categorias..." /*...otros valores...*/],
      fabricantes: ["cargando fabricantes..." /*...otros valores...*/],
      subCategorias: ["cargando subcategoria" /*...otros valores...*/],
      estado: [
        { etiqueta: "Activo", valor: true },
        { etiqueta: "No Disponible", valor: false },
      ],
      proceso: "",
      estadoProceso: false,
      filteredSubCategorias: [],
      urlCategoria: "",
    };
  },

  methods: {
    async inicializar() {
      const result = await consultaCategoriasFB();

      console.log(result);

      this.categorias = result[1].categorias;
      this.product.estadoTienda = result[1].estado;
      this.subCategorias = result[1].subcategorias;
      this.fabricantes = result[1].fabricantes;
      this.product.tienda = result[1].titulo;
    },

    previewFiles() {
      this.imagePreviews = [];
      for (let file of this.product.imageFile) {
        //console.log(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.imagePreviews.push({ img: e.target.result, name: file.name });
        };
      }
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.estadoProceso = true;

      this.proceso = "Procesando Solicitud";

      const resultImg = await subirImageFB(this.product);

      if (resultImg[0]) {
        this.proceso =
          "Se han guardado las imágene son éxito. Procesando Producto...";
        this.product.imagen = resultImg[1];

        setTimeout(async () => {
          //Ahora subir datos
          const resultDatos = await subirRegistroFB(this.product);

          if (resultDatos[0]) {
            //console.log(resultDatos[1]);
            this.proceso = "Se el Producto, de manera exitosa.";

            setTimeout(() => {
              this.proceso = "";
              this.estadoProceso = false;
              this.reiniciarFormulario();
            }, 3000);
          } else {
            //console.log(resultDatos[1]);
            this.proceso = "Error al crear el producto.";

            setTimeout(() => {
              this.proceso = "";
              this.estadoProceso = false;
              this.reiniciarFormulario();
            }, 3000);
          }
        }, 3000); // 3000 milisegundos equivalen a 3 segundos
      } else {
        this.proceso = "Error guardar las imágenes.";

        setTimeout(() => {
          this.proceso = "";
          this.estadoProceso = false;
          this.reiniciarFormulario();
        }, 3000);
      }
    },

    reiniciarFormulario() {
      (this.product = {
        nombre: "",
        descripcion: "",
        base_price: null,
        categoria: "",
        fabricante: "",
        imageFile: null,
      }),
        (this.imagePreviews = []);
    },
  },

  watch: {
    "product.categoria": function (newVal) {
      console.log(newVal);

      if (newVal) {
        this.filteredSubCategorias = this.subCategorias.filter(
          (subCat) => subCat.categoria === newVal
        );

        let categoria = this.categorias.filter(
          (subCat) => subCat.nombre === newVal
        );

        this.urlCategoria = categoria[0].url ? categoria[0].url : "";
      } else {
        this.filteredSubCategorias = [];
      }
    },
  },
};
</script>
  