import { firebase, db, storage } from "../../firebase/config"
const ref = storage.ref();

export async function FBcedulaCambio(datos, nuevoDato) {

    /*console.log('Corregir cedula');

    console.log(nuevoDato);
    console.log(datos.nitCliente);
    console.log(datos.idCartera);
    console.log(datos.idRegistro);
    console.log(datos.data.Cedula);
    console.log(datos.asignadoActual[0]);*/

    //1. Modificar la cedula

    try {
        //1. Modificar la cédula en la base de datos original
        const clientesRef = db.collection('cf-cartera').doc('Carteras').collection(datos.nitCliente).doc(datos.idCartera).collection('registros').doc(datos.idRegistro);

        const doc = await clientesRef.update({ 'data.Cedula': Number(nuevoDato) });

        //2. Modificar la cédula en la bolsa del ejecutivo
        const bolsaEjecutivoRef = db.collection('cf-cartera').doc('Asignaciones').collection(datos.asignadoActual[0]).where('Cedula', '==', datos.data.Cedula);
        const snapshot = await bolsaEjecutivoRef.get();
        if (snapshot.empty) {
            //console.log('No matching documents.');
            return;
        }

        snapshot.forEach(async doc => {
            //console.log(doc.id, '=>', doc.data());

            const itemEnLaBolsa = db.collection('cf-cartera').doc('Asignaciones').collection(datos.asignadoActual[0]).doc(doc.id);
            const actualizar = await itemEnLaBolsa.update({ 'Cedula': Number(nuevoDato) });

        });


        //True es existe
        return true;

    } catch (error) {
        console.log(error);
    }


    return 2
}

//Solo muestra el listado de Ejecutivos
export async function FBListadoEjecutivos() {
    //console.log('Listado Ejecutivos');
    try {

        let datosEjecutivos = [];
        let nombres = [];
        const citiesRef = db.collection('cf-recursoHumano').doc('RecursoHumano').collection('Empleados').where('data.estado', '==', true);
        const snapshot = await citiesRef.where('data.cargo', '==', 'acm').get();
        if (snapshot.empty) {
            console.log('No matching documents.');
            return;
        }

        snapshot.forEach(doc => {
            //console.log(doc.id, '=>', doc.data());
            //console.log(doc.id);
            datosEjecutivos.push({ idEjecutivo: doc.id, nombre: doc.data().data.empleado })
            nombres.push(doc.data().data.empleado)
        });

        return [datosEjecutivos, nombres];
    } catch (error) {
        console.log(error);
    }
}

export async function FBcambiarEjecutivo(datos, antiguoEjecutivo, nuevoEjecutivo) {
    try {
        /*console.log('Inicia Cambio Ejecutivo');
        console.log(datos);
        console.log(antiguoEjecutivo);
        console.log(nuevoEjecutivo);
        console.log(datos.data.Cedula);*/
        let antEjecutivo = String(antiguoEjecutivo[0].idEjecutivo);
        let nueEjecutivo = nuevoEjecutivo[0].idEjecutivo;
        let cedulaTT = Number(datos.data.Cedula);
        let existe = false;//
        let idRegistroBolsaTitular = '';

        //console.log('Validar si es el mismo: ',);

        if (antEjecutivo != nueEjecutivo) {
            //1. Validar que el titular esté en la bolsa del ejecutivo antiguo, si está eliminarlo
            const titularBolsaEjecutivoRef = db.collection('cf-cartera').doc('Asignaciones').collection(antEjecutivo).where('Cedula', '==', cedulaTT);
            const doc = await titularBolsaEjecutivoRef.get();
            if (doc.empty) {
                //console.log('No existe titular en la bolsa del ejecutivo antiguo.');
                existe = false;
                //return;
            }
            doc.forEach(async doc => {
                //console.log(doc.id, '=>', doc.data());
                idRegistroBolsaTitular = doc.id;
                existe = true;
            })

            //Eliminar.
            if (existe == true) {
                const titularBolsaEjecutivoDet = db.collection('cf-cartera')
                    .doc('Asignaciones')
                    .collection(antEjecutivo)
                    .doc(idRegistroBolsaTitular);

                const resEliminar = await titularBolsaEjecutivoDet.delete();
            } else {
                //console.log('No se eliminó porque no existe!');
            }

            //2. Luego, ubicar el titular en la cartera y actualiza al nuevoEjecutivo asignado
            ///cf-cartera/Carteras/900/obVcuaRgT3xdtTRvThjN/registros/3QM5zgulPo9MOVgVvY4Z
            const itemEnLaBolsa = db.collection('cf-cartera').doc('Carteras').collection(datos.nitCliente).doc(datos.idCartera).collection('registros').doc(datos.idRegistro);
            const actualizaTTEjecutivo = await itemEnLaBolsa.update({ asignadoActual: [nueEjecutivo] });

            //console.log(actualizaTTEjecutivo);

            //3. Agregar el titular a la bolsa de ejecutivo nuevo
            const datoAlaBolsa = {
                Cedula: cedulaTT,
                idCartera: datos.idCartera,
                idRegistro: datos.idRegistro,
                nitCliente: datos.nitCliente,
                toques: 0
            };
            ///cf-cartera/Asignaciones/g5XK9n5Fdche1oHnYM6x/wKstlpbW1wTcWrJNa62s
            //console.log(datoAlaBolsa);
            //console.log('Agrega a la bolsa del nuevo ejecutivo y finaliza');
            const res = await db.collection('cf-cartera').doc('Asignaciones').collection(nueEjecutivo).add(datoAlaBolsa);

            //4. Finalizar el proceso.
            return [true, 'Cambio exitoso'];
        } else {
            //console.log('Es el mismo Ejecutivo.');
            return [true, 'Es el mismo ejecutivo'];
        }
    } catch (error) {
        //console.log(error);
        return [false, 'Error'];
    }
}

