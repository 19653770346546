export function fechaseInteres() {

    const fecha1 = [
        {
            fecha: '2011-1-1',//Enero 2011 --------------------------------------------
            interes: 23.42 / 100
        },
        {
            fecha: '2011-2-1',//Febrero 2011
            interes: 23.42 / 100
        },
        {
            fecha: '2011-3-1',//Marzo 2011
            interes: 23.42 / 100
        },
        {
            fecha: '2011-4-1',//Abril 2011
            interes: 26.54 / 100
        },
        {
            fecha: '2011-5-1',//Mayo 2011
            interes: 26.54 / 100
        },
        {
            fecha: '2011-6-1',//Junio 2011
            interes: 26.54 / 100
        },
        {
            fecha: '2011-7-1',//Julio 2011
            interes: 27.95 / 100
        },
        {
            fecha: '2011-8-1',//Agosto 2011
            interes: 27.95 / 100
        },
        {
            fecha: '2011-9-1',//Septiembre 2011
            interes: 27.95 / 100
        },
        {
            fecha: '2011-10-1',//Octubre 2011
            interes: 29.09 / 100
        },
        {
            fecha: '2011-11-1',//Noviembre 2011
            interes: 29.09 / 100
        },
        {
            fecha: '2011-12-1',//Diciembre 2011
            interes: 29.09 / 100
        },
        {
            fecha: '2012-1-1',//Enero 2012 -------------------------------------------------------------
            interes: 29.88 / 100
        },
        {
            fecha: '2012-2-1',//Febrero 2012
            interes: 29.88 / 100
        },
        {
            fecha: '2012-3-1',//Marzo 2012
            interes: 29.88 / 100
        },
        {
            fecha: '2012-4-1',//Abril 2012
            interes: 30.78 / 100
        },
        {
            fecha: '2012-5-1',//Mayo 2012
            interes: 30.78 / 100
        },
        {
            fecha: '2012-6-1',//Junio 2012
            interes: 30.78 / 100
        },
        {
            fecha: '2012-7-1',//Julio 2012
            interes: 31.29 / 100
        },
        {
            fecha: '2012-8-1',//Agosto 2012
            interes: 31.29 / 100
        },
        {
            fecha: '2012-9-1',//Septiembre 2012
            interes: 31.29 / 100
        },
        {
            fecha: '2012-10-1',//Octubre 2012
            interes: 31.34 / 100
        },
        {
            fecha: '2012-11-1',//Noviembre 2012
            interes: 31.34 / 100
        },
        {
            fecha: '2012-12-1',//Diciembre 2012
            interes: 31.34 / 100
        },
        {
            fecha: '2013-1-1',//Enero 2013 -------------------------------------------------------------
            interes: 31.13 / 100
        },
        {
            fecha: '2013-2-1',//Febrero 2013
            interes: 31.13 / 100
        },
        {
            fecha: '2013-3-1',//Marzo 2013
            interes: 31.13 / 100
        },
        {
            fecha: '2013-4-1',//Abril 2013
            interes: 31.25 / 100
        },
        {
            fecha: '2013-5-1',//Mayo 2013
            interes: 31.25 / 100
        },
        {
            fecha: '2013-6-1',//Junio 2013
            interes: 31.25 / 100
        },
        {
            fecha: '2013-7-1',//Julio 2013
            interes: 30.51 / 100
        },
        {
            fecha: '2013-8-1',//Agosto 2013
            interes: 30.51 / 100
        },
        {
            fecha: '2013-9-1',//Septiembre 2013
            interes: 30.51 / 100
        },
        {
            fecha: '2013-10-1',//Octubre 2013
            interes: 29.78 / 100
        },
        {
            fecha: '2013-11-1',//Noviembre 2013
            interes: 29.78 / 100
        },
        {
            fecha: '2013-12-1',//Diciembre 2013
            interes: 29.78 / 100
        },
        {
            fecha: '2014-1-1',//Enero 2014 -------------------------------------------------------------
            interes: 29.48 / 100
        },
        {
            fecha: '2014-2-1',//Febrero 2014
            interes: 29.48 / 100
        },
        {
            fecha: '2014-3-1',//Marzo 2014
            interes: 29.48 / 100
        },
        {
            fecha: '2014-4-1',//Abril 2014
            interes: 29.45 / 100
        },
        {
            fecha: '2014-5-1',//Mayo 2014
            interes: 29.45 / 100
        },
        {
            fecha: '2014-6-1',//Junio 2014
            interes: 29.45 / 100
        },
        {
            fecha: '2014-7-1',//Julio 2014
            interes: 29.00 / 100
        },
        {
            fecha: '2014-8-1',//Agosto 2014
            interes: 29.00 / 100
        },
        {
            fecha: '2014-9-1',//Septiembre 2014
            interes: 29.00 / 100
        },
        {
            fecha: '2014-10-1',//Octubre 2014
            interes: 28.76 / 100
        },
        {
            fecha: '2014-11-1',//Noviembre 2014
            interes: 28.76 / 100
        },
        {
            fecha: '2014-12-1',//Diciembre 2014
            interes: 28.76 / 100
        },
        {
            fecha: '2015-1-1',//Enero 2015 -------------------------------------------------------------
            interes: 28.82 / 100
        },
        {
            fecha: '2015-2-1',//Febrero 2015
            interes: 28.82 / 100
        },
        {
            fecha: '2015-3-1',//Marzo 2015
            interes: 28.82 / 100
        },
        {
            fecha: '2015-4-1',//Abril 2015
            interes: 29.06 / 100
        },
        {
            fecha: '2015-5-1',//Mayo 2015
            interes: 29.06 / 100
        },
        {
            fecha: '2015-6-1',//Junio 2015
            interes: 29.06 / 100
        },
        {
            fecha: '2015-7-1',//Julio 2015
            interes: 28.89 / 100
        },
        {
            fecha: '2015-8-1',//Agosto 2015
            interes: 28.89 / 100
        },
        {
            fecha: '2015-9-1',//Septiembre 2015
            interes: 28.89 / 100
        },
        {
            fecha: '2015-10-1',//Octubre 2015
            interes: 29.00 / 100
        },
        {
            fecha: '2015-11-1',//Noviembre 2015
            interes: 29.00 / 100
        },
        {
            fecha: '2015-12-1',//Diciembre 2015
            interes: 29.00 / 100
        },
        {
            fecha: '2016-1-1',//Enero 2016 -------------------------------------------------------------
            interes: 29.52 / 100
        },
        {
            fecha: '2016-2-1',//Febrero 2016
            interes: 29.52 / 100
        },
        {
            fecha: '2016-3-1',//Marzo 2016
            interes: 29.52 / 100
        },
        {
            fecha: '2016-4-1',//Abril 2016
            interes: 30.81 / 100
        },
        {
            fecha: '2016-5-1',//Mayo 2016
            interes: 30.81 / 100
        },
        {
            fecha: '2016-6-1',//Junio 2016
            interes: 30.81 / 100
        },
        {
            fecha: '2016-7-1',//Julio 2016
            interes: 32.01 / 100
        },
        {
            fecha: '2016-8-1',//Agosto 2016
            interes: 32.01 / 100
        },
        {
            fecha: '2016-9-1',//Septiembre 2016
            interes: 32.01 / 100
        },
        {
            fecha: '2016-10-1',//Octubre 2016
            interes: 32.99 / 100
        },
        {
            fecha: '2016-11-1',//Noviembre 2016
            interes: 32.99 / 100
        },
        {
            fecha: '2016-12-1',//Diciembre 2016
            interes: 32.99 / 100
        },
        {
            fecha: '2017-1-1',//Enero 2017 -------------------------------------------------------------
            interes: 33.51 / 100
        },
        {
            fecha: '2017-2-1',//Febrero 2017
            interes: 33.51 / 100
        },
        {
            fecha: '2017-3-1',//Marzo 2017
            interes: 33.51 / 100
        },
        {
            fecha: '2017-4-1',//Abril 2017
            interes: 33.50 / 100
        },
        {
            fecha: '2017-5-1',//Mayo 2017
            interes: 33.50 / 100
        },
        {
            fecha: '2017-6-1',//Junio 2017
            interes: 33.50 / 100
        },
        {
            fecha: '2017-7-1',//Julio 2017
            interes: 32.97 / 100
        },
        {
            fecha: '2017-8-1',//Agosto 2017
            interes: 32.97 / 100
        },
        {
            fecha: '2017-9-1',//Septiembre 2017
            interes: 32.22 / 100
        },
        {
            fecha: '2017-10-1',//Octubre 2017
            interes: 31.73 / 100
        },
        {
            fecha: '2017-11-1',//Noviembre 2017
            interes: 31.44 / 100
        },
        {
            fecha: '2017-12-1',//Diciembre 2017
            interes: 31.16 / 100
        },
        {
            fecha: '2018-1-1',//Enero 2018 -------------------------------------------------------------
            interes: 31.04 / 100
        },
        {
            fecha: '2018-2-1',//Febrero 2018
            interes: 31.52 / 100
        },
        {
            fecha: '2018-3-1',//Marzo 2018
            interes: 31.02 / 100
        },
        {
            fecha: '2018-4-1',//Abril 2018
            interes: 30.72 / 100
        },
        {
            fecha: '2018-5-1',//Mayo 2018
            interes: 30.66 / 100
        },
        {
            fecha: '2018-6-1',//Junio 2018
            interes: 30.42 / 100
        },
        {
            fecha: '2018-7-1',//Julio 2018
            interes: 30.05 / 100
        },
        {
            fecha: '2018-8-1',//Agosto 2018
            interes: 29.91 / 100
        },
        {
            fecha: '2018-9-1',//Septiembre 2018
            interes: 29.72 / 100
        },
        {
            fecha: '2018-10-1',//Octubre 2018
            interes: 29.45 / 100
        },
        {
            fecha: '2018-11-1',//Noviembre 2018
            interes: 29.24 / 100
        },
        {
            fecha: '2018-12-1',//Diciembre 2018
            interes: 29.10 / 100
        },
        {
            fecha: '2019-1-1',//Enero 2019 -------------------------------------------------------------
            interes: 28.74 / 100
        },
        {
            fecha: '2019-2-1',//Febrero 2019
            interes: 29.55 / 100
        },
        {
            fecha: '2019-3-1',//Marzo 2019
            interes: 29.06 / 100
        },
        {
            fecha: '2019-4-1',//Abril 2019
            interes: 28.98 / 100
        },
        {
            fecha: '2019-5-1',//Mayo 2019
            interes: 29.01 / 100
        },
        {
            fecha: '2019-6-1',//Junio 2019
            interes: 28.95 / 100
        },
        {
            fecha: '2019-7-1',//Julio 2019
            interes: 28.92 / 100
        },
        {
            fecha: '2019-8-1',//Agosto 2019
            interes: 28.98 / 100
        },
        {
            fecha: '2019-9-1',//Septiembre 2019
            interes: 28.98 / 100
        },
        {
            fecha: '2019-10-1',//Octubre 2019
            interes: 28.65 / 100
        },
        {
            fecha: '2019-11-1',//Noviembre 2019
            interes: 28.55 / 100
        },
        {
            fecha: '2019-12-1',//Diciembre 2019
            interes: 28.37 / 100
        },
        {
            fecha: '2020-1-1',//Enero 2020 -------------------------------------------------------------
            interes: 28.16 / 100
        },
        {
            fecha: '2020-2-1',//Febrero 2020
            interes: 28.59 / 100
        },
        {
            fecha: '2020-3-1',//Marzo 2020
            interes: 28.43 / 100
        },
        {
            fecha: '2020-4-1',//Abril 2020
            interes: 28.04 / 100
        },
        {
            fecha: '2020-5-1',//Mayo 2020
            interes: 27.29 / 100
        },
        {
            fecha: '2020-6-1',//Junio 2020
            interes: 27.18 / 100
        },
        {
            fecha: '2020-7-1',//Julio 2020
            interes: 27.18 / 100
        },
        {
            fecha: '2020-8-1',//Agosto 2020
            interes: 27.44 / 100
        },
        {
            fecha: '2020-9-1',//Septiembre 2020
            interes: 27.53 / 100
        },
        {
            fecha: '2020-10-1',//Octubre 2020
            interes: 27.14 / 100
        },
        {
            fecha: '2020-11-1',//Noviembre 2020
            interes: 26.76 / 100
        },
        {
            fecha: '2020-12-1',//Diciembre 2020
            interes: 26.19 / 100
        },
        {
            fecha: '2021-1-1',//Enero 2021 -------------------------------------------------------------
            interes: 25.98 / 100
        },
        {
            fecha: '2021-2-1',//Febrero 2021
            interes: 26.31 / 100
        },
        {
            fecha: '2021-3-1',//Marzo 2021
            interes: 26.12 / 100
        },
        {
            fecha: '2021-4-1',//Abril 2021
            interes: 25.97 / 100
        },
        {
            fecha: '2021-5-1',//Mayo 2021
            interes: 25.83 / 100
        },
        {
            fecha: '2021-6-1',//Junio 2021
            interes: 25.82 / 100
        },
        {
            fecha: '2021-7-1',//Julio 2021
            interes: 25.77 / 100
        },
        {
            fecha: '2021-8-1',//Agosto 2021
            interes: 25.86 / 100
        },
        {
            fecha: '2021-9-1',//Septiembre 2021
            interes: 25.79 / 100
        },
        {
            fecha: '2021-10-1',//Octubre 2021
            interes: 25.62 / 100
        },
        {
            fecha: '2021-11-1',//Noviembre 2021
            interes: 25.91 / 100
        },
        {
            fecha: '2021-12-1',//Diciembre 2021
            interes: 26.19 / 100
        },
        {
            fecha: '2022-1-1',//Enero 2022 -------------------------------------------------------------
            interes: 26.49 / 100
        },
        {
            fecha: '2022-2-1',//Febrero 2022
            interes: 27.45 / 100
        },
        {
            fecha: '2022-3-1',//Marzo 2022
            interes: 27.71 / 100
        },
        {
            fecha: '2022-4-1',//Abril 2022
            interes: 0
        },
        {
            fecha: '2022-5-1',//Mayo 2022
            interes: 0
        },
        {
            fecha: '2022-6-1',//Junio 2022
            interes: 0
        },
        {
            fecha: '2022-7-1',//Julio 2022
            interes: 0
        },
        {
            fecha: '2022-8-1',//Agosto 2022
            interes: 0
        },
        {
            fecha: '2022-9-1',//Septiembre 2022
            interes: 0
        },
        {
            fecha: '2022-10-1',//Octubre 2022
            interes: 0
        },
        {
            fecha: '2022-11-1',//Noviembre 2022
            interes: 0
        },
        {
            fecha: '2022-12-1',//Diciembre 2022
            interes: 0
        },
    ];

    const fecha2 = [
        {
            fecha: '2011-0-1',//Enero 2011 --------------------------------------------
            interes: 23.42
        },
        {
            fecha: '2011-1-1',//Febrero 2011
            interes: 23.42
        },
        {
            fecha: '2011-2-1',//Marzo 2011
            interes: 23.42
        },
        {
            fecha: '2011-3-1',//Abril 2011
            interes: 26.54
        },
        {
            fecha: '2011-4-1',//Mayo 2011
            interes: 26.54
        },
        {
            fecha: '2011-5-1',//Junio 2011
            interes: 26.54
        },
        {
            fecha: '2011-6-1',//Julio 2011
            interes: 27.95
        },
        {
            fecha: '2011-7-1',//Agosto 2011
            interes: 27.95
        },
        {
            fecha: '2011-8-1',//Septiembre 2011
            interes: 27.95
        },
        {
            fecha: '2011-9-1',//Octubre 2011
            interes: 29.09
        },
        {
            fecha: '2011-10-1',//Noviembre 2011
            interes: 29.09
        },
        {
            fecha: '2011-11-1',//Diciembre 2011
            interes: 29.09
        },
        {
            fecha: '2012-0-1',//Enero 2012 -------------------------------------------------------------
            interes: 29.88
        },
        {
            fecha: '2012-1-1',//Febrero 2012
            interes: 29.88
        },
        {
            fecha: '2012-2-1',//Marzo 2012
            interes: 29.88
        },
        {
            fecha: '2012-3-1',//Abril 2012
            interes: 30.78
        },
        {
            fecha: '2012-4-1',//Mayo 2012
            interes: 30.78
        },
        {
            fecha: '2012-5-1',//Junio 2012
            interes: 30.78
        },
        {
            fecha: '2012-6-1',//Julio 2012
            interes: 31.29
        },
        {
            fecha: '2012-7-1',//Agosto 2012
            interes: 31.29
        },
        {
            fecha: '2012-8-1',//Septiembre 2012
            interes: 31.29
        },
        {
            fecha: '2012-9-1',//Octubre 2012
            interes: 31.34
        },
        {
            fecha: '2012-10-1',//Noviembre 2012
            interes: 31.34
        },
        {
            fecha: '2012-11-1',//Diciembre 2012
            interes: 31.34
        },
        {
            fecha: '2013-0-1',//Enero 2013 -------------------------------------------------------------
            interes: 31.13
        },
        {
            fecha: '2013-1-1',//Febrero 2013
            interes: 31.13
        },
        {
            fecha: '2013-2-1',//Marzo 2013
            interes: 31.13
        },
        {
            fecha: '2013-3-1',//Abril 2013
            interes: 31.25
        },
        {
            fecha: '2013-4-1',//Mayo 2013
            interes: 31.25
        },
        {
            fecha: '2013-5-1',//Junio 2013
            interes: 31.25
        },
        {
            fecha: '2013-6-1',//Julio 2013
            interes: 30.51
        },
        {
            fecha: '2013-7-1',//Agosto 2013
            interes: 30.51
        },
        {
            fecha: '2013-8-1',//Septiembre 2013
            interes: 30.51
        },
        {
            fecha: '2013-9-1',//Octubre 2013
            interes: 29.78
        },
        {
            fecha: '2013-10-1',//Noviembre 2013
            interes: 29.78
        },
        {
            fecha: '2013-11-1',//Diciembre 2013
            interes: 29.78
        },
        {
            fecha: '2014-0-1',//Enero 2014 -------------------------------------------------------------
            interes: 29.48
        },
        {
            fecha: '2014-1-1',//Febrero 2014
            interes: 29.48
        },
        {
            fecha: '2014-2-1',//Marzo 2014
            interes: 29.48
        },
        {
            fecha: '2014-3-1',//Abril 2014
            interes: 29.45
        },
        {
            fecha: '2014-4-1',//Mayo 2014
            interes: 29.45
        },
        {
            fecha: '2014-5-1',//Junio 2014
            interes: 29.45
        },
        {
            fecha: '2014-6-1',//Julio 2014
            interes: 29.00
        },
        {
            fecha: '2014-7-1',//Agosto 2014
            interes: 29.00
        },
        {
            fecha: '2014-8-1',//Septiembre 2014
            interes: 29.00
        },
        {
            fecha: '2014-9-1',//Octubre 2014
            interes: 28.76
        },
        {
            fecha: '2014-10-1',//Noviembre 2014
            interes: 28.76
        },
        {
            fecha: '2014-11-1',//Diciembre 2014
            interes: 28.76
        },
        {
            fecha: '2015-0-1',//Enero 2015 -------------------------------------------------------------
            interes: 28.82
        },
        {
            fecha: '2015-1-1',//Febrero 2015
            interes: 28.82
        },
        {
            fecha: '2015-2-1',//Marzo 2015
            interes: 28.82
        },
        {
            fecha: '2015-3-1',//Abril 2015
            interes: 29.06
        },
        {
            fecha: '2015-4-1',//Mayo 2015
            interes: 29.06
        },
        {
            fecha: '2015-5-1',//Junio 2015
            interes: 29.06
        },
        {
            fecha: '2015-6-1',//Julio 2015
            interes: 28.89
        },
        {
            fecha: '2015-7-1',//Agosto 2015
            interes: 28.89
        },
        {
            fecha: '2015-8-1',//Septiembre 2015
            interes: 28.89
        },
        {
            fecha: '2015-9-1',//Octubre 2015
            interes: 29.00
        },
        {
            fecha: '2015-10-1',//Noviembre 2015
            interes: 29.00
        },
        {
            fecha: '2015-11-1',//Diciembre 2015
            interes: 29.00
        },
        {
            fecha: '2016-0-1',//Enero 2016 -------------------------------------------------------------
            interes: 29.52
        },
        {
            fecha: '2016-1-1',//Febrero 2016
            interes: 29.52
        },
        {
            fecha: '2016-2-1',//Marzo 2016
            interes: 29.52
        },
        {
            fecha: '2016-3-1',//Abril 2016
            interes: 30.81
        },
        {
            fecha: '2016-4-1',//Mayo 2016
            interes: 30.81
        },
        {
            fecha: '2016-5-1',//Junio 2016
            interes: 30.81
        },
        {
            fecha: '2016-6-1',//Julio 2016
            interes: 32.01
        },
        {
            fecha: '2016-7-1',//Agosto 2016
            interes: 32.01
        },
        {
            fecha: '2016-8-1',//Septiembre 2016
            interes: 32.01
        },
        {
            fecha: '2016-9-1',//Octubre 2016
            interes: 32.99
        },
        {
            fecha: '2016-10-1',//Noviembre 2016
            interes: 32.99
        },
        {
            fecha: '2016-11-1',//Diciembre 2016
            interes: 32.99
        },
        {
            fecha: '2017-0-1',//Enero 2017 -------------------------------------------------------------
            interes: 33.51
        },
        {
            fecha: '2017-1-1',//Febrero 2017
            interes: 33.51
        },
        {
            fecha: '2017-2-1',//Marzo 2017
            interes: 33.51
        },
        {
            fecha: '2017-3-1',//Abril 2017
            interes: 33.50
        },
        {
            fecha: '2017-4-1',//Mayo 2017
            interes: 33.50
        },
        {
            fecha: '2017-5-1',//Junio 2017
            interes: 33.50
        },
        {
            fecha: '2017-6-1',//Julio 2017
            interes: 32.97
        },
        {
            fecha: '2017-7-1',//Agosto 2017
            interes: 32.97
        },
        {
            fecha: '2017-8-1',//Septiembre 2017
            interes: 32.22
        },
        {
            fecha: '2017-9-1',//Octubre 2017
            interes: 31.73
        },
        {
            fecha: '2017-10-1',//Noviembre 2017
            interes: 31.44
        },
        {
            fecha: '2017-11-1',//Diciembre 2017
            interes: 31.16
        },
        {
            fecha: '2018-0-1',//Enero 2018 -------------------------------------------------------------
            interes: 31.04
        },
        {
            fecha: '2018-1-1',//Febrero 2018
            interes: 31.52
        },
        {
            fecha: '2018-2-1',//Marzo 2018
            interes: 31.02
        },
        {
            fecha: '2018-3-1',//Abril 2018
            interes: 30.72
        },
        {
            fecha: '2018-4-1',//Mayo 2018
            interes: 30.66
        },
        {
            fecha: '2018-5-1',//Junio 2018
            interes: 30.42
        },
        {
            fecha: '2018-6-1',//Julio 2018
            interes: 30.05
        },
        {
            fecha: '2018-7-1',//Agosto 2018
            interes: 29.91
        },
        {
            fecha: '2018-8-1',//Septiembre 2018
            interes: 29.72
        },
        {
            fecha: '2018-9-1',//Octubre 2018
            interes: 29.45
        },
        {
            fecha: '2018-10-1',//Noviembre 2018
            interes: 29.24
        },
        {
            fecha: '2018-11-1',//Diciembre 2018
            interes: 29.10
        },
        {
            fecha: '2019-0-1',//Enero 2019 -------------------------------------------------------------
            interes: 28.74
        },
        {
            fecha: '2019-1-1',//Febrero 2019
            interes: 29.55
        },
        {
            fecha: '2019-2-1',//Marzo 2019
            interes: 29.06
        },
        {
            fecha: '2019-3-1',//Abril 2019
            interes: 28.98
        },
        {
            fecha: '2019-4-1',//Mayo 2019
            interes: 29.01
        },
        {
            fecha: '2019-5-1',//Junio 2019
            interes: 28.95
        },
        {
            fecha: '2019-6-1',//Julio 2019
            interes: 28.92
        },
        {
            fecha: '2019-7-1',//Agosto 2019
            interes: 28.98
        },
        {
            fecha: '2019-8-1',//Septiembre 2019
            interes: 28.98
        },
        {
            fecha: '2019-9-1',//Octubre 2019
            interes: 28.65
        },
        {
            fecha: '2019-10-1',//Noviembre 2019
            interes: 28.55
        },
        {
            fecha: '2019-11-1',//Diciembre 2019
            interes: 28.37
        },
        {
            fecha: '2020-0-1',//Enero 2020 -------------------------------------------------------------
            interes: 28.16
        },
        {
            fecha: '2020-1-1',//Febrero 2020
            interes: 28.59
        },
        {
            fecha: '2020-2-1',//Marzo 2020
            interes: 28.43
        },
        {
            fecha: '2020-3-1',//Abril 2020
            interes: 28.04
        },
        {
            fecha: '2020-4-1',//Mayo 2020
            interes: 27.29
        },
        {
            fecha: '2020-5-1',//Junio 2020
            interes: 27.18
        },
        {
            fecha: '2020-6-1',//Julio 2020
            interes: 27.18
        },
        {
            fecha: '2020-7-1',//Agosto 2020
            interes: 27.44
        },
        {
            fecha: '2020-8-1',//Septiembre 2020
            interes: 27.53
        },
        {
            fecha: '2020-9-1',//Octubre 2020
            interes: 27.14
        },
        {
            fecha: '2020-10-1',//Noviembre 2020
            interes: 26.76
        },
        {
            fecha: '2020-11-1',//Diciembre 2020
            interes: 26.19
        },
        {
            fecha: '2021-0-1',//Enero 2021 -------------------------------------------------------------
            interes: 25.98 / 100
        },
        {
            fecha: '2021-1-1',//Febrero 2021
            interes: 26.31 / 100
        },
        {
            fecha: '2021-2-1',//Marzo 2021
            interes: 26.12 / 100
        },
        {
            fecha: '2021-3-1',//Abril 2021
            interes: 25.97 / 100
        },
        {
            fecha: '2021-4-1',//Mayo 2021
            interes: 25.83 / 100
        },
        {
            fecha: '2021-5-1',//Junio 2021
            interes: 25.82 / 100
        },
        {
            fecha: '2021-6-1',//Julio 2021
            interes: 25.77 / 100
        },
        {
            fecha: '2021-7-1',//Agosto 2021
            interes: 25.86 / 100
        },
        {
            fecha: '2021-8-1',//Septiembre 2021
            interes: 25.79 / 100
        },
        {
            fecha: '2021-9-1',//Octubre 2021
            interes: 25.62 / 100
        },
        {
            fecha: '2021-10-1',//Noviembre 2021
            interes: 25.91 / 100
        },
        {
            fecha: '2021-11-1',//Diciembre 2021
            interes: 26.19 / 100
        },
        {
            fecha: '2022-0-1',//Enero 2022 -------------------------------------------------------------
            interes: 26.49 / 100
        },
        {
            fecha: '2022-1-1',//Febrero 2022
            interes: 26.49 / 100
        },
        {
            fecha: '2022-2-1',//Marzo 2022
            interes: 27.71 / 100
        },
        {
            fecha: '2022-3-1',//Abril 2022
            interes: 28.58 / 100
        },
        {
            fecha: '2022-4-1',//Mayo 2022
            interes: 29.57 / 100
        },
        {
            fecha: '2022-5-1',//Junio 2022
            interes: 30.60 / 100
        },
        {
            fecha: '2022-6-1',//Julio 2022
            interes: 31.92 / 100
        },
        {
            fecha: '2022-7-1',//Agosto 2022
            interes: 33.32 / 100
        },
        {
            fecha: '2022-8-1',//Septiembre 2022
            interes: 35.25 / 100
        },
        {
            fecha: '2022-9-1',//Octubre 2022
            interes: 36.92 / 100
        },
        {
            fecha: '2022-10-1',//Noviembre 2022
            interes: 38.67 / 100
        },
        {
            fecha: '2022-11-1',//Diciembre 2022
            interes: 41.46 / 100
        },
        {
            fecha: '2023-0-1',//Enero 2023
            interes: 43.26 / 100
        },
        {
            fecha: '2023-1-1',//Febrero 2023
            interes: 45.27 / 100
        },
        {
            fecha: '2023-2-1',//Marzo 2023
            interes: 46.26 / 100
        },
        {
            fecha: '2023-3-1',//Abril 2023
            interes: 47.08 / 100
        },
        {
            fecha: '2023-4-1',//Mayo 2023
            interes: 47.08 / 100
        },
        {
            fecha: '2023-5-1',//Junio 2023
            interes: 44.64 / 100
        },
        {
            fecha: '2023-6-1',//Julio 2023
            interes: 0
        },
        {
            fecha: '2023-7-1',//Agosto 2023
            interes: 0
        },
    ]

    return fecha1;
}

//https://www.superfinanciera.gov.co/inicio/sala-de-prensa/comunicados-de-prensa-/interes-bancario-corriente-10829


