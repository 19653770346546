import { db } from "../../firebase/config";

export async function FBcambioProcesoProspecto(json) {
    try {
        const jsonLlega = {
            etapaAnterior: json.etapa,
            idRegistro: json.idRegistro,
            nuevaEtapa: json.nuevoProceso,
        };

        const collectionPath = "cf-ventas";
        const etapasDocument = "etapas";
        const seguimientoId = jsonLlega.idRegistro;

        // Consulta para obtener los documentos que coinciden con el seguimientoId
        const querySnapshot = await db
            .collection(collectionPath)
            .doc(etapasDocument)
            .collection(jsonLlega.etapaAnterior)
            .where("seguimientoId", "==", seguimientoId)
            .get();

        const array = [];

        // Recorremos los documentos encontrados en la consulta
        querySnapshot.forEach(async (doc) => {
            const documentoData = doc.data();
            documentoData.etapa = jsonLlega.nuevaEtapa; // Actualizar el nuevo proceso

            // Agregamos los datos del documento al array
            array.push(documentoData);

            const documentoRef = db
                .collection(collectionPath)
                .doc(etapasDocument)
                .collection(jsonLlega.nuevaEtapa)
                .doc(); // Utilizamos .doc() para generar un nuevo ID en la colección nueva

            // Guardamos los datos del documento en la nueva ubicación
            await documentoRef.set(documentoData);

            // Eliminamos el documento de la ubicación anterior
            await doc.ref.delete();
        });

        // Recorremos los documentos en array y actualizamos los registros de apoyo
        for (const documentoData of array) {
            if (documentoData.apoyo && documentoData.apoyo.length > 0) {
                for (const apoyoData of documentoData.apoyo) {
                    if (apoyoData.departamento) {
                        const departamento = apoyoData.departamento;

                        // Consulta para obtener los documentos en "listado" que coinciden con seguimientoId
                        const listadoSnapshot = await db
                            .collection(departamento)
                            .doc("tareasExternas")
                            .collection("listado")
                            .where("seguimientoId", "==", seguimientoId)
                            .get();

                        // Actualizamos cada documento encontrado en "listado"
                        listadoSnapshot.forEach(async (listadoDoc) => {
                            await listadoDoc.ref.update({
                                etapa: jsonLlega.nuevaEtapa,
                            });
                        });
                    }
                }
            }
        }


        return [true, array, null];
    } catch (error) {
        console.error("Error al cambiar el proceso del prospecto:", error);
        return [false, [], null];
    }
}
