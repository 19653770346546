<template>
  <div>
    <!-- PANEL DCM -->
    <v-row class="mt-5">
      <v-col cols="12" sm="4">
        <v-card class="rounded-xl pa-2 blue lighten-4" outlined tile>
          Última Gestión:
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="rounded-xl pa-2 blue lighten-4" outlined tile>
          Total Gestiones Hoy:
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="rounded-xl pa-2 blue lighten-4" outlined tile>
          Total Solicitudes: {{ totalElementos - 1 }}
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Titular v-show="titular" :datosTitular="datosTitularBusqueda" />
      </v-col>
    </v-row>

    <!-- TABLA DE TRABAJO LISTADO DE TITULARES...  -->
    <v-row>
      <!-- PANEL 1 DE DCM Verificación de Pagos 
      <v-col cols="6">
        <v-card color="blue darken-4 rounded-xl">
          <v-card-title>
            <v-text-field
              class="blue darken-4"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              filled
              rounded
              dense
              dark
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="verificaPago"
            :search="search"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.tipiUlti`]="{ item }">
              <v-chip :color="getColortipiUlti(item.tipiUlti)" dark>
                {{ item.tipiUlti }}
              </v-chip>
            </template>

            <template v-slot:[`item.toques`]="{ item }">
              <v-chip :color="getColor(item.toques)" dark>
                {{ item.toques }}
              </v-chip>
            </template>

            <template v-slot:[`item.estado`]="{ item }">
              <v-simple-checkbox
                :ripple="false"
                v-model="item.estado"
                :value="item.estado"
                @click="estadoTarea(item)"
              ></v-simple-checkbox>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <! - -  - ->
                <v-toolbar-title>Pagos</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >¿Está seguro de eliminar el registro?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn color="red" text @click="deleteItemConfirm()"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-icon small class="mr-2" @click="detect(item, 'Copiar')">
                mdi-content-copy
              </v-icon>
              <v-icon small class="mr-2" @click="detect(item, 'Editar')">
                mdi-pencil
              </v-icon>
              <v-icon small @click="detect(item, 'Eliminar')">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>-->
      <!-- PANEL 1 DE GERENCIA Todo lo demás -->
      <v-col cols="12">
        <v-card color="blue darken-4 rounded-xl">
          <v-card-title>
            <v-text-field
              class="blue darken-4"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              filled
              rounded
              dense
              dark
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.tipiUlti`]="{ item }">
              <v-chip :color="getColortipiUlti(item.tipiUlti)" dark>
                {{ item.tipiUlti }}
              </v-chip>
            </template>

            <template v-slot:[`item.toques`]="{ item }">
              <v-chip :color="getColor(item.toques)" dark>
                {{ item.toques }}
              </v-chip>
            </template>

            <template v-slot:[`item.estado`]="{ item }">
              <v-simple-checkbox
                v-model="item.estado"
                :ripple="false"
                :value="item.estado"
                @click="estadoTarea(item, item.estado)"
              ></v-simple-checkbox>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <!--  -->
                <v-toolbar-title>Varios</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >¿Está seguro de eliminar el registro?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn color="red" text @click="deleteItemConfirm()"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-icon small class="mr-2" @click="detect(item, 'Copiar')">
                mdi-content-copy
              </v-icon>
              <v-icon small class="mr-2" @click="detect(item, 'Editar')">
                mdi-pencil
              </v-icon>
              <v-icon small @click="detect(item, 'Eliminar')">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- PANEL 1 DE DCM  Titulos Valores -->

      <v-col cols="12" v-if="verTitulosValoresHora">
        <v-card color="blue darken-4 rounded-xl">
          <v-card-title>
            <v-text-field
              class="blue darken-4"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              filled
              rounded
              dense
              dark
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headersTV"
            :items="titulosValores"
            :search="search"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.tipiUlti`]="{ item }">
              <v-chip :color="getColortipiUlti(item.tipiUlti)" dark>
                {{ item.tipiUlti }}
              </v-chip>
            </template>

            <template v-slot:[`item.toques`]="{ item }">
              <v-chip :color="getColor(item.toques)" dark>
                {{ item.toques }}
              </v-chip>
            </template>

            <template v-slot:[`item.estado`]="{ item }">
              <v-simple-checkbox
                :ripple="false"
                v-model="item.estado"
                :value="item.estado"
                @click="estadoTarea(item, item.estado)"
              ></v-simple-checkbox>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <!--  -->
                <v-toolbar-title>Titulos Valores</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >¿Está seguro de eliminar el registro?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn color="red" text @click="deleteItemConfirm()"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-icon small class="mr-2" @click="detect(item, 'Copiar')">
                mdi-content-copy
              </v-icon>
              <v-icon small class="mr-2" @click="detect(item, 'Editar')">
                mdi-pencil
              </v-icon>
              <v-icon small @click="detect(item, 'Eliminar')">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- PANEL PARA VER  INDICADORES  -->
    <v-row>
      <indicadoresACM v-if="rolPrueba == 'g'" />
    </v-row>

    <!-- PANEL DE CARTERAS...  -->
    <v-row v-if="rolPrueba == 'g'">
      <v-col cols="12">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Resumen Básico por Carteras
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- SECCIÓN INFORME CARTERA -->
              <v-row class="mt-2">
                <v-col class="pa-2" cols="12" sm="4">
                  <v-select
                    v-if="nits.length > 0"
                    v-model="selectedOptionNit"
                    @change="consultarCartera()"
                    :items="nits"
                    label="Nit a consultar"
                  ></v-select>
                </v-col>
                <v-col class="pa-2" cols="12" sm="4">
                  <v-select
                    v-model="selectedOptionCartera"
                    :items="
                      carteraConsulta
                        ? carteraConsulta.map(
                            (cartera) => cartera.nombreCartera
                          )
                        : []
                    "
                    label="Cartera a consultar"
                  ></v-select>
                </v-col>
                <v-col class="pa-2" cols="12" sm="4">
                  <v-btn
                    :disabled="estadoBotonCargaCartera"
                    @click="consultasCarterasTotalesBasico"
                    icon
                  >
                    <v-icon>mdi-send-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-date-picker v-model="datesRango" range></v-date-picker>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Date range"
                    prepend-icon="mdi-calendar"
                    readonly
                  ></v-text-field>
                  Desde: {{ datesRango[0] }} Hasta: {{ datesRango[1] }}
                </v-col>
              </v-row>

              <!-- PANELES CARTERA -->
              <v-row v-if="carteras != null">
                <v-col
                  v-for="(item, index) in carteras"
                  :key="index"
                  cols="12"
                  sm="12"
                  md="6"
                  lg="4"
                  xl="4"
                >
                  <v-card class="mx-auto mt-5" max-width="400">
                    <v-img
                      class="white--text align-end"
                      height="150px"
                      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                    >
                      <v-card-title>
                        <!-- TITULO CARTERA...  -->
                        <v-row>
                          <v-col cols="12">
                            {{ item.datosCartera.nombreCartera }}
                          </v-col>
                          <v-col class="mt-n10" cols="12">
                            <span class="overline">{{
                              item.datosCliente.cliente
                            }}</span>
                          </v-col>
                        </v-row>
                      </v-card-title>
                    </v-img>
                    <!-- FECHA REGISTRO ...  -->
                    <v-card-subtitle class="pb-0">
                      F.Registro: {{ timeTodate(item.datosCartera.timeR) }}
                    </v-card-subtitle>

                    <v-card-subtitle class="pb-0">
                      Total Titulares: {{ item.totalRegistrosSinFiltros }}
                    </v-card-subtitle>

                    <v-spacer></v-spacer>

                    <v-card-text class="text--primary">
                      <v-row>
                        <v-col class="pa-2" cols="12" sm="12"> </v-col>
                      </v-row>

                      <v-row justify="center" no-gutters>
                        <!-- Total Titulares ... -->
                        <v-col class="pa-2" cols="12" sm="4">
                          <v-card
                            v-if="item.bolsa != undefined"
                            class="pa-2 rounded-xl"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            @click="titulares()"
                          >
                            <detalleInfo
                              :datos="item.bolsa"
                              :modulo="'titulares'"
                            />

                            <v-row justify="center">
                              <span class="pa-2 font-weight-black subtitle-1">
                                {{ item.bolsa.length }}
                              </span>
                            </v-row>
                            <v-row class="text-center mt-n5"  justify="center">
                              <span class="pa-2 caption">
                                Total <br />
                                Titulares
                              </span>
                            </v-row>
                          </v-card>
                          <v-card
                            v-else
                            class="pa-2 text-center"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            outlined
                          >
                            <span> No se ha asignado</span>
                          </v-card>
                        </v-col>
                        <!-- Total Asesores ... -->
                        <v-col class="pa-2" cols="12" sm="4">
                          <v-card
                            v-if="item.datosCartera.asesores != undefined"
                            class="pa-2 rounded-xl"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                          >
                            <detalleInfo
                              :datos="item.datosCartera.asesores"
                              :modulo="'asesores'"
                            />
                            <v-row align="center" justify="center">
                              <span class="pa-2 font-weight-black subtitle-1">
                                {{ item.datosCartera.asesores.length }}
                              </span>
                            </v-row>
                            <v-row
                              class="text-center"
                              align="center"
                              justify="center"
                            >
                              <span class="pa-2 caption mt-n5">
                                Total <br />
                                Asesores
                              </span>
                            </v-row>
                          </v-card>

                          <v-card
                            v-else
                            class="pa-2 text-center"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            outlined
                          >
                            <span> No se ha asignado</span>
                          </v-card>
                        </v-col>
                        <!-- Total Gestionados ... -->
                        <v-col class="pa-2" cols="12" sm="4">
                          <v-card
                            v-if="item.TotalFecha != undefined"
                            class="pa-2 rounded-xl"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                          >
                          <detalleInfo
                              :datos="item.TotalFecha"
                              :modulo="'gestionados'"
                            />

                            <v-row align="center" justify="center">
                              <span class="pa-2 font-weight-black subtitle-1">
                                {{ item.TotalFecha.length }}
                              </span>
                            </v-row>
                            <v-row
                              class="text-center"
                              align="center"
                              justify="center"
                            >
                              <span class="pa-2 caption mt-n5">
                                Total Gestionados
                              </span>
                            </v-row>
                          </v-card>
                          <v-card
                            v-else
                            class="pa-2 text-center"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            outlined
                          >
                            <span> No se ha asignado</span>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row align="center" justify="center" no-gutters>
                        <!-- Total Acuerdos de Pago ... -->
                        <v-col class="pa-2" cols="12" sm="4">
                          <v-card
                            v-if="item.bolsa != undefined"
                            class="pa-2 rounded-xl"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            @click="titulares()"
                          >
                         
                          <detalleInfo
                              :datos="item.TotalAcuerdosDP"
                              :modulo="'acuerdoDP'"
                            />
                            <v-row align="center" justify="center">
                              <span class="pa-2 font-weight-black subtitle-1">
                                {{ item.TotalAcuerdosDP.length }}
                              </span>
                            </v-row>
                            <v-row
                              class="text-center mt-n5"
                              align="center"
                              justify="center"
                            >
                              <span class="pa-2 caption">
                                Total <br />
                                Acuerdos D.P.
                              </span>
                            </v-row>
                          </v-card>
                          <v-card
                            v-else
                            class="pa-2 text-center"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            outlined
                          >
                            <span> No se ha asignado</span>
                          </v-card>
                        </v-col>
                        <!-- Total Historial  ... -->
                        <v-col class="pa-2" cols="12" sm="4">
                          <v-card
                            v-if="item.datosCartera.asesores != undefined"
                            class="pa-2 rounded-xl"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                          >
                            <v-row align="center" justify="center">
                              <span class="pa-2 font-weight-black subtitle-1">
                                {{ item.TotalPPHistorial.length }}
                              </span>
                            </v-row>
                            <v-row
                              class="text-center"
                              align="center"
                              justify="center"
                            >
                              <span class="pa-2 caption mt-n5">
                                Histórico <br />
                                Pagos
                              </span>
                            </v-row>
                          </v-card>
                          <v-card
                            v-else
                            class="pa-2 text-center"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            outlined
                          >
                            <span> No se ha asignado</span>
                          </v-card>
                        </v-col>
                        <!-- Total Demandados ... -->
                        <v-col class="pa-2" cols="12" sm="4">
                          <v-card
                            v-if="item.TotalDemandados != undefined"
                            class="pa-2 rounded-xl"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            @click="titulares()"
                          >
                            <v-row align="center" justify="center">
                              <span class="pa-2 font-weight-black subtitle-1">
                                {{ item.TotalDemandados.length }}
                              </span>
                            </v-row>
                            <v-row
                              class="text-center mt-n5"
                              align="center"
                              justify="center"
                            >
                              <span class="pa-2 caption">
                                Total <br />
                                Demandados
                              </span>
                            </v-row>
                          </v-card>
                          <v-card
                            v-else
                            class="pa-2 text-center"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            outlined
                          >
                            <span> No se ha asignado</span>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row align="center" justify="center" no-gutters>
                        <!-- Total pdfAuto  ... -->
                        <v-col class="pa-2" cols="12" sm="4">
                          <v-card
                            v-if="item.TotalAuto != undefined"
                            class="pa-2 rounded-xl"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                          >
                            <v-row align="center" justify="center">
                              <span class="pa-2 font-weight-black subtitle-1">
                                {{ item.TotalAuto.length }}
                              </span>
                            </v-row>
                            <v-row
                              class="text-center"
                              align="center"
                              justify="center"
                            >
                              <span class="pa-2 caption mt-n5">
                                Total <br />
                                Autos
                              </span>
                            </v-row>
                          </v-card>
                          <v-card
                            v-else
                            class="pa-2 text-center"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            outlined
                          >
                            <span> No se ha asignado</span>
                          </v-card>
                        </v-col>
                        <!-- Total pdfOficio ... -->
                        <v-col class="pa-2" cols="12" sm="4">
                          <v-card
                            v-if="item.TotalOficio != undefined"
                            class="pa-2 rounded-xl"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                          >
                            <v-row align="center" justify="center">
                              <span class="pa-2 font-weight-black subtitle-1">
                                {{ item.TotalOficio.length }}
                              </span>
                            </v-row>
                            <v-row
                              class="text-center"
                              align="center"
                              justify="center"
                            >
                              <span class="pa-2 caption mt-n5">
                                Total <br />
                                Oficios
                              </span>
                            </v-row>
                          </v-card>
                          <v-card
                            v-else
                            class="pa-2 text-center"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            outlined
                          >
                            <span> No se ha asignado</span>
                          </v-card>
                        </v-col>
                        <!-- Total PazySalvo v1 ... -->
                        <v-col class="pa-2" cols="12" sm="4">
                          <v-card
                            v-if="item.TotalPazySalvoV1 != undefined"
                            class="pa-2 rounded-xl"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                          >
                            <v-row align="center" justify="center">
                              <span class="pa-2 font-weight-black subtitle-1">
                                {{ item.TotalPazySalvoV1.length }}
                              </span>
                            </v-row>
                            <v-row
                              class="text-center"
                              align="center"
                              justify="center"
                            >
                              <span class="pa-2 caption mt-n5">
                                Total Paz y Salvo v1
                              </span>
                            </v-row>
                          </v-card>
                          <v-card
                            v-else
                            class="pa-2 text-center"
                            elevation="0"
                            tile
                            color="light-blue lighten-4"
                            outlined
                          >
                            <span> No se ha asignado</span>
                          </v-card>
                        </v-col>
                      </v-row>

                      <div class="pa-2">
                        Expectativa: {{ pesosCOP(item.TotalMonto) }}
                      </div>
                      <div class="pa-2 mt-n2">
                        Recaudado: {{ sumaHistorial(item.TotalPPHistorial) }}
                      </div>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn disabled color="orange" text> Cerrar </v-btn>

                      <v-btn disabled color="blue" text> Explorar </v-btn>

                      <v-spacer></v-spacer>
                      <v-switch
                        disabled
                        v-model="item.datosCartera.estado"
                        :label="`Estado: ${boleanTexto(
                          item.datosCartera.estado
                        )}`"
                      ></v-switch>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Linea de carga de datos...  -->
              <v-row v-else>
                <v-progress-linear
                  class="mt-5"
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
                  v-show="estadoBotonCargaCartera"
                ></v-progress-linear>

                <v-col
                  ><p>{{ estadoCarterasText }}</p></v-col
                >
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- Alerta de Cambios...  -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      absolute
      bottom
      color="blue darken-4"
      elevation="24"
      centered
      rounded="pill"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import _ from "lodash";
import { firebase, db, storage } from "../../../../firebase/config";
import {
  FBverClientesNitResportes,
  FBverCarteras,
  FBverClientesCarteras,
} from "../../../../components/Cartera/gestionDCM/busquedas";

import Titular from "../panelTitular.vue";
import store from "../../../../store";
import indicadoresACM from "./indicadores/gestionACM.vue";

import detalleInfo from "./indicadores/dialogoDetalle.vue";

const audio = new Audio(
  require("../../../../assets/notificaciones/Vermillion.mp3")
);

const hoy = new Date();

export default {
  name: "gestionBasicaG",
  components: {
    Titular,
    indicadoresACM,
    detalleInfo,
  },
  data: () => ({
    datesRango: ["2023-01-01", "2023-01-02"],
    rolPrueba: store.state.user[2][0].data.cargo,
    hora: hoy.getHours() + ":" + hoy.getMinutes() + ":" + hoy.getSeconds(),
    search: "",
    headers: [
      { text: "Tipificación", value: "tipiUlti" },
      { text: "Cédula T.", value: "Cedula" },
      { text: "Nit.", value: "nitCliente" },
      { text: "Fecha solicitud", value: "proxToque" }, //Antes era proxToque, pero ahora es solo fecha regisotro
      { text: "Trabajado", value: "estado" },
      //{ text: "Cartera", value: "idCartera" },
      { text: "Acciones", value: "acciones", sortable: false },
    ],
    headersTV: [
      { text: "Tipificación", value: "tipiUlti" },
      { text: "Cédula T.", value: "Cedula" },
      { text: "Nit.", value: "nitCliente" },
      { text: "Fecha solicitud", value: "proxToque" }, //Antes era proxToque, pero ahora es solo fecha regisotro
      { text: "Trabajado", value: "estado" },
      //{ text: "Cartera", value: "idCartera" },
      { text: "Acciones", value: "acciones", sortable: false },
    ],
    desserts: [
      /*{
        Cedula: 80,
        idRegistro: 159,
        proxToque: 6.0,
        tipiUlti: 24,
        toques: 4.0,
      },*/
    ],
    titulosValores: [],
    verificaPago: [],
    verTitulosValoresHora: false,
    selectedOptionNit: null,
    nits: [],
    datosNits: [],
    carteras: [],
    carteraConsulta: [],
    selectedOptionCartera: null,
    estadoBotonCargaCartera: false,
    estadoCarterasText: "",
    snackbar: false,
    text: "",
    timeout: 1500,
    titular: false,
    datosTitularBusqueda: null,
    dialogDelete: false,
    titularAccion: null,
    totalElementos: 0,
    idTraza: "",
  }),
  mounted() {
    //console.log("Montado Gestion DCM");
    //setTimeout(() => {
    //this.consultasCarterasTotalesBasico();
    this.consultaTareasDCM();
    this.consultaNits();
    //}, 1000);
  },
  methods: {
    async deleteItemConfirm() {
      //console.log("Eliminar del todo...");
      //console.log(this.titularAccion);
      this.dialogDelete = false;

      /*db.collection('cf-cartera').doc('Carteras')
            .collection(jsonTitular.nitCliente).doc(jsonTitular.idCartera)
            .collection('registros').where('data.Cedula', '==', Number(jsonTitular.Cedula));*/
      //.collection("Z9yqrRvsymlViYTMxoMy").where("Cedula", "==", 1037545432)

      /*console.log(this.titularAccion);
      console.log(this.titularAccion.codigoB);
      console.log(this.idTraza);*/

      //ELIMINA POR EL ID
      const rutaTitular = db
        .collection("cf-cartera")
        .doc("Asignaciones")
        .collection("gerencia")
        .doc(this.titularAccion.codigoB);

      //Eliminar
      await rutaTitular.delete();

      //Marcar ultima gestión Para indicador Director Cartera Morosa
      /*const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

      const rutaTitularTraza = db
        .collection("cf-cartera")
        .doc("Asignaciones")
        .collection("dcm")
        .doc(this.idTraza); //.where("a", "==", "no borrar");

      let huella = {
        fecha: firebase.firestore.Timestamp.now(),
        tipoCambio: this.titularAccion.tipiUlti,
        codigoB: this.titularAccion.codigoB,
        cedula: this.titularAccion.Cedula,
        usuario: this.rolPrueba,
      };

      await rutaTitularTraza.update({
        b: arrayUnion(huella),
      });*/

      //const res = await db.collection('cities').doc('LA').set(data);

      //console.log(rutaTitular);
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    detect(event, accion) {
      //console.log(event);
      //console.log(event.Cedula);
      switch (accion) {
        case "Eliminar":
          //this.snackbar = true;
          //this.text = "Usuario:" + event.Cedula;

          //this.titular = true;
          //this.datosTitularBusqueda = event;
          //console.log("Eliminar:", event);
          this.titularAccion = event;
          this.dialogDelete = true;
          //const res = await db.collection('cities').doc('DC').delete();index.js

          break;
        case "Editar":
          //this.snackbar = true;
          this.text = "Usuario:" + event.Cedula;

          this.titular = true;
          this.datosTitularBusqueda = event;
          break;
        case "Copiar":
          //this.snackbar = true;
          //console.log(event);

          let dato = event.Cedula;

          let textoCopiado = dato;

          //Copia el texto en el portapapeles
          const a = navigator.clipboard.writeText(textoCopiado);
          //console.log(a);

          break;
        default:
          break;
      }
    },
    async consultaTareasDCM() {
      this.FBgestionDCM();
    },
    async consultaNits() {
      //Traer todos los nit, pero se cambia por un solo nit
      const result = await FBverClientesNitResportes();
      //console.log(result);
      this.datosNits = result;
      for (const item of result) {
        //console.log(item.nit);
        this.nits.push(item.nit);
      }
    },
    async consultarCartera() {
      //console.log(this.selectedOptionNit);
      const resultCarteras = await FBverClientesCarteras(
        this.selectedOptionNit
      );
      //console.log(resultCarteras);
      this.carteraConsulta = resultCarteras;
    },
    //TRAE LOS DATOS DEL PANEL CARTERAS
    async consultasCarterasTotalesBasico() {
      this.estadoCarterasText = "Cargando datos...";
      this.carteras = [];
      this.estadoBotonCargaCartera = true;

      //Buscar Nit
      const clienteEncontrado = _.find(this.datosNits, {
        nit: this.selectedOptionNit,
      });

      const resultCarteras = await FBverCarteras(
        [this.selectedOptionNit, this.selectedOptionCartera],
        clienteEncontrado,
        this.datesRango
      );
      //console.log(resultCarteras);
      this.carteras = resultCarteras;
      this.estadoBotonCargaCartera = false;
    },
    async estadoTarea(item, estadoLlega) {
      //Ayuda a identificar los procesos de la tarea actual, mirando que no se puede borrar.
      //console.log("Hola: ", item);
      //console.log("id: ", item.codigoB);
      //console.log("Estado: ", estadoLlega);

      const rutaTitular = db
        .collection("cf-cartera")
        .doc("Asignaciones")
        .collection("gerencia")
        .doc(item.codigoB);

      const res = await rutaTitular.update({ estado: estadoLlega });

      /*const cityRef = db.collection("cities").doc("DC");

      // Set the 'capital' field of the city
      const res = await cityRef.update({ capital: true });*/
    },
    sumaHistorial(a) {
      var sumar = 0;
      for (var element of a) {
        sumar = sumar + Number(element);
      }

      var pesosCOP = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      }).format(sumar);

      return pesosCOP;
    },
    pesosCOP(a) {
      var pesosCOP = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      }).format(a);

      return pesosCOP;
    },
    titulares() {
      //console.log("Titulares");
      this.snackbar = true;
    },
    boleanTexto(a) {
      switch (a) {
        case true:
          return "Activa";
          break;
        case false:
          return "InActiva";
          break;
        default:
          break;
      }
    },
    timeTodate(a) {
      //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.
      //console.log('Entra a leer fecha');
      //console.log(typeof a);
      //console.log(a);

      if (typeof a == "object" && a != null) {
        let time = {
          seconds: a[0].seconds,
          nanoseconds: a[0].nanoseconds,
        };

        const fireBaseTime = new Date(time.seconds * 1000);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        //var convertida = fireBaseTime.toDateString();

        return fireBaseTime.toLocaleDateString("es-ES", options);
      }
    },
    timeTodateDCM(a) {
      //Convierte la fecha si está guardada en marca de tiempo de firebase y la convierte.
      //console.log('Entra a leer fecha');
      //console.log(typeof a);
      //console.log(a);

      if (typeof a == "object" && a != null && a != undefined) {
        let time = {
          seconds: a.seconds,
          nanoseconds: a.nanoseconds,
        };

        const fireBaseTime = new Date(time.seconds * 1000);
        var options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        //var convertida = fireBaseTime.toDateString();

        return fireBaseTime.toLocaleDateString("es-ES", options);
      }
    },
    //Lo que llega hoy / Consulta FIREBASE
    //Horario Titulos Valores
    FBgestionDCM() {
      try {
        //console.log("Gestion DCM");

        // /cf-cartera/Asignaciones/dcm
        const observer = db
          .collection("cf-cartera")
          .doc("Asignaciones")
          .collection("gerencia");
        //.where("a", "==", "no borrar"); //.limit(1); //.where('state', '==', 'CA')

        observer.onSnapshot(
          (docSnapshot) => {
            //console.log(docSnapshot.size);
            this.desserts = []; //Vaciar
            this.titulosValores = []; //Vaciar
            this.verificaPago = []; //Vaciar

            docSnapshot.forEach((doc) => {
              //console.log(doc.data());
              //console.log(doc);
              if (doc.data().a == "no borrar") {
                //console.log(doc.id);
                //audio.pause();
                this.idTraza = doc.id;
              } else {
                var fechaCambio = this.timeTodateDCM(doc.data().proxToque);
                let tipificacion = doc.data().tipiUlti;
                if (tipificacion == "Solicitud Titulo Valor") {
                  //console.log(this.hora);
                  //4:30 pm   -- 16:30:00  -- 17:30:00
                  // && this.hora <= "17:30:00"
                  if (this.hora > "07:00:00") {
                    //console.log("es mayor");
                    this.verTitulosValoresHora = true;
                    this.titulosValores.push({
                      Cedula: doc.data().Cedula,
                      estado: doc.data().estado,
                      idRegistro: doc.data().idRegistro,
                      proxToque: fechaCambio,
                      tipiUlti: doc.data().tipiUlti,
                      toques: doc.data().toques,
                      idCartera: doc.data().idCartera,
                      nitCliente: doc.data().nitCliente,
                      codigoB: doc.id,
                    });
                  } else {
                    //console.log("es menor");
                    this.verTitulosValoresHora = false;
                  }
                } else if (tipificacion == "Verificar Pago") {
                  this.verificaPago.push({
                    Cedula: doc.data().Cedula,
                    estado: doc.data().estado,
                    idRegistro: doc.data().idRegistro,
                    proxToque: fechaCambio,
                    tipiUlti: doc.data().tipiUlti,
                    toques: doc.data().toques,
                    idCartera: doc.data().idCartera,
                    nitCliente: doc.data().nitCliente,
                    codigoB: doc.id,
                  });
                } else {
                  this.desserts.push({
                    Cedula: doc.data().Cedula,
                    estado: doc.data().estado,
                    idRegistro: doc.data().idRegistro,
                    proxToque: fechaCambio,
                    tipiUlti: doc.data().tipiUlti,
                    toques: doc.data().toques,
                    idCartera: doc.data().idCartera,
                    nitCliente: doc.data().nitCliente,
                    codigoB: doc.id,
                  });
                }
              }
            });

            //console.log(docSnapshot);
            this.totalElementos = docSnapshot.size;

            docSnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                //console.log("New: ", change.doc.data());
                //console.log("New");
                this.snackbar = true;
                this.text = "Registro Nuevo";
                this.sonido();
              }
              if (change.type === "modified") {
                //console.log("Modified: ", change.doc.data());
                //console.log("Modified");
                this.snackbar = true;
                this.text = "Registro Modificado";
              }
              if (change.type === "removed") {
                //console.log("Removed");
                this.snackbar = true;
                this.text = "Registro Eliminado";
              }
            });
          },
          (error) => {
            //console.log(error);
            //return false;
          }
        );
      } catch (error) {
        //console.log(error);
      }
    },
    //Color Toques
    getColor(toques) {
      if (toques > 10) return "red";
      else if (toques > 20) return "orange";
      else return "blue lighten-4";
    },
    //Titificación
    getColortipiUlti(a) {
      switch (a) {
        case "Verificar Pago":
          return "red";
          break;
        case "Solicitud Titulo Valor":
          return "orange";
          break;
        case "Novedad":
          return "green";
          break;
        default:
          return "amber";
          break;
      }
    },
    sonido() {
      audio.play();
    },
  },
  computed: {
    dateRangeText() {
      return this.datesRango.join(" ~ ");
    },
  },
  watch: {
    /*desserts() {
      //console.log("Llega trabajo!");
      this.snackbar = true;
      this.text = "¡Registro!";
    },*/
  },
};
</script>