import { firebase, db, storage } from "../../../firebase/config"

export async function FBpuntajeTitular(titularActual, campo, a, usuario) {
    //this.titularActual,"puntajeTitular",a,this.usuario
    try {
        let idResponsable = titularActual.asignadoActual[0];//Era antes... un ERROR...
        let idLogueado = usuario
        let idCartera = titularActual.idCartera;
        let idTitular = titularActual.idRegistro;
        let cliente = titularActual.nitCliente;
        let nodo = campo;
        let valor = a;

        const titularRef = db.collection('cf-cartera').doc('Carteras').collection(cliente).doc(idCartera).collection('registros').doc(idTitular);
        const result = await titularRef.update({
            puntajeTitular: valor
        })

        //console.log(result);

        return true;
    } catch (error) {
        //console.log(error);
        return false;
    }
}